import { DOCUMENT, DatePipe } from '@angular/common';
import { Component, OnInit,Inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DropDownItem } from 'src/app/Interfaces/DropDownItem';
import { EventStepsService } from 'src/app/services/event-steps.service';
import { EventsService } from 'src/app/services/events.service';
import { TicketFormStepsService } from 'src/app/services/ticket-form-steps.service';

@Component({
  selector: 'app-initial-ticket-form',
  templateUrl: './initial-ticket-form.component.html',
  styleUrls: ['./initial-ticket-form.component.scss']
})
export class InitialTicketFormComponent  implements OnInit{
     eventInstance:DropDownItem[]=[];
     submitted:boolean=false;

    constructor(public srv:TicketFormStepsService,private EventSrv:EventsService,
        private eventStepsSrv: EventStepsService,
        private router:Router,
        private activatedRoute:ActivatedRoute
         ,private datePipe: DatePipe ,
         @Inject(DOCUMENT) public document: Document
        ){}

        getDateOntomeZone(d: string | number) {
            var eventOffset = this.eventStepsSrv.offSet * 60 ;
            var MyDate = new Date(d).setMinutes(new Date(d).getMinutes()  + (eventOffset));
            if(new Date(MyDate).getFullYear()==new Date(Date.now()).getFullYear()){
                var l= this.datePipe.transform(new Date(MyDate), 'EEE MMMM d hh:mm a');
                return l;
            }else{
                var l= this.datePipe.transform(new Date(MyDate), 'EEE MMMM d,y hh:mm a');
                return l;
            }
          }
    ngOnInit(): void {
     //   console.log(this.eventStepsSrv.id)
        this.srv.myform.controls['events'].setValue([this.eventStepsSrv.id])

        if(this.eventStepsSrv.isColection){

            this.EventSrv.GetSubEvents(this.eventStepsSrv.id).subscribe(y=>{

                var t=y.map(l=>{
                    if(l.id==this.eventStepsSrv.id){l.name="This event"}

                    return l;
                })
                this.eventInstance=t;
            })
        }
        if(this.eventStepsSrv.isRecurring&&!this.eventStepsSrv.isColection){

            this.EventSrv.getReccuringInstances(this.eventStepsSrv.id).subscribe(y=>{

                var t=y.map(l=>{
                    if(l.id==this.eventStepsSrv.id){l.name="This instance"}
                    else(l.name= "Instance of:"+this.getDateOntomeZone(l.name) )
                    return l;
                })
                this.eventInstance=t;
            })
        }

    // this.EventSrv.getReccuringInstances(this.eventStepsSrv.id).subscribe(x=>{
    //     console.log(x);
    //     var t=x.map(y=>{
    //         if(y.id==this.eventStepsSrv.id){y.name="This instance"}
    //         else(y.name= "Instance of:"+this.getDateOntomeZone(y.name) )
    //         return y;
    //     })
    //     this.eventInstance=t;
    // })
    }
    submit(){
        if(this.validateticketinit()){
            this.router.navigate( ['info'] ,{relativeTo: this.activatedRoute.parent});
        }else{
            this.submitted=true;
        }

    }
    cancel(){
        this.srv.clear();
        this.router.navigate( ['tickets'] ,{relativeTo: this.activatedRoute.parent.parent.parent});
        // this.router.navigate([
        //     {
        //         outlets: { 'tickets-steps': ['tickets-all'] }
        //     }
        //   ],{relativeTo: this.activatedRoute.parent.parent});
    }

    validateticketinit(){
        return   this.srv.myform.controls['events'].valid;
       }
}
