
<form [formGroup]="formGroup" (ngSubmit)="onSubmit()" autocomplete="off">
    <div class="container">
        <div class="card custom-table-border">
            <div class="card-header border-0 text-center bg-primary rounded-top-23 ">
                <h1 class="fw-bold text-white mt-2">{{'WaitListTitl1'|translate}}</h1>
            </div>
            <div class="card-body">
                <div class="card-body p-0">
                    <div class="row d-flex justify-content-center ">
                        <h5>{{'WaitListTitl2'|translate}}</h5>
                        <p-inputSwitch formControlName="active" pTooltip="{{'WaitListTitl2Plac'|translate}}"
                            showDelay="1000" tooltipPosition="top" inputId="is-online"></p-inputSwitch>
                    </div>
                    <div class="" *ngIf="formGroup.get('active').value">
                        <p>{{'WaitListP1'|translate}}</p>

                        <div class="p-input-filled col-sm-4 col-md-4 mb-2">
                            <span class="p-float-label">
                                <p-inputNumber pTooltip="{{'AddOnFrmInfoInpt004Pop'|translate}}" showDelay="1000"
                                    tooltipPosition="bottom" inputId="locale-user" [styleClass]="'w-100'"
                                    class="form-control m-0 p-0" [inputStyleClass]="'form-control'"
                                    formControlName="maxnumber"></p-inputNumber>
                                <label class="form-label font-size-14" for="locale-user">{{'WaitListLable1'|translate}}</label>
                            </span>
                        </div>

                        <small>{{'WaitListSmall1'|translate}}</small>
                        <div class="my-4">
                            <p>{{'WaitListP2'|translate}}</p>
                            <div class="my-2">
                                <p-inputSwitch formControlName="useFirstname"></p-inputSwitch>
                                {{'WaitListSwitch1'|translate}} <br>
                            </div>
                            <div class="my-3">
                                <p-inputSwitch formControlName="useEmail" disabled="true"></p-inputSwitch>
                                {{'WaitListSwitch2'|translate}}
                            </div>
                            <div class="my-2">
                                <p-inputSwitch formControlName="useNumberPhone"></p-inputSwitch>
                                {{'WaitListSwitch3'|translate}}
                            </div>
                        </div>
                        <div class="mb-4">
                            <p>{{'WaitListP3'|translate}}</p>
                            <div class="row mt-4">
                                <div class="p-input-filled col-sm-4 col-md-4 mb-2">
                                    <span class="p-float-label">
                                        <p-inputNumber pTooltip="{{'AddOnFrmInfoInpt004Pop'|translate}}"
                                            showDelay="1000" tooltipPosition="bottom" inputId="locale-user"
                                            [styleClass]="'w-100'" class="form-control m-0 p-0"
                                            [inputStyleClass]="'form-control'"
                                            formControlName="maxSeconde"></p-inputNumber>
                                        <label class="form-label font-size-14" for="locale-user">{{'Day(s)'|translate}}</label>
                                    </span>
                                </div>
                                <div class="p-input-filled col-sm-4 col-md-4 mb-2">
                                    <span class="p-float-label">
                                        <p-inputNumber pTooltip="{{'AddOnFrmInfoInpt004Pop'|translate}}"
                                            showDelay="1000" tooltipPosition="bottom" inputId="locale-user"
                                            [styleClass]="'w-100'" class="form-control m-0 p-0"
                                            [inputStyleClass]="'form-control'"
                                            formControlName="maxHours"></p-inputNumber>
                                        <label class="form-label font-size-14" for="locale-user">{{'Hour(s)'|translate}}</label>
                                    </span>
                                </div>
                                <div class="p-input-filled col-sm-4 col-md-4 mb-2">
                                    <span class="p-float-label">
                                        <p-inputNumber pTooltip="{{'AddOnFrmInfoInpt004Pop'|translate}}"
                                            showDelay="1000" tooltipPosition="bottom" inputId="locale-user"
                                            [styleClass]="'w-100'" class="form-control m-0 p-0"
                                            [inputStyleClass]="'form-control'"
                                            formControlName="maxMinute"></p-inputNumber>
                                        <label class="form-label font-size-14" for="locale-user">{{'Minute(s)'|translate}}</label>
                                    </span>
                                </div>
                            </div>
                            <small>{{'WaitListSmall2'|translate}}</small>
                        </div>
                        <div>
                            <p>{{'WaitListP4'|translate}}</p>
                            <p-editor showDelay="1000" tooltipPosition="bottom"
                                [style]="{'min-height': '300px', 'margin-bottom': '15px'}" formControlName="responseMessage">
                            </p-editor>
                        </div>
                        <div>
                            <p>{{'WaitListP5'|translate}}</p>
                            <p-editor showDelay="1000" tooltipPosition="bottom"
                                [style]="{'min-height': '300px', 'margin-bottom': '15px'}" formControlName="confirmMessage">
                            </p-editor>
                        </div>
                        <div>
                            <p>{{'WaitListP6'|translate}}</p>
                            <p-editor showDelay="1000" tooltipPosition="bottom"
                                [style]="{'min-height': '300px', 'margin-bottom': '15px'}" formControlName="apologyMessage">
                            </p-editor>
                        </div>
                    </div>

                </div>
            </div>
        </div>

        <div class="d-flex justify-content-center mt-1">
            <button pTooltip="Save and generate" showDelay="1000" tooltipPosition="right" type="submit"
                class="rounded-pill save-btn  ms-1 mt-md-0 mt-1">{{'SavBtn'|translate}} <span></span></button>

        </div>
    </div>
</form>
