import { Injectable } from '@angular/core';
import axios from 'axios';
@Injectable({
  providedIn: 'root'
})
export class GeoIPServiceService {

  constructor() {
    
   }
   async getLanguageFromIP(ip: string): Promise<string> {
    try {
      const response = await axios.get('http://ip-api.com/json/${ip}');
      const data = response.data;
      
      if (data && data.countryCode) {
        switch (data.countryCode.toLowerCase()) {
          case 'us':
            return 'en'; // English
          case 'fr':
            return 'fr'; // French
          // Add more cases for other countries/languages
          default:
            return 'en'; // Default language
        }
      }
    } catch (error) {
      console.error('Error retrieving client language from IP:', error);
    }

    return 'en'; // Default language
  }
}
