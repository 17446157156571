import { Component,Inject, ViewChild } from '@angular/core';
import { Orgnizer, OrgnizersService } from 'src/app/services/orgnizers.service';
import { environment } from 'src/environments/environment';
import { EventCard } from '../../pages/landing-page/landing-page.service';
import { UserActionsService } from 'src/app/services/user-actions.service';
import { OrganizerFromStepService } from 'src/app/services/organizer-from-step.service';
import { ActivatedRoute, Router } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import { HttpBackend, HttpClient } from '@angular/common/http';
import { profileData } from '../../pages/user-profile-form/user-profile-form.component';
import { Observable, ReplaySubject, Subject } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-profile-form',
  templateUrl: './profile-form.component.html',
  styleUrls: ['./profile-form.component.scss']
})
export class ProfileFormComponent {
  @ViewChild('gmap3') gmapElement2: any;
  @ViewChild('gmap4') gmapElement3: any;
  map: google.maps.Map;
  map2: google.maps.Map;
  id : string;
  url : string = environment.Api;
  data !: profileData;
  linksData :any[];
  linksForPreview  :any[] = [];
  helpStepsModal : boolean = false;
  EventCards : EventCard[];
  IsFollowing:boolean=false;

  private subject: Subject<profileData> = new ReplaySubject<profileData>(1);

  get $getSubject(): Observable<profileData> {
    return this.subject.asObservable();
  }
  constructor(public ua : UserActionsService,private http:HttpClient,
    private router : Router , private activatedrouter : ActivatedRoute , @Inject(DOCUMENT) public document: Document,
    public auth : AuthService
    ) {


  }

  ngOnInit(): void {
   
   this.id = this.activatedrouter.snapshot.paramMap.get("id");
   this.getuserProfile().subscribe(x=>{
    console.log(x)
    this.data=x;
    // setTimeout(()=>{
      this.showMap2(this.data.userAddress.lat,this.data.userAddress.lng,this.data.userAddress.address,1)
      this.showMap2( this.data.userShippingAddress.lat,this.data.userShippingAddress.lng,this.data.userShippingAddress.address,0)
    // },100)
    
   })
   


  }


  getuserProfile(){
   return this.http.get<profileData>(environment.Api+'d/up/g');

  }

  showMap2(lat, lng, address,id) {

    if(id==1){
      const mapProp = {
        center: new google.maps.LatLng(lat, lng),
        zoom: 15,
        mapTypeId: google.maps.MapTypeId.ROADMAP
      };
      this.map = new google.maps.Map(this.gmapElement2.nativeElement, mapProp);
      const marker = new google.maps.Marker({
        position: new google.maps.LatLng(lat, lng),
        title: address,
      });
      const geocoder = new google.maps.Geocoder();
    marker.setMap(this.map);
    }else{
      const mapProp1 = {
        center: new google.maps.LatLng(lat, lng),
        zoom: 15,
        mapTypeId: google.maps.MapTypeId.ROADMAP
      };
      // set center
     
      this.map2 = new google.maps.Map(this.gmapElement3.nativeElement, mapProp1);
      // take marker
     
      const marker2 = new google.maps.Marker({
        position: new google.maps.LatLng(lat, lng),
        title: address,
      });
      const geocoder = new google.maps.Geocoder();
      marker2.setMap(this.map2);
    }

   
   


  }

}




