<div class=" rounded-25 mt-3">
    <div class="container rounded-top-right-25 rounded-top-left-25 bg-modified  p-0">
        <div class="d-flex align-items-center justify-content-start rounded-top-right-25 rounded-top-left-25">
            <div class="  pt-3 bg-white organizer-space shadow-sm rounded-top-right-25 rounded-top-left-25 ps-3">
                <div class="row align-items-center">
                    <div class="col-lg-2 col-md-2 col-12 text-md-end text-start ">
                        <img style="height: 80px; width: 80px;"
                        [src]="image==''?'assets/img/courses/3SUBiaP.png':url+image"

                            alt="Organizer Profile Photo" class=" rounded-circle me-2" />
                    </div>
                    <div class="col-lg-10 col-md-10 col-12">
                        <div class="d-flex align-items-center">
                            <h3>{{'Hi,'|translate}} <a href="javascript:;" (click)="opp.toggle($event)"><u>{{name}}</u> <i class="fa-solid fa-chevron-down ms-2 fs-6" (click)="opp.toggle($event)"></i> </a> </h3>
                            
                            <p-overlayPanel #opp  [style]="{width: '450px'}">
                                <ng-template pTemplate>
                                    <p-table [value]="orgnizerData" selectionMode="single" [(selection)]="selectedProduct" (onRowSelect)="onRowSelect2($event,opp)"   [scrollable]="true" scrollHeight="400px" responsiveLayout="scroll">
                                        <!-- <ng-template pTemplate="header">
                                            <tr>

                                            </tr>
                                        </ng-template> -->
                                        <ng-template pTemplate="body" let-rowData let-product>
                                            <tr [pSelectableRow]="rowData">
                                                <td>{{product.name}}</td>
                                                <!-- <td><img src="assets/showcase/images/demo/product/{{product.image}}" [alt]="product.image" class="product-image" /></td>
                                                <td>{{product.price}}</td> -->
                                            </tr>
                                        </ng-template>
                                    </p-table>
                                </ng-template>
                            </p-overlayPanel>
                        </div>
                        <div class="d-flex align-items-center">
                            <h6 class="me-2">{{eventcount+" "}}{{'Total Event'|translate}} </h6>
                            <h6>{{totalfollwers+" "}}{{'Total followers'|translate}}</h6>
                        </div>
                        <div class="d-flex align-items-center">
                            <span class="me-2"><a routerLink="/Profile" style="color: blue;">{{'View'|translate}}</a></span>
                             <span *ngIf="id==''"><a routerLink="/User-profile"
                                    style="color: blue ; ">{{'Edit'|translate}}</a></span><span *ngIf="id!=''"><a routerLink="/organizers"
                                        style="color: blue ; ">{{'Edit'|translate}}</a></span>
                        </div>
                    </div>
                </div>
                <div class="row  mt-3 ">
                    <h4  *ngIf="events!=null" >{{'Your next event is in'|translate}} <span style="color: blue;"> {{afternum}} {{'days'|translate}}.</span></h4>
                     <h4 *ngIf="events==null">{{'there is no next event'|translate}} <span style="color: blue;"></span></h4>
                    <a routerLink="/events"><span style="color: blue;">{{'Go to events'|translate}}</span> </a>
                    <div class="card-base  " *ngIf="events!=null">
                        <div class="d-lg-flex d-block align-items-center mt-3  mx-2 justify-content-between border  rounded-4 ">
                            <div class="text-md-start  padding-custom text-end Image-cutom ">
                                <div class="d-lg-flex d-block align-items-center">
                                    <div class="static-width text-center ">
                                        <span class=" text-center row align-items-center  fw-bold me-md-2 date-width">
                                            <h5 class="text-primary col-12">{{events!=undefined?getDateOntomeZoneformat2(events?.startDate,'month'):""}}</h5>
                                            <h5 class=" col-12">{{events!=undefined?getDateOntomeZoneformat2(events?.startDate,'day'):""}}</h5>
                                        </span>
                                        <div class="d-lg-flex d-block align-items-center me-lg-2">
                                            <img [src]="events?.photoLink==null?'assets/img/courses/3SUBiaP.png':url+events?.photoLink"
                                                alt="img" width="100" class="shadow-4 min-size rounded-2" />
                                        </div>
                                    </div>

                                    <div class="d-block my-lg-0 my-2 text-center">
                                        <span class="text-lenght d-lg-block fw-bold">{{events?.title}}</span>
                                        <span class="text-secondary d-lg-block d-none">{{events?.address}} </span>
                                            <span class="text-secondary d-lg-block d-none">{{'Starts:'|translate}}{{" "+events!=undefined?getDateOntomeZoneformat2(events?.startDate,'full'):""+"."}} </span>

                                    </div>
                                </div>
                            </div>

                            <div class="text-md-center padding-custom text-lenght-larg text-end d-grid  "
                                style="justify-items: center;">
                                <span class="headers-cutom text-start fw-bold">{{ events?.soldTickets+'/'+ events?.totalQty}}</span>
                                <span class="text-lenght">{{'TicketsSold'|translate}} </span>
                            </div>



                            <div class="text-center">
                                <button *ngIf="" type="button" pButton pRipple [pRowToggler]="event"
                                    class="p-button-text p-button-rounded d-lg-none d-block"
                                    [icon]="expanded ? 'fa-regular fa-chevron-down' : 'fa-regular fa-ticket fa-rotate-90'">{{'EvntTblBtn002Chois004'|translate}}</button>
                                <p-overlayPanel #op>
                                    <button pButton pRipple icon="pi pi-pencil"
                                        class="p-button-rounded p-button-primary m-1"
                                        (click)="editProduct(events)"></button><br>
                                    <button  pButton pRipple icon="pi pi-eye"
                                        class="p-button-rounded p-button-primary m-1"
                                        (click)="preview(events.id)"></button><br>
                                    <!-- <p-toast></p-toast> -->
                                    <!-- <p-confirmPopup></p-confirmPopup>
                                    <button pButton pRipple icon="pi pi-trash"
                                        class="p-button-rounded p-button-danger m-1"></button> -->
                                </p-overlayPanel>

                                <button pButton pRipple (click)="op.toggle($event)" icon="far fa-ellipsis-v"
                                    class="p-button-rounded p-button-primary m-1"></button>

                            </div>
                        </div>

                    </div>
                </div>

                <ng-container *ngIf="id==''">


                    <div class="row  mt-3">
                        <h4>{{'You have'|translate}}<span style="color: blue;">{{newOrgOrders.length==0?0:newOrgOrders.length}} {{'tickets.'|translate}}</span></h4>
                        <a routerLink="/User-Tickets"><span style="color: blue;">{{'Gotoorders'|translate}}</span> </a>
                        <div  [ngClass]="{'card-base':newOrgOrders.length>1}">


                            <ng-container *ngFor="let f of newOrgOrders ">
                                <div class="d-lg-flex d-block align-items-center mt-3 mx-2 justify-content-around border  rounded-4 ">
                                    <div class="text-md-start  padding-custom text-end Image-cutom ">
                                        <div class="d-lg-flex d-block align-items-center">
                                            <div class="static-width text-center ">
                                                <span class=" text-center row align-items-center  fw-bold me-md-2 date-width" >
                                                    <h5 class="text-primary col-12">{{getDateOntomeZoneformat2(f?.eventStartTime,'month')}}</h5>
                                                    <h5 class=" col-12">{{getDateOntomeZoneformat2(f?.eventStartTime,'day')}}</h5>
                                                </span>
                                                <div class="d-lg-flex d-block align-items-center me-lg-2">
                                                    <img [src]="f?.eventPic==null?'assets/img/courses/3SUBiaP.png':url+f?.eventPic"
                                                        alt="img" width="100" class="shadow-4 min-size rounded-2" />
                                                </div>
                                            </div>
                                            <div class="d-block my-lg-0 my-2 text-center">
                                                <span class="text-lenght d-lg-block fw-bold">{{f.eventName}}</span>
                                            <span class="text-secondary d-lg-block d-none">{{f?.address}} </span>
                                                <span class="text-secondary d-lg-block d-none">{{'Starts:'|translate}}{{" "+getDateOntomeZoneformat2(f?.eventStartTime,'full')+'.'}} </span>
                                                    <span class="text-secondary d-lg-block d-none">{{'OrderRepCo001'|translate}}{{" "+f.orderNum+" "}}{{'placed on'|translate}}{{" "+getDateOntomeZoneformat2(f?.orderDate,'full')+'.'}}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="text-center padding-custom text-lenght-larg ">
                                        <span class="headers-cutom text-start fw-bold">{{f.tikettype}}</span>

                                    </div>
                                    <div class="text-center padding-custom text-lenght-larg">
                                        <span class="headers-cutom text-start fw-bold">{{f.delivery|translate}}</span>

                                    </div>
                                    <div class="text-center">
                                        <button *ngIf="" type="button" pButton pRipple [pRowToggler]="event"
                                            class="p-button-text p-button-rounded d-lg-none d-block"
                                            [icon]="expanded ? 'fa-regular fa-chevron-down' : 'fa-regular fa-ticket fa-rotate-90'">{{'EvntTblBtn002Chois004'|translate}}</button>
                                        <p-overlayPanel #opi>
                                            <button pButton pRipple icon="fa-sharp fa-solid fa-eye"
                                            class="p-button-rounded p-button-primary m-1"
                                            (click)="preview(f.eventId)"></button><br>
                                            <button  *ngIf="f?.address=='Online'" pButton pRipple icon="fa-solid fa-link"
                                                class="p-button-rounded p-button-primary m-1"
                                                (click)="gotoOnlinePage(f.eventId)"></button><br  *ngIf="f?.address=='Online'">
                                            <button  pButton pRipple icon="fa-sharp fa-solid fa-ticket"
                                                class="p-button-rounded p-button-primary m-1"
                                                (click)="downloadTickets(f)"></button><br >
                                            <p-toast></p-toast>
                                            <p-confirmPopup></p-confirmPopup>
                                            <button pButton pRipple icon="pi pi-trash"
                                                class="p-button-rounded p-button-danger m-1"></button>
                                        </p-overlayPanel>

                                        <button pButton pRipple (click)="opi.toggle($event)" icon="far fa-ellipsis-v"
                                            class="p-button-rounded p-button-primary m-1"></button>

                                    </div>
                                </div>
                            </ng-container>



                        </div>
                    </div>
                    <div class="row  mt-3">
                        <h6>{{'Past tickets'|translate}}</h6>
                        <div  [ngClass]="{'card-base':pastOrgOrders.length>1}">
                            <ng-container  *ngFor="let f of pastOrgOrders">
                                <div class="d-lg-flex d-block align-items-center mt-3  mx-2 justify-content-around  border  rounded-4">
                                    <div class="text-md-start  padding-custom text-end Image-cutom ">
                                        <div class="d-lg-flex d-block align-items-center">
                                            <div class="static-width text-center ">
                                                <span class=" text-center row align-items-center  fw-bold me-md-2 date-width" >
                                                    <h5 class="text-primary col-12">{{getDateOntomeZoneformat2(f?.eventStartTime,'month')}}</h5>
                                                    <h5 class=" col-12">{{getDateOntomeZoneformat2(f?.eventStartTime,'day')}}</h5>
                                                </span>
                                                <div class="d-lg-flex d-block align-items-center me-lg-2">
                                                    <img
                                                         [src]="f?.eventPic==null?'assets/img/courses/3SUBiaP.png':url+f?.eventPic"
                                                        alt="img" width="100" class="shadow-4 min-size rounded-2" />
                                                </div>
                                            </div>
                                            <div class="d-block my-lg-0 my-2 text-center">
                                                <span class="text-lenght d-lg-block fw-bold"> {{f.eventName}}</span>
                                                <span class="text-secondary d-lg-block d-none">{{f?.address}} </span>
                                                <span class="text-secondary d-lg-block d-none">{{'Starts:'|translate}}{{" "+getDateOntomeZoneformat2(f?.eventStartTime,'full')+'.'}} </span>
                                                    <span class="text-secondary d-lg-block d-none">{{'OrderRepCo001'|translate}}{{" "+f.orderNum+" "}}{{'placed on'|translate}}{{" "+getDateOntomeZoneformat2(f?.orderDate,'full')+'.'}}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="text-center padding-custom text-lenght-larg ">
                                        <span class="headers-cutom text-start fw-bold">{{f.tikettype}}</span>

                                    </div>
                                    <div class="text-center padding-custom text-lenght-larg">
                                        <span class="headers-cutom text-start fw-bold">{{f.delivery|translate}}</span>

                                    </div>
                                    <div class="text-center">
                                        <button *ngIf="" type="button" pButton pRipple [pRowToggler]="event"
                                            class="p-button-text p-button-rounded d-lg-none d-block"
                                            [icon]="expanded ? 'fa-regular fa-chevron-down' : 'fa-regular fa-ticket fa-rotate-90'">{{'EvntTblBtn002Chois004'|translate}}</button>
                                        <p-overlayPanel #opt>
                                             <button pButton pRipple icon="fa-sharp fa-solid fa-eye"
                                            class="p-button-rounded p-button-primary m-1"
                                            (click)="preview(f.eventId)"></button><br>
                                            <button  *ngIf="f?.address=='Online'" pButton pRipple icon="fa-solid fa-link"
                                                class="p-button-rounded p-button-primary m-1"
                                                (click)="gotoOnlinePage(f.eventId)"></button><br  *ngIf="f?.address=='Online'">
                                            <button  pButton pRipple icon="fa-sharp fa-solid fa-ticket"
                                                class="p-button-rounded p-button-primary m-1"
                                                (click)="downloadTickets(f)"></button><br >
                                            <p-toast></p-toast>
                                            <p-confirmPopup></p-confirmPopup>
                                            <button pButton pRipple icon="pi pi-trash"
                                                class="p-button-rounded p-button-danger m-1"></button>
                                        </p-overlayPanel>

                                        <button pButton pRipple (click)="opt.toggle($event)" icon="far fa-ellipsis-v"
                                            class="p-button-rounded p-button-primary m-1"></button>

                                    </div>
                                </div>
                            </ng-container>



                        </div>
                    </div>
                </ng-container>

                <ng-container *ngIf="id!=''">


                    <div class="row  mt-3">
                        <h4>{{'You have'|translate}}<span style="color: blue;">{{newOrgOrders.length==0?0:newOrgOrders.length}} {{'newordertickets.'|translate}}</span></h4>
                        <a routerLink="/user-order"><span style="color: blue;">{{'Gotoorders'|translate}}</span> </a>
                        <div  [ngClass]="{'card-base':newOrgOrders.length>1}">


                            <ng-container *ngFor="let f of newOrgOrders ">
                                <div class="d-lg-flex d-block align-items-center mt-3 mx-2 justify-content-around  border  rounded-4">
                                    <div class="text-md-start  padding-custom text-end Image-cutom ">
                                        <div class="d-lg-flex d-block align-items-center">
                                            <div class="static-width text-center ">
                                                <span class=" text-center row align-items-center  fw-bold me-md-2 date-width" >
                                                    <h5 class="text-primary col-12">{{getDateOntomeZoneformat2(f?.eventStartTime,'month')}}</h5>
                                                    <h5 class=" col-12">{{getDateOntomeZoneformat2(f?.eventStartTime,'day')}}</h5>
                                                </span>
                                                <div class="d-lg-flex d-block align-items-center me-lg-2">
                                                    <img [src]="f?.eventPic==null?'assets/img/courses/3SUBiaP.png':url+f?.eventPic"
                                                        alt="img" width="100" class="shadow-4 min-size rounded-2" />
                                                </div>
                                            </div>
                                            <div class="d-block my-lg-0 my-2 text-center">
                                                <span class="text-lenght d-lg-block fw-bold">{{f.eventName}}</span>
                                            <span class="text-secondary d-lg-block d-none">{{f?.address}} </span>
                                                <span class="text-secondary d-lg-block d-none">{{'Starts:'|translate}}{{" "+getDateOntomeZoneformat2(f?.eventStartTime,'full')+'.'}}</span>
                                                    <span class="text-secondary d-lg-block d-none">{{'order #'|translate}}{{" "+f.orderNum+" "}}{{'placed on'|translate}}{{" "+getDateOntomeZoneformat2(f?.orderDate,'full')+'.'}}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="text-center padding-custom text-lenght-larg ">
                                        <span class="headers-cutom text-start fw-bold">{{f.tikettype}}</span>

                                    </div>
                                    <div class="text-center padding-custom text-lenght-larg">
                                        <span class="headers-cutom text-start fw-bold">{{f.delivery|translate}}</span>

                                    </div>
                                    <div class="text-center">
                                        <button *ngIf="" type="button" pButton pRipple [pRowToggler]="event"
                                            class="p-button-text p-button-rounded d-lg-none d-block"
                                            [icon]="expanded ? 'fa-regular fa-chevron-down' : 'fa-regular fa-ticket fa-rotate-90'">{{'EvntTblBtn002Chois004'|translate}}</button>
                                        <p-overlayPanel #op>
                                            <button pButton pRipple icon="fa-sharp fa-solid fa-eye"
                                            class="p-button-rounded p-button-primary m-1"
                                            (click)="preview(f.eventId)"></button><br>
                                            <button  *ngIf="f?.address=='Online'" pButton pRipple icon="fa-solid fa-link"
                                                class="p-button-rounded p-button-primary m-1"
                                                (click)="gotoOnlinePage(f.eventId)"></button><br  *ngIf="f?.address=='Online'">
                                            <button  pButton pRipple icon="fa-sharp fa-solid fa-ticket"
                                                class="p-button-rounded p-button-primary m-1"
                                                (click)="downloadTickets(f)"></button><br >
                                            <p-toast></p-toast>
                                            <p-confirmPopup></p-confirmPopup>
                                            <button pButton pRipple icon="pi pi-trash"
                                                class="p-button-rounded p-button-danger m-1"></button>
                                        </p-overlayPanel>

                                        <button pButton pRipple (click)="op.toggle($event)" icon="far fa-ellipsis-v"
                                            class="p-button-rounded p-button-primary m-1"></button>

                                    </div>
                                </div>
                            </ng-container>



                        </div>
                    </div>
                    <div class="row  mt-3">
                        <h6>{{'Pastordertickets'|translate}}</h6>
                        <div  [ngClass]="{'card-base':pastOrgOrders.length>1}">
                            <ng-container  *ngFor="let f of pastOrgOrders">
                                <div class="d-lg-flex d-block align-items-center mt-3 mx-2 justify-content-around border  rounded-4 ">
                                    <div class="text-md-start  padding-custom text-end Image-cutom ">
                                        <div class="d-lg-flex d-block align-items-center">
                                            <div class="static-width text-center ">
                                                <span class=" text-center row align-items-center  fw-bold me-md-2 date-width" >
                                                    <h5 class="text-primary col-12">{{getDateOntomeZoneformat2(f?.eventStartTime,'month')}}</h5>
                                                    <h5 class=" col-12">{{getDateOntomeZoneformat2(f?.eventStartTime,'day')}}</h5>
                                                </span>
                                                <div class="d-lg-flex d-block align-items-center me-lg-2">
                                                    <img
                                                         [src]="f?.eventPic==null?'assets/img/courses/3SUBiaP.png':url+f?.eventPic"
                                                        alt="img" width="100" class="shadow-4 min-size rounded-2" />
                                                </div>
                                            </div>
                                            <div class="d-block my-lg-0 my-2 text-center">
                                                <span class="text-lenght d-lg-block fw-bold"> {{f.eventName}}</span>
                                                <span class="text-secondary d-lg-block d-none">{{f?.address}} </span>
                                                <span class="text-secondary d-lg-block d-none">{{'Starts:'|translate}}{{" "+getDateOntomeZoneformat2(f?.eventStartTime,'full')+'.'}}</span>
                                                    <span class="text-secondary d-lg-block d-none">{{'order #'|translate}}{{" "+f.orderNum+" "}}{{'placed on'|translate}}{{" "+getDateOntomeZoneformat2(f?.orderDate,'full')+'.'}}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="text-center padding-custom text-lenght-larg ">
                                        <span class="headers-cutom text-start fw-bold">{{f.tikettype}}</span>

                                    </div>
                                    <div class="text-center padding-custom text-lenght-larg">
                                        <span class="headers-cutom text-start fw-bold">{{f.delivery|translate}}</span>

                                    </div>
                                    <div class="text-center">
                                        <button *ngIf="" type="button" pButton pRipple [pRowToggler]="event"
                                            class="p-button-text p-button-rounded d-lg-none d-block"
                                            [icon]="expanded ? 'fa-regular fa-chevron-down' : 'fa-regular fa-ticket fa-rotate-90'">{{'EvntTblBtn002Chois004'|translate}}</button>
                                        <p-overlayPanel #op>
                                            <button pButton pRipple icon="fa-sharp fa-solid fa-eye"
                                            class="p-button-rounded p-button-primary m-1"
                                            (click)="preview(f.eventId)"></button><br>
                                            <button  *ngIf="f?.address=='Online'" pButton pRipple icon="fa-solid fa-link"
                                                class="p-button-rounded p-button-primary m-1"
                                                (click)="gotoOnlinePage(f.eventId)"></button><br  *ngIf="f?.address=='Online'">
                                            <button  pButton pRipple icon="fa-sharp fa-solid fa-ticket"
                                                class="p-button-rounded p-button-primary m-1"
                                                (click)="downloadTickets(f)"></button><br >
                                            <p-toast></p-toast>
                                            <p-confirmPopup></p-confirmPopup>
                                            <button pButton pRipple icon="pi pi-trash"
                                                class="p-button-rounded p-button-danger m-1"></button>
                                        </p-overlayPanel>

                                        <button pButton pRipple (click)="op.toggle($event)" icon="far fa-ellipsis-v"
                                            class="p-button-rounded p-button-primary m-1"></button>

                                    </div>
                                </div>
                            </ng-container>



                        </div>
                    </div>
                </ng-container>


                <div class="row  mt-3">
                    <h3>{{'Howwecanhelp'|translate}}</h3>
                    <div class="row d-flex align-items-center mt-3">
                        <div class="col-lg-3 col-md-6 col-12">
                            <h5 class="me-4"><i class="fa-sharp fa-solid fa-calendar-plus text-primary me-1"></i><a
                                [routerLink]="['/events/manage-event/', '0']" style="color:blue">{{'Createanevent'|translate}}</a></h5>
                        </div>
                        <div class="col-lg-3 col-md-6 col-12">
                            <h5 class="me-4"><i class="fa-solid fa-magnifying-glass text-primary me-1"></i><a  routerLink="/search"
                                    style="color:blue"> {{'BrowsEvents'|translate}}</a></h5>
                        </div>
                        <div class="col-lg-3 col-md-6 col-12">
                            <h5 class="me-4"><i class="fa-solid fa-file-chart-column text-primary me-1"></i><a href=""
                                    style="color:blue"> {{'Report'|translate}}</a></h5>
                        </div>
                        <div class="col-lg-3 col-md-6 col-12">
                            <h5 class="me-4"><i class="fa-solid fa-message-text text-primary me-1"></i> <a href=""
                                    style="color:blue"> {{'Contact us'|translate}}</a></h5>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
