import { Component } from '@angular/core';
import { Location } from '@angular/common';

@Component({
  selector: 'app-not-allowed',
  templateUrl: './not-allowed.component.html',
  styleUrls: ['./not-allowed.component.scss']
})
export class NotAllowedComponent {
  /**
   *
   */
  constructor(private loc : Location) {

    
  }
goBack(){this.loc.back()}
}
