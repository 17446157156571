import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, ReplaySubject, Subject } from 'rxjs';
import { DropDownItem } from '../Interfaces/DropDownItem';
import { environment } from 'src/environments/environment';
import { AdminlocalstringDto, langugesDto, localstringDto, localstringsingleDto } from '../Interfaces/attrDto';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class LocalStringSrvService {

  url=environment.Api;
 
  private subject: Subject<AdminlocalstringDto> = new ReplaySubject<AdminlocalstringDto>(1);

  get $getSubject(): Observable<AdminlocalstringDto> {
    return this.subject.asObservable();
  }
  constructor(public http:HttpClient,
    private router:Router,
    private activatedRoute:ActivatedRoute) { }
    getAlltype(id=0,seacrh:string=""){ //all/tab/{CultureId}/{Skip?}
     // this.http.get<localstringDto[]>(this.url+'d/loc/all/en').subscribe(x=> this.subject.next(x));
      //  return this.http.get<TicketsTablesDto[]>(this.url+'d/t/g/t/all/'+id);
      var lang=localStorage.getItem('loclizetion')
      this.http.get<AdminlocalstringDto>(this.url+'d/loc/all/tab/'+lang+'/'+id+'/'+seacrh).subscribe(x=> this.subject.next(x));
      }

      // postattrstauts(id){
      //   var data:string="";
      //  return this.http.put(this.url+'d/a/t/'+ id,data)
      // }

      GetAllSystemLang(){
       return this.http.get<langugesDto[]>(this.url+'d/cu/all').pipe(map(x => {

         var l=x.map(y=>{
            var  t:DropDownItem={id:y.cultureId,name:y.name}
            return t
         })
          return l;
      }))
      }
      GetAtrrSingle(id){
        return this.http.get<localstringsingleDto[]>(this.url+'d/loc/g/i/l/'+id);
      }

      PostnewAttr(data){
        var url=this.url+'d/loc/c/lo';
        return this.http.post(url, data);
      }
      DeletLocalString(s:string){
        const queryParams = new HttpParams().append('ResKey', s);
        return this.http.delete(this.url+'d/loc/d',{params:queryParams});
      }
      // DeletAttr(id){
      //   return this.http.delete(this.url+'d/a/d/'+id);
      // }

}
