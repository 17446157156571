<form [formGroup]="eventDateForm"  autocomplete="off">
    <div   class="container">
        <div class="card custom-table-border">
            <div class="card-header border-0 text-center bg-primary rounded-top-23 ">
                <h1 class="fw-bold text-white mt-2">{{'Dat&TimTitl'|translate}}</h1>
                </div>
            <!-- <div class="card-header text-center bg-primary rounded-top-23">
                <h1 class="text-white my-1">Date & time</h1>
            </div> -->
            <div  class="card-body">
                <div class="card-body p-0">
                    <div *ngIf="loader.show1" @fadeOut class="d-flex justify-content-center align-items-center" style="border-radius: 25px;top : 0;position: absolute;z-index: 1050;background-color:#fff;width: 100%;height: -webkit-fill-available;right: 0;bottom: 0;left: 0;">


                    </div>

                    <div class="row d-flex justify-content-center">
                        <div class="p-input-filled col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6 mb-2">
                            <span class="p-float-label">
                                <!--    -->
                                <p-calendar  #cli
                                dataType="string"
                                [stepMinute]="1" 
                                [showTime]="true"
                                [touchUI]="screenSize"
                                pTooltip="{{'Dat&TimInput001Pop'|translate}}"
                                 showDelay="1000"
                                 touchUI="true"
                                 tooltipPosition="bottom"
                                 (onShow)="getchow($event,cli)"
                                  [showSeconds]="false"
                                  [hourFormat]="12"
                                   appendTo="body"
                                    [minDate]="minDateValue"
                                    [maxDate]="maxEventDate"
                                    inputId="salesStart"
                                    [styleClass]="'form-control m-0 p-0 '"
                                    [inputStyleClass]="'form-control'"
                                     formControlName="startDate"
                                    (onSelect)="confirmdate($event)" [ngClass]="{
                                    'ng-invalid ng-dirty':
                                        eventDateForm.get('startDate')
                                            ?.touched &&
                                        eventDateForm.get('startDate')?.invalid || eventDateForm.get('startDate')?.invalid && submitted
                                }">

                              <ng-template pTemplate="footer" >
                                <p class="clock-positon"> <i  class="fa-solid fa-clock"></i> </p>
                             </ng-template>
                            </p-calendar>
                                <label class="form-label font-size-14" for="salesStart">
                                   {{'Dat&TimInput001'|translate}}</label>
                            </span>
                            <small class="p-error" *ngIf="eventDateForm.get('startDate')
                            ?.touched &&
                        eventDateForm.get('startDate')?.invalid || eventDateForm.get('startDate')?.invalid && submitted
                                 ">{{'Dat&TimInput001ErrMsg'|translate}}</small>
                        </div>
                        <div class="p-input-filled col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6 mb-2 mb-2">
                            <span class="p-float-label">
                                <p-calendar  #cl2 dataType="string" [stepMinute]="1" 
                                [showTime]="true"  [touchUI]="screenSize"  
                                pTooltip="{{'Dat&TimInput002Pop'|translate}}" showDelay="1000" 
                                tooltipPosition="bottom" [showSeconds]="false" 
                                [hourFormat]="12" appendTo="body"
                                    [minDate]="maxDateValue"   
                                     [maxDate]="maxEventDate" 
                                     (onShow)="getchow($event,cli)" inputId="salesEnd"
                                    [styleClass]="'form-control m-0 p-0'" [inputStyleClass]="'form-control'"
                                    formControlName="endDate" [ngClass]="{
                                    'ng-invalid ng-dirty':
                                        eventDateForm.get('endDate')?.touched &&
                                        eventDateForm.get('endDate')?.invalid || eventDateForm.get('endDate')?.invalid && submitted
                                }">
                          <ng-template pTemplate="footer" >
                            <p class="clock-positon"> <i  class="fa-solid fa-clock"></i> </p>
                    <!-- <p class="" style="padding-left: 1.1rem;position:relative;translate: 0 -80px;"> <i style="font-size: 1.1rem" class="fa-solid fa-clock"></i> </p> -->
                 </ng-template>

                            </p-calendar>
                                <label class="form-label font-size-14" for="salesEnd">
                                    {{'Dat&TimInput002'|translate}}</label>
                            </span>
                            <small class="p-error" *ngIf="eventDateForm.get('endDate')
                            ?.touched &&
                        eventDateForm.get('endDate')?.invalid || eventDateForm.get('endDate')?.invalid && submitted
                                 ">{{'Dat&TimInput002ErrMsg'|translate}}</small>
                        </div>
                    </div>
                    <div class="row mb-2"></div>

                    <div class="row mb-2">
                        <div class="col-6">
                            <p-inputSwitch ariaLabelledBy="ttt" pTooltip="{{'Dat&TimSwtch001Pop'|translate}}" showDelay="1000" tooltipPosition="bottom" formControlName="displayStart"
                                inputId="bbbb"></p-inputSwitch>
                            <label class=" form-check-label b-5 ms-2" id="ttt" for="bbbb">{{'Dat&TimSwtch001'|translate}}</label>
                        </div>
                        <div class="col-6">
                            <p-inputSwitch ariaLabelledBy="tttt"  pTooltip="{{'Dat&TimSwtch002Pop'|translate}} " showDelay="1000" tooltipPosition="bottom" inputId="bbb"
                                formControlName="displayEnd"></p-inputSwitch>
                            <label class=" form-check-label b-5 ms-2" id="tttt" for="bbb">{{'Dat&TimSwtch002'|translate}}</label>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row"  *ngIf="!loader.show1">
            <div class="col-12 d-flex justify-content-lg-center justify-content-center">
<!--                <div class="banner-btn rounded-pill mx-2">-->
<!--                    <button class="rounded-pill cancel-btn" pTooltip="Cancel and dismiss changes" showDelay="1000" tooltipPosition="left">-->
<!--                        <i class="pi pi-angle-left mt-1" ></i> Cancel<span></span>-->
<!--                    </button>-->
<!--                </div>-->
                <div class=" d-flex justify-content-center gap-1">
                    <button [disabled]="isSubmitting" (click)="submit(1)" type="submit" class="rounded-pill save-btn {{isSubmitting? 'bg-disabled' : ''}}" pTooltip="Save changes" showDelay="1000" tooltipPosition="right">
                        <i  *ngIf="isSubmitting" class="fas fa-spinner fa-spin"></i>
                        {{'SavBtn'|translate}}  <span></span>
                    </button>
                    <button *ngIf="!srv.TimingBool" [disabled]="isSubmitting" (click)="submit(2)"  type="submit" class="rounded-pill save-btn {{isSubmitting? 'bg-disabled' : ''}}" pTooltip="Save changes" showDelay="1000" tooltipPosition="right">
                        <i  *ngIf="isSubmitting" class="fas fa-spinner fa-spin"></i>
                        {{'Sav&NxtBtn'|translate}}  <span></span>
                    </button>
                </div>
            </div>
        </div>
    </div>
    <!-- <div class="p-input-filled col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6 mb-2">
                <label class="b-5 ms-2" for="RecTicketConf">Visibilty</label>
                <p-dropdown inputId="DelveryM" [styleClass]="'w-100'"  [autoDisplayFirst]="false"
                    [options]="displayOption" optionLabel="name" optionValue="code"
                    [ngClass]="{'ng-invalid ng-dirty':eventDateForm.get('hidden')?.touched && eventDateForm.get('hidden')?.invalid}" (onChange)="onvisiblityChange($event)"></p-dropdown>
            </div> -->

</form>
