<div class="container y mt-3">

    <div class="hate-btn ">
        <button type="button" (click)="goBack()"><i class="fa-solid fa-left"></i></button>
    </div>

    <p-dialog header="Reserve a spot" [(visible)]="visible" [modal]="true" [breakpoints]="{ '425px': '100vw' }"
        [style]="{ width: '80vw' ,background:'#FFF3E5' }" [draggable]="false" [resizable]="false">
        <div class="events-details-info">
            <ul class="info">
                <li class="price">
                    <div class="d-flex justify-content-between align-items-center">
                        <span>Price</span>
                        {{data?.priceRange?.minValue==data?.priceRange?.maxValue?data?.priceRange?.maxValue:data?.priceRange?.minValue+""+data.curSymbol+"-"+data?.priceRange?.maxValue+""+data.curSymbol}}
                        <!-- {{data?.priceRange?.minValue+""+data.curSymbol }} - {{data?.priceRange?.maxValue+""+data.curSymbol }} -->
                    </div>
                </li>
            </ul>
            <div class="btn-box">
                <a class="rounded-pill save-btn" [routerLink]="['order']">{{'RsrvSptBtn'|translate}} <i
                        class="fa-solid fa-cart-shopping "></i><span></span></a>
                <p>You must <a routerLink="/login">login</a> before register event.</p>
            </div>

        </div>
    </p-dialog>
    <div class="card border border-primary bg-modified rounded-25 ">
        <div class="no-gutters ">

            <!-- Event Photo -->


            <div class="col-12 p-0" id="event-img-div">
                <img [src]='url+data.coverImg' class="card-img img-fluid d-lg-flex d-block " alt="..." id="event-image">
                <div id="timer" class="flex-wrap d-flex justify-content-center" *ngIf="!isStarted&&!isEnded">
                    <div id="days" class="align-items-center flex-column d-flex justify-content-center">{{days}}
                        <span>{{'EvntPgClock001'|translate}}</span>
                    </div>
                    <div id="hours" class="align-items-center flex-column d-flex justify-content-center">{{hours}}
                        <span>{{'EvntPgClock002'|translate}}</span>
                    </div>
                    <div id="minutes" class="align-items-center flex-column d-flex justify-content-center">{{minutes}}
                        <span>{{'EvntPgClock003'|translate}}</span>
                    </div>
                    <div id="seconds" class="align-items-center flex-column d-flex justify-content-center">{{seconds}}
                        <span>{{'EvntPgClock004'|translate}}</span>
                    </div>
                </div>
                <div id="timer" class="flex-wrap d-flex justify-content-center" *ngIf="isStarted||isEnded">
                    <div id="days" class="align-items-center w-100 flex-column d-flex justify-content-center">
                        {{isEnded?('EvntPgTiming002'|translate):'EvntPgTiming001'|translate}}
                        <span></span>
                    </div>


                </div>

            </div>

            <!-- Event-Next-To-img-Info -->
            <div class="col-lg-12 col-12 mt-0 pt-0 events-top-details ">
                <div class="card-body mt-0 pt-0 ">
                    <div class="events-details-header">
                        <ul class="p-0">
                            <li *ngIf="data.startDate"><i class="bx bx-calendar"></i>{{'EvntPgTimDsc001'|translate}} {{
                                getDateOntomeZone(data.startDate)}}</li>
                            <li *ngIf="data.endDate"><i class="bx bx-calendar"></i> {{'EvntPgTimDsc002'|translate}}{{
                                getDateOntomeZone(data.endDate)}}</li>


                        </ul>
                    </div>
                    <span class="event-title text-primary mb-5">{{data.title}}</span>
                </div>
            </div>
        </div>

        <div class="d-lg-flex no-gutters ">
            <!-- Event Sections -->

            <div class="col-lg-8 col-12 container  ">

                <ng-container *ngFor="let item of data.description">
                    <!-- Regular Pargraph -->

                    <section *ngIf="item.type ==='provider'" class="col-12 mb-5">

                        <div class="card custom-table-border">
                            <div class="card-header bg-primary  rounded-top-23">
                                <div class="col-12 d-flex  justify-content-center">
                                    <h3 class="text-white m-0">{{item.title}}</h3>
                                </div>

                            </div>
                            <div class="row  p-2">

                                <div class="col-12 d-flex justify-content-center">
                                    <img [src]="item.image" alt="">
                                </div>
                                <div class="col-12">
                                    <div [innerHTML]="makeItSafeHtml(item.editor)">
                                    </div>
                                </div>
                                <div class="col-12 d-flex justify-content-center">
                                    <a class="btn rounded-pill follow-btn"
                                        [href]="item.link">{{'OnlinEvntPgJoinBtn'|translate}} <span></span></a>

                                    <!-- <a class="btn btn-primary" [href]="item.link">Join the meeting</a> -->
                                </div>
                            </div>
                        </div>



                    </section>


                    <section *ngIf="item.type ==='link'" class="col-12 mb-5">
                        <a class="btn rounded-pill follow-btn" [href]="item.value2">{{item.value1}} <span></span></a>

                        <!-- <a class="btn btn-primary" [href]="item.value2">{{item.value1}}</a> -->
                    </section>


                    <section *ngIf="item.type ==='text'" class="col-12 mb-5 event-section-text-size">
                        <div [innerHTML]="makeItSafeHtml(item.value1)">

                        </div>
                    </section>

                    <!-- Image Section -->

                    <section *ngIf="item.type ==='image'" class="col-12 mb-5">
                        <div>
                            <img class="img-fluid w-100" [src]="item.value1" alt="" />
                        </div>
                    </section>

                    <!--YouTube Video Section -->

                    <!--                    <section *ngIf="item.type ==='video'" class="col-12 mb-5">-->
                    <!--                        <div class="iframe-container w-100">-->
                    <!--                            <iframe width="560" height="315" [src]="makeItSafe(item.value1)"-->
                    <!--                                    title="YouTube video player" frameborder="0"-->
                    <!--                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"-->
                    <!--                                    allowfullscreen></iframe>-->
                    <!--                        </div>-->
                    <!--                    </section>-->

                </ng-container>
                <!-- Tags Section* -->






            </div>





            <!-- Reserv a Spot on Right Side -->


        </div>







    </div>

</div>
