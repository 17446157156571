import { ApplicationRef, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { itemshipingaddress } from '../../pages/order-event/order-event.component';
 export interface elementforgetshippingAddress{
  id:string;
  name:string;
 }
@Component({
  selector: 'app-json-get-shipping-address',
  templateUrl: './json-get-shipping-address.component.html',
  styleUrls: ['./json-get-shipping-address.component.scss']
})
export class JsonGetShippingAddressComponent implements OnChanges,OnInit {
  @Input() jsonFormData: elementforgetshippingAddress;
  @Output() result : EventEmitter<itemshipingaddress> = new EventEmitter();
  selectedaddress:string;
  myform!:FormGroup;
  visible: boolean;
  @ViewChild('gmap') gmapElement: any;
    map: google.maps.Map;
  ssss: google.maps.Marker;
  iniializeFrom(x:elementforgetshippingAddress){
   this.myform=this.fb.group({
    isUsLocal:[false],
    address:[],
    id:[x.id],
    country:[],
    city:[],   
    lat: [],
    lng: []
   })
  }
  constructor(private fb:FormBuilder, private ApplicationRef1: ApplicationRef,){

  }
  ngOnChanges(changes: SimpleChanges): void {
    if (!changes.jsonFormData.firstChange) {
      this.iniializeFrom(this.jsonFormData);
   //   console.log(this.jsonFormData)
    }
    console.log(this.jsonFormData)
}
  ngOnInit(): void {
    this.iniializeFrom(this.jsonFormData)
  }
  getAddress(x) {
    this.visible = true;
    this.showMap(x);
    this.myform.controls['address'].setValue(x.formatted_address);
    this.myform.controls['city'].setValue(x.address_components.find(y => y.types.includes('administrative_area_level_1'))?.long_name);
    this.myform.controls['country'].setValue(x.address_components.find(y => y.types.includes('country'))?.long_name);
    this.myform.controls['lat'].setValue(x.geometry.location.lat());
    this.myform.controls['lng'].setValue(x.geometry.location.lng());
    this.ApplicationRef1.tick()

}
getaddrees(lat,lng){
  
  this.myform.controls['lat'].setValue(lat);
  this.myform.controls['lng'].setValue(lng);

}

outputAddress(){
  var m=this.myform.value as itemshipingaddress
  this.result.emit(m)
  console.log(this.myform.value)
}
showMap(value) {
  //  console.log(value);
    const mapProp = {
        center: new google.maps.LatLng(value.geometry.location.lat(), value.geometry.location.lng()),
        zoom: 15,
        mapTypeId: google.maps.MapTypeId.SATELLITE
    };
    // set center
    this.map = new google.maps.Map(this.gmapElement.nativeElement, mapProp);

    // take marker
    const marker = new google.maps.Marker({
        position: new google.maps.LatLng(value.geometry.location.lat(), value.geometry.location.lng()),
        title: value.formatted_address,
        draggable: true,
        
    });
    const geocoder = new google.maps.Geocoder();
    geocoder.geocode({ placeId: value.place_id }, function (results, status) {
        if (status === 'OK') {
          //  console.log(results);
            // this.map.setCenter(results[0].geometry.location);
            // const marker1 = new google.maps.Marker({
            //     map: this.map,
            //     position: results[0].geometry.location
            // });
        } else {
            alert('Geocode was not successful for the following reason: ' + status);
        }
    });
    // set marker
    marker.setMap(this.map);
    this.ssss = marker;
   this.ssss.addListener('dragend', (event)=> {
      const latLng = event.latLng;
      console.log('Marker moved to: ' + latLng.lat() + ', ' + latLng.lng());
      // Update the form controls with the new marker position
      this.getaddrees(latLng.lat(),latLng.lng())
      this.outputAddress()
      // Call the tick function if necessary
      this.ApplicationRef1.tick();
  });

   
}



}
