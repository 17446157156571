import { Injectable } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { AddOnGroupPostDto, AddOnPostDto } from '../Interfaces/addOnsIntreFacesDto';
import { AddOnsService } from './add-ons.service';

@Injectable({
  providedIn: 'root'
})
export class AddOnGroupStepsService {

  AddonsGroupForm!: FormGroup;
  constructor(private fb: FormBuilder, private addOnsSrv: AddOnsService) {
    this.Intial("0", null, "", null, null, false,null)
  }
  Intial(id: string, event: string, image: string, name: string, addOns: string[], imgChanged: boolean,catId:string) {
    this.AddonsGroupForm = this.fb.group({
      id: [id],
      eventId: [event],
      image: [image,[Validators.required]],
      name: [name, [Validators.required]],
      imageChanged: [imgChanged],
      categoryId:[catId,[Validators.required]],
      addOns: [addOns, [Validators.required,this.validationAddOns()]]
    });
    this.AddonsGroupForm.controls.addOns.valueChanges.subscribe(()=>this.AddonsGroupForm.controls.addOns.updateValueAndValidity())

  }
  validationAddOns():ValidatorFn{
    return (control:AbstractControl)=>{
             return control?.value?.length>=2?null:{IsAgroup:true}
          }
  }
  clear() {
    this.Intial("0", null, "", null, null, false,null)
  }
  Finish() {
    var data = this.AddonsGroupForm.value as AddOnGroupPostDto;
  //  console.log(data)
    if (data.id == "0") {
      this.addOnsSrv.postAddOnsGroup(data);
      this.clear();
    } else {
      this.addOnsSrv.UpdateAddOnsGroup(data);
      this.clear();
    }
  }

}
