<form [formGroup]="prosrvform.promoteCodeForm" (submit)="submit()" autocomplete="off">
    <div class="row">
        <div class="p-input-filled col-12 mb-2">
            <p-inputSwitch pTooltip="{{'PrmoFrmStp2Swtch001Pop'|translate}}" showDelay="1000" tooltipPosition="bottom"
                name="isHidden" binary="true" formControlName="isHidden" inputId="isHidden"></p-inputSwitch>
            <label class=" form-check-label b-5 ms-2" for="isHidden">{{'PrmoFrmStp2Swtch001'|translate}}</label>
        </div>

        <div class="p-input-filled col-md-6 col-12 mb-2 ">
            <span class="p-float-label">
                <p-multiSelect pTooltip="{{'PrmoFrmStp2Slct001Pop'|translate}}" showDelay="1000"
                    tooltipPosition="bottom" [styleClass]="'w-100'"
                    [options]="prosrvform.promoteCodeForm.value['isHidden']? hiddenTickets:visibleTickets"
                    optionLabel="name" optionValue="code" defaultLabel="{{'PrmoFrmStp2Slct001'|translate}}" formControlName="tickets"
                    appendTo="body"
                    [ngClass]="{'ng-invalid ng-dirty':prosrvform.promoteCodeForm.get('tickets')?.touched && prosrvform.promoteCodeForm.get('tickets')?.invalid ||prosrvform.promoteCodeForm.get('tickets')?.invalid && submitted}"></p-multiSelect>
                <label for="">{{'PrmoFrmStp2Slct001'|translate}}</label>
            </span>
            <small *ngIf="prosrvform.promoteCodeForm.get('tickets')?.touched && prosrvform.promoteCodeForm.get('tickets')?.invalid
             ||prosrvform.promoteCodeForm.get('tickets')?.invalid && submitted " class="p-error" text="">Please Select
                a Ticket</small>
        </div>
        <div class="p-input-filled col-md-6 col-12 mb-2 ">
            <span class="p-float-label">
                <p-multiSelect pTooltip="{{'PrmoFrmStp2Slct002Pop'|translate}}" showDelay="1000"
                    tooltipPosition="bottom" [styleClass]="'w-100'"
                    [options]="prosrvform.promoteCodeForm.value['isHidden']? hiddenAddOns:visibleAddOns"
                    optionLabel="name" optionValue="code" defaultLabel="{{'PrmoFrmStp2Slct002'|translate}}" formControlName="addons"
                    appendTo="body"
                    [ngClass]="{'ng-invalid ng-dirty':prosrvform.promoteCodeForm.get('addons')?.touched && prosrvform.promoteCodeForm.get('addons')?.invalid ||prosrvform.promoteCodeForm.get('addons')?.invalid && submitted}"></p-multiSelect>
                <label for="">{{'PrmoFrmStp2Slct002'|translate}}</label>
            </span>
            <small
                *ngIf="prosrvform.promoteCodeForm.get('addons')?.touched && prosrvform.promoteCodeForm.get('addons')?.invalid ||prosrvform.promoteCodeForm.get('addons')?.invalid && submitted "
                class="p-error" text="">Please Select a Ticket</small>
        </div>
    </div>




    <div class=" d-flex  justify-content-center">
        <div class="banner-btn rounded-pill mx-1">

            <button pTooltip="Go back to previous step" showDelay="1000" tooltipPosition="left"
                class="rounded-pill cancel-btn" (click)="previous()"><i class="pi mt-1"
                    [class.pi-angle-right]="document.documentElement.dir === 'rtl'"
                    [class.pi-angle-left]="document.documentElement.dir === 'ltr'"></i> {{'PrviosBtn'|translate}}
                <span></span></button>
            <button pTooltip="Cancel and dismiss changes" showDelay="1000" tooltipPosition="bottom"
                class="rounded-pill save-btn ms-1" (click)="cancel()">{{'CanclBtn'|translate}} <span></span></button>
            <button pTooltip="Save adn go to next step" showDelay="1000" tooltipPosition="right"
                class="rounded-pill next-btn  ms-1 mt-md-0 mt-1">{{'NextBtn'|translate}} <i class="pi mt-1"
                    [class.pi-angle-right]="document.documentElement.dir === 'ltr'"
                    [class.pi-angle-left]="document.documentElement.dir === 'rtl'"></i><span></span></button>
        </div>
    </div>



</form>