import {Component, Input, OnInit} from '@angular/core';
import { ImageCroppedEvent, LoadedImage } from 'ngx-image-cropper';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-cropper',
  templateUrl: './cropper.component.html',
  styleUrls: ['./cropper.component.scss']
})
export class CropperComponent implements OnInit{
    croppedImage: any;
    myFile: any;
    @Input() format: string = 'jpeg';
    @Input() quality: string = '50'; // only works with jpeg
    @Input() aspectRatio: number

    constructor(public ref: DynamicDialogRef, public config: DynamicDialogConfig){}

    ngOnInit(): void {
        this.aspectRatio = this.config.data.aspectRatio;
        this.format = this.config.data.format;
        this.quality = this.config.data.quality;
        this.myFile = this.config.data.myFile;
      //  console.log(this.myFile);

    }

    imageCropped(event: ImageCroppedEvent): void {
        this.croppedImage = event.base64;
    }
    imageLoaded(image: LoadedImage): void {
        // show cropper

    }
    imageLoaded1(): void {
        // show cropper
    }
    cropperReady(): void {
        // cropper ready
    }
    loadImageFailed(): void {
    //    console.log('image failed to load')
    }
    onCropButton(): void{
        this.ref.close({a : this.croppedImage,b: 'ok'});
    }
    cancel(){
        this.ref.close({a : null,b: 'notOK'});
        this.ref.destroy()

    }
}
