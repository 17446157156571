import { AuthService } from 'src/app/services/auth.service';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Observable, ReplaySubject, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit{
  form :  FormGroup = this.fb.group({
    email : [ , [Validators.required , Validators.email]]
  }) ; 

  LogoUrl:string="";
  private subject: Subject<string> = new ReplaySubject<string>(1);

get $getSubject(): Observable<string> {
  return this.subject.asObservable();
}

  constructor(private fb : FormBuilder,public AuthService : AuthService,private translate:TranslateService) {

    
    this.translate.onLangChange.subscribe(x=>{
      var s = localStorage.getItem(this.translate.currentLang+1) as string;
      this.subject.next(s);
  })
  }
  submit(){
    this.AuthService.resetPassword(this.form.get("email").value);
  }
  ngOnInit(): void {
    var s = localStorage.getItem(this.translate.currentLang+1) as string;
    this.subject.next(s);
    this.$getSubject.subscribe(x=>{
        this.LogoUrl=environment.Api+x;
    })
  }

}
