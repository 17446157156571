import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { OrganizerFromStepService } from 'src/app/services/organizer-from-step.service';
import { OrgnizersService, Permission, Roles } from 'src/app/services/orgnizers.service';

@Component({
  selector: 'app-team-roles',
  templateUrl: './team-roles.component.html',
  styleUrls: ['./team-roles.component.scss']
})
export class TeamRolesComponent implements OnInit {
  
  submitted: boolean;
  data: Roles[] = [];
  form: FormGroup;

  permissions: number[] = [];

  ngOnInit(): void {
    
    this.router.navigate(['roles-table'] 
      
    ,{relativeTo: this.activatedRoute});


  }

  constructor(private orgsrvForm: OrganizerFromStepService, private orgSrv: OrgnizersService, private router: Router, private activatedRoute: ActivatedRoute, private fb: FormBuilder) {
    this.router.navigate(['roles-table'
    ],{relativeTo: this.activatedRoute});

  }



}
