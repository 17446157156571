import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Observable, ReplaySubject, Subject } from 'rxjs';
import { AuthService, resetPassword } from 'src/app/services/auth.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-reset-password-form',
  templateUrl: './reset-password-form.component.html',
  styleUrls: ['./reset-password-form.component.scss']
})
export class ResetPasswordFormComponent implements OnInit{

  LogoUrl:string="";
  private subject: Subject<string> = new ReplaySubject<string>(1);

get $getSubject(): Observable<string> {
  return this.subject.asObservable();
}
  token : string;
  email : string;
  form :  FormGroup = this.fb.group({
    password: ['',[Validators.required , Validators.pattern(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+~`-])(?=.*\d).{8,32}$/)]],
    confirmPassword: ['',[Validators.required ]],
  },{
    validators : this.ConfirmPasswordValidator("password" , "confirmPassword")
}) ; 
  constructor(private router : ActivatedRoute,private fb : FormBuilder,public AuthService : AuthService,private translate:TranslateService) {

    this.translate.onLangChange.subscribe(x=>{
      var s = localStorage.getItem(this.translate.currentLang+1) as string;
      this.subject.next(s);
  })
  }
  ngOnInit(): void {
    var s = localStorage.getItem(this.translate.currentLang+1) as string;
    this.subject.next(s);
    this.$getSubject.subscribe(x=>{
        this.LogoUrl=environment.Api+x;
    })
    this.token = this.router.snapshot.paramMap.get("id")
    this.email = this.router.snapshot.paramMap.get("email")

  }
  submit(){
    var data : resetPassword= {
            email : this.email,
            token : this.token,
            password : this.form.get("password").value

    }
    this.AuthService.resetPasswordConfirm(data);
  }
  ConfirmPasswordValidator(controlName: string, matchingControlName: string) {

    return (formGroup: FormGroup) => {
        let control = formGroup.controls[controlName];
        let matchingControl = formGroup.controls[matchingControlName]
        if (
            matchingControl.errors &&
            !matchingControl.errors.confirmPasswordValidator
        ) {
            return;
        }
        if (control.value !== matchingControl.value) {
            matchingControl.setErrors({ confirmPasswordValidator: true });
        } else {
            matchingControl.setErrors(null);
        }
    };
}

}
