import { DOCUMENT, DatePipe } from '@angular/common';
import { Component, OnDestroy, OnInit,Inject} from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { EventDateTimeDto } from 'src/app/Interfaces/EventData';
import { EventStepsService } from 'src/app/services/event-steps.service';
import { EventsService } from 'src/app/services/events.service';



@Component({
  selector: 'app-clender-update-single-instans',
  templateUrl: './clender-update-single-instans.component.html',
  styleUrls: ['./clender-update-single-instans.component.scss']
})
export class ClenderUpdateSingleInstansComponent implements OnInit,OnDestroy {
  eventDateForm!: FormGroup;
  id: string;
  minDateValue!: Date;
  maxDateValue!: Date;
  displayOption = [{ name: "Only Display Start Date", code: "a" },
  { name: "Only Display End Date", code: "b" },
  { name: " Display Both", code: "c" }, { name: " Display None", code: "z" }]
  constructor(private EventSrv: EventStepsService,private fb: FormBuilder,
       public activatedRoute: ActivatedRoute,
        private eventSrv: EventsService,
         private router:Router,private datePipe: DatePipe, @Inject(DOCUMENT) public document: Document

         ) { }
  ngOnDestroy(): void {
 //  console.log("single ime destroyed")
  }

         getDateOntomeZone(d: Date | number) {
          var eventOffset = this.EventSrv.offSet * 60 ;
          var MyDate = new Date(d).setMinutes(new Date(d).getMinutes()  + (eventOffset));
          return new Date(MyDate);
        }

  ngOnInit(): void {


    this.minDateValue=new Date(new Date(new Date(Date.now()).setHours(0)).setMinutes(0));
      this.id = this.activatedRoute.snapshot.paramMap.get('eventId')
      this.inilizeform(this.id ,new Date(Date.now()),new Date(Date.now()),false, false);

      if(this.id!="0"){
          this.eventSrv.getEventDateTime(this.id).subscribe(x => {
                  this.inilizeform(x.id,this.getDateOntomeZone(x.startDate) ,this.getDateOntomeZone(x.endDate) , x.displayStartTime, x.displayEndTime);
          })

      }

      this.minDateValue =new Date(new Date(new Date(Date.now()).setHours(0)).setMinutes(0));
      this.maxDateValue = new Date(new Date(new Date(Date.now()).setHours(0)).setMinutes(0));
  }
  inilizeform(id: string, start: Date, end: Date, DisplayStart: boolean, DisplayEnd: boolean) {
      this.eventDateForm = this.fb.group({
          id: [id,[Validators.required]],
          startDate: [start,[Validators.required]],
          endDate: [end,[Validators.required,this.Vlaidation('startDate')]],
          displayStart: [DisplayStart],
          displayEnd: [DisplayEnd],
      });
      this.eventDateForm.controls.startDate.valueChanges.subscribe(x=>{
        this.eventDateForm.controls.endDate.updateValueAndValidity()
      }) ;
      this.eventDateForm.controls.endDate.valueChanges.subscribe(x=>{
        this.eventDateForm.controls.endDate.updateValueAndValidity()
      }) ;
  }
  confirmdate(e) {

    var t=e;
    var lm=new Date(t)
    this.maxDateValue = new Date( lm.setMinutes(lm.getMinutes()+15));//15
    this.eventDateForm.controls['endDate'].setValue( this.datePipe.transform(this.maxDateValue, 'MM/dd/yyyy hh:mm a'));
      // this.maxDateValue = new Date(new Date(e).setMinutes(new Date(e).getMinutes()+15));
    }

  Vlaidation(param:string):ValidatorFn{
    return (control:AbstractControl)=>{
     var s=new Date(control?.value);
     var e=new Date(control?.parent?.controls[param].value);
      return moment(e).isBefore(s) ?null:{NotVla:true}
    }
}

  submit() {
    //  console.log(this.eventDateForm.value)
      var data=this.eventDateForm.value as EventDateTimeDto;
      this.eventSrv.postEventdate(data).subscribe(x=>{
        if(data.id==this.EventSrv.ParentId){
          var tt = this.eventSrv.GetEventProgressInfo(data.id).subscribe(x => {
            this.EventSrv.InitState(x);
            if (x.isColection&&x.id== this.EventSrv.ParentId) {
                this.EventSrv.setParentDate(x);
            }
        });
        }

        this.router.navigate( ['calendar'] ,{relativeTo: this.activatedRoute.parent.parent});
      })

      // this.router.navigate([
      //   {
      //     outlets: { 'CalenderOutlet': ['calender_prview'] }
      //   }
      // ],{relativeTo: this.activatedRoute.parent});


  }
  cancel(){
    this.router.navigate( ['calendar'] ,{relativeTo: this.activatedRoute.parent.parent});
  }
}
