<form [formGroup]="srv.myform" (submit)="sumbit()" autocomplete="off">
    <div class="row ">
        <div class="p-input-filled col-sm-6 col-md-6 mb-2">
            <span class="p-float-label">
                <input type="text" formControlName="name" pTooltip="{{'TcktFrmInfoInpt001Pop'|translate}}"
                showDelay="1000" tooltipPosition="top" pInputText class="form-control" [ngClass]="{
                        'ng-invalid ng-dirty':
                          srv.myform.get('name')?.touched &&
                            srv.myform.get('name')?.invalid||(submitted&& srv.myform.get('name').invalid)
                    }" [class.ng-invalid.ng-dirty]="" #ticketName />
                <label class="form-lable font-size-14" for="TicketName">{{'TcktFrmInfoInpt001'|translate}}</label>
            </span>
            <small class="p-error" *ngIf="srv.myform.get('name')?.touched &&
            srv.myform.get('name')?.invalid||(submitted&& srv.myform.get('name').invalid)
                " >{{'TcktFrmInfoInpt001ErrMsg'|translate}}</small>

        </div>
        <div class="p-input-filled col-sm-6 col-md-6 mb-2">
            <span class="p-float-label">
                <p-dropdown (onChange)="removeValid($event)" [readonly]="srv.myform.get('id').value!='0'" inputId="TicketType"
                pTooltip="{{'TcktFrmInfoSlct001Pop'|translate}}" showDelay="1000" tooltipPosition="top"
                 [ngClass]="{
                        'ng-invalid ng-dirty':
                           srv.myform.get('ticketType')?.touched &&
                            srv.myform.get('ticketType')?.invalid||(submitted&& srv.myform.get('ticketType').invalid)
                    }" [styleClass]="'w-100'" formControlName="ticketType" [autoDisplayFirst]="false"
                    [options]="tickTy" optionLabel="name" optionValue="id" #ticketsType></p-dropdown>
                <label class="form-lable font-size-14" for="TicketType">{{'TcktFrmInfoSlct001'|translate}}</label>
            </span>
            <small class="p-error" *ngIf="srv.myform.get('ticketType')?.touched &&
            srv.myform.get('ticketType')?.invalid||(submitted&& srv.myform.get('ticketType').invalid)
                " >{{'TcktFrmInfoSlct001ErrMsg'|translate}}</small>

        </div>


        <div class="p-input-filled col-sm-6 col-md-6 mb-2" *ngIf="ticketsType.value != 101040102">


            <div class="p-inputgroup">
                <span class="p-inputgroup-addon  " [ngClass]="{' currency-custom':document.documentElement.dir === 'ltr',' currency-custom-rtl':document.documentElement.dir === 'rtl'}">{{eventStepSrv.currancysymbole}}</span>
                <span class="p-float-label">
                <!-- <input type="text" pInputText  /> -->
                <p-inputNumber inputId="locale-user" pTooltip="{{'TcktFrmInfoInpt002Pop'|translate}}" showDelay="1000" tooltipPosition="top" [minFractionDigits]="2"
                class="w-100 " [inputStyleClass]="'form-control'" formControlName="price" [ngClass]="{
                    'ng-invalid ng-dirty':
                       srv.myform.get('price')?.touched &&
                        srv.myform.get('price')?.invalid||(submitted&& srv.myform.get('price').invalid)
                }"></p-inputNumber>
                <label class="form-label font-size-14" for="withoutgrouping">{{
                    ticketsType.value == 101040103
                    ? 'TcktFrmInfoInpt002Tit002'
                    : 'TcktFrmInfoInpt002Tit001'
                    |translate}}</label>
            </span>
            </div>
            <small class="p-error" *ngIf="srv.myform.get('price')?.touched &&
            srv.myform.get('price')?.invalid||(submitted&& srv.myform.get('price').invalid)
                " >{{'TcktFrmInfoInpt002ErrMsg'|translate}}</small>
        </div>

        <div class="p-input-filled col-sm-6 col-md-6 mb-2">
            <span class="p-float-label">
                <p-inputNumber mode="decimal" pTooltip="{{'TcktFrmInfoInpt003Pop'|translate}}"
                showDelay="1000" tooltipPosition="top" inputId="withoutgrouping" [useGrouping]="false" [styleClass]="'w-100'"
                    class="form-control m-0 p-0" [inputStyleClass]="'form-control'" formControlName="quantity"
                    [ngClass]="{
                        'ng-invalid ng-dirty':
                            srv.myform.get('quantity')?.touched &&
                            srv.myform.get('quantity')?.invalid||(submitted&& srv.myform.get('quantity').invalid)
                    }" #availableQuantity>
                </p-inputNumber>
                <label class="form-label font-size-14" for="withoutgrouping">{{'TcktFrmInfoInpt003'|translate}}</label>
            </span>
            <small class="p-error" *ngIf="srv.myform.get('quantity')?.touched &&
            srv.myform.get('quantity')?.invalid&&
            !srv.myform.get('quantity')?.errors?.['min']||(submitted&& srv.myform.get('quantity').invalid&&!srv.myform.get('quantity')?.errors?.['min'])
                " >{{'TcktFrmInfoInpt003ErrMsg'|translate}}</small>
                <small class="p-error" *ngIf="srv.myform.get('quantity')?.touched &&
                srv.myform.get('quantity')?.invalid&&
                srv.myform.get('quantity')?.errors?.['min']||(submitted&& srv.myform.get('quantity').invalid&&srv.myform.get('quantity')?.errors?.['min'])
                    " >Please add ticket quantity at least {{  srv.myform.get('quantity')?.errors?.['min'].min}}</small>

        </div>
    </div>

    <div class="row mb-2">
        <div class="p-input-filled col-12 mb-2">
            <span class="p-float-label">
                <textarea pTooltip="{{'TcktFrmInfoInpt004Pop'|translate}}"
                 showDelay="1000" tooltipPosition="top" rows="5" cols="30" pInputTextarea [autoResize]="true" class="form-control"
                    formControlName="description" [ngClass]="{
                        'ng-invalid ng-dirty':
                            srv.myform.get('description')?.touched &&
                            srv.myform.get('description')?.invalid||(submitted&& srv.myform.get('description').invalid)
                    }" #description></textarea>
                <label class="form-lable font-size-14" for="description">{{'TcktFrmInfoInpt004'|translate}}
                </label>
            </span>
            <small class="p-error" *ngIf="srv.myform.get('description')?.touched &&
            srv.myform.get('description')?.invalid||(submitted&& srv.myform.get('description').invalid)
                " >{{'TcktFrmInfoInpt004ErrMsg'|translate}}</small>

            <p-message *ngIf="
                    (!description.value &&
                    srv.myform.get('description')?.touched)||(submitted&& srv.myform.get('description').invalid)
                " severity="error" text="{{'TcktFrmInfoInpt004ErrMsg'|translate}}"></p-message>
            <!-- <aside class=" text-end" id="count3">0/2500</aside> -->
        </div>
    </div>

    <div class="col-12 d-flex  justify-content-center">
        <div class="banner-btn rounded-pill">
            <button pTooltip="Go back for previous step" type="button"
             showDelay="1000" tooltipPosition="left"
              *ngIf="srv.myform.get('eventType').value" pButton pRipple
               class="rounded-pill cancel-btn" (click)="previous()"><i 
               class="pi mt-1" 
               [class.pi-angle-right]="document.documentElement.dir === 'rtl'"
               [class.pi-angle-left]="document.documentElement.dir === 'ltr'"
              ></i> {{'PrviosBtn'|translate}} </button>

            <button pTooltip="Cancel and dismiss changes" type="button" showDelay="1000" tooltipPosition="bottom" pButton pRipple type="submit"  class="rounded-pill save-btn text-center ms-1"
                (click)="cancel()">{{'CanclBtn'|translate}}<span></span></button>
            <button pTooltip="Save and go to next step" showDelay="1000" tooltipPosition="right" pButton pRipple
             type="submit" class="rounded-pill next-btn  ms-1 mt-md-0 mt-1">{{'NextBtn'|translate}} <i  class="pi mt-1" 
             [class.pi-angle-right]="document.documentElement.dir === 'ltr'"
             [class.pi-angle-left]="document.documentElement.dir === 'rtl'"
              ></i><span></span></button>
        </div>
    </div>
</form>
