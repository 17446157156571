<form [formGroup]="srvform.form" (submit)="submit()" class="row" autocomplete="off">
    <div class="col-12">
        <h4>{{'OrgnzrFrmScialLnksTitl001'|translate}}</h4>
    </div>

    <div class="p-input-filled mb-3 col-md-4 col-12">
        <span class="p-float-label">
            <input type="text" formControlName="phoneNumber" id="phoneNumber" pInputText class="form-control" [ngClass]="{
                        'ng-invalid ng-dirty':
                            (srvform.form.get('phoneNumber')?.touched &&
                            srvform.form.get('phoneNumber')?.invalid) || (srvform.form.get('phoneNumber')?.invalid && submitted)
                    }" [class.ng-invalid.ng-dirty] />
            <label class="font-size-14" for="phoneNumber">{{'OrgnzrFrmScialLnksTitl001Inpt001'|translate}}</label>
        </span>
        <small class="p-error" *ngIf="(srvform.form.get('phoneNumber')?.touched &&
        srvform.form.get('phoneNumber')?.invalid) || (srvform.form.get('phoneNumber')?.invalid && submitted)">
            {{'OrgnzrFrmScialLnksTitl001Inpt001ErrMsg'|translate}}
        </small>
    </div>
    <div class="p-input-filled mb-3 col-md-4 col-12">
        <span class="p-float-label">
            <input type="email" formControlName="email" id="email" pInputText class="form-control" [ngClass]="{
                        'ng-invalid ng-dirty':
                        (srvform.form.get('email')?.touched &&
                        srvform.form.get('email')?.invalid) || (srvform.form.get('email')?.invalid && submitted)
                    }" [class.ng-invalid.ng-dirty] />
            <label class="font-size-14" for="email">{{'OrgnzrFrmScialLnksTitl001Inpt002'|translate}}</label>
        </span>
        <small class="p-error" *ngIf="(srvform.form.get('email')?.touched &&
        srvform.form.get('email')?.invalid) || (srvform.form.get('email')?.invalid && submitted)
            ">{{'OrgnzrFrmScialLnksTitl001Inpt002ErrMsg'|translate}}</small>
    </div>
    <div class="p-input-filled mb-3 col-md-4 col-12">
        <span class="p-float-label">
            <input type="text" formControlName="website" id="website" pInputText class="form-control" [ngClass]="{
                        'ng-invalid ng-dirty':
                            (srvform.form.get('website')?.touched &&
                            srvform.form.get('website')?.invalid) || (srvform.form.get('website')?.invalid && submitted)
                    }" [class.ng-invalid.ng-dirty] />
            <label class="font-size-14" for="website">{{'OrgnzrFrmScialLnksTitl001Inpt003'|translate}}</label>
        </span>
        <small class="p-error"
            *ngIf="(srvform.form.get('website')?.touched &&
        srvform.form.get('website')?.invalid) || (srvform.form.get('website')?.invalid && submitted)">{{'OrgnzrFrmScialLnksTitl001Inpt003ErrMsg'|translate}}</small>
    </div>
    <div class=" col-12">
        <h4>{{'OrgnzrFrmScialLnksTitl002'|translate}}</h4>
    </div>
    <form [formGroup]="srvform.linksForm" (submit)="check()" autocomplete="off">
        <div class="row">
            <div class="p-input-filled col-lg-4 col-md-4 col-12">
                <span class="p-float-label">
                    <p-dropdown formControlName="linktype" autoWidth="false" [style]="{'width':'100%'}"
                        [options]="srvform.linkData" optionLabel="name" optionValue="attrId" [ngClass]="{
            'ng-invalid ng-dirty':
                (srvform.linksForm.get('linktype')?.touched &&
                srvform.linksForm.get('linktype')?.invalid) || ( submittedForm &&
                srvform.linksForm.get('linktype')?.invalid)
        }" placeholder="linktype">
                        <ng-template let-item pTemplate="selectedItem">
                            <div class="flex align-items-center gap-2">
                                <i class="{{item.misc01}}"></i>
                                <div>{{ item.name }}</div>
                            </div>
                        </ng-template>
                        <ng-template let-item pTemplate="item">
                            <div class="flex align-items-center gap-2">
                                <i class="{{item.misc01}}"></i>
                                <div>{{ item.name }}</div>
                            </div>
                        </ng-template>

                    </p-dropdown>
                    <label class="font-size-14" for="linktype">{{'OrgnzrFrmScialLnksTitl002Inpt001'|translate}}</label>
                </span>
                <small class="p-error" *ngIf="(srvform.linksForm.get('linktype')?.touched &&
        srvform.linksForm.get('linktype')?.invalid) || ( submittedForm &&
        srvform.linksForm.get('linktype')?.invalid)">{{'OrgnzrFrmScialLnksTitl002Inpt001ErrMsg'|translate}}</small>

            </div>
            <div class="p-input-filled col-lg-7 col-md-6 col-12">
                <span class="p-float-label">
                    <input type="text" id="linkurl" [ngClass]="{
                    'ng-invalid ng-dirty':
                    (srvform.linksForm.get('linkurl')?.touched &&
                    srvform.linksForm.get('linkurl')?.invalid) || ( submittedForm &&
                    srvform.linksForm.get('linkurl')?.invalid)
                }" pInputText class="form-control" formControlName="linkurl" />
                    <label class="font-size-14"
                        for="linkurl">{{'OrgnzrFrmScialLnksTitl002Inpt001Chois001'|translate}}</label>
                </span>
                <small class="p-error" *ngIf="(srvform.linksForm.get('linkurl')?.touched &&
                    srvform.linksForm.get('linkurl')?.invalid) || ( submittedForm &&
                    srvform.linksForm.get('linkurl')?.invalid)
                ">{{'OrgnzrFrmScialLnksTitl002Inpt002ErrMsg'|translate}}</small>
            </div>
            <div class="col-lg-1 col-md-2 col-12 text-md-end text-center ">
                <button class="rounded-pill save-btn ms-1">{{'Add'|translate}}</button>
            </div>
        </div>
        <div class="row mt-4" id="moreQuestion" *ngIf="srvform.links.length > 0">

            <div class="col-12 border-bottom border-primary border-opacity-50">
                <div class="row d-md-flex d-none ">
                    <div class="col-md-6 ">
                        <h5>{{'OrgnzrFrmScialLnksTitl003Dsc001'|translate}}</h5>
                    </div>
                    <div class="col-md-3 col-3">
                        <h5>{{'OrgnzrFrmScialLnksTitl003Dsc002'|translate}}</h5>
                    </div>
                    <div class="col-md-2 col-3">
                        <h5>{{'OrgnzrFrmScialLnksTitl003Dsc003'|translate}}</h5>
                    </div>
                </div>
            </div>
            <div>
                <div *ngFor="
                    let item of srvform.links;
                    let i = index
                " class="col-12  ">
                    <div
                        class="row text-md-start text-center align-items-center border border-primary rounded-4 border-opacity-50 bg-primary-opacity">
                        <div class="col-md-6 col-12 ">

                            <h4 class="d-md-none d-block">URL:</h4>

                            <span>
                                {{
                                item.linkurl
                                }}
                            </span>
                        </div>
                        <div class="col-md-3 col-12">
                            <h4 class="d-md-none d-block">Type</h4>
                            <span>
                                {{
                                item.name
                                }}
                            </span>
                        </div>

                        <div class="col-md-3 col-12">
                            <p-toast></p-toast>
                            <p-confirmPopup></p-confirmPopup>
                            <button class="btn btn-danger rounded-5" (click)="removeTag($event,i)" type="button"><i
                                    class="fa-regular fa-trash-can"></i></button>
                        </div>
                    </div>
                </div>
            </div>
        </div>




    </form>
    <div class=" d-flex  justify-content-center">
        <div class="banner-btn rounded-pill mx-1">
            <button class="rounded-pill cancel-btn" (click)="previous()"><i class="pi mt-1"
                    [class.pi-angle-right]="document.documentElement.dir === 'rtl'"
                    [class.pi-angle-left]="document.documentElement.dir === 'ltr'"></i> {{'PrviosBtn'|translate}}
                <span></span></button>
            <button class="rounded-pill save-btn ms-1" (click)="cancel()">{{'CanclBtn'|translate}}
                <span></span></button>
            <button class="rounded-pill save-btn  ms-1 mt-md-0 mt-1">{{'SavBtn'|translate}} <span></span></button>
        </div>
    </div>
</form>