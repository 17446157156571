<form [formGroup]="Srv.clenderSteps" (ngSubmit)="submit()" autocomplete="off" >
    <div class="container">
            <div class="row mb-2">
                <div class="p-input-filled col-sm-12 col-md-12  mb-2">
                    <span class="p-float-label">
                        <p-dropdown inputId="occuringtype"
                        pTooltip="{{'ShdlrFrmTitl003Slct001Pop'|translate}}"
                         tooltipPosition="bottom"  showDelay="1000"  formControlName="occuringtype" appendTo="body" [autoZIndex]="true" [baseZIndex]="100"
                            [styleClass]="'w-100'" [autoDisplayFirst]="false" [options]="OccursTypeOption"
                            optionLabel="name" optionValue="id" (onChange)="getOccuring($event)"
                            [ngClass]="{
                                'ng-invalid ng-dirty':
                                    (Srv.clenderSteps.get('occuringtype')?.touched &&
                                    Srv.clenderSteps.get('occuringtype')?.invalid)||(submitted&& Srv.clenderSteps.get('occuringtype').invalid)
                            }"></p-dropdown>
                        <label class="font-size-14" for="occuringtype">{{'ShdlrFrmTitl003Slct001'|translate}}</label>
                    </span>
                    <small class="p-error" *ngIf="(Srv.clenderSteps.get('occuringtype')?.touched &&
                    Srv.clenderSteps.get('occuringtype')?.invalid)||(submitted&& Srv.clenderSteps.get('occuringtype').invalid)
                        ">{{'ShdlrFrmTitl003Slct001ErrMsg01'|translate}} </small>
                        <small class="p-error" *ngIf="evSrv.ParentId!=''&&submitted
                            ">  {{'ShdlrFrmTitl003Slct001ErrMsg02'|translate}}
                        </small>
                </div>
            </div>
            <div class="row mb-2">
                <div class=" p-input-filled col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 mb-2" *ngIf="Srv.clenderSteps.get('occuringtype').value=='WEEKLY'">
                    <span class="p-float-label">
                        <p-multiSelect #dtObject appendTo="body"
                        pTooltip="Select day that you want to reccuring thisevent in it"
                        tooltipPosition="bottom" showDelay="1000"  [options]="WeeklyOccursOption" formControlName="weeklyOccuringOption"
                            [styleClass]="'w-100'" optionLabel="name" optionValue="id" display="chip"
                            [ngClass]="{
                                'ng-invalid ng-dirty':
                                    (Srv.clenderSteps.get('weeklyOccuringOption')?.touched &&
                                    Srv.clenderSteps.get('weeklyOccuringOption')?.invalid)||(submitted&& Srv.clenderSteps.get('weeklyOccuringOption').invalid)
                            }" ></p-multiSelect>
                        <label class=" font-size-14" for="time">{{'CalenderStep2lable1'|translate}}</label>
                    </span>
                    <small class="p-error" *ngIf="(Srv.clenderSteps.get('weeklyOccuringOption')?.touched &&
                    Srv.clenderSteps.get('weeklyOccuringOption')?.invalid)||(submitted&& Srv.clenderSteps.get('weeklyOccuringOption').invalid)
                        ">{{'CalenderStep2lable2'|translate}}</small>
                </div>
                <div class=" p-input-filled col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 mb-2  mb-2"
                *ngIf="Srv.clenderSteps.get('occuringtype').value=='MONTHLY'">
                    <span class="p-float-label">
                        <p-dropdown
                        pTooltip="Select the day of month that you want to reccuring event on it"
                         tooltipPosition="bottom" showDelay="1000"  appendTo="body" [styleClass]="'w-100'"
                         formControlName="monthlyOccuringOption"
                            [autoDisplayFirst]="false" [options]="monthlyOccuringOption" optionLabel="name"
                            optionValue="id"   [ngClass]="{
                                'ng-invalid ng-dirty':
                                    (Srv.clenderSteps.get('monthlyOccuringOption')?.touched &&
                                    Srv.clenderSteps.get('monthlyOccuringOption')?.invalid)||(submitted&& Srv.clenderSteps.get('monthlyOccuringOption').invalid)
                            }"  ></p-dropdown>
                        <label class=" font-size-14" for="time2">{{'CalenderStep2lable3'|translate}}</label>
                    </span>
                    <small class="p-error" *ngIf="(Srv.clenderSteps.get('monthlyOccuringOption')?.touched &&
                    Srv.clenderSteps.get('monthlyOccuringOption')?.invalid)||(submitted&& Srv.clenderSteps.get('monthlyOccuringOption').invalid)
                        ">{{'CalenderStep2lable4'|translate}}</small>
                </div>
            </div>
            <div class="row mb-2"  [formGroup]="Srv.cutomOption">
                <div class=" p-input-filled col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6 mb-2  mb-2"
                *ngIf="Srv.clenderSteps.get('occuringtype').value=='Custom'" >
                    <span class="p-float-label">
                        <p-inputNumber inputId="inputnumber2" showDelay="1000"  pTooltip="" tooltipPosition="left" [formControl]="Srv.cutomOption.get('number')" [styleClass]="'w-100'"
                        class="form-control m-0 p-0" [inputStyleClass]="'form-control'"
                        [ngClass]="{
                            'ng-invalid ng-dirty':
                                (Srv.clenderSteps.get('OcurinngEnd')?.touched &&
                                Srv.clenderSteps.get('OcurinngEnd')?.invalid)||(submitted&& Srv.clenderSteps.get('OcurinngEnd').invalid)
                        }" ></p-inputNumber>
                        <label class=" font-size-14" for="time2">{{'CalenderStep2lable5'|translate}} </label>
                    </span>
                    <small class="p-error" *ngIf="(Srv.clenderSteps.get('monthlyOccuringOption')?.touched &&
                    Srv.clenderSteps.get('monthlyOccuringOption')?.invalid)||(submitted&& Srv.clenderSteps.get('monthlyOccuringOption').invalid)
                        ">{{'CalenderStep2lable4'|translate}}</small>
                </div>
                <div class=" p-input-filled col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6 mb-2  mb-2"
                    *ngIf="Srv.clenderSteps.get('occuringtype').value=='Custom'" >
                    <span class="p-float-label">
                        <p-dropdown
                        pTooltip="Select the day of month that you want to reccuring event on it"
                         tooltipPosition="bottom" showDelay="1000"  appendTo="body" [styleClass]="'w-100'" [formControl]="Srv.cutomOption.get('option')"
                            [autoDisplayFirst]="false" [options]="CustomOption" optionLabel="name"
                            optionValue="id"   [ngClass]="{
                                'ng-invalid ng-dirty':
                                    (Srv.clenderSteps.get('monthlyOccuringOption')?.touched &&
                                    Srv.clenderSteps.get('monthlyOccuringOption')?.invalid)||(submitted&& Srv.clenderSteps.get('monthlyOccuringOption').invalid)
                            }"  ></p-dropdown>
                        <label class=" font-size-14" for="time2">{{'CalenderStep2lable6'|translate}}</label>
                    </span>
                    <small class="p-error" *ngIf="(Srv.clenderSteps.get('monthlyOccuringOption')?.touched &&
                    Srv.clenderSteps.get('monthlyOccuringOption')?.invalid)||(submitted&& Srv.clenderSteps.get('monthlyOccuringOption').invalid)
                        ">{{'CalenderStep2lable4'|translate}}</small>
                </div>
            </div>
        </div>
        <div class="col-12 d-flex  justify-content-center">
            <div class="banner-btn rounded-pill mx-1">
                <button  class="rounded-pill cancel-btn"
                pTooltip="Go back to previous step " type="button" showDelay="1000"  tooltipPosition="left"
                    (click)="previous()"><i class="pi mt-1"
                    [class.pi-angle-right]="document.documentElement.dir === 'rtl'"
                    [class.pi-angle-left]="document.documentElement.dir === 'ltr'"></i> {{'PrviosBtn'|translate}}   <span></span></button>
                <button
                pTooltip="Cancel and dismiss changes" type="button" showDelay="1000"  tooltipPosition="bottom" class="rounded-pill save-btn ms-1"
                    (click)="cancel()">{{'CanclBtn'|translate}} <span></span></button>
                <button
                pTooltip="Save changes and go to next step " showDelay="1000"  tooltipPosition="right"
                 type="submit"  class="rounded-pill next-btn  ms-1 mt-md-0 mt-1">{{'NextBtn'|translate}}<i class="pi mt-1"
                 [class.pi-angle-right]="document.documentElement.dir === 'ltr'"
                 [class.pi-angle-left]="document.documentElement.dir === 'rtl'"></i><span></span></button>
            </div>
        </div>
</form>
