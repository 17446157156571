import { DOCUMENT } from '@angular/common';
import { Component, OnInit, Inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { PromoteFormStepsService } from 'src/app/services/promote-form-steps.service';
import { PromoteService } from 'src/app/services/promote.service';

@Component({
    selector: 'app-step2',
    templateUrl: './step2.component.html',
    styleUrls: ['./step2.component.scss']
})
export class Step2Component implements OnInit {

    hiddenTickets !: any[];
    visibleTickets !: any[];
    visibleAddOns !: any[];
    hiddenAddOns !: any[];
    ticketId: string;
    submitted: boolean;
    selectedTickets: any;
    tickets!: any[];
    addOns!: any[];

    constructor(private router: Router, private activatedRoute: ActivatedRoute, public prosrvform: PromoteFormStepsService, private promoteservice: PromoteService, @Inject(DOCUMENT) public document: Document) {


    }

    ngOnInit(): void {

        this.ticketId = this.activatedRoute.parent.parent.parent.snapshot.paramMap.get('id')
        this.promoteservice.getAllTickets(this.ticketId).subscribe(x => {
         //   console.log(x)
            this.tickets = x;
            this.visibleTickets = x.filter(x => x.visable == false && x.type == 'Paid').map(y => { return { code: y.groupId, name: y.name }; });
            this.hiddenTickets = x.filter(x => x.visable == true).map(y => { return { code: y.groupId, name: y.name }; });


        });
        this.promoteservice.getAllAddons(this.ticketId).subscribe(x => {
          //  console.log(x)
            this.addOns = x;
            this.visibleAddOns = x.filter(x => x.hidden == false).map(y => { return { code: y.id, name: y.name }; });
            this.hiddenAddOns = x.filter(x => x.hidden == true).map(y => { return { code: y.id, name: y.name }; });


        });




    }




    submit() {
        (this.validateStep2())
        if (this.validateStep2()) {
            this.prosrvform.isHidden = this.prosrvform.promoteCodeForm.get('isHidden').value;
            this.router.navigate(['step3'], { relativeTo: this.activatedRoute.parent });
            this.selectedTickets = [...this.prosrvform.promoteCodeForm.controls["tickets"].value];
            this.selectedTickets.forEach(e => {
                const price = this.tickets.find(x => x.groupId == e)?.price
                if (this.prosrvform.maxDiscount > price) {
                    this.prosrvform.maxDiscount = price;
                }
            });
        }

        this.submitted = true


    }
    cancel() {
        this.prosrvform.clear();
        this.router.navigate(['Promo-codes'], { relativeTo: this.activatedRoute.parent.parent.parent });
        // this.router.navigate([
        //   {
        //     outlets: { 'Promot-steps': ['promo-codes-all'] }
        //   }
        // ],{relativeTo: this.activatedRoute.parent.parent});
    }
    previous() {
        this.router.navigate(['step1'], { relativeTo: this.activatedRoute.parent });

    }
    validateStep2() {

       // (this.prosrvform.promoteCodeForm)
        return !this.prosrvform.promoteCodeForm.hasError('ibra')
    }


}
