import { Injectable, OnInit } from '@angular/core';
import {  AbstractControl, FormBuilder, FormGroup,  ValidatorFn,  Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { DropDownItem } from '../Interfaces/DropDownItem';
import { MultiplepromoteFormdto, promoteFormdto } from '../Interfaces/promoteData';
import { PromoteService } from './promote.service';
import { TicketsService } from './tickets.service';
import { DatePipe } from '@angular/common';

@Injectable({
    providedIn: 'root'
})
export class PromoteFormStepsService {
    maxDiscount : number = 9999999;
    id !: string;
    minDateValue!: Date;
    maxDateValue!: Date;
    promoteCodeForm !: FormGroup;
    isEditing !: boolean;

    tickets !: any[];
    isPercent !: boolean;
    hiddenTickets !: any[];
    visibleTickets !: any[];
    Tickets !: any[];
    parentId !: string //eventId

    isHidden !: boolean
    confirmdate(e) {
        var t=e;
        var lm=new Date(t)
        this.maxDateValue = new Date( lm.setMinutes(lm.getMinutes()+15));//15
       if(this.promoteCodeForm.controls['endDate'].value==null){
        this.promoteCodeForm.controls['endDate'].value.setValue( this.datePipe.transform(this.maxDateValue, 'MM/dd/yyyy hh:mm a'));
       }
       // this.maxDateValue = new Date(new Date(e).setMinutes(new Date(e).getMinutes()+15));
    }

    ticketLimit: DropDownItem[] = [
        {
            id: "1",
            name: "Limited"
        }
        ,
        {
            id: "0",
            name: "Unlimited"
        }
    ]



    Initializers(id: string, name: string[], isPercent: boolean, eventId: string, quantity: number, discountAmount: number, startDdate: Date|string, endDate: Date, limit: string, isHidden: boolean, tickets: string[],addons:string[],used:number) {
        this.promoteCodeForm = this.fb.group({
            id: [id,],
            isMultiple: [false,],
            names: [],
            isPercent: [isPercent],
            name: [name, [Validators.required , Validators.pattern(/^\S*$/)]],
            eventId: [eventId],
            quantity: [quantity , [Validators.required , Validators.min(used)]],
            fileUpload: [],
            startDate: [startDdate, Validators.required],
            endDate: [endDate, [Validators.required,this.validPromoEnd('startDate')]],
            isHidden: [isHidden],
            discountAmount: [discountAmount, [Validators.required , Validators.min(1) , Validators.max(this.maxDiscount)]],
            limit: [limit, Validators.required],
            tickets: [tickets, ],
            addons: [addons],
        },{validators:this.ValidationofTicketsAndaddons()})

        this.promoteCodeForm.controls.isHidden.valueChanges.subscribe(x => {
            this.promoteCodeForm.get('tickets').setValue([]);
        })
        this.promoteCodeForm.get('startDate').valueChanges.subscribe(x=>
            {
                this.promoteCodeForm.controls['endDate'].updateValueAndValidity();
              //  this.promoteCodeForm.controls['endDate'].;
            })

       this.promoteCodeForm.controls['tickets'].valueChanges.subscribe(x => {
                this.promoteCodeForm.updateValueAndValidity()
            })
    this.promoteCodeForm.controls['addons'].valueChanges.subscribe(x => {
                this.promoteCodeForm.updateValueAndValidity()
            })
    }



    ValidationofTicketsAndaddons():ValidatorFn{
        return (group: FormGroup)=>{
                 return (group?.controls['tickets'].value.length!=0||group?.controls['addons'].value.length!=0)?null:{ibra:true}
              }
      }
    validPromoEnd(param: string): ValidatorFn {
        return (control: AbstractControl) => {
            var s = new Date(control?.value);
            var e = new Date(control?.parent?.controls[param].value);
            return s > e ? null : { isValidPro: true }
        }
    }


    constructor(private tikserv: TicketsService,private datePipe: DatePipe,
         private fb: FormBuilder, private promoteserv: PromoteService, private activerout: ActivatedRoute) {
            var l=this.datePipe.transform(new Date(new Date(new Date(Date.now()).setHours(0)).setMinutes(0)), 'MM/dd/yyyy hh:mm a')
         //   var l=this.datePipe.transform(new Date(new Date(new Date(Date.now()).setHours(0)).setMinutes(0)), 'MM/dd/yyyy hh:mm a')
            this.Initializers("0", null, false, null, 0, 0,l , null, null, false, [],[],0);
        this.minDateValue=new Date(new Date(new Date(Date.now()).setHours(0)).setMinutes(0))

    }





    clear() {
        // this.promoteCodeForm.reset()
        this.Initializers("0", null, false, null, 0, 0, this.datePipe.transform(new Date(new Date(new Date(Date.now()).setHours(0)).setMinutes(0)), 'MM/dd/yyyy hh:mm a'), null, null, false, [],[],0);

    }

    setLimit(param1: number) {
        if (param1 == 0) {
            return '0'
        }
        return '1'
    }



    //to check if percent or not
    // check(){
    //   if(this.promoteCodeForm.get("discountAmount").value >0){

    //   this.promoteCodeForm.get("percentAmount").disable();
    //   this.isPercent = false;
    //   }
    //   if(this.promoteCodeForm.get("discountAmount").value ==null){
    //     this.promoteCodeForm.get("percentAmount").enable()
    //   }
    //   if(this.promoteCodeForm.get("percentAmount").value ==null){
    //     this.promoteCodeForm.get("discountAmount").enable()
    //   }
    //   if(this.promoteCodeForm.get("percentAmount").value > 0){

    //     this.promoteCodeForm.get("discountAmount").disable();
    //     this.isPercent = true;
    //   }

    // }

    finish(): void {

        var data: promoteFormdto = {
            id: this.promoteCodeForm.get("id").value,
            names: this.promoteCodeForm.get("isMultiple").value ? this.promoteCodeForm.get("names").value : [this.promoteCodeForm.get("name").value],
            eventId: this.promoteCodeForm.get("eventId").value,
            quantity: (this.promoteCodeForm.get("quantity").value == "") ? 0 : this.promoteCodeForm.get("quantity").value,
            tickets: this.promoteCodeForm.get("tickets").value,
            addons: this.promoteCodeForm.get("addons").value,
            startDate: this.promoteCodeForm.get("startDate").value,
            endDate: this.promoteCodeForm.get("endDate").value,
            isPercent: this.promoteCodeForm.get("isPercent").value,
            limit: (this.promoteCodeForm.get("limit").value) == "0" ? 0 : this.promoteCodeForm.get("quantity").value,
            discountAmount: this.promoteCodeForm.get("discountAmount").value,
            hidden: this.promoteCodeForm.controls["isHidden"].value



        }


      ////  console.log(data);
        this.promoteserv.postPromote(data);





    }

}
