<div class="container py-3 ">
    <div class="card custom-table-border">
        <!-- <div class="card-header text-center bg-primary rounded-top-23">

            <h1 class=" text-white mt-2"> Order preview</h1>

        </div> -->
        <div class="card-header border-0 text-center bg-primary rounded-top-23 ">
            <h1 class="fw-bold text-white mt-2">{{'OrdrPrvewTitl'|translate}}</h1>
            </div>
        <!-- <div *ngIf="loader.show1" @fadeOut class="d-flex justify-content-center align-items-center" style="border-radius: 25px;top : 0;position: absolute;z-index: 1050;background-color:#fff;width: 100%;height: -webkit-fill-available;right: 0;bottom: 0;left: 0;"> -->


        <!-- </div> -->
        <div class="card-body">
            <div class="card-body p-0">
                <div class="row">

                </div>
                <div class="row revers-cols">
                    <div class="col-xl-8 col-12 ">
                        <div >
                            <h4 class="fw-bold">{{'OrdrPrvewDsc001'|translate}} </h4>
                        </div>
                        <div *ngIf="data.tickets.length!=0" class="row  ps-2">
                            <div class="col-12 mb-md-2 d-flex justify-content-between">
                                <h5>{{'OrdrPrvewDsc001Titl001'|translate}} </h5>
                            </div>
                            <div class=" col-12 p-0 pointer " [ngClass]="{'card-base':data.tickets.length>1}">

                                <div class=" py-1" *ngFor="let item of data.tickets">
                                    <div class="ticket">
                                        <div class=" py-2 mx-2 mb-2 row">
                                            <div class="col-sm-4 col-12 ">
                                                <div class="ps-sm-3">
                                                    <span class="text-primary d-flex">
                                                        <i class="fa-light fa-ticket pe-1 "></i>
                                                        <p class="text-black w-100 pe-1">
                                                            {{item.name}}
                                                        </p>
                                                    </span>
                                                </div>
                                            </div>
                                            <div class="col-sm-5 col-12">
                                                <div class="d-flex flex-row justify-content-between">
                                                    <span class=" text-primary d-block ">
                                                        <i class="fa-light fa-hashtag"></i>{{'OrdrPrvewCrdTitl001'|translate}}
                                                        <span class=" text-black">
                                                            {{item.qty}}
                                                        </span>
                                                    </span>
                                                    <span class=" text-primary d-block">
                                                        <i class="fa-light fa-truck"></i>
                                                        {{item.deliveryMethods}}
                                                    </span>
                                                </div>
                                            </div>
                                            <div class="col-sm-3 col-12">
                                                <div class="text-sm-end pe-sm-3">
                                                    <span class="   text-primary d-block">
                                                        <i class="fa-light fa-sack-dollar pe-1"></i>{{'OrdrPrvewCrdTitl003'|translate}}:
                                                        <span class="text-black">
                                                            {{item.finalPrice+""+data.curSymbol}}
                                                        </span>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class=" d-flex justify-content-center mt-lg-3">
                                <h5 class="  d-block">
                                    <i class="fa-light fa-sack-dollar"></i> {{'OrdrPrvewTotlAmnt'|translate}}:
                                </h5>
                                <h5 class="  d-block ps-1"> {{data.tickets.length!=0?
                                    getTotal(data.tickets)+ ""+data.curSymbol : ''}} </h5>
                            </div>
                        </div>
                        <div *ngIf="data.addons.length!=0"  class="row ps-2">
                            <div class="col-12 mb-md-2 d-flex justify-content-between">
                                <h5>{{'OrdrPrvewDsc001Titl002'|translate}} </h5>
                            </div>
                            <div class="col-12 p-0" [ngClass]="{'card-base':data.addons.length>1}">
                                <div class=" py-1" *ngFor="let item of data?.addons">
                                    <div class="card-2-modified2 pointer py-2 mx-1 mb-2 row align-items-center">
                                        <div class=" col-sm-4 col-12 ">
                                            <div class=" text-center ms-lg-1 text-md-start">
                                                <img [src]="
                                                item.photo==null?'assets/img/courses/3SUBiaP.png':url+item.photo"
                                                    [alt]="item.name" width="100" class="shadow-4 rounded-addImage" />
                                            </div>
                                        </div>
                                        <div class="col-sm-4 col-12 ">
                                            <div class=" flex-column ">
                                                <span>
                                                    <b>{{item.name}}</b>
                                                </span>
                                                <span class=" text-primary d-block text-center d-flex
                                                align-items-center">
                                                    <i class="fa-light fa-truck pe-1 "></i>
                                                    <b> {{item.deliveryMethods}}</b>
                                                </span>
                                            </div>
                                        </div>
                                        <div class="col-sm-4 col-12">
                                            <div class="d-inline-flex flex-column ">
                                                <span class=" text-primary d-block">
                                                    <i class="fa-light fa-hashtag"></i><b>{{'OrdrPrvewCrdTitl001'|translate}}:</b>
                                                    <span class="text-black fw-bold">{{item.qty}}</span>
                                                </span>
                                                <span class="   text-primary d-block">
                                                    <i class="fa-light fa-sack-dollar pe-1"></i><b>{{'OrdrPrvewCrdTitl003'|translate}}:</b>
                                                    <span class="text-black fw-bold">
                                                        {{item.finalPrice+""+data.curSymbol}}
                                                    </span>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class=" d-flex justify-content-center mt-lg-3">
                                <h5 class="d-block">
                                    <i class="fa-light fa-sack-dollar"></i> {{'OrdrPrvewTotlAmnt'|translate}}:
                                </h5>
                                <h5 class=" d-block"> {{data.addons.length !=0 ?
                                    getTotal(data.addons)+""+data.curSymbol : ''}} </h5>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-4 col-12 ">
                        <div class="row">
                            <div class="col-12">
                                <img [src]="data.eventPhoto==null?'assets/img/courses/3SUBiaP.png':url+data.eventPhoto"
                                    width="100%" alt="data.eventName" class="shadow-4 rounded-addImage" />
                            </div>

                            <div class="col-12 ">
                                <h4 class=" fw-bold ">
                                    <i class="fa-regular fa-calendar-star text-primary"></i>  {{data.eventName}}
                                </h4>
                            </div>
                            <div class="col-12">
                                <h5> {{'OrdrPrvewDsc002'|translate}} </h5>
                            </div>
                            <div class="col-12 d-flex justify-content-between fss">
                                <span class="text-primary d-block"> <i
                                        class="fa-solid fa-calendar-days text-primary"></i>
                                    {{'OrdrPrvewDsc002Titl001'|translate}} :
                                </span>
                                <span class="d-block">
                                    {{getDateOntomeZoneformat2(data.orderDate)}} </span>
                            </div>
                            <div class="col-12 d-flex justify-content-between fss">
                                <span class="text-primary d-block"> <i class="fa-solid fa-credit-card text-primary"></i>
                                    {{'OrdrPrvewDsc002Titl002'|translate}} :
                                </span>
                                <span class="  d-block">
                                    {{'OrdrPrvewDsc002Titl002Dsc001'|translate}}
                                </span>
                            </div>
                            <div class="col-12 d-flex justify-content-between fss">
                                <span class="text-primary d-block"> <i class="fa-solid fa-sack-dollar"></i>
                                    {{'OrdrPrvewTotlAmnt'|translate}}:
                                </span>
                                <span class="  d-block">
                                    {{data.totalAmount+""+data.curSymbol}}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
