<form [formGroup]="Srv.clenderSteps" (ngSubmit)="submit()" autocomplete="off">
    <div class="container">
        <div class="row mb-2 ms-2">
            <span class="text-start mb-2 ms-3 "> {{'ShdlrFrmTitl001Dsc001'|translate}} </span>
            <div class="p-input-filled col-sm-5 col-md-5  mb-2">
                <span class="p-float-label">
                    <p-inputNumber inputId="inputnumber2" showDelay="1000"
                        pTooltip="{{'ShdlrFrmTitl001Dsc001Inpt001Pop'|translate}}" tooltipPosition="left"
                        formControlName="OcurinngEnd" [styleClass]="'w-100'" class="form-control m-0 p-0"
                        [inputStyleClass]="'form-control'" [ngClass]="{
                                'ng-invalid ng-dirty':
                                    (Srv.clenderSteps.get('OcurinngEnd')?.touched &&
                                    Srv.clenderSteps.get('OcurinngEnd')?.invalid)||(submitted&& Srv.clenderSteps.get('OcurinngEnd').invalid)
                            }"></p-inputNumber>
                    <label for="inputnumber2">{{'ShdlrFrmTitl001Dsc001Inpt001'|translate}}</label>
                </span>
                <small class="p-error" *ngIf="(Srv.clenderSteps.get('OcurinngEnd')?.touched &&
                    Srv.clenderSteps.get('OcurinngEnd')?.invalid&&Srv.clenderSteps.get('OcurinngEnd').errors.min)||(submitted&& Srv.clenderSteps.get('OcurinngEnd').invalid&&Srv.clenderSteps.get('OcurinngEnd').errors.min)
                        ">{{'ShdlrFrmTitl001Dsc001Inpt001ErrMsg01'|translate}} </small>
                <small class="p-error" *ngIf="(Srv.clenderSteps.get('OcurinngEnd')?.touched &&
                    Srv.clenderSteps.get('OcurinngEnd')?.invalid&&Srv.clenderSteps.get('OcurinngEnd').errors.max)||(submitted&& Srv.clenderSteps.get('OcurinngEnd').invalid &&Srv.clenderSteps.get('OcurinngEnd').errors.max)
                        ">{{'ShdlrFrmTitl001Dsc001Inpt001ErrMsg02'|translate}}
                    {{Srv.clenderSteps.get('OcurinngEnd')?.errors['max']?.max}} </small>
            </div>
        </div>
    </div>
    <div class="col-12 d-flex  justify-content-center">
        <div class="banner-btn rounded-pill mx-1">
            <!-- <button  class="rounded-pill cancel-btn"
                    (click)="previous()"><i class="pi pi-angle-left  mt-1"></i> Previous <span></span></button> -->
            <button class="rounded-pill save-btn ms-1" pTooltip="cancel and dismiss changes" type="button"
                tooltipPosition="left" showDelay="1000" (click)="cancel()">{{'CanclBtn'|translate}}
                <span></span></button>
            <button pTooltip="Save and go to next step" tooltipPosition="right" showDelay="1000"
                class="rounded-pill next-btn  ms-1 mt-md-0 mt-1">{{'NextBtn'|translate}} <i class="pi mt-1"
                    [class.pi-angle-right]="document.documentElement.dir === 'ltr'"
                    [class.pi-angle-left]="document.documentElement.dir === 'rtl'"></i><span></span></button>
        </div>
    </div>
</form>
