import { DOCUMENT, DatePipe } from '@angular/common';
import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, NonNullableFormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { C, cl } from '@fullcalendar/core/internal-common';
import { EventStepsService } from 'src/app/services/event-steps.service';
import { TicketFormStepsService } from 'src/app/services/ticket-form-steps.service';

@Component({
    selector: 'app-ticket-date',
    templateUrl: './ticket-date.component.html',
    styleUrls: ['./ticket-date.component.scss']
})
export class TicketDateComponent implements OnInit {

    getDateForCalender(e) {
        if (e) {
            return new Date(e).toLocaleTimeString()
        }
        return
    }

    getDateBoolean(date) {
        var d = new Date(date.year, date.month, date.day)




        return d.getFullYear() === this.MaxValue.getFullYear() &&
            d.getMonth() === this.MaxValue.getMonth() &&
            d.getDate() === this.MaxValue.getDate()

    }
    submitted: boolean = false;
    minDateValue!: Date;
    maxDateValue!: Date;
    MaxValue!: Date
    ticketFormDate!: FormGroup;
    durationType = [
        { name: 'Hour(s)', code: 0 },
        { name: 'Day(s)', code: 1 },
        { name: 'Week(s)', code: 2 },
        { name: 'Month(s)', code: 3 }
    ];
    duration = [
        { name: 'Before event starts', code: 0 },
        { name: 'After event starts', code: 1 },
        { name: 'Before event ends', code: 2 }
    ];
    constructor(private fb: NonNullableFormBuilder, private datePipe: DatePipe,
         @Inject(DOCUMENT) public document: Document,
        private router: Router, private activatedRoute: ActivatedRoute,
         public srv: TicketFormStepsService, 
         private EventSrv: EventStepsService) { }
    ngOnInit(): void {

      //  console.log(this.srv.myform.controls.eventType.value)
        this.srv.setsomeValidation(this.srv.myform.controls.eventType.value);
        this.EventSrv.state$.subscribe(x => {
            this.MaxValue = new Date(x.endTime)
        })

        this.minDateValue = new Date(new Date(new Date(Date.now()).setHours(0)).setMinutes(0))

        // this.minDateValue=new Date(new Date(Date.now()).setDate(new Date(Date.now()).getDate()-1))
    }

    confirmdate(e) {

        var t = e;
        var lm = new Date(t)

        //   this.maxDateValue = new Date(lm.setMinutes(lm.getMinutes()+15));
        this.maxDateValue = new Date(lm.setMinutes(lm.getMinutes() + 15));//15
        this.srv.myform.controls['endDate'].setValue(this.datePipe.transform(this.maxDateValue, 'MM/dd/yyyy hh:mm a'));
    }
    submit() {
        //  console.log( this.validateticketDate())
        //  console.log(this.srv.myform.value)
        if (this.validateticketDate()) {
            this.router.navigate(['settings'], { relativeTo: this.activatedRoute.parent });
        } else {
            this.submitted = true;
        }


    }
    previous() {
        this.router.navigate(['info'], { relativeTo: this.activatedRoute.parent });
    }
    cancel() {
        this.srv.clear()
        this.router.navigate(['tickets'], { relativeTo: this.activatedRoute.parent.parent.parent });
        // this.router.navigate([
        //     {
        //         outlets: { 'tickets-steps': ['tickets-all'] }
        //     }
        //   ],{relativeTo: this.activatedRoute.parent.parent});
    }

    getDurationType() {
        var m = "";

        switch (this.srv.myform.controls['priodType'].value) {
            case 0: m = 'hours';
                break;
            case 1: m = 'days';
                break;
            case 2: m = 'weeks';
                break;
            case 3: m = 'months';
                break;
        }
        return m

    }
    validateticketDate() {
        if (this.srv.myform.controls['eventType'].value) {

            return (this.srv.myform.controls['startDate'].valid
                && this.srv.myform.controls['startingPoint'].valid && this.srv.myform.controls['priodNumber'].valid && this.srv.myform.controls['priodType'].valid);
        } else {
            return (this.srv.myform.controls['endDate'].valid && this.srv.myform.controls['startDate'].valid)
        }

    }
}
