
<div class="  row align-items-center p-0">
    <p-toast></p-toast>
        <div class="card  border-0 col-xl-4 col-lg-5 col-12 m-0 p-0 login-tab-mob">
                <a class="navbar-brand d-lg-flex d-none" routerLink="/"><img 
                    src="{{LogoUrl}}"
                     class="logo-size-larg" alt="Antares"></a>
                     <!-- src="../../../../../assets/img/Logo/Antares - Logo H (Colored).png" -->
            <div class=" text-center card-title ">
                <h1 class="text-primary">{{'Change password'|translate}}</h1>
            </div>
            <div class="card-body">
    
                <form  [formGroup]="form" (submit)="submit()"  autocomplete="off">
    
                    <div class="row justify-content-center">
                        <div class="p-input-filled   col-12">
                <span class="p-float-label">
                    <input  type="email"  formControlName="email" class="form-control" pInputText id="email" [ngClass]="{
                        'ng-invalid ng-dirty':
                        form.get('email')?.touched &&
                        form.get('email')?.invalid
                    }" />
                    <label htmlFor="email">{{'Email'|translate}}</label>
                </span>
                            <small class="p-error" *ngIf="form.get('email')?.touched &&
                form.get('email')?.invalid ">{{'Please add valid value.'|translate}}</small>
                        </div>
                   </div>
                   <div class="d-flex justify-content-center mt-3">
                    <div class="banner-btn rounded-pill mx-2 text-center">
                        <button type="submit" pTooltip="Login page" class="rounded-pill login-btn" routerLink="/login" >{{'CanclBtn'|translate}}<span></span></button>
                    </div>
                   <div class="banner-btn rounded-pill mx-2 text-center">
                    <button  [disabled]="form.invalid||AuthService.isSubmitting" type="submit" pTooltip="Send email" showDelay="1000" tooltipPosition="right" class="rounded-pill save-btn {{AuthService.isSubmitting? 'bg-disabled' : ''}}">
                        <i  *ngIf="AuthService.isSubmitting" class="fas fa-spinner fa-spin"></i>
                       {{' Send email'|translate}} <span></span>
                    </button>
                </div>
                   </div>
                </form>

            </div>
        </div>
        <div class="col-xl-8 col-lg-7 d-lg-flex d-none m-0 p-0 img-fluid authentication-bg">
    
        </div>
    </div>
    