import { Component, ComponentFactoryResolver, OnInit, TemplateRef, Type, ViewChild, ViewContainerRef } from '@angular/core';
import { ImageCroppedEvent, LoadedImage } from 'ngx-image-cropper';
import { MessageService } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { CropperComponent } from 'src/app/components/shared/cropper/cropper.component';
import { TextEditorComponent } from 'src/app/components/shared/text-editor/text-editor.component';
import { UploadPhotoComponent } from 'src/app/components/shared/upload-photo/upload-photo.component';
import { BasicInfoComponent } from '../basic-info/basic-info.component';
import { DomSanitizer } from '@angular/platform-browser';
import { BypassHTMLPipe } from '../../../../../BypassHTML.pipe'
import { MyImageUploaderComponent } from 'src/app/components/shared/my-image-uploader/my-image-uploader.component';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { OnlineEventProfileService } from './online-event-profile.service';
import { EventStepsService } from 'src/app/services/event-steps.service';
import { environment as env} from '../../../../../../environments/environment'
import { transition, style, animate, trigger } from '@angular/animations';
import { LoaderService } from 'src/app/services/loader.service';
import {ActivatedRoute, Router} from '@angular/router';
const leaveTrans = transition(':leave', [
    style({
        opacity: 1
    }),
    animate('1s ease-out', style({
        opacity: 0
    }))
])



const fadeOut = trigger('fadeOut', [
    leaveTrans
]);


@Component({
    selector: 'app-online-event-Page',
    templateUrl: './online-event-Page.component.html',
    styleUrls: ['./online-event-Page.component.scss'],
    animations : [fadeOut]
})
export class OnlineEventPageComponent implements OnInit {
    croppedImage: any = '';
    imageChangedEvent : any;
    components = [];
    // tickets = [];
    get formDescription() {
        return this.onlineForm.get('description') as FormArray;
    }
    get formLinks() {
        return this.onlineForm.get('links') as FormArray;
    }
    onlineForm!: FormGroup;
    isSubmitting =false;
    // initialize Form
    initForm(id,eventId,descr,links) {
        this.onlineForm = this.fb.group({
            id: id,
            eventId:eventId,
            description: this.fb.array(descr),
            links: this.fb.array(links)
        })
    }
    removeItem(i) {
        this.formDescription.removeAt(i);
    }
    move(shift: number, i: number,): void {
        const { controls } = this.formDescription;
        [controls[i], controls[i + shift]] = [controls[i + shift], controls[i]];
        this.formDescription.patchValue(controls);
    }
    constructor(public loader : LoaderService,
        private fb: FormBuilder,
        private messageService: MessageService,
        public dialogService: DialogService,
        public _sanitizer: DomSanitizer,
        private srvProfile : OnlineEventProfileService,
        private srv : EventStepsService ,
        private router :Router , private activatedRoute : ActivatedRoute
    ) {}
    ngOnInit(): void {

        this.loader.showLoader1();
        this.initForm('0','0',[],[]);
        this.srv.state$.subscribe(y=>{


            this.initForm('0',y.id,[],[]);
            // this.srvProfile.getAllTickets(y['id']).subscribe(x=>{
            //    x.forEach(element => {
            //         this.tickets.push({code: element['groupId'],name: element['name']})
            //     });
            // })
            this.srvProfile.getOnlineEventProfile(y.id).subscribe(x=>{
               // console.log(x)
                if(x){
                    this.initForm(x['id'],x['eventId'],[],[]);
                    let c = JSON.parse(x['description']);
                    c.forEach(element => {
                    this.formDescription.push(this.fb.group(element))
                    });

                    if(x['links'].length > 0){
                        let c1 = JSON.parse(x['links']);
                        c1.forEach(element => {
                        this.formLinks.push(this.fb.group(element))
                        });
                    }

                    this.loader.hideLoader1();
                }
                this.loader.hideLoader1();
            },err=>
            console.log(err)
            )
        })
    }
    addComponent(type) {
        let comp = {};
        if(type === 'provider'){
            comp = this.fb.group({
                type: ['provider'],
                title: [''],
                id: [''],
                link: [''],
                editor: [''],
                image: ['']
                // tickets: [[]]
            });
        }
        else
        {
            comp = this.fb.group({
                value1: [''],
                value2: [''],
                type: [type]
            });
        }
        this.formDescription.push(comp);
    }
    makeItSafe(x) {
        let str = x;
        let index = str.indexOf('v');
        str = str.slice(index + 2, str.length);
        let url = 'https://www.youtube.com/embed/' + str;
        return this._sanitizer.bypassSecurityTrustResourceUrl(url);
    }
    submit(x){

        if (x === 1){
            this.isSubmitting = true;
            const x = this.onlineForm.value;
            const d = this.formDescription.controls.filter(x => x.get('type').value === 'provider');
            for (let index = 0; index < d.length; index++) {
                x.links.push(d[index].value);
            }
            x.description = JSON.stringify(x.description);
            this.srvProfile.postOnlineEventProfile(x).subscribe(id => {
                this.onlineForm.get('id').setValue(id);
                this.messageService.add({severity: 'success', summary: 'Saved Successfully'});
                this.isSubmitting =false;
                if (x)
                {
                    this.srv.addressBool = true;
                    this.srv.CalculateProgress();
                }
            });
        }
       else if (x == 2)
        {
            this.isSubmitting = true;
            const x = this.onlineForm.value;
            const d = this.formDescription.controls.filter(x => x.get('type').value === 'provider');
            for (let index = 0; index < d.length; index++) {
                x.links.push(d[index].value);
            }
            x.description = JSON.stringify(x.description);
            this.srvProfile.postOnlineEventProfile(x).subscribe(id => {
                this.onlineForm.get('id').setValue(id);
                this.messageService.add({severity: 'success', summary: 'Saved Successfully'});
                this.isSubmitting =false;
                if (x)
                {
                    this.srv.addressBool = true;
                    this.srv.CalculateProgress();
                }
                this.router.navigate(['tickets'],{relativeTo: this.activatedRoute.parent});
            });


        }
    }
}
