<!-- <div class=" mt-0">
    <p-toast></p-toast>
    <div class="d-lg-none d-block ">
        <p-sidebar [(visible)]="visibleSidebar1" position="left" [transitionOptions]="'0.3ms'" [baseZIndex]="10000">
            <div class=" d-lg-none d-block mb-2  bg-light  ">

                <ul style="list-style: none " class="p-0">
                    <li class="menu mb-2 border-bottom d-flex justify-content-start">
                        <button type="button" class="btn" [routerLink]="hasPerant? ['/events/manage-event/', Parent] : ['events'] "><i
                                class="fa-solid fa-arrow-left me-2"></i>Back to events</button>
                    </li>
                    <li></li>
                    <li class="d-flex align-items-center mb-3  border-bottom">
                        <div>
                            <p-knob [size]="70" [(ngModel)]="srv.progressValue"  [readonly]="true"></p-knob>
                        </div>
                        <div class="p-input-filled ms-2 d-flex align-items-center ">
                          {{srv.progressValue/20}}   of 5 steps to complete
                        </div>
                    </li>
                    <li>
                        <h5><span class="fw-bold text-break"> {{eventTitle}}</span></h5>
                    </li>
                    <li>{{ srv.TimingBool? eventDate : ""}}</li>
                    <li class="border-bottom"><a Class=" text-primary" [routerLink]="['/','event',eventId]"><i
                                class="pi pi-eye"></i> Preview your event</a></li>
                    <li>
                        <ul style="list-style: none;" class="items-1-ul">
                            <ng-container *ngFor="let item of items">
                                <li *ngIf="item.visible" class="border-bottom menu-items-modif">

                                    <a (click)="itemClicked(item)" href="javascript:;" class="d-flex justify-content-between"> <span><i [class]="item.icon"></i> {{item.label}}</span>
                                        <i *ngIf="item.completed" class="fa-regular fa-circle-check text-success"></i>
                                    </a>

                                </li>
                                <ul style="list-style: none;" class="items-1-ul ms-3">
                                    <li *ngFor="let s of item.items" class="border-bottom menu-items-modif ">
                                        <a (click)="itemClicked(s)" [disabled]="s.clicked" [routerLink]="s.routerLink"> <i [class]="s.icon"></i> {{s.label}} </a>
                                    </li>
                                </ul>
                            </ng-container>
                        </ul>
                    </li>
                    <ng-container *ngFor="let item of items2">
                        <li class=" border-bottom menu-items-2-modif">
                            <a [routerLink]="item.routerLink"> {{item.label}}</a>
                        </li>
                    </ng-container>
                </ul>

            </div>
        </p-sidebar>
        <div class="seconde-nav px-sm-5" *ngIf="eventId!='0'">
            <button type="text" class="btn btn-primary border border-white" (click)="visibleSidebar1 = true" icon=""><i
                    class="fa-sharp fa-regular fa-clipboard-list"></i></button>
        </div>
    </div>
    <div class="row" *ngIf="eventId!='0'">
        <div class="col-lg-2 d-lg-block d-none  mb-2  bg-light  ">

            <ul style="list-style: none " class="p-0">
                <li class="menu mb-2 border-bottom d-flex justify-content-start">
                    <button type="button" class="btn" [routerLink]="hasPerant? ['/events/manage-event/', Parent] : ['/events'] "><i
                            class="fa-solid fa-arrow-left me-2"></i>Back to events</button>
                </li>
                <li></li>
                <li class="d-flex  align-items-center mb-3  border-bottom">
                    <div>
                        <p-knob [size]="70" [(ngModel)]="srv.progressValue"  [readonly]="true"></p-knob>
                    </div>
                    <div class="p-input-filled  d-flex align-items-center ">
                        {{5-(srv.progressValue/20)==0? "Complete":5-(srv.progressValue/20)+" steps are remaining to complete"}}
                    </div>
                </li>
                <li>
                    <h5><span class="fw-bold text-break"> {{eventTitle}}</span></h5>
                </li>
                <li>{{ srv.TimingBool? eventDate : ""}}</li>
                <li class="border-bottom" *ngIf="srv.profileBool">
                    <a Class=" text-primary" [routerLink]="['/','event',eventId]"><i
                            class="pi pi-eye"></i> Preview your event</a>
                </li>
                <li>
                    <ul style="list-style: none;" class="items-1-ul">
                        <ng-container *ngFor="let item of items">
                            <li *ngIf="item.visible" class="border-bottom menu-items-modif">

                                <a (click)="itemClicked(item)" href="javascript:;" class="d-flex justify-content-between"> <span><i [class]="item.icon"></i> {{item.label}}</span>
                                    <i *ngIf="item.completed" class="fa-regular fa-circle-check text-success"></i>
                                </a>

                            </li>
                            <ul style="list-style: none;" class="items-1-ul ms-3">
                                <li *ngFor="let s of item.items" class="border-bottom menu-items-modif ">
                                    <a (click)="itemClicked(s)" [disabled]="s.clicked" [routerLink]="s.routerLink"> <i [class]="s.icon"></i> {{s.label}} </a>
                                </li>
                            </ul>
                        </ng-container>
                    </ul>
                </li>
                <ng-container *ngFor="let item of items2">
                    <li class=" border-bottom menu-items-2-modif">
                        <a [routerLink]="item.routerLink"> {{item.label}}</a>
                    </li>
                </ng-container>
            </ul>

        </div>
        <div class="col-lg-10 col-12 ">
            <div class=" row justify-content-center    mb-2 ">
                <div class=" card row container col-12 justify-content-center border-0 d-none">
                    <div class="col-12  d-flex justify-content-center ">
                        Progress: 10% percent
                    </div>
                    <div class="col-12 ">
                        <div>
                            <p-progressBar [value]="10"
                                [style]="{'height': '14px' , 'border-radius' : '10px'}"></p-progressBar>
                        </div>
                    </div>
                </div>
            </div>

            <router-outlet ></router-outlet>
        </div>
    </div>
    <div class="row" *ngIf="eventId=='0'">
        <div class="col-12">
            <app-basic-info></app-basic-info>
        </div>
    </div>
</div> -->

<div class=" mt-0">
    <p-toast></p-toast>
    <div class="d-lg-none d-block ">
        <p-sidebar [(visible)]="visibleSidebar1" position="left" [transitionOptions]="'0.3ms'" [baseZIndex]="10000">
            <div class=" d-lg-none d-block mb-2  bg-light p-0">
                <!-- <p-panelMenu [model]="items" ></p-panelMenu> -->
                <ul style="list-style: none " class="p-0">
                    <li class="menu back-btn-pad bg-primary  d-flex justify-content-start">
                        <button type="button" class="btn text-white" [routerLink]="hasPerant? ['/events/manage-event/', Parent] : ['events'] "><i
                                class="fa-solid fa-arrow-left me-2"></i>{{'EvntMnuBakBtn'|translate}}</button>
                    </li>
                    <div class="less-bg border-bottom ">
                    <li class="d-flex menu-items-pad  py-2 align-items-center  ">
                        <div>
                            <p-knob [size]="70" [(ngModel)]="srv.progressValue"  [readonly]="true"></p-knob>
                        </div>
                        <!-- <div class="p-input-filled d-flex align-items-center ">
                          {{srv.progressValue/20}}   of 5 steps to complete
                        </div> -->
                        <div class="p-input-filled  d-flex align-items-center " *ngIf="5-(srv.progressValue/20)==0">
                            {{ 'EvntMnuStps01'|translate}}
                        </div>
                        <div class="p-input-filled  d-flex align-items-center " *ngIf="5-(srv.progressValue/20)!=0">
                            <!-- {{5-(srv.progressValue/20)}}{{'EvntMnuStps'|translate}} -->
                           {{ getstringLoderr()}}
                        </div>
                    </li>
                    <div class="menu-items-pad">
                    <li class=" ">
                        <h5><span class="fw-bold text-break"> {{eventTitle}}</span></h5>
                    </li>
                    <li class=" ">{{ srv.TimingBool? getDateOntomeZoneformat(eventDate,'full') : ""}}</li>
                    <li class=" "><a Class=" text-primary" [routerLink]="['/','event',eventId]"><i
                                class="pi pi-eye"></i> {{'EvntMnuPrvewBtn'|translate}}</a>
                        </li>
                    </div>
                    </div>


                    <!-- [ngClass]="{' currency-custom':document.documentElement.dir === 'ltr',' currency-custom-rtl':document.documentElement.dir === 'rtl'}" -->

                    <li class="">
                        <ul style="list-style: none;" class="items-1-ul">
                            <ng-container *ngFor="let item of items">
                                <li *ngIf="item.visible" class="border-bottom "
                                [ngClass]="{' menu-items-modif':document.documentElement.dir === 'ltr','  menu-items-modif-rtl':document.documentElement.dir === 'rtl'}">

                                    <a (click)="itemClicked(item)" href="javascript:;" class="d-flex menu-items-pad justify-content-between"> <span><i [class]="item.icon"></i> {{item.label}}</span>
                                        <i *ngIf="item.completed" class="fa-regular fa-circle-check text-success"></i>
                                    </a>

                                </li>
                                <ul style="list-style: none;" class="items-1-ul ms-3">
                                    <li *ngFor="let s of item.items" class="border-bottom "
                                    [ngClass]="{' menu-items-modif':document.documentElement.dir === 'ltr','  menu-items-modif-rtl':document.documentElement.dir === 'rtl'}">
                                        <a (click)="itemClicked(s)" [disabled]="s.clicked" [routerLink]="s.routerLink"> <i [class]="s.icon"></i> {{s.label}} </a>
                                    </li>
                                </ul>
                            </ng-container>
                        </ul>
                    </li>
                    <!-- <ng-container *ngFor="let item of items2">
                        <li class=" border-bottom   menu-items-2-modif">
                            <a class="menu-items-pad" [routerLink]="item.routerLink"> {{item.label}}</a>
                        </li>
                    </ng-container> -->
                    <ng-container *ngFor="let item of items2">
                        <li class=" border-bottom  menu-items-pad menu-items-2-modif">
                            <a class="menu-items-pad" [routerLink]="item.routerLink"> {{item.label}}</a>
                        </li>
                        <ul style="list-style: none;" class="items-1-ul ms-3">
                            <li *ngFor="let s of item.items" class="border-bottom   " [ngClass]="{' menu-items-modif':document.documentElement.dir === 'ltr','  menu-items-modif-rtl':document.documentElement.dir === 'rtl'}">
                                <a class="menu-items-pad" (click)="itemClicked(s)" [disabled]="s.clicked" [routerLink]="s.routerLink"> <i [class]="s.icon"></i> {{s.label}} </a>
                            </li>
                        </ul>
                    </ng-container>
                </ul>

            </div>
        </p-sidebar>
        <div class="seconde-nav px-sm-5" *ngIf="eventId!='0'">
            <button type="text" class="btn btn-primary border border-white" (click)="visibleSidebar1 = true" icon=""><i
                    class="fa-sharp fa-regular fa-clipboard-list"></i></button>
        </div>
    </div>
    <div class="row" *ngIf="eventId!='0'">
        <div class="col-lg-2 d-lg-block d-none  mb-2  bg-light  p-0">
            <!-- <p-panelMenu [model]="items" ></p-panelMenu> -->
            <ul style="list-style: none " class="p-0">
                    <li class="menu back-btn-pad  bg-primary d-flex justify-content-start">
                        <button type="button" class="btn text-white" [routerLink]="hasPerant? ['/events/manage-event/', Parent] : ['/events'] "><i
                                class="fa-solid fa-arrow-left me-2"></i>{{'EvntMnuBakBtn'|translate}}</button>
                    </li>
                    <div class="less-bg border-bottom ">
                        <li class="d-flex menu-items-pad  py-2 align-items-center  ">
                            <div>
                                <p-knob [size]="70" [(ngModel)]="srv.progressValue"  [readonly]="true"></p-knob>
                            </div>
                            <div class="p-input-filled  d-flex align-items-center " *ngIf="5-(srv.progressValue/20)==0">
                                {{ 'EvntMnuStps01'|translate}}
                            </div>
                            <div class="p-input-filled  d-flex align-items-center " *ngIf="5-(srv.progressValue/20)!=0">
                                <!-- {{5-(srv.progressValue/20)}}{{'EvntMnuStps'|translate}} -->
                               {{ getstringLoderr()}}
                            </div>
                        </li>
                        <div class="menu-items-pad">
                            <li class="">
                                <h5><span class="fw-bold  text-break"> {{eventTitle}}</span></h5>
                            </li>
                            <li class=" ">{{ srv.TimingBool?getDateOntomeZoneformat(eventDate,'full')  : ""}}</li>
                            <li class=" " *ngIf="srv.profileBool">
                                <a Class=" text-primary" [routerLink]="['/','event',eventId]"><i
                                        class="pi pi-eye"></i> {{'EvntMnuPrvewBtn'|translate}}</a>
                            </li>
                        </div>
                    </div>







                <li>
                    <ul style="list-style: none;" class="items-1-ul">
                        <ng-container *ngFor="let item of items">
                            <li *ngIf="item.visible" class="border-bottom  " [ngClass]="{' menu-items-modif':document.documentElement.dir === 'ltr','  menu-items-modif-rtl':document.documentElement.dir === 'rtl'}">

                                <a (click)="itemClicked(item)" href="javascript:;" class="d-flex menu-items-pad justify-content-between"> <span><i [class]="item.icon"></i> {{item.label}}</span>
                                    <i *ngIf="item.completed" class="fa-regular fa-circle-check text-success"></i>
                                </a>

                            </li>
                            <ul style="list-style: none;" class="items-1-ul ms-3">
                                <li *ngFor="let s of item.items" class="border-bottom   " [ngClass]="{' menu-items-modif':document.documentElement.dir === 'ltr','  menu-items-modif-rtl':document.documentElement.dir === 'rtl'}">
                                    <a class="menu-items-pad" (click)="itemClicked(s)" [disabled]="s.clicked" [routerLink]="s.routerLink"> <i [class]="s.icon"></i> {{s.label}} </a>
                                </li>
                            </ul>
                        </ng-container>
                    </ul>
                </li>
                <ng-container *ngFor="let item of items2">
                    <li class=" border-bottom  menu-items-pad menu-items-2-modif">
                        <a class="menu-items-pad" [routerLink]="item.routerLink"> {{item.label}}</a>
                    </li>
                    <ul style="list-style: none;" class="items-1-ul ms-3">
                        <li *ngFor="let s of item.items" class="border-bottom   " [ngClass]="{' menu-items-modif':document.documentElement.dir === 'ltr','  menu-items-modif-rtl':document.documentElement.dir === 'rtl'}">
                            <a class="menu-items-pad" (click)="itemClicked(s)" [disabled]="s.clicked" [routerLink]="s.routerLink"> <i [class]="s.icon"></i> {{s.label}} </a>
                        </li>
                    </ul>
                </ng-container>
            </ul>

        </div>
        <div class="col-lg-10 col-12 ">
            <div class=" row justify-content-center    mb-2 ">
                <div class=" card row container col-12 justify-content-center border-0 d-none">
                    <div class="col-12  d-flex justify-content-center ">
                        Progress: 10% percent
                    </div>
                    <div class="col-12 ">
                        <div>
                            <p-progressBar [value]="10"
                                [style]="{'height': '14px' , 'border-radius' : '10px'}"></p-progressBar>
                        </div>
                    </div>
                </div>
            </div>

            <router-outlet ></router-outlet>
        </div>
    </div>
    <div class="row" *ngIf="eventId=='0'">
        <div class="col-12">
            <app-basic-info></app-basic-info>
        </div>
    </div>
</div>

