import { Injectable } from '@angular/core';
import { environment as env } from 'src/environments/environment';
import {EventLink, EventPageDto, OrganizerLink, PriceRange} from '../event-page/event-page.component';
import {HttpClient} from '@angular/common/http';

export interface OnlineEventPageDto {
    id?: any;
    coverImg?: string;
    startDate?: Date;
    endDate?: Date;
    title?: string;
    isOnline?: boolean;
    description?: string;

}
@Injectable({
  providedIn: 'root'
})
export class OnlinePageService {
    url = env.Api;
  constructor(private http: HttpClient) { }
    getOnlineEventDetails(eventId: string){
        return this.http.get<OnlineEventPageDto>(this.url + 'd/e/g/on/' + eventId);
    }
}
