// import { Component } from '@angular/core';
import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {EventCard} from '../../pages/landing-page/landing-page.service';
import {environment} from '../../../../environments/environment';
import {UserActionsService} from '../../../services/user-actions.service';
import { Orgnizer, OrgnizersService } from 'src/app/services/orgnizers.service';
import { OrganizerFromStepService } from 'src/app/services/organizer-from-step.service';
import * as m from 'moment';
import 'moment/locale/pt-br';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-organizer-card',
  templateUrl: './organizer-card.component.html',
  styleUrls: ['./organizer-card.component.scss']
})
export class OrganizerCardComponent implements OnInit
{
    @Input() item: any;
    @Input() follow = false;
   
    data:any;
    id:string="ff369891-a344-4238-86f0-99f08ec701ca";
    @Output() likedClicked: EventEmitter<string> = new EventEmitter<string>();
    linksForPreview:any[] =[]
    url = environment.Api;
    constructor(private orgformsrv:OrganizerFromStepService,public orgsrv:OrgnizersService,public ua: UserActionsService,private translate:TranslateService) {
      this.translate.onLangChange.subscribe(() => {
        m.locale(this.translate.currentLang);
      });
    }
    ngOnInit(): void {
      this.getLinks(this.item.links)
        //this.orgsrv.getOneOrg(this.id).subscribe(x => {this.data = x;  this.getLinks(x.links)})
    }
    Like(x){
        this.likedClicked.emit(x);
    }
    getLinks(links){
        links.forEach(e => {

          let x = {
            url : e.linkUrl,
            icon : this.orgformsrv.getLinksName(e.attrId).misc01
          }
          this.linksForPreview.push(x)
        });
      }
    getDateOntomeZone(d){
        let t = new Date(Date.now()).getTimezoneOffset();
        let MyDate = new Date(d).setMinutes(new Date(d).getMinutes() - (t));
        return new Date(MyDate).toLocaleString();
    }
    getDateOntomeZoneformat2(d){
  
      m.locale();
        let t = new Date(Date.now()).getTimezoneOffset();
        let MyDate = new Date(d).setMinutes(new Date(d).getMinutes() - (t));
        var l="";
      if(new Date(MyDate).getFullYear()==new Date(Date.now()).getFullYear()){
            l= m(new Date(MyDate)).format('ddd MMMM d hh:mm a');
      
        }else{
           l=  m(new Date(MyDate)).format( 'ddd MMMM d,y hh:mm a');
      
        }
        
        return l
      
      
      
      
       // return new Date(MyDate).toLocaleString();
      }

}
