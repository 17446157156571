import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { environment as env} from 'src/environments/environment'
@Injectable({
  providedIn: 'root'
})
export class EventProfileService {

    url = env.Api;
  constructor(public httpClient: HttpClient,private fb:FormBuilder) {
  
  }


  postEventProfile(x){

  return this.httpClient.post (this.url +'d/e/c/profile',x)

}
getEventProfile(x){

    return this.httpClient.get (this.url +'d/e/g/profile/'+x);
}
}
