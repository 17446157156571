<div class="container">
    <div class="row">
        <div class="col-12">
            <p-tabMenu [model]="items" [activeItem]="items[0]"  ></p-tabMenu>
        </div>
        <div class="col-12">
            <router-outlet ></router-outlet>
        </div>
    </div>
</div>

