import { transition, style, animate, trigger } from '@angular/animations';
import { Component, OnInit,Inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationService, MessageService } from 'primeng/api';
import { environment as env } from 'src/environments/environment';
import { LoaderService } from 'src/app/services/loader.service';
import {
    OrgnizersService,
    invitedmembers,
} from 'src/app/services/orgnizers.service';
import { Clipboard } from '@angular/cdk/clipboard';
import { DOCUMENT } from '@angular/common';

const leaveTrans = transition(':leave', [
    style({
        opacity: 1,
    }),
    animate(
        '1s ease-out',
        style({
            opacity: 0,
        })
    ),
]);

const fadeOut = trigger('fadeOut', [leaveTrans]);

@Component({
    selector: 'app-invite-table',
    templateUrl: './invite-table.component.html',
    styleUrls: ['./invite-table.component.scss'],
    animations: [fadeOut],
})
export class InviteTableComponent implements OnInit {
    data: invitedmembers[];
    id: string;

    ngOnInit(): void {
        this.id = this.activatedRoute.parent.snapshot.paramMap.get('id');
        this.loader.showLoader1();
        // this.orgSrv.getInvite(this.id).subscribe(x => {this.data = x ; this.loader.hideLoader1()})
        this.orgSrv.getInvite(this.id)
        this.orgSrv.$getSubject.subscribe((x) => {
            this.data = x;
            this.loader.hideLoader1();
        });
    }

    constructor(
        private clip: Clipboard,
        private msg: MessageService,
        private conSrv: ConfirmationService,
        public loader: LoaderService,
        private orgSrv: OrgnizersService,
        private activatedRoute: ActivatedRoute,
        @Inject(DOCUMENT) public document: Document
    ) {}
    copyclick(e) {
        this.clip.copy(env.Website + 'register/' + e);
        this.msg.add({ severity: 'success', summary: 'Copied to clipboard' });
    }
    resendInvite(x) {
      //  console.log(x);
        let data = {
            id: x,
            orgId: this.id,
        };
      //  console.log(data)
        this.orgSrv.resendInvite(data);
    }
    getstatus(x: boolean) {
        return x ? 'Registered' : 'Pending';
    }
    getDate(x: Date) {
        var t = new Date(x).toUTCString();
        return t;
    }
    delete(event, id: string) {
        this.conSrv.confirm({
            target: event.target,
            message: 'Are you sure that you want to proceed?',
            icon: 'pi pi-exclamation-triangle',
            accept: () => {
                // this.messageService.add({ severity: 'info', summary: 'Confirmed', detail: 'You have accepted' });
            },
            reject: () => {},
        });
    }
}
