import {Component, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import { EventStepsService } from '../../../../../../services/event-steps.service';

@Component({
  selector: 'app-tickets-steps',
  templateUrl: './tickets-steps.component.html',
  styleUrls: ['./tickets-steps.component.scss']
})
export class TicketsStepsComponent implements OnInit {


    constructor(private router:Router,private activatedRoute:ActivatedRoute){}

    ngOnInit(): void {

        // this.router.navigate([
        //     {
        //       outlets: { 'tickets-steps': ['tickets-all'] }
        //     }
        //   ],{relativeTo: this.activatedRoute});
    }




}
