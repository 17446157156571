import { Injectable } from '@angular/core';
import * as signalR from "@microsoft/signalr"
import { HubConnection, HubConnectionBuilder } from '@microsoft/signalr';
import { Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class OrdernotisignalRService {
 message:string; 
cl:HubConnection
orderUpdateSubject:Subject<any> = new Subject();
  constructor() { 
    
  }
  getOrderUpdates() {


    return this.orderUpdateSubject.asObservable();
  }


  setcon(id){
    this.cl=new HubConnectionBuilder()
    .configureLogging(signalR.LogLevel.Debug)
    .withUrl(`${environment.Api}hub/ord/`+id,{
      skipNegotiation: true,
      transport: signalR.HttpTransportType.WebSockets
    }).withAutomaticReconnect().build()
    this.cl.start().then(function () {
    //  console.log('Connected!');
  }).catch(function (err) {
      return console.error(err.toString()); 
  });
    this.cl.on('SendMessage', (order) => {
      this.orderUpdateSubject.next(order);
    });
  }
  stopconnection(){
    this.cl.stop()
  }
}
