<form [formGroup]="srv.myform" (submit)="submit()" autocomplete="off">
    <div class="row mb-2">
        <div class=" p-input-filled field   col-md-6 col-12  mb-2">
            <label class="form-lable fs-6 fw-bold" appendTo="body"
                for="salesStart">{{'TcktFrmDateInpt001'|translate}}</label>
            <p-calendar dataType="string" #op pTooltip="{{'TcktFrmDateInpt001Pop'|translate}}" showDelay="1000"
                tooltipPosition="top" [showTime]="true" [showSeconds]="false" [minDate]="minDateValue"
                [maxDate]="!srv.myform.get('eventType').value?MaxValue:null" inputId="time" [hourFormat]="12"
                [styleClass]="'form-control m-0 p-0'" [stepMinute]="1" [inputStyleClass]="'form-control'"
                formControlName="startDate" (onSelect)="confirmdate($event)" appendTo="body" [ngClass]="{'ng-invalid ng-dirty':srv.myform.get('startDate')?.touched
                && srv.myform.get('startDate')?.invalid||(submitted&& srv.myform.get('startDate').invalid)}"
                #salesStartDate>
                <ng-template pTemplate="date" let-date>
                    <span [ngStyle]="{color:getDateBoolean(date) ? 'red' : 'inherit'}">{{date.day}}</span>
                </ng-template>
                <ng-template pTemplate="footer">
                    <p class="clock-positon"> <i class="fa-solid fa-clock"></i> </p>
                    <!-- <p class="" style="padding-left: 1.1rem;position:relative;translate: 0 -80px;"> <i style="font-size: 1.1rem" class="fa-solid fa-clock"></i> </p> -->
                </ng-template>
            </p-calendar>
            <small class="p-error" *ngIf="(srv.myform.get('startDate')?.touched &&
            srv.myform.get('startDate')?.invalid)||(submitted&& srv.myform.get('startDate').invalid)
                ">{{'TcktFrmDateInpt001ErrMsg'|translate}}</small>
        </div>
        <div class=" p-input-filled field   col-md-6 col-12  mb-2" *ngIf="!srv.myform.get('eventType').value">
            <label pTooltip="{{'TcktFrmDateInpt002Pop'|translate}}" showDelay="1000" tooltipPosition="center"
                class="form-lable fs-6 fw-bold" appendTo="body"
                for="salesEnd">{{'TcktFrmDateInpt002'|translate}}</label>
            <p-calendar #op1 [showTime]="true" [stepMinute]="1" dataType="string" [showSeconds]="false"
                [minDate]="maxDateValue" [maxDate]="MaxValue" inputId="time" [hourFormat]="12"
                [styleClass]="'form-control m-0 p-0'" [inputStyleClass]="'form-control'" formControlName="endDate"
                appendTo="body"
                [ngClass]="{'ng-invalid ng-dirty':srv.myform.get('endDate')?.touched && srv.myform.get('endDate')?.invalid||(submitted&& srv.myform.get('endDate').invalid)}">
                <ng-template pTemplate="date" let-date>
                    <span [ngStyle]="{color: getDateBoolean(date) ? 'red' : 'inherit'}">{{date.day}}</span>
                </ng-template>
                <ng-template pTemplate="footer">
                    <p class="clock-positon"> <i class="fa-solid fa-clock"></i> </p>
                    <!-- <p class="" style="padding-left: 1.1rem;position:relative;translate: 0 -80px;"> <i style="font-size: 1.1rem" class="fa-solid fa-clock"></i> </p> -->
                </ng-template>

            </p-calendar>
            <small class="p-error" *ngIf="(srv.myform.get('endDate')?.touched &&
                srv.myform.get('endDate')?.invalid)||(submitted&& srv.myform.get('endDate').invalid)
                    ">{{'TcktFrmDateInpt002ErrMsg'|translate}}</small>
        </div>
    </div>
    <div class=" form-floating row mb-2" id="TicketSection" *ngIf="srv.myform.get('eventType').value">
        <span class="text-start fs-6 fw-bold">{{'sales-end-in'|translate}}</span>
        <div class="p-input-filled col-12 col-md-5 mt-md-3 ms-2  p-0">
            <label class="form-lable font-size-14 fw-bold  pb-2 ps-md-0 ps-2 ms-3" for="salesEnd"
                pTooltip="enter duration for stop sale this tickets  based on start date of event  Ex:sales  end  in one hour befor start event "
                showDelay="1000" tooltipPosition="right">{{'CalenderStep2lable5'|translate}}</label>
            <!-- <label class="form-lable font-size-14 pb-2 ps-md-0 ps-2" for="salesEnd">From</label> -->
            <span class="p-float-label">
                <p-dropdown inputId="befor"
                    pTooltip="select duration start point  for stop sale this tickets  based on start date of event  Ex:sales  end  in one hour befor start event "
                    showDelay="1000" tooltipPosition="bottom" [styleClass]="'w-100'" [autoDisplayFirst]="false"
                    [options]="duration" optionLabel="name" optionValue="code" formControlName="startingPoint"
                    [ngClass]="{'ng-invalid ng-dirty':srv.myform.get('startingPoint')?.touched && srv.myform.get('startingPoint')?.invalid ||(submitted&& srv.myform.get('startingPoint').invalid) }"></p-dropdown>
                <label for="befor">Duration end point</label>
            </span>
        </div>
        <!-- <div class="col-md-1 col-0">
        </div> -->
        <div class="p-input-filled col-md-6 col-12  mt-md-3 p-0">
            <label class="form-lable font-size-14 ps-2" for="salesEnd"></label>
            <div class="d-flex">
                <div class="col-6">
                    <span class="p-float-label">
                        <p-dropdown inputId="durationType"
                            pTooltip="select  duration  for stop sale this tickets  based on start date of event  Ex:sales  end  in one hour befor start event "
                            showDelay="1000" tooltipPosition="bottom" [styleClass]="'w-100'" [autoDisplayFirst]="false"
                            [options]="durationType" optionLabel="name" optionValue="code" formControlName="priodType"
                            [ngClass]="{'ng-invalid ng-dirty':srv.myform.get('priodType')?.touched && srv.myform.get('priodType')?.invalid ||(submitted&& srv.myform.get('priodType').invalid)}"></p-dropdown>
                        <label for="durationType">{{'CalenderStep2lable6'|translate}}</label>
                    </span>
                </div>
                <div class="col-6">
                    <span class="p-float-label">
                        <p-inputNumber
                            pTooltip="enter number of  duration type for stop sale this tickets  based on start date of event  Ex:sales  end  in one hour befor start event "
                            showDelay="1000" tooltipPosition="bottom" inputId="inputnumber" [styleClass]="'w-100'"
                            class="form-control me-1 p-0" [inputStyleClass]="'form-control'"
                            formControlName="priodNumber"
                            [ngClass]="{'ng-invalid ng-dirty':srv.myform.get('priodNumber')?.touched && srv.myform.get('priodNumber')?.invalid||(submitted&& srv.myform.get('priodNumber').invalid)}"></p-inputNumber>
                        <label for="inputnumber">Number of {{getDurationType()}}</label>
                    </span>
                </div>

            </div>
        </div>


    </div>

    <div class="col-12 d-flex  justify-content-center">
        <div class="banner-btn rounded-pill">

            <button pTooltip="Go back for previous step" type="button" showDelay="1000" tooltipPosition="left" pButton
                pRipple class="rounded-pill cancel-btn" (click)="previous()"><i class="pi mt-1"
                    [class.pi-angle-right]="document.documentElement.dir === 'rtl'"
                    [class.pi-angle-left]="document.documentElement.dir === 'ltr'"></i> {{'PrviosBtn'|translate}}
                <span></span></button>
            <button pTooltip="Cancel and dismiss changes" type="button" showDelay="1000" tooltipPosition="bottom"
                pButton pRipple type="submit" class="rounded-pill save-btn text-center ms-1"
                (click)="cancel()">{{'CanclBtn'|translate}} <span></span></button>
            <button pTooltip="Save and go to next step" showDelay="1000" tooltipPosition="right" pButton pRipple
                type="submit" class="rounded-pill next-btn  ms-1 ms-1 mt-md-0 mt-1">{{'NextBtn'|translate}} <i
                    class="pi mt-1" [class.pi-angle-right]="document.documentElement.dir === 'ltr'"
                    [class.pi-angle-left]="document.documentElement.dir === 'rtl'"></i><span></span></button>
        </div>
    </div>
</form>
