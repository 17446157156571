<div class="">
    <div class="d-flex justify-content-between align-items-center">
        <h2 class="fw-bold">{{'ShdlrFrmTitl'|translate}}</h2>
        <!-- <div class="card-header border-0 text-center bg-primary rounded-top-23 ">
            <h1 class="fw-bold text-white mt-2">Add event date</h1>
            </div> -->
<div>



    <h6 class="fw-bold d-flex">Help <span class="text-primary help-btn" (click)="showHelpStepsModal()" ><i class="fa-regular fa-circle-question mx-2"></i></span></h6>
    <p-dialog header="How to start" [(visible)]="helpStepsModal" [modal]="true" class="bg-dark"
        [draggable]="false" [resizable]="false">
        <p class="m-0"><b class="text-primary">Step 1: </b> Enter the total count of instance you want the event have it </p>
        <p class="m-0"><b class="text-primary">Step 2: </b>  Enter first instance date information for geting the duration bettween start and end date</p>
        <p class="m-0"><b class="text-primary">Step 3: </b> define type of reccuring (daily,weekly,monthly,Yearly) for weekly reccuring you have to select days of week you want to repet this event on it and for monthly we should select day of minth to repet event</p>
        <p class="m-0"><b class="text-primary">Step 4: </b> check the date that generat befor confirm progress </p>

            <ng-template pTemplate="footer">
            <p-button icon="pi pi-check" (click)="helpStepsModal=false" label="Ok" styleClass="p-button-text"></p-button>
            </ng-template>
    </p-dialog>
</div>
    </div>
    <div class="row">
            <div class="col-12 p-0">
                <p-steps [model]="items" [readonly]="true"></p-steps>
            </div>
            <div class="col-12 p-0">
                <router-outlet></router-outlet>
            </div>
    </div>
</div>
