<div class="error-area ptb-100">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="error-content">
                    <img src="assets/img/error1.png" alt="image">
                    <h3>{{'ErrorTitl2'|translate}}</h3>
                    <p>{{'ErrorDisc2'|translate}}</p>
                    <div class="btn-box">
                        <a (click)="goBack()" routerLink="/" class="rounded-pill save-btn"><i
                                class="flaticon-home mx-2"></i>{{'HomepageClick'|translate}}<span></span></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
