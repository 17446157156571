import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-promo-codes-steps',
  templateUrl: './promo-codes-steps.component.html',
  styleUrls: ['./promo-codes-steps.component.scss']
})
export class PromoCodesStepsComponent implements OnInit {
  constructor(private router:Router,private activerout:ActivatedRoute){

  }
  ngOnInit(): void {
  //   this.router.navigate([
  //     {
         
  //         outlets: { 'Promot-steps': ['promo-codes-all'] }
  //     }
  // ], { relativeTo: this.activerout });
  }

}
