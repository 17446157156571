import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-si-banner',
  templateUrl: './si-banner.component.html',
  styleUrls: ['./si-banner.component.scss']
})
export class SiBannerComponent implements OnInit {

  constructor( public auth: AuthService,@Inject(DOCUMENT) public document: Document) { }

  ngOnInit(): void {
  }

}
