import { DOCUMENT } from '@angular/common';
import { Component, OnInit, Inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { CalenderStepsService } from 'src/app/services/calender-steps.service';
import { EventStepsService } from 'src/app/services/event-steps.service';

@Component({
    selector: 'app-calender-form-step2',
    templateUrl: './calender-form-step2.component.html',
    styleUrls: ['./calender-form-step2.component.scss']
})
export class CalenderFormStep2Component implements OnInit {
    submitted: boolean = false;
    monthlyOccuringOption = [];// monthly occuring types option
    OccursTypeOption = [
        { name: 'Daily', id: 'DAILY' },
        { name: 'Weekly', id: 'WEEKLY' },
        { name: 'Monthly', id: 'MONTHLY' }, { name: 'Yearly', id: 'YEARLY' },
        { name: 'Custom reccuring priod', id: 'Custom' }]//occuring types option
    WeeklyOccursOption = [
        { name: 'Sunday', id: 'Sunday' },
        { name: 'Monday', id: 'Monday' },
        { name: 'Tuesday', id: 'Tuesday' },
        { name: 'Wednesday', id: 'Wednesday' },
        { name: 'Thursday', id: 'Thursday' },
        { name: 'Friday', id: 'Friday' },
        { name: 'Saturday', id: 'Saturday' }]// Daily occuring types option
    CustomOption = [
        { name: 'Day', id: 'day' },
        { name: 'Week', id: 'week' },
        { name: 'Month', id: 'month' },
        { name: 'year', id: 'year' },
    ]
    IsWeekly: boolean = false;
    IsMonthly: boolean = false;
    parentDate!: Date
    hasParent: boolean = false;
    constructor(public Srv: CalenderStepsService, public evSrv: EventStepsService, private router: Router, private activatedRoute: ActivatedRoute, public EventSrv: EventStepsService, @Inject(DOCUMENT) public document: Document) {

    }
    ngOnInit(): void {


        this.evSrv.ParentId$.subscribe(x => {
            if (x) {

                if (x == this.evSrv.id) {
                    this.hasParent = false;
                } else {
                    this.hasParent = true;
                }

                this.evSrv.ParentDate$.subscribe(x => {
                    this.parentDate = x.end;
                })
            }
        })

        // if(this.EventSrv.ParentId!=""){
        //   this.EventSrv.ParentDate$.subscribe(x=>{
        //     var Parentstamp= moment(x.end).diff(x.start);
        //     var  ParentstampAshour=Number.parseInt((stamp/(24*1000*60*60)).toString());
        //     var  ParentstampAsWeek=Number.parseInt((stamp/(24*1000*60*60*7)).toString());
        //     var  ParentstampAsmonth=Number.parseInt((stamp/(24*1000*60*60*30)).toString());
        //     var  ParentstampAsYear=Number.parseInt((stamp/(24*1000*60*60*30*12)).toString());
        //     var stamp= moment(this.Srv.clenderSteps.controls['endDate'].value).diff(this.Srv.clenderSteps.controls['startDate'].value);
        //     this.bindselect(this.Srv.clenderSteps.controls['startDate'].value)
        //      var stampAshour=Number.parseInt((stamp/(24*1000*60*60)).toString());//get timestamp of event if is for day will get 0 if mor than day it get 1||2|3
        //      var stampAsWeek=Number.parseInt((stamp/(24*1000*60*60*7)).toString());
        //      var stampAsmonth=Number.parseInt((stamp/(24*1000*60*60*30)).toString());
        //      if(stampAshour==0){
        //       this.OccursTypeOption = [
        //         { name: 'Daily', id: 'DAILY' },
        //         { name: 'Weekly', id: 'WEEKLY' },
        //         { name: 'Monthly', id: 'MONTHLY' },
        //         { name: 'Yearly', id: 'YEARLY' },
        //         { name: 'Custom reccuring priod', id: 'Custom' }]
        //         this.CustomOption = [
        //           { name: 'Day', id: 'day' },
        //           { name: 'Week', id: 'week' },
        //           { name: 'Month', id: 'month' },
        //           { name: 'year', id: 'year' },
        //           ]
        //      }else{
        //       if(stampAsWeek==0){
        //         this.OccursTypeOption = [
        //           { name: 'Weekly', id: 'WEEKLY' },
        //           { name: 'Monthly', id: 'MONTHLY' },
        //           { name: 'Yearly', id: 'YEARLY' },
        //           { name: 'Custom reccuring priod', id: 'Custom' }];
        //           this.CustomOption = [
        //             { name: 'Day', id: 'day' },
        //             { name: 'Week', id: 'week' },
        //             { name: 'Month', id: 'month' },
        //             { name: 'year', id: 'year' },
        //             ]



        //       }else{
        //         if(stampAsmonth==0){
        //           this.OccursTypeOption = [

        //             { name: 'Monthly', id: 'MONTHLY' }, { name: 'Yearly', id: 'YEARLY' },
        //             { name: 'Custom reccuring priod', id: 'Custom' }]
        //             this.CustomOption = [
        //               { name: 'Day', id: 'day' },
        //               { name: 'Week', id: 'week' },
        //               { name: 'Month', id: 'month' },
        //               { name: 'year', id: 'year' },
        //               ]
        //         }
        //         else{
        //           this.OccursTypeOption = [

        //             { name: 'Yearly', id: 'YEARLY' },
        //             { name: 'Custom reccuring priod', id: 'Custom' }]
        //             this.CustomOption = [
        //               { name: 'Day', id: 'day' },
        //               { name: 'Week', id: 'week' },
        //               { name: 'Month', id: 'month' },
        //               { name: 'year', id: 'year' },
        //               ]

        //         }
        //       }

        //      }

        //      if((ParentstampAshour>2&&ParentstampAsWeek==0)||(ParentstampAsWeek>1&&ParentstampAsmonth==0)||(ParentstampAsmonth>1&&ParentstampAsYear==0)){
        //       if(stampAshour==0){
        //         this.OccursTypeOption = [
        //           { name: 'Daily', id: 'DAILY' },

        //           { name: 'Custom reccuring priod', id: 'Custom' }]
        //           this.CustomOption = [
        //             { name: 'Day', id: 'day' },
        //             { name: 'Week', id: 'week' },
        //             { name: 'Month', id: 'month' },
        //             { name: 'year', id: 'year' },
        //             ]
        //        }
        //      }

        //   })

        // }

        // else{
        var stamp = moment(this.Srv.clenderSteps.controls['endDate'].value).diff(this.Srv.clenderSteps.controls['startDate'].value);
        this.bindselect(this.Srv.clenderSteps.controls['startDate'].value)
        var stampAshour = Number.parseInt((stamp / (24 * 1000 * 60 * 60)).toString());//get timestamp of event if is for day will get 0 if mor than day it get 1||2|3
        var stampAsWeek = Number.parseInt((stamp / (24 * 1000 * 60 * 60 * 7)).toString());
        var stampAsmonth = Number.parseInt((stamp / (24 * 1000 * 60 * 60 * 30)).toString());
        if (stampAshour == 0) {
            this.OccursTypeOption = [
                { name: 'Daily', id: 'DAILY' },
                { name: 'Weekly', id: 'WEEKLY' },
                { name: 'Monthly', id: 'MONTHLY' },
                { name: 'Yearly', id: 'YEARLY' },
                { name: 'Custom reccuring priod', id: 'Custom' }]
            this.CustomOption = [
                { name: 'Day', id: 'day' },
                { name: 'Week', id: 'week' },
                { name: 'Month', id: 'month' },
                { name: 'year', id: 'year' },
            ]
        } else {
            if (stampAsWeek == 0) {
                this.OccursTypeOption = [
                    { name: 'Weekly', id: 'WEEKLY' },
                    { name: 'Monthly', id: 'MONTHLY' },
                    { name: 'Yearly', id: 'YEARLY' },
                    { name: 'Custom reccuring priod', id: 'Custom' }];
                this.CustomOption = [
                    { name: 'Day', id: 'day' },
                    { name: 'Week', id: 'week' },
                    { name: 'Month', id: 'month' },
                    { name: 'year', id: 'year' },
                ]



            } else {
                if (stampAsmonth == 0) {
                    this.OccursTypeOption = [

                        { name: 'Monthly', id: 'MONTHLY' }, { name: 'Yearly', id: 'YEARLY' },
                        { name: 'Custom reccuring priod', id: 'Custom' }]
                    this.CustomOption = [
                        { name: 'Day', id: 'day' },
                        { name: 'Week', id: 'week' },
                        { name: 'Month', id: 'month' },
                        { name: 'year', id: 'year' },
                    ]
                }
                else {
                    this.OccursTypeOption = [

                        { name: 'Yearly', id: 'YEARLY' },
                        { name: 'Custom reccuring priod', id: 'Custom' }]
                    this.CustomOption = [
                        { name: 'Day', id: 'day' },
                        { name: 'Week', id: 'week' },
                        { name: 'Month', id: 'month' },
                        { name: 'year', id: 'year' },
                    ]

                }
            }

        }
        //}


    }

    bindselect(date)//forbind select of monthly recuuring
    {
        if (moment(date).dates() == 31 || moment(date).dates() == 30) {
            this.monthlyOccuringOption = [{ name: 'On last day of month', id: "lastDay" }, { name: `On The ${this.weekAndDay(date)}`, id: "WeekDay" }]
        }
        else {
            this.monthlyOccuringOption = [{ name: `On the${moment(date).dates()}Th`, id: "Day" }, { name: `On the ${this.weekAndDay(date)}`, id: "WeekDay" }]
        }
    }
    getOccuring(e)//for get the type of reccurring
    {
        this.Srv.setSomeValidtion();
        if (e.value == 'Custom') {
            this.IsMonthly = false;
            this.IsWeekly = false;
        }
        if (e.value == 'WEEKLY') {
            this.IsWeekly = true;
            this.IsMonthly = false;
        }
        else {
            if (e.value == "MONTHLY") {

                this.bindselect(this.Srv.clenderSteps.controls['startDate'].value)

                this.IsMonthly = true;
                this.IsWeekly = false;
            }
            else {
                this.IsWeekly = false;
                this.IsMonthly = false;


            }




        }

    }







    weekAndDay(date)//to get num of day in week with the weeek num of month
    {
        var days = ['sunday', 'monday', 'tuesday', 'wednesday',
            'thursday', 'friday', 'saturday'],
            prefixes = ['first', 'fecond', 'third', 'fourth', 'last'];
        if (prefixes[Math.ceil(moment(date).dates() / 7 - 1)] == "Fourth") {
            return 'Last  ' + days[moment(date).day()];
        }
        else {
            return prefixes[Math.ceil(moment(date).dates() / 7 - 1)] + ' ' + days[moment(date).day()];
        }

    }

    submit() {
        // console.log(this.validateCalenderstep2())
        if (this.validateCalenderstep2()) {
            this.Srv.finish();
            this.router.navigate(['step4'], { relativeTo: this.activatedRoute.parent });
        } else {
            this.submitted = true;
        }

    }
    previous() {
        this.router.navigate(['step1'], { relativeTo: this.activatedRoute.parent })
    }
    cancel() {
        this.Srv.clearForm();
        this.router.navigate(['calendar'], { relativeTo: this.activatedRoute.parent.parent.parent });
        // this.router.navigate([
        //   {
        //     outlets: { 'CalenderOutlet': ['calender_prview'] }
        //   }
        // ],{relativeTo: this.activatedRoute.parent.parent});
    }

    validateCalenderstep2() {
        if (this.hasParent) {
           // console.log('validtion 2')
           // console.log(this.Srv.testValidation(this.parentDate)
           // )
            return this.Srv.clenderSteps.controls['occuringtype'].valid && this.Srv.clenderSteps.controls['weeklyOccuringOption'].valid && this.Srv.clenderSteps.controls['monthlyOccuringOption'].valid && this.Srv.testValidation(this.parentDate);


        } else {
          //  console.log('validtion 2')
            return this.Srv.clenderSteps.controls['occuringtype'].valid && this.Srv.clenderSteps.controls['weeklyOccuringOption'].valid && this.Srv.clenderSteps.controls['monthlyOccuringOption'].valid;

        }




    }
}
