import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Observable } from 'rxjs';
import { CropperComponent } from '../cropper/cropper.component';

@Component({
  selector: 'app-upload-photo',
  templateUrl: './upload-photo.component.html',
  styleUrls: ['./upload-photo.component.scss']
})
export class UploadPhotoComponent implements OnInit  ,OnChanges {
 @Input() img:Observable<string>;
 @Output() photo = new EventEmitter<string>();
 @Output() ischanged=new EventEmitter<boolean>()
  imageChangedEvent: any = '';
  croppedImage: any = '';
  myFile: any = '';
  uploadedFiles: any[] = [];
  constructor(public config: DynamicDialogConfig,private fb: FormBuilder, private dialogService: DialogService, public ref: DynamicDialogRef) {}
  ngOnChanges(changes: SimpleChanges): void {
    if(changes.img){
      this.croppedImage=this.img
    }
  }
  ngOnInit(): void {
  }

  onUpload(event): void {
    for (const file of event.files) {
      this.uploadedFiles.push(file);
    }
    this.imageChangedEvent = event.originalEvent;
    this.ischanged.emit(true);

    this.show();
  }
  onSelect(event): void {
    this.myFile = event.currentFiles[0];
    this.show();
  }
  show(): void {
    const ref = this.dialogService.open(CropperComponent, {
      data: {
        myFile: this.myFile
      },
      header: 'Crop image',
      width: '100%',
      height: '100vh',
    });

    ref.onClose.subscribe((croppedImage: any) => {
      if (croppedImage) {
        this.croppedImage = croppedImage;
        this.photo.emit(croppedImage);
        this.ischanged.emit(true);

      }

    });
  }
  deletImg(){
    this.croppedImage="";
    
  }
}
