


<div class="container">
    <div class="card custom-table-border">
        <div class="card-header border-0 text-center bg-primary rounded-top-23 ">
            <h1 class="fw-bold text-white mt-2">{{'Dashboard'|translate}}</h1>
        </div>
        <div class="card-body">
            <div class="card-body p-0">
                <div class="row my-3">
                    <div class="col-lg-6">
                        <div class="row ">
                            <div class="col-lg-6 mb-3 text-center" *ngIf="soldData?.addonsQty!=0">
                                <div class="card dash-card-top">
                                    <span class="pt-2">{{'Add-ons sold'|translate}}</span>
                                    <p-chart type="doughnut" [data]="dataAddOnsSold"></p-chart>
                                    <h5 class="fw-bold">{{soldData?.addonsQty}}/{{soldData?.soldAddons}}</h5>
                                </div>
                            </div>
                            <div class="col-lg-6 mb-3 text-center">

                                <p-overlayPanel #op>
                                    <ng-template pTemplate="content">
                                        <p-table [value]="plugin" selectionMode="single"
                                            (onRowSelect)="onRowSelect($event, op)" responsiveLayout="scroll">
                                            <ng-template pTemplate="body" let-rowData let-product>
                                                <tr [pSelectableRow]="rowData">
                                                    <td>{{product|translate}}</td>
                                                </tr>
                                            </ng-template>
                                        </p-table>
                                    </ng-template>
                                </p-overlayPanel>
                                <div class="card dash-card-top">
                                    <div class=" d-flex justify-content-between px-3 pt-3">
                                        <span class="text-center ">{{'Tickets Sold'|translate}} </span>
                                        <span class="cursor-pointer" (click)="op.toggle($event)"><i class="far fa-ellipsis-v"></i></span>
                                    </div>



                                    <p-chart type="doughnut" [data]="dataTicketSales"></p-chart>
                                    <!-- <p-chart type="polarArea" [data]="data1"  ></p-chart> -->
                                    <!-- <p-chart type="pie" [data]="data1"  ></p-chart> -->
                                    <h5 class="fw-bold">{{ticketsQty}}/{{ticketsales}}</h5>
                                </div>
                            </div>
                            <!-- <div class="col-lg-6 mb-3 text-center">
                        <div class="card dash-card-top">
                            <span>Add-ons sold</span>
                            <p-chart type="doughnut" [data]="dataAddOnsSold"></p-chart>
                            <h5 class="fw-bold">10000000/10000000</h5>
                            <aside>$100000000 net sales</aside>
                            <aside>Open <a class="text-primary" href="javascript:;">add-on sales report</a></aside>
                        </div>
                    </div> -->
                            <div class="col-lg-6 mb-3 text-center">
                                <div class="card dash-card-top h-100">
                                    <span class="pt-3">{{'Page Views'|translate}}</span>
                                    <p-chart type="doughnut" [data]="dataPageView"></p-chart>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 text-center">
                        <div class="card dash-card-top">
                            <span>{{'Orders'|translate}}</span>
                            <p-chart type="bar" [data]="basicData"></p-chart>
                            <!-- <h5 class="fw-bold">100000000</h5>
                    <aside>$100000000 from Eventbrite</aside>
                    <aside>Open <a class="text-primary" href="javascript:;">page views report</a></aside> -->
                        </div>
                    </div>
                </div>


                <div class="row  border-top border-primary pt-3">
                    <div class="col-lg-8">
                        <div class=" mb-3">
                            <div class="">
                                <p-toast></p-toast>
                                <div class="card custom-table-border ">
                                    <p-table #dt responsiveLayout="stack" [breakpoint]="'991px'" [value]="data"
                                        [rows]="5" [paginator]="data.length>5" [globalFilterFields]="['name']"
                                        [tableStyle]="{'max-width':'100%'}" [rowHover]="true" dataKey="id"
                                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                                        [showCurrentPageReport]="true" class="">
                                        <ng-template pTemplate="caption">
                                            <h3 class="fw-bold text-center">{{'Sales type'|translate}}</h3>

                                            <!-- <div>
                        <div class="d-sm-flex d-block align-items-center justify-content-sm-between text-center">
                        <div class="p-input-filled me-md-3 ">
                        <span class="p-input-icon-left">
                            <i class="pi pi-search"></i>
                            <input pTooltip="Enter ticket name for search" showDelay="1000" tooltipPosition="left" pInputText type="text" class="form-control" (input)="dt.filterGlobal($event.target.value, 'contains')"
                                placeholder="Search..." />
                        </span>
                        </div>
                    </div>
                    </div> -->
                                        </ng-template>
                                        <ng-template pTemplate="header" class="">
                                            <tr>
                                                <th class="fw-bold text-center">{{'Ticket'|translate}}</th>
                                                <th pSortableColumn="title" style="min-width:7rem"
                                                    class="fw-bold text-center">
                                                    {{'Price'|translate}} </th>
                                                <th pSortableColumn="totalQty" style="min-width:7.8rem"
                                                    class="fw-bold text-center ">{{'TicketSold'|translate}} </th>
                                            </tr>
                                        </ng-template>
                                        <ng-template pTemplate="body" let-ticket>
                                            <tr>
                                                <td class="text-md-center padding-custom text-lenght-larg text-end">
                                                    <span class="headers-cutom text-start fw-bold ">{{'Ticket'|translate}}</span> <span
                                                        class="text-lenght"> {{ticket.name}}
                                                        </span></td>
                                                <td class="text-md-center padding-custom text-end"><span
                                                        class="headers-cutom text-start fw-bold ">{{'Price'|translate}}</span>{{ticket.price==0?('free'|translate):ticket.price}}
                                                        {{ticket.price==0?'':curencySympol}}
                                                </td>
                                                <td class="text-md-center padding-custom text-end"><span
                                                        class="headers-cutom text-start fw-bold ">{{'TicketSold'|translate}}</span>{{ticket.totalQty+' / '+ticket.qty}}
                                                </td>
                                            </tr>
                                        </ng-template>
                                        <ng-template pTemplate="summary">
                                            <div class="flex align-items-center justify-content-between">
                                               {{'In total there are'|translate}} {{data ? data.length : 0 }}
                                            </div>
                                        </ng-template>
                                    </p-table>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div class="col-lg-4">
                        <h5 class="mb-3">{{'Other Attendee Actions'|translate}}</h5>
                        <div class="d-flex mb-3">
                            <div class="me-3">
                                <i class="fa-regular fa-chart-column"></i>
                            </div>
                            <div>
                                <a class="text-primary" [routerLink]="['/','events','manage-event',id,'Manage-Attendees']">{{'Attendee summary report'|translate}}</a>
                            </div>
                        </div>
                        <div class="d-flex mb-3">
                            <div class="me-3">
                                <i class="fa-sharp fa-regular fa-comment"></i>
                            </div>
                            <div>
                                <a class="text-primary" [routerLink]="['/','events','manage-event',id,'Manage-Attendees']">{{'Responses to custom questions'|translate}}</a>
                            </div>
                        </div>
                        <div class="d-flex mb-3">
                            <div class="me-3">
                                <i class="fa-regular fa-ticket pi pi-pw"></i>
                            </div>
                            <div>
                                <a class="text-primary" [routerLink]="['/','events','manage-event',id,'Manage-Attendees']">{{'Go to all ticket sales'|translate}}</a>
                            </div>
                        </div>

                    </div>
                </div>
                <div class="row  pt-3">
                    <div class="col-lg-8">
                        <div class=" mb-3">
                            <div class="">
                                <p-toast></p-toast>
                                <div class="card custom-table-border">
                                    <p-table #dt responsiveLayout="stack" [breakpoint]="'991px'" [value]="oderArry"
                                        [rows]="5" [paginator]="ordertotalRecord>5" [globalFilterFields]="['name']"
                                        [lazy]="true" [totalRecords]="ordertotalRecord" (onLazyLoad)="getEvent($event)"
                                        [tableStyle]="{'max-width':'100%'}" [rowHover]="true" dataKey="id"
                                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                                        [showCurrentPageReport]="true" class="">
                                        <ng-template pTemplate="caption">
                                            <h3 class="fw-bold text-center ">{{'Recent Orders'|translate}}</h3>

                                            <!-- <div>
                        <div class="d-sm-flex d-block align-items-center justify-content-sm-between text-center">
                        <div class="p-input-filled me-md-3 ">
                        <span class="p-input-icon-left">
                            <i class="pi pi-search"></i>
                            <input pTooltip="Enter ticket name for search" showDelay="1000" tooltipPosition="left" pInputText type="text" class="form-control" (input)="dt.filterGlobal($event.target.value, 'contains')"
                                placeholder="Search..." />
                        </span>
                        </div>
                    </div>
                    </div> -->
                                        </ng-template>
                                        <ng-template pTemplate="header" class="">
                                            <tr>
                                                <th class="fw-bold text-center">{{'RsrvOrdrTitl'|translate}} </th>
                                                <th pSortableColumn="title" style="min-width:7rem"
                                                    class="fw-bold text-center">
                                                    {{'Name'|translate}} </th>
                                                <th pSortableColumn="totalQty" style="min-width:7.8rem"
                                                    class="fw-bold text-center ">{{'Quantity'|translate}} </th>
                                                <th class="fw-bold text-center">{{'Price'|translate}}</th>
                                                <th class="fw-bold text-center">{{'TcktFrmDateTitl'|translate}}</th>
                                            </tr>
                                        </ng-template>
                                        <ng-template pTemplate="body" let-order>
                                            <tr>
                                                <td class="text-md-center padding-custom  text-end">
                                                    <span class="headers-cutom text-start fw-bold ">{{'RsrvOrdrTitl'|translate}}</span> <span
                                                        class="text-lenght">{{order.orderNum}} </span></td>
                                                <td class="text-md-center padding-custom text-lenght-larg text-end"><span
                                                        class="headers-cutom text-start fw-bold "> {{'Name'|translate}}</span><span
                                                        class="text-lenght">{{order.name}}</span>
                                                </td>
                                                <td class="text-md-center padding-custom text-end"><span
                                                        class="headers-cutom text-start fw-bold ">{{'Quantity'|translate}}</span>{{order.quantity}}
                                                </td>
                                                <td class="text-md-center padding-custom text-end"><span
                                                        class="headers-cutom text-start fw-bold ">{{'Price'|translate}}</span>{{order.totalAmount}}{{curencySympol}}
                                                </td>
                                                <td class="text-md-center padding-custom text-end"><span
                                                        class="headers-cutom text-start fw-bold ">{{'TcktFrmDateTitl'|translate}}</span>{{getDateOntomeZone(order.orderDate)}}
                                                </td>
                                            </tr>
                                        </ng-template>
                                        <ng-template pTemplate="summary">
                                            <div class="flex align-items-center justify-content-between">
                                                {{'In total there are'|translate}} {{ordertotalRecord!=0 ? ordertotalRecord : 0 }}

                                            </div>
                                        </ng-template>
                                    </p-table>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4">
                        <div class="d-flex mb-3">
                            <div class="me-3">
                                <i _ngcontent-pot-c237="" class="fa-regular fa-chart-column"></i>
                            </div>
                            <div>
                                <a class="text-primary" routerLink="/user-order">{{'Go to all Orders'|translate}}</a>
                            </div>
                        </div>

                    </div>
                </div>

                <div class="row  pt-3" *ngIf="WaitlistMemmber.length!=0">
                    <div class="col-12">
                        <div class=" mb-3">
                            <div class="">
                                <p-toast></p-toast>
                                <div class="card custom-table-border">
                                    <p-table #dt responsiveLayout="stack" [breakpoint]="'991px'" [value]="WaitlistMemmber"
                                        [rows]="5" [paginator]="waitlistlong>5" [globalFilterFields]="['name']"
                                        [lazy]="true" [totalRecords]="waitlistlong" (onLazyLoad)="GetTeam($event)"
                                        [tableStyle]="{'max-width':'100%'}" [rowHover]="true" dataKey="id"
                                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                                        [showCurrentPageReport]="true" class="">
                                        <ng-template pTemplate="caption">
                                            <h3 class="fw-bold text-center ">{{'WaitListTitl1'|translate}} </h3>

                                            <!-- <div>
                        <div class="d-sm-flex d-block align-items-center justify-content-sm-between text-center">
                        <div class="p-input-filled me-md-3 ">
                        <span class="p-input-icon-left">
                            <i class="pi pi-search"></i>
                            <input pTooltip="Enter ticket name for search" showDelay="1000" tooltipPosition="left" pInputText type="text" class="form-control" (input)="dt.filterGlobal($event.target.value, 'contains')"
                                placeholder="Search..." />
                        </span>
                        </div>
                    </div>
                    </div> -->
                                        </ng-template>
                                        <ng-template pTemplate="header" class="">
                                            <tr>
                                                <th class="fw-bold text-center">{{'Email'|translate}} </th>
                                                <th pSortableColumn="title" style="min-width:7rem"
                                                    class="fw-bold text-center">
                                                    {{'Name'|translate}} <p-sortIcon field="titel"></p-sortIcon></th>
                                                <th pSortableColumn="totalQty" style="min-width:7.8rem"
                                                    class="fw-bold text-center ">{{'Phone'|translate}} <p-sortIcon
                                                        field="totalQty"></p-sortIcon></th>
                                                <th class="fw-bold text-center">{{'Action'|translate}}</th>


                                            </tr>
                                        </ng-template>
                                        <ng-template pTemplate="body" let-m>
                                            <tr>
                                                <td class="text-md-center padding-custom  text-end">
                                                    <span class="headers-cutom text-start fw-bold ">{{'Email'|translate}}</span> <span
                                                        class="text-lenght">{{m.email}} </span></td>
                                                <td class="text-md-center padding-custom text-lenght-larg text-end"><span
                                                        class="headers-cutom text-start fw-bold ">{{'Name'|translate}}</span><span
                                                        class="text-lenght">{{m.name}}</span>
                                                </td>
                                                <td class="text-md-center padding-custom text-end"><span
                                                        class="headers-cutom text-start fw-bold ">{{'Phone'|translate}}</span>{{m.phone}}
                                                </td>
                                                <td class="text-md-center padding-custom text-end">
                                                    <div [class.action-custom]="document.documentElement.dir === 'ltr'"
                                                    [class.action-custom-rtl]="document.documentElement.dir === 'rtl'">
                                                        <button pButton pRipple icon="pi pi-send"
                                                            class="p-button-rounded p-button-primary mx-2" (click)="postEmail($event,m.id)" ></button>

                                                    </div>

                                                    <!-- <button pButton pRipple icon="pi pi-trash"
                                                    class="p-button-rounded p-button-danger m-1"
                                                    (click)="remove($event,event.id)"></button> -->


                                                </td>


                                            </tr>
                                        </ng-template>
                                        <ng-template pTemplate="summary">
                                            <div class="flex align-items-center justify-content-between">
                                                {{'In total there are '|translate}}{{ordertotalRecord!=0 ? ordertotalRecord : 0 }}

                                            </div>
                                        </ng-template>
                                    </p-table>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

                <div class="row  ">
                    <div class="col-lg-8 mb-3">
                        <h3 class="fw-bold">{{'Share'|translate}}</h3>
                        <span>{{'Event URL'|translate}}</span>
                        <div class="d-flex mb-3 align-items-center">
                            <span
                                class="url-length">https://www.eventbrite.com/e/the-second-event-tickets-660417986977?aff=oddtdtcreator</span>
                            <a class="mx-2   fs-5 " href="javascript:;"><i class="fa-regular fa-pen"></i></a>
                            <a class="fs-5 " href="javascript:;"><i class="fa-light fa-copy"></i></a>
                        </div>
                        <span>{{'Share on'|translate}}</span>
                        <div>
                            <a class="text-primary fs-4 me-2" href="javascript:;"><i
                                    class="fa-brands fa-facebook"></i></a>
                            <a class="text-primary fs-4 me-2" href="javascript:;"><i
                                    class="fa-brands fa-facebook-messenger"></i></a>
                            <a class="text-primary fs-4 me-2" href="javascript:;"><i
                                    class="fa-brands fa-twitter"></i></a>
                            <a class="text-primary fs-4 me-2" href="javascript:;"><i
                                    class="fa-solid fa-envelope"></i></a>
                            <a class="text-primary fs-4 me-2" href="javascript:;"><i
                                    class="fa-brands fa-linkedin"></i></a>
                            <a class="text-primary fs-4 me-2" href="javascript:;"><i
                                    class="fa-brands fa-whatsapp"></i></a>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>


</div>
