import { AfterContentInit, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { CalendarOptions, DateSelectArg, EventApi, EventClickArg, EventInput } from '@fullcalendar/core';
import interactionPlugin from '@fullcalendar/interaction';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import { DialogService } from 'primeng/dynamicdialog';
import { TicketsFormComponent } from '../tickets/tickets-form/tickets-form.component';

import { ActivatedRoute, Router } from '@angular/router';
import { CalenderService } from 'src/app/services/calender.service';
import { CalenderEvent, CalenderGetGetDto } from 'src/app/Interfaces/calenderDto';
// import multiMonthPlugin from '@fullcalendar/multimonth'
import { OverlayPanel } from 'primeng/overlaypanel';
import { DropDownItem } from 'src/app/Interfaces/DropDownItem';
import { FullCalendarComponent, } from '@fullcalendar/angular';
import { CalenderStepsService } from 'src/app/services/calender-steps.service';
import { EventStepsService } from 'src/app/services/event-steps.service';
import allLocales from '@fullcalendar/core/locales-all';
import { observable, Observable, of, Subscription } from 'rxjs';
import * as moment from 'moment';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-calender',
  templateUrl: './calender.component.html',
  styleUrls: ['./calender.component.scss']
})
export class CalenderComponent implements OnInit ,OnDestroy{
  @ViewChild('op') op: OverlayPanel;
  @ViewChild('op1') op1: OverlayPanel;
  @ViewChild('cal') cal: FullCalendarComponent;
  plugin: DropDownItem[] = [
    { name: 'Month', id: 'dayGridMonth' },
    { name: 'Week', id: 'timeGridWeek' },
    { name: 'Day', id: 'timeGridDay' },
    { name: 'List week', id: 'listWeek' },
    { name: 'Grid day', id: 'dayGridDay' },
    { name: 'Grid week', id: 'dayGridWeek' },

  ];
  inialVew: string = 'dayGridMonth'
  events: CalenderEvent[]=[];
  calendarVisible = true;
  calendarOptions: CalendarOptions = {
    customButtons: {
      AddShedular: {
        text: 'Add events',
        click: this.customFunc.bind(this)
      },
      SetThem: {
        text: 'Display mode',
        click: this.caleht.bind(this)
      }
    },
    plugins: [
      interactionPlugin,
      dayGridPlugin,
      timeGridPlugin,
      listPlugin,
    ],
    headerToolbar: {
      left: 'prev AddShedular',
      center: 'title',
      right: 'SetThem next'
    },
    initialView: this.inialVew,
    
    //initialEvents:this.events ,
    editable: false,
    selectable: true,
    selectMirror: true,
    dayMaxEvents: true,
    dateClick: (e) => this.clicged(e),
    // select: this.handleDateSelect.bind(this),
    eventClick: this.handleEventClick.bind(this),
    eventsSet: this.handleEvents.bind(this),
    customRenderingReplacesEl: true
  };
  sub: Subscription[]=[];
  addtext:string="Add scheduler";
  desplayMode:string="Display mode";
  constructor(private EventSrv: EventStepsService,
     private Srv: CalenderStepsService, private router: Router,
     private ActivatedRout: ActivatedRoute, private changeDetector: ChangeDetectorRef,
      public calServ: CalenderService, private modalServ: DialogService,
       private ActiveRout: ActivatedRoute,private translate:TranslateService) {
        this.translate.onLangChange.subscribe(() => {
          this.updateTranslations();
          this.updateCalenderrTranslate()
        });
  }

  minStartDate:any; 
  updateTranslations() {
    this.plugin = [
      { name: this.translate.instant('ShdlrTblBtn002Chois001'), id: 'dayGridMonth' },
      { name: this.translate.instant('ShdlrTblBtn002Chois002'), id: 'timeGridWeek' },
      { name: this.translate.instant('ShdlrTblBtn002Chois003'), id: 'timeGridDay' },
      { name: this.translate.instant('ShdlrTblBtn002Chois004'), id: 'listWeek' },
      { name: this.translate.instant('ShdlrTblBtn002Chois005'), id: 'dayGridDay' },
      { name: this.translate.instant('ShdlrTblBtn002Chois006'), id: 'dayGridWeek' },
  
    ];
  


 
  }

  updateCalenderrTranslate(){
    this.calendarOptions={
      locales: allLocales,
       locale:this.translate.currentLang,
      eventContent: (arg: any) => {   //add time
        let container = document.createElement('div')
        container.className = "d-flex gap-1 p-1";
        let timeText = document.createElement('div')
        timeText.className = "fc-event-time";
        timeText.innerHTML = arg.timeText;
        //include additional info
        let additionalText = document.createElement('i')
        additionalText.className = "mx-2";
        additionalText.style.fontSize = "18px"
       // additionalText.style.overflowX = "hidden"
        additionalText.innerHTML = arg.event.title;
       
    
        container.appendChild(timeText);
       
        container.appendChild(additionalText);
        //   let arrayOfDomNodes = [ timeText, additionalText, img, img1 ]
        let arrayOfDomNodes = [container]
        return { domNodes: arrayOfDomNodes }
  
      }
      ,
        customButtons: {
            AddShedular: {
                text:this.translate.instant('ShdlrTblBtn001'),
                click: this.customFunc.bind(this)
            },
            SetThem: {
                text: this.translate.instant('ShdlrTblBtn002'),
                click: this.caleht.bind(this)
            }
        },
        plugins: [
            // multiMonthPlugin,
            interactionPlugin,
            dayGridPlugin,
            timeGridPlugin,
            listPlugin,
        ],
        headerToolbar: {
            left: 'prev AddShedular',
            center: 'title',
            right: 'SetThem next'
        },
        initialView: this.inialVew,
        initialDate: this.events.length!=0&&this.events.length>1?this.minStartDate:new Date(Date.now()),
         // set the initial date to January 1, 2023
        //initialEvents:this.events , // alternatively, use the `events` setting to fetch from a feed
        weekends: true,
        editable: false,
        selectable: true,
        selectMirror: true,
        dayMaxEvents: true,
        events:this.events,
        dateClick: (e) => this.clicged(e),
       // eventChange:(e)=>console.log(e),
        // select: this.handleDateSelect.bind(this),
        eventClick: this.handleEventClick.bind(this),
        eventsSet: this.handleEvents.bind(this),
        customRenderingReplacesEl: true
    }
  }
  ngOnDestroy(): void {
   this.sub.forEach(x=>{
    x.unsubscribe();
   });
  }
  setinital(e) {
    this.inialVew = e.value;
    this.cal.getApi().changeView(this.inialVew, Date.now())
  }
  ngOnInit(): void {
    this.updateTranslations()
   
    var stat=  this.EventSrv.state$.subscribe(y=>{
          this.calServ.getClenderEvent(y.id);
       var s=   this.calServ.$getSubject.subscribe(x=>{
        //console.log(x)
              if(x.dates.length>0)
              {  //console.log("it have data")
                
                  if(x.dates.length==1&&x.dates[0].startDate==null){
                    this.router.navigate(['calender-steps'] , { relativeTo: this.ActivatedRout.parent });
                  }else{
                    this.EventSrv.TimingBool = true;
                    this.EventSrv.CalculateProgress()
                  }
              }
              
              this.events=[];
              if(x.dates.length!=0){
                  for (var i = 0; i < x.dates.length; i++) {
                      this.events.push({
                          id:x.dates[i].id,
                          title: x.title,
                          start: this.getDateOntomeZone(x.dates[i].startDate),
                          end: this.getDateOntomeZone(x.dates[i].endDate)
                      });
                  }

                  this. minStartDate = this.events.reduce((min, event) => {
                    if (event.start < min) {
                        return event.start;
                    }
                    return min;
                }, this.events[0].start);
              
           var dateForparent;
          var t= this.EventSrv.ParentDate$.subscribe(x=>{
            dateForparent=x.start;
           })
           this.sub.push(t)
                  //console.log(this.events)
                  // this.calendarOptions={
                    
                  //   eventContent: (arg: any) => {   //add time
                  //     let container = document.createElement('div')
                  //     container.className = "d-flex gap-1 p-1";
                  //     let timeText = document.createElement('div')
                  //     timeText.className = "fc-event-time";
                  //     timeText.innerHTML = arg.timeText;
                  //     //include additional info
                  //     let additionalText = document.createElement('i')
                  //     additionalText.className = "mx-2";
                  //     additionalText.style.fontSize = "18px"
                  //    // additionalText.style.overflowX = "hidden"
                  //     additionalText.innerHTML = arg.event.title;
                     
                  
                  //     container.appendChild(timeText);
                     
                  //     container.appendChild(additionalText);
                  //     //   let arrayOfDomNodes = [ timeText, additionalText, img, img1 ]
                  //     let arrayOfDomNodes = [container]
                  //     return { domNodes: arrayOfDomNodes }
            
                  //   }
                  //   ,
                  //     customButtons: {
                  //         AddShedular: {
                  //             text: this.addtext,
                  //             click: this.customFunc.bind(this)
                  //         },
                  //         SetThem: {
                  //             text: this.desplayMode,
                  //             click: this.caleht.bind(this)
                  //         }
                  //     },
                  //     plugins: [
                  //         // multiMonthPlugin,
                  //         interactionPlugin,
                  //         dayGridPlugin,
                  //         timeGridPlugin,
                  //         listPlugin,
                  //     ],
                  //     headerToolbar: {
                  //         left: 'prev AddShedular',
                  //         center: 'title',
                  //         right: 'SetThem next'
                  //     },
                  //     initialView: this.inialVew,
                  //     initialDate: this.events.length!=0&&this.events.length>1?this.minStartDate:new Date(Date.now()),
                  //      // set the initial date to January 1, 2023
                  //     //initialEvents:this.events , // alternatively, use the `events` setting to fetch from a feed
                  //     weekends: true,
                  //     editable: false,
                  //     selectable: true,
                  //     selectMirror: true,
                  //     dayMaxEvents: true,
                  //     events:this.events,
                  //     dateClick: (e) => this.clicged(e),
                  //    // eventChange:(e)=>console.log(e),
                  //     // select: this.handleDateSelect.bind(this),
                  //     eventClick: this.handleEventClick.bind(this),
                  //     eventsSet: this.handleEvents.bind(this),
                  //     customRenderingReplacesEl: true
                  // }
                  this.updateCalenderrTranslate() ;
              }

          });
          this.sub.push(s)

      });

      this.sub.push(stat)

  }

  getDateOntomeZone(d: Date | number) {
    var eventOffset = this.EventSrv.offSet * 60 ;
    var MyDate = new Date(d).setMinutes(new Date(d).getMinutes()  + (eventOffset));
    return new Date(MyDate);
  }
  // getDateOntomeZone(d:Date){
  //   var t= new Date(Date.now()).getTimezoneOffset()
  //   var MyDate=new Date(d).setMinutes(new Date(d).getMinutes()-(new Date(Date.now()).getTimezoneOffset()));
  //   return new Date(MyDate);
  // }
  clicged(e) {
    const calendarApi = e.view.calendar;
    if(moment(e.date).isBefore(new Date(Date.now()))){
      this.Srv.clenderSteps.controls['startDate'].setValue( new Date(new Date(new Date(Date.now()).setHours(0)).setMinutes(0)))
    }else{
      this.Srv.clenderSteps.controls['startDate'].setValue( new Date(new Date(new Date(e.date).setHours(0)).setMinutes(0)))
    }
    this.router.navigate(['calender-steps'] , { relativeTo: this.ActivatedRout.parent });
  }
  handleCalendarToggle() {
    this.calendarVisible = !this.calendarVisible;

  }

  handleWeekendsToggle() {
    const { calendarOptions } = this;
    calendarOptions.weekends = !calendarOptions.weekends;
  }

  handleDateSelect(selectInfo: DateSelectArg) {
    const calendarApi = selectInfo.view.calendar;
    calendarApi.unselect(); // clear date selection
  }

  handleEventClick(clickInfo: EventClickArg) {
    this.router.navigate(['single-update',clickInfo.event.id ], { relativeTo: this.ActivatedRout.parent });
  }

  handleEvents(events: EventApi[]) {
    this.changeDetector.detectChanges();
  }
  customFunc() {
    this.router.navigate(['calender-steps'], { relativeTo: this.ActivatedRout.parent });
  }
  caleht(e) {
    this.op.toggle(e.nativeEvent)
  }
  onRowSelect(e, op: OverlayPanel) {
     this.inialVew = e.data.id;
     this.cal.getApi().changeView(this.inialVew, this.events.length!=0?this.events[0].start:new Date(Date.now()).toLocaleDateString
     ());
     op.hide();
}
}
