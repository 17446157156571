import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormArrayName, FormBuilder, FormGroup } from '@angular/forms';
import { eventDragMutationMassager } from '@fullcalendar/core/internal';
import { MessageService } from 'primeng/api';
import { Subscription } from 'rxjs';
import { OrderConformationDto } from 'src/app/Interfaces/EventData';
import { EventStepsService } from 'src/app/services/event-steps.service';
import { EventsService } from 'src/app/services/events.service';

@Component({
  selector: 'app-order-confirmation',
  templateUrl: './order-confirmation.component.html',
  styleUrls: ['./order-confirmation.component.scss']
})
export class OrderConfirmationComponent implements OnInit,OnDestroy  {
    oreForm!:FormGroup;
    subs:Subscription[]=[];
    constructor(private Fb:FormBuilder,
        private SRv:EventStepsService,
        private EventSrv:EventsService,private messageService: MessageService){

    }
    ngOnDestroy(): void {
        this.subs.forEach(x=>{
            x.unsubscribe();
        })
    }

    onSubmit(){

        const data = this.oreForm.value as OrderConformationDto;
       // console.log(this.oreForm.value)
        this.EventSrv.postEventOrderConfirmation(data).subscribe(x=>{
            this.getData(data.eventId);
            this.messageService.add({ severity: 'success', summary: 'Saved Successfully' });
        })
    }
    ngOnInit(): void {
        this.intialForm("0","0","","","",false);
       var l= this.SRv.state$.subscribe(x=>{
    
                 this.getData(x.id);
           
        });
        this.subs.push(l)
    }

    getData(id){
        this.EventSrv.GetEventOrderConfiramtion(id).subscribe(y=>{
        //    console.log(y);
            if(y!=null){
             this.intialForm(y.id,y.eventId,y.pageMessage,y.replayEmail,y.replayMessage,y.canprint);
            }else{
                this.intialForm("0",id,"","","",false);
            }
        })
    }

    intialForm(id:string,eventId:string,pageMessage:string,replayEmail:string,replayMessage:string,canprint:boolean){
  this.oreForm=this.Fb.group({
    id           :[id ],
	eventId      :[eventId],
	pageMessage  :[pageMessage],
	replayEmail  :[replayEmail  ],
	replayMessage:[replayMessage],
	canprint     :[canprint     ],
  })
    }

    onTextWrite() {
      //  console.log('Text Was writed');

    }

    onEmailChange() {
      //  console.log('Email');

    }

    onTextChange() {
       // console.log('Text area');

    }

    onSelect() {
       // console.log('Selected');
    }

    onChecked() {
     //   console.log('Checked');
    }
}
