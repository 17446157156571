import { Component, OnDestroy, OnInit } from '@angular/core';
import { RolesServiceService } from '../roles.service';
import { ActivatedRoute, Router } from '@angular/router';
import { LoaderService } from 'src/app/services/loader.service';
import { transition, style, animate, trigger } from '@angular/animations';
import { FormBuilder, FormGroup } from '@angular/forms';
import { TreeNode } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';

const leaveTrans = transition(':leave', [
  style({
      opacity: 1
  }),
  animate('1s ease-out', style({
      opacity: 0
  }))
])



const fadeOut = trigger('fadeOut', [
  leaveTrans
]);


@Component({
  selector: 'app-roles-form',
  templateUrl: './roles-form.component.html',
  styleUrls: ['./roles-form.component.scss'],
  animations : [fadeOut]
})
export class RolesFormComponent implements OnInit , OnDestroy{
  submitted : boolean;
  id : number = 101050301;
  i: number;





  ngOnInit(): void {
    this.rolesrv.prepareform()
  }


  constructor(private fb : FormBuilder , public loader : LoaderService,
    public rolesrv : RolesServiceService ,
     private router : Router , private activatedRoute : ActivatedRoute,private translate :TranslateService ) {
     this.translate.onLangChange.subscribe(x=>{
    this.rolesrv.prepareform()
     })

  }



  onCategoryChange(e){
  //  console.log(this.rolesrv.form)
     this.id = e.value

  }
  ngOnDestroy(): void {

  }
  cancel(){
    this.rolesrv.selectedItems = [];
    this.rolesrv.newData = [];
    this.rolesrv.newData1 = [];
    this.router.navigate(['roles-table'
    ],{relativeTo: this.activatedRoute.parent});

  }
  filterItems() {
    this.rolesrv.newSelection =
      this.rolesrv.selectedItems.filter((e) => e.key > 101050305).map(x => x.key)

  }

  async submit(){
   // console.log(this.rolesrv.selectedItems)
    this.filterItems();


    if(this.rolesrv.form.valid){
     this.rolesrv.submit(this.activatedRoute.parent.parent.parent.snapshot.paramMap.get('id'));
     this.router.navigate(['roles-table'] ,{relativeTo: this.activatedRoute.parent});
     this.rolesrv.selectedItems = [];
     this.rolesrv.newData = [];
     this.rolesrv.newData1 = [];
     this.router.navigate(['roles-table'
     ],{relativeTo: this.activatedRoute.parent});
  }
  }

}
