<form [formGroup]="myForm">
    <ng-container *ngFor="let control of jsonFormData">
        <ng-container *ngIf="control.isShown">
            <ng-container [ngSwitch]="control.type">
                <ng-container *ngSwitchCase="'short'">
                    <div class="p-input-filled col-12 p-0 my-3">
                        <span class="p-float-label">
                            <input type="text" pInputText class="form-control" [class.ng-invalid.ng-dirty]="" formControlName="{{control.question}}"/>
                            <label class="form-lable font-size-14">{{control.question|translate}}</label>
                        </span>
                    </div>
                </ng-container>

                <ng-container *ngSwitchCase="'paragraph'">
                    <div class="p-input-filled col-12  p-0 my-3">
                        <span class="p-float-label">
                            <textarea rows="1" type="text" pInputText class="form-control" formControlName="{{control.question}}"
                                [class.ng-invalid.ng-dirty]=""></textarea>
                            <label class="font-size-14">{{control.question|translate}}</label>
                        </span>
                    </div>
                </ng-container>

                <ng-container *ngSwitchCase="'checkbox'">
                    <div class="col-12 p-0 my-2">
                        <div class="p-input-filled col-12 p-0 my-3">
                            <div class="p-input-filled  ">
                                <span class="p-float-label">
                                    <p-multiSelect
                                    pTooltip="Select which instance of event will have this tickets"
                                    showDelay="1000"
                                    tooltipPosition="top"
                                    [options]="control.options"
                                    [styleClass]="'w-100'"
                                    [optionLabel]="'text'"
                                    [optionValue]="'text'"
                                    display="chip"
                                    appendTo="body"
                                    formControlName="{{control.question}}"
                                   >
                                    </p-multiSelect>
                                    <label class="form-label font-size-14">
                                        {{control.question|translate}}
                                    </label>
                                </span>
                            </div>
                        </div>
                    </div>
                </ng-container>

                <ng-container *ngSwitchCase="'radio'">
                    <div class="col-12 p-0 my-2">
                        <h5 class="">{{control.question|translate}}</h5>
                        <div class="row">
                            <div class="col-12">
                                <div class="border-custom p-2">
                                    <span *ngFor="let a of control.options; let i = index">
                                        <input  type="radio" class="btn-check " id="{{'radio-'+i}}"
                                            value="{{a.text}}" formControlName="{{control.question}}" />
                                        <label class="btn btn-outline-primary me-2  my-2" for="{{'radio-'+i}}">
                                            {{a.text|translate}}
                                        </label>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-container>

                <ng-container *ngSwitchCase="'select'">
                    <div class="p-input-filled col-12 p-0 my-3">
                        <div class="p-input-filled  ">
                            <span class="p-float-label">
                                <p-dropdown appendTo="body"  [autoDisplayFirst]="false" [showClear]="true" [filter]="true" [styleClass]="'form-control'" formControlName="{{control.question}}"
                                    [options]="control.options" optionLabel="text" optionValue="text"></p-dropdown>
                                <label class="form-label font-size-14">
                                    {{control.question|translate}}
                                </label>
                            </span>
                        </div>
                    </div>
                </ng-container>

                <ng-container *ngSwitchCase="'terms'">
                    <div class="card border-custom">
                        <div class="card-title ps-3 pt-3">
                            <span class="fw-bold">{{'Terms&conditions'|translate}}</span>
                        </div>
                        <div class="card-body">
                            <span> {{control.answer|translate}}</span>
                        </div>
                    </div>
                    <div class=" row my-3  text-start">
                        <div class="square-switch">
                            <p-inputSwitch class="pe-2" name="acceptTerms" #acceptTerms formControlName="{{control.question}}"></p-inputSwitch>
                            <label for="acceptTerms">
                                {{control.question|translate}}
                            </label>
                        </div>

                    </div>
                </ng-container>
            </ng-container>
        </ng-container>


    </ng-container>
</form>

