<div class="container">
    <div class="row">
        <div class="col-12">
            <image-cropper
                (cropperReady)="cropperReady()"
                (imageCropped)="imageCropped($event)"
                (imageLoaded)="imageLoaded1()"
                (loadImageFailed)="loadImageFailed()"
                [aspectRatio]="aspectRatio"
                [cropperMinWidth]="200"
                [imageFile]="myFile"
                [maintainAspectRatio]="true"
                [format]="format"
                [imageQuality]="quality"
            ></image-cropper>
        </div>
        <div class="col-12 d-flex justify-content-center">
            <button pButton type="button" label="{{'EvntProfImgInpt001Btn'|translate}}" icon="pi pi-check" class="p-button-text" (click)="onCropButton()"></button>
            <button pButton type="button" label="{{'Cancel'}}" icon="pi pi-times" class="p-button-text" (click)="cancel()"></button>
        </div>
    </div>
</div>
