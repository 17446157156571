import { Injectable } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { AddOnCreateDto } from '../Interfaces/addOnsIntreFacesDto';
import { AddOnsService } from './add-ons.service';

@Injectable({
    providedIn: 'root'
})
export class AddOnsStepsService {
    AddonsForm!: FormGroup;
    Isinitail:boolean=false;
    constructor(private fb: FormBuilder, private addOnsSrv: AddOnsService) {
        this.init("0", null, "", null, false, 0, 0, "", 1, 10,new Date(new Date(new Date(Date.now()).setHours(0)).setMinutes(0)), null, false, false, false, false, "0",null,1);
    }
    init(id: string, name: string, image: string, 
      eventId: string, imageChanged: boolean, 
      quantity: number, price: number, description: string,
         minPerOrder: number, maxPerOrder: number, startDate: Date, endDate: Date, hidden: boolean, eTicket: boolean, willCall: boolean,
         hasGroup: boolean, groupId: string,cat:string,soldItem:number) {
        this.AddonsForm = this.fb.group({
            id: [id],
            name: [name, [Validators.required]],
            image: [image, [this.validImage()]],
            eventId: [eventId],
            imageChanged: [imageChanged],
            quantity: [quantity, [Validators.required , Validators.min(soldItem)]],
            price: [price,[Validators.required , Validators.min(0.01)]],
            description: [description, [Validators.required]],
            minPerOrder: [minPerOrder, [Validators.required]],
            maxPerOrder: [maxPerOrder,[Validators.required,this.morethan('minPerOrder') , this.lessthan("quantity")]],
            startDate: [startDate, [Validators.required]],
            endDate: [endDate, [Validators.required,this.VlaidEndDate('startDate')]],
            hidden: [hidden, [Validators.required]],
            eTicket: [eTicket],
            willCall: [willCall],
            hasGroup: [hasGroup],
            groupId: [groupId],
            categoryId:[cat,[Validators.required]]
        },{validators:this.ValidDel()});

        this.AddonsForm.controls.minPerOrder.valueChanges.subscribe(()=>{
            this.AddonsForm.controls.maxPerOrder.updateValueAndValidity();
          });

          this.AddonsForm.controls.startDate.valueChanges.subscribe(()=>{
            this.AddonsForm.controls.endDate.updateValueAndValidity();
          })
          this.AddonsForm.controls.willCall.valueChanges.subscribe(()=>{
            this.AddonsForm.updateValueAndValidity()
          })
          this.AddonsForm.controls.eTicket.valueChanges.subscribe(()=>{
            this.AddonsForm.updateValueAndValidity()
          })



    }
    validationGroups():ValidatorFn{
        return (control:AbstractControl)=>{
                 return control?.value!="0"?null:{IsNotInGroups:true}
              }
      }
    morethan(param:string):ValidatorFn{
        return (control:AbstractControl)=>{
            return control?.value>=control?.parent?.controls[param].value?null:{isMatched:true}
              }
      }
      lessthan(param:string):ValidatorFn{
        return (control:AbstractControl)=>{
                 return control?.value<=control?.parent?.controls[param].value?null:{isValid:true}
              }
      }

 ValidDel():ValidatorFn{
        return (group: FormGroup)=>{
                 return (group?.controls['willCall'].value||group?.controls['eTicket'].value)?null:{ValidDev:true}
              }
      }
      validImage():ValidatorFn{
        return (control:AbstractControl)=>{
                 return control?.value!=""?null:{imageisnull:true}
              }
      }

      VlaidEndDate(param:string):ValidatorFn{
        return (control:AbstractControl)=>{
         var s=new Date(control?.value);
         var e=new Date(control?.parent?.controls[param].value);
          return s>e ?null:{IsValidDate:true}
        }
      }
    finish() {
        var data = this.AddonsForm.value as AddOnCreateDto;
       // console.log(data);
        if(data.id=="0"){
            this.addOnsSrv.postAddOns(data).subscribe(x => {this.addOnsSrv.getallData(data.eventId);});
            this.clear();
        }
        else{
             this.addOnsSrv.UpdateAddOns(data).subscribe(x=> {this.addOnsSrv.getallData(data.eventId);});
             this.clear();
        }
    }
    clear() {
        this.init("0", null, "", null, false, 0, 0, "", 1, 10, new Date(new Date(new Date(Date.now()).setHours(0)).setMinutes(0)), null, false, false, false, false, "0",null,1);
    }

    setsomeValidation(param:boolean){
        if(param){
            this.AddonsForm.controls.groupId.setValidators([Validators.required,this.validationGroups()])
            this.AddonsForm.controls.groupId.updateValueAndValidity();
        }else{
            this.AddonsForm.controls.groupId.setValidators([])
            this.AddonsForm.controls.groupId.setValue("0")
            this.AddonsForm.controls.groupId.updateValueAndValidity();
        }


    }
}
