import { Component, EventEmitter, Input, Output,Inject } from '@angular/core';
import { environment } from 'src/environments/environment';
import { EventCard } from '../../pages/landing-page/landing-page.service';
import { UserActionsService } from 'src/app/services/user-actions.service';
import { DOCUMENT, DatePipe } from '@angular/common';
import * as m from 'moment';
import 'moment/locale/pt-br';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-search-event-card',
  templateUrl: './search-event-card.component.html',
  styleUrls: ['./search-event-card.component.scss']
})
export class SearchEventCardComponent {
     @Input() item: EventCard;
    @Input() Liked = false;
    @Input() filters: any;
    @Output() likedClicked: EventEmitter<string> = new EventEmitter<string>();
    url = environment.Api;
    constructor(public ua: UserActionsService,private datePipe: DatePipe, @Inject(DOCUMENT) public document: Document,private translate:TranslateService) {
        this.translate.onLangChange.subscribe(() => {
            m.locale(this.translate.currentLang);
          });
    }
    Like(x){
        this.likedClicked.emit(x);
    }

    getDateOntomeZone(d){

       
                let t = new Date(Date.now()).getTimezoneOffset();
        let MyDate = new Date(d).setMinutes(new Date(d).getMinutes() - (t));
        if(new Date(MyDate).getFullYear()==new Date(Date.now()).getFullYear()){
            var l= this.datePipe.transform(new Date(MyDate), 'EEE MMMM d,  hh:mm a');
            return l;
        }else{
            var l= this.datePipe.transform(new Date(MyDate), 'EEE MMMM d,y,  hh:mm a');
            return l;
        }
       // return new Date(MyDate).toLocaleString();
    }

    getDateOntomeZoneformat2(d){
       
        m.locale();
          let t = new Date(Date.now()).getTimezoneOffset();
          let MyDate = new Date(d).setMinutes(new Date(d).getMinutes() - (t));
          var l="";
        if(new Date(MyDate).getFullYear()==new Date(Date.now()).getFullYear()){
              l= m(new Date(MyDate)).format('ddd MMMM D, hh:mm a');
        
          }else{
             l=  m(new Date(MyDate)).format( 'ddd MMMM D,y, hh:mm a');
        
          }
          
          return l
        
        
        
        
         // return new Date(MyDate).toLocaleString();
        }
}
