import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DropDownItem } from 'src/app/Interfaces/DropDownItem';
import { AddOnGroupStepsService } from 'src/app/services/add-on-group-steps.service';
import { AddOnsService } from 'src/app/services/add-ons.service';
import { EventStepsService } from 'src/app/services/event-steps.service';
import { EventsService } from 'src/app/services/events.service';

@Component({
  selector: 'app-group-info',
  templateUrl: './group-info.component.html',
  styleUrls: ['./group-info.component.scss']
})
export class GroupInfoComponent implements OnInit {
  submitted:boolean=false;
  cat:DropDownItem[]=[];
  constructor(private router: Router, private activatedRoute: ActivatedRoute
    , private eventStepsSrv: EventStepsService,
    public Srv: AddOnGroupStepsService,private AddOnsSrv:AddOnsService,private AttrSrv:EventsService) {

  }
  ss(e){
  //  console.log(e)
  }
  ngOnInit(): void {
    if(this.Srv.AddonsGroupForm.controls['id'].value!="0"){
      this.croppedImage = this.AddOnsSrv.getImage(this.Srv.AddonsGroupForm.controls['image'].value);

    }
    this.AttrSrv.GetAttributes(10112).subscribe(x=>{
      //console.log(x);
    this.cat=x})

  }
  croppedImage: string = "";
  setPhoto(e) {
    this.Srv.AddonsGroupForm.controls['image'].setValue(e)
  }
  setIsChanged(e) {
    this.Srv.AddonsGroupForm.controls['imageChanged'].setValue(e)
  }
  cancel() {
    this.Srv.clear();
    this.router.navigate( ['Add-ons'] ,{relativeTo: this.activatedRoute.parent.parent.parent});
  }
  submit() {
    if(this.validateGroupInfo()){
      this.router.navigate( ['vareities'] , { relativeTo: this.activatedRoute.parent });
    }else{
      this.submitted=true;
    }

  }
  validateGroupInfo(){
    return   this.Srv.AddonsGroupForm.controls['name'].valid&&this.Srv.AddonsGroupForm.controls['image'].valid&&this.Srv.AddonsGroupForm.controls['categoryId'].valid;
   }
}
