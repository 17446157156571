<div class='container'>
    <full-calendar  #cal *ngIf='calendarVisible' [options]='calendarOptions'  >
    </full-calendar>
    <p-overlayPanel #op [style]="{'width': '200px'}" [showCloseIcon]="true">
        <ng-template pTemplate="content">
            <p-table [value]="plugin" selectionMode="single" [(selection)]="selectedProduct" (onRowSelect)="onRowSelect($event, op)"  responsiveLayout="scroll">
                <ng-template pTemplate="body" let-rowData let-product>
                    <tr [pSelectableRow]="rowData">
                        <td>{{product.name}}</td>
                    </tr>
                </ng-template>
            </p-table>
        </ng-template>
    </p-overlayPanel>
  <p-overlayPanel #op1 [style]="{'width': '300px','border-radius':'50px'}" [showCloseIcon]="true"   appendTo="body"  >
    <form  >
      <div class="row mb-2">
          <div class="p-input-filled col-sm-12 col-md-12 mb-2">
              <span class="p-float-label">
                  <p-dropdown inputId="TicketType" [styleClass]="'w-100'" [autoDisplayFirst]="false"
                      [options]="plugin" optionLabel="name" optionValue="id" (onChange)="setinital($event)"  ></p-dropdown>
                  <label class="form-lable font-size-14" for="TicketType" >Type</label>
              </span>
          </div>
      </div>
  </form>
</p-overlayPanel>
  </div>
