
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { EventStepsService } from '../services/event-steps.service';

@Injectable({
  providedIn: 'root'
})
export class RouteGuard implements CanActivate {
  constructor(private AuthService: AuthService,
    private router: Router ,
    private event : EventStepsService) { }
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

      let claimType: number = route.data["claimType"];
      let roleType: string = route.data["roleType"];

     

      let isAuth = this.AuthService.securityObject.isAuthenticated;
      let isPropTrue = this.AuthService.securityObject.checkClaim(claimType)
      let isAdmintrue=this.AuthService.securityObject.checkRole(roleType)
     // console.log(route);
      
      if(isAuth && claimType == 0){

        return true;
      }
      
      if(!isAuth){
        this.router.navigate(['login'],
        { queryParams: { returnUrl: state.url } });
        return false;
      }

      if ((isAuth && isPropTrue)||(isAuth && isAdmintrue)) {


        return true;
      }
      else {
        this.router.navigate(['notallowed']);
        return false;
      }
  }

}
