import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { ReplaySubject } from 'rxjs/internal/ReplaySubject';
import { Subject } from 'rxjs/internal/Subject';


@Component({
  selector: 'app-text-editor',
  templateUrl: './text-editor.component.html',
  styleUrls: ['./text-editor.component.scss']
})
export class TextEditorComponent implements OnInit  {
ngOnInit(): void {
    this.subject = new Subject<string>();
    this.subject.next(this.val)
}
@Input() val:string ;
public subject!:Subject<string>;

issa(e){

 this.subject.next(e)

}
}
