import { Component ,OnInit} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-team-manage',
  templateUrl: './team-manage.component.html',
  styleUrls: ['./team-manage.component.scss']
})
export class TeamManageComponent implements OnInit{
  activeItem: { label: string; icon: string; routerLink: string[]; };
  ngOnInit(): void {
    // this.items =[
    //   {label: 'Team members', icon: 'fa-solid fa-user-plus', routerLink: ['team-members'] },
    //   {label: 'Roles', icon: 'fa-solid fa-user-gear',routerLink: ['team-roles/roles-table']  },
    //   {label: 'Invitatoins', icon: 'fa-solid fa-user-gear',routerLink: ['invite-member']}
    //  ]
    this.updateTranslations()
     this.activeItem = this.items[2];
     
    // this.router.navigate( ['invite-member'] ,{relativeTo: this.activatedRoute});
  }
  constructor(private router:Router,private activatedRoute:ActivatedRoute,private translate:TranslateService){
    this.translate.onLangChange.subscribe(() => {
      this.updateTranslations();
    });
  }
  updateTranslations() {
    this.items = [
      {label: this.translate.instant('TemMmbrsTblTitl'), icon: 'fa-solid fa-user-plus', routerLink: ['team-members'] },
      {label: this.translate.instant('RolsTblTitl'), icon: 'fa-solid fa-user-gear',routerLink: ['team-roles']  },
      {label: this.translate.instant('InvtionTblTitl'), icon: 'fa-solid fa-user-gear',routerLink: ['invite-member']}
     ];
  }
  items = [
    {label: 'Team members', icon: 'fa-solid fa-user-plus', routerLink: ['team-members'] },
    {label: 'Roles', icon: 'fa-solid fa-user-gear',routerLink: ['team-roles']  },
    {label: 'Invitatoins', icon: 'fa-solid fa-user-gear',routerLink: ['invite-member']}
   ];

}
