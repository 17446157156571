<app-si-banner></app-si-banner>

<div class="features-area pt-100 pb-70">
    <app-features-style-one></app-features-style-one>
</div>

<div class="events-area pt-100 pb-70 card-ticket-bg">
    <div class="container">
        <h1 class="text-center mb-4 fw-bold">{{'LndPgTitle003'|translate}}</h1>
        <div class="events-tabs">

            <ngx-tabset (onSelect)="TabChange($event)">
                <ngx-tab tabTitle="{{'LndPgTitle003Chois001'|translate}}">
                    <div class="row">
                        <div *ngIf="!haveInterests||!isAt" class="col-lg-4 col-md-6 ">
                            <div class="single-courses-box-modified bg-color border  ">
                                <div class="courses-content text-md-start text-center">
                                    <h2 class="">{{'DefCrdTitl1'|translate}}</h2>
                                    <h3>{{'DefCrdTitl2'|translate}}</h3>
                                    <a *ngIf="auth.securityObject.isAuthenticated" routerLink="interests"
                                        class="rounded-pill" [ngClass]="{'default-btn-modified-rtl': document.documentElement.dir === 'rtl',
                                    'default-btn-modified': document.documentElement.dir === 'ltr'}"><i
                                            class="flaticon-user"></i>{{'NavStrtBtn'|translate}}<span></span></a>
                                    <a *ngIf="!auth.securityObject.isAuthenticated" routerLink="login"
                                        class="rounded-pill" [ngClass]="{'default-btn-modified-rtl': document.documentElement.dir === 'rtl',
                                    'default-btn-modified': document.documentElement.dir === 'ltr'}"><i
                                            class="flaticon-user"></i>{{'NavStrtBtn'|translate}}<span></span></a>
                                </div>
                            </div>
                        </div>
                        <div *ngFor="let item of data; index as i;" class="col-lg-4 col-md-6">
                            <app-event-card class="flex-card-small" [item]="item"
                                (likedClicked)="Like($event) [Liked] "></app-event-card>
                        </div>
                    </div>
                </ngx-tab>
                <ngx-tab tabTitle="{{'LndPgTitle003Chois002'|translate}}" *ngIf="haveInterests||isAt">
                    <div class="row">


                        <div *ngIf="!haveInterests||!isAt" class="col-lg-4 col-md-6 ">
                            <div class="single-courses-box-modified bg-color border  ">
                                <div class="courses-content text-md-start text-center">
                                    <h2 class="">{{'DefCrdTitl1'|translate}}</h2>
                                    <h3>{{'DefCrdTitl2'|translate}}</h3>
                                    <a *ngIf="auth.securityObject.isAuthenticated" routerLink="interests"
                                        class="rounded-pill" [ngClass]="{'default-btn-modified-rtl': document.documentElement.dir === 'rtl',
                                    'default-btn-modified': document.documentElement.dir === 'ltr'}"><i
                                            class="flaticon-user"></i>{{'NavStrtBtn'|translate}}<span></span></a>
                                    <a *ngIf="!auth.securityObject.isAuthenticated" routerLink="login"
                                        class="rounded-pill" [ngClass]="{'default-btn-modified-rtl': document.documentElement.dir === 'rtl',
                                    'default-btn-modified': document.documentElement.dir === 'ltr'}"><i
                                            class="flaticon-user"></i>{{'NavStrtBtn'|translate}}<span></span></a>
                                </div>
                            </div>
                        </div>
                        <div *ngFor="let item of data; index as i;" class="col-lg-4 col-md-6">
                            <div [class.single-courses-box-rtl]="document.documentElement.dir === 'rtl'"
                                [class.single-courses-box]="document.documentElement.dir === 'ltr'"
                                class=" bg-color border-45">
                                <div class="courses-image">
                                    <a [routerLink]="'/event/'+item.id" class="d-block image ">
                                        <img class="" [src]="url+ item.photo" alt="image">
                                    </a>
                                    <a routerLink="/" class="fav"><i class="fa-regular fa-heart"></i></a>
                                    <div class="price shadow">
                                        {{item.price.minValue!=item.price.minValue?item.price.minValue+item.curSymbol+"-"+item.price.maxValue+item.curSymbol:item.price.maxValue+item.curSymbol}}
                                    </div>
                                </div>
                                <div class="courses-content">

                                    <h3><a [routerLink]="'/event/'+item.id">{{item.title}}</a></h3>
                                    <div class="">
                                        <span class="mt-2 me-2 category-in-card ">{{item.category}}</span>
                                        <span class="mt-2 subCategory-in-card ">{{item.subCategory}}</span>
                                    </div>

                                    <div class="card-footer-font-size  mb-2 mt-2 ">
                                        <div class="mt-3  border-top border-primary" style="color:var(--mainColor);">
                                            <span class="mt-2 fw-bold"><small><i class="far fa-clock "></i>
                                                    Start</small> {{getDateOntomeZone(item.startDate)}}</span><br />
                                            <span class="mt-2 fw-bold"><small><i class="far fa-clock"></i> End:</small>
                                                {{getDateOntomeZone(item.endDate)}}</span>
                                        </div>
                                    </div>
                                    <div class="card-footer-font-size d-block align-items-center ">
                                        <p class=" my-2"><i class="far fa-map-marker-alt"></i> {{item.address}}</p>
                                        <!-- <div class="course__authors-box d-flex align-items-center">
                                                                <div class="course__authors-box-text">
                                                                    <h5><i class="fal fa-user"></i> Ahmad asd sa d asd sasa  d sad</h5>
                                                                </div>
                                                            </div> -->
                                        <div class="course-author d-flex align-items-center">
                                            <span><i class="fal fa-user"></i> {{item.orgName}}</span>
                                        </div>
                                        <p class="card-footer-font-size "><i class="fa-solid fa-users"></i> {{'OrgnizerProfDsc001'|translate}}
                                            <span style="color:var(--mainColor);">{{item.orgFollowers}}</span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </ngx-tab>
                <ngx-tab tabTitle="{{'LndPgTitle003Chois003'|translate}}">
                    <div class="row">
                        <div *ngFor="let item of data; index as i;" class="col-lg-4 col-md-6">
                            <app-event-card class="flex-card-small" [item]="item"
                                (likedClicked)="Like($event)"></app-event-card>

                        </div>
                    </div>
                </ngx-tab>
                <ngx-tab tabTitle="{{'LndPgTitle003Chois004'|translate}}">
                    <div class="row">
                        <div *ngFor="let item of data; index as i;" class="col-lg-4 col-md-6">
                            <app-event-card class="flex-card-small" [item]="item"
                                (likedClicked)="Like($event)"></app-event-card>
                        </div>
                    </div>
                </ngx-tab>
                <ngx-tab tabTitle="{{'LndPgTitle003Chois005'|translate}}">
                    <div class="row">
                        <div *ngFor="let item of data; index as i;" class="col-lg-4 col-md-6">
                            <app-event-card class="flex-card-small" [item]="item"
                                (likedClicked)="Like($event)"></app-event-card>
                        </div>
                    </div>
                </ngx-tab>
                <ngx-tab tabTitle="{{'LndPgTitle003Chois006'|translate}}">
                    <div class="row">
                        <div *ngFor="let item of data; index as i;" class="col-lg-4 col-md-6">
                            <app-event-card class="flex-card-small" [item]="item"
                                (likedClicked)="Like($event)"></app-event-card>
                        </div>
                    </div>
                </ngx-tab>
                <ngx-tab tabTitle="{{'LndPgTitle003Chois007'|translate}}" *ngIf="haveInterests||isAt">
                    <div class="row">
                        <div *ngFor="let item of data; index as i;" class="col-lg-4 col-md-6">
                            <app-event-card class="flex-card-small" [item]="item"
                                (likedClicked)="Like($event)"></app-event-card>
                        </div>
                    </div>
                </ngx-tab>
                <ngx-tab tabTitle="{{'LndPgTitle003Chois008'|translate}}">
                    <div class="row">
                        <div *ngFor="let item of data; index as i;" class="col-lg-4 col-md-6">
                            <app-event-card class="flex-card-small" [item]="item"
                                (likedClicked)="Like($event)"></app-event-card>
                        </div>
                    </div>
                </ngx-tab>
            </ngx-tabset>
        </div>
    </div>
</div>

<div class="courses-area ptb-100 card-ticket-bg">
    <div class="container ">
        <h1 class="text-center mb-4 fw-bold">{{'LndPgTitle004'|translate}}</h1>
        <div class="events-tabs px-3">
            <div class="odemy-grid-sorting row align-items-center ">
                <div class=" col-lg-8 col-md-6 text-md-start result-count">
                    <!-- <p>We found <span class="count">12</span> evenets available for you</p> -->
                </div>
                <div class="p-input-filled  col-lg-4 col-md-6 col-12">
                    <!-- <div class="select-box mb-2 ">
                        <label>Sort By:</label>
                        <select class="rounded ">
                            <option>Default</option>
                            <option>Popularity</option>
                            <option>Latest</option>
                            <option>Price: low to high</option>
                            <option>Price: high to low</option>
                        </select>
                    </div> -->
                    <p-dropdown class="sorting-custom  " [styleClass]="'w-100'" [options]="sortType"
                        [(ngModel)]="selectedSort" placeholder="{{ 'LndPgTitle004Chois001'| translate }}"
                        [autoDisplayFirst]="true" optionLabel="name" [showClear]="true"
                        (onChange)="getorderEvent($event)"></p-dropdown>
                </div>
            </div>
        </div>

        <div class="row">
            <div *ngFor="let item of data2; index as i;" class="col-lg-4 col-md-6">
                <app-event-card class="flex-card-small" [item]="item" (likedClicked)="Like($event)"></app-event-card>
            </div>
            <div class="col-lg-12 col-md-12">
                <div class="pagination-area text-center">

                    <p-button label="{{'SeeMorBtn'|translate}}" styleClass="p-button-link"
                        (click)="GetMoreData()"></p-button>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="partner-area  ptb-70  ">
    <div class="mx-md-5">
        <app-partner></app-partner>
    </div>
</div>
