<form [formGroup]="srv.AddonsForm" (ngSubmit)="submit()" autocomplete="off">
    <div class="row">
        <div class="col-md-12 col-12 col-xs-12 mb-3 ">
            <p-inputSwitch pTooltip="{{'AddOnFrmIntialSwtch001Pop'|translate}}" showDelay="1000"
                tooltipPosition="bottom" inputId="Etic" ariaLabelledBy="lab1" formControlName="hasGroup" #Checkbox
                (onChange)="setsmoeval($event)"></p-inputSwitch>
            <label class="form-check-label b-5 ms-2" id="lab1" for="Etic">{{'AddOnFrmIntialSwtch001'|translate}}</label>

        </div>

        <div class="p-input-filled col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6 mb-2"
            *ngIf="srv.AddonsForm.get('hasGroup').value">
            <span class="p-float-label">

                <p-dropdown pTooltip="{{'AddOnFrmIntialSlct001Pop'|translate}} " showDelay="1000"
                    tooltipPosition="bottom" inputId="RecTicketConf" [styleClass]="'w-100'" [autoDisplayFirst]="false"
                    [options]="groups" optionLabel="name" optionValue="id" formControlName="groupId" [ngClass]="{
                    'ng-invalid ng-dirty':
                        (srv.AddonsForm.get('groupId')?.touched &&
                        srv.AddonsForm.get('groupId')?.invalid)||(submitted&& srv.AddonsForm.get('groupId').invalid)
                }"></p-dropdown>
                <label class="b-5 ms-2" for="RecTicketConf">{{'AddOnFrmIntialSlct001'|translate}}</label>
            </span>
            <small class="p-error" *ngIf="(srv.AddonsForm.get('groupId')?.touched &&
            srv.AddonsForm.get('groupId')?.invalid)||(submitted&& srv.AddonsForm.get('groupId').invalid)
                ">{{'AddOnFrmIntialSlct001ErrMsg'|translate}}</small>
            <!-- <p>If you don`t have any group <a [routerLink]="" href="">add one now</a></p> -->
        </div>

    </div>
    <div class="row">
        <div class="col-12 d-flex justify-content-lg-center justify-content-center">
            <div class="banner-btn rounded-pill mx-2">
                <button type="button" pTooltip="Cancel and dismiss changes" showDelay="1000" tooltipPosition="left"
                    class="rounded-pill save-btn" (click)="cancel()">{{'CanclBtn'|translate}} <span></span> </button>
            </div>
            <div class="banner-btn rounded-pill mx-2">
                <button pTooltip="Save and go to next stap" showDelay="1000" tooltipPosition="right" type="submit"
                    class="rounded-pill next-btn">{{'NextBtn'|translate}} <i class="pi mt-1"
                        [class.pi-angle-right]="document.documentElement.dir === 'ltr'"
                        [class.pi-angle-left]="document.documentElement.dir === 'rtl'"></i><span></span></button>
            </div>
        </div>
    </div>


</form>