import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment as env } from 'src/environments/environment';
import { OrderFormGetDto } from './order-form-builder.component';
@Injectable({
  providedIn: 'root'
})
export class OrderFormService {

    url = env.Api;


  constructor(private http: HttpClient) { }

    getTickets(id: string)
    {
        return this.http.get<any[]>(this.url + 'd/of/g/tickets/' + id);
    }
    getOrderForm(id: string){
        return this.http.get<OrderFormGetDto>(this.url + 'd/of/g/i/' + id);
    }
    postOrderForm(body){
        return this.http.post(this.url + 'd/of/c', body);
    }

}
