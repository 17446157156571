import { DOCUMENT } from '@angular/common';
import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { ConfirmationService } from 'primeng/api';
import { OrganizerFromStepService } from 'src/app/services/organizer-from-step.service';
import { LinkTypes, OrgnizersService } from 'src/app/services/orgnizers.service';

@Component({
    selector: 'app-form-step2',
    templateUrl: './form-step2.component.html',
    styleUrls: ['./form-step2.component.scss']
})
export class FormStep2Component implements OnInit {




    submitted: boolean;
    submittedForm: boolean;


    constructor(private conSrv: ConfirmationService, private fb: FormBuilder, private orgSrv: OrgnizersService, public srvform: OrganizerFromStepService, private router: Router, private activatedRoute: ActivatedRoute, @Inject(DOCUMENT) public document: Document) {


    }
    ngOnInit(): void {
        this.srvform.Initializersform();
        this.orgSrv.getLinksType().subscribe(x => { this.srvform.linkData = x; });
       // console.log(this.srvform.links)
    }
    removeTag(event, x) {
        this.conSrv.confirm({

            target: event.target,
            message: 'Are you sure that you want to proceed?',
            icon: 'pi pi-exclamation-triangle',
            accept: () => {
              //  console.log(this.srvform.form.value)
                this.srvform.links.splice(x, 1)
                this.srvform.linkDto.splice(x, 1);
//console.log(this.srvform.form.value.links)
               // console.log(this.srvform.linkDto)
                this.srvform.form.controls["links"].setValue(this.srvform.linkDto);
            },
            reject: () => {

            }
        });

    }
    submit() {
        if (this.srvform.form.valid && !this.srvform.form.get("website").hasError("pattern")) {
          //  console.log(!this.srvform.form.get("website").hasError("pattern"))
            this.srvform.finish();
            this.srvform.clear();
            this.router.navigate([
                '/organizers'
            ]);
        }
        else {
            this.submitted = true
        }
    }
    cancel() {
        this.srvform.clear();
        this.router.navigate([
            '/organizers'
        ]);
    }

    previous() {
        this.router.navigate([
            'step1'

        ], { relativeTo: this.activatedRoute.parent });
    }

    check() {
        if (this.srvform.linksForm.valid) {

            let { linktype, linkurl } = this.srvform.linksForm.value;
            let additionalData = this.srvform.linkData.find(x => x.attrId == linktype)
            let x = {
                name: additionalData.name,
                linktype: linktype,
                linkurl: linkurl,
            }
        //    console.log(x)
            this.srvform.links.push(x)
            let y = {
                attrId: linktype,
                linkUrl: linkurl
            }
            this.srvform.linkDto.push(y);
            this.srvform.form.controls["links"].setValue(this.srvform.linkDto);
            this.srvform.linksForm.reset();
            this.submittedForm = false
        }
        else {
            this.submittedForm = true;
        }
    }
}
