import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { OrgnizersService } from 'src/app/services/orgnizers.service';

@Component({
  selector: 'app-invite-member',
  templateUrl: './invite-member.component.html',
  styleUrls: ['./invite-member.component.scss']
})
export class InviteMemberComponent implements OnInit {
  form: FormGroup;
  submitted : boolean;
  data1 : any[] = [];
  id : string;




  constructor(public config: DynamicDialogConfig,private orgSrv : OrgnizersService,private fb: FormBuilder,public ref: DynamicDialogRef) {


  }
  ngOnInit(): void {
      this.id = this.config?.data.id
    this.orgSrv.getRoles1(this.id).subscribe(x => this.data1 = x);
      this.init()
  }
  init() {
    this.form = this.fb.group({
      email: ["", [Validators.required , Validators.email]],
      roleId : [ , [Validators.required]]
    })
  }
  submit(){
    if(this.form.valid){
      this.ref.close( this.form.value  ) ;
    }
    else{
      this.submitted = true;
    }
  }
}
