import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';

import { MessageService, TreeNode } from 'primeng/api';
import {Observable, ReplaySubject, Subject} from 'rxjs';
import {environment as env} from '../../environments/environment';
import { EventCard } from '../components/pages/landing-page/landing-page.service';

export interface Orgnizer {
    id: string;
    name: string;
    imageUrl: string;
    phoneNumber : string;

    bio:string;
    email:string;
    numOfEvents: number;
    numOfFollowers: number;
}

export interface OrganizerFormDto {
    id: string;
    name: string;
    image: string;
    website: string;
    phoneNumber : string;
    email: string;
    bio : string
    links : Links[]
}

export interface Links{
    attrId : number;
    linkUrl : string;
}



export interface LinkTypes {
    attrId: number;
    parentId: number;
    cultureId?: any;
    order: number;
    status: number;
    name: string;
    desc?: any;
    misc01: string;
    misc02?: any;
    misc03?: any;
    misc04?: any;
    misc05?: any;
    misc06?: any;
    misc07?: any;
    parentName: string;
    childs?: any;
  }

  export interface Permission{
    id : number;
    name : string;
    value : boolean;
    parentId : number;
  }
  export interface Roles {
    id : string;
    name:string;
    orgId : string;
    permissions : number[];
  }
  export interface Role1 {
    id : string;
    name:string;
    orgId : string;
    permissions : Permission[];
  }
  export interface Role2 {
    id : string;
    name:string;
    orgId : string;
    permissions : TreeNode[];
  }

  export interface member{
    email : string;
    organizerId : string;
  }
  export interface member1{
    email : string;
    organizerId : string;
    roleId : string
  }
  export interface invitedmembers{
    email : string;
    memberId : string;
  }
  export interface teammembers{
    id : string;
    email : string;
    firstName:string;
    lastName : string;
    role : string;

  }
  export interface newPermissions {
    key : bigint;
    label : string;
    children :newChildrenPermissions[];
  }
  export interface newChildrenPermissions {
    key : bigint;
    label : string;

  }





/// d/o/g/all
@Injectable({
    providedIn: 'root'
})
export class OrgnizersService {
    isSubmitting : boolean;
    url: string = env.Api
    private subject: Subject<any> = new ReplaySubject<any>(1);

    get $getSubject(): Observable<any> {

      return this.subject.asObservable();
    }

    constructor(public httpClient: HttpClient,private messageService: MessageService) {
    }
    getOrgEvents(x,size,num){
        return this.httpClient.get<EventCard[]>(this.url + `d/o/orgevents/${x}/${size}/${num}`);
      }

      getuserEvents(size,num){
        return this.httpClient.get<EventCard[]>(this.url + `d/o/userevents/${size}/${num}`);
      }
      resendInvite(y){
        this.httpClient.post(this.url + "d/o/r/e" , y).subscribe(x => {
          this.getInvite(y.orgId)
        })
      }

    getLinksType():Observable<any[]>{
        return this.httpClient.get<any[]>(this.url + 'd/a/g/m/50303')
    }
    getAllOrgs():void {

        this.httpClient.get<Orgnizer[]>(this.url + 'd/o/g/u/table').subscribe(data => {
            this.subject.next(data);
        });
    }
    getOneOrg(id : string):Observable<any>{
      return this.httpClient.get<any>(this.url + "d/o/g/i/" + id)
    }
    postOrganizer(data: OrganizerFormDto) {
        data.phoneNumber = data.phoneNumber + ""
        this.httpClient.post(this.url + 'd/o/c/i', data,
          {
            headers: {
              'Content-Type': 'application/json',
              'Accept': 'application/json'
              // Authorization: 'my-auth-token'
            }
          }
        ).subscribe(x => {
            this.getAllOrgs();
            this.messageService.add({severity:'success', summary:'Saved Successfully'})

        });
      }
     getRoles(id : string){
        return this.httpClient.get<Roles[]>(this.url + 'd/o/roles/' + id).subscribe(data => {
            this.subject.next(data);
         });
     }
     getRoles1(id : string){
      return this.httpClient.get<Roles[]>(this.url + 'd/o/roles/' + id)
   }
     getPermissionsForRole(id : string){
      return this.httpClient.get<Role2>(this.url + "d/o/g/roles/" + id)
     }
     PostRoleForUser(id :string , roleId : string ,  orgid : string){
      var data = {
        id : id,
        roleId : roleId
      }
      this.httpClient.post(this.url + "d/o/c/r/u/" , data , {
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json'
          // Authorization: 'my-auth-token'
        }
      }).subscribe(x => {
        this.getTeamMembers(orgid)
        this.messageService.add({severity:'success', summary:'Added Successfully'});
    });

     }

     postInvite(data : member | member1){
         this.httpClient.post(this.url + "d/o/teammember" , data , {
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
            // Authorization: 'my-auth-token'
          }
         }).subscribe(x => {
            this.messageService.add({severity:'success', summary:'Invited Successfully'});
            this.isSubmitting = false
        } , err =>{this.isSubmitting = false} , () => {});
     }
     getTeamMembers(id : string){
     return this.httpClient.get<teammembers[]>(this.url + "d/o/g/teammember/" + id).subscribe(data => {
        this.subject.next(data);
     });;
     }
     getInvite(id : string) {
      return this.httpClient.get<invitedmembers[]>(this.url + "d/o/g/invite/" + id).subscribe(x =>{
         this.subject.next(x);
      })
     }

     //for testing
     getPermissionsWithParent() : Observable<TreeNode[]>{
      return this.httpClient.get<TreeNode[]>(this.url + 'd/o/i/ibra')
     }

     getPermissions() : Observable<Permission[]>{
      return this.httpClient.get<Permission[]>(this.url + 'd/o/g/p')
     }
     getParentPermissions() : Observable<Permission[]>{
      return this.httpClient.get<Permission[]>(this.url + 'd/o/permissions')
     }
     postRoles(data : Roles){
      this.httpClient.post(this.url + 'd/o/roles/c', data,
      {
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json'
          // Authorization: 'my-auth-token'
        }
      }
    ).subscribe(x => {
      this.getRoles(data.orgId);
        this.messageService.add({severity:'success', summary:'Saved Successfully'});
    });
    }
    deletOrg(id:string){
      return this.httpClient.delete(this.url+'d/o/d/'+id)
    }
    deletOrgteam(org:string,id:string){
      return this.httpClient.delete(this.url+'d/o/d/teammember/'+org+'/'+id)
    }
}
