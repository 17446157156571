import { Injectable } from '@angular/core';
import { ActivatedRoute,  Router } from '@angular/router';
import { Observable, ReplaySubject, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AttrDto, adminattr, langugesDto } from '../Interfaces/attrDto';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { DropDownItem } from '../Interfaces/DropDownItem';

@Injectable({
  providedIn: 'root'
})
export class AttrbuteService {

  url=environment.Api;
 
  private subject: Subject<adminattr> = new ReplaySubject<adminattr>(1);

  get $getSubject(): Observable<adminattr> {
    return this.subject.asObservable();
  }
  constructor(public http:HttpClient,
    private router:Router,
    private activatedRoute:ActivatedRoute) { }
    getAlltype(id,skip=0,seacrh:string="",or:number=1){
      this.http.get<adminattr>(this.url+'d/a/g/m/tab/' + id+'/'+skip+'/'+or+'/'+seacrh).subscribe(x=> this.subject.next(x));
      //  return this.http.get<TicketsTablesDto[]>(this.url+'d/t/g/t/all/'+id);
      }

      getAllpermisiion(id,skip=0,seacrh:string="",or:number=1){
        this.http.get<adminattr>(this.url+'d/a/g/m/tab/p/' + id+'/'+skip+'/'+or+'/'+seacrh).subscribe(x=> this.subject.next(x));
        //  return this.http.get<TicketsTablesDto[]>(this.url+'d/t/g/t/all/'+id);
        }

      postattrstauts(id){
        var data:string="";
       return this.http.put(this.url+'d/a/t/'+ id,data)
      }

      GetAllSystemLang(){
       return this.http.get<langugesDto[]>(this.url+'d/cu/all').pipe(map(x => {
  console.log(x)
         var l=x.map(y=>{
            var  t:DropDownItem={id:y.cultureId,name:y.name}
            return t
         })
          return l;
      }))


      }

      GetAllSystemLangUi(){
        return this.http.get<langugesDto[]>(this.url+'d/cu/all').pipe(map(x => {
   console.log(x)
          var l=x.map(y=>{
             var  t:DropDownItem={id:y.cultureId,name:y.uiCulture}
             return t
          })
           return l;
       }))}
      GetAtrrSingle(id){
        return this.http.get<AttrDto[]>(this.url+'d/a/g/i/cul/'+id);
      }

      PostnewAttr(data){
        var url=this.url+'d/a/c';
        return this.http.post(url, data);
      }

      DeletAttr(id){
        return this.http.delete(this.url+'d/a/d/'+id);
      }

      
  
}
