import { DOCUMENT } from '@angular/common';
import { Component, HostListener, OnInit,Inject } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { State } from '@popperjs/core';
import { DropDownItem } from 'src/app/Interfaces/DropDownItem';
import { UserAddreesDto } from 'src/app/components/shared/location-sertch/location-sertch.component';
import { AuthService, changpassDto } from 'src/app/services/auth.service';
import { ProfileEditingSrvService, cardInfoDto, profileContactInfoVM } from './profile-editing-srv.service';
import { Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';
import { validateLocaleAndSetLanguage } from 'typescript';
import { MessageService } from 'primeng/api';
import { OverlayPanel } from 'primeng/overlaypanel';

@Component({
  selector: 'app-user-profile-home-edit',
  templateUrl: './user-profile-home-edit.component.html',
  styleUrls: ['./user-profile-home-edit.component.scss']
})
export class UserProfileHomeEditComponent implements OnInit {
  screenSize: boolean;
  url:string=environment.Api
  THRESHOLD = 750;
  @HostListener('window:resize', ['$event'])
  sizeChange(event) {
    this.screenSize= window.innerWidth < this.THRESHOLD;
  }
  passwordMatchValidator: ValidatorFn = (control: FormGroup): ValidationErrors | null => {
    const password = control.get('password')?.value;
    const retypePassword = control.get('retypePass')?.value;

    if (password !== retypePassword) {
      return { passwordMismatch: true };
    }

    return null;
  };

  getUrl(){
    return this.UserPhoto==""?'assets/img/courses/3SUBiaP.png':this.url+this.UserPhoto
  }
  textsearch: any;
  subs:Subscription[]=[]
  constructor(private Fb:FormBuilder, @Inject(DOCUMENT) public document: Document
  ,private ProfileSrv:ProfileEditingSrvService,public Auth: AuthService,private messageService : MessageService){
  }
  mask1:string=""
  mask2:string=""
  selectedMAsk1:string="";
  selectedMAsk2:string="";
  click1:boolean=false
  click2:boolean=false
  UserPhoto:string="";
  countryforphone=[
  {
  name: "(+93) Afghanistan",
 num: "+93",
  code: "AF",max:9
  },
//   {
//   name: "(+358)Aland Islands",
//  num: "+358",
//   code: "AX"
//   },
//   {
//   name: "(+355)Albania",
//  num: "+355",
//   code: "AL"
//   },
  {
  name: "(+213)Algeria",
 num: "+213",
  code: "DZ",max:9
  },
//   {
//   name: "(+1684)AmericanSamoa",
//  num: "+1684",
//   code: "AS"
//   },
//   {
//   name: "(+376)Andorra",
//  num: "+376",
//   code: "AD"
//   },
//   {
//   name: "(+244) Angola",
//  num: "+244",
//   code: "AO"
//   },
  {
  name: "(+1264) Anguilla",
 num: "+1264",
  code: "AI",
  max:10
  },
//   {
//   name: "(+672) Antarctica",
//  num: "+672",
//   code: "AQ"
//   },
  {
  name: "(+1268) Antigua and Barbuda",
 num: "+1268",
  code: "AG",max:10
  },
//   {
//   name: "(+54) Argentina",
//  num: "+54",
//   code: "AR"
//   },
  {
  name: "(+374) Armenia",
 num: "+374",
  code: "AM",max:8
  },
  {
  name: "(+297)Aruba",
 num: "+297",
  code: "AW",max:7
  },
  {
  name: "(+61) Australia",
 num: "+61",
  code: "AU",max:9
  },
  {
  name: "(+43) Austria",
 num: "+43",
  code: "AT",max:10
  },
  {
  name: "(+994)Azerbaijan",
 num: "+994",
  code: "AZ",max:9
  },
  {
  name: "(+1242)Bahamas",
 num: "+1242",
  code: "BS",max:10
  },
  {
  name: "(+973)Bahrain",
 num: "+973",
  code: "BH",max:8
  },
  {
  name: "(+880)Bangladesh",
 num: "+880",
  code: "BD",max:10
  },
  {
  name: "(+1246)Barbados",
 num: "+1246",
  code: "BB",max:10
  },
  {
  name: "(+375)Belarus",
 num: "+375",
  code: "BY",max:9
  },
  {
  name: "(+32)Belgium",
 num: "+32",
  code: "BE",max:9
  },
  {
  name: "(+501)Belize",
 num: "+501",
  code: "BZ",max:7
  },
  {
  name: "(+229)Benin",
 num: "+229",
  code: "BJ",max:9
  },
  {
  name: "(+1441)Bermuda",
 num: "+1441",
  code: "BM",max:10
  },
//   {
//   name: "(+975)Bhutan",
//  num: "+975",
//   code: "BT"
//   },
//   {
//   name: "(+591)Bolivia, Plurinational State of",
//  num: "+591",
//   code: "BO"
//   },
  {
  name: "(+387)Bosnia and Herzegovina",
 num: "+387",max:8,
  code: "BA"
  },
//   {
//   name: "(+267)Botswana",
//  num: "+267",
//   code: "BW"
//   },
  {
  name: "(+55)Brazil",
 num: "+55",
  code: "BR",max:11
  },
//   {
//   name: "(+246)British Indian Ocean Territory",
//  num: "+246",
//   code: "IO"
//   },
//   {
//   name: "(+673)Brunei Darussalam",
//  num: "+673",
//   code: "BN"
//   },
//   {
//   name: "(+359)Bulgaria",
//  num: "+359",
//   code: "BG"
//   },
  {
  name: "(+226)Burkina Faso",
 num: "+226",
  code: "BF",max:8
  },
//   {
//   name: "(+257)Burundi",
//  num: "+257",
//   code: "BI"
//   },
  {
  name: "(+855)Cambodia",
 num: "+855",
  code: "KH",max:9
  },
  {
  name: "(+237)Cameroon",
 num: "+237",
  code: "CM",max:9
  },
  {
  name: "(+1)Canada",
 num: "+1",
  code: "CA",max:10
  },
//   {
//   name: "(+238)Cape Verde",
//  num: "+238",
//   code: "CV"
//   },
  {
  name: "(+345)Cayman Islands",
 num: "+ 345",
  code: "KY",max:10
  },
//   {
//   name: "(+236)Central African Republic",
//  num: "+236",
//   code: "CF"
//   },
  {
  name: "(+235)Chad",
 num: "+235",
  code: "TD",max:8
  },
  {
  name: "(+56)Chile",
 num: "+56",
  code: "CL",max:9
  },
  {
  name: "(+86)China",
 num: "+86",
  code: "CN",
  max:13
  },
//   {
//   name: "(+61)Christmas Island",
//  num: "+61",
//   code: "CX"
//   },
//   {
//   name: "(+61)Cocos (Keeling) Islands",
//  num: "+61",
//   code: "CC"
//   },
  {
  name: "(+57)Colombia",
 num: "+57",
  code: "CO",max:10
  },
//   {
//   name: "(+269)Comoros",
//  num: "+269",
//   code: "KM"
//   },
//   {
//   name: "(+242)Congo",
//  num: "+242",
//   code: "CG"
//   },
//   {
//   name: "(+243)Congo, The Democratic Republic of the Congo",
//  num: "+243",
//   code: "CD"
//   },
  {
  name: "(+682)Cook Islands",
 num: "+682",
  code: "CK",
  max:5
  },
  {
  name: "(+506)Costa Rica",
 num: "+506",
  code: "CR",max:8
  },
//   {
//   name: "(+225)Cote d'Ivoire",
//  num: "+225",
//   code: "CI"
//   },
  {
  name: "(+385)Croatia",
 num: "+385",
  code: "HR",max:9
  },
//   {
//   name: "(+53)Cuba",
//  num: "+53",
//   code: "CU"
//   },
  {
  name: "(+357)Cyprus",
 num: "+357",
  code: "CY",max:8
  },
  {
  name: "(+420)Czech Republic",
 num: "+420",
  code: "CZ",max:9
  },
  {
  name: "(+45)Denmark",
 num: "+45",
  code: "DK",max:8
  },
//   {
//   name: "(+253)Djibouti",
//  num: "+253",
//   code: "DJ"
//   },
  {
  name: "(+1767)Dominica",
 num: "+1767",
  code: "DM",max:10
  },
  {
  name: "(+1849)Dominican Republic",
 num: "+1849",
  code: "DO",max:10
  },
  {
  name: "(+593)Ecuador",
 num: "+593",
  code: "EC",max:9
  },
  {
  name: "(+20)Egypt",
 num: "+20",
  code: "EG",max:10
  },
  {
  name: "(+503)El Salvador",
 num: "+503",
  code: "SV",max:8
  },
//   {
//   name: "(+240)Equatorial Guinea",
//  num: "+240",
//   code: "GQ"
//   },
//   {
//   name: "(+291)Eritrea",
//  num: "+291",
//   code: "ER"
//   },
  {
  name: "(+372)Estonia",
 num: "+372",
  code: "EE",max:8
  },
//   {
//   name: "(+251)Ethiopia",
//  num: "+251",
//   code: "ET"
//   },
//   {
//   name: "(+500)Falkland Islands (Malvinas)",
//  num: "+500",
//   code: "FK"
//   },
  {
  name: "(+298)Faroe Islands",
 num: "+298",
  code: "FO",max:5
  },
//   {
//   name: "(+679)Fiji",
//  num: "+679",
//   code: "FJ"
//   },
//   {
//   name: "(+358)Finland",
//  num: "+358",
//   code: "FI"
//   },
  {
  name: "(+33)France",
 num: "+33",
  code: "FR",max:10
  },
  {
  name: "(+594)French Guiana",
 num: "+594",
  code: "GF",max:9
  },
  {
  name: "(+689)French Polynesia",
 num: "+689",
  code: "PF",max:6
  },
  {
  name: "(+241)Gabon",
 num: "+241",
  code: "GA",max:7
  },
//   {
//   name: "(+220)Gambia",
//  num: "+220",
//   code: "GM"
//   },
  {
  name: "(+995)Georgia",
 num: "+995",
  code: "GE",max:9
  },
  {
  name: "(+49)Germany",
 num: "+49",
  code: "DE",max:10
  },
  {
  name: "(+233)Ghana",
 num: "+233",
  code: "GH",max:9
  },
//   {
//   name: "(+350)Gibraltar",
//  num: "+350",
//   code: "GI"
//   },
  {
  name: "(+30)Greece",
 num: "+30",
  code: "GR",max:10
  },
  {
  name: "(+299)Greenland",
 num: "+299",
  code: "GL",max:6
  },
  {
  name: "(+1473)Grenada",
 num: "+1473",
  code: "GD",max:10
  },
  {
  name: "(+590)Guadeloupe",
 num: "+590",
  code: "GP",max:9
  },
  {
  name: "(+1671)Guam",
 num: "+1671",
  code: "GU",max:10
  },
  {
  name: "(+502) Guatemala",
 num: "+502",
  code: "GT",max:8
  },
  {
  name: "(+44)Guernsey",
 num: "+44",
  code: "GG",max:10
  },
//   {
//   name: "(+224)Guinea",
//  num: "+224",
//   code: "GN"
//   },
//   {
//   name: "(+245)Guinea-Bissau",
//  num: "+245",
//   code: "GW"
//   },
//   {
//   name: "(+595)Guyana",
//  num: "+595",
//   code: "GY"
//   },
//   {
//   name: "(+509)Haiti",
//  num: "+509",
//   code: "HT"
//   },
  {
  name: "(+379)Holy See (Vatican City State)",
 num: "+379",
  code: "VA",
  max:10
  },
  {
  name: "(+504)Honduras",
 num: "+504",
  code: "HN",max:8
  },
  {
  name: "(+852)Hong Kong",
 num: "+852",
  code: "HK",max:8
  },
  {
  name: "(+36)Hungary",
 num: "+36",
  code: "HU",max:9
  },
//   {
//   name: "(+354)Iceland",
//  num: "+354",
//   code: "IS"
//   },
  {
  name: "(+91)India",
 num: "+91",
  code: "IN",
  max:10
  },
  {
  name: "(+62)Indonesia",
 num: "+62",
  code: "ID",
  max:9
  },
  {
  name: "(+98)Iran, Islamic Republic of Persian Gulf",
 num: "+98",
  code: "IR",max:10
  },
//   {
//   name: "(+964)Iraq",
//  num: "+964",
//   code: "IQ"
//   },
  {
  name: "(+353)Ireland",
 num: "+353",
  code: "IE",max:9
  },
  {
  name: "(+44)Isle of Man",
 num: "+44",
  code: "IM",max:10
  },
  {
  name: "(+972)Israel",
 num: "+972",
  code: "IL",max:9
  },
  {
  name: "(+39)Italy",
 num: "+39",
  code: "IT",max:10
  },
  {
  name: "(+1876)Jamaica",
 num: "+1876",
  code: "JM",max:10
  },
  {
  name: "(+81)Japan",
 num: "+81",
  code: "JP",
  max:10
  },
//   {
//   name: "(+44)Jersey",
//  num: "+44",
//   code: "JE"
//   },
  {
  name: "(+962)Jordan",
 num: "+962",
  code: "JO",max:9
  },
  {
  name: "(+77)Kazakhstan",
 num: "+77",
  code: "KZ",max:10
  },
  {
  name: "(+254)Kenya",
 num: "+254",
  code: "KE",max:10
  },
  {
  name: "(+686)Kiribati",
 num: "+686",
  code: "KI",max:8
  },
//   {
//   name: "(+850)Korea, Democratic People's Republic of Korea",
//  num: "+850",
//   code: "KP"
//   },
  {
  name: "(+82)Korea, Republic of South Korea",
 num: "+82",
  code: "KR",max:10
  },
  {
  name: "(+965)Kuwait",
 num: "+965",
  code: "KW",max:8
  },
//   {
//   name: "(+996)Kyrgyzstan",
//  num: "+996",
//   code: "KG"
//   },
//   {
//   name: "(+856)Laos",
//  num: "+856",
//   code: "LA"
//   },
  {
  name: "(+371)Latvia",
 num: "+371",
  code: "LV",max:8
  },
  {
  name: "(+961)Lebanon",
 num: "+961",
  code: "LB",max:7
  },
//   {
//   name: "(+266)Lesotho",
//  num: "+266",
//   code: "LS"
//   },
  {
  name: "(+231)Liberia",
 num: "+231",
  code: "LR",max:7
  },
  {
  name: "(+218)Libyan Arab Jamahiriya",
 num: "+218",
  code: "LY",max:10
  },
//   {
//   name: "(+423)Liechtenstein",
//  num: "+423",
//   code: "LI"
//   },
  {
  name: "(+370)Lithuania",
 num: "+370",
  code: "LT",max:8
  },
  {
  name: "(+352)Luxembourg",
 num: "+352",
  code: "LU",max:9
  },
//   {
//   name: "(+853)Macao",
//  num: "+853",
//   code: "MO"
//   },
//   {
//   name: "(+389)Macedonia",
//  num: "+389",
//   code: "MK"
//   },
//   {
//   name: "(+261)Madagascar",
//  num: "+261",
//   code: "MG"
//   },
//   {
//   name: "(+265)Malawi",
//  num: "+265",
//   code: "MW"
//   },
  {
  name: "(+60)Malaysia",
 num: "+60",
  code: "MY",max:7
  },
  {
  name: "(+960)Maldives",
 num: "+960",
  code: "MV",max:7
  },
  {
  name: "(+223)Mali",
 num: "+223",
  code: "ML",max:8
  },
  {
  name: "(+356)Malta",
 num: "+356",
  code: "MT",max:8
  },
  {
  name: "(+692)Marshall Islands",
 num: "+692",
  code: "MH",max:7
  },
  {
  name: "(+596)Martinique",
 num: "+596",
  code: "MQ",max:9
  },
//   {
//   name: "(+222)Mauritania",
//  num: "+222",
//   code: "MR"
//   },
  {
  name: "(+230)Mauritius",
 num: "+230",
  code: "MU",max:8
  },
//   {
//   name: "(+262)Mayotte",
//  num: "+262",
//   code: "YT"
//   },
  {
  name: "(+52)Mexico",
 num: "+52",
  code: "MX",max:10
  },
  {
  name: "(+691)Micronesia, Federated States of Micronesia",
 num: "+691",
  code: "FM",max:7
  },
  {
  name: "(+373)Moldova",
 num: "+373",
  code: "MD",max:8
  },
//   {
//   name: "(+377)Monaco",
//  num: "+377",
//   code: "MC"
//   },
//   {
//   name: "(+976)Mongolia",
//  num: "+976",
//   code: "MN"
//   },
  {
  name: "(+382)Montenegro",
 num: "+382",
  code: "ME",max:8
  },
  {
  name: "(+1664)Montserrat",
 num: "+1664",
  code: "MS",
  max:10,
  },
  {
  name: "(+212)Morocco",
 num: "+212",
  code: "MA",max:9
  },
  {
  name: "(+258)Mozambique",
 num: "+258",
  code: "MZ",max:12
  },
  {
  name: "(+95)Myanmar",
 num: "+95",
  code: "MM",max:8
  },
//   {
//   name: "(+264)Namibia",
//  num: "+264",
//   code: "NA"
//   },
//   {
//   name: "(+674)Nauru",
//  num: "+674",
//   code: "NR"
//   },
  {
  name: "(+977)Nepal",
 num: "+977",
  code: "NP",max:10
  },
  {
  name: "(+31)Netherlands",
 num: "+31",
  code: "NL",max:9
  },
  {
  name: "(+599)Netherlands Antilles",
 num: "+599",
  code: "AN",max:8
  },
  {
  name: "(+687)New Caledonia",
 num: "+687",
  code: "NC",max:6
  },
  {
  name: "(+64)New Zealand",
 num: "+64",
  code: "NZ",max:9
  },
  {
  name: "(+505)Nicaragua",
 num: "+505",
  code: "NI",max:8
  },
  {
  name: "(+227)Niger",
 num: "+227",
  code: "NE",max:8
  },
  {
  name: "(+234)Nigeria",
 num: "+234",
  code: "NG",max:8
  },
  {
  name: "(+683)Niue",
 num: "+683",
  code: "NU",
  max:4
  },
//   {
//   name: "(+672)Norfolk Island",
//  num: "+672",
//   code: "NF"
//   },
  {
  name: "(+1670)Northern Mariana Islands",
 num: "+1670",
  code: "MP",max:10
  },
  {
  name: "(+47)Norway",
 num: "+47",
  code: "NO",max:8
  },
  {
  name: "(+968)Oman",
 num: "+968",
  code: "OM",max:8
  },
  {
  name: "(+92)Pakistan",
 num: "+92",
  code: "PK",max:10
  },
  {
  name: "(+680)Palau",
 num: "+680",
  code: "PW",max:7
  },
  {
  name: "(+970)Palestinian Territory, Occupied",
 num: "+970",
  code: "PS",max:9
  },
  {
  name: "(+507)Panama",
 num: "+507",
  code: "PA",max:8
  },
//   {
//   name: "(+675)Papua New Guinea",
//  num: "+675",
//   code: "PG"
//   },
  {
  name: "(+595)Paraguay",
 num: "+595",
  code: "PY",max:9
  },
  {
  name: "(+51)Peru",
 num: "+51",
  code: "PE",max:9
  },
  {
  name: "(+63)Philippines",
 num: "+63",
  code: "PH",max:10
  },
//   {
//   name: "(+872)Pitcairn",
//  num: "+872",
//   code: "PN"
//   },
  {
  name: "(+48)Poland",
 num: "+48",
  code: "PL",max:9
  },
  {
  name: "(+351)Portugal",
 num: "+351",
  code: "PT",max:9
  },
  {
  name: "(+1939)Puerto Rico",
 num: "+1939",
  code: "PR",max:10
  },
  {
  name: "(+974)Qatar",
 num: "+974",
  code: "QA",max:8
  },
  {
  name: "(+40)Romania",
 num: "+40",
  code: "RO",max:10
  },
  {
  name: "(+7)Russia",
 num: "+7",
  code: "RU",max:10
  },
//   {
//   name: "(+250)Rwanda",
//  num: "+250",
//   code: "RW"
//   },
  //{
//   name: "(+262)Reunion",
//  num: "+262",
//   code: "RE"
//   },
//   {
//   name: "(+590)Saint Barthelemy",
//  num: "+590",
//   code: "BL"
//   },
//   {
//   name: "(+290)Saint Helena, Ascension and Tristan Da Cunha",
//  num: "+290",
//   code: "SH"
//   },
  {
  name: "(+1869)Saint Kitts and Nevis",
 num: "+1869",
  code: "KN",max:10
  },
  {
  name: "(+1758)Saint Lucia",
 num: "+1758",
  code: "LC",max:10
  },
//   {
//   name: "(+590)Saint Martin",
//  num: "+590",
//   code: "MF"
//   },
//   {
//   name: "(+508)Saint Pierre and Miquelon",
//  num: "+508",
//   code: "PM"
//   },
  {
  name: "(+1784)Saint Vincent and the Grenadines",
 num: "+1784",
  code: "VC",max:10
  },
  {
  name: "(+685)Samoa",
 num: "+685",
  code: "WS",max:5
  },
//   {
//   name: "(+378)San Marino",
//  num: "+378",
//   code: "SM"
//   },
//   {
//   name: "(+239)Sao Tome and Principe",
//  num: "+239",
//   code: "ST"
//   },
  {
  name: "(+966)Saudi Arabia",
 num: "+966",
  code: "SA",max:9
  },
//   {
//   name: "(+221)Senegal",
//  num: "+221",
//   code: "SN"
//   },
  {
  name: "(+381)Serbia",
 num: "+381",
  code: "RS",max:8
  },
//   {
//   name: "(+248)Seychelles",
//  num: "+248",
//   code: "SC"
//   },
//   {
//   name: "(+232)Sierra Leone",
//  num: "+232",
//   code: "SL"
//   },
  {
  name: "(+65)Singapore",
 num: "+65",
  code: "SG",max:8
  },
  {
  name: "(+421)Slovakia",
 num: "+421",
  code: "SK",max:9
  },
//   {
//   name: "(+386)Slovenia",
//  num: "+386",
//   code: "SI"
//   },
  {
  name: "(+677)Solomon Islands",
 num: "+677",
  code: "SB",max:7
  },
  {
  name: "(+252)Somalia",
 num: "+252",
  code: "SO",max:8
  },
  {
  name: "(+27)South Africa",
 num: "+27",
  code: "ZA",max:9
  },
  //{
//   name: "(+211)South Sudan",
//  num: "+211",
//   code: "SS"
//   },
//   {
//   name: "(+500)South Georgia and the South Sandwich Islands",
//  num: "+500",
//   code: "GS"
//   },
  {
  name: "(+34)Spain",
 num: "+34",
  code: "ES",max:9
  },
  {
  name: "(+94)Sri Lanka",
 num: "+94",
  code: "LK",max:7
  },
//   {
//   name: "(+249)Sudan",
//  num: "+249",
//   code: "SD"
//   },
//   {
//   name: "(+597)Suriname",
//  num: "+597",
//   code: "SR"
//   },
//   {
//   name: "(+47)Svalbard and Jan Mayen",
//  num: "+47",
//   code: "SJ"
//   },
//   {
//   name: "(+268)Swaziland",
//  num: "+268",
//   code: "SZ"
//   },
  {
  name: "(+46)Sweden",
 num: "+46",
  code: "SE",max:7
  },
  {
  name: "(+41)Switzerland",
 num: "+41",
  code: "CH",max:9
  },
  {
  name: "(+963)Syrian Arab Republic",
 num: "+963",
  code: "SY",max:9
  },
  {
  name: "(+886)Taiwan",
 num: "+886",
  code: "TW",max:9
  },
//   {
//   name: "(+992)Tajikistan",
//  num: "+992",
//   code: "TJ"
//   },
  {
  name: "(+255)Tanzania, United Republic of Tanzania",
 num: "+255",
  code: "TZ",max:6
  },
  {
  name: "(+66)Thailand",
 num: "+66",
  code: "TH",max:9
  },
//   {
//   name: "(+670)Timor-Leste",
//  num: "+670",
//   code: "TL"
//   },
  {
  name: "(+228)Togo",
 num: "+228",
  code: "TG",max:8
  },
//   {
//   name: "(+690)Tokelau",
//  num: "+690",
//   code: "TK"
//   },
//   {
//   name: "(+676)Tonga",
//  num: "+676",
//   code: "TO"
//   },
  {
  name: "(+1868)Trinidad and Tobago",
 num: "+1868",
  code: "TT",max:10
  },
  {
  name: "(+216)Tunisia",
 num: "+216",
  code: "TN",max:8
  },
  {
  name: "(+90)Turkey",
 num: "+90",
  code: "TR",max:10
  },
//   {
//   name: "(+993)Turkmenistan",
//  num: "+993",
//   code: "TM"
//   },
  {
  name: "(+1649)Turks and Caicos Islands",
 num: "+1649",
  code: "TC",max:10
  },
//   {
//   name: "(+688)Tuvalu",
//  num: "+688",
//   code: "TV"
//   },
//   {
//   name: "(+256)Uganda",
//  num: "+256",
//   code: "UG"
//   },
  {
  name: "(+380)Ukraine",
 num: "+380",
  code: "UA",max:9
  },
  {
  name: "(+971)United Arab Emirates",
 num: "+971",
  code: "AE",max:9
  },
  {
  name: "(+44)United Kingdom",
 num: "+44",
  code: "GB",max:10
  },
  {
  name: "(+1)United States",
 num: "+1",
  code: "US",max:10
  },
//   {
//   name: "(+598)Uruguay",
//  num: "+598",
//   code: "UY"
//   },
//   {
//   name: "(+998)Uzbekistan",
//  num: "+998",
//   code: "UZ"
//   },
//   {
//   name: "(+678)Vanuatu",
//  num: "+678",
//   code: "VU"
//   },
  {
  name: "(+58)Venezuela, Bolivarian Republic of Venezuela",
 num: "+58",
  code: "VE",max:7
  },
  {
  name: "(+84)Vietnam",
 num: "+84",
  code: "VN",max:9
  },
//   {
//   name: "(+1284)Virgin Islands, British",
//  num: "+1284",
//   code: "VG"
//   },
//   {
//   name: "(+1340)Virgin Islands, U.S.",
//  num: "+1340",
//   code: "VI"
//   },
//   {
//   name: "(+681)Wallis and Futuna",
//  num: "+681",
//   code: "WF"
//   },
  {
  name: "(+967)Yemen",
 num: "+967",
  code: "YE",max:9
  },
  {
  name: "(+260)Zambia",
 num: "+260",
  code: "ZM",max:9
  },
  {
  name: "(+263)Zimbabwe",
 num: "+263",
  code: "ZW",max:9
  }
  ]

  getnew1(){
   this.click1=true;
   this.mask1=""
   var l=this.countryforphone.find(x=>x.num==this.selectedMAsk1);


    var t=l.max;
    for(var i=0;i<t;i++){
       this.mask1+="9";
    }
    
  }
  getnew2(){
    this.click2=true;
    this.mask2=""
    var l=this.countryforphone.find(x=>x.num==this.selectedMAsk2);
 
 
     var t=l.max;
     for(var i=0;i<t;i++){
        this.mask2+="9";
     }
   }
  onRowSelect(e,op:OverlayPanel){
    console.log(e)
    this.mask1=""
    this.selectedMAsk1=e.data.num
    var t=e.data.max;
    for(var i=0;i<t;i++){
       this.mask1+="9";
    }
    this.UserDetailsForm.controls['homePhonecountry'].setValue(e.data.num)
    op.hide();
  }
  onRowSelect2(e,op:OverlayPanel){
    console.log(e)
    this.mask2=""
    this.selectedMAsk2=e.data.num
    var t=e.data.max;
    for(var i=0;i<t;i++){
       this.mask2+="9";
    }
    this.UserDetailsForm.controls['mobilePhonecountry'].setValue(e.data.num)
    op.hide();
  }
   val4:number;
   submitted:boolean=false;
   CardDetailsForm!:FormGroup;
   UserDetailsForm!:FormGroup;
   homeAddressForm!:FormGroup;
   workAddressForm!:FormGroup;
   billingAddressForm!:FormGroup;
   shippingAddressForm!:FormGroup;
    date3: Date;
    selectedState: State | undefined;
    dates: Date[];
    rangeDates: Date[];
    minDate: Date;
    maxDate: Date;
    invalidDates: Array<Date>
    val:number
    ResetPasswordForm!:FormGroup;
    initialAddressForm( type:string,address:string,address2:string,city:string,country:string,lat:number,lng:number,zip:number,state:string)
    {
      switch(type){
      case 'home':this.homeAddressForm=this.Fb.group({
                                                  address:[address,Validators.required],
                                                  address2:[address2],
                                                  city:[city,Validators.required],
                                                  country:[country,Validators.required],
                                                  lat:[lat,Validators.required],
                                                  lng:[lng,Validators.required],
                                                  state:[state],
                                                  zip:[zip]
                                                  });
                                                  break;
      case 'work':this.workAddressForm=this.Fb.group({
                                                    address:[address,Validators.required],
                                                    address2:[address2],
                                                    city:[city,Validators.required],
                                                    country:[country,Validators.required],
                                                    lat:[lat,Validators.required],
                                                    lng:[lng,Validators.required],
                                                    state:[state],
                                                    zip:[zip]
                                                    });
                                                    break;
      case 'billing':this.billingAddressForm=this.Fb.group({
                                                      address:[address,Validators.required],
                                                      address2:[address2],
                                                      city:[city,Validators.required],
                                                      country:[country,Validators.required],
                                                      lat:[lat,Validators.required],
                                                      lng:[lng,Validators.required],
                                                      state:[state],
                                                      zip:[zip]
                                                      });
                                                      break;
      case 'shipping':this.shippingAddressForm=this.Fb.group({
                                                        address:[address,Validators.required],
                                                        address2:[address2],
                                                        city:[city,Validators.required],
                                                        country:[country,Validators.required],
                                                        lat:[lat,Validators.required],
                                                        lng:[lng,Validators.required],
                                                        state:[state],
                                                        zip:[zip]
                                                        });
                                                        break;
    }
    }
    iniialCardDetailsForm(cardNumber,cardholderName,expiryDate,securityCode,billingAddress){
     this.CardDetailsForm=this.Fb.group({
    cardNumber:[cardNumber,Validators.required],
    cardholderName:[cardholderName,Validators.required],
    expiryDate:[expiryDate,Validators.required],
    securityCode:[securityCode],
    billingAddress:[billingAddress],

    });
    }
    iniialuserdetailsForm(prefix,firstName,lastName,homePhone,dd,mobilePhone,ff,suffix,job,company,website){

      this.UserDetailsForm=this.Fb.group({
        prefix:[prefix,Validators.required],
        firstName:[firstName,Validators.required],
        lastName:[lastName,Validators.required],
        homePhone:[homePhone],
        mobilePhone:[mobilePhone],
        suffix:[suffix],
        job:[job],
        company:[company],
        website:[website],
        homePhonecountry:[dd],
        mobilePhonecountry:[ff]
      });


      this.UserDetailsForm.get('mobilePhone').valueChanges.subscribe(value => {
        this.val = value;
      });

        }

    inializeFormPss(){
      this.ResetPasswordForm=this.Fb.group({
        currentpassword:["",Validators.required],
        password:["",Validators.required],
        retypePass:["",[Validators.required,]]
      }, { validator: this.passwordMatchValidator })
    }
    ngOnInit(): void {
      this.inializeFormPss();
        this.iniialCardDetailsForm(null,"",null,null,"")
        this.iniialuserdetailsForm("","","","","","","","","","","")
        this.initialAddressForm('home',"",'','','',0,0,0,'')
        this.initialAddressForm('work',"",'','','',0,0,0,'')
        this.initialAddressForm('billing',"",'','','',0,0,0,'')
        this.initialAddressForm('shipping',"",'','','',0,0,0,'')
        this.Auth.checkUserProfile().subscribe(x=>{console.log(x)
          if(x){
             this.ProfileSrv.GetUserProfileInfo();
             var f=this.ProfileSrv.$getUserprofileImage.subscribe(x=>{
              this.UserPhoto=x.image;

             })
             var t= this.ProfileSrv.$getUserprofileBillingAddress.subscribe(x=>{
              console.log(x,'billing')
              this.initialAddressForm('billing',x.address,x.secondAddress,x.city,x.country,x.lat,x.lng as number,x.zip,x.state)
             });
             this.subs.push(t)
             var l= this.ProfileSrv.$getUserprofileCardInfo.subscribe(x=>{

              this.iniialCardDetailsForm(x.cardNumber,x.cardholderName,x.expiryDate,x.securityCode,x.billingAddress)
             });
             this.subs.push(l)
             var m= this.ProfileSrv.$getUserprofileContactInfo.subscribe(x=>{
               console.log(x)

               if(x.homePhonecountry!=""){
                var m=this.countryforphone.find(y=>y.num==x.homePhonecountry);
                this.selectedMAsk1=m.num;
                for(var i=0;i<m.max;i++){
                  this.mask1+="9";
               }
              }
              if(x.mobilePhonecountry!=""){
                var m=this.countryforphone.find(y=>y.num==x.mobilePhonecountry);
                this.selectedMAsk2=m.num;
                for(var i=0;i<m.max;i++){
                  this.mask2+="9";
               }
              }
              this.iniialuserdetailsForm(x.prefix,x.firstName,x.lastName,x.homePhone, x.homePhonecountry,x.mobilePhone,x.mobilePhonecountry,x.suffix,x.job,x.company,x.website);
              console.log(this.UserDetailsForm.value)
             });
             this.subs.push(m)
             var n= this.ProfileSrv.$getUserprofileShippingAddress.subscribe(x=>{
              console.log(x,'shipping')
              this.initialAddressForm('shipping',x.address,x.secondAddress,x.city,x.country,x.lat,x.lng as number,x.zip,x.state)
             })
             this.subs.push(n)
             var o= this.ProfileSrv.$getUserprofileHomeAdDress.subscribe(x=>{
              console.log(x,'home')
              this.initialAddressForm('home',x.address,x.secondAddress,x.city,x.country,x.lat,x.lng as number,x.zip,x.state)
             })
             this.subs.push(o)
             var p= this.ProfileSrv.$getUserprofileWorkAddress.subscribe(x=>{
              console.log(x,'work')
              this.initialAddressForm('work',x.address,x.secondAddress,x.city,x.country,x.lat,x.lng as number,x.zip,x.state)
             })
             this.subs.push(p)
          }});
    }
    value1: number =0 ;
    value: string | undefined;
    getAddressfromCom(e:UserAddreesDto,type:string){
      switch(type){
        case 'home':
                      this.homeAddressForm.controls['address'].setValue(e.address)
                      this.homeAddressForm.controls['address2'].setValue(e.secondAddress);
                      this.homeAddressForm.controls['city'].setValue(e.city);
                      this.homeAddressForm.controls['country'].setValue(e.country);
                      this.homeAddressForm.controls['lat'].setValue(e.lat);
                      this.homeAddressForm.controls['lng'].setValue(e.lng);
                      this.homeAddressForm.controls['state'].setValue(e.state);
                      this.homeAddressForm.controls['zip'].setValue(e.zip as number);
                        break;
        case 'work':
                      this.workAddressForm.controls['address'].setValue(e.address)
                      this.workAddressForm.controls['address2'].setValue(e.secondAddress);
                      this.workAddressForm.controls['city'].setValue(e.city);
                      this.workAddressForm.controls['country'].setValue(e.country);
                      this.workAddressForm.controls['lat'].setValue(e.lat);
                      this.workAddressForm.controls['lng'].setValue(e.lng);
                      this.workAddressForm.controls['state'].setValue(e.state);
                      this.workAddressForm.controls['zip'].setValue(e.zip as number);
                     break;
        case 'billing':
                       this.billingAddressForm.controls['address'].setValue(e.address)
                       this.billingAddressForm.controls['address2'].setValue(e.secondAddress);
                       this.billingAddressForm.controls['city'].setValue(e.city);
                       this.billingAddressForm.controls['country'].setValue(e.country);
                       this.billingAddressForm.controls['lat'].setValue(e.lat);
                       this.billingAddressForm.controls['lng'].setValue(e.lng);
                       this.billingAddressForm.controls['state'].setValue(e.state);
                       this.billingAddressForm.controls['zip'].setValue(e.zip as number);
                       break;
        case  'shipping':
                        this.shippingAddressForm.controls['address'].setValue(e.address)
                        this.shippingAddressForm.controls['address2'].setValue(e.secondAddress);
                        this.shippingAddressForm.controls['city'].setValue(e.city);
                        this.shippingAddressForm.controls['country'].setValue(e.country);
                        this.shippingAddressForm.controls['lat'].setValue(e.lat);
                        this.shippingAddressForm.controls['lng'].setValue(e.lng);
                        this.shippingAddressForm.controls['state'].setValue(e.state);
                        this.shippingAddressForm.controls['zip'].setValue(e.zip as number);
                        break;
      }

    }


    save(g:string ){
      switch(g){
        case 'info' :
                      var data1=this.UserDetailsForm.value as profileContactInfoVM;
                      var k=this.ProfileSrv.PostUserProfileinfo(data1).subscribe(x=>{
                        this.ProfileSrv.GetUserProfileinfo();
                        this.click2=false;
                        this.click1=false;
                        this.messageService.add({ severity: 'success', summary: 'Saved Successfully' });

                      },(err)=>{

                              this.messageService.add({ severity: 'error', summary: ' have some error' });

                      })
                      this.subs.push(k)
                      break;
        case 'home' :
                       var data2=this.homeAddressForm.value as UserAddreesDto;
                       var u=this.ProfileSrv.PostUserhomeAddress(data2).subscribe(x=>{
                         this.ProfileSrv.GetUserhomeAddress();
                         this.messageService.add({ severity: 'success', summary: 'Saved Successfully' });

                        },(err)=>{

                                this.messageService.add({ severity: 'error', summary: ' have some error' });

                        })
                       this.subs.push(u)
                       break;
        case 'bill' :
                       var data3=this.billingAddressForm.value as UserAddreesDto;
                       var y=this.ProfileSrv.PostUserBillingAddress(data3).subscribe(x=>{
                         this.ProfileSrv.GetUserBillingAddress();
                         this.messageService.add({ severity: 'success', summary: 'Saved Successfully' });

                        },(err)=>{

                                this.messageService.add({ severity: 'error', summary: ' have some error' });

                        })
                       this.subs.push(y)
                       break;
        case 'ship' :
                        var data4=this.shippingAddressForm.value as UserAddreesDto;
                        var u2=this.ProfileSrv.PostUserShippingAddress(data4).subscribe(x=>{
                          this.ProfileSrv.GetUserShippingAddress();
                          this.messageService.add({ severity: 'success', summary: 'Saved Successfully' });

                        },(err)=>{

                                this.messageService.add({ severity: 'error', summary: ' have some error' });

                        })
                        this.subs.push(u2)
                        break;
        case 'work' :
                       var data5=this.workAddressForm.value as UserAddreesDto;
                       var u3=this.ProfileSrv.PostUserWorkAddress(data5).subscribe(x=>{
                         this.ProfileSrv.GetUserShippingAddress();
                         this.messageService.add({ severity: 'success', summary: 'Saved Successfully' });

                        },(err)=>{

                                this.messageService.add({ severity: 'error', summary: ' have some error' });

                        })
                       this.subs.push(u3)
                       break;
        case 'card' :
                        var data6=this.CardDetailsForm.value as cardInfoDto;
                        var u5=this.ProfileSrv.PostUserCadrInfo(data6).subscribe(x=>{
                          this.ProfileSrv.GetUserCadrInfo();
                          this.messageService.add({ severity: 'success', summary: 'Saved Successfully' });

                        },(err)=>{

                                this.messageService.add({ severity: 'error', summary: ' have some error' });

                        })
                        this.subs.push(u5)
                        break;
      }

    }
    submit(){

      var data:changpassDto={
        currentpassword:this.ResetPasswordForm.controls['currentpassword'].value,
        password:this.ResetPasswordForm.controls['password'].value,

      }
      this.Auth.changepassword(data);
    }
}
