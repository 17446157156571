<form [formGroup]="form" (submit)="submit()">

    <div class="row mb-2">
        <div class="p-input-filled col-12 ">
            <span class="p-float-label">
            <p-dropdown autoWidth="false" [options]="data" optionLabel="name" appendTo="body" optionValue="id"
                        formControlName="roleId" styleClass="form-control"
                        optionLabel="name" [filter]="true" [ngClass]="{
                  'ng-invalid ng-dirty':(  form.get('roleId')?.touched &&
              form.get('roleId')?.invalid)||(submitted&& form.get('roleId').invalid)
            }" filterBy="name" [showClear]="true" placeholder="Role">

                <ng-template let-item pTemplate="selectedItem">
                    <div class="flex align-items-center gap-2">

                        <div>{{ item.name }}</div>
                    </div>
                </ng-template>
                <ng-template let-item pTemplate="item">
                    <div class="flex align-items-center gap-2">

                        <div>{{ item.name }}</div>
                    </div>
                </ng-template>
            </p-dropdown>
            <label class="font-size-14" for="linktype">Role</label>
        </span>
            <small class="p-error" *ngIf="(form.get('roleId')?.touched &&
form.get('roleId')?.invalid) || ( submitted &&
form.get('roleId')?.invalid)">{{'PrmoFrmStp1Small1'|translate}}</small>
        </div>
    </div>

    <div class=" d-flex  justify-content-center">
        <div class="banner-btn rounded-pill mx-1">
            <button class="rounded-pill next-btn  ms-1 mt-md-0 mt-1">Assign <i
                class="pi pi-angle-right mt-1"></i><span></span></button>
        </div>
    </div>

</form>
