import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { CalenderStepsService } from 'src/app/services/calender-steps.service';
import { EventStepsService } from 'src/app/services/event-steps.service';

@Component({
  selector: 'app-calender-steps',
  templateUrl: './calender-steps.component.html',
  styleUrls: ['./calender-steps.component.scss']
})
export class CalenderStepsComponent implements OnInit{
  helpStepsModal: boolean;
  position: string;
  showHelpStepsModal() {
      this.helpStepsModal = true;
  }
  items = [ {label: 'Count of instance', routerLink: ['step3']},
    {label: 'First instance date', routerLink: ['step1'] },
    {label: 'Reccuring setting', routerLink:  ['step2'] },
    {label: 'Preview', routerLink:  ['step4'] }


];
constructor(private router: Router, private activatedRoute: ActivatedRoute,private EventSrv:EventStepsService,private srv:CalenderStepsService,private translate: TranslateService){
    this.translate.onLangChange.subscribe(() => {
        this.updateTranslations();
      });
}
    updateTranslations() {
        this.items = [ {label: this.translate.instant('CalenderLable1'), routerLink: ['step3']},
    {label:  this.translate.instant('CalenderLable2'), routerLink: ['step1'] },
    {label:  this.translate.instant('CalenderLable3'), routerLink:  ['step2'] },
    {label:  this.translate.instant('CalenderLable4'), routerLink:  ['step4'] }


];
    }
  ngOnInit(): void {
    this.updateTranslations();
    this.srv.clenderSteps.controls['eventId'].setValue(this.EventSrv.id)
    this.router.navigate( ['step3'] , { relativeTo: this.activatedRoute });
  }
}
