<form [formGroup]="form" (submit)="submit()">

    <div class="row justify-content-center mb-2">
        <div class="p-input-filled col-sm-10 col-12 mb-2">
            <span class="p-float-label">
                <input type="text" formControlName="email" pInputText class="form-control" [ngClass]="{
                        'ng-invalid ng-dirty':(  form.get('email')?.touched &&
                        form.get('email')?.invalid)||(submitted&& form.get('email').invalid)
                    }" [class.ng-invalid.ng-dirty]="" />
                <label class="form-lable font-size-14" for="Ticketemail">email</label>
            </span>
            <small class="p-error" *ngIf="(  form.get('email')?.touched &&
            form.get('email')?.invalid)||(submitted&& form.get('email').invalid)
                " >{{'Please add valid email'|translate}}</small>

        </div>
        <div class="p-input-filled col-lg-4 col-md-4 col-12">
            <span class="p-float-label">
                <p-dropdown appendTo="body" formControlName="roleId" autoWidth="false" [style]="{'width':'100%'}"
                    [options]="data1" optionLabel="name" optionValue="id" [ngClass]="{
        'ng-invalid ng-dirty':
            (form.get('roleId')?.touched &&
            form.get('roleId')?.invalid) || ( submitted &&
            form.get('roleId')?.invalid)
    }" placeholder="Role">
                    <ng-template let-item pTemplate="selectedItem">
                        <div class="flex align-items-center gap-2">
                            <div>{{ item.name }}</div>
                        </div>
                    </ng-template>
                    <ng-template let-item pTemplate="item">
                        <div class="flex align-items-center gap-2">
                            <div>{{ item.name }}</div>
                        </div>
                    </ng-template>

                </p-dropdown>
                <label class="font-size-14" for="roleId">Role</label>
            </span>
            <small class="p-error" *ngIf="(form.get('roleId')?.touched &&
    form.get('roleId')?.invalid) || ( submitted &&
    form.get('roleId')?.invalid)">{{'PrmoFrmStp1Small1'|translate}}</small>
    </div>

    </div>

    <div class=" d-flex  justify-content-center">
            <button  class="rounded-pill next-btn  ms-1 ">Invite <i class="fa-solid fa-paper-plane"></i><span></span></button>
    </div>

</form>


