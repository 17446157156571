import { Component , OnDestroy, OnInit } from '@angular/core';
import { IntersetService } from 'src/app/services/interset.service';
import { Categories, SubCategories } from 'src/app/Interfaces/intersets';
import {ToggleButtonModule} from 'primeng/togglebutton';
import { FormArray, FormBuilder } from '@angular/forms';
import { LoaderService } from 'src/app/services/loader.service';
import { Location } from '@angular/common';
import { AuthService } from 'src/app/services/auth.service';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
@Component({
    selector: 'app-interest',
    templateUrl: './interest.component.html',
    styleUrls: ['./interest.component.scss']
})
export class InterestComponent implements OnInit,OnDestroy{
    i : number = 0;
    userId = this.auth.securityObject.id;
    data !: Categories[];
    interestsData : number[] =[];
    interestForm = this.fb.group({
        interests : this.fb.array([]),
    });
    subs:Subscription[]=[] ;

    constructor(private router : Router,public auth : AuthService,public load: LoaderService,
         private fb: FormBuilder, public intersetService: IntersetService,private loc : Location,
           public translate: TranslateService) {

       var t=  this.translate.onLangChange.subscribe(() => {
            this.updateLang();
          });
          this.subs.push(t)
    }
    ngOnDestroy(): void {
       this.subs.forEach(x=>{
x.unsubscribe();
       })
    }
    updateLang() {

        this.resetform()
        this.load.showLoader();
      var l=  this.intersetService.getAllIntersets().subscribe(x => {
            this.data = x; this.setIntersetForm(x);
            
            this.intersetService.getUserInterests(this.userId);
        var m=    this.intersetService.$getUserInterests  .subscribe(x =>{
                if(x.length > 0){
                    this.setInterest(x);
                }
            })
            this.subs.push(m)
            this.load.hideLoader();
        });
        this.subs.push(l);
    }
    get formPermissions() {
        return this.interestForm.get('interests') as FormArray;
      }

resetform(){
    const control = <FormArray>this.interestForm.controls['interests'];
        for(let i = control.length-1; i >= 0; i--) {
            control.removeAt(i)
    }
}

    ngOnInit(): void {
       
        // this.intersetService.getAllIntersets().subscribe(x => {
        //     this.data = x; this.setIntersetForm(x);
        //     this.load.hideLoader();
        //     this.intersetService.getUserInterests(this.userId);
        //     this.intersetService.$getUserInterests  .subscribe(x =>{
        //         if(x.length > 0){
        //             this.setInterest(x);
        //         }
        //     })
        // });

       this.updateLang()
    // console.log(this.interestForm);
    }

    setIntersetForm(controls: Categories[]): void{

        for (const control of controls){
            for (const item of control.subCategory){
                 const interest = this.fb.group({
                    id : item.id ,
                    name : item.name , 
                    value1 : false
                 })
                this.formPermissions.push(interest);


                // this.interestForm.addControl(item.id + '' , this.fb.control(false , ));
            }
        }
    }
    saveForm(){
        this.interestForm.value.interests.forEach((x : {value1 : boolean , name : string , id : number}) => {
            if(x.value1){
                this.interestsData.push(x.id);
            }
        })
        const data = {
            interests : this.interestsData ,
            userId : this.userId
        };

        this.intersetService.postUserInterests(data)
        this.router.navigate([''])

    }
    counter(){

        return this.i++
      }

    goBack(){
        this.loc.back();
      }
      setInterest(p : {name :string,id : number,value : boolean}[]){


        for (let index = 0; index < p.length; index++) {
          const element = this.formPermissions.controls.find(x => x.value.id == p[index].id);
          element.setValue({...element.value, value1 :true})
    
    
        }
    
    
    
    
      }

}

