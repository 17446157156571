

<div class="container">
    <div class="card custom-table-border">
        <div class="card-header border-0 text-center bg-primary rounded-top-23 ">
            <h1 class="fw-bold text-white mt-2">{{'AddOnTblTitl'|translate}}</h1>
            </div>
        <div *ngIf="loader.show1" @fadeOut class="d-flex justify-content-center align-items-center" style="border-radius: 25px;top : 0;position: absolute;z-index: 1050;background-color:#fff;width: 100%;height: -webkit-fill-available;right: 0;bottom: 0;left: 0;">


        </div>


        <p-toast></p-toast>

        <div class="" >


            <p-table #dt responsiveLayout="stack" [breakpoint]="'991px'" [value]="data" [rows]="5" [paginator]="totalRecourd>5" [globalFilterFields]="['name']"
            [tableStyle]="{'min-width': '100%','max-width':'100%'}" dataKey="id"
            currentPageReportTemplate="{{'Showing'|translate}} {first} {{'to'|translate}} {last} {{'of'|translate}} {totalRecords} {{'entries'|translate}}"
            [showCurrentPageReport]="true" [lazy]="true" [totalRecords]="totalRecourd" (onLazyLoad)="getAddOnsLazy($event)">
            <ng-template pTemplate="caption">


                <div>

                    <div class="d-sm-flex d-block align-items-center justify-content-sm-between text-center">

                        <div class="  ms-md-4 mb-sm-0  mb-2">

                            <button type="button" class="rounded-pill addTag-btn me-1 my-md-0 my-1"
                                (click)="addAddOn()">{{'AddOnTblBtn001'|translate}} <span></span></button>
                            <!--  -->
                            <button type="button" *ngIf="canaddGroup"
                                class="rounded-pill addTag-btn me-1 my-md-0 my-1" (click)="addGroup()">{{'AddOnTblBtn002'|translate}}
                                <span></span></button>

                                <button type="button"
                                class="rounded-pill addTag-btn me-1 my-md-0 my-1" (click)="copyAddOns()">{{'CopAddOnsTitl2'|translate}}
                                <span></span></button>

                        </div>
                        <div class="p-input-filled me-md-3 ">
                            <span class="p-input-icon-left">
                                <i class="pi pi-search"></i>
                                <input pInputText type="text" class="form-control"
                                    (input)="dt.filterGlobal($event.target.value, 'contains')"
                                    placeholder="{{'Search...'|translate}}" />
                            </span>
                        </div>
                    </div>
                </div>


            </ng-template>
            <ng-template pTemplate="header">
                <tr>
                    <th></th>
                    <th class="fw-bold text-center">{{'AddOnTblTh001'|translate}}</th>
                    <th class="fw-bold text-center">{{'AddOnTblTh002'|translate}} </th>

                    <th class="fw-bold text-center">{{'AddOnTblTh003'|translate}} </th>
                    <!-- <th pSortableColumn="category">Category <p-sortIcon field="category"></p-sortIcon></th> -->
                    <th class="fw-bold text-center" >{{'AddOnTblTh004'|translate}} </th>
                    <th class="fw-bold text-center" > {{'AddOnTblTh005'|translate}}</th>
                    <th></th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-product let-expanded="expanded">
                <tr>
                    <td>
                        <button *ngIf="product.varieties!=null" type="button" pButton pRipple
                            [pRowToggler]="product" class="p-button-text p-button-rounded p-button-plain"
                            [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"></button>
                    </td>
                    <td class="text-md-center padding-custom text-end Image-cutom"><span class=" text-start fw-bold "></span><img [src]="url+product.image" [alt]="product.name" width="100"
                            class="shadow-4" /></td>
                    <td class="text-md-center padding-custom text-lenght-larg text-end"><span class="headers-cutom text-start fw-bold ">{{'AddOnTblTh002'|translate}}</span><span class="text-lenght">{{product.name}}</span></td>
                    <td class="text-md-center padding-custom text-end"><span class="headers-cutom text-start fw-bold ">{{'AddOnTblTh003'|translate}}</span>
                        {{(product.price==-1&&product.varieties!=Null)?'['+(getMinPrice(product)+" "+cuurency)+" - "+(getMaxPrice(product)+" "+cuurency)+']':(product.price==-1)?'Free':product.price+" "+cuurency
                        }}</td>
                    <td class="text-md-center padding-custom text-end"><span class="headers-cutom text-start fw-bold ">{{'AddOnTblTh004'|translate}}</span>{{product.soldItems+"/"+product.qty}}</td>
                    <td class="text-md-center ">
                        <div [class.action-custom]="document.documentElement.dir === 'ltr'"
                        [class.action-custom-rtl]="document.documentElement.dir === 'rtl'">

                        <button *ngIf="product.varieties!=Null" pButton pRipple icon="pi pi-pencil"
                            class="p-button-rounded p-button-primary mr-2"
                            (click)="updateAddOnsGroup(product)"></button>
                        <button *ngIf="product.varieties==Null" pButton pRipple icon="pi pi-pencil"
                            class="p-button-rounded p-button-primary mr-2"
                            (click)="updateAddOns(product)"></button>
                        <button *ngIf="product.varieties!=Null" pButton pRipple icon="pi pi-trash"
                            class="p-button-rounded p-button-danger" (click)="removegroup($event,product)"></button>
                     <button *ngIf="product.varieties==Null" pButton pRipple icon="pi pi-trash"
                            class="p-button-rounded p-button-danger" (click)="remove($event,product)"></button>
                        </div>
                        <p-confirmPopup></p-confirmPopup>
                    </td>

                    <td class="text-center"><span
                            [class]="'product-badge status-'">{{product.inventoryStatus}}</span></td>
                </tr>
            </ng-template>
            <ng-template pTemplate="rowexpansion" let-product>
                <tr>
                    <td colspan="7">
                            <p-table responsiveLayout="stack" [breakpoint]="'991px'" [value]="product.varieties" dataKey="id" class="table-responsive">

                                <ng-template pTemplate="body" let-AddOns>
                <tr>
                    <td class="text-md-center padding-custom text-end Image-cutom"><span class=" text-start fw-bold "></span><img [src]="url+AddOns.image" [alt]="product.name" width="100" class="shadow-4" /></td>
                    <td class="text-md-center padding-custom text-lenght-larg text-end"><span class="headers-cutom text-start fw-bold ">{{'AddOnTblTh002'|translate}}</span><span class="text-lenght">{{AddOns.name}}</span></td>
                    <td class="text-md-center padding-custom text-end"><span class="headers-cutom text-start fw-bold ">{{'AddOnTblTh003'|translate}}</span>{{AddOns.price==-1?'Free':AddOns.price+"  "+cuurency}}</td>
                    <td class="text-md-center padding-custom text-end"><span class="headers-cutom text-start fw-bold ">{{'AddOnTblTh004'|translate}}</span>{{AddOns.soldItems+"/"+AddOns.qty}}</td>
                    <td>
                        <div [class.action-custom]="document.documentElement.dir === 'ltr'"
                        [class.action-custom-rtl]="document.documentElement.dir === 'rtl'">
                        <button pButton pRipple icon="pi pi-pencil"
                            class="p-button-rounded p-button-primary mr-2"
                            (click)="updateAddOns(AddOns)"></button>
                        <button pButton pRipple icon="pi pi-trash"
                            class="p-button-rounded p-button-danger" (click)="remove($event,AddOns)"></button>
                        </div>
                        <p-confirmPopup></p-confirmPopup>
                    </td>
                </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage">
                <tr>
                    <td colspan="6">{{'addOnTbEmpty'|translate}}</td>
                </tr>
            </ng-template>
        </p-table>
    </td>
    </tr>
    </ng-template>
    <ng-template pTemplate="summary">
        <div class="flex align-items-center justify-content-between">
           {{'AddOnTblFooter01'|translate}} {{data ? datacount: 0 }} {{'AddOnTblFooter02'|translate}}
        </div>
    </ng-template>
    </p-table>


        </div>

    </div>
</div>















