
<div class="container  py-3">
    <div class="card custom-table-border">
        <div class="card-header text-center bg-primary rounded-top-23">
            <h1 class="fw-bold text-white mt-2">{{'OrgnzrFrmTitl'|translate}}</h1>
        </div>
        <div class="card-body">
            <div class="card-body p-0">
                <div class="">
<div class="row">
        <div class="col-12">
            <p-steps [model]="items" [readonly]="true"></p-steps>
        </div>
        <div class="col-12">
            <router-outlet ></router-outlet>
        </div>


</div>
</div>
</div>
        </div>
    </div>
</div>

