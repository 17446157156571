<div class=" rounded-25 mt-3">
    <div class="container rounded-top-right-25 rounded-top-left-25 bg-modified  p-0">
        <div class="d-flex align-items-center justify-content-center rounded-top-right-25 rounded-top-left-25">



            <div class=" text-center pt-3 bg-white organizer-space shadow-sm rounded-top-right-25 rounded-top-left-25 ">
                <div class="d-flex justify-content-center">
                    <img style="height: 250px;" [src]="url + data?.image" alt="user Profile Photo"
                        class=" rounded-circle" />

                </div>
                <div class="">
                    <h3 class="text-primary mt-3 text-lenght fw-bold">
                        <i class="fas fa-user"></i> {{auth.securityObject.firstName+""+auth.securityObject.lastName}} 
                    </h3>
                </div>

            </div>


        </div>
    </div>
    <!-- <div class="waves container p-0 mb-xl-5 mb-lg-5 mb-md-5 mb-sm-4 ">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
            <path fill="#FFF3E5" fill-opacity="1"
                d="M0,96L120,117.3C240,139,480,181,720,192C960,203,1200,181,1320,170.7L1440,160L1440,320L1320,320C1200,320,960,320,720,320C480,320,240,320,120,320L0,320Z">
            </path>
        </svg>

    </div> -->

    <section class="container bg-modified rounded-bottom-right-25 rounded-bottom-left-25">
        <div class="row  ">
            <div class="col-xl-6 col-lg-6 col-12">
                <div class="row">
                    <div class="col-12">
                        <div>
                            <i class="fa-solid fa-location-dot text-primary fw-bold"></i> <span class="text-primary">  Logical Address:
                            </span> {{data?.userAddress?.address}}
                        </div>
                    </div>
                    <div class="col-12" style="padding: 20px; position: relative; height: 500px;">

                        <div #gmap3 style="width:90%;height:90%; position: absolute;" #addresstext></div>
                    </div>
                </div>
            </div>
            <div class="col-xl-6 col-lg-6 col-12">
                <div class="row">
                    <div class="col-12">
                        <div>
                            <i class="fa-solid fa-location-dot text-primary fw-bold"></i> <span class="text-primary"> Shipping Address: </span>
                            {{data?.userShippingAddress?.address}}
                        </div>
                    </div>
                    <div class="col-12" style="padding: 20px; position: relative; height: 500px;">
                        <div #gmap4 style="width:90%;height:90%; position: absolute;" #addresstext></div>
                    </div>
                </div>
            </div>
         
        </div>
    </section>

</div>