import { Component, ComponentFactoryResolver, OnDestroy, OnInit, Type, ViewChild, ViewContainerRef,Inject } from '@angular/core';
import { EventsService } from '../../../services/events.service';
import { EventData, EventTableDto } from '../../../Interfaces/EventData';
import { DataViewModule } from 'primeng/dataview';
import { ConfirmationService, LazyLoadEvent, MessageService, SelectItem } from 'primeng/api';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { BasicInfoComponent } from './event-steps/basic-info/basic-info.component';
import { Route, Router } from '@angular/router';
import { CalendarOptions, DateSelectArg, EventApi, EventClickArg, EventInput } from '@fullcalendar/core';
import interactionPlugin from '@fullcalendar/interaction';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import { FullCalendarComponent } from '@fullcalendar/angular';
import { DropDownItem } from 'src/app/Interfaces/DropDownItem';
import { OverlayPanel } from 'primeng/overlaypanel';
import { Table } from 'primeng/table';
import { CalenderEvent, CalenderEventapi } from 'src/app/Interfaces/calenderDto';
import { environment } from 'src/environments/environment';
import { Subscription } from 'rxjs';
import { LoaderService } from 'src/app/services/loader.service';
import { transition, style, animate, trigger } from '@angular/animations';
import { AuthService } from 'src/app/services/auth.service';
import { DOCUMENT, DatePipe } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import allLocales from '@fullcalendar/core/locales-all';
import * as m from 'moment';
import 'moment/locale/pt-br';

const leaveTrans = transition(':leave', [
  
  style({
    opacity: 1
  }),
  animate('1s ease-out', style({
    opacity: 0
  }))
])



const fadeOut = trigger('fadeOut', [
  leaveTrans
]);
@Component({
  selector: 'app-events',
  templateUrl: './events.component.html',
  styleUrls: ['./events.component.scss'],
  animations: [fadeOut]

})
export class EventsComponent implements OnInit, OnDestroy {


  url = environment.Api;
  sub: Subscription[]=[];
  TargetChanged = true;
  endSub = false;
  events: EventTableDto[] = [];

  Subevents: EventTableDto[] = [];
  totalRecins:number=0
  cols!: any[];
  sortOptions: SelectItem[];
  recuring = '#eee';
  single = '#ffd384';
  sortOrder: number;
  sortKey: any;
  sortField: string;
  @ViewChild('op') op: OverlayPanel;
  @ViewChild('op1') op1: OverlayPanel;
  @ViewChild('dt') dt: Table;
  @ViewChild('cal') cal: FullCalendarComponent;
  plugin: DropDownItem[] = [
    { name: 'Month', id: 'dayGridMonth' },
    { name: 'Week', id: 'timeGridWeek' },
    { name: 'Day', id: 'timeGridDay' },
    { name: 'List week', id: 'listWeek' },
    { name: 'Grid day', id: 'dayGridDay' },
    { name: 'Grid week', id: 'dayGridWeek' },

  ];
  selectedFilter:string=""
  inialVew = 'dayGridMonth';
  calendarVisible = false;
  claenderEvent: CalenderEventapi[] = [];
  calendarOptions: CalendarOptions = {
    customButtons: {
      SetThem: {
        text: 'Display mode',
        click: this.setDisablyView.bind(this)
      }
    },
    plugins: [
      interactionPlugin,
      dayGridPlugin,
      timeGridPlugin,
      listPlugin,
    ],
    headerToolbar: {
      left: 'prev',
      center: 'title',
      right: 'SetThem next'
    },
    handleWindowResize: true,
    initialView: this.inialVew,
    initialEvents: this.events,
    editable: true,
    selectable: true,
    selectMirror: true,
    dayMaxEvents: true,
    customRenderingReplacesEl: true
  };
  allEventForPrev: EventTableDto[] = [];
  eventPrv: EventTableDto;

  items = [
    {label: 'All event',id:'all' , command: (item) => {
      //this.update();
      this.selectedFilter=item.item.label
      this.filterselected=item.item.id;
      this.eventService.GetEventsTableLines(item.item.id,this.filterselected1);
      this.eventService.getEventFOrCalender(item.item.id,this.filterselected1);
  }   },
  {label: 'Draft', id:'Draft', command: (item) => {
    //this.update();
   // console.log(item)
    this.selectedFilter=item.item.label
    this.filterselected=item.item.id;
    this.eventService.GetEventsTableLines(item.item.id,this.filterselected1);
    this.eventService.getEventFOrCalender(item.item.id,this.filterselected1);
}   },
{label: 'Reccuring event', id:'req', command: (item) => {
  //this.update();
  this.selectedFilter=item.item.label
  this.filterselected=item.item.id;
  this.eventService.GetEventsTableLines(item.item.id,this.filterselected1);
  this.eventService.getEventFOrCalender(item.item.id,this.filterselected1);
}   },
{label: 'Event series', id:'sris', command: (item) => {
  this.selectedFilter=item.item.label;
  this.filterselected=item.item.id;
  this.eventService.GetEventsTableLines(item.item.id,this.filterselected1);
  this.eventService.getEventFOrCalender(item.item.id,this.filterselected1);
  //this.update();
}   },
{label: 'Single event', id:'singl', command: (item) => {
  this.selectedFilter=item.item.label;
  this.filterselected=item.item.id;
  this.eventService.GetEventsTableLines(item.item.id,this.filterselected1);
  this.eventService.getEventFOrCalender(item.item.id,this.filterselected1);
  //this.update();
}   },
{label: 'Published event', id:'Puplish', command: (item) => {
  this.selectedFilter=item.item.label
  this.filterselected=item.item.id;
  this.eventService.GetEventsTableLines(item.item.id,this.filterselected1);
  this.eventService.getEventFOrCalender(item.item.id,this.filterselected1);
  //this.update();
}   },

];
  orgnizerData: any[]=[
    {label:  this.translate.instant('AllUserEvent'),id:'all' , command: (item) => {
      //this.update();
      this.selectedFilter1=item.item.label
      this.filterselected1=item.item.id;
    this.eventService.GetEventsTableLines(item.item.id,this.filterselected1);
       this.eventService.getEventFOrCalender(item.item.id,this.filterselected1);
  }   }
  ];
  selectedFilter1: string='';
  filterselected1: string='';
updateLang(){
  this.eventService.GetOrganizersByUser().subscribe((x) => {
    //console.log(x)
    this.orgnizerData=[
      {label:  this.translate.instant('AllUserEvent001'),id:'' , command: (item) => {
        //this.update();
        this.selectedFilter1=item.item.label
        this.filterselected1=item.item.id;
         this.eventService.GetEventsTableLines(this.filterselected,item.item.id);
         this.eventService.getEventFOrCalender(item.item.id,this.filterselected1);
    }   }
    ];
    x.forEach(y=>{
      var t={label:y.name+" "+  this.translate.instant('AllUserEvent002'), id:y.id, command: (item) => {
        this.selectedFilter1=item.item.label;
        this.filterselected1=item.item.id;
        this.eventService.GetEventsTableLines(this.filterselected,item.item.id);
        this.eventService.getEventFOrCalender(this.filterselected,item.item.id);
        //this.update();
      }}
      this.orgnizerData.push(t)
    })
    // console.log(x);
    // this.orgnizerData = x;
    // this.orgnizerData.push({})
    
    
     }); 
     this.eventService.GetEventsTableLines(this.filterselected,this.filterselected1);
     this.eventService.getEventFOrCalender(this.filterselected,this.filterselected1);
    //  this.calenderintial(this.allEventForPrev);
    this.items = [
        {label:  this.translate.instant('EvntTblBtn002Chois001'),id:'all' , command: (item) => {
          //this.update();
          this.selectedFilter=item.item.label
          this.filterselected=item.item.id;
          this.eventService.GetEventsTableLines(item.item.id,this.filterselected1);
          this.eventService.getEventFOrCalender(item.item.id,this.filterselected1);
      }   },
      {label: this.translate.instant('EvntTblBtn002Chois002'), id:'Draft', command: (item) => {
        //this.update();
      //  console.log(item)
        this.selectedFilter=item.item.label
        this.filterselected=item.item.id;
        this.eventService.GetEventsTableLines(item.item.id,this.filterselected1);
        this.eventService.getEventFOrCalender(item.item.id,this.filterselected1);
    }   },
    {label: this.translate.instant('EvntTblBtn002Chois003'), id:'req', command: (item) => {
      //this.update();
      this.selectedFilter=item.item.label
      this.filterselected=item.item.id;
      this.eventService.GetEventsTableLines(item.item.id,this.filterselected1);
      this.eventService.getEventFOrCalender(item.item.id,this.filterselected1);
    }   },
    {label: this.translate.instant('EvntTblBtn002Chois004'), id:'sris', command: (item) => {
      this.selectedFilter=item.item.label;
      this.filterselected=item.item.id;
      this.eventService.GetEventsTableLines(item.item.id,this.filterselected1);
      this.eventService.getEventFOrCalender(item.item.id,this.filterselected1);
      //this.update();
    }   },
    {label: this.translate.instant('EvntTblBtn002Chois005'), id:'singl', command: (item) => {
      this.selectedFilter=item.item.label;
      this.filterselected=item.item.id;
      this.eventService.GetEventsTableLines(item.item.id,this.filterselected1);
      this.eventService.getEventFOrCalender(item.item.id,this.filterselected1);
      //this.update();
    }   },
    {label: this.translate.instant('EvntTblBtn002Chois006'), id:'Puplish', command: (item) => {
      this.selectedFilter=item.item.label
      this.filterselected=item.item.id;
      this.eventService.GetEventsTableLines(item.item.id,this.filterselected1);
      this.eventService.getEventFOrCalender(item.item.id,this.filterselected1);
      //this.update();
    }   },

    ];

    this.plugin = [
      { name: this.translate.instant('ShdlrTblBtn002Chois001'), id: 'dayGridMonth' },
      { name: this.translate.instant('ShdlrTblBtn002Chois002'), id: 'timeGridWeek' },
      { name: this.translate.instant('ShdlrTblBtn002Chois003'), id: 'timeGridDay' },
      { name: this.translate.instant('ShdlrTblBtn002Chois004'), id: 'listWeek' },
      { name: this.translate.instant('ShdlrTblBtn002Chois005'), id: 'dayGridDay' },
      { name: this.translate.instant('ShdlrTblBtn002Chois006'), id: 'dayGridWeek' },
  
    ];
}
totalRecourd:number=0;
  filterselected: string='all';

  constructor(
    public auth: AuthService,
    public loader: LoaderService,
    public eventService: EventsService,
    private route: Router,
    private conSrv: ConfirmationService,
    private messageService: MessageService,
    private datePipe: DatePipe ,
    @Inject(DOCUMENT) public document: Document,
    private translate:TranslateService
  ) {    this.translate.onLangChange.subscribe(() => {
    this.updateLang();
    m.locale(this.translate.currentLang)
  });}
  ngOnDestroy(): void {
    this.sub.forEach(element => {
      element.unsubscribe();
    });
  }

  goto(id){
    this.route.navigate(['/events/manage-event/',id,'SubEvents']);
  }

  chang(s: boolean) {
    this.calendarVisible = s;
  }
  gettime(x: Date): string {
    let t = new Date(x).toLocaleTimeString();
    return t;
  }

  getrecDate(x: Date): string {
    let t = new Date(x).toLocaleDateString();
    return t;
  }
  eventRender(info) {
    const button = document.createElement('button');
    button.innerText = 'Click me';
    button.addEventListener('click', () => {
      // Handle button click event here
    });
    info.el.appendChild(button);
  }
  claenderEvent2 = [];
  calenderintial(event){

      this.claenderEvent = [];


    if (event.length !== 0) {
      for (let i = 0; i < event.length; i++) {
        var item=event[i]
        this.claenderEvent.push({
          id: item.id,
          title: item.title,
          start: this.getDateOntomeZone(item.startDate),
          end: this.getDateOntomeZone(item.endDate),
         // backgroundColor:item.isRecurring ? this.recuring : this.single,
          borderColor: '#ff8703',
          textColor: 'Black',
          groupId: `${i}`,
          display: 'block'
        });
        this.allEventForPrev.push(event[i]);

      }
    }

    this.claenderEvent2=this.claenderEvent;
    this.calendarOptions = {
      eventContent: (arg: any) => {   //add time
        let container = document.createElement('div')
        container.className = "d-flex gap-1 p-1";
        let timeText = document.createElement('div')
        timeText.className = "fc-event-time";
        timeText.innerHTML = arg.timeText;
        //include additional info
        let additionalText = document.createElement('i')
        additionalText.className = "mx-2";
        additionalText.style.fontSize = "18px"
        additionalText.style.overflowX = "hidden"
        additionalText.innerHTML = arg.event.title;
        //include img/icon that should work as a button
        let img = document.createElement('a');
        img.className = "btn btn-sm btn-primary d-flex justify-content-center align-items-center";
        img.innerHTML = "<i class='pi pi-eye'> </i>"
        img.addEventListener("click", (e: Event) => {
          e.stopPropagation();  //prevents eventClick to be triggered
          //  this.anotherMethod();  //this is not working because here 'this' is the function
          this.handleEventMouseEnter1(arg.event.id, e.target, e)
        })
        let img1 = document.createElement('a');
        img1.className = "btn  btn-sm btn-primary d-flex justify-content-center align-items-center";
        img1.innerHTML = '<i class="fa-light fa-right-to-bracket"></i>'
        img1.addEventListener("click", (e: Event) => {
          e.stopPropagation();  //prevents eventClick to be triggered
          //  this.anotherMethod();  //this is not working because here 'this' is the function
          this.handleEventClick(arg.event.id)
        })
        container.appendChild(img);
        container.appendChild(img1);
        container.appendChild(additionalText);
        //   let arrayOfDomNodes = [ timeText, additionalText, img, img1 ]
        let arrayOfDomNodes = [container]
        return { domNodes: arrayOfDomNodes }

      }
      , locales: allLocales,
      locale:this.translate.currentLang
      ,
      customButtons: {

        SetThem: {
          text: this.translate.instant('ShdlrTblBtn002'),
          click: this.setDisablyView.bind(this)
        }
      },
      plugins: [
        interactionPlugin,
        dayGridPlugin,
        timeGridPlugin,
        listPlugin,
      ],
      droppable: false,
      editable: false,
      headerToolbar: {
        left: 'prev',
        center: 'title',
        right: 'SetThem next'
      },
      initialView: this.inialVew,
      initialEvents: this.events,
      selectable: true,
      selectMirror: true,
      dayMaxEvents: true,
      events: this.claenderEvent,
      // dateClick: (e) => this.clicged(e),
      // select: this.handleDateSelect.bind(this),
      // eventClick: this.handleEventClick.bind(this),
      // eventMouseEnter: (e) => {this.handleEventMouseEnter(e); },
      //  eventMouseLeave: (e) => {this.handleEventMouseLeave(e); },
      // eventsSet: this.handleEvents.bind(this),
      customRenderingReplacesEl: true
    };

  }
  ngOnInit(): void {
    this.loader.showLoader();
    this.updateLang();

    
    this.eventService.getEventFOrCalender(this.filterselected,this.filterselected1)
    this.sortOptions = [
      { label: 'Price High to Low', value: '!price' },
      { label: 'Price Low to High', value: 'price' }
    ];

    // this.filterselected='all';
    // this.eventService.GetEventsTableLines('all');

     var l= this.eventService.$getSubject.subscribe(x => {
      this.allEventForPrev = []
      this.events = x.events;
 this.totalRecourd=x.totalCount;
      this.endSub = true;



      // this.claenderEvent = [];
      // if (x.events.length !== 0) {
      //   for (let i = 0; i < x.events.length; i++) {
      //     var item=x.events[i]
      //     this.claenderEvent.push({
      //       id: item.id,
      //       title: item.title,
      //       start: this.getDateOntomeZone(item.startDate),
      //       end: this.getDateOntomeZone(item.endDate),
      //       backgroundColor:item.isRecurring ? this.recuring : this.single,
      //       borderColor: '#ff8703',
      //       textColor: 'Black',
      //       groupId: `${i}`,
      //       display: 'block'
      //     });
      //     this.allEventForPrev.push(x[i]);
      //     // if (x[i].eventInstance != null) {
      //     //   for (let j = 0; j < x[i].eventInstance.length; j++) {
      //     //     let t = x[i].eventInstance[j];
      //     //     this.claenderEvent.push({
      //     //       id: t.id,
      //     //       title: t.title,
      //     //       start: this.getDateOntomeZone(t.startDate),
      //     //       end: this.getDateOntomeZone(t.endDate),
      //     //       backgroundColor: this.recuring,
      //     //       borderColor: '#ff8703',
      //     //       textColor: 'Black',
      //     //       groupId: `${i}`,
      //     //       display: 'block',
      //     //     });
      //     //     this.allEventForPrev.push(t);
      //     //   }
      //     // }
      //     // console.log( this.allEventForPrev)
      //   }
      // }
      // this.calendarOptions = {
      //   eventContent: (arg: any) => {   //add time
      //     let container = document.createElement('div')
      //     container.className = "d-flex gap-1 p-1";
      //     let timeText = document.createElement('div')
      //     timeText.className = "fc-event-time";
      //     timeText.innerHTML = arg.timeText;
      //     //include additional info
      //     let additionalText = document.createElement('i')
      //     additionalText.className = "mx-2";
      //     additionalText.style.fontSize = "18px"
      //     additionalText.style.overflowX = "hidden"
      //     additionalText.innerHTML = arg.event.title;
      //     //include img/icon that should work as a button
      //     let img = document.createElement('a');
      //     img.className = "btn btn-sm btn-primary d-flex justify-content-center align-items-center";
      //     img.innerHTML = "<i class='pi pi-eye'> </i>"
      //     img.addEventListener("click", (e: Event) => {
      //       e.stopPropagation();  //prevents eventClick to be triggered
      //       //  this.anotherMethod();  //this is not working because here 'this' is the function
      //       this.handleEventMouseEnter1(arg.event.id, e.target, e)
      //     })
      //     let img1 = document.createElement('a');
      //     img1.className = "btn  btn-sm btn-primary d-flex justify-content-center align-items-center";
      //     img1.innerHTML = '<i class="fa-light fa-right-to-bracket"></i>'
      //     img1.addEventListener("click", (e: Event) => {
      //       e.stopPropagation();  //prevents eventClick to be triggered
      //       //  this.anotherMethod();  //this is not working because here 'this' is the function
      //       this.handleEventClick(arg.event.id)
      //     })
      //     container.appendChild(img);
      //     container.appendChild(img1);
      //     container.appendChild(additionalText);
      //     //   let arrayOfDomNodes = [ timeText, additionalText, img, img1 ]
      //     let arrayOfDomNodes = [container]
      //     return { domNodes: arrayOfDomNodes }

      //   }
      //   ,
      //   customButtons: {

      //     SetThem: {
      //       text: 'Display mode',
      //       click: this.setDisablyView.bind(this)
      //     }
      //   },
      //   plugins: [
      //     interactionPlugin,
      //     dayGridPlugin,
      //     timeGridPlugin,
      //     listPlugin,
      //   ],
      //   droppable: false,
      //   editable: false,
      //   headerToolbar: {
      //     left: 'prev',
      //     center: 'title',
      //     right: 'SetThem next'
      //   },
      //   initialView: this.inialVew,
      //   initialEvents: this.events,
      //   selectable: true,
      //   selectMirror: true,
      //   dayMaxEvents: true,
      //   events: this.claenderEvent,
      //   // dateClick: (e) => this.clicged(e),
      //   // select: this.handleDateSelect.bind(this),
      //   // eventClick: this.handleEventClick.bind(this),
      //   // eventMouseEnter: (e) => {this.handleEventMouseEnter(e); },
      //   //  eventMouseLeave: (e) => {this.handleEventMouseLeave(e); },
      //   // eventsSet: this.handleEvents.bind(this),
      //   customRenderingReplacesEl: true
      // };


      this.loader.hideLoader();
      var l=this.eventService.$getClenderSubject.subscribe(x=>{
        this.calenderintial(x);
      })

      return this.events;
    });
    this.sub.push(l)



    this.cols = [
      { field: 'startDate', header: '' },
      { field: 'photoLink', header: '' },
      { field: 'title', header: 'Title' },
      { field: 'totalQty', header: 'Sold' },
      { field: 'soldTickets', header: 'Sold' },
      { field: 'gross', header: 'Gross' },
      { field: 'status', header: 'Status' },
      { field: 'id', header: 'Actions' }
    ];
  }

  getEvent(e:LazyLoadEvent){
    var l=e.first/5;
    this.eventService.GetEventsTableLines(this.filterselected,this.filterselected1,l);
  }
  getinstanse(id:string,e:LazyLoadEvent){
    if(e){
      var l=e.first/5;
      this.eventService.GetEventRecuringTableInstan(id,l);
    }else{
      this.eventService.GetEventRecuringTableInstan(id,0);
    }

    this.eventService.$getRegSubject.subscribe(x=>{
      this.Subevents=x.events;
      this.totalRecins=x.totalCount;


    })
  }


  onSortChange(event): void {
    const value = event.value;

    if (value.indexOf('!') === 0) {
      this.sortOrder = -1;
      this.sortField = value.substring(1, value.length);
    }
    else {
      this.sortOrder = 1;
      this.sortField = value;
    }
  }
  editProduct(product) {
    this.route.navigate(['/events/manage-event/', product.id]);
  }
  remove(event, id) {

    this.conSrv.confirm({

      target: event.target,
      message: 'Are you sure that you want to proceed?',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
     var del=    this.eventService.removeEntry(id).subscribe(x => {
          this.eventService.GetEventsTableLines(this.filterselected,this.filterselected1);
          this.eventService.getEventFOrCalender(this.filterselected,this.filterselected1);
          this.messageService.add({ severity: 'success', summary: 'Deleted successfully' });
        });
        this.sub.push(del)
        // this.messageService.add({ severity: 'info', summary: 'Confirmed', detail: 'You have accepted' });
      },
      reject: () => {
        //this.messageService.add({ severity: '', summary: 'Deleted successfully' });
      }
    });
  }
  preview(id) {
    this.route.navigate(['event', id])

  }
  openNew() {
    this.route.navigate(['/events/manage-event/', '0']);

  }
  getstatus(x,y) {

    return y ? 'Complete': x?  'EvntTblStatus001' : 'EvntTblStatus002';
  }
  ///////
  /// calender Events and methods
  ////////
  setDisablyView(e) {// to show view minu
   // console.log(e)
    this.op.toggle(e.nativeEvent);
  }
  onRowSelect(e, op: OverlayPanel) {// to change the view of calender
    this.inialVew = e.data.id;
    this.cal.getApi().changeView(this.inialVew, Date.now());
    op.hide();
  }
  handleEventClick(clickInfo) {
    this.route.navigate(['/events/manage-event/', clickInfo]);
    //  this.router.navigate([{ outlets: { 'CalenderOutlet': ['single-update',clickInfo.event.id ]} }], { relativeTo: this.ActivatedRout.parent });
  }
  // handleEventMouseEnter(e) {

  //   //         this.eventPrv = this.allEventForPrev.find(x => x.id == e.event._def.publicId);

  //   //    //     console.log(e)
  //   //         this.op1.show(e.jsEvent, e.el);
  // }
  handleEventMouseEnter1(id, el, event) {
    if (this.op1.render) {
      this.op1.hide();
      //         this.eventPrv = this.allEventForPrev.find(x => x.id == id);
      //         this.op1.baseZIndex = 9999;

      //    //     console.log(e)
      //         this.op1.show(event);
    }
    else {
      this.eventPrv = this.allEventForPrev.find(x => x.id == id);
      this.op1.baseZIndex = 9999;
      //     console.log(e)
      this.op1.show(event);
    }
  }
  // handleEventMouseLeave(e) {
  //   //    console.log("aaaaa",e)
  //   //     this.op1.hide();
  //   //     console.log('leave')
  // }

  getDateOntomeZone(d: Date) {
    var t = new Date(Date.now()).getTimezoneOffset()
    var MyDate = new Date(d).setMinutes(new Date(d).getMinutes() - (new Date(Date.now()).getTimezoneOffset()));
    return new Date(MyDate);
  }
  getDateOntomeZoneformat(d,x){
    m.locale()
    let t = new Date(Date.now()).getTimezoneOffset();
    let MyDate = new Date(d).setMinutes(new Date(d).getMinutes() - (t));
    var l="";
    switch(x){
      case 'full':if(new Date(MyDate).getFullYear()==new Date(Date.now()).getFullYear()){
        l= m(new Date(MyDate)).format('ddd MMMM D hh:mm a')
        //this.datePipe.transform(new Date(MyDate), 'EEE MMMM d hh:mm a');

    }else{
       l= m(new Date(MyDate)).format( 'ddd MMMM D,y hh:mm a');
       //this.datePipe.transform(new Date(MyDate), 'EEE MMMM d,y hh:mm a');

    }
    break;
    case 'month': l=m(new Date(MyDate)).format('MMM');
    //this.datePipe.transform(new Date(MyDate), 'MMM');
    break;
    case 'day':l=m(new Date(MyDate)).format('D')
    //this.datePipe.transform(new Date(MyDate), 'dd');
    break
    }
    return l




   // return new Date(MyDate).toLocaleString();
}
}
