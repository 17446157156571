<p-fileUpload (onSelect)="onSelect($event)" (uploadHandler)="onUpload($event)" [customUpload]="true"
[showUploadButton]="false" accept="image/*" cancelIcon="pi pi-times" cancelLabel="c"
chooseIcon="pi pi-plus" chooseLabel="a" maxFileSize="1000000000" multiple="multiple" name="demo[]"
uploadIcon="pi pi-upload" uploadLabel="b"  *ngIf="croppedImage==''" >
</p-fileUpload>
<div class="img-container"  *ngIf="croppedImage!=''">
    <img [src]="croppedImage"  class="img-fluid"   />
    <div class="overlay ">
        <button pButton pRipple type="button" icon="pi pi-trash" class="p-button-rounded p-button-secondary bbbb" (click)="deletImg()"></button>
        <!-- <button pButton pRipple type="button" icon="pi pi-trash" class="p-button-rounded p-button-text "></button> -->
        <!-- <button pButton pRipple type="button" icon="pi pi-trash" class="p-button-rounded p-button-outlined"></button> -->
     </div>
</div>



