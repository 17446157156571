import { DOCUMENT } from '@angular/common';
import { Component, OnInit, Inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AddOnsStepsService } from 'src/app/services/add-ons-steps.service';

@Component({
    selector: 'app-addon-settings',
    templateUrl: './addon-settings.component.html',
    styleUrls: ['./addon-settings.component.scss']
})
export class AddonSettingsComponent implements OnInit {
    submitted: boolean = false;
    visibilty = [
        { name: 'Hidden', code: true },
        { name: 'Visible', code: false },
    ]
    constructor(private router: Router, private translate: TranslateService, private activatedRoute: ActivatedRoute, public srv: AddOnsStepsService, @Inject(DOCUMENT) public document: Document) {

        this.translate.onLangChange.subscribe(() => {
            this.updateTranslateio();
        });
    }
    ngOnInit(): void {
        this.updateTranslateio();
    }

    updateTranslateio() {
        this.visibilty = [
            { name: this.translate.instant('AddOnFrmStngsTitl002SlctChois001'), code: true },
            { name: this.translate.instant('AddOnFrmStngsTitl002SlctChois002'), code: false },
        ];
    }
    cancel() {
        this.srv.clear();
        this.router.navigate(['Add-ons'], { relativeTo: this.activatedRoute.parent.parent.parent });
    }
    submit() {
        if (this.validateAddOnsSetting()) {
            this.srv.finish();

            this.router.navigate(['Add-ons'], { relativeTo: this.activatedRoute.parent.parent.parent });

        } else {
            this.submitted = true;
        }

    }
    previous() {
        this.router.navigate(['sales-date'], { relativeTo: this.activatedRoute.parent });
    }

    validateAddOnsSetting() {
        return this.srv.AddonsForm.controls['maxPerOrder'].valid && this.srv.AddonsForm.controls['minPerOrder'].valid
            && this.srv.AddonsForm.controls['hidden'].valid && this.srv.AddonsForm.valid;
    }

}
