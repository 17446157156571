import { Injectable } from '@angular/core';
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor,
    HttpEventType,
    HttpResponse
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, switchMap, tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { AuthService } from './auth.service';
 export interface refTok{
    token:string;
    refrToken:string;
 }

@Injectable()
export class CommonInterceptor implements HttpInterceptor {

    constructor(private router: Router,private auth:AuthService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<unknown>> {



        var l=localStorage.getItem('refreah');
         // console.log(l)
          if(l==null){
            l="null";
          }  
          var local=localStorage.getItem('loclizetion')
          if(local==null){
            local='en'
          }
          var tok=localStorage.getItem('user')
    
      
        const modifiedRequest = request.clone({
            headers: request.headers.append('Content-Type', 'application/json')
                .append('Accept', 'application/json')
                .append('Authorization', 'Bearer ' + localStorage.getItem('user'))
                .append('X-Refresh-Token',l)
                .append('X-Culture-ID',local),
             
                
        }

        )

    return     next.handle(modifiedRequest).pipe(
            tap(event => {
                if (event.type === HttpEventType.Response) {
                    const response = event as HttpResponse<any>;
                    const refreshToken = response.headers.get("X-Refresh-Token");
                    const accessToken = response.headers.get("Authorization");
                    if (refreshToken!=null && refreshToken != l) {
                        localStorage.setItem('refreah', refreshToken);

                    }
                    if (accessToken!=null && accessToken != tok) {
                        localStorage.setItem('user', accessToken);
                    }
                }
            }
            // ,
            //  error => {
            //     if (error.status === 401) {

                  
            //        return this.handlerRefreshToken(request,next)
            //        //this.router.navigate(['notallowed']); 
            //     }
            // }
        ),
        catchError(error => {
          if (error.status === 401) {
            // Handle 401 Unauthorized response
            return this.handlerRefreshToken(request,next)
          } else {
            // Handle other errors
            return throwError(error);
          }
        })
        );
             

           

    }
    handlerRefreshToken( request: HttpRequest<any>, next: HttpHandler):Observable<HttpEvent<any>>{
        var token= localStorage.getItem('user')
        var refreshToken=localStorage.getItem('refreah');
       return  this.auth.rexfreachToken(token,refreshToken).pipe(
         switchMap((data:refTok)=>{
            localStorage.setItem('user', data.token);
            localStorage.setItem('refreah', data.refrToken);
            var modifiedRequest=this.handleAuth(request, data);
            return next.handle(modifiedRequest )
         }),
         catchError(error=>{
          //  console.log(error)
          this.auth.logout();
          return throwError('Please log in again. Your session has expired.') as Observable<HttpEvent<any>>;
           
         }) 
       ) 
       //.subscribe(x=>x) 
        }
     handleAuth(request: HttpRequest<any>,data:refTok):HttpRequest<any>{

      //   var l=localStorage.getItem('refreah');
      // //  console.log(l)
      //   if(l==null){
      //     l="null";
      //   }  
        var tok=localStorage.getItem('user')
        const modifiedRequest = request.clone({
            headers: request.headers.append('Content-Type', 'application/json')
                .append('Accept', 'application/json')
                .append('Authorization', 'Bearer ' + data.token)
                .append('X-Refresh-Token',data.refrToken)
                .append('X-Culture-ID',"en"),
             
                
        });
        return modifiedRequest; 
     }
}
