<div class="container">
    <div class="card custom-table-border">
        <div class="card-header border-0 text-center bg-primary rounded-top-23 ">
            <h1 class="fw-bold text-white mt-2">{{'PrmoTblTitle'|translate}}</h1>
        </div>
        <div *ngIf="loader.show1" @fadeOut class="d-flex justify-content-center align-items-center"
            style="border-radius: 25px;top : 0;position: absolute;z-index: 1050;background-color:#fff;width: 100%;height: -webkit-fill-available;right: 0;bottom: 0;left: 0;">


        </div>


        <p-toast></p-toast>

        <div class="">



            <p-table #dt responsiveLayout="stack" [breakpoint]="'991px'" [value]="data" [globalFilterFields]="['name']"
                [tableStyle]="{'min-width': '100%','max-width':'100%'}" [rowHover]="true" dataKey="id" [rows]="5"
                [paginator]="totalRecord>5" [lazy]="true" [totalRecords]="totalRecord" (onLazyLoad)="getEvent($event)"
                currentPageReportTemplate="{{'Showing'|translate}} {first} {{'to'|translate}} {last} {{'of'|translate}} {totalRecords} {{'entries'|translate}}"
                [showCurrentPageReport]="true" class="">
                <ng-template pTemplate="caption">

                    <div>
                        <div class="d-sm-flex d-block align-items-center justify-content-sm-between text-center">

                            <div class="  ms-md-3  mb-sm-0 mb-2">
                                <button pButton pRipple class="rounded-pill add-new-btn mr-2" (click)="show()"><i
                                        class="fa-solid fa-plus"></i> {{'PrmoTblBtn'|translate}}<span></span></button>
                            </div>
                            <div class="p-input-filled me-md-3 ">
                                <span class="p-input-icon-left">
                                    <i class="pi pi-search"></i>
                                    <input pInputText type="text" class="form-control"
                                        (input)="dt.filterGlobal($event.target.value, 'contains')"
                                        placeholder="{{'Search...'|translate}}" />
                                </span>
                            </div>
                        </div>
                    </div>

                </ng-template>
                <ng-template pTemplate="header" class="">
                    <tr>

                        <th  style="min-width:15rem" class="fw-bold text-center">
                            {{'PrmoTblTh001'|translate}} </th>
                        <th  style="min-width:10rem" class="fw-bold text-center">
                            {{'PrmoTblTh002'|translate}} </th>
                        <th  style="min-width:10rem" class="fw-bold text-center">
                            {{'PrmoTblTh003'|translate}} </th>
                        <th class="fw-bold text-center"> {{'PrmoTblTh004'|translate}}</th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-Code>
                    <tr>
                        <td class="text-md-center padding-custom text-lenght-larg text-end  "><span
                                class="headers-cutom text-start fw-bold ">{{'PrmoTblTh001'|translate}}</span><span
                                class="text-lenght">{{Code.name}}</span></td>
                        <td class="text-md-center padding-custom text-end"><span
                                class="headers-cutom text-start fw-bold ">{{'PrmoTblTh002'|translate}}</span>
                            {{Code.count}}/{{Code.limit}}</td>
                        <td class="text-md-center padding-custom text-end"><span
                                class="headers-cutom text-start fw-bold ">{{'PrmoTblTh003'|translate}}</span>
                            {{Code.discountAmount }} {{Code.isPercentage?'%':EventSrv.currancysymbole}}</td>

                        <td class="text-md-center padding-custom ">
                            <div [class.action-custom]="document.documentElement.dir === 'ltr'"
                            [class.action-custom-rtl]="document.documentElement.dir === 'rtl'">
                                <button pButton pRipple icon="pi pi-pencil "
                                    class="p-button-rounded p-button-primary mx-2" (click)="edit(Code)"></button>
                                <button pButton pRipple icon="pi pi-trash" class="p-button-rounded p-button-danger"
                                    (click)="remove($event,Code)"></button>
                                <p-confirmPopup></p-confirmPopup>
                            </div>

                        </td>
                    </tr>
                </ng-template>
                <ng-template pTemplate="summary">
                    <div class="flex align-items-center justify-content-between">
                        {{'PrmoTblFooter01'|translate}} {{data ? data.length : 0 }} {{'PrmoTblFooter02'|translate}}
                    </div>
                </ng-template>
            </p-table>


        </div>

    </div>
</div>