<div class="error-area ptb-100">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="error-content">
                    <img src="assets/img/banner-shape7.png" alt="image">
                    <h3>{{'Go Check your email'|translate}}</h3>

                    <div class="btn-box">
                        <a routerLink="/login" class="rounded-pill save-btn"><i class="flaticon-home"></i>
                            {{'Login page'|translate}}<span></span></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>