import { Component, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DropDownItem } from 'src/app/Interfaces/DropDownItem';
import { AddOnGroupStepsService } from 'src/app/services/add-on-group-steps.service';
import { AddOnsService } from 'src/app/services/add-ons.service';
import { EventStepsService } from 'src/app/services/event-steps.service';

@Component({
  selector: 'app-varieties',
  templateUrl: './varieties.component.html',
  styleUrls: ['./varieties.component.scss']
})
export class VarietiesComponent implements OnInit {
 addOns:DropDownItem[]=[];
 submitted:boolean=false;

  constructor(private router:Router,private activatedRoute:ActivatedRoute
    ,private eventStepsSrv: EventStepsService,
  public Srv:AddOnGroupStepsService,private AddOnsSrv:AddOnsService){


  }
  set(e){
  //  console.log(e)
   // console.log(this.Srv.AddonsGroupForm.value)
  }
  ngOnInit(): void {
    this.AddOnsSrv.getAddOnsWithoutGroup(this.eventStepsSrv.id).subscribe(x=>{ this.addOns=x;})

  }
    submit(){
      if(this.validateGroupAddOns()){
        this.Srv.Finish()
        this.router.navigate( ['Add-ons'] ,{relativeTo: this.activatedRoute.parent.parent.parent});
       
      }else{
        this.submitted=true;
      }

    }
    cancel(){
      this.Srv.clear();
      this.router.navigate( ['Add-ons'] ,{relativeTo: this.activatedRoute.parent.parent.parent});
    }
    previous(){
        this.router.navigate( ['group-info'] ,{relativeTo: this.activatedRoute.parent});
    }

    validateGroupAddOns(){
      return   this.Srv.AddonsGroupForm.controls['addOns'].valid;
     }
}
