import { ApplicationRef, Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { GMap } from 'primeng/gmap';
import { Subscription } from 'rxjs';
import { DropDownItem } from 'src/app/Interfaces/DropDownItem';
import { AddressService } from 'src/app/services/address.service';
import { LoaderService } from 'src/app/services/loader.service';
import { GeocodingService } from '../map/geocoding.service';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService } from 'primeng/api';
export interface UserAddreesDto{
    address?:string;
    secondAddress?:string;
    country?:string;
    state?:string;
    city?:string;
    lat?:number;
    lng?:number;
    zip?:number;
}

@Component({
  selector: 'app-location-sertch',
  templateUrl: './location-sertch.component.html',
  styleUrls: ['./location-sertch.component.scss']
})
export class LocationSertchComponent {
  addressForm:UserAddreesDto={} ;
  visible: boolean = false;
  options: any;
  overlays: any[];
  address = '';
  @Input() addressinput: string;
  @Output() setAddress: EventEmitter<UserAddreesDto> = new EventEmitter();
  @ViewChild('gmap') gmapElement1: GMap;
  @ViewChild('gmap') gmapElement: any;
  map: google.maps.Map;
  ssss;
  submitted: boolean;
  mapProblem: boolean = false;
  sub: Subscription[]=[];
    marker: any;
  constructor(public loader: LoaderService,
      private AddressSrv: AddressService,
      private geo: GeocodingService,
      private fb: FormBuilder,
      private http: HttpClient,
      private router: Router,
      private activatedRoute: ActivatedRoute,
      private ApplicationRef1: ApplicationRef,
      // private EventSrv: EventStepsService,
      private messageService: MessageService,
  ) { }

  ngOnDestroy(): void {
      this.sub.forEach(e=>{
         e.unsubscribe();
      })
     }
  getProblem(e){
      this.mapProblem = e;
  }
 
  ngOnInit(): void {
      this.loader.showLoader1();
  }

  handleMapClick(event) {
      // event: MouseEvent of Google Maps api
     // console.log(event);
      this.options = {
          center: { lat: 36.890257, lng: 30.707417 },
          zoom: 5
      };
  }
  handleOverlayClick(event) {
  }
  showMap(value) {
    const mapProp = {
      center: new google.maps.LatLng(value.geometry.location.lat(), value.geometry.location.lng()),
      zoom: 15,
      mapTypeId: google.maps.MapTypeId.ROADMAP
    };
    // Set center
    this.map = new google.maps.Map(this.gmapElement.nativeElement, mapProp);
    // Take marker
    this.marker = new google.maps.Marker({
      position: new google.maps.LatLng(value.geometry.location.lat(), value.geometry.location.lng()),
      title: value.formatted_address,
      draggable: true
    });
    // Set marker
    this.marker.setMap(this.map);
    // Add event listener for marker dragend event
    google.maps.event.addListener(this.marker, 'dragend', (event) => {
      this.getAddress(event.latLng);
    });
    // Get initial address
    this.getAddress(this.marker.getPosition());
  }
  
  getAddress(latLng) {
    const geocoder = new google.maps.Geocoder();
    geocoder.geocode({ location: latLng }, (results, status) => {
      if (status === 'OK') {
        if (results[0]) {
          console.log(results[0]);
          this.emitaddress(results[0])
          // Here you can access the full address component and use it as needed
        } else {
          console.log('No results found');
        }
      } else {
        console.log('Geocoder failed due to: ' + status);
      }
    });
  }

  handleDragEnd(event) {
  }



  getAddress2(x) {
      this.visible = true;
        console.log(x)
          this.showMap(x);
      this.ApplicationRef1.tick()
  }

  emitaddress(x){

    var subpremise=x.address_components.find(y => y.types.includes('subpremise'))?.long_name;
    var premise=x.address_components.find(y => y.types.includes('premise'))?.long_name;
    var plus_code=x.address_components.find(y => y.types.includes('plus_code'))?.long_name;
    var street_number=x.address_components.find(y => y.types.includes('street_number'))?.long_name;
    var route=x.address_components.find(y => y.types.includes('route'))?.long_name;
    var neighborhood=x.address_components.find(y => y.types.includes('neighborhood'))?.long_name;
    var sublocality_level_1=x.address_components.find(y => y.types.includes('sublocality_level_1'))?.long_name;
    var sublocality_level_2=x.address_components.find(y => y.types.includes('sublocality_level_2'))?.long_name;
    var sublocality_level_3=x.address_components.find(y => y.types.includes('sublocality_level_3'))?.long_name;
    var sublocality_level_4=x.address_components.find(y => y.types.includes('sublocality_level_4'))?.long_name;
    var  locality=x.address_components.find(y => y.types.includes('locality'))?.long_name;
    var administrative_area_level_2=x.address_components.find(y => y.types.includes('administrative_area_level_2'))?.long_name;
    var administrative_area_level_1=x.address_components.find(y => y.types.includes('administrative_area_level_1'))?.long_name;
    var administrative_area_level_3=x.address_components.find(y => y.types.includes('administrative_area_level_3'))?.long_name;
    var administrative_area_level_4=x.address_components.find(y => y.types.includes('administrative_area_level_4'))?.long_name;
    this.addressForm['address']=(x.formatted_address);
    this.addressForm['secondAddress']=this.getAddressTow(subpremise,premise, plus_code,street_number,route,
         neighborhood,sublocality_level_1,sublocality_level_2,sublocality_level_3,sublocality_level_4, locality)
    
    // this.AddressSrv['SecondAddress']=x.address_components.find(y => y.types.includes('subpremise'))==undefined?
    this.addressForm['city']=this.getcityTow(neighborhood,sublocality_level_1,sublocality_level_2,sublocality_level_3,sublocality_level_4, locality)
    this.addressForm['country']=(x.address_components.find(y => y.types.includes('country'))?.long_name);
    this.addressForm['state']=this.getstate(administrative_area_level_3,administrative_area_level_4,administrative_area_level_2,administrative_area_level_1)
    this.addressForm['zip']=(x.address_components.find(y => y.types.includes('postal_code'))?.long_name==undefined?'0':x.address_components.find(y => y.types.includes('postal_code'))?.long_name);
    this.addressForm['lat']=(x.geometry.location.lat());
    this.addressForm['lng']=(x.geometry.location.lng());
    console.log(this.addressForm);
    this.setAddress.emit(this.addressForm);
    
  }

  getAddressTow(subpremise,premise,plus_code,street_number,route,neighborhood,sublocality1,sublocality2,sublocality3,sublocality4,locality){
    var s="";
    if(subpremise!=undefined){
        s=s==""?subpremise:s+","+subpremise;
    }
    if(premise!=undefined){
        s=s==""?premise:s+","+premise;
    }
    if(plus_code!=undefined){
        s=s==""?plus_code: s+","+plus_code;
    }
    if(street_number!=undefined){
        s=s==""?street_number:s+","+street_number;
    }
    if(route!=undefined){
        s=s==""?route:s+","+route;
    }
    if(sublocality1!=undefined){
        s=s==""?sublocality1:s+","+sublocality1;
    }
    if(sublocality2!=undefined){
        s=s==""?sublocality2:s+","+sublocality2;
    }
    if(sublocality3!=undefined){
        s=s==""?sublocality3:s+","+sublocality3;
    }
    if(sublocality4!=undefined){
        s=s==""?sublocality4:s+","+sublocality4;
    }
    if(neighborhood!=undefined){
        s=s==""?neighborhood:s+","+neighborhood;
    }
    if(locality!=undefined){
        s=s==""?locality:s+","+locality;
    }
    return s;
    

  }
  getcityTow(neighborhood,locality ,administrative3,administrative4,administrative2,administrative1){
  var s="";
   
    if(locality!=undefined){
        s=locality;
        return s
    }
    if(administrative4!=undefined){
        s=administrative4;
        return s
    }
    if(administrative3!=undefined){
        s=administrative3;
        return s
    }
    if(administrative2!=undefined){
        s=administrative2;
        return s
    }
    if(administrative1!=undefined){
        s=administrative1;
         return s
    }
  }
  getstate(administrative3,administrative4,administrative2,administrative1){
    var s="";
    if(administrative1!=undefined){
        s=administrative1;
         return s
    }
    if(administrative2!=undefined){
        s=administrative2;
        return s
    }
    if(administrative3!=undefined){
        s=administrative3;
        return s
    }
    if(administrative4!=undefined){
        s=administrative4;
        return s
    }
   
  
    
  }
}
