<form [formGroup]="surveyForm">
    <div class="container">
        <div class="row">
            <div class="col-12 d-flex justify-content-between align-items-center">
                <h4>Survey</h4>
                <button class="btn btn-danger rounded-circle"><i class="fa-solid fa-xmark"></i></button>
            </div>
            <div class="col-12">
                <div class="p-input-filled col-12 p-0 my-3">
                    <span class="p-float-label">
                        <input type="text" pTooltip="Enter survey title" tooltipPosition="top"
                            pInputText class="form-control" [class.ng-invalid.ng-dirty]="" #surveyTitle />
                        <label class="form-lable font-size-14" for="surveyTitle">Survey title</label>
                    </span>
                </div>

                <div class="p-input-filled col-12 p-0 my-3">
                    <span class="p-float-label">
                        <textarea #evTitle rows="1" type="text"pTooltip="Type your opinion"
                            tooltipPosition="bottom" id="yourOpinion" pInputText class="form-control"
                            [class.ng-invalid.ng-dirty]=""></textarea>
                        <label class="font-size-14" for="yourOpinion">Type your opinion</label>
                    </span>
                </div>

                <div class="row mb-1">
                    <div class="col-12 pb-0">
                        <h5>Questions</h5>
                    </div>
                    <div class="col-md-8 col-8">
                        <span>
                            Do you want to asdkjasjdjasdjk sajkdjkasd
                        </span>
                    </div>
                    <div class="col-md-4 col-4 text-end">
                        <div class="square-switch">
                            <p-inputSwitch name="isShown"></p-inputSwitch>
                        </div>
                    </div>
                    <div class="col-md-8 col-8">
                        <span>
                            Do you want to asdkjasjdjasdjk sajkdjkasd
                        </span>
                    </div>
                    <div class="col-md-4 col-4 text-end">
                        <div class="square-switch">
                            <p-inputSwitch name="isShown"></p-inputSwitch>
                        </div>
                    </div>
                    <div class="col-md-8 col-8">
                        <span>
                            Do you want to asdkjasjdjasdjk sajkdjkasd
                        </span>
                    </div>
                    <div class="col-md-4 col-4 text-end">
                        <div class="square-switch">
                            <p-inputSwitch name="isShown"></p-inputSwitch>
                        </div>
                    </div>
                    <div class="col-md-8 col-8">
                        <span>
                            Do you want to asdkjasjdjasdjk sajkdjkasd
                        </span>
                    </div>
                    <div class="col-md-4 col-4 text-end">
                        <div class="square-switch">
                            <p-inputSwitch name="isShown"></p-inputSwitch>
                        </div>
                    </div>
                </div>

                <div class="col-12 p-0 my-2">
                    <h5 class="">Is Your Choice is blabla</h5>
                    <div class="row">
                        <div class="col-12  ">
                            <div class="border-custom p-2">


                            <input type="radio" class="btn-check " name="orderFormType" id="buyer"
                                name="orderFormType" [value]="true" checked />
                            <label class="btn btn-outline-primary me-2  my-2" for="buyer">Buyer only</label>
                            <input type="radio" class="btn-check" name="orderFormType" id="attendee"
                                name="orderFormType" [value]="false" />
                            <label class="btn btn-outline-primary me-2  my-2" for="attendee">Esad</label>

                            <input type="radio" class="btn-check" name="orderFormType" id="select3"
                                name="orderFormType" [value]="false" />
                            <label class="btn btn-outline-primary me-2  my-2" for="select3">Easdsad</label>

                            <input type="radio" class="btn-check" name="orderFormType" id="select4"
                                name="orderFormType" [value]="false" />
                            <label class="btn btn-outline-primary me-2  my-2" for="select4">sadsadsad </label>

                            <input type="radio" class="btn-check" name="orderFormType" id="select5"
                                name="orderFormType" [value]="false" />
                            <label class="btn btn-outline-primary me-2  my-2" for="select5">The quick brown fox jumps
                                over the lazy dog, showcasing its agility and speed in a classic English
                                pangram.</label>

                            <input type="radio" class="btn-check" name="orderFormType" id="select6"
                                name="orderFormType" [value]="false" />
                            <label class="btn btn-outline-primary me-2  my-2" for="select6">Eadsadas</label>

                            <input type="radio" class="btn-check" name="orderFormType" id="select7"
                                name="orderFormType" [value]="false" />
                            <label class="btn btn-outline-primary me-2  my-2" for="select7">Easdsadect3</label>
                        </div>
                        </div>
                    </div>
                </div>
                <div class="col-12 p-0 my-3">
                <div class="p-input-filled  ">
                    <p-dropdown class="sorting-custom" [styleClass]="'form-control '" [options]="QezSelect"
                       placeholder="Default" [autoDisplayFirst]="true" optionLabel="type"
                        [showClear]="true"></p-dropdown>
                </div>
            </div>
                <div class=" row my-3  text-start">
                    <div class="square-switch">
                        <p-inputSwitch class="pe-2" name="acceptTerms" #acceptTerms></p-inputSwitch>
                        <label for="acceptTerms">
                            Do you want to asdkjasjdjasdjk sajkdjkasd
                        </label>
                    </div>

                </div>
                <div class="card border-custom">
                    <div class="card-title ps-3 pt-3">
                        <span class="fw-bold">Terms</span>
                    </div>
                    <div class="card-body">
                        <span>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Rerum inventore illum quaerat, eaque atque excepturi sunt sit placeat quam rem nisi doloribus dicta expedita, impedit amet saepe obcaecati cupiditate quos voluptatibus id, enim voluptas esse? Tempore ipsa quibusdam explicabo! Quam commodi ducimus ex temporibus sequi vel error, ullam harum fugit.</span>
                    </div>

                </div>

            </div>

        </div>
    </div>
</form>
