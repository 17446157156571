<form [formGroup]="prosrvform.promoteCodeForm" (submit)="submit()" autocomplete="off">
    <div class="row mb-2">
        <div class="row p-0 m-0">
            <div class="p-input-filled col-sm-6 col-md-6 mb-2">
                <span class="p-float-label">
                    <p-dropdown pTooltip="{{'PrmoFrmStp3Slct001Pop'|translate}}" showDelay="1000"
                        tooltipPosition="bottom" inputId="ticketLimit" [options]="ticketLimit" [styleClass]="'w-100'"
                        (onChange)="removeValid()" formControlName="limit" [autoDisplayFirst]="false" optionLabel="name"
                        optionValue="id" appendTo="body"
                        [ngClass]="{'ng-invalid ng-dirty':prosrvform.promoteCodeForm.get('limit')?.touched && prosrvform.promoteCodeForm.get('limit')?.invalid ||(prosrvform.promoteCodeForm.get('limit').invalid&&Submitted)  }"></p-dropdown>
                    <label class="form-label font-size-14" for="ticketLimit">{{'PrmoFrmStp3Slct001'|translate}}</label>
                </span>
                <small *ngIf="prosrvform.promoteCodeForm.get('limit')?.touched && prosrvform.promoteCodeForm.get('limit')?.invalid
                ||(prosrvform.promoteCodeForm.get('limit').invalid&&Submitted)  " class="p-error"
                    text="">{{'PrmoFrmStp3Slct001ErrMsg'|translate}} </small>

            </div>

            <div class="p-input-filled col-sm-6 col-md-6  mb-2"
                *ngIf="prosrvform.promoteCodeForm.get('limit').value == '1' ">
                <span class="p-float-label">
                    <p-inputNumber pTooltip="{{'PrmoFrmStp3Slct001ErrMsg'|translate}} " showDelay="1000"
                        tooltipPosition="bottom" inputId="quantity" [useGrouping]="false" [styleClass]="'w-100'"
                        class="form-control m-0 p-0" [inputStyleClass]="'form-control'" formControlName="quantity"
                        [ngClass]="{
                        'ng-invalid ng-dirty':
                            prosrvform.promoteCodeForm.get('quantity')?.touched &&
                            prosrvform.promoteCodeForm.get('quantity')?.invalid||(prosrvform.promoteCodeForm.get('quantity').invalid&&Submitted)
                    }">
                    </p-inputNumber>
                    <label class="form-label font-size-14"
                        for="quantity">{{'PrmoFrmStp3Slct001ErrMsg'|translate}}</label>
                </span>
                <small *ngIf="prosrvform.promoteCodeForm.get('quantity')?.touched && prosrvform.promoteCodeForm.get('quantity')?.invalid
                 && !prosrvform.promoteCodeForm.get('quantity')?.errors?.['min']  ||(prosrvform.promoteCodeForm.get('quantity').invalid&&Submitted
                  && !prosrvform.promoteCodeForm.get('quantity')?.errors?.['min'])  " class="p-error" text="">Please
                    add Quantity</small>
                <small *ngIf="prosrvform.promoteCodeForm.get('quantity')?.touched && prosrvform.promoteCodeForm.get('quantity')?.invalid &&
                prosrvform.promoteCodeForm.get('quantity')?.errors?.['min'] ||(prosrvform.promoteCodeForm.get('quantity').invalid&&Submitted&&
                 prosrvform.promoteCodeForm.get('quantity')?.errors?.['min'] )" class="p-error" text="">Please add
                    valid Quantity at least {{prosrvform.promoteCodeForm.get('quantity').errors?.['min']?.min}}</small>
            </div>
        </div>
        <div *ngIf="!prosrvform.isHidden" class="p-input-filled col-12 col-md-6 mb-2">
            <p-inputSwitch #isPercent name="isPercent" pTooltip="{{'PrmoFrmStp3Swth001Pop'|translate}}" showDelay="1000"
                tooltipPosition="bottom" binary="true" formControlName="isPercent" inputId="isPercent"
                (onChange)="checkValid($event)"></p-inputSwitch>
            <label class=" form-check-label b-5 ms-2" for="isPercent">{{'PrmoFrmStp3Swth001'|translate}} </label>
        </div>
        <div *ngIf="!prosrvform.isHidden" class="p-input-filled col-sm-12 col-md-6  mb-2">
            <div class="p-inputgroup">
                <span class="p-inputgroup-addon  "
                    [ngClass]="{' currency-custom':document.documentElement.dir === 'ltr',' currency-custom-rtl':document.documentElement.dir === 'rtl'}">
                    {{prosrvform.promoteCodeForm.get('isPercent').value?"%":eventStepSrv.currancysymbole}}</span>
                <span class="p-float-label">
                    <!-- <input type="text" pInputText  /> -->
                    <p-inputNumber pTooltip="{{'PrmoFrmStp3Inpt002Pop'|translate}}" showDelay="1000"
                        tooltipPosition="bottom" inputId="discountAmount" [useGrouping]="false" [styleClass]="'w-100'"
                        class="w-100" [inputStyleClass]="'form-control'" formControlName="discountAmount" [ngClass]="{
                            'ng-invalid ng-dirty':
                                prosrvform.promoteCodeForm.get('discountAmount')?.touched &&
                                prosrvform.promoteCodeForm.get('discountAmount')?.invalid||(prosrvform.promoteCodeForm.get('discountAmount').invalid&&Submitted)
                        }">
                    </p-inputNumber>
                    <label class="form-label font-size-14" for="withoutgrouping">{{
                        prosrvform.promoteCodeForm.get('isPercent').value
                        ? ('PrmoFrmStp3Inpt002Titl002'|translate)
                        : 'PrmoFrmStp3Inpt002Titl001'
                        |translate}}</label>
                </span>
            </div>
            <small
                *ngIf="prosrvform.promoteCodeForm.get('discountAmount')?.touched && prosrvform.promoteCodeForm.get('discountAmount')?.invalid ||(prosrvform.promoteCodeForm.get('discountAmount').invalid&&Submitted) "
                class="p-error" text="">Please add Valid value</small>
        </div>
        <div class="p-input-filled col-sm-12 col-md-6 mb-2">
            <span class="p-float-label">
                <p-calendar dataType="string" #op1 pTooltip="{{'PrmoFrmStp3Inpt003Pop'|translate}}" showDelay="1000"
                    tooltipPosition="bottom" appendTo="body" [stepMinute]="1" [hourFormat]="12" [showTime]="true"
                    [showSeconds]="false" [minDate]="prosrvform.minDateValue" [maxDate]="MaxDate" inputId="time"
                    [styleClass]="'form-control m-0 p-0'" [inputStyleClass]="'form-control'" formControlName="startDate"
                    (onSelect)="prosrvform.confirmdate($event)"
                    [ngClass]="{'ng-invalid ng-dirty':prosrvform.promoteCodeForm.get('startDate')?.touched && prosrvform.promoteCodeForm.get('startDate')?.invalid ||(prosrvform.promoteCodeForm.get('startDate').invalid&&Submitted)}">
                     <ng-template pTemplate="date" let-date>
                        <span [ngStyle]="{color:getDateBoolean(date) ? 'red' : 'inherit'}">{{date.day}}</span>
                        </ng-template>
                        <ng-template pTemplate="footer">
                            <p class="clock-positon"> <i class="fa-solid fa-clock"></i> </p>
                            <!-- <p class="" style="padding-left: 1.1rem;position:relative;translate: 0 -80px;"> <i style="font-size: 1.1rem" class="fa-solid fa-clock"></i> </p> -->
                        </ng-template>

                </p-calendar>
                <label class="form-label font-size-14" for="salesStart">{{'PrmoFrmStp3Inpt003'|translate}}</label>
            </span>
            <small *ngIf="prosrvform.promoteCodeForm.get('startDate')?.touched && prosrvform.promoteCodeForm.get('startDate')?.invalid
             ||(prosrvform.promoteCodeForm.get('startDate').invalid&&Submitted)" class="p-error"
                text="">{{'PrmoFrmStp3Inpt003ErrMsg'|translate}}</small>
        </div>
        <div class="p-input-filled col-sm-12 col-md-6 mb-2">
            <span class="p-float-label">
                <p-calendar #op dataType="string" pTooltip="{{'PrmoFrmStp3Inpt004Pop'|translate}}" showDelay="1000"
                    tooltipPosition="bottom" [showTime]="true" [stepMinute]="1" [hourFormat]="12" [maxDate]="MaxDate"
                    [showSeconds]="false" [minDate]="prosrvform.maxDateValue" inputId="time"
                    [styleClass]="'form-control m-0 p-0'" [inputStyleClass]="'form-control'" formControlName="endDate"
                    appendTo="body"
                    [ngClass]="{'ng-invalid ng-dirty':prosrvform.promoteCodeForm.get('endDate')?.touched && prosrvform.promoteCodeForm.get('endDate')?.invalid ||(prosrvform.promoteCodeForm.get('endDate').invalid&&Submitted)}">
                    <ng-template pTemplate="date" let-date>
                        <span [ngStyle]="{color:getDateBoolean(date) ? 'red' : 'inherit'}">{{date.day}}</span>
                    </ng-template>
                    <ng-template pTemplate="footer">
                        <p class="clock-positon"> <i class="fa-solid fa-clock"></i> </p>
                        <!-- <p class="" style="padding-left: 1.1rem;position:relative;translate: 0 -80px;"> <i style="font-size: 1.1rem" class="fa-solid fa-clock"></i> </p> -->
                    </ng-template>

                </p-calendar>
                <label class="form-label font-size-14" for="salesEnd">{{'PrmoFrmStp3Inpt004'|translate}}</label>
            </span>
            <small *ngIf="prosrvform.promoteCodeForm.get('endDate')?.touched && prosrvform.promoteCodeForm.get('endDate')?.invalid
            ||(prosrvform.promoteCodeForm.get('endDate').invalid&&Submitted)" class="p-error"
                text="">{{'PrmoFrmStp3Inpt004ErrMsg'|translate}}</small>
        </div>



    </div>

    <div class="col-12 d-flex  justify-content-center">
        <div class="banner-btn rounded-pill mx-1">
            <button pTooltip="Go back for previous step" showDelay="1000" tooltipPosition="left" type="button"
                class="rounded-pill cancel-btn" (click)="previous()"><i class="pi mt-1"
                    [class.pi-angle-right]="document.documentElement.dir === 'rtl'"
                    [class.pi-angle-left]="document.documentElement.dir === 'ltr'"></i>
                {{'PrviosBtn'|translate}} <span></span></button>
            <button pTooltip="Cancel and dismiss changes" showDelay="1000" tooltipPosition="bottom" type="button"
                class="rounded-pill save-btn ms-1" (click)="cancel()">{{'CanclBtn'|translate}} <span></span></button>
            <button pTooltip="Save and generate" showDelay="1000" tooltipPosition="right" type="submit"
                class="rounded-pill save-btn  ms-1 mt-md-0 mt-1">{{'FnshBtn'|translate}} <span></span></button>


        </div>
    </div>

</form>
