import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, NavigationEnd, NavigationStart, Router} from '@angular/router';
import {LocationStrategy, PathLocationStrategy} from '@angular/common';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-add-ons-step',
  templateUrl: './add-ons-step.component.html',
  styleUrls: ['./add-ons-step.component.scss']

})
export class AddOnsStepComponent implements OnInit, OnDestroy {
    sub: Subscription;
  constructor(private router:Router,private activedRoute:ActivatedRoute){

  }

    ngOnDestroy(): void {

    }
  ngOnInit(): void {


      // this.router.navigate([], { relativeTo: this.activedRoute });
  }



}
