import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class LoaderService {
  show :boolean = false;
  show1 :boolean = false;
  showLoader(){
        this.show = true;
  }
  hideLoader(){

      this.show = false;


  }
  showLoader1(){
    this.show1 = true;
}
hideLoader1(){

  this.show1 = false;


}
  constructor(private http:HttpClient) { }

  removeEntry(id:string){
    return this.http.delete(environment.Api + 'd/e/remove/'+id)
  }
}

