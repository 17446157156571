import {Component, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {FormArray, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {DynamicDialogConfig, DynamicDialogRef} from 'primeng/dynamicdialog';
import {Question} from '../../../../../../../Interfaces/question';
import { TranslateService } from '@ngx-translate/core';
@Component({
    selector: 'app-question-form',
    templateUrl: './question-form.component.html',
    styleUrls: ['./question-form.component.scss']
})

export class QuestionFormComponent implements OnInit{
    questionForm: FormGroup;
    edit = false;
    optionForms: any;
    submitted = false;
    inputTypes = [
        {name: 'Short text', code: 'short'},
        {name: 'Paragraph', code: 'paragraph'},
        {name: 'Multiple choice (multiselect)', code: 'checkbox'},
        {name: 'Single Choice (Radio)', code: 'radio'},
        {name: 'Select from dropdown (Dropdown)', code: 'select'},
        {name: 'Additional terms & Conditions', code: 'terms'},
    ];
    get hasOptions(): any{
        return this.questionForm.get('type').value === 'checkbox'
            || this.questionForm.get('type').value === 'radio'
            || this.questionForm.get('type').value === 'select';
    }

    get isTerm(): any{
        return this.questionForm.get('type').value === 'terms';
    }

    constructor(private fb: FormBuilder, public ref: DynamicDialogRef,public config: DynamicDialogConfig ,private translate:TranslateService) {
        this.translate.onLangChange.subscribe(() => {
            this.updateTranslations();
          });
    }
    updateTranslations() {
        this.inputTypes = [
            {name: this.translate.instant('QstionFrmSlct001Chois001'), code: 'short'},
            {name: this.translate.instant('QstionFrmSlct001Chois002'), code: 'paragraph'},
            {name: this.translate.instant('QstionFrmSlct001Chois003'), code: 'checkbox'},
            {name: this.translate.instant('QstionFrmSlct001Chois004'), code: 'radio'},
            {name: this.translate.instant('QstionFrmSlct001Chois005'), code: 'select'},
            {name: this.translate.instant('QstionFrmSlct001Chois006'), code: 'terms'},
        ];
    }
    ngOnInit(): void {
     this.  updateTranslations();
        this.questionForm = this.fb.group({
            id: [],
            question: ['', [Validators.required]],
            type: ['', [Validators.required]],
            isShown: [true],
            isRequired: [false],
            answer: '',
            options: this.fb.array([], [Validators.minLength(2)])
        });

        this.questionForm.get('type').valueChanges.subscribe(x=>{
            if(this.hasOptions)
            {
                this.questionForm.get('options').setValidators([Validators.required,Validators.minLength(2)]);
            }
            else
            {
                this.questionForm.get('options').setValidators([]);
            }
            this.questionForm.get('options').updateValueAndValidity();
        })

        this.questionForm.get('type').valueChanges.subscribe(x=>{
            if(x == 'terms')
            {
                this.questionForm.get('answer').setValidators(Validators.required);
            }
            else
            {
                this.questionForm.get('answer').setValidators([]);
            }
            this.questionForm.get('answer').updateValueAndValidity();
        })



        if (this.config.data){

            this.questionForm = this.config.data;

            // console.log(this.config.data)
            // this.questionForm.patchValue(this.config.data);
            // console.log(this.questionForm.value);


            // console.log(this.config.data)
            // this.questionForm = this.fb.group( this.config.data);
            // console.log(this.config.data.options)
            // this.questionForm.controls['options'] = this.fb.array([this.config.data.options]);
            this.edit = true;
        }else{

        }
    }
    get optionsForms(){
        return this.questionForm.get('options') as FormArray;
    }
    addOption(x){
        const option = this.fb.group(
            {
                text: [x],
                value1: [x]
            }
        );

        option.get('text').valueChanges.subscribe(x=> option.get('value1').setValue(option.get('text').value))
        this.optionsForms.push(option);
    }
    deleteOption(i){
        this.optionsForms.removeAt(i);
    }
    onAddClicked(x) {
        if(this.questionForm.valid &&this.questionForm.get('options').valid ){

            // if( this.hasOptions() )
            // {
            //     let xx = this.questionForm.get('options').value

            // }
            this.ref.close( this.questionForm) ;
        }
        else
        {
            this.submitted= true;
        }
    }
}
