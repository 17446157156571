import { DOCUMENT } from '@angular/common';
import { Component, OnInit,Inject } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { DropDownItem } from 'src/app/Interfaces/DropDownItem';
import { AddOnsStepsService } from 'src/app/services/add-ons-steps.service';
import { AddOnsService } from 'src/app/services/add-ons.service';
import { EventStepsService } from 'src/app/services/event-steps.service';
import { EventsService } from 'src/app/services/events.service';

export interface CopyAddOns{
  eventId:string,
      groupId:string[]
}
@Component({
  selector: 'app-copy-add-ons-group',
  templateUrl: './copy-add-ons-group.component.html',
  styleUrls: ['./copy-add-ons-group.component.scss']
})
export class CopyAddOnsGroupComponent implements OnInit {
  groups:DropDownItem[]=[];
  allevents:DropDownItem[]=[]
  copyform!:FormGroup;
  submitted:boolean=false;
  init(){
    this.copyform=this.fb.group({
      eventId:[],
      groupId:[],
      non:[]

    })
  }
 constructor(private router: Router,
   private activatedRoute: ActivatedRoute,
    private eventStepsSrv: EventStepsService,
    private AddOnsSrv: AddOnsService,private fb:FormBuilder,private eventSrv:EventsService,
    @Inject(DOCUMENT) public document: Document){

    }
    getaddons(e){
    //  console.log(e);
      this.AddOnsSrv.getAddonsGroupForCopy(e.value).subscribe(x=>{
        this.groups=x;
   });
  }
  ngOnInit(): void {

  this.init();
  this.copyform.controls['eventId'].setValue(this.eventStepsSrv.id);

  this.eventSrv.getOrgEventAsDropdown().subscribe(x=>{
  //  console.log(x)
    this.allevents=x;
  })
  }
  submit(){
    var data = this.copyform.value as CopyAddOns ;
   //     console.log(data);
    this.AddOnsSrv.postCopyAddons(data).subscribe(x=>{
      this.AddOnsSrv.getallData(data.eventId);
      this.router.navigate( ['Add-ons'] ,{relativeTo: this.activatedRoute.parent.parent});


    })
  }
  cancel(){
    this.init();
    this.router.navigate( ['Add-ons'] ,{relativeTo: this.activatedRoute.parent.parent});
  }


}
