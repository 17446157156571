import { Component } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-preview-event',
  templateUrl: './preview-event.component.html',
  styleUrls: ['./preview-event.component.scss']
})
export class PreviewEventComponent {

    formData;
    constructor(private fb: FormBuilder, public ref: DynamicDialogRef,public config: DynamicDialogConfig) {

        this.formData = JSON.parse(config.data);
    }
}
