<form [formGroup]="addressForm" autocomplete="off">
    <div class="container">
        <div class="card custom-table-border">
            <!-- <div class="card-header text-center bg-primary rounded-top-23">
                <h1 class="text-white">Location</h1>

                
            </div> -->
            <div class="card-header border-0 text-center bg-primary rounded-top-23 ">
                <h1 class="fw-bold text-white mt-2">{{'LctionTitle'|translate}}</h1>
                </div>
            
            <div *ngIf="!mapProblem" class="card-body">
                <div *ngIf="loader.show1" @fadeOut class="d-flex justify-content-center align-items-center" style="border-radius: 25px;top : 0;position: absolute;z-index: 1050;background-color:#fff;width: 100%;height: -webkit-fill-available;right: 0;bottom: 0;left: 0;">


                </div>
                <div class="row">
                </div>

                <!-- <div class="row  align-items-center">
                    <div class="col-12">
                        <h6>This event is reccuring so that select which event instances that you want to copy this location to  </h6>
                    </div>
                
                    <div class="p-input-filled col-sm-12 col-md-8 mb-2">
                        <span class="p-float-label">
                
                                <p-multiSelect pTooltip="Select which instance of event will have this tickets " showDelay="1000" tooltipPosition="top" [options]="eventInstance" [styleClass]="'w-100'" [optionLabel]="'name'" [optionValue]="'id'"
                                    display="chip" formControlName="eventId" inputId="eventInstance"
                                    [ngClass]="{'ng-invalid ng-dirty':(addressForm.get('eventId')?.touched && addressForm.get('eventId')?.invalid)||(submitted&& addressForm.get('eventId').invalid)}"></p-multiSelect>
                                    <label class="b-5 ms-2" for="eventInstance">Event instances</label>
                                    </span>
                                    <small class="p-error" *ngIf="(  addressForm.get('eventId')?.touched &&
                                    addressForm.get('eventId')?.invalid)||(submitted&& addressForm.get('eventId').invalid)
                                        " >Please select at least one event instances</small>
                            </div>
                
                </div> -->
               
                <div  class="row  align-items-center" [ngClass]="{'justify-content-between':isRecurring,'justify-content-center':!isRecurring}" >
                    <div class="col-12" *ngIf="isRecurring&&!isSreise">
                        <h6>{{'LctionRecDsc002'|translate}} </h6>
                    </div>
                    <div class="col-12" *ngIf="isRecurring&&isSreise">
                        <h6>{{'LctionRecDsc001'|translate}}  </h6>
                    </div>
                    <div class="col-12" *ngIf="!isRecurring&&isSreise">
                        <h6>{{'LctionRecDsc003'|translate}}  </h6>
                    </div>
                    <div class="p-input-filled col-sm-12 col-md-6 mb-2" *ngIf="isRecurring||isSreise">
                        <span class="p-float-label">
                
                                <p-multiSelect pTooltip="{{'LctionRecSlct001Pop'|translate}} " showDelay="1000" tooltipPosition="top" [options]="eventInstance" [styleClass]="'w-100'" [optionLabel]="'name'" [optionValue]="'id'"
                                    display="chip" formControlName="eventId" inputId="eventInstance"
                                    [ngClass]="{'ng-invalid ng-dirty':(addressForm.get('eventId')?.touched && addressForm.get('eventId')?.invalid)||(submitted&& addressForm.get('eventId').invalid)}"></p-multiSelect>
                                    <label class="b-5 ms-2" for="eventInstance">{{'LctionRecDsc001'|translate}}</label>
                                    </span>
                                    <small class="p-error" *ngIf="(  addressForm.get('eventId')?.touched &&
                                    addressForm.get('eventId')?.invalid)||(submitted&& addressForm.get('eventId').invalid)
                                        " >{{'LctionRecSlct001ErrMsg'|translate}}</small>
                            </div>

                            <!-- Please select at least one event instances -->
                    <div class="p-input-filled  col-sm-12 col-md-6 mb-2">
                        <AutocompleteComponent (problem)="getProblem($event)"  (setAddress)="getAddress($event)" 
                        pTooltip="{{'LctionInpt001Pop'|translate}}" showDelay="1000" 
                        tooltipPosition="top"  addressType="geocode"
                         [addressValue]="addressForm.get('address').value" ></AutocompleteComponent>
                    </div>

                </div>
                <div class="row mb-3">
                    <div class=" col-md-12 col-12 col-xs-12">
                        <div #gmap style="width:100%;height:400px" #addresstext [ngClass]="{'d-none': !visible}" ></div>
                    </div>
                </div>
                <div class="row mb-3" *ngIf="visible">

                    <div class="p-input-filled  col-sm-4 col-md-4 mb-3">
                        <span class="p-float-label">
                        <input  inputId="ffff"
                         pTooltip="{{'LctionInpt002Pop'|translate}}" showDelay="1000" tooltipPosition="bottom" formControlName="address"  class="form-control"  pInputText />
                        <label class=" form-label font-size-18"  for="ffff">{{'LctionInpt002'|translate}}</label>
                        </span>
                    </div>
                    <div class="p-input-filled  col-sm-4 col-md-4 mb-3">
                        <span class="p-float-label">
                        <input formControlName="country" type="text" 
                        pTooltip="{{'LctionInpt003Pop'|translate}}" showDelay="1000" tooltipPosition="bottom" class="form-control" required pInputText />
                        <label class="font-size-18" >{{'LctionInpt003'|translate}}</label>
                        </span>
                    </div>
                    <div class="p-input-filled  col-sm-4 col-md-4 mb-3">
                        <span class="p-float-label">
                        <input  formControlName="{{'LctionInpt004Pop'|translate}}" pTooltip="City" showDelay="1000" tooltipPosition="bottom" type="text" class="form-control" required pInputText />
                        <label class="font-size-18" >{{'LctionInpt004'|translate}}</label>
                        </span>
                    </div>
                    <div class="p-input-filled  col-sm-4 col-md-4 mb-3 d-none">
                        <span class="p-float-label">
                        <input  formControlName="lat" type="text" pTooltip="lat" showDelay="1000" tooltipPosition="bottom" class="form-control" required pInputText />
                        <label class="font-size-18" >lat</label>
                        </span>
                    </div>
                    <div class="p-input-filled  col-sm-4 col-md-4 mb-3 d-none">
                        <span class="p-float-label">
                        <input  formControlName="lng" pTooltip="lng" showDelay="1000" tooltipPosition="bottom" type="text" class="form-control" required pInputText />
                        <label class="font-size-18" >lng</label>
                        </span>
                    </div>

                </div>

              
                <div class="row mb-3 justify-content-end d-none">
                    <div class="col-md-3 col-12 justify-content-md-end ">
                        <div class="d-flex  justify-content-center">
                            <div class="banner-btn rounded-pill mx-2">
                                <button  class="save-btn rounded-pill" pTooltip="Reset Marker" showDelay="1000" tooltipPosition="left" >Reset Marker<span></span></button>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-3 col-12 ">
                        <div class="d-flex justify-content-center">
                            <div class="banner-btn rounded-pill mx-2">
                                <button type="submit" class="save-btn rounded-pill">{{'Save'|translate}}<span></span></button>
                            </div>
                        </div>
                    </div>
                </div>


            </div>

            <div *ngIf="mapProblem">
                <div class="row">
                    <div class="col-12 d-flex justify-content-center">
                        <h5>
                           {{'ApiErrormap001'|translate}}
                        </h5>
                    </div>
                </div>

            </div>
        </div>




        <div class="row"  *ngIf="!loader.show1">
            <div class="col-12 d-flex justify-content-lg-center justify-content-center">
<!--                <div class="banner-btn rounded-pill mx-2">-->
<!--                    <button class="rounded-pill cancel-btn" pTooltip="Cancel and dismiss changes" showDelay="1000" tooltipPosition="left">-->
<!--                        <i class="pi pi-angle-left mt-1" ></i> Cancel<span></span>-->
<!--                    </button>-->
<!--                </div>-->
                <div class=" d-flex justify-content-center gap-1">
                    <button [disabled]="isSubmitting" (click)="submit(1)" type="submit" class="rounded-pill save-btn {{isSubmitting? 'bg-disabled' : ''}}" pTooltip="Save changes" showDelay="1000" tooltipPosition="right">
                        <i  *ngIf="isSubmitting" class="fas fa-spinner fa-spin"></i>
                        {{'SavBtn'|translate}}  <span></span>
                    </button>
                    <button *ngIf="!srv.addressBool" [disabled]="isSubmitting" (click)="submit(2)"  type="submit" class="rounded-pill save-btn {{isSubmitting? 'bg-disabled' : ''}}" pTooltip="Save changes" showDelay="1000" tooltipPosition="right">
                        <i  *ngIf="isSubmitting" class="fas fa-spinner fa-spin"></i>
                        {{'Sav&NxtBtn'|translate}}  <span></span>
                    </button>
                </div>
            </div>
        </div>
    </div>
</form>






