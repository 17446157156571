<div class="container">
    <div class="card custom-table-border">
        <div class="card-header border-0 text-center bg-primary rounded-top-23 ">
            <h1 class="fw-bold text-white mt-2">{{'TcktTblTitl'|translate}}</h1>
        </div>
        <div *ngIf="loader.show1" @fadeOut class="d-flex justify-content-center align-items-center"
            style="border-radius: 25px;top : 0;position: absolute;z-index: 1050;background-color:#fff;width: 100%;height: -webkit-fill-available;right: 0;bottom: 0;left: 0;">


        </div>


        <p-toast></p-toast>

        <div class="">
            <p-table #dt responsiveLayout="stack" [breakpoint]="'991px'" [value]="data" [rows]="5"
                [paginator]="totalRecord>5" [globalFilterFields]="['name']" [tableStyle]="{'max-width':'100%'}"
                [rowHover]="true" dataKey="id"
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                [showCurrentPageReport]="true" class="" [lazy]="true" [totalRecords]="totalRecord"
                (onLazyLoad)="getTiketsLazy($event)">
                <ng-template pTemplate="caption">

                    <div>

                        <div class="d-sm-flex d-block align-items-center justify-content-sm-between text-center">

                            <div class="  ms-md-3  mb-sm-0  mb-2">
                                <button pButton pRipple class="rounded-pill add-new-btn mr-2" (click)="show()"><i
                                        class="fa-solid fa-plus"></i> {{'TcktTblBtn'|translate}}<span></span></button>
                            </div>
                            <div class="p-input-filled me-md-3 ">
                                <span class="p-input-icon-left">
                                    <i class="pi pi-search"></i>
                                    <input pTooltip="{{'TcktTblSrchInptPop'|translate}}" showDelay="1000"
                                        tooltipPosition="left" pInputText type="text" class="form-control"
                                        (input)="dt.filterGlobal($event.target.value, 'contains')"
                                        placeholder="{{'Search...'|translate}}" />
                                </span>
                            </div>
                        </div>
                    </div>

                </ng-template>
                <ng-template pTemplate="header" class="">
                    <tr>
                        <th class="fw-bold text-center">{{'TcktTblTh001'|translate}}</th>
                        <th pSortableColumn="title" style="min-width:7rem" class="fw-bold text-center">
                            {{'TcktTblTh002'|translate}} </th>
                        <th pSortableColumn="totalQty" style="min-width:7.8rem" class="fw-bold text-center ">
                            {{'TcktTblTh003'|translate}} </th>
                        <th pSortableColumn="gross" style="min-width:9rem" class="fw-bold text-center ">
                            {{'TcktTblTh004'|translate}} </th>
                        <th style="min-width:7rem" class="fw-bold text-center ">{{'TcktTblTh005'|translate}} </th>
                        <th class="fw-bold text-center "> {{'TcktTblTh006'|translate}}</th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-ticket>
                    <tr>
                        <td class="text-md-center padding-custom text-lenght-larg text-end"><span
                                class="headers-cutom text-start fw-bold ">{{'TcktTblTh001'|translate}}</span> <span
                                class="text-lenght">{{ticket.name}}</span></td>
                        <td class="text-md-center padding-custom text-end"><span
                                class="headers-cutom text-start fw-bold ">{{'TcktTblTh002'|translate}}</span>
                            {{ticket.price == '0' ? ('TcktTblTyp002'|translate) : ticket.price+""+cuurency}}</td>

                        <td class="text-md-center padding-custom text-end"><span
                                class="headers-cutom text-start fw-bold ">{{'TcktTblTh003'|translate}}</span>
                            {{ticket.sold}}/{{ticket.count}}</td>
                        <td class="text-md-center padding-custom text-end"><span
                                class="headers-cutom text-start fw-bold ">{{'TcktTblTh004'|translate}}</span>
                            {{getDateOntomeZoneformat(ticket.start,'full')}}</td>
                        <!-- <td><p-rating [ngModel]="event.gross" [readonly]="true" [cancel]="false"></p-rating></td> -->
                        <!-- <td><span [class]="'product-badge status-' + (product.inventoryStatus ? product.inventoryStatus.toLowerCase() : '')">{{product.inventoryStatus}}</span></td> -->
                        <td class="text-md-center padding-custom text-end"> <span
                                class="headers-cutom text-start fw-bold ">{{'TcktTblTh005'|translate}}</span> <span
                                class="badge rounded-pill text-bg-dark">{{ticket.type|translate}}</span></td>
                        <td class="text-md-center padding-custom text-end">
                            <div [class.action-custom]="document.documentElement.dir === 'ltr'"
                            [class.action-custom-rtl]="document.documentElement.dir === 'rtl'">
                                <button pButton pRipple icon="pi pi-pencil"
                                    class="p-button-rounded p-button-primary mx-2" (click)="edit(ticket)"></button>
                                <button pButton pRipple icon="pi pi-trash" class="p-button-rounded p-button-danger"
                                    (click)="remove($event,ticket)"></button>
                            </div>
                            <p-confirmPopup></p-confirmPopup>
                            <!-- <button pButton pRipple icon="pi pi-trash"
                            class="p-button-rounded p-button-danger m-1"
                            (click)="remove($event,event.id)"></button> -->


                        </td>
                    </tr>
                </ng-template>

                <ng-template pTemplate="summary">
                    <div class="flex align-items-center justify-content-between">
                        {{'TcktTblFooter01'|translate}} {{data ? data.length : 0 }} {{'TcktTblFooter02'|translate}}.
                    </div>
                </ng-template>
            </p-table>

        </div>

    </div>
</div>

<!-- <div class="events-area pt-100 pb-70">
    <div class="container">
        <div class="">
            <p-toast></p-toast>


            <div class=" custom-table-border">


            </div>

        </div>
    </div>
</div> -->
