import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { EventStepsService } from 'src/app/services/event-steps.service';

@Component({
  selector: 'app-redirect',
  templateUrl: './redirect.component.html',
  styleUrls: ['./redirect.component.scss']
})
export class RedirectComponent implements OnInit {
    constructor(private srv:EventStepsService, private router : Router,private activatedRoute : ActivatedRoute){}
    ngOnInit(): void {
       var id= this.activatedRoute.snapshot.paramMap.get('id')

        if(this.srv.isRecurring){
            this.router.navigate(['/events/manage-event/',id , 'calendar'])
        }
else
    {
        this.router.navigate(['/events/manage-event/',id , 'timing'])
    }

    }



}
