import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-survey',
  templateUrl: './survey.component.html',
  styleUrls: ['./survey.component.scss']
})
export class SurveyComponent {
    surveyForm!: FormGroup;


    constructor(private fb: FormBuilder){

    }
    initializeForm(surveyTitle: string, yourOpinion: string) {
        this.surveyForm = this.fb.group({
            surveyTitle: [surveyTitle, [Validators.required]],
            yourOpinion: [yourOpinion, [Validators.required]],

        });
    }

    QezSelect = [
        {type: 'Value Num 1'},
        {type: 'Value Num 2'},
        {type: 'Value Num 3'},
        {type: 'Value Num 4'},
        {type: 'Value Num 5'}
    ];
}
