import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { OrgnizersService } from 'src/app/services/orgnizers.service';

@Component({
  selector: 'app-assign-role',
  templateUrl: './assign-role.component.html',
  styleUrls: ['./assign-role.component.scss']
})
export class AssignRoleComponent implements OnInit{
  form : FormGroup;
  data : any[];
  id : string;
  submitted: boolean;
  ngOnInit(): void {
    this.init()
    this.id = this.config.data.id;
  //  console.log(this.id);
    this.orgsrv.getRoles1(this.id).subscribe(x => this.data = x);
  }
  init(){
    this.form = this.fb.group({
      roleId : ["" , Validators.required]
    })
  }
  constructor(private ref: DynamicDialogRef,public config: DynamicDialogConfig,private activatedRoute : ActivatedRoute,private fb : FormBuilder , private orgsrv : OrgnizersService) {
    
    
  }
  submit(){
    if(this.form.valid){
    this.ref.close(  this.form.value.roleId   )  ;
    }
    else{
      this.submitted = true;
    }
  }
   
}
