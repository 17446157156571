<div class="events-area pt-100 pb-70 card-ticket-bg">
    <h1 class="text-center mb-4 fw-bold">{{'FavPgTitl001'|translate}}</h1>
    <div class="container">
        <div class="row">
            <div *ngFor="let item of Events; index as i;" class="col-lg-4 col-md-6">
                <app-event-card [item]="item" (likedClicked)="Like($event)"></app-event-card>
            </div>
        </div>
    </div>
</div>

<div class="events-area pt-100 pb-70 card-ticket-bg">
    <h1 class="text-center mb-4 fw-bold">{{'FavPgTitl002'|translate}}</h1>
    <div class="container">
        <div class="row">
            <div *ngFor="let item of Organizers; index as i;" class="col-lg-4 col-md-6 mb-4">
              <app-organizer-card  [item]="item" (likedClicked)="follow($event)"></app-organizer-card>
            </div>
        </div>
    </div>
</div>
