import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, ReplaySubject, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { CurrencyDataDto, admincurrency, currencyesDatasingleDto, langugesDto } from '../Interfaces/attrDto';
import { map } from 'rxjs/operators';
import { DropDownItem } from '../Interfaces/DropDownItem';

@Injectable({
  providedIn: 'root'
})
export class CurrenciesSrvService {

  url=environment.Api;
 
  private subject: Subject<admincurrency> = new ReplaySubject<admincurrency>(1);

  get $getSubject(): Observable<admincurrency> {
    return this.subject.asObservable();
  }
  constructor(public http:HttpClient,
    private router:Router,
    private activatedRoute:ActivatedRoute) { }

    getAlltype(skip:number=0,serach:string=""){
    //  this.http.get<CurrencyDataDto[]>(this.url+'d/currency/all').subscribe(x=> this.subject.next(x));
      this.http.get<admincurrency>(this.url+'d/currency/g/m/tab/'+skip+'/'+serach).subscribe(x=> this.subject.next(x));
   
      }

      postattrstauts(id){
        var data:string="";
       return this.http.put(this.url+'d/currency/t/'+ id,data)
      }

      GetAllSystemLang(){
       return this.http.get<langugesDto[]>(this.url+'d/cu/all').pipe(map(x => {

         var l=x.map(y=>{
            var  t:DropDownItem={id:y.cultureId,name:y.name}
            return t
         })
          return l;
      }))
      }
      GetAtrrSingle(id){
        return this.http.get<currencyesDatasingleDto[]>(this.url+'d/currency/g/lan/'+id);
      }

      PostnewAttr(data){
        var url=this.url+'d/currency/c';
        return this.http.post(url, data);
      }

      DeletAttr(id){
        return this.http.delete(this.url+'d/a/d/'+id);
      }
}
