import { OrgnizersService } from 'src/app/services/orgnizers.service';
import { Component , OnInit, ViewEncapsulation} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { OrganizerFromStepService } from 'src/app/services/organizer-from-step.service';
import { environment as env}  from 'src/environments/environment';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-organizer-form',
  templateUrl: './organizer-form.component.html',
  styleUrls: ['./organizer-form.component.scss'],
  encapsulation : ViewEncapsulation.None
})export class OrganizerFormComponent implements OnInit{
  items :any[];
  id : string;
  
  
  constructor(private srvform : OrganizerFromStepService,private translate:TranslateService,private OrgServ : OrgnizersService,private router :Router ,private activatedRoute : ActivatedRoute ,){
    this.translate.onLangChange.subscribe(() => {
      this.updateTranslations();
    });
  }
  updateTranslations() {
    this.items = [
      { label:this.translate.instant('OrgnzrFrmBscInfoTitl'), routerLink: [ 'step1'] },
      { label: this.translate.instant('OrgnzrFrmScialLnksTitl'), routerLink: [ 'step2'  ] }
  ];
  }

  ngOnInit(): void {
    this.id = this.activatedRoute.snapshot.paramMap.get('id');

    if (this.id != "0") {
      this.OrgServ.getOneOrg(this.id).subscribe(x => {


        this.srvform.Initializers(x.id, x.name, x.bio, env.Api + x.imageUrl, x.email, x.website, x.links , x.phoneNumber);
        this.srvform.getLinksForEdit(x.links);


      })
    }
  //   this.items = [
  //     { label: 'Basic info', routerLink: [ 'step1'] },
  //     { label: 'Social links', routerLink: [ 'step2'  ] }
  // ];
  this.updateTranslations();
  this.router.navigate(["step1"
  ], { relativeTo: this.activatedRoute });
  }

}