import { AfterViewInit, Component, ComponentFactoryResolver, OnInit, TemplateRef, Type, ViewChild, ViewContainerRef } from '@angular/core';
import { ImageCroppedEvent, LoadedImage } from 'ngx-image-cropper';
import { MessageService } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { CropperComponent } from 'src/app/components/shared/cropper/cropper.component';
import { TextEditorComponent } from 'src/app/components/shared/text-editor/text-editor.component';
import { UploadPhotoComponent } from 'src/app/components/shared/upload-photo/upload-photo.component';
import { BasicInfoComponent } from '../basic-info/basic-info.component';
import { DomSanitizer,SafeResourceUrl  } from '@angular/platform-browser';
import { BypassHTMLPipe } from '../../../../../BypassHTML.pipe'
import { MyImageUploaderComponent } from 'src/app/components/shared/my-image-uploader/my-image-uploader.component';
import { AbstractControl, FormArray, FormBuilder, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { EventProfileService } from './event-profile.service';
import { EventStepsService } from 'src/app/services/event-steps.service';
import { environment as env} from '../../../../../../environments/environment';
import { transition, style, animate, trigger } from '@angular/animations';
import { LoaderService } from 'src/app/services/loader.service';
import { ActivatedRoute, Router } from '@angular/router';
import { HtmlTagDefinition } from '@angular/compiler';
import { EventsService } from 'src/app/services/events.service';
import { DropDownItem } from 'src/app/Interfaces/DropDownItem';
import { DatePipe } from '@angular/common';


const leaveTrans = transition(':leave', [
    style({
        opacity: 1
    }),
    animate('1s ease-out', style({
        opacity: 0
    }))
])



const fadeOut = trigger('fadeOut', [
    leaveTrans
]);



@Component({
    selector: 'app-create-event-profile',
    templateUrl: './create-event-profile.component.html',
    styleUrls: ['./create-event-profile.component.scss'],
    animations : [fadeOut]
})
export class CreateEventProfileComponent implements OnInit , AfterViewInit{
    @ViewChild('ifram') iframe:HTMLIFrameElement
    submitted=false;
    isSubmitting: boolean = false;
    editorOptions = {
        toolbar: [
            ['bold', 'italic', 'underline', 'strike'],
             ['blockquote', 'code-block'],
            [{ 'header': 1 }, { 'header': 2 }],
            [{ 'list': 'ordered'}, { 'list': 'bullet' }],
            [{ 'script': 'sub'}, { 'script': 'super' }],
            [{ 'indent': '-1'}, { 'indent': '+1' }],
            [{ 'direction': 'rtl' }],
            [{ 'size': ['small', false, 'large', 'huge'] }],
            [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
            [{ 'color': [] }, { 'background': [] }],
            [{ 'font': [] }],
            [{ 'align': [] }],

                ['clean']
        ]
    }
    eventInstance:DropDownItem[]=[];
    croppedImage: any = '';
    imageChangedEvent : any;
    components = [];
    isRecurring: any;
    isSreise: any;
    get formDescription() {
        return this.form.get('description') as FormArray;
    }

    form!: FormGroup;
    // initialize Form
    initForm(id:string[],image, summary,descr) {
        this.form = this.fb.group({
            id: [id,[Validators.required]],
            image: [image,[Validators.required,this.validImage()]],
            summary: [summary,[Validators.required]],
            description: this.fb.array(descr)
        })
    }
    validImage():ValidatorFn{
        return (control:AbstractControl)=>{
                 return control?.value!=""?null:{imageisnull:true}
              }
      }


      getUrlid(x):string{
        let str = x;
        const index = str.indexOf('v');
        str = str.slice(index + 2, str.length);
        const url = 'https://www.youtube.com/embed/' + str;
        //console.log(str)
        return str
      }
    removeItem(i) {
        this.formDescription.removeAt(i);
    }
    move(shift: number, i: number): void {
        const { controls } = this.formDescription;
        [controls[i], controls[i + shift]] = [controls[i + shift], controls[i]];
        this.formDescription.patchValue(controls);
    }
   // @ViewChild('aaas') aaa: MyImageUploaderComponent;
    constructor(public loader : LoaderService,
        private fb: FormBuilder,
        private messageService: MessageService,
        public dialogService: DialogService,
        public _sanitizer: DomSanitizer,
        public router : Router , public activatedRoute : ActivatedRoute,
        private srvProfile : EventProfileService,
        private srv : EventStepsService ,
          private sanitizer: DomSanitizer,private EventPostSrv:EventsService 
          , private datePipe: DatePipe ) {}
    ngAfterViewInit(): void {
        // this.iframe.addEventListener('scroll',(e)=>{
        //     e.preventDefault()
        //             })
    }

    getDateOntomeZone(d: string | number) {
        var eventOffset = this.srv.offSet * 60 ;
        var MyDate = new Date(d).setMinutes(new Date(d).getMinutes()  + (eventOffset));
        if(new Date(MyDate).getFullYear()==new Date(Date.now()).getFullYear()){
            var l= this.datePipe.transform(new Date(MyDate), 'EEE MMMM d hh:mm a');
            return l;
        }else{
            var l= this.datePipe.transform(new Date(MyDate), 'EEE MMMM d,y hh:mm a');
            return l;
        }
      }
    ngOnInit(): void {


      
        this.loader.showLoader1();
        this.initForm([],'','',[]);

        this.srv.state$.subscribe(x =>{
            this.initForm([x.id],'','',[]);
            this.isRecurring=x.isRecurring;
            this.isSreise=x.isColection;
            if(x.isColection){
    
                this.EventPostSrv.GetSubEvents(x.id).subscribe(y=>{
                  //  console.log(x);
                    var t=y.map(l=>{
                        if(l.id==x.id){l.name="This event"}
                        
                        return l;
                    })
                    this.eventInstance=t;
                })
            }
            if(x.isRecurring&&!x.isColection){
    
                this.EventPostSrv.getReccuringInstances(x.id).subscribe(y=>{
                  //  console.log(x);
                    var t=y.map(l=>{
                        if(l.id==x.id){l.name="This instance"}
                        else(l.name= "Instance of:"+this.getDateOntomeZone(l.name) )
                        return l;
                    })
                    this.eventInstance=t;
                })
            }
            this.srvProfile.getEventProfile(x['id']).subscribe(y=>{
                if (y){
                    this.loader.hideLoader1();
                    this.initForm([x.id],env.Api+y['image'],y['summary'],[]);
                    let c = JSON.parse(y['description']);
                    c.forEach(element => {
                    this.formDescription.push(this.fb.group(element))
                    });
                }
                this.loader.hideLoader1();
            },err=>
           console.log(err)
            )

        })

    }
    addComponent(type) {

        if(type=="image"){
            const description = this.fb.group({
                value1: ['',[Validators.required,this.validImage()]],
                type: [type]
            });
            this.formDescription.push(description);
        }else
        {
            const description = this.fb.group({
                value1: ['',[Validators.required]],
                type: [type]
            });
            this.formDescription.push(description);
        }


    }

  getVideoIdFromUrl(url: string): string | null {
        const searchParams = new URLSearchParams(new URL(url).search);
        return searchParams.get('v');
      }
      
    makeItSafe(x):SafeResourceUrl  {
        let str = x;
        let index = str.indexOf("v");
        str = str.slice(index + 2, str.length);
        let url = "https://www.youtube.com/embed/" + str;
       // console.log(this.sanitizer.bypassSecurityTrustResourceUrl(url))
        return this.sanitizer.bypassSecurityTrustResourceUrl(url);
    }
    submit(x){
        if(x == 1){
          
            if(this.form.valid){
                this.isSubmitting =true;
                let x = this.form.value
                x.description = JSON.stringify(x.description)
               // console.log(x)
                this.srvProfile.postEventProfile(x).subscribe(x=>{
                    this.messageService.add({severity:'success', summary:'Saved Successfully'})
                    this.isSubmitting =false;
                    if(x)
                    {
                      this.srv.profileBool = true;
                      this.srv.CalculateProgress()
                    }
                });

            }
            else
            {
                this.submitted = true;

            }

        }
        if(x == 2){
    
            if(this.form.valid){
                this.isSubmitting =true;
                let x = this.form.value
                x.description = JSON.stringify(x.description)
                //console.log(x)
                this.srvProfile.postEventProfile(x).subscribe(x=>{
                    this.messageService.add({severity:'success', summary:'Saved Successfully'})
                    this.isSubmitting =false;
                    if(x)
                    {
                      this.srv.profileBool = true;
                      this.srv.CalculateProgress()
                    }
                    if(this.srv.isOnline){
                        this.router.navigate(['online-page'],{relativeTo: this.activatedRoute.parent});
                        }
                        else{
                            this.router.navigate(['tickets'],{relativeTo: this.activatedRoute.parent});
                        }
                });
              


            }
            else
            {
                this.submitted = true;

            }

        }

    }
    aaa(s){
      //  console.log(s);
        alert('hello');

    }
    // fileChangeEvent(event: any): void {
    //     this.imageChangedEvent = event;
    // }
    // imageCropped(event: ImageCroppedEvent): void {
    //     this.croppedImage = event.base64;
    // }
    // imageLoaded(image: LoadedImage): void {
    //     this.show();
    // }
    // onUpload(event): void {
    //     for (const file of event.files) {
    //         this.uploadedFiles.push(file);
    //     }
    //     this.imageChangedEvent = event.originalEvent;
    //     this.messageService.add({ severity: 'info', summary: 'File Uploaded', detail: '' });
    //     this.show();
    // }
    // onSelect(event): void {
    //     this.myFile = event.currentFiles[0];
    //     this.show();
    // }
    // show(): void {
    //     const ref = this.dialogService.open(CropperComponent, {
    //         data: {
    //             myFile: this.myFile
    //         },
    //         header: 'Crop image',
    //         width: '100%',
    //         height: '100vh',
    //     });

    //     ref.onClose.subscribe((croppedImage: any) => {
    //         if (croppedImage) {
    //             this.croppedImage = croppedImage;
    //             this.messageService.add({ severity: 'info', summary: 'Car Selected', detail: 'Vin:' });
    //         }
    //         else {
    //             console.log(this.aaa)
    //             this.aaa.clear()
    //         }
    //     });
    // }
}
