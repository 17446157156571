import { Component, OnInit,Inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { OrganizerFromStepService } from 'src/app/services/organizer-from-step.service';
import { LinkTypes, Orgnizer, OrgnizersService } from 'src/app/services/orgnizers.service';
import { environment as env } from 'src/environments/environment';
import { EventCard } from '../../landing-page/landing-page.service';
import { UserActionsService } from 'src/app/services/user-actions.service';
import { DOCUMENT } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-preview',
  templateUrl: './preview.component.html',
  styleUrls: ['./preview.component.scss']
})
export class PreviewComponent implements OnInit{
  id : string="";
  url : string = env.Api;
  data : Orgnizer;
  linksData :any[];
  linksForPreview  :any[] = [];
  helpStepsModal : boolean = false;
  EventCards : EventCard[];
  IsFollowing:boolean=false;


  constructor(public ua : UserActionsService,
    private orgformsrv:OrganizerFromStepService,private orgsrv : OrgnizersService
    ,private router : Router , private activatedrouter : ActivatedRoute , @Inject(DOCUMENT) public document: Document,   private translate:TranslateService) {
      this.translate.onLangChange.subscribe(() => {
        
        if(this.id!=""){
          this.orgsrv.getOrgEvents(this.id , 6 , 0).subscribe(x => this.EventCards = x);
        }
        
      });

  }
  showContactModal(){
    this.helpStepsModal = true
  }
  ngOnInit(): void {
    this.orgformsrv.bbbb()
   this.id = this.activatedrouter.snapshot.paramMap.get("id");
   this.linksData = this.orgformsrv.linkData
  
   this.linksForPreview = []
   this.getOrgnizerData(this.id);
  //  this.orgsrv.getOneOrg(this.id).subscribe(x => {this.data = x;
  //     this.getLinks(x.links);
  //     this.ua.getFollowerList().subscribe(list=>{
  //       if(list.includes(x.orgId)){
  //           this.IsFollowing = true;
  //       }});
  //   })
   this.orgsrv.getOrgEvents(this.id , 6 , 0).subscribe(x => this.EventCards = x);

  }

getOrgnizerData(id){
  this.orgsrv.getOneOrg(id).subscribe(x => {this.data = x;
    this.getLinks(x.links);
    this.ua.getFollowerList().subscribe(list=>{
      if(list.includes(id)){
          this.IsFollowing = true;
      }});
  })
}

  Like(id: string) {
    this.ua.postLike(id).subscribe(x => {
        this.ua.getLikedEventList().subscribe(y => {
            this.ua.LikedEvents = y as string[];
        });
    });
}
follow(Orgid){
 // console.log(Orgid)
  this.ua.postFollow(Orgid).subscribe(x=>{
    this.IsFollowing = !this.IsFollowing;
    this.getOrgnizerData(Orgid)

  });
}
  getLinks(links){

    this.linksForPreview=[];
    links.forEach(e => {

      let x = {
        url : e.linkUrl,
        icon : this.orgformsrv.getLinksName(e.attrId).misc01
      }
      this.linksForPreview.push(x)
    });
   // console.log(this.linksForPreview)
  }


}
