import { Component, OnInit, Inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FileUploadModule } from 'primeng/fileupload';
import { ToolbarModule } from 'primeng/toolbar';

import * as Papa from "papaparse"
import { Validators } from '@angular/forms';
import { PromoteFormStepsService } from 'src/app/services/promote-form-steps.service';
import { TranslateService } from '@ngx-translate/core';
import { DOCUMENT } from '@angular/common';


@Component({
    selector: 'app-step1',
    templateUrl: './step1.component.html',
    styleUrls: ['./step1.component.scss']
})
export class Step1Component implements OnInit {

    filename: string;
    submitted: boolean
    names: string[];

    constructor(private router: Router, private translate: TranslateService
        , private activatedRoute: ActivatedRoute, public prosrvform: PromoteFormStepsService, @Inject(DOCUMENT) public document: Document) {


    }
    onBasicUpload(e) {

    }
    onChange(files: any) {
     //   console.log(this.prosrvform.promoteCodeForm.controls["names"])
        this.filename = files.target.files[0].name
        if (files.target.files[0]) {
         //   console.log(files.target.files[0])
            Papa.parse(files.target.files[0], {
                header: false,
                preview: 1,
                skipEmptyLines: true,

                complete: (result, file) => {
                    const isValid = this.checkValidation(result.data.flat(1));
                    if (isValid) {

                        this.prosrvform.promoteCodeForm.get('names').patchValue(result.data.flat(1));

                    }
                    else {
                        this.filename = this.translate.instant('PrmoFrmStp1Btn001ErrMsg');
                    //    console.log("no")
                    }
                  //  console.log(result.data.flat(1));

                },
            });
        }


    }

    checkValidation(data: any[]): boolean {

        for (const cell of data) {
            if (cell === '' || cell.trim() !== cell || !(/^\S*$/.test(cell))) {
                return false;
            }

        }
        return true;
    }

    ngOnInit(): void {

    }
    submit() {
      //  console.log(this.prosrvform.promoteCodeForm.value)
        if (this.validateStep1()) {
            this.router.navigate(['step2'], { relativeTo: this.activatedRoute.parent });
        }
        this.submitted = true;
    }
    cancel() {
        this.prosrvform.clear()
        this.router.navigate(['Promo-codes'], { relativeTo: this.activatedRoute.parent.parent.parent });
        // this.router.navigate([
        //   {
        //     outlets: { 'Promot-steps': ['promo-codes-all'] }
        //   }
        // ], { relativeTo: this.activatedRoute.parent.parent });
    }
    validateStep1() {
        return this.prosrvform.promoteCodeForm.controls['name'].valid && this.prosrvform.promoteCodeForm.controls["names"].valid
    }
    //remove validation when multiple and set required when single
    removeValid() {
        if (this.prosrvform.promoteCodeForm.get("isMultiple").value) {
            this.prosrvform.promoteCodeForm.controls["name"].clearValidators();
            this.prosrvform.promoteCodeForm.controls["name"].updateValueAndValidity();
            this.prosrvform.promoteCodeForm.controls["names"].setValidators(Validators.required);
            this.prosrvform.promoteCodeForm.controls["names"].updateValueAndValidity();
        }
        else {
            this.prosrvform.promoteCodeForm.controls["name"].setValidators([Validators.required, Validators.pattern(/^\S*$/)]);
            this.prosrvform.promoteCodeForm.controls["name"].updateValueAndValidity();
            this.prosrvform.promoteCodeForm.controls["names"].clearValidators();
            this.prosrvform.promoteCodeForm.controls["names"].updateValueAndValidity();
        }
    }


}
