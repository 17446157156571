<p-fileUpload
    (onSelect)="onSelect($event)"
    (uploadHandler)="onUpload($event)"
    [customUpload]="true"
    [showUploadButton]="false"
    accept="image/*"
    cancelIcon="pi pi-times"
    cancelLabel="c"
    chooseIcon="pi pi-plus"
    chooseLabel="a"
    maxFileSize="1000000"
    multiple="multiple"
    name="demo[]"
    uploadIcon="pi pi-upload"
    uploadLabel="b"
    disabled = true
    url="./upload.php"
    invalidFileSizeMessageSummary="{0}: Invalid file size, "
    invalidFileSizeMessageDetail="maximum upload size is {0}."
    invalidFileTypeMessageSummary="{0}: Invalid file type, "
    invalidFileLimitMessageDetail="limit is {0} at most."
    invalidFileLimitMessageSummary="Maximum number of files exceeded, "
    invalidFileTypeMessageDetail="allowed file types: {0}."
    chooseStyleClass=""
    cancelStyleClass=""
    removeStyleClass=""
    uploadStyleClass=""
    chooseLabel=""
    uploadLabel=""
    cancelLabel=""
    chooseIcon=""
    uploadIcon=""
    cancelIcon=""
    showUploadButton="false"
    showCancelButton="true"
    files=""
    (onClear)="onClearClicked()"
>
    <ng-template let-file pTemplate="file">
        <img [src]="croppedImage" class="img-fluid"/>
        <!--<img [src]="file.objectURL" alt="">-->
    </ng-template>
    <!-- <ng-template pTemplate="content">
        <ul *ngIf="uploadedFiles.length">
            <li *ngFor="let file of uploadedFiles">
                {{ file.name }} - {{ file.size }} bytes
            </li>
        </ul>
    </ng-template> -->
</p-fileUpload>
