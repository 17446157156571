import { Component, OnInit,Inject } from '@angular/core';
import { OverlayPanel } from 'primeng/overlaypanel';
import { WaitListMemberDto, dashboardDto } from 'src/app/Interfaces/EventData';
import { EventStepsService } from 'src/app/services/event-steps.service';
import { EventsService } from 'src/app/services/events.service';
import { LoaderService } from 'src/app/services/loader.service';
import { UserOrderService, eventOrderDto } from '../../../user-order/user-order.service';
import { LazyLoadEvent } from 'primeng/api';
import { DOCUMENT, DatePipe } from '@angular/common';
import {environment as env} from 'src/environments/environment';
import { TranslateService } from '@ngx-translate/core';
import * as m from 'moment';
import 'moment/locale/pt-br';

@Component({
    selector: 'app-event-dashboard',
    templateUrl: './event-dashboard.component.html',
    styleUrls: ['./event-dashboard.component.scss']
})
export class EventDashboardComponent implements OnInit {

  waitlistlong:number=0;
    WaitlistMemmber:WaitListMemberDto[]=[];
    data: any[] = [];
    basicData: any;
    id:string="";
    curencySympol="";
    ordertotalRecord:number=0;
    oderArry:eventOrderDto[]=[];
    data1:any;
    soldData:dashboardDto;
    public loader: LoaderService;
    dataNetSales: any;
    dataTicketSales: any;
    dataAddOnsSold: any;
    dataPageView: any;
    ticketsQty:number=0;
    ticketsales:number=0;
    plugin=["ticket sales","Paid tickets sales","Free tickets sales","Donation tickets sales"]

   
   // basicData: { labels: string[]; datasets: { label: string; backgroundColor: string; data: number[]; }[]; };
    constructor(private eventSrv:EventsService,public Srv:EventStepsService,private orderSrv:UserOrderService,
        @Inject(DOCUMENT) public document: Document, private translate:TranslateService, private datePipe: DatePipe,){
            this.translate.onLangChange.subscribe(() => {
                this.updateLang();
                m.locale(this.translate.currentLang)
              });
    }

    onRowSelect(e, op: OverlayPanel) {// to change the view of calender
    //   console.log(e)
       if(e.data=='Paid tickets sales'){
        this.ticketsQty=this.soldData.paidticketQty;
        this.ticketsales=this.soldData.soldPaid ;

        this.dataTicketSales = {
            labels: [this.translate.instant('Paid tickets'),this.translate.instant('Sold paid tickets')],
            datasets: [
                {
                    data: [this.soldData.paidticketQty,this.soldData.soldPaid],
                    backgroundColor: [
              
                        "#FF8703",
                        "#36A2EB",
                        
                    ]
                }
            ]
        }
       }
       if(e.data=='Free tickets sales'){
        this.ticketsQty=this.soldData.freeTicketQty;
        this.ticketsales=this.soldData.soldFree ;

        this.dataTicketSales = {
            labels: [this.translate.instant('Free tickets'),this.translate.instant('Sold free tickets')],
            datasets: [
                {
                    data: [this.soldData.freeTicketQty,this.soldData.soldFree],
                    backgroundColor: [
              
                        "#FF8703",
                        "#36A2EB",
                        
                    ]
                }
            ]
        }
       }
       if(e.data=='Donation tickets sales'){
        this.ticketsQty=this.soldData.donationTicketQty;
        this.ticketsales=this.soldData.soldDontion ;

        this.dataTicketSales = {
            labels: [this.translate.instant('Donation tickets'),this.translate.instant('Sold donation tickets')],
            datasets: [
                {
                    data: [this.soldData.donationTicketQty,this.soldData.soldDontion],
                    backgroundColor: [
              
                        "#FF8703",
                        "#36A2EB",
                        
                    ]
                }
            ]
        }
       }
       if(e.data=='ticket sales'){
        this.ticketsQty=this.soldData.totalQty;
        this.ticketsales=this.soldData.soldFree+this.soldData.soldPaid+this.soldData.soldDontion ;

        this.dataTicketSales = {
            labels: [this.translate.instant('Tickets'),this.translate.instant('Sold tickets')],
            datasets: [
                {
                    data: [this.soldData.totalQty,this.soldData.soldPaid+ this.soldData.soldFree],
                    backgroundColor: [
              
                        "#FF8703",
                        "#36A2EB",
                        
                    ]
                }
            ]
        }
       }
       
        op.hide();
      }
      updateLang(){
        this.ngOnInit();
      }
    ngOnInit(): void {
        this.Srv.state$.subscribe(x=>{
            this.id=x.id;
           // console.log(x.id)
        this.curencySympol=x.currenySymbole;
            this.eventSrv.getDashboardDetails(x.id).subscribe(y=>{
                 //console.log(y)
                this.soldData=y;
                this.ticketsQty=y.totalQty;
                this.ticketsales=y.soldFree+y.soldPaid+y.soldDontion ;
                this.dataTicketSales = {
                    labels: [ this.translate.instant('Tickets'),this.translate.instant('Sold tickets')],
                    datasets: [
                        {
                            data: [y.totalQty,y.soldPaid+ y.soldFree,y.soldDontion],
                            backgroundColor: [
                      
                                "#FF8703",
                                "#36A2EB",
                                
                            ]
                        }
                    ]
                }
                this.dataAddOnsSold = {
                    labels: [this.translate.instant('AddOnTblTitl'),this.translate.instant('Sales add-ons')],
                    datasets: [
                        {
                            data: [y.addonsQty, y.soldAddons],
                            backgroundColor: [
                                "#FF8703",
                                "#36A2EB",
                            ],
                        }]
                };
                this.dataPageView = {
                    labels: [this.translate.instant('View'),this.translate.instant('like')],
                    datasets: [
                        {
                            data: [y.views,y.likers],
                            backgroundColor: [
                                "#FF8703",
                                "#b4b4b4",
                            ],
                        }]
                };
            })

            this.orderSrv.getEventOrder(this.id);
            this.orderSrv.$getSubject2
            .subscribe(x=>{
                //console.log(x)
                this.oderArry=x.order;
                this.ordertotalRecord=x.total

                this.basicData = {
                    labels: ['Jan', 'Feb', 'March', 'Apr', 'May', 'Jun', 'Jul','Aug','Sept','Oct','Nov','Des'],
                    datasets: [
                        {
                            label:this.translate.instant( 'Order report'),
                            backgroundColor: '#42A5F5',
                            data: [this.countOrdersByMonth(x.order,1), 
                                this.countOrdersByMonth(x.order,2),
                                this.countOrdersByMonth(x.order,3),
                                this.countOrdersByMonth(x.order,4),
                                this.countOrdersByMonth(x.order,5),
                                this.countOrdersByMonth(x.order,6),
                                this.countOrdersByMonth(x.order,7),
                                this.countOrdersByMonth(x.order,8),
                                this.countOrdersByMonth(x.order,9),
                                this.countOrdersByMonth(x.order,10),
                                this.countOrdersByMonth(x.order,11),
                                this.countOrdersByMonth(x.order,12)
                               ]
                        },
                    ]
                };

            });
            this.orderSrv.geteventTickettypeSales(x.id).subscribe(y=>{
                this.data=y;
                //console.log(y)
            })

            this.eventSrv.GetEventWaitListMember(x.id).subscribe(z=>{
              //  console.log(z)
                if(z.total!=0){
                    this.WaitlistMemmber=z.member;
                    this.waitlistlong=z.total
                }
            })
        })
    

        //Line Chart
      
    }
    getOverlay(e){
        
    }
    getEvent(e:LazyLoadEvent){
        var l=e.first/5;
        this.orderSrv.getEventOrder(this.id,l);

    }

    GetTeam(e:LazyLoadEvent){
        var l=e.first/5;
        this.eventSrv.GetEventWaitListMember( this.id,l).subscribe(z=>{
         
            if(z.total!=0){
                this.WaitlistMemmber=z.member;
                this.waitlistlong=z.total
            }
        })
    }

   countOrdersByMonth(orders: eventOrderDto[], month: number): number {
        let count = 0;
        orders.forEach(order => {
          const orderMonth = new Date(order.orderDate).getMonth() + 1; // add 1 to month since getMonth() returns 0-indexed month
          if (orderMonth === month) {
            count++;
          }
        });
        return count;
      }


   postEmail(e,id:string){
   // console.log(id)
        this.orderSrv.postemail(id).subscribe(x=>{
            this.eventSrv.GetEventWaitListMember( this.id).subscribe(z=>{
         
                if(z.total!=0){
                    this.WaitlistMemmber=z.member;
                    this.waitlistlong=z.total
                }
            })
        });
      }

      getDateOntomeZone(d){
        m.locale()
        let t = this.Srv.offSet * 60 ;
        let MyDate = new Date(d).setMinutes(new Date(d).getMinutes() - (t));
        if(new Date(MyDate).getFullYear()==new Date(Date.now()).getFullYear()){
            var l= m(new Date(MyDate)).format('ddd MMMM D hh:mm a')
            // this.datePipe.transform(new Date(MyDate), 'EEE MMMM d hh:mm a');
            return l;
        }else{
            var l= m(new Date(MyDate)).format( 'ddd MMMM D,y hh:mm a');
            //this.datePipe.transform(new Date(MyDate), 'EEE MMMM d,y hh:mm a');
            return l;
        }
    
    
        
       // return new Date(MyDate).toLocaleString();
    }
}
