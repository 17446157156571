import { DOCUMENT } from '@angular/common';
import { Component, OnInit, Inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CalenderStepsService } from 'src/app/services/calender-steps.service';
import { EventStepsService } from 'src/app/services/event-steps.service';

@Component({
    selector: 'app-calender-step4',
    templateUrl: './calender-step4.component.html',
    styleUrls: ['./calender-step4.component.scss']
})
export class CalenderStep4Component implements OnInit {

    ev: any[] = [];
    constructor(public Srv: CalenderStepsService, private router: Router, private activatedRoute: ActivatedRoute, @Inject(DOCUMENT) public document: Document) {
    }
    ngOnInit(): void {
        var t = this.Srv.clenderPost.controls['dates'].value;
        for (var i = 0; i < t.length; i++) {
            this.ev.push({
                start: "Start at : " + new Date(t[i].startDate).toLocaleString(), end: "End at : " + new Date(t[i].endDate).toLocaleString(), icon: 'pi pi-shopping-cart', color: '#9C27B0', image: 'game-controller.jpg'
            })
        }
    }

    previous() {
        this.router.navigate(['step2'], { relativeTo: this.activatedRoute.parent })
    }

    submit() {
        this.Srv.postCalender()
        //this.router.navigate( ['calendar'] ,{relativeTo: this.activatedRoute.parent.parent.parent});

        // this.router.navigate([
        //   {
        //     outlets: { 'CalenderOutlet': ['calender_prview'] }
        //   }
        // ],{relativeTo: this.activatedRoute.parent.parent});


    }
    cancel() {
        this.Srv.clearForm();
        this.router.navigate(['calendar'], { relativeTo: this.activatedRoute.parent.parent.parent });
        // this.router.navigate([
        //   {
        //     outlets: { 'CalenderOutlet': ['calender_prview'] }
        //   }
        // ],{relativeTo: this.activatedRoute.parent.parent});
    }
}
