<form [formGroup]="Srv.clenderSteps" (ngSubmit)="submit()" autocomplete="off">
    <div class="">
        <div class="row mb-2 ms-2">
            <span class="text-start mb-2 ms-3  "> First instance date </span>
            <div class=" p-input-filled col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6 mb-2">
                <span class="p-float-label">
                    <p-calendar [showTime]="true" [stepMinute]="1"
                        pTooltip=" {{'ShdlrFrmTitl002Dsc001Inpt001Pop'|translate}}" dataType="string" showDelay="1000"
                        tooltipPosition="bottom" [hourFormat]="12" appendTo="body" formControlName="startDate"
                        [minDate]="minDateValue" [maxDate]="maxEventDate" inputId="time"
                        [styleClass]="'form-control m-0 p-0'" [inputStyleClass]="'form-control'" #salesStartDate
                        (onSelect)="setDateAndConfig($event)" [ngClass]="{
                                'ng-invalid ng-dirty':
                                    (Srv.clenderSteps.get('startDate')?.touched &&
                                    Srv.clenderSteps.get('startDate')?.invalid)||(submitted&& Srv.clenderSteps.get('startDate').invalid)
                            }">
                        <ng-template pTemplate="footer">
                            <p class="clock-positon"> <i class="fa-solid fa-clock"></i> </p>
                            <!-- <p class="" style="padding-left: 1.1rem;position:relative;translate: 0 -80px;"> <i style="font-size: 1.1rem" class="fa-solid fa-clock"></i> </p> -->
                        </ng-template>
                    </p-calendar>
                    <label class=" font-size-14" for="time">{{'ShdlrFrmTitl002Dsc001Inpt001'|translate}}</label>
                </span>
                <small class="p-error" *ngIf="(Srv.clenderSteps.get('startDate')?.touched &&
                    Srv.clenderSteps.get('startDate')?.invalid)||(submitted&& Srv.clenderSteps.get('startDate').invalid)
                        ">{{'ShdlrFrmTitl002Dsc001Inpt001ErrMsg'|translate}}</small>
            </div>
            <div class=" p-input-filled col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6 mb-2  mb-2">
                <span class="p-float-label">
                    <p-calendar [showTime]="true" [hourFormat]="12" [stepMinute]="1"
                        pTooltip="{{'ShdlrFrmTitl002Dsc001Inpt002Pop'|translate}}" tooltipPosition="bottom"
                        showDelay="1000" formControlName="endDate" dataType="string" appendTo="body"
                        [minDate]="maxDateValue" [maxDate]="maxEventDate2" inputId="time2"
                        [styleClass]="'form-control m-0 p-0'" [inputStyleClass]="'form-control'" [ngClass]="{
                                'ng-invalid ng-dirty':
                                    (Srv.clenderSteps.get('endDate')?.touched &&
                                    Srv.clenderSteps.get('endDate')?.invalid)||(submitted&& Srv.clenderSteps.get('endDate').invalid)
                            }">
                        <ng-template pTemplate="footer">
                            <p class="clock-positon"> <i class="fa-solid fa-clock"></i> </p>
                            <!-- <p class="" style="padding-left: 1.1rem;position:relative;translate: 0 -80px;"> <i style="font-size: 1.1rem" class="fa-solid fa-clock"></i> </p> -->
                        </ng-template>
                    </p-calendar>
                    <label class=" font-size-14" for="time2">{{'ShdlrFrmTitl002Dsc001Inpt002'|translate}}</label>
                </span>
                <small class="p-error" *ngIf="(Srv.clenderSteps.get('endDate')?.touched &&
                    Srv.clenderSteps.get('endDate')?.invalid)||(submitted&& Srv.clenderSteps.get('endDate').invalid)
                        ">{{'ShdlrFrmTitl002Dsc001Inpt002ErrMsg'|translate}}</small>
            </div>
        </div>
    </div>
    <div class="col-12 d-flex  justify-content-center">
        <div class="banner-btn rounded-pill mx-1">
            <button class="rounded-pill cancel-btn" pTooltip="Go back for previous step" showDelay="1000"
                tooltipPosition="left" (click)="previous()" type="button"><i class="pi mt-1"
                    [class.pi-angle-right]="document.documentElement.dir === 'rtl'"
                    [class.pi-angle-left]="document.documentElement.dir === 'ltr'"></i> {{'PrviosBtn'|translate}}
                <span></span></button>
            <button class="rounded-pill save-btn ms-1" pTooltip="cancel and dismiss changes" showDelay="1000"
                tooltipPosition="bottom" (click)="cancel()" type="button">{{'CanclBtn'|translate}}
                <span></span></button>
            <button pTooltip="Save and go to next step" showDelay="1000" tooltipPosition="right" type="submit"
                class="rounded-pill next-btn  ms-1 mt-md-0 mt-1">{{'NextBtn'|translate}} <i class="pi mt-1"
                    [class.pi-angle-right]="document.documentElement.dir === 'ltr'"
                    [class.pi-angle-left]="document.documentElement.dir === 'rtl'"></i><span></span></button>
        </div>
    </div>

</form>
