import { DOCUMENT } from '@angular/common';
import { Component, OnInit, Inject } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { DropArg } from '@fullcalendar/interaction';
import { DropDownItem } from 'src/app/Interfaces/DropDownItem';
import { AddOnsStepsService } from 'src/app/services/add-ons-steps.service';
import { AddOnsService } from 'src/app/services/add-ons.service';

@Component({
    selector: 'app-addon-init',
    templateUrl: './addon-init.component.html',
    styleUrls: ['./addon-init.component.scss']
})
export class AddonInitComponent implements OnInit {
    groups: DropDownItem[] = [];
    submitted: boolean = false;
    constructor(private router: Router, private activatedRoute: ActivatedRoute, public srv: AddOnsStepsService, private AddonsServe: AddOnsService, @Inject(DOCUMENT) public document: Document) {

    }
    ngOnInit(): void {
        this.AddonsServe.getEventAddOnsGroup(this.srv.AddonsForm.controls['eventId'].value).subscribe(x => { this.groups = x })
    }
    cancel() {
        this.srv.clear()
        this.router.navigate(['Add-ons'], { relativeTo: this.activatedRoute.parent.parent.parent });
    }
    submit() {
        if (this.validateAddOnsInit()) {
            this.router.navigate(['info'], { relativeTo: this.activatedRoute.parent });
        } else {
            this.submitted = true;
        }

    }
    setsmoeval(s) {
        this.srv.setsomeValidation(s.checked);
    }
    validateAddOnsInit() {
        return this.srv.AddonsForm.controls['groupId'].valid;
    }
}
