<div class="container">
    <div class="card custom-table-border">
        <!-- <div class="card-header text-center bg-primary rounded-top-23">
            <h1 class="text-white">Publish</h1>
        </div> -->
        <div class="card-header border-0 text-center bg-primary rounded-top-23 ">
            <h1 class="fw-bold text-white mt-2">{{'PblshTblTitl'|translate}}</h1>
            </div>

        <div class="">
            <div class="">
                <div class="">
                    <div class="col-12 d-flex justify-content-center" *ngIf="singlePublish" >
                        <div class="banner-btn rounded-pill  ms-md-4   mb-2" >
                            <p *ngIf="!readyToPublish">{{'PblshTblDisc'|translate}}</p>
                            <button [disabled]="isSubmitting" *ngIf="readyToPublish" pButton pRipple  class="rounded-pill add-new-btn mr-2" [ngStyle]="{'background':isPub? 'rgb(211 47 47 / 92%)'  : 'rgb(4 205 4 / 60%)'}"
                                    (click)="publish()"><i class="fa-solid fa-plus"></i>{{isPub? ('Unpublish'|translate):('Publish'|translate)}} <span></span></button>
                        </div>


                    </div>
                    <!-- <div class="" *ngIf="!singlePublish" >
                        <p-table #dt responsiveLayout="stack" [breakpoint]="'991px'" [value]="data" [rows]="6" [paginator]="true" [globalFilterFields]="['title']"
                        [tableStyle]="{'max-width':'100%'}" [rowHover]="true" dataKey="id"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                        [showCurrentPageReport]="true" class="">
                        <ng-template pTemplate="caption">

                            <div>

                                <div class="d-sm-flex d-block align-items-center justify-content-sm-between text-center">

                                    <div class="  ms-md-3  mb-sm-0  mb-2" >
                                    </div>
                                <div class="p-input-filled me-md-3 ">
                                <span class="p-input-icon-left">
                                    <i class="pi pi-search"></i>
                                    <input pTooltip="Enter ticket name for search" showDelay="1000" tooltipPosition="left" pInputText type="text" class="form-control" (input)="dt.filterGlobal($event.target.value, 'contains')"
                                        placeholder="Search..." />
                                </span>
                                </div>
                            </div>
                            </div>

                        </ng-template>
                        <ng-template pTemplate="header" class="">
                            <tr>
                                <th class="fw-bold text-center">#</th>
                                <th pSortableColumn="totalAmount" style="min-width:7rem" class="fw-bold text-center">Event <p-sortIcon
                                        field="totalAmount"></p-sortIcon></th>

                                <th class="fw-bold text-center "> Action</th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-ord let-rowIndex="rowIndex" >
                            <tr >
                                <td class="text-md-center padding-custom text-end"><span class="headers-cutom text-start fw-bold ">#</span> {{rowIndex+1}}</td>
                                <td class="text-md-center padding-custom text-lenght-larg text-end"><span class="headers-cutom text-start fw-bold ">Event</span><span class="text-lenght">{{ord.title}}</span></td>


                                <td class="text-md-center padding-custom text-end">
                                    <div class=" action-custom">
                                        <button [disabled]="isSubmitting" *ngIf="ord.canPublish" pButton pRipple  class="rounded-pill add-new-btn mr-2" [ngStyle]="{'background':ord.isPublish? 'rgb(211 47 47 / 92%)'  : 'rgb(4 205 4 / 60%)'}"
                                        (click)="publish(ord.id)"><i class="fa-solid fa-plus"></i>{{ord.isPublish? 'Unpublish':'Publish'}} <span></span></button>

                                    </div>

                                </td>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="summary">
                            <div class="flex align-items-center justify-content-between">

                            </div>
                        </ng-template>

                    </p-table>

                    </div> -->

                    <div class="" *ngIf="!singlePublish" >
                        <p-table #dt responsiveLayout="stack" [breakpoint]="'991px'" [value]="data" [rows]="6" [paginator]="true" [globalFilterFields]="['title']"
                        [tableStyle]="{'max-width':'100%'}" [rowHover]="true" dataKey="id"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                        [showCurrentPageReport]="true" class="">
                        <ng-template pTemplate="caption">

                            <div>

                                <div class="d-sm-flex d-block align-items-center justify-content-sm-between text-center">

                                    <div class="  ms-md-3  mb-sm-0  mb-2" >
                                    </div>
                                <div class="p-input-filled me-md-3 ">
                                <span class="p-input-icon-left">
                                    <i class="pi pi-search"></i>
                                    <input pTooltip="{{'searchPlaceholder'|translate}}" showDelay="1000" tooltipPosition="left" pInputText type="text" class="form-control" (input)="dt.filterGlobal($event.target.value, 'contains')"
                                        placeholder="{{'Search...'|translate}}" />
                                </span>
                                </div>
                            </div>
                            </div>

                        </ng-template>
                        <ng-template pTemplate="header" class="">
                            <tr>
                                <!-- <th class="fw-bold text-center">#</th> -->

                                <th pSortableColumn="totalAmount" style="min-width:7rem" class="fw-bold text-center">{{'PblshTblTh001'|translate}} <p-sortIcon
                                        field="totalAmount"></p-sortIcon></th>

                                <th class="fw-bold text-center "> {{'PblshTblTh002'|translate}}</th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-ord let-rowIndex="rowIndex" >
                            <tr >
                                <!-- <td class="text-md-center padding-custom text-end"><span class="headers-cutom text-start fw-bold ">#</span> {{rowIndex+1}}</td> -->


                                <td class="text-md-start  padding-custom text-end Image-cutom">
                                    <div class="d-flex">
                                        <div class="static-width">
                                            <span class=" text-center row align-items-center d-lg-flex d-none fw-bold me-md-2 ">
                                                <h5 class="text-primary col-12">{{getDateOntomeZoneformat(ord.startDate,'month')}}</h5>
                                                <h5 class=" col-12">{{getDateOntomeZoneformat(ord.startDate,'day')}}</h5>
                                            </span>
                                            <div class="d-lg-flex d-block align-items-center me-lg-2">
                                                <img [src]="ord.photo==null?'assets/img/courses/3SUBiaP.png':url+ord.photo"
                                                    alt="" width="100" class="shadow-4 min-size rounded-2" />
                                            </div>
                                        </div>

                                        <div class="d-lg-block d-none">
                                            <span class="text-lenght d-lg-block"> {{ord.title}}</span>
                                            <span class="text-secondary d-lg-block">{{ord.address}}</span>
                                            <span class="text-secondary">{{getDateOntomeZoneformat(ord.startDate,'full')}}</span>
                                        </div>
                                    </div>
                                </td>

                                <td class="text-md-center padding-custom text-lenght-larg text-end d-lg-none d-flex ">
                                    <span class="headers-cutom text-start fw-bold">{{'Title'|translate}}</span><span
                                        class="text-lenght"> {{ord.title}}</span>
                                </td>
                                <!-- <td class="text-md-center padding-custom text-lenght-larg text-end"><span class="headers-cutom text-start fw-bold ">Event</span><span class="text-lenght">{{ord.title}}</span></td> -->


                                <td class="text-md-center padding-custom text-end">
                                    <div class=" action-custom">
                                        <button [disabled]="isSubmitting" *ngIf="ord.canPublish" pButton pRipple  class="rounded-pill add-new-btn mr-2" [ngStyle]="{'background':ord.isPublish? 'rgb(211 47 47 / 92%)'  : 'rgb(4 205 4 / 60%)'}"
                                        (click)="publish(ord.id)"><i class="fa-solid fa-plus"></i>{{ord.isPublish? ('PblshTblBtn001Title002'|translate):'PblshTblBtn001Title001'|translate}} <span></span></button>
                                    <!-- <button pButton pRipple icon="fa-sharp fa-solid fa-arrow-right-to-line" class="p-button-rounded p-button-primary"
                                        (click)="show(ord)"></button> -->
                                    </div>

                                </td>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="summary">
                            <div class="flex align-items-center justify-content-between">

                            </div>
                        </ng-template>

                    </p-table>

                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


