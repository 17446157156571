import { animate, style, transition, trigger } from '@angular/animations';
import { Component, OnInit,Inject } from '@angular/core';
import { LoaderService } from 'src/app/services/loader.service';
import { UserOrderService, userOrderDto } from './user-order.service';
import { ActivatedRoute, Router } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import { EventsService } from 'src/app/services/events.service';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from 'src/app/services/auth.service';
import * as m from 'moment';
import 'moment/locale/pt-br';

const leaveTrans = transition(':leave', [
  style({
      opacity: 1
  }),
  animate('1s ease-out', style({
      opacity: 0
  }))
])



const fadeOut = trigger('fadeOut', [
  leaveTrans
]);

export interface OrgEventsOrderDto{
  orderNum :string,
  orderId :string,
  orderDate :Date,
  status :number,
  totalAmount :number,
  curSymbol :string,
  eventName :string,
  isRefundable :boolean,
  userName :string,
}
@Component({
  selector: 'app-user-order',
  templateUrl: './user-order.component.html',
  styleUrls: ['./user-order.component.scss'],
  animations:[fadeOut]
})
export class UserOrderComponent implements OnInit {
  data:OrgEventsOrderDto[]=[];
  orgnizerData: any[]=[
    {label:  this.translate.instant('AllUserEvent'),id:'all' , command: (item) => {
      //this.update();
      this.selectedFilter1=item.item.label
      this.filterselected1=item.item.id;
      this.userOrdSrv.getOrgEventsOrder(this.filterselected1)
  }   }
  ];
  selectedFilter1: string='';
  filterselected1: string='';
  updateLang(){
    this.eventService.GetOrganizersByUser().subscribe((x) => {
      console.log(x)
      this.orgnizerData=[
        {label:  this.translate.instant('AllOrderEvent001'),id:'' , command: (item) => {
          //this.update();
          this.selectedFilter1=item.item.label
          this.filterselected1=item.item.id;
          this.userOrdSrv.getOrgEventsOrder(this.filterselected1)
         
      }   }
      ];
      x.forEach(y=>{
        var t={label:y.name, id:y.id, command: (item) => {
          this.selectedFilter1=item.item.label;
          this.filterselected1=item.item.id;
          this.userOrdSrv.getOrgEventsOrder(this.filterselected1)
          //this.update();
        }}
        this.orgnizerData.push(t)
      })
      // console.log(x);
      // this.orgnizerData = x;
      // this.orgnizerData.push({})
      
      
       });
    
    this.orgnizerData=[
      {label:  this.translate.instant('AllOrderEvent001'),id:'' , command: (item) => {
        //this.update();
        this.selectedFilter1=item.item.label
        this.filterselected1=item.item.id;
        this.userOrdSrv.getOrgEventsOrder(this.filterselected1)
     
    }   }
    ];
}

  constructor(  public eventService: EventsService,
     @Inject(DOCUMENT) public document: Document,
     public loader : LoaderService,
     private userOrdSrv:UserOrderService
     ,private router:Router 
     ,private ActiveRout:ActivatedRoute ,private translate:TranslateService,public auth: AuthService){
      
      this.translate.onLangChange.subscribe(() => {
        this.updateLang();
        m.locale(this.translate.currentLang);
      })
     }
 
 
 
  ngOnInit(): void {
    this.loader.showLoader1();
    this.updateLang();
    this.userOrdSrv.getOrgEventsOrder();
  //this.userOrdSrv.getUserOrder();
  // this.userOrdSrv.$getSubject.subscribe(x=>{
  //   this.loader.hideLoader1();
  //   this.data=x;
  //   //console.log(x)
  // })

  this.userOrdSrv.$getOrgEventOrderSubject2.subscribe(x=>{
    this.loader.hideLoader1();
     this.data=x;
  })
  }
  getDate(x:Date){
    var t= new Date(x);

    return t.toLocaleString();
  }
  show(s){
this.router.navigate(['preview/',s.orderId],{relativeTo:this.ActiveRout})

  }

  getDateOntomeZoneformat2(d){
    m.locale();
      let t = new Date(Date.now()).getTimezoneOffset();
      let MyDate = new Date(d).setMinutes(new Date(d).getMinutes() - (t));
      var l="";
    if(new Date(MyDate).getFullYear()==new Date(Date.now()).getFullYear()){
          l= m(new Date(MyDate)).format('ddd MMMM D hh:mm a');
      }else{
         l=  m(new Date(MyDate)).format( 'ddd MMMM D,y hh:mm a');
      }
      return l
     // return new Date(MyDate).toLocaleString();
    }

}
