import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { EventCard } from '../../pages/landing-page/landing-page.service';
import { DatePipe } from '@angular/common';
import { EventStepsService } from 'src/app/services/event-steps.service';
import { TranslateService } from '@ngx-translate/core';

import * as m from 'moment';
import 'moment/locale/pt-br';

@Component({
  selector: 'app-desing-co',
  templateUrl: './desing-co.component.html',
  styleUrls: ['./desing-co.component.scss']
})
export class DesingCoComponent implements OnInit {
  @Input() item: any;
  @Input() cols:any;
  @Input()  AttendeesCol:any;
  @Output() update : EventEmitter<any[]> = new EventEmitter(); 
  @Output() Canceld : EventEmitter<any[]> = new EventEmitter(); 
   constructor(  private datePipe: DatePipe, public EventSrv: EventStepsService, private translate:TranslateService){
    this.translate.onLangChange.subscribe(() => {

      m.locale(this.translate.currentLang)
    });
   }
  ngOnInit(): void {
    // console.log(this.AttendeesCol)
    // console.log(this.item)
  }
    

  checkpropName(s:string):boolean{
  var l=  this.cols.find(x=>x.field==s);
    if(l==undefined){
      return false
    }else{
      return true
    }
  }
  edit(s,i) {

    this.update.emit([s,i])
  //     console.log(s);
  //      var orderNumindex = s.SortId.indexOf('-');
  //      var orderNum = s.SortId.substring(0, orderNumindex) as number;
  //      var ticket = s.SortId.substring(orderNumindex + 1);
  //     console.log(orderNum);
  //     console.log(ticket);
  //      var t = this.dataFromDb.find((x) => x.orderNum == orderNum).tickets;
  //      var selectedtickets = t.find((x) => x.name == ticket).attendees;
  //      var mmm = JSON.parse(selectedtickets);
  //      this.editeSrv.myticket = mmm;
  //      this.editeSrv.attendeesnum = s['Attendee #'];
  //      this.editeSrv.ticketID = t.find((x) => x.name == ticket).id;
  //     console.log(this.editeSrv.attendeesnum);
  //     console.log(t);
  //     console.log(selectedtickets);
  //     console.log(mmm);
  //      this.router.navigate(['Edit-attendee', this.editeSrv.attendeesnum], {
  //          relativeTo: this.activatedRoute.parent,
  //      });
   }
   canseld(s,i) {
    this.Canceld.emit([s,i])
      //  var url = environment.Api + 'd/At/c/a';
      //  var orderNumindex = s.SortId.indexOf('-');
      //  var orderNum = s.SortId.substring(0, orderNumindex) as number;
      //  var ticket = s.SortId.substring(orderNumindex + 1);
      //  var t = this.dataFromDb.find((x) => x.orderNum == orderNum).tickets;
      //  var selectedtickets = t.find((x) => x.name == ticket).attendees;
      //  var mmm = JSON.parse(selectedtickets);
      //  var l = mmm.order.find((x) => x.attendeesNum == s['Attendee #']);
      //  l.status = 'canceled';
      //  var f = {
      //      ticket: t.find((x) => x.name == ticket).id,
      //      order: JSON.stringify(mmm),
      //  };
      //  this.http.post(url, f).subscribe((x) => {
      //      this.datatt = [];
      //      this.DataForReport=[];
      //      this.GetDataFromDb();
      //  });
   }
   getDateOntomeZoneformat(d,x){
    m.locale()
    let t = new Date(Date.now()).getTimezoneOffset();
    let MyDate = new Date(d).setMinutes(new Date(d).getMinutes() - (t));
    var l="";
    switch(x){
      case 'full':if(new Date(MyDate).getFullYear()==new Date(Date.now()).getFullYear()){
        l= m(new Date(MyDate)).format('ddd MMMM D hh:mm a')
        //this.datePipe.transform(new Date(MyDate), 'EEE MMMM d hh:mm a');

    }else{
       l= m(new Date(MyDate)).format( 'ddd MMMM D,y hh:mm a');
       //this.datePipe.transform(new Date(MyDate), 'EEE MMMM d,y hh:mm a');

    }
    break;
    case 'month': l=m(new Date(MyDate)).format('MMM');
    //this.datePipe.transform(new Date(MyDate), 'MMM');
    break;
    case 'day':l=m(new Date(MyDate)).format('D')
    //this.datePipe.transform(new Date(MyDate), 'dd');
    break
    }
    return l




   // return new Date(MyDate).toLocaleString();
}
}
