<div class="events-area py-3">
    <div #myDiv class="container">
        <div class="card custom-table-border ">
            <div class="card-header border-0 text-center bg-light rounded-top-23 ">
                <div class="row">
                    <div class=" col-12  " >
                        <div class="row">
                            <div class="col-md-6 col-12 text-start ">
                        <h3 class="text-primary font-s">{{'OrderRepCo001'|translate}} <span>{{item['Order #']}}</span></h3>
                            </div>
                            <div class="col-md-6 col-12 text-md-end text-start ">
                                <h3 class="text-primary font-s "><i class="bx bx-calendar text-primary"></i>
                                    <span>{{getDateOntomeZoneformat(item['Order Date'],'full')}}</span></h3>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

         
            <div class="row p-3 ">
              <div class="col-md-6 col-12">
                <h6>{{'Orderrepco002'|translate}} <span>{{item['Ticket type']}}</span></h6>
                <h6>{{'Orderrepco004'|translate}} <span>{{item['Quantity']}}</span></h6>
                <h6>{{'Orderrepco005'|translate}} <span>{{item['Total']}}</span></h6>
                <h6>{{'ordertype01'|translate}} <span>{{item['ordertype'] |translate}}</span></h6>
                <h6>{{'Orderrepco003'|translate}} <span>{{item['Attendees Count']}}</span></h6>
                <!-- <h6>Order Space 2 <span>Hasan</span></h6>
                <h6>Order Space 3<span>Hasan</span></h6>
                <h6>Order Space 4<span>Hasan</span></h6> -->
              </div>
              <div class="col-md-6 col-12 text-end">
                <img [src]='item.Qr!=null?url+item.Qr:"../../assets/img/about-img1.png"' alt="Image" style="max-width: 100%;">
              </div>
            </div>
            <div class="row p-3">

                <div class="col-12">
                    <div class=" col-12 p-0 pointer " [ngClass]="{'card-base':item.Atendees.length>2}">
                        <div class=" py-1" *ngFor="let i of item.Atendees ">
                            <div class="ticket">
                                <div class="row align-items-center">
                                    <div class="col-lg-8 col-md-8 col-12 ">
                                        <div class="row">
                                    <div class="col-lg-6 col-md-6 col-12 text-primary" >{{'Orderrepco014'|translate}} <span>{{i['Attendee #']}}</span></div>
                                    <div class="col-lg-6 col-md-6 col-12 text-primary">{{'Orderrepco015'|translate}}<span>{{i['Attendee Status']}}</span></div>
                                </div>
                                <div class="row">
                                    <div class="col-lg-6 col-md-6 col-12" *ngFor="let m of AttendeesCol ">
                                       {{(m.header|translate)+" : "}}<span>{{i[m.field]}}</span>
                                    </div>
                                   </div>
                                </div>
                                
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

