

<div class="container">
    <div class="card custom-table-border">
        <div class="card-header border-0 text-center bg-primary rounded-top-23 ">
            <h1 class="fw-bold text-white mt-2">{{'PrmoTblTitle'|translate}}</h1>
            </div>
        <div class="card-body">
            <div class="card-body p-0">
                <div class="container">
                    <div class="d-flex justify-content-between align-items-center">
                        <h2 class="fw-bold">{{'PrmoFrmTitl'|translate}}</h2>

                <div>
                    <h6 class="fw-bold  ">{{'Help'|translate}} <span class="text-primary help-btn" (click)="showHelpStepsModal()" ><i class="fa-regular fa-circle-question"></i></span></h6>
                    <p-dialog header="How to start" [(visible)]="helpStepsModal" [modal]="true" class="bg-dark"
                        [draggable]="false" [resizable]="false">
                        <p class="m-0"><b class="text-primary">{{'Step1'|translate}}  </b> {{'promoformstep1tooltip'|translate}} </p>
                        <p class="m-0"><b class="text-primary">{{'Step2'|translate}} </b> {{'promoformstep2tooltip'|translate}} </p>
                        <p class="m-0"><b class="text-primary">{{'Step3'|translate}}  </b>  {{'promoformstep3tooltip'|translate}}</p>
                        <!-- <p class="m-0"><b class="text-primary">{{'Step4'|translate}}  </b>  {{'promoformstep4tooltip'|translate}}</p>
                        <p class="m-0"><b class="text-primary">Step 5: </b> Lorem ipsum dolor sit amet consectetur adipisicing elit. Rerum, similique voluptates aperiam quas facilis voluptas. Autem, ab amet nam error, nulla placeat excepturi facere numquam accusantium natus pariatur sint ex.</p> -->
                            <ng-template pTemplate="footer">
                            <p-button icon="pi pi-check" (click)="helpStepsModal=false" label="Ok" styleClass="p-button-text"></p-button>
                            </ng-template>
                    </p-dialog>
                </div>

                    </div>
                    <div class="row">
                        <div class="col-12 p-0">
                            <p-steps  [model]="items" [readonly]="true"></p-steps>

                        </div>
                        <div class="col-12 p-0">
                            <router-outlet></router-outlet>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>

</div>















