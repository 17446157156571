<form [formGroup]="questionForm" autocomplete="off">
    <div class="row mb-1">
        <div class="p-input-filled col-md-6 col-12 col-xs-12 mb-3">
            <span class="p-float-label">
                <input class="form-control" formControlName="question" id="Question" pInputText
                       placeholder="Question prompt" type="text"/>
                <label class="font-size-14" for="Question">{{'QstionFrmInpt001'|translate}}</label>
            </span>
        </div>



        <div class="p-input-filled col-md-6 col-12 col-xs-12 mb-3">
            <span class="p-float-label">
                <p-dropdown [options]="inputTypes" [showClear]="true" [filter]="true" [styleClass]="'w-100'"
                            formControlName="type" inputId="type" optionLabel="name" optionValue="code"
                            placeholder="Select Input Type" appendTo="body">
                </p-dropdown>
                <label class="font-size-14" for="type">{{'QstionFrmSlct001'|translate}}</label>
            </span>
        </div>
        <div *ngIf="hasOptions">
<div class="d-lg-flex align-items-center">
    <div class=" p-input-filled col-lg-8 col-12 ps-lg-0 pe-lg-2 px-0 text-md-start text-center">
        <span class="p-float-label">
            <input pInputText type="text" class="form-control" #Ahmad/>
            <label>{{'QstionFrmSlct001ChoisInpt001'|translate}}</label>
        </span>

    </div>
    <button style="height:50px;" class="btn save-btn rounded-pill col-lg-4 col-12" (click)="addOption(Ahmad.value)"
                type="button">{{'QstionFrmSlct001ChoisInpt001Btn'|translate}} <i class="fa-solid fa-plus"></i><span></span></button>
</div>


            <div class="d-inline-block  col-md-11 col-12" formArrayName="options">
                <button pButton type="button" class="p-button-raised p-button-rounded mx-1 my-1"
                *ngFor="let option of optionsForms.controls; let i = index" [formGroupName]="i">
                {{option.value.text }}
                    <span class="tagCancel ms-1" (click)="deleteOption(i)">
                        <i class="fa-solid fa-circle-xmark"></i></span>
                </button>
            </div>
            <!-- <div formArrayName="options">
                <div *ngFor="let option of optionsForms.controls; let i = index" [formGroupName]="i">
                    <div class="row align-items-center   my-4">
                        <div class="col-md-5 col-12 p-input-filled">
                            <span class="p-float-label">
                               {{option.value.text }}
                            </span>
                        </div>

                        <div class="col-md-2 col-12  text-center">
                            <button class="btn btn-danger rounded-5 " (click)="deleteOption(i)"
                                    type="button"><i class="fa-regular fa-trash-can"></i></button>
                        </div>
                    </div>



                </div>
            </div> -->

        </div>
        <div *ngIf="isTerm">
            <div class="row align-items-center my-4">
                <div class="p-input-filled col-md-12 col-12 col-xs-12 mb-3">
                     <span class="p-float-label">
                         <textarea class="form-control" formControlName="answer" id="answer" pInputText
                                    type="text"></textarea>
                         <label class="font-size-14" for="answer">{{'QstionFrmSlct001ChoisInpt002'|translate}}</label>
                     </span>
                </div>
            </div>
        </div>
        <div class="form-group col-md-12 col-12 col-xs-12 mb-3">
            <div class="row">
                <div class="col-12 col-md-6 d-flex justify-content-end d-none">
                    <label class="font-size-18 me-1" for="aaa">
                        Limit quantity for each option
                    </label>
                    <div class="form-check mb-1">
                        <div class="square-switch">
                            <input checked="" id="aaa" switch="none" type="checkbox"/>
                            <label for="aaa"></label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="form-group col-md-12 col-12 col-xs-12 mb-3 optionsHide d-none">
            <div class="row">
                <div class="col-12" id="OptContainer">
                    <div class="row my-1" id="row1">
                        <div class="col-9 col-lg-10 d-flex">
                            <input class="form-control me-1" id="optionText1" name="optionText1" placeholder="text"/>
                            <input class="form-control" id="optionValue1" name="optionValue1" placeholder="value"/>
                        </div>
                        <div class="col-3 col-lg-2">
                            <a href="javascript:;" onclick="moveUp1(1)">
                                <i class="fas fa-arrow-up me-1"></i>
                            </a>
                            <a href="javascript:;" onclick="moveDown1(1)">
                                <i class="fas fa-arrow-down me-1"></i>
                            </a>
                        </div>
                    </div>

                    <div class="row my-1" id="row2">
                        <div class="col-9 col-lg-10 d-flex">
                            <input class="form-control me-1" id="optionText2" name="optionText2"
                                   oninput="handleChange(this, 2)" placeholder="text"/>
                            <input class="form-control" id="optionValue2" name="optionValue2"
                                   oninput="handleChange(this, 2)" placeholder="value"/>
                        </div>
                        <div class="col-3 col-lg-2">
                            <a href="javascript:;" onclick="moveUp1(2)">
                                <i class="fas fa-arrow-up me-1"></i>
                            </a>
                            <a href="javascript:;" onclick="moveDown1(2)">
                                <i class="fas fa-arrow-down me-1"></i>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- <div class="form-group col-md-12 col-12 col-xs-12 mb-3 optionsHide d-none">
            <div class="row">
                <div class="col-12 d-flex justify-content-between">
                    <a class="btn btn-outline-primary w-100" onclick="AddOption()">Add answer</a>
                </div>
            </div>
        </div> -->

        <!-- <div class="form-group col-md-12 col-12 col-xs-12 mb-3 d-flex">
            <label class="font-size-18 me-1" for="IsRequired">Is required</label>

            <div class="form-check mb-1">
                <div class="square-switch">
                    <p-inputSwitch formControlName="isRequired" inputId="IsRequired"></p-inputSwitch>
                </div>
            </div>
        </div> -->
        <!-- <div class="form-group col-md-12 col-12 col-xs-12 mb-3 d-flex">
        </div> -->
        <div class="row mb-1">
            <div class="col-md-3 col-12">
                <small class="p-error" *ngIf="submitted&&questionForm.get('question').invalid">{{'QstionFrmInpt001ErrMsg'|translate}}</small>
                <small class="p-error" *ngIf="submitted&&questionForm.get('type').invalid"> {{'QstionFrmSlct001ErrMsg001'|translate}}</small>
                <small class="p-error" *ngIf="submitted&&questionForm.get('options').invalid"> {{'QstionFrmSlct001ErrMsg002'|translate}}</small>
                <small class="p-error" *ngIf="submitted&&questionForm.get('answer').invalid">{{'QstionFrmSlct001ErrMsg003'|translate}}</small>
            </div>
        </div>
    </div>

    <div class="row mb-1 justify-content-end">
        <div class="col-md-3 col-12">
            <a class="btn btn-primary w-100" (click)="onAddClicked(edit)"> {{ edit? 'SavBtn': 'SavBtn' |translate }} </a>
        </div>
    </div>
</form>

