import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, ReplaySubject, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { OrgEventsOrderDto } from './user-order.component';
export interface userOrderDto {
  orderId: string
  totalAmount: number
  orderDate: string
  eventName: string
  curSymbol: string
  status: number
  isRefundable: boolean
  isUsedPromo: boolean
}

export interface eventOrderDto {
  orderId: string;
  totalAmount: number;
  orderDate: string;
  name: string;
  quantity: number;
  orderNum:string;
 
}
export interface eventOrderallDto {
  total: number;
 order:eventOrderDto[];
 
}
export interface ticketstype
        {
            name  :string   ;
            totalQty:number ;
            price :number   ;
            qty:number;
        }


export interface UserOrderInstance {
  orderId?: string
  totalAmount?: number
  orderDate?: Date
  eventName?: string
  eventPhoto?: string
  curSymbol?: string
  status?: number
  isRefundable?: boolean
  isUsedPromo?: boolean
  addons?: Addon[]
  tickets?: Ticket[]
}

export interface Addon {
  name?: string
  qty?: number
  finalPrice?: number
  deliveryMethods?: string
  photo?: string
}

export interface Ticket {
  name?: string
  qty?: number
  finalPrice?: number
  deliveryMethods?: string
}

@Injectable({
  providedIn: 'root'
})
export class UserOrderService {
  private subject: Subject<userOrderDto[]> = new ReplaySubject<userOrderDto[]>(1);

  get $getSubject(): Observable<any> {
    return this.subject.asObservable();
  }

  private eventOrdersubject: Subject<eventOrderallDto> = new ReplaySubject<eventOrderallDto>(1);
 private  OrgEventOrderSubject:Subject<OrgEventsOrderDto[]>=new ReplaySubject<OrgEventsOrderDto[]>(1)
  get $getSubject2(): Observable<any> {
    return this.eventOrdersubject.asObservable();
  }
  get $getOrgEventOrderSubject2(): Observable<OrgEventsOrderDto[]> {
    return this.OrgEventOrderSubject.asObservable();
  }

  constructor( private http:HttpClient) { 

  }
  getUserOrder(){
    this.http.get<userOrderDto[]>(environment.Api+'d/or/g/u/o/all').subscribe(x=>{this.subject.next(x)})
  }

  getOrgEventsOrder(Id=""){
    this.http.get<OrgEventsOrderDto[]>(environment.Api+'d/or/g/e/o/a/'+Id).subscribe(x=>{this.OrgEventOrderSubject.next(x)})
  }

  getUserOrderPrivew(id:string){
     return this.http.get<UserOrderInstance>(environment.Api+'d/or/g/u/o/i/'+id)
  }

  getEventOrder(Id:string,skip:number=0){
    return this.http.get<eventOrderallDto>(environment.Api+'d/or/g/e/or/'+Id+'/'+skip).subscribe(x=>{

      this.eventOrdersubject.next(x)
    })

  
  }
  geteventTickettypeSales(id:string){
    return this.http.get<ticketstype[]>(environment.Api+'d/or/g/e/t/ty/'+id)
  }

  postemail(id){
    const url = environment.Api + 'd/or/g/u/Waitlist/i';
    var data={id:id}
   return this.http.post(url,data);
  }

}
