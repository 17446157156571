<form [formGroup]="srv.AddonsForm" (submit)="submit()" autocomplete="off">

    <div class="">

        <div class="row mb-2">
            <span class="text-start">{{'AddOnFrmStngsTitl001'|translate}}</span>
            <div class="p-input-filled col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6 mb-2">
                <span [classList]="'p-float-label '">
                    <p-inputNumber pTooltip="{{'AddOnFrmStngsTitl001Inpt001Pop'|translate}}" showDelay="1000"
                        tooltipPosition="bottom" inputId="inputnumber3" [styleClass]="'w-100'"
                        class="form-control m-0 p-0" [inputStyleClass]="'form-control'" formControlName="minPerOrder"
                        [ngClass]="{
                            'ng-invalid ng-dirty':
                                (srv.AddonsForm.get('minPerOrder')?.touched &&
                                srv.AddonsForm.get('minPerOrder')?.invalid)||(submitted&& srv.AddonsForm.get('minPerOrder').invalid)
                        }"></p-inputNumber>
                    <label for="inputnumber3">{{'AddOnFrmStngsTitl001Inpt001'|translate}}</label>
                </span>
                <small class="p-error" *ngIf="(srv.AddonsForm.get('minPerOrder')?.touched &&
                srv.AddonsForm.get('minPerOrder')?.invalid)||(submitted&& srv.AddonsForm.get('minPerOrder').invalid)
                    ">{{'AddOnFrmStngsTitl001Inpt001ErrMsg'|translate}}</small>
            </div>
            <div class="p-input-filled col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6 mb-2">
                <span class="p-float-label">
                    <p-inputNumber pTooltip="{{'AddOnFrmStngsTitl001Inpt002'|translate}}" showDelay="1000"
                        tooltipPosition="bottom" inputId="inputnumber4" [styleClass]="'w-100'"
                        class="form-control m-0 p-0" [inputStyleClass]="'form-control'" formControlName="maxPerOrder"
                        [ngClass]="{
                            'ng-invalid ng-dirty':
                                (srv.AddonsForm.get('maxPerOrder')?.touched &&
                                srv.AddonsForm.get('maxPerOrder')?.invalid)||(submitted&& srv.AddonsForm.get('maxPerOrder').invalid)
                        }"></p-inputNumber>
                    <label for="inputnumber4">{{'AddOnFrmStngsTitl001Inpt002'|translate}}r</label>
                </span>
                <small class="p-error" *ngIf="(srv.AddonsForm.get('maxPerOrder')?.touched &&
                srv.AddonsForm.get('maxPerOrder')?.hasError('required')  )||(submitted&& srv.AddonsForm.get('maxPerOrder').invalid)
                    ">{{'AddOnFrmStngsTitl001Inpt002ErrMsg001'|translate}}</small>
                <small class="p-error" *ngIf="(srv.AddonsForm.get('maxPerOrder')?.hasError('isValid'))
                    ">{{'AddOnFrmStngsTitl001Inpt002ErrMsg002'|translate}}</small>
                <small class="p-error" *ngIf="(srv.AddonsForm.get('maxPerOrder')?.hasError('isMatched') && !srv.AddonsForm.get('maxPerOrder')?.hasError('required') )
                    ">{{'AddOnFrmStngsTitl001Inpt002ErrMsg003'|translate}}</small>

            </div>
        </div>
        <div class=" row mb-2">
            <div class="p-input-filled col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6 mb-2">
                <label class="b-5 ms-2" for="RecTicketConf">{{'AddOnFrmStngsTitl002'|translate}}</label>
                <p-dropdown pTooltip="{{'AddOnFrmStngsTitl002Pop'|translate}}" showDelay="1000" tooltipPosition="bottom"
                    inputId="RecTicketConf" [styleClass]="'w-100'" [autoDisplayFirst]="false" [options]="visibilty"
                    optionLabel="name" optionValue="code" formControlName="hidden" [ngClass]="{
                        'ng-invalid ng-dirty':
                            (srv.AddonsForm.get('hidden')?.touched &&
                            srv.AddonsForm.get('hidden')?.invalid)||(submitted&& srv.AddonsForm.get('hidden').invalid)
                    }"></p-dropdown>
                <small class="p-error" *ngIf="(srv.AddonsForm.get('hidden')?.touched &&
                    srv.AddonsForm.get('hidden')?.invalid)||(submitted&& srv.AddonsForm.get('hidden').invalid)
                        ">Please add Visibilty</small>
            </div>
            <div class="p-input-filled col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6 mb-2">
                <label class="b-5 ms-2" for="RecTicketConf">{{'AddOnFrmStngsTitl003'|translate}}</label>
                <div class="row mt-md-2">
                    <div class="col-6">

                        <p-inputSwitch inputId="Etic" ariaLabelledBy="lab1" formControlName="eTicket"
                            pTooltip="{{'AddOnFrmStngsTitl003Swtch001Pop'|translate}}" showDelay="1000"
                            tooltipPosition="bottom"></p-inputSwitch>
                        <label class="form-check-label b-5 ms-2" id="lab1"
                            for="Etic">{{'AddOnFrmStngsTitl003Swtch001'|translate}}</label>
                    </div>
                    <div class="col-6 ">

                        <p-inputSwitch inputId="wiltic" formControlName="willCall"
                            pTooltip="{{'AddOnFrmStngsTitl003Swtch002Pop'|translate}}" showDelay="1000"
                            tooltipPosition="bottom" ariaLabelledBy="lab2"></p-inputSwitch>
                        <label class=" form-check-label b-5 ms-2" id="lab2"
                            for="wiltic">{{'AddOnFrmStngsTitl003Swtch002'|translate}}</label>
                    </div>

                </div>
                <small class="p-error" *ngIf="(submitted&& srv.AddonsForm.invalid)
                       ">{{'AddOnFrmStngsTitl003ErrMsg'|translate}}</small>

            </div>
        </div>
    </div>
    <div class="col-12 d-flex  justify-content-center">
        <div class="banner-btn rounded-pill mx-1">

            <button pTooltip="Go back to previous step" type="button" showDelay="1000" tooltipPosition="left"
                class="rounded-pill cancel-btn" (click)="previous()"><i class="pi mt-1"
                    [class.pi-angle-right]="document.documentElement.dir === 'rtl'"
                    [class.pi-angle-left]="document.documentElement.dir === 'ltr'"></i> {{'PrviosBtn'|translate}}
                <span></span></button>
            <button pTooltip="Cancel and dismiss changes" type="button" showDelay="1000" tooltipPosition="bottom"
                class="rounded-pill save-btn ms-1" (click)="cancel()">{{'CanclBtn'|translate}} <span></span></button>
            <button pTooltip="Save and finish" showDelay="1000" tooltipPosition="bottom"
                class="rounded-pill save-btn  ms-1 mt-md-0 mt-1">{{'FnshBtn'|translate}} <span></span></button>
        </div>
    </div>


</form>