import { animate, style, transition, trigger } from '@angular/animations';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MessageService } from 'primeng/api';
import { WatlistDto } from 'src/app/Interfaces/EventData';
import { EventStepsService } from 'src/app/services/event-steps.service';
import { EventsService } from 'src/app/services/events.service';
import { LoaderService } from 'src/app/services/loader.service';

const leaveTrans = transition(':leave', [
    style({
        opacity: 1
    }),
    animate('1s ease-out', style({
        opacity: 0
    }))
])



const fadeOut = trigger('fadeOut', [
    leaveTrans
]);
@Component({
  selector: 'app-wait-list',
  templateUrl: './wait-list.component.html',
  styleUrls: ['./wait-list.component.scss'],
  animations : [fadeOut]
})
export class WaitListComponent implements OnInit{
// checked: boolean = false;
// mySwitchValue:boolean = true;
formGroup!: FormGroup;
 constructor(private Fb:FormBuilder,public loader : LoaderService,
    private srv : EventStepsService,private EventPostSrv:EventsService,
    private messageService: MessageService ){

 }
 initialform(id:string,eventId:string,maxNumber:number,hour:number,minute:number,second:number,respons:string,
    confirm:string,apology:string,useEmail:boolean,useFirstname:boolean,useNumberPhone:boolean,active:boolean){
    this.formGroup = this.Fb.group ({
        id             :[id],
        eventId        :[eventId],
        maxnumber      :[maxNumber      ],
        maxHours       :[hour       ],
        maxSeconde     :[second     ],
        maxMinute      :[minute      ],
        responseMessage:[respons],
        confirmMessage :[confirm ],
        apologyMessage :[apology ],
        useNumberPhone :[useNumberPhone ],
        useFirstname   :[useFirstname   ],
        useEmail       :[useEmail       ],
        active         :[active         ]
    });
 }
ngOnInit(): void {
  
    this.initialform("0","0",0,0,0,0,"","","",true,true,false,false);
    this.srv.state$.subscribe(x =>{
        this.initialform("0",x.id,0,0,0,0,"","","",true,true,false,false);
      this.getData(x.id);
      
    });
}
getData(id:string){
    this.EventPostSrv.getEventWaitListDetails(id).subscribe(y=>{
      //  console.log(y);
        if(y!=null){
         this.initialform(y.id,y.eventId,y.maxnumber,y.maxHours,y.maxMinute,y.maxSeconde,y.responseMessage,y.confirmMessage,y.apologyMessage,y.useEmail,y.useFirstname,y.useNumberPhone,y.active);
        }else{
            this.initialform("0",id,0,0,0,0,"","","",true,true,false,false);
        }
     })
}

onSubmit(){
  //  console.log(this.formGroup.value)
    const data = this.formGroup.value as WatlistDto;
    this.EventPostSrv.postEventWaitlist(data).subscribe(x=>{
       this.getData(data.eventId);
       this.messageService.add({ severity: 'success', summary: 'Saved Successfully' });
    })

}
}

