import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AddOnsStepsService } from 'src/app/services/add-ons-steps.service';
import { AddOnsService } from 'src/app/services/add-ons.service';
import { EventStepsService } from 'src/app/services/event-steps.service';

@Component({
  selector: 'app-addon-form',
  templateUrl: './addon-form.component.html',
  styleUrls: ['./addon-form.component.scss']
})
export class AddonFormComponent implements OnInit {
   pageTitle:string="";
  helpStepsModal: boolean;
  position: string;
  showHelpStepsModal() {
    this.helpStepsModal = true;
  }

  items = [
    { label: 'Initial', icon: 'pi pi-fw pi-home', routerLink: ['initial']  },
    { label: 'Info', icon: 'pi pi-fw pi-calendar', routerLink: ['info'] },
    { label: 'Sales date', icon: 'pi pi-fw pi-calendar', routerLink: ['sales-date']  },
    { label: 'Settings', icon: 'pi pi-fw pi-calendar', routerLink: ['settings']  }



  ];
  constructor(private router: Router, private translate: TranslateService,private activatedRoute: ActivatedRoute, private eventStepsSrv: EventStepsService, private addOnsFormSrv: AddOnsStepsService, private AddOnsSrv: AddOnsService) {


    this.translate.onLangChange.subscribe(() => {
      this.updateTranslations();
      this.updateTranslations2();
    });
  }
  updateTranslations() {
    this.items = [
      { label:  this.translate.instant('AddOnFrmInfoTitl'), icon: 'pi pi-fw pi-calendar', routerLink: ['info'] },
      { label:  this.translate.instant('AddOnFrmSlsDateTitle'), icon: 'pi pi-fw pi-calendar', routerLink:  ['sales-date'] },
      { label: this.translate.instant('AddOnFrmStngsTitl'), icon: 'pi pi-fw pi-calendar', routerLink:  ['settings']  }
    ];
    
  }
  updateTranslations2() {
    this.items = [
      { label: this.translate.instant('AddOnFrmIntialTitle'), icon: 'pi pi-fw pi-home', routerLink: ['initial']  },
      { label:  this.translate.instant('AddOnFrmInfoTitl'), icon: 'pi pi-fw pi-calendar', routerLink: ['info'] },
      { label:  this.translate.instant('AddOnFrmSlsDateTitle'), icon: 'pi pi-fw pi-calendar', routerLink:  ['sales-date'] },
      { label: this.translate.instant('AddOnFrmStngsTitl'), icon: 'pi pi-fw pi-calendar', routerLink:  ['settings']  }
    ];
    
  }
  ngOnInit(): void {
    this.AddOnsSrv.getEventAddOnsGroup(this.eventStepsSrv.id).subscribe(x => {

      if (x.length != 0) {
        // this.items = [
        //   { label: 'Initial', icon: 'pi pi-fw pi-home', routerLink: ['initial']  },
        //   { label: 'Info', icon: 'pi pi-fw pi-calendar', routerLink: ['info'] },
        //   { label: 'Sales date', icon: 'pi pi-fw pi-calendar', routerLink:  ['sales-date'] },
        //   { label: 'Settings', icon: 'pi pi-fw pi-calendar', routerLink:  ['settings']  }
        // ];
        this.updateTranslations2();
        this.addOnsFormSrv.AddonsForm.controls['eventId'].setValue(this.eventStepsSrv.id)

        this.addOnsFormSrv.Isinitail=true;
        this.router.navigate(['initial'] , { relativeTo: this.activatedRoute });
      }else{
        // this.items = [
        //   { label: 'Info', icon: 'pi pi-fw pi-calendar', routerLink: ['info']  },
        //   { label: 'Sales date', icon: 'pi pi-fw pi-calendar', routerLink: ['sales-date']  },
        //   { label: 'Settings', icon: 'pi pi-fw pi-calendar', routerLink:  ['settings']  }
        // ];
        this.updateTranslations();
        this.addOnsFormSrv.AddonsForm.controls['eventId'].setValue(this.eventStepsSrv.id);
        this.router.navigate( ['info'] , { relativeTo: this.activatedRoute });
      }

      if(this.addOnsFormSrv.AddonsForm.controls['id'].value=="0"){
            //this.pageTitle="Add new add-on"
            this.pageTitle="AddOnFrmTitl001"
      }else{
        //this.pageTitle="Update add-on"
        this.pageTitle="AddOnFrmTitl002"
        
      }
    })

  }
}
