<form [formGroup]="form1" autocomplete="off" class="mt-3">
    <div class="container">
        <div class="card custom-table-border">
           

            <div class="card-header border-0 text-center bg-primary rounded-top-23 ">
                <h1 class="fw-bold text-white mt-2">{{'RsrvOrdrTitl'|translate}}</h1>
            </div>
            <div class="card-body">
                <div class="card-body p-0">
                    <div class="row revers-cols">
                        <div class="col-lg-8 col-12">
                            <div class="row">
                                <div class="p-input-filled col-md-10 col-12 mb-md-3">
                                    <span class="p-float-label">
                                        <input type="text" #myTags id="event-title" pInputText class="form-control"
                                            pTooltip="{{'OrderPromoCodePost'|translate}}" showDelay="1000" tooltipPosition="top"
                                            maxlength="25" />
                                        <label class="font-size-14"
                                            for="event-title">{{'RsrvOrdrInpt001'|translate}}</label>
                                    </span>
                                </div>
                                <div class="col-md-2 col-12 text-md-end text-center mb-sm-0 mb-3">
                                    <button type="button" class="rounded-pill save-btn"
                                        (click)="submitpromo(myTags.value)">
                                        {{'RsrvOrdrInpt001Btn001'|translate}} <span></span>
                                    </button>
                                </div>
                            </div>


                            
                            <div class="row" *ngIf="data.tickets!=null">
                                <div class="col-12">
                                    <h4>{{'RsrvOrdrTitl001'|translate}}</h4>
                                </div>
                                <div class="col-12  p-0" [ngClass]="{'card-base':data.tickets.length>2}">
                                    <div class=" py-3">

                                        

                                        <div *ngFor="let ticket of data.tickets">
                                            <div class="ticket p-0 mx-md-2 mb-3 row d-flex   align-items-center">
                                                <div class="col-sm-6 col-12  ">
                                                    <div class="d-flex align-items-center ps-2">
                                                        <h4 class="w-100 fw-bold me-2">
                                                            {{ticket.name}}
                                                        </h4>
                                                        <h6 class=" h-25 badge rounded-pill text-bg-dark"
                                                            *ngIf="ticket.type=='Donation'">{{ticket.type}}</h6>
                                                    </div>
                                                    <span class="text-primary d-block ps-2"><i
                                                            class="fa-solid fa-calendar-days text-primary"></i>
                                                        {{checkStartDateForItem(getDateOntomeZone1(ticket.ticketStart))?('RsrvOrdrTitl001CrdDsc003'|translate):(checkEndDateForItem(getDateOntomeZone1(ticket.ticketEnd))?('RsrvOrdrTitl001CrdDsc004'|translate):'RsrvOrdrTitl001CrdDsc001'|translate)}}
                                                        {{checkStartDateForItem(getDateOntomeZone1(ticket.ticketStart))?(getDateOntomeZone(ticket.ticketStart)):(checkEndDateForItem(getDateOntomeZone1(ticket.ticketEnd))?'':getDateOntomeZone(ticket.ticketEnd))}}

                                                    </span>
                                                    <span class="d-block ps-2"> {{ticket.type=='Donation'?
                                                        ('RsrvOrdrTitl001CrdDsc005'|translate):'RsrvOrdrTitl001CrdDsc002'|translate}}
                                                        <span [ngClass]="{
                                                        'text-decoration-line-through': ticket.newPrice!=null}">
                                                            {{ticket.price!=0?ticket.price+""+ticket.curSymbol:'RsrvOrdrTitl001CrdDsc002Titl001'|translate}}
                                                        </span>
                                                        <span
                                                            *ngIf="ticket.newPrice!=null">{{ticket.newPrice!=0?ticket.newPrice+"
                                                            "+curSymbol:'RsrvOrdrTitl001CrdDsc002Titl001'
                                                            |translate}}</span> </span>
                                                    <span class="d-block ps-2"> {{ticket.description}}</span>
                                                </div>
                                                <div class="col-sm-6 col-12 mb-2 pt-sm-3 d-flex flex-column justify-content-end align-item-center"
                                                    *ngIf="ticket.type!='Donation'&&!checkAllTiketQty()">
                                                    <div class="p-input-filled col-12 col-md-10 mb-2  border-primary pb-4"
                                                        *ngIf="!checkStartDateForItem(getDateOntomeZone1(ticket.ticketStart))&&!checkEndDateForItem(getDateOntomeZone1(ticket.ticketEnd))">
                                                        <span class="p-float-label">
                                                            <p-dropdown inputId="event-sub-category" [showClear]="true"
                                                                id="event-sub-category" [styleClass]="'w-100'"
                                                                pTooltip="{{'RsrvOrdrTitl001Slct001Pop'|translate}}"
                                                                appendTo="body" showDelay="1000"
                                                                tooltipPosition="bottom" [styleClass]="'w-100'"
                                                                [autoDisplayFirst]="false"
                                                                [options]="getOptions(ticket.minPerOrder,ticket.maxPerOrder)"
                                                                (onChange)="setchekoutTickets($event,ticket)"></p-dropdown>
                                                            <label class="form-label font-size-14"
                                                                for="event-sub-category">{{'RsrvOrdrTitl001Slct001'|translate}}</label>
                                                        </span>
                                                    </div>

                                                    <div class="p-input-filled col-12 col-md-10   border-primary pb-4 "
                                                        *ngIf="haveQty(ticket)">
                                                        <span class="p-float-label">

                                                            <p-dropdown inputId="event-sub-category" [showClear]="true"
                                                                id="event-sub-category" [styleClass]="'w-100'"
                                                                pTooltip="{{'RsrvOrdrTitl001Slct002Pop'|translate}}"
                                                                appendTo="body" showDelay="1000"
                                                                tooltipPosition="bottom" [styleClass]="'w-100'"
                                                                [autoDisplayFirst]="false"
                                                                [options]="getDelveryMethodsOption(ticket)"
                                                                optionLabel="name" optionValue="id"
                                                                (onChange)="setDelverymethods($event,ticket.id)"></p-dropdown>
                                                            <label class="form-label font-size-14"
                                                                for="event-sub-category">{{'RsrvOrdrTitl001Slct002'|translate}}
                                                            </label>
                                                        </span>
                                                    </div>



                                                </div>
                                                <div class="col-sm-6 col-12 mb-2 pt-sm-3  d-flex flex-column justify-content-end algin-item-center"
                                                    *ngIf="ticket.type=='Donation' &&!checkAllTiketQty() &&!checkStartDateForItem(getDateOntomeZone1(ticket.ticketStart))&&!checkEndDateForItem(getDateOntomeZone1(ticket.ticketEnd))">
                                                    <div
                                                        class="p-input-filled col-12 col-md-10 mb-3  border-primary pb-4">
                                                        <span class="p-float-label">
                                                            <div class="p-inputgroup">
                                                                <span class="p-inputgroup-addon "
                                                                    [ngClass]="{' currency-custom-rtl':document.documentElement.dir === 'rtl',
                                                                    ' currency-custom':document.documentElement.dir === 'ltr' }">{{ticket.curSymbol}}</span>
                                                                <span class="p-float-label">
                                                                    <!-- <input type="text" pInputText  /> -->
                                                                    <p-inputNumber inputId="locale-user"
                                                                        pTooltip="{{'RsrvOrdrTitl001Inpt001Pop'|translate}}"
                                                                        showDelay="1000" tooltipPosition="top"
                                                                        [minFractionDigits]="3" class="w-100 "
                                                                        [inputStyleClass]="'form-control'"
                                                                        [min]="ticket.price"
                                                                        (onInput)="SetDonation($event,ticket)"></p-inputNumber>
                                                                    <label class="form-label font-size-14"
                                                                        for="withoutgrouping">

                                                                        {{'RsrvOrdrTitl001Inpt001'|translate}}t

                                                                    </label>
                                                                </span>
                                                            </div>
                                                        </span>
                                                    </div>
                                                    <div class="p-input-filled col-12 col-md-10   border-primary pb-4 "
                                                        *ngIf="haveQty(ticket)">
                                                        <span class="p-float-label">

                                                            <p-dropdown inputId="event-sub-category" [showClear]="true"
                                                                id="event-sub-category" [styleClass]="'w-100'"
                                                                pTooltip="{{'RsrvOrdrTitl001Slct002Pop'|translate}}"
                                                                appendTo="body" showDelay="1000"
                                                                tooltipPosition="bottom" [styleClass]="'w-100'"
                                                                [autoDisplayFirst]="false"
                                                                [options]="getDelveryMethodsOption(ticket)"
                                                                optionLabel="name" optionValue="id"
                                                                (onChange)="setDelverymethods($event,ticket.id)"></p-dropdown>
                                                            <label class="form-label font-size-14"
                                                                for="event-sub-category">{{'RsrvOrdrTitl001Slct002'|translate}}
                                                            </label>
                                                        </span>
                                                    </div>
                                                </div>

                                                <div class="col-sm-6 col-12 mb-2 pt-sm-3  d-flex  justify-content-end align-items-center"
                                                    *ngIf="checkAllTiketQty()">
                                                        <h6 class=" badge rounded-pill text-bg-dark">{{'SoldOut'|translate}}</h6>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row mt-4" *ngIf="data.addOns.length>0">
                                <div class="col-12">
                                    <h4>{{'RsrvOrdrTitl002'|translate}}</h4>
                                </div>
                                <div class="col-12  p-0" [ngClass]="{'card-base2':data.addOns.length>2 || itOpen()}">
                                    <div class="py-3">
                                        <div class="" *ngFor="let Addons of data.addOns;let i=index">
                                            <div class="" *ngIf="Addons.varieties.length>1">
                                                <div
                                                    class="card-2-modified p-0 mx-2 mb-3 row d-flex  justify-content-between ">
                                                    <div class="col-sm-2 col-12 ">
                                                        <img [src]="url+Addons.photo" [alt]="Addons.name" width="100"
                                                            class="shadow-4 rounded-addImage" />
                                                    </div>
                                                    <div class="col-sm-6 col-12  text-start ">
                                                        <h5 class="d-block fw-bold"> {{Addons.name}}</h5>
                                                        <h6 class=" d-block">{{'RsrvOrdrTitl001CrdDsc002'|translate}}
                                                            {{getMinMax(Addons.varieties)}}{{Addons.varieties[0].curSymbol}}
                                                        </h6>

                                                    </div>
                                                    <div
                                                        class="col-sm-4 col-12 mb-2 d-flex justify-content-sm-end justify-content-center align-item-center">

                                                        <button type="button" pButton pRipple
                                                            class="p-button-text p-button-rounded p-button-plain"
                                                            [icon]="
                                                    [i] ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"
                                                            (click)="shown(i)"></button>
                                                    </div>
                                                </div>

                                                <div class=" py-3" *ngIf="show[i]">
                                                    <div *ngFor="let v of Addons.varieties"
                                                        class="card-2-modified p-0 mx-2 mb-3 row d-flex  justify-content-between ">
                                                        <div class="col-sm-2 col-12 ">
                                                            <img [src]="url+v.varietyImage" [alt]="v.name" width="100"
                                                                class="shadow-4 rounded-addImage" />
                                                        </div>
                                                        <div class="col-sm-4 col-12 ">
                                                            <h5 class="d-block fw-bold">{{v.name}}</h5>
                                                            <h6 class="text-primary d-block"><i
                                                                    class="fa-solid fa-calendar-days text-primary"></i>
                                                                {{checkStartDateForItem(getDateOntomeZone1(v.addStart))?('RsrvOrdrTitl001CrdDsc003'|translate):(checkEndDateForItem(getDateOntomeZone1(ticket.ticketEnd))?('RsrvOrdrTitl001CrdDsc004'|translate):"RsrvOrdrTitl001CrdDsc001")|translate}}

                                                                {{checkStartDateForItem(getDateOntomeZone1(v.addStart))?
                                                                    ("Sales start at:"+getDateOntomeZone1(v.addStart)):(checkEndDateForItem(getDateOntomeZone1(v.addEnd))?
                                                                    "Sales End":"Sales end at: " +getDateOntomeZone1(v.addEnd))
                                                            }}
                                                            </h6>
                                                            <h6> {{'RsrvOrdrTitl001CrdDsc002'|translate}}
                                                                <span [ngClass]="{ 'text-decoration-line-through':v.newPrice!=null}">
                                                                    {{v.price!=0?v.price+""+v.curSymbol:'RsrvOrdrTitl001CrdDsc002Titl001'|translate}}
                                                                </span>
                                                                <h6 *ngIf="v.newPrice!=null">
                                                                    {{v.newPrice!=0?v.newPrice+" "+v.curSymbol:'RsrvOrdrTitl001CrdDsc002Titl001'|translate}}
                                                                    {{v.curSymbol}}</h6>
                                                            </h6>


                                                        </div>
                                                        <div
                                                            class="col-sm-6 col-12  d-flex flex-column justify-content-end">
                                                            <div class="p-input-filled col-12 col-md-10 mb-3  border-primary pb-4"
                                                                *ngIf="!checkStartDateForItem(getDateOntomeZone1(v.addStart))&&!checkEndDateForItem(getDateOntomeZone1(v.addEnd))">
                                                                <span class="p-float-label">
                                                                    <p-dropdown inputId="event-sub-category"
                                                                        [showClear]="true" appendTo="body"
                                                                        id="event-sub-category" [styleClass]="'w-100'"
                                                                        pTooltip="number of tickets" showDelay="1000"
                                                                        tooltipPosition="bottom" [styleClass]="'w-100'"
                                                                        [autoDisplayFirst]="false"
                                                                        [options]="getOptions(v.minPerOrder,v.maxPerOrder)"
                                                                        (onChange)="setchekout($event,v)"></p-dropdown>
                                                                    <label class=" form-label font-size-14"
                                                                        for="event-sub-category">{{'RsrvOrdrTitl002Slct001'|translate}}</label>
                                                                </span>
                                                            </div>
                                                            <div class="p-input-filled col-12 col-md-10   border-primary pb-4 "
                                                                *ngIf="haveQty(v)">
                                                                <span class="p-float-label">

                                                                    <p-dropdown inputId="event-sub-category"
                                                                        [showClear]="true" id="event-sub-category"
                                                                        [styleClass]="'w-100'"
                                                                        pTooltip="{{'RsrvOrdrTitl001Slct002Pop'|translate}}"
                                                                        appendTo="body" showDelay="1000"
                                                                        tooltipPosition="bottom" [styleClass]="'w-100'"
                                                                        [autoDisplayFirst]="false"
                                                                        [options]="getDelveryMethodsOption(v)"
                                                                        optionLabel="name" optionValue="id"
                                                                        (onChange)="setDelverymethods($event,v.id)"></p-dropdown>
                                                                    <label class="form-label font-size-14"
                                                                        for="event-sub-category">{{'RsrvOrdrTitl001Slct002'|translate}}
                                                                    </label>
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>






                                            <div class="" *ngIf="Addons.varieties.length==1">
                                                <div
                                                    class="card-2-modified p-0 mx-2 mb-3 row d-flex  justify-content-sm-between ">
                                                    <div class="col-sm-2 col-12 ">
                                                        <img [src]="url+Addons.varieties[0].varietyImage"
                                                            [alt]="Addons.varieties[0].name" width="100"
                                                            class="shadow-4 rounded-addImage" />
                                                    </div>
                                                    <div class="col-sm-4 col-12 ">
                                                        <h5 class="d-block fw-bold">{{Addons.varieties[0].name}}</h5>
                                                        <h6 class="text-primary d-block"><i
                                                                class="fa-solid fa-calendar-days text-primary"></i>
                                                            {{'RsrvOrdrTitl001CrdDsc001'|translate}}
                                                            {{" "
                                                            +Addons.varieties[0].addEnd}}</h6>
                                                        <h6 class="d-flex"> {{'Price:'|translate}}
                                                            <h6 [ngClass]="{
                                                        'text-decoration-line-through':
                                                        Addons.varieties[0].newPrice!=null
                                                    }"> {{Addons.varieties[0].price!=0?Addons.varieties[0].price+"
                                                                "+Addons.varieties[0].curSymbol:'RsrvOrdrTitl001CrdDsc002Titl001'|translate}}
                                                            </h6>
                                                            <h6 *ngIf="Addons.varieties[0].newPrice!=null">
                                                                {{Addons.varieties[0].newPrice!=0?Addons.varieties[0].newPrice+"
                                                                "+Addons.varieties[0].curSymbol:'RsrvOrdrTitl001CrdDsc002Titl001'|translate}}
                                                            </h6>
                                                        </h6>

                                                    </div>
                                                    <div class="col-sm-6 col-12 d-flex flex-column justify-content-end">
                                                        <div class="p-input-filled col-12  mb-3  border-primary pb-4">
                                                            <span class="p-float-label">
                                                                <p-dropdown inputId="event-sub-category"
                                                                    [showClear]="true" appendTo="body"
                                                                    id="event-sub-category" [styleClass]="'w-100'"
                                                                    pTooltip="{{'RsrvOrdrTitl002Slct001Pop'|translate}}"
                                                                    showDelay="1000" tooltipPosition="bottom"
                                                                    [styleClass]="'w-100'" [autoDisplayFirst]="false"
                                                                    [options]="getOptions(Addons.varieties[0].minPerOrder,Addons.varieties[0].maxPerOrder)"
                                                                    (onChange)="setchekout($event,Addons.varieties[0])"></p-dropdown>
                                                                <label class="form-label font-size-14"
                                                                    for="event-sub-category">{{'RsrvOrdrTitl002Slct001'|translate}}</label>
                                                            </span>
                                                        </div>
                                                        <div class="p-input-filled col-12    border-primary pb-4 "
                                                            *ngIf="haveQty(Addons.varieties[0])">
                                                            <span class="p-float-label">

                                                                <p-dropdown inputId="event-sub-category"
                                                                    [showClear]="true" id="event-sub-category"
                                                                    [styleClass]="'w-100'"
                                                                    pTooltip="{{'RsrvOrdrTitl001Slct002Pop'|translate}}"
                                                                    appendTo="body" showDelay="1000"
                                                                    tooltipPosition="bottom" [styleClass]="'w-100'"
                                                                    [autoDisplayFirst]="false"
                                                                    [options]="getDelveryMethodsOption(Addons.varieties[0])"
                                                                    optionLabel="name" optionValue="id"
                                                                    (onChange)="setDelverymethods($event,Addons.varieties[0].id)"></p-dropdown>
                                                                <label class="form-label font-size-14"
                                                                    for="event-sub-category">{{'RsrvOrdrTitl001Slct002'|translate}}
                                                                </label>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>



                                        </div>
                                    </div>
                                </div>
                            </div>
                            <ng-container *ngIf="!data.isForBuyer">
                                <ng-container  *ngFor="let item of ordertick ; let j = index">
                                    <div class="row" >
                                        <div class="col-12">
                                         <h4>{{'Atendees information for ticket'|translate}} {{item.name}}</h4>
                                        </div>
                                      </div>
                                        <div class="row">
                                            <div *ngIf="!data.isForBuyer &&firstStep" class="col-12">
                                                <div class="p-input-filled col-12 col-md-8 mb-3  border-primary pb-4">
                                                    <span class="p-float-label">
                                                        <p-dropdown #numAttendee inputId="event-sub-category" [showClear]="true"
                                                            id="event-sub-category" [styleClass]="'w-100'"
                                                            pTooltip="number of tickets" appendTo="body" showDelay="1000"
                                                            tooltipPosition="bottom" [styleClass]="'w-100'"
                                                            [autoDisplayFirst]="false"
                                                            [options]="getOptions(item.qty*item.minPerTicket,item.qty*item.maxPerTicket)"
                                                            (onChange)="getHowManyAtt($event,j)"></p-dropdown>
                                                        <label class="form-label font-size-14" for="event-sub-category">
                                                           {{'Numberofattendees'|translate}}
                                                        </label>
                                                    </span>
                                                </div>
                                            </div>
                                           
                                            <ng-container *ngIf="!data.isForBuyer" >
                                                <div class="row"  [ngClass]="{'card-base':numOfattendees[j]>=2}">
                                                    <div class="col-12" 
                                                    *ngFor="let x of  getOptions(1,numOfattendees[j]) ; let i = index ">
                                                    <h5>{{('Attendee'|translate) + (i + 1)}}</h5>
                                                    <app-json-form [jsonFormData]="formData" (result)="GetResult($event, i,j,item) "
                                                        (IsNotValid)="getValud($event,i)"></app-json-form>
                                                </div>
                                                </div>    
                                             
                                            </ng-container>
                                           
                                        </div>
                                  </ng-container>
                            </ng-container>

                             


                              <div class="row">
                                <ng-container *ngIf="data.isForBuyer &&firstStep">
                                    <div class="col-12">
                                        <h5> {{'RsrvOrdrTitl003'|translate}}</h5>
                                        <app-json-form [jsonFormData]="formData" (result)="GetResult1($event, 0,0) "
                                            (IsNotValid)="getValud1($event,0)"></app-json-form>
                                    </div>
                                </ng-container>
                              </div>

                              <div class="row">
                                <ng-container *ngFor="let x of Addressdata ; let j = index">
                                    <div class="col-12">
                                        <h5> {{'Shipping address for '|translate}} {{x.name}} </h5>
                                        <app-json-get-shipping-address [jsonFormData]="x" (result)="getAddress($event,j) "></app-json-get-shipping-address>
                                    </div>
                                </ng-container>
                              </div>
                              

                      
                            <!-- <div class="row">

                                <div *ngIf="!data.isForBuyer &&firstStep" class="col-12">
                                    <div class="p-input-filled col-12 col-md-8 mb-3  border-primary pb-4">
                                        <span class="p-float-label">
                                            <p-dropdown #numAttendee inputId="event-sub-category" [showClear]="true"
                                                id="event-sub-category" [styleClass]="'w-100'"
                                                pTooltip="number of tickets" appendTo="body" showDelay="1000"
                                                tooltipPosition="bottom" [styleClass]="'w-100'"
                                                [autoDisplayFirst]="false"
                                                [options]="getOptions(minNumofOrderform,maxNumofOrderform)"
                                                (onChange)="getHowManyAtt($event)"></p-dropdown>
                                            <label class="form-label font-size-14" for="event-sub-category">Number of
                                                attendees
                                            </label>
                                        </span>
                                    </div>
                                </div>
                                <ng-container *ngIf="!data.isForBuyer">

                                    <div class="col-12"
                                        *ngFor="let x of  getOptions(1,numOfattendees) ; let i = index ">
                                        <h5>{{'Attendee ' + (i + 1)}}</h5>
                                        <app-json-form [jsonFormData]="formData" (result)="GetResult($event, i) "
                                            (IsNotValid)="getValud($event,i)"></app-json-form>
                                    </div>
                                </ng-container>

                                <ng-container *ngIf="data.isForBuyer">
                                    <div class="col-12">
                                        <h5> {{'RsrvOrdrTitl003'|translate}}</h5>
                                        <app-json-form [jsonFormData]="formData" (result)="GetResult1($event, 0) "
                                            (IsNotValid)="getValud1($event,0)"></app-json-form>
                                    </div>
                                </ng-container>
                            </div> -->
                        </div>
                        <div class="col-lg-4 col-12">
                            <div class="row">
                                <div class="col-12">
                                    <img [src]="data.eventImage==null?'assets/img/courses/3SUBiaP.png':url+data.eventImage"
                                        alt="ddd" width="100%" class="shadow-4 rounded-addImage" />

                                </div>
                                <div class="col-12 ">
                                    <h5 class="d-block fw-bold">
                                        {{data.eventName}}
                                    </h5>

                                    <h6 class="text-primary d-block" *ngIf="data.displayStart"> <i
                                            class="fa-solid fa-calendar-days text-primary"></i>
                                       {{'RsrvOrdrDsc001'|translate}} {{getDateOntomeZone(data.eventStart)}}
                                    </h6>
                                    <h6 class=" text-primary d-block" *ngIf="data.displayEnd"> <i
                                            class="fa-solid fa-calendar-days text-primary"></i>
                                       {{'RsrvOrdrDsc002'|translate}} {{getDateOntomeZone(data.eventEnd)}} </h6>

                                </div>
                                <div class="col-12 d-lg-block d-none events-details-parent" *ngIf="!checkAllTiketQty()" >
                                    <div class="events-details-info">
                                        <ul class="info">
                                            <ng-container *ngFor="
                    let c of orderForm.controls;
                    let i = index">
                                                <li *ngIf="orderForm.controls[i]?.get('qty').value!=0">
                                                    <div class="d-flex justify-content-between align-items-center">
                                                        <span><span>{{orderForm.controls[i]?.get('qty').value}} X</span>
                                                            {{" "+orderForm.controls[i]?.get('name').value}}</span>
                                                        <span>
                                                            <span [ngClass]="{
                                                        'text-decoration-line-through':
                                                        orderForm.controls[i]?.get('price').value!=orderForm.controls[i]?.get('oldPrice').value 
                                                    }">
                                                                {{getitemPrice(
                                                                orderForm.controls[i]?.get('oldPrice').value ,
                                                                orderForm.controls[i]?.get('qty').value)}}{{orderForm.controls[i]?.get('curSymbol').value}}

                                                            </span>
                                                            <span
                                                                *ngIf="  orderForm.controls[i]?.get('price').value!=orderForm.controls[i]?.get('oldPrice').value ">
                                                                {{getitemPrice(
                                                                orderForm.controls[i]?.get('price').value ,
                                                                orderForm.controls[i]?.get('qty').value)}}{{orderForm.controls[i]?.get('curSymbol').value}}

                                                            </span>
                                                        </span>
                                                    </div>
                                                </li>
                                            </ng-container>
                                            <ng-container *ngIf=" Addressdata.length!=0">
                                                <li *ngIf="orderForm.controls[i]?.get('qty').value!=0">
                                                    <div class="d-flex justify-content-between align-items-center">
                                                        <span><span>{{countOfShippingAddress}} X</span>
                                                            {{'shipping '|translate}}</span>
                                                        <span>
                                                            <!-- <span [ngClass]="{
                                                        'text-decoration-line-through':
                                                        orderForm.controls[i]?.get('price').value!=orderForm.controls[i]?.get('oldPrice').value 
                                                    }">
                                                                {{getitemPrice(
                                                                orderForm.controls[i]?.get('oldPrice').value ,
                                                                orderForm.controls[i]?.get('qty').value)}}{{orderForm.controls[i]?.get('curSymbol').value}}

                                                            </span> -->
                                                            <span>
                                                                {{shippingCost*countOfShippingAddress}}{{orderForm.controls[i]?.get('curSymbol').value}}

                                                            </span>
                                                        </span>
                                                    </div>
                                                </li>
                                            </ng-container>
                                            <li class="price">
                                                <div class="d-flex justify-content-between align-items-center">
                                                    <span>{{'RsrvOrdrTotlSlt'|translate}}</span>
                                                    {{gettotal()}}{{orderForm.controls[0]?.get('curSymbol').value}}
                                                    <!-- {{ CheckOut.length!=0?CheckOut[0].curSymbol:null}} -->
                                                </div>
                                            </li>
                                        </ul>
                                        <div class="btn-box">

                                            <button class=" rounded-pill save-btn w-100" (click)="postOrder()">
                                                {{'RsrvOrdrTotlSltBtn001'|translate}}<span></span>
                                            </button>

                                        </div>

                                    </div>
                                </div>



                                <div class="col-12 d-lg-block d-none events-details-parent" *ngIf="checkAllTiketQty()">
                                    <div class="events-details-info">
                                        <ul class="info">
                                      
                                                <li *ngIf="configdata.waitlist==null||!checkAvailableWaitlist()" >
                                                    <div class=" row ">
                                                      <div class="col-12">
                                                        <span> <i class="fa-regular fa-circle-info"></i>
                                                            {{'soryOfWaitlist'|translate}}
                                                       </span>
                                                      </div>
                                                    </div>
                                                </li>

                                                <li  *ngIf="configdata.waitlist!=null && checkAllTiketQty()&&checkAvailableWaitlist()">
                                                    <div class=" row ">
                                                      <div class="col-12">
                                                        <span> <i class="fa-regular fa-circle-info"></i>
                                                            {{'SoryAndRegWaitList'|translate}}
                                                       </span>
                                                      </div>
                                                      <form [formGroup]="WaitlistForm" >
                                                      <div class="col-12">
                                                        <div class="p-input-filled col-sm-10 col-12 mb-1">
                                                            <span class="p-float-label">
                                                                <input type="text" formControlName="email" pInputText class="form-control"  
                                                                [ngClass]="{
                                                                    'ng-invalid ng-dirty':(  WaitlistForm.get('email')?.touched &&
                                                                    WaitlistForm.get('email')?.invalid)
                                                                }"
                                                                 [class.ng-invalid.ng-dirty]="" />
                                                                <!--  -->
                                                                    
                                                                <label class="form-lable font-size-14" for="Ticketemail">{{'Email'|translate}}</label>
                                                            </span>
                                                            
                                                
                                                        </div>
                                                      </div>
                                                      <div class="col-12">
                                                        <div class="p-input-filled col-sm-10 col-12 mb-1">
                                                            <span class="p-float-label">
                                                                <input type="text" formControlName="name" pInputText class="form-control" 
                                                                [ngClass]="{
                                                                    'ng-invalid ng-dirty':(  WaitlistForm.get('name')?.touched &&
                                                                    WaitlistForm.get('name')?.invalid)
                                                                }"  [class.ng-invalid.ng-dirty]="" />
                                                                <!--  -->
                                                                    
                                                                <label class="form-lable font-size-14" for="Ticketemail">{{'Name'|translate}}</label>
                                                            </span>
                                                            
                                                
                                                        </div>
                                                      </div>
                                                      <div class="col-12">
                                                        <div class="p-input-filled col-sm-10 col-12 mb-1">
                                                            <span class="p-float-label">
                                                                <input type="text" formControlName="phone" pInputText class="form-control"  
                                                                
                                                                [ngClass]="{
                                                                    'ng-invalid ng-dirty':(  WaitlistForm.get('phone')?.touched &&
                                                                    WaitlistForm.get('phone')?.invalid)}"
                                                                    [class.ng-invalid.ng-dirty]="" />
                                                                
                                                                    
                                                                <label class="form-lable font-size-14" for="Ticketemail">{{'WaitListSwitch3'|translate}}</label>
                                                            </span>
                                                            
                                                
                                                        </div>
                                                      </div>
                                                    </form>
                                                    </div>
                                                </li>
                                                       
                                        </ul>
                                        <div class="btn-box" *ngIf="configdata.waitlist!=null &&checkAllTiketQty() &&checkAvailableWaitlist()">

                                            <button class=" rounded-pill save-btn w-100"  (click)=" submitmyWaitlist($event)" > 
                                                {{'WaitlistBtnPOst'|translate}}
                                             <span></span>
                                            </button>

                                        </div>
                                        <div class="btn-box" *ngIf="configdata.waitlist==null ||!checkAvailableWaitlist()">

                                            <button class=" rounded-pill save-btn w-100"  (click)="GoToHomePage()" > 
                                               {{'BrowsAnOtherEvent'|translate}}
                                             <span></span>
                                            </button>

                                        </div>

                                    </div>
                                </div>

                            </div>



                        </div>
                    </div>


                </div>
            </div>
        </div>
        <div class="  d-lg-none events-details-width2 " *ngIf="!checkAllTiketQty()">
            <div class="events-details-info2 ">
                <ul class="info">
                    <div class="solts-small-display">
                        <ng-container *ngFor="
                    let c of orderForm.controls;
                    let i = index">
                            <li *ngIf="orderForm.controls[i]?.get('qty').value!=0">
                                <div class="d-flex justify-content-between align-items-center">
                                    <span>
                                        <span>{{orderForm.controls[i]?.get('qty').value}} X</span>
                                        {{" "+orderForm.controls[i]?.get('name').value}}
                                    </span>
                                    <span>
                                        <span [ngClass]="{
                                    'text-decoration-line-through':
                                    orderForm.controls[i]?.get('price').value!=orderForm.controls[i]?.get('oldPrice').value 
                                }">
                                            {{getitemPrice( orderForm.controls[i]?.get('oldPrice').value ,
                                            orderForm.controls[i]?.get('qty').value)}}{{orderForm.controls[i]?.get('curSymbol').value}}

                                        </span>
                                        <span
                                            *ngIf="  orderForm.controls[i]?.get('price').value!=orderForm.controls[i]?.get('oldPrice').value ">
                                            {{getitemPrice( orderForm.controls[i]?.get('price').value ,
                                            orderForm.controls[i]?.get('qty').value)}}{{orderForm.controls[i]?.get('curSymbol').value}}

                                        </span>
                                    </span>
                                </div>
                            </li>

                        </ng-container>

                    </div>
                    <li class="price">
                        <div class="d-flex justify-content-between align-items-center">
                            <span>{{'RsrvOrdrTotlSlt'|translate}}</span>
                            {{gettotal()}}{{orderForm.controls[i]?.get('curSymbol').value}}
                        </div>
                    </li>
                </ul>
                <div class="btn-box">

                    <button class=" rounded-pill save-btn w-100" *ngIf="(  OrderFormIsComplete)" (click)="postOrder()">
                        {{'RsrvOrdrTotlSltBtn001'|translate}}<span></span>
                    </button>

                </div>

            </div>
        </div>

        <div class="  d-lg-none events-details-width2 " *ngIf="checkAllTiketQty()">
            <div class="events-details-info2 ">
                <ul class="info">
                    <div class="solts-small-display">
                     
                        <li *ngIf="configdata.waitlist==null||!checkAvailableWaitlist()" >
                            <div class=" row ">
                              <div class="col-12">
                                <span> <i class="fa-regular fa-circle-info"></i>
                                    {{'soryOfWaitlist'|translate}}
                 
                               </span>
                              </div>
                            </div>
                        </li>

                        <li  *ngIf="configdata.waitlist!=null &&checkAllTiketQty()&&checkAvailableWaitlist() ">
                            <div class=" row ">
                              <div class="col-12">
                                <span> <i class="fa-regular fa-circle-info"></i>
                                    {{'SoryAndRegWaitList'|translate}}
                               </span>
                              </div>
                              <form [formGroup]="WaitlistForm" >
                              <div class="col-12">
                                <div class="p-input-filled col-sm-10 col-12 mb-1">
                                    <span class="p-float-label">
                                        <input type="text" formControlName="email" pInputText class="form-control"  
                                        [ngClass]="{
                                            'ng-invalid ng-dirty':(  WaitlistForm.get('email')?.touched &&
                                            WaitlistForm.get('email')?.invalid)
                                        }"
                                         [class.ng-invalid.ng-dirty]="" />
                                        <!--  -->
                                            
                                        <label class="form-lable font-size-14" for="Ticketemail">{{'Email'|translate}}</label>
                                    </span>
                                    
                        
                                </div>
                              </div>
                              <div class="col-12">
                                <div class="p-input-filled col-sm-10 col-12 mb-1">
                                    <span class="p-float-label">
                                        <input type="text" formControlName="name" pInputText class="form-control" 
                                        [ngClass]="{
                                            'ng-invalid ng-dirty':(  WaitlistForm.get('name')?.touched &&
                                            WaitlistForm.get('name')?.invalid)
                                        }"  [class.ng-invalid.ng-dirty]="" />
                                        <!--  -->
                                            
                                        <label class="form-lable font-size-14" for="Ticketemail">{{'Name'|translate}}</label>
                                    </span>
                                    
                        
                                </div>
                              </div>
                              <div class="col-12">
                                <div class="p-input-filled col-sm-10 col-12 mb-1">
                                    <span class="p-float-label">
                                        <input type="text" formControlName="phone" pInputText class="form-control"  
                                        
                                        [ngClass]="{
                                            'ng-invalid ng-dirty':(  WaitlistForm.get('phone')?.touched &&
                                            WaitlistForm.get('phone')?.invalid)}"
                                            [class.ng-invalid.ng-dirty]="" />
                                        
                                            
                                        <label class="form-lable font-size-14" for="Ticketemail">{{'WaitListSwitch3'|translate}}</label>
                                    </span>
                                    
                        
                                </div>
                              </div>
                            </form>
                            </div>
                        </li>

                    </div>
                   
                </ul>
                <div class="btn-box" *ngIf="configdata.waitlist!=null &&checkAllTiketQty()&&checkAvailableWaitlist()">

                    <button class=" rounded-pill save-btn w-100"  (click)=" submitmyWaitlist($event)">
                        {{'WaitlistBtnPOst'|translate}}<span></span>
                    </button>

                </div>

            </div>
        </div>



    </div>
    <div *ngFor="let i of noti">{{i}}</div>
</form>