import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
export interface EventCard {
    id: string;
    title: string;
    summary: string;
    photo: string;
    startDate: string;
    endDate: string;
    category: string;
    subCategory: string;
    address: string;
    price: Price;
    curSymbol: string;
    orgName: string;
    orgFollowers: number;
    liked: boolean ;
     orgId : string;
  }
export interface Price {
    minValue: number;
    maxValue: number;
  }

@Injectable({
  providedIn: 'root'
})
export class LandingPageService {
  constructor(private http: HttpClient) { }

  getLandingPageEvents(){
    return this.http.get<EventCard[]>(environment.Api + 'd/la/all/9');
  }

  getOnline(){
    return this.http.get<EventCard[]>(environment.Api + 'd/la/online');
  }
  getToday(){
    return this.http.get<EventCard[]>(environment.Api + 'd/la/today');
  }
  getWeekend(){
    return this.http.get<EventCard[]>(environment.Api + 'd/la/weekend');
  }
  getFree(){
    return this.http.get<EventCard[]>(environment.Api + 'd/la/free');
  }
  getMyEvent(){
    return this.http.get<EventCard[]>(environment.Api + 'd/la/myevents');
  }
  getForYou(){
    return this.http.get<EventCard[]>(environment.Api + 'd/la/foryou');
  }
  getlocal(la){
    return this.http.get<EventCard[]>(environment.Api + 'd/la/local/'+la);
  }
  getMoreEvent(pageSize, PageNum,local){
    return this.http.get<EventCard[]>(environment.Api + 'd/la/moreevent/' + pageSize + '/' + PageNum+'/'+local);
  }


  getUserAddress(last,lang){
    //this.http.get(this.url, { search: this.params });

  }


}
