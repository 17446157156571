import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import * as m from 'moment';
import 'moment/locale/pt-br';

@Component({
  selector: 'app-add-ons-card-report',
  templateUrl: './add-ons-card-report.component.html',
  styleUrls: ['./add-ons-card-report.component.scss']
})
export class AddOnsCardReportComponent {
  @Input() item: any;
  @Input() cols:any;
  
  @Output() Canceld : EventEmitter<any[]> = new EventEmitter(); 
 constructor(private translate:TranslateService){
  this.translate.onLangChange.subscribe(() => {

    m.locale(this.translate.currentLang)
  });
 }
  checkpropName(s:string):boolean{
    var l=  this.cols.find(x=>x.field==s);
      if(l==undefined){
        return false
      }else{
        return true
      }
    }

    getDateOntomeZoneformat(d,x){
      m.locale()
      let t = new Date(Date.now()).getTimezoneOffset();
      let MyDate = new Date(d).setMinutes(new Date(d).getMinutes() - (t));
      var l="";
      switch(x){
        case 'full':if(new Date(MyDate).getFullYear()==new Date(Date.now()).getFullYear()){
          l= m(new Date(MyDate)).format('ddd MMMM D hh:mm a')
          //this.datePipe.transform(new Date(MyDate), 'EEE MMMM d hh:mm a');
  
      }else{
         l= m(new Date(MyDate)).format( 'ddd MMMM D,y hh:mm a');
         //this.datePipe.transform(new Date(MyDate), 'EEE MMMM d,y hh:mm a');
  
      }
      break;
      case 'month': l=m(new Date(MyDate)).format('MMM');
      //this.datePipe.transform(new Date(MyDate), 'MMM');
      break;
      case 'day':l=m(new Date(MyDate)).format('D')
      //this.datePipe.transform(new Date(MyDate), 'dd');
      break
      }
      return l
  
  
  
  
     // return new Date(MyDate).toLocaleString();
  }
}
