<form [formGroup]="srv.AddonsForm" (submit)="submit()" autocomplete="off">

    <div class="">

        <div class="row mb-2">
            <div class="p-input-filled col-sm-6 col-md-6 mb-2">
                <span class="p-float-label">
                    <input pTooltip="{{'AddOnFrmInfoInpt001Pop'|translate}}" showDelay="1000" tooltipPosition="bottom"
                        type="text" pInputText id="TicketName" formControlName="name" class="form-control" [ngClass]="{
                    'ng-invalid ng-dirty':
                        (srv.AddonsForm.get('name')?.touched &&
                        srv.AddonsForm.get('name')?.invalid)||(submitted&& srv.AddonsForm.get('name').invalid)
                }">
                    <label class="form-label font-size-14" for="TicketName">{{'AddOnFrmInfoInpt001'|translate}}</label>
                </span>
                <small class="p-error" *ngIf="(srv.AddonsForm.get('name')?.touched &&
        srv.AddonsForm.get('name')?.invalid)||(submitted&& srv.AddonsForm.get('name').invalid)
            ">{{'AddOnFrmInfoInpt001ErrMsg'|translate}} </small>

            </div>
            <div class="p-input-filled col-sm-6 col-md-6   mb-2">
                <span class="p-float-label">
                    <input pTooltip="{{'AddOnFrmInfoInpt002Pop'|translate}}" showDelay="1000" tooltipPosition="bottom"
                        type="text" pInputText id="TicketName" formControlName="categoryId" class="form-control"
                        [ngClass]="{
                    'ng-invalid ng-dirty':
                        (srv.AddonsForm.get('categoryId')?.touched &&
                        srv.AddonsForm.get('categoryId')?.invalid)||(submitted&& srv.AddonsForm.get('categoryId').invalid)
                }">
                    <label class="form-label font-size-14" for="TicketName">{{'AddOnFrmInfoInpt002'|translate}}</label>
                </span>
                <!-- <span class="p-float-label">
                    <p-dropdown inputId="RecTicketConf" pTooltip="Select category of this add-ons " showDelay="1000" tooltipPosition="bottom" [styleClass]="'w-100'" [autoDisplayFirst]="false" [options]="cat"
                    optionLabel="name" optionValue="Id" formControlName="categoryId"
                    [ngClass]="{
                        'ng-invalid ng-dirty':
                            (srv.AddonsForm.get('categoryId')?.touched &&
                            srv.AddonsForm.get('categoryId')?.invalid)||(submitted&& srv.AddonsForm.get('categoryId').invalid)
                    }" ></p-dropdown>
                    <label class="form-label font-size-14" for="withoutgrouping">Category</label>
                </span> -->
                <small class="p-error" *ngIf="(srv.AddonsForm.get('categoryId')?.touched &&
                srv.AddonsForm.get('categoryId')?.invalid)||(submitted&& srv.AddonsForm.get('categoryId').invalid)
                    ">{{'AddOnFrmInfoInpt002ErrMsg'|translate}} </small>
            </div>

            <div class="p-input-filled col-sm-6 col-md-6 mb-2">

                <div class="p-inputgroup">
                    <span class="p-inputgroup-addon"
                        [ngClass]="{' currency-custom':document.documentElement.dir === 'ltr',' currency-custom-rtl':document.documentElement.dir === 'rtl'}">{{EventStepSrv.currancysymbole}}</span>
                    <span class="p-float-label">
                        <!-- <input type="text" pInputText  /> -->
                        <p-inputNumber inputId="locale-user" pTooltip="{{'AddOnFrmInfoInpt003Pop'|translate}}"
                            showDelay="1000" tooltipPosition="bottom" [minFractionDigits]="2" class="w-100 "
                            [inputStyleClass]="'form-control'" formControlName="price" [ngClass]="{
                    'ng-invalid ng-dirty':
                        (srv.AddonsForm.get('price')?.touched &&
                        srv.AddonsForm.get('price')?.invalid)||(submitted&& srv.AddonsForm.get('price').invalid)
                }"></p-inputNumber>
                        <label class="form-label font-size-14"
                            for="locale-user">{{'AddOnFrmInfoInpt003'|translate}}</label>
                    </span>
                </div>

                <!-- <span class="p-float-label">
                    <p-inputNumber inputId="locale-user" pTooltip="enter price for this add-on " showDelay="1000" tooltipPosition="bottom" [minFractionDigits]="2" [styleClass]="'w-100'" mode="currency" currency="USD" locale="en-US"
                        class="form-control m-0 p-0" [inputStyleClass]="'form-control'" formControlName="price"
                        [ngClass]="{
                        'ng-invalid ng-dirty':
                            (srv.AddonsForm.get('price')?.touched &&
                            srv.AddonsForm.get('price')?.invalid)||(submitted&& srv.AddonsForm.get('price').invalid)
                    }"></p-inputNumber>
                    <label class="form-label font-size-14" for="locale-user">Price</label>
                </span> -->
                <small class="p-error" *ngIf="(srv.AddonsForm.get('price')?.touched &&
        srv.AddonsForm.get('price')?.invalid)||(submitted&& srv.AddonsForm.get('price').invalid)
            ">{{'AddOnFrmInfoInpt003ErrMsg'|translate}}</small>

            </div>
            <div class="p-input-filled col-sm-6 col-md-6 mb-2">
                <span class="p-float-label">

                    <p-inputNumber pTooltip="{{'AddOnFrmInfoInpt004Pop'|translate}}" showDelay="1000"
                        tooltipPosition="bottom" inputId="locale-user" [styleClass]="'w-100'"
                        class="form-control m-0 p-0" [inputStyleClass]="'form-control'" formControlName="quantity"
                        [ngClass]="{
                        'ng-invalid ng-dirty':
                            (srv.AddonsForm.get('quantity')?.touched &&
                            srv.AddonsForm.get('quantity')?.invalid&&srv.AddonsForm.get('quantity')?.errors?.['min'])||(submitted&& srv.AddonsForm.get('quantity').invalid&&srv.AddonsForm.get('quantity')?.errors?.['min'])
                    }"></p-inputNumber>
                    <label class="form-label font-size-14" for="locale-user">{{'AddOnFrmInfoInpt004'|translate}}</label>
                </span>

                <small class="p-error" *ngIf="(srv.AddonsForm.get('quantity')?.touched &&
        srv.AddonsForm.get('quantity')?.invalid&&!srv.AddonsForm.get('quantity')?.errors?.['min'])||(submitted&& srv.AddonsForm.get('quantity').invalid&&!srv.AddonsForm.get('quantity')?.errors?.['min'])
            ">{{'AddOnFrmInfoInpt004ErrMsg01'|translate}}</small>
                <small class="p-error" *ngIf="(srv.AddonsForm.get('quantity')?.touched &&
        srv.AddonsForm.get('quantity')?.invalid&&srv.AddonsForm.get('quantity')?.errors?.['min'])||(submitted&& srv.AddonsForm.get('quantity').invalid&&srv.AddonsForm.get('quantity')?.errors?.['min'])
            ">{{'AddOnFrmInfoInpt004ErrMsg02'|translate}}
                    {{srv.AddonsForm.get('quantity').errors?.['min']?.min}}</small>
            </div>
        </div>
        <div class="row mb-2">
            <div class="p-input-filled col-md-6 col-12 ">
                <app-my-image-uploader formControlName="image" pTooltip="{{'AddOnFrmInfoInpt005Pop'|translate}}"
                    showDelay="1000" tooltipPosition="bottom" [aspectRatio]="1 / 1" [quality]="'50'"
                    [format]="'jpeg'"></app-my-image-uploader>
                <!-- <app-upload-photo (photo)="setPhoto($event)"  (ischanged)="setIsChanged($event)" [img]="croppedImage"
                    [ngClass]="{
                'ng-invalid ng-dirty':
                   submitted&& srv.AddonsForm.get('image').hasError('imageisnull')
            }"></app-upload-photo> -->
                <small class="p-error" *ngIf="(srv.AddonsForm.get('image')?.touched &&
            srv.AddonsForm.get('image')?.invalid)||(submitted&& srv.AddonsForm.get('image').invalid)
                ">{{'AddOnFrmInfoInpt005ErrMsg'|translate}}</small>
            </div>
            <div class=" p-input-filled col-md-6 col-12 mb-2">
                <span class="p-float-label">
                    <textarea pTooltip="{{'AddOnFrmInfoInpt006Pop'|translate}}  " showDelay="1000"
                        tooltipPosition="bottom" rows="10" cols="30" pInputTextarea style="height: 300px;"
                        [autoResize]="false" class="form-control " formControlName="description" [ngClass]="{
                'ng-invalid ng-dirty':
                    (srv.AddonsForm.get('description')?.touched &&
                    srv.AddonsForm.get('description')?.invalid)||(submitted&& srv.AddonsForm.get('description').invalid)
            }"></textarea>
                    <label class="form-lable font-size-14" for="description">{{'AddOnFrmInfoInpt006'|translate}}
                    </label>
                </span>
                <small class="p-error" *ngIf="(srv.AddonsForm.get('description')?.touched &&
        srv.AddonsForm.get('description')?.invalid)||(submitted&& srv.AddonsForm.get('description').invalid)
            ">{{'AddOnFrmInfoInpt006ErrMsg'|translate}}</small>
            </div>
        </div>
    </div>

    <div class="col-12 d-flex  justify-content-center">
        <div class="banner-btn rounded-pill mx-1">

            <button pTooltip="Go back to previous step" type="button" showDelay="1000" tooltipPosition="left"
                *ngIf="srv.Isinitail" class="rounded-pill cancel-btn" (click)="previous()"><i class="pi mt-1"
                    [class.pi-angle-right]="document.documentElement.dir === 'rtl'"
                    [class.pi-angle-left]="document.documentElement.dir === 'ltr'"></i> {{'PrviosBtn'|translate}}
                <span></span></button>
            <button pTooltip="Cancel and dismiss changes" type="button" showDelay="1000" tooltipPosition="bottom"
                type="button" class="rounded-pill save-btn ms-1" (click)="cancel()">{{'CanclBtn'|translate}}
                <span></span></button>
            <button pTooltip="Save and go to next step" showDelay="1000" tooltipPosition="right"
                class="rounded-pill next-btn  ms-1 mt-md-0 mt-1">{{'NextBtn'|translate}} <i class="pi mt-1"
                    [class.pi-angle-right]="document.documentElement.dir === 'ltr'"
                    [class.pi-angle-left]="document.documentElement.dir === 'rtl'"></i><span></span></button>
        </div>
    </div>


</form>