<form [formGroup]="oreForm" (ngSubmit)="onSubmit()" autocomplete="off">
<div class="container">
    <div class="card custom-table-border">
        <div class="card-header border-0 text-center bg-primary rounded-top-23 ">
            <h1 class="fw-bold text-white mt-2">{{'OrderConfTitl'|translate}}</h1>
        </div>
        <div class="card-body">
            <div class="card-body p-0">

                <div class="row d-flex justify-content-center ">
                    <h5>{{'OrderConfTitl2'|translate}}</h5>
                    <p>{{'OrderConfDisc'|translate}}</p>
                    <p>{{'OrderConfDisc2'|translate}}</p>
                    <!-- <div class=" p-input-filled mb-2">
                        <span class="p-float-label">
                            <textarea pTooltip="{{'AddOnFrmInfoInpt006Pop'|translate}}  " showDelay="1000"
                                tooltipPosition="bottom" rows="10" cols="30" pInputTextarea style="height: 300px;"
                                [autoResize]="false" class="form-control " formControlName="pageMessage"></textarea>
                            <label class="form-lable font-size-14" for="description">{{'AddOnFrmInfoInpt006'|translate}}
                            </label>
                        </span>
                    </div> --> 
                     <p-editor showDelay="1000" tooltipPosition="bottom"
                            [style]="{'min-height': '300px', 'margin-bottom': '15px'}" formControlName="pageMessage">
                        </p-editor>
                    <h5>{{'OrderConfTitl3'|translate}}</h5>
                    <p>{{'OrderConfDisc3'|translate}}
                    </p>
                    <p> {{'OrderConfDisc4'|translate}}</p>
                    <div class="row">
                        <div class="p-input-filled col-sm-4 col-md-4 mb-2">
                            <span class="p-float-label">
                                <input type="text" formControlName="replayEmail" pInputText class="form-control" [ngClass]="{
                                        'ng-invalid ng-dirty':(  oreForm.get('replayEmail')?.touched &&
                                        oreForm.get('replayEmail')?.invalid)
                                    }" [class.ng-invalid.ng-dirty]="" />
                                <label class="form-lable font-size-14" for="Ticketemail">{{'InvtNuMmbrMdlInpt001'|translate}}</label>
                            </span>
                        </div>
                        <p>{{'OrderConfDisc5'|translate}}</p>
                        <p-editor showDelay="1000" tooltipPosition="bottom"
                            [style]="{'min-height': '300px', 'margin-bottom': '15px'}" formControlName="replayMessage">
                        </p-editor>

                        <h5>{{'OrderConfTitl4'|translate}}</h5>
                        <p>{{'OrderConfDisc6'|translate}}</p>
                        <!-- <select class="form-select" aria-label="Default select example" (change)="onSelect()">
                            <option value="1">Same setting for all ticket types</option>
                            <option value="2">Custome settings for each ticket type</option>
                        </select> -->
                        <p>{{'OrderConfDisc7'|translate}}</p>
                        <p-inputSwitch showDelay="1000" tooltipPosition="top" inputId="is-online"
                             formControlName="canprint"></p-inputSwitch>
                    </div>

                </div>
            </div>
        </div>
    </div>
    <div class="d-flex justify-content-center mt-1">
        <button class="rounded-pill save-btn">
            {{'SavBtn'|translate}}
        </button>
    </div>
</div>
</form>
