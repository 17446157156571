<div class="card custom-table-border">
    <!-- <div class="card-header text-center bg-primary rounded-top-23">
        <h1 class="text-white">Invitation</h1>
    </div> -->
    <div class="card-header text-center bg-primary rounded-top-23">
        <h1 class="fw-bold text-white mt-2">{{'InvtionTblTitl'|translate}}</h1>
    </div>
    <div class="">
        <p-toast></p-toast>
        <div *ngIf="loader.show1" @fadeOut class="d-flex justify-content-center align-items-center"
            style="border-radius: 25px;top : 0;position: absolute;z-index: 1050;background-color:#fff;width: 100%;height: -webkit-fill-available;right: 0;bottom: 0;left: 0;">
        </div>
        <p-table #dt responsiveLayout="stack" [breakpoint]="'767px'" [value]="data" [rows]="10" [paginator]="data.length > 5"
            [globalFilterFields]="['email']" [tableStyle]="{'min-width': '100%','max-width':'100%'}" [rowHover]="true"
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
            [showCurrentPageReport]="true" class="">
            <ng-template pTemplate="caption">
                <div>
                    <div class="d-sm-flex d-block align-items-center justify-content-sm-end text-center">

                        <div class="p-input-filled me-md-3 ">
                            <span class="p-input-icon-left">
                                <i class="pi pi-search"></i>
                                <input pInputText type="text" class="form-control"
                                    (input)="dt.filterGlobal($event.target.value, 'contains')"
                                    placeholder="{{'Search...'|translate}}" />
                            </span>
                        </div>
                    </div>
                </div>
            </ng-template>
            <ng-template pTemplate="header" class="">
                <tr>
                    <th class="fw-bold text-center">{{'InvtionTblTh001'|translate}}</th>
                    <th class="fw-bold text-center">{{'InvtionTblTh002'|translate}}</th>
                    <th class="fw-bold text-center">{{'InvtionTblTh003'|translate}}</th>
                    <th class="fw-bold text-center">{{'InvtionTblTh004'|translate}}</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-member>
                <tr>
                    <td class="text-md-center padding-custom text-end"><span
                            class="headers-cutom text-start fw-bold ">{{'InvtionTblTh001'|translate}}</span>{{member.email}}</td>
                    <td class="text-md-center padding-custom text-end"><span
                            class="headers-cutom text-start fw-bold ">{{'InvtionTblTh002'|translate}}</span>{{getDate(member.inviteTime)}}</td>
                    <td class="text-md-center padding-custom text-end"><span
                            class="headers-cutom text-start fw-bold ">{{'InvtionTblTh003'|translate}}</span><span [ngClass]="{
                                'text-bg-green': member.status , 'text-bg-dark' : !member.status
                            }" class="badge rounded-pill"> {{getstatus(member.status)}}</span></td>
                    <!-- <td><p-rating [ngModel]="event.gross" [readonly]="true" [cancel]="false"></p-rating></td> -->
                    <!-- <td><span [class]="'product-badge status-' + (product.inventoryStatus ? product.inventoryStatus.toLowerCase() : '')">{{product.inventoryStatus}}</span></td> -->
                    <td class="text-md-center " [class.action-custom]="document.documentElement.dir === 'ltr'"
                    [class.action-custom-rtl]="document.documentElement.dir === 'rtl'">
                        <p-overlayPanel #op>

                            <button pButton pRipple icon="fa-solid fa-copy" pTooltip="Copy registration link"
                            tooltipPosition="top" *ngIf="!member.status" class="p-button-rounded p-button m-1"
                            (click)="copyclick(member.id)"></button>
                            <br *ngIf="!member.status">
                            <button pButton pRipple icon="fa-solid fa-paper-plane" pTooltip="Resend Invitation"
                            tooltipPosition="top" *ngIf="!member.status" class="p-button-rounded p-button m-1"
                            (click)="resendInvite(member.id)"></button>
                            <br *ngIf="!member.status">
                            <button pButton pRipple icon="pi pi-trash" pTooltip="Delete" tooltipPosition="top"
                                class="p-button-rounded p-button-danger m-1"
                                (click)="delete($event,member.id)"></button>
                            <p-confirmPopup></p-confirmPopup>
                        </p-overlayPanel>
                        <button pButton pRipple (click)="op.toggle($event)" icon="far fa-ellipsis-v"
                            class="p-button-rounded p-button-primary m-1"></button>
                    </td>
                </tr>
            </ng-template>
            <ng-template pTemplate="summary">
                <div class="flex align-items-center justify-content-between">
                    <!--                            In total there are {{event ? data.length : 0 }} events.-->
                </div>
            </ng-template>
        </p-table>
    </div>
</div>