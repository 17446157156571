import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, ReplaySubject, Subject } from 'rxjs';
import { MultiplepromoteFormdto, PromoTableVM, promoteData, promoteFormdto, singlePromote } from '../Interfaces/promoteData';
import { environment as env } from 'src/environments/environment';
import { TicketsTablesDto } from '../Interfaces/TicketsDtoS';
import { GetAddonsNameDto } from '../Interfaces/addOnsIntreFacesDto';
@Injectable({
  providedIn: "root"
})
export class PromoteService {
  private subject: Subject<PromoTableVM> = new ReplaySubject<PromoTableVM>(1);

  public $getSubject(): Observable<PromoTableVM> {
    return this.subject.asObservable();
  }
  url: string = env.Api
  constructor(public http: HttpClient) { }


  getAllPromo(id: string,Skip:number=0): void {


    this.http.get<PromoTableVM>(this.url + 'd/pc/g/all/' + id+"/"+Skip).subscribe(data => {
      this.subject.next(data);
    });

  }

  getAllTickets(id: string): Observable<TicketsTablesDto[]> {


    return this.http.get<TicketsTablesDto[]>(this.url+'d/t/g/t/pa/all/'+id);

  }
   getAllAddons(id: string) {


    return this.http.get<GetAddonsNameDto[]>(this.url+'d/ao/g/pa/all/'+id);

  }

  getOnePromote(id: string): Observable<singlePromote> {


    return this.http.get<singlePromote>(this.url + 'd/pc/g/i/' + id);

  }
  postPromote(data: promoteFormdto) {

    this.http.post(this.url + 'd/pc/c/i', data,
      {
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json'
          // Authorization: 'my-auth-token'
        }
      }
    ).subscribe(x => this.getAllPromo(data.eventId));
  }
  postMultiplePromote(data: MultiplepromoteFormdto) {

    this.http.post(this.url + 'd/pc/c/m', data,
      {
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json'
          // Authorization: 'my-auth-token'
        }
      }
    ).subscribe(x => this.getAllPromo(data.eventId));
  }
  deletPromo(id:string){
    return this.http.delete(this.url+'d/pc/remove/'+id)
  }
}
