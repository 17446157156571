import { DOCUMENT } from '@angular/common';
import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, NonNullableFormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { TicketFormStepsService } from 'src/app/services/ticket-form-steps.service';

@Component({
    selector: 'app-ticket-settings',
    templateUrl: './ticket-settings.component.html',
    styleUrls: ['./ticket-settings.component.scss']
})
export class TicketSettingsComponent implements OnInit {
    submitted: boolean = false;
    visibilty = [
        { name: 'Hidden', code: true },
        { name: 'Visible', code: false },
    ];
    ticketFormSetting!: FormGroup;
    constructor(private fb: NonNullableFormBuilder, private translate: TranslateService, private router: Router, private activatedRoute: ActivatedRoute, public srv: TicketFormStepsService, @Inject(DOCUMENT) public document: Document) {
        this.translate.onLangChange.subscribe(() => {
            this.updateTranslateio();
        });
    }
    ngOnInit(): void {
        this.updateTranslateio();
        this.initialize();
    }
    initialize() {
        this.ticketFormSetting = this.fb.group({
            id: [],

            hidden: [, Validators.required],
            minQuantity: [, Validators.required],
            maxQuantity: [, [Validators.required]],
            minNumber: [, Validators.required],
            maxNumber: [, [Validators.required]],
            eticket: [],
            willCall: [],

        })
    }

    updateTranslateio() {
        this.visibilty = [
            { name: this.translate.instant('TcktFrmStngsTitle003SlctChois001'), code: true },
            { name: this.translate.instant('TcktFrmStngsTitle003SlctChois002'), code: false },
        ];
    }

    cancel() {
        this.srv.clear()
        this.router.navigate(['tickets'], { relativeTo: this.activatedRoute.parent.parent.parent });
        // this.router.navigate([
        //     {
        //         outlets: { 'tickets-steps': ['tickets-all'] }
        //     }
        //   ],{relativeTo: this.activatedRoute.parent.parent});
    }
    submit() {
      //  console.log(this.srv.myform)
        if (this.validateticketSetting()) {

            this.srv.Finish()
            this.srv.clear()
            this.router.navigate(['tickets'], { relativeTo: this.activatedRoute.parent.parent.parent });

        } else {
            this.submitted = true;
        }
        // this.router.navigate([
        //     {
        //         outlets: { 'tickets-steps': ['tickets-all'] }
        //     }
        //   ],{relativeTo: this.activatedRoute.parent.parent});
    }
    previous() {
        this.router.navigate(['date'], { relativeTo: this.activatedRoute.parent });
    }
    validateticketSetting() {
        return this.srv.myform.controls['minQuantity'].valid && this.srv.myform.controls['maxQuantity'].valid
            && this.srv.myform.controls['minNumber'].valid && this.srv.myform.controls['maxNumber'].valid
            && this.srv.myform.controls['hidden'].valid && this.srv.myform.valid;
    }
}
