import { Component } from '@angular/core';

@Component({
  selector: 'app-tryr',
  templateUrl: './tryr.component.html',
  styleUrls: ['./tryr.component.scss']
})
export class TryrComponent {
  data = [
    { id: 1, name: 'John', age: 25 },
    { id: 1, name: 'John', age: 30 },
    { id: 2, name: 'Jane', age: 28 },
    { id: 2, name: 'Jane', age: 35 },
    { id: 3, name: 'Mike', age: 40 },
    { id: 3, name: 'Mike', age: 45 }
  ];

  cols = [
    { field: 'id', header: 'ID' },
    { field: 'name', header: 'Name' },
    { field: 'age', header: 'Age' }
  ];
}
