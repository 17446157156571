

<div class="container">
    <div class="card custom-table-border">
        <div class="card-header border-0 text-center bg-primary rounded-top-23 ">
            <h1 class="fw-bold text-white mt-2">{{'CopAddOnsTitl1'|translate}} </h1>
            </div>
        <div class="card-body">
            <div class="card-body p-0">
                <div class="container">
                    <div class="d-flex justify-content-between align-items-center">
                        <h2 class="fw-bold">{{'CopAddOnsTitl2'|translate}} </h2>
                    </div>
                    <div class="row">

                        <div class="col-12  p-0">
                            <form [formGroup]="copyform" (submit)="submit()" autocomplete="off">
                                <div class="row">


                                    <div class="p-input-filled  col-md-6 col-12 mb-3">
                                        <span class="p-float-label">
                                            <p-dropdown [showClear]="true"
                                             [autoDisplayFirst]="false"
                                             pTooltip="Select event that you want to copy add-ons from it"
                                              showDelay="1000"  tooltipPosition="bottom" [filter]="true"
                                               [styleClass]="'w-100'"  formControlName="non"
                                                [options]="allevents" optionLabel="name"
                                                optionValue="id" (onChange)="getaddons($event)"></p-dropdown>
                                                <label >{{'CopAddOnsLable1'|translate}} </label>
                                        </span>
                                    </div>

                                    <div class="p-input-filled col-md-6 col-12 mb-2 ">
                                        <span class="p-float-label">
                                        <p-multiSelect  pTooltip="Select add-ons that you want to apply this code on it"
                                        showDelay="1000" tooltipPosition="bottom"
                                        [styleClass]="'w-100'"  [options]="groups"
                                            optionLabel="name" optionValue="id" defaultLabel="Select a Ticket" formControlName="groupId" appendTo="body"
                                            [ngClass]="{'ng-invalid ng-dirty':copyform.get('groupId')?.touched && copyform.get('groupId')?.invalid ||copyform.get('groupId')?.invalid && submitted}"></p-multiSelect>
                                            <label for="">{{'CopAddOnsLable2'|translate}} </label>
                                        </span>
                                        <small *ngIf="copyform.get('groupId')?.touched && copyform.get('groupId')?.invalid ||copyform.get('groupId')?.invalid && submitted " class="p-error" text="">Please Select a Ticket</small>
                                    </div>
                                </div>




                            <div class=" d-flex  justify-content-center">
                                <div class="banner-btn rounded-pill mx-1">


                                    <button pButton pRipple pTooltip="Cancel and dismiss changes" type="button" showDelay="1000"
                                    tooltipPosition="bottom" class="rounded-pill save-btn text-center ms-1"
                                    (click)="cancel()">{{'CanclBtn'|translate}} <span></span></button>
                                    <button pButton pRipple type="submit" pTooltip="Save and go to next step" showDelay="1000"
                                    tooltipPosition="right" class="rounded-pill next-btn ms-1">{{'NextBtn'|translate}} <i class="pi mt-1"
                                        [class.pi-angle-right]="document.documentElement.dir === 'ltr'"
                                        [class.pi-angle-left]="document.documentElement.dir === 'rtl'"></i><span></span></button>
                                </div>
                            </div>



                            </form>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>

</div>

