<div class="events-area  py-3">
    <div class="container">
        <p-toast></p-toast>
        <div class="card custom-table-border">
            <!-- <div class="card-header text-center bg-primary rounded-top-23 ">
                <h2 class="fw-bold text-white mt-2">Organizers</h2>
                </div> -->
            <div class="card-header text-center bg-primary rounded-top-23">
                <h1 class="fw-bold text-white mt-2">{{'OrgnzrTblTitl'|translate}}</h1>
            </div>


            <div *ngIf="loader.show1" @fadeOut class="d-flex justify-content-center align-items-center"
                style="border-radius: 25px;top : 0;position: absolute;z-index: 1050;background-color:#fff;width: 100%;height: -webkit-fill-available;right: 0;bottom: 0;left: 0;">
            </div>
            <p-table #dt responsiveLayout="stack" [breakpoint]="'991px'" [value]="data" [rows]="5"
                [paginator]="data?.length > 5" [globalFilterFields]="['name']"
                [tableStyle]="{'min-width': '100%','max-width':'100%'}" [rowHover]="true" dataKey="id"
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                [showCurrentPageReport]="true" class="">
                <ng-template pTemplate="caption">
                    <div>
                        <div class="d-sm-flex d-block align-items-center justify-content-sm-between text-center">
                            <div class=" ms-md-4  mb-sm-0 mb-2">
                                <!-- class="rounded-pill add-new-btn mr-2" -->
                                <button pButton pRipple *ngIf="auth.securityObject.checkClaim(10105030203)"
                                    class="rounded-pill add-new-btn mx-2 mb-md-0 mb-2" (click)="openNew()"><i
                                        class="fa-solid fa-plus"></i>
                                    {{'OrgnzrTblBtn001'|translate}}<span></span></button>

                                <!-- rounded-pill save-btn  -->
                                <button [disabled]="OrgServ.isSubmitting" pButton
                                    *ngIf="auth.securityObject.checkClaim(10105030201)"
                                    class="rounded-pill save-btn mb-md-0 mb-2 {{OrgServ.isSubmitting? 'bg-disabled' : ''}}"
                                    (click)="openNewTeam()"><i *ngIf="OrgServ.isSubmitting"
                                        class="fas fa-spinner fa-spin"></i><i *ngIf="!OrgServ.isSubmitting"
                                        class="fa-solid fa-plus"></i>
                                    {{'OrgnzrTblBtn002'|translate}}<span></span></button>
                            </div>
                            <div class="p-input-filled me-md-3 ">
                                <span class="p-input-icon-left">
                                    <i class="pi pi-search"></i>
                                    <input pInputText type="text" class="form-control"
                                        (input)="dt.filterGlobal($event.target.value, 'contains')"
                                        placeholder="{{'Search...'|translate}}" />
                                </span>
                            </div>
                        </div>
                    </div>
                </ng-template>
                <ng-template pTemplate="header" class="">
                    <tr>
                        <th class="fw-bold text-center" style="min-width:9rem">{{'OrgnzrTblTh001'|translate}}</th>
                        <th pSortableColumn="name" class="fw-bold text-center">{{'OrgnzrTblTh002'|translate}}

                        </th>
                        <th pSortableColumn="numOfEvents" class="fw-bold text-center">{{'OrgnzrTblTh003'|translate}}

                        </th>
                        <th pSortableColumn="numOfFollowers" class="fw-bold text-center">
                            {{'OrgnzrTblTh004'|translate}}</th>
                        <th class="fw-bold text-center"> {{'OrgnzrTblTh005'|translate}}</th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-organizer>
                    <tr>
                        <td class="text-md-center padding-custom text-end Image-cutom"><span
                                class=" text-start fw-bold "></span>
                            <img [src]="url+organizer.imageUrl" [alt]="organizer.name" width="100"
                                class="shadow-4 rounded-2" />
                        </td>
                        <!-- <td class="text-md-center padding-custom text-end"><span class="headers-cutom text-start fw-bold ">Name</span>{{organizer.name}}</td> -->
                        <td class="text-md-center padding-custom text-lenght-larg text-end"><span
                                class="headers-cutom text-start fw-bold ">{{'OrgnzrTblTh002'|translate}}</span><span
                                class="text-lenght">{{organizer.name}}</span></td>
                        <td class="text-md-center padding-custom text-end"><span
                                class="headers-cutom text-start fw-bold ">{{'OrgnzrTblTh003'|translate}}</span>{{organizer.numOfEvents}}
                        </td>
                        <td class="text-md-center padding-custom text-end"><span
                                class="headers-cutom text-start fw-bold ">{{'OrgnzrTblTh004'|translate}}</span>{{organizer.numOfFollowers}}
                        </td>
                        <!-- <td><p-rating [ngModel]="event.gross" [readonly]="true" [cancel]="false"></p-rating></td> -->
                        <!-- <td><span [class]="'product-badge status-' + (product.inventoryStatus ? product.inventoryStatus.toLowerCase() : '')">{{product.inventoryStatus}}</span></td> -->
                        <td class="text-center">
                            <button pButton pRipple icon="fa-regular fa-users-gear"
                                pTooltip="{{'OrgnzrTblActionBtn001Pop'|translate}}"
                                *ngIf="auth.securityObject.checkClaim(10105030201)" tooltipPosition="top"
                                class="p-button-rounded p-button-primary m-1" (click)="manage(organizer.id)"></button>
                            <p-overlayPanel #op>
                                <button pButton pRipple icon="pi pi-pencil"
                                    *ngIf="auth.securityObject.checkClaim(10105030202)"
                                    pTooltip="{{'OrgnzrTblActionBtn002Pop'|translate}}" tooltipPosition="left"
                                    class="p-button-rounded p-button-primary m-1"
                                    (click)="edit(organizer.id)"></button><br
                                    *ngIf="auth.securityObject.checkClaim(10105030202)">
                                <button pButton pRipple icon="pi pi-eye"
                                    pTooltip="{{'OrgnzrTblActionBtn003Pop'|translate}}" tooltipPosition="left"
                                    class="p-button-rounded p-button-primary m-1"
                                    (click)="preview(organizer.id)"></button><br>
                                <button *ngIf="auth.securityObject.checkClaim(10105030101)" pButton pRipple
                                    icon="fa-solid fa-plus" pTooltip="{{'OrgnzrTblActionBtn004Pop'|translate}}"
                                    tooltipPosition="left" class="p-button-rounded p-button-primary m-1"
                                    (click)="createEvent(organizer.id)"></button><br
                                    *ngIf="auth.securityObject.checkClaim(10105030101)">

                                <p-confirmPopup></p-confirmPopup>
                                <button pButton pRipple icon="pi pi-trash" class="p-button-rounded p-button-danger m-1"
                                    (click)="delete($event,organizer.id)"></button>
                            </p-overlayPanel>
                            <button pButton pRipple (click)="op.toggle($event)" icon="far fa-ellipsis-v"
                                class="p-button-rounded p-button-primary m-1"></button>
                        </td>
                    </tr>
                </ng-template>
                <ng-template pTemplate="summary">
                    <div class="flex align-items-center justify-content-between">
                        <!--                            In total there are {{event ? data.length : 0 }} events.-->
                    </div>
                </ng-template>
            </p-table>
        </div>


    </div>
</div>
