<div class="container">
    <form [formGroup]="form" (ngSubmit)="submit()" autocomplete="off">
        <div class="card custom-table-border">
            <!-- <div class="card-header text-center bg-primary rounded-top-23" id="PartialTitle">
                <h1 class="text-white">Order form</h1>
            </div> -->
            <div class="card-header border-0 text-center bg-primary rounded-top-23 ">
                <h1 class="fw-bold text-white mt-2">{{'OrdrFrmTitl'|translate}}</h1>
                </div>
            <div class="card-body" id="PartialEventLoader">
                <div class="card-body p-0" id="evt">
                    <div *ngIf="loader.show1" @fadeOut class="d-flex justify-content-center align-items-center"
                         style="border-radius: 25px;top : 0;position: absolute;z-index: 1050;background-color:#fff;width: 100%;height: -webkit-fill-available;right: 0;bottom: 0;left: 0;">
                    </div>
                    <div class="row">
                        <div class="col-12 mb-2"></div>
                        <div class="col-12 mb-2">
                            <p>
                              {{'OrdrFrmDsc'|translate}}
                            </p>
                        </div>
                        <div class="col-12" id="PartialEventLoader1">
                            <div class="col-12 p-0 mb-2">
                                <h2 class="fs-md-2">{{'OrdrFrmTitle001'|translate}}</h2>
                                <div class="row">
                                    <div class="col-12">
                                        <input type="radio" class="btn-check " formControlName="orderFormType"
                                               id="buyer" name="orderFormType" [value]="true" />
                                        <label class="btn btn-outline-primary me-2 mb-sm-0 mb-2" for="buyer">{{'OrdrFrmTitle001Btn001'|translate}}
                                            </label>
                                        <input type="radio" class="btn-check" formControlName="orderFormType"
                                               id="attendee" name="orderFormType" [value]="false"/>
                                        <label class="btn btn-outline-primary mb-sm-0 mb-2" for="attendee">{{'OrdrFrmTitle001Btn002'|translate}}</label>
                                    </div>
                                    <div class="col-6" *ngIf="!form.get('orderFormType').value">
                                        <div class="col-12 mb-2 ">
                                            <h6 class="mt-2">
                                                {{'OrdrFrmTitle001Btn002Dsc'|translate}}
                                            </h6>

                                        </div>
                                        <div class="p-input-filled col-md-6 col-12 mb-2 ">
                                         <span class="p-float-label">
                                        <p-multiSelect pTooltip="{{'OrdrFrmTitle001Btn002Slct001Pop'|translate}}"
                                                       showDelay="1000" tooltipPosition="bottom"
                                                       [styleClass]="'w-100'"
                                                       [options]="tickets"
                                                       optionLabel="name" optionValue="id"
                                                       defaultLabel="Select a Ticket" formControlName="ticketsIds"
                                                       appendTo="body"
                                                       [ngClass]="{'ng-invalid ng-dirty':form.get('tickets')?.touched && form.get('tickets')?.invalid ||form.get('tickets')?.invalid && submitted}"></p-multiSelect>
                                                   <label for="">{{'OrdrFrmTitle001Btn002Slct001'|translate}}</label>
                                                 </span>
                                            <small
                                                *ngIf="form.get('tickets')?.touched && form.get('tickets')?.invalid ||form.get('tickets')?.invalid && submitted "
                                                class="p-error" text="">Please Select a Ticket</small>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <hr/>
                            <h2 data-id="0" id="ttt">
                               {{'OrdrFrmTitle002'|translate}}
                            </h2>
                            <hr/>
                            <p>
                                <!-- We collect <b>first name, last name</b> and
                                <b>email</b> by default -->
                                {{'OrdrFrmTitle002Dsc'|translate}}
                            </p>
                            <div class="row my-2">
                                <div class="col-md-8 col-4">
                                    <h6 class="fw-bold details-bar-font">{{'OrdrFrmTitle002Titl001'|translate}}</h6>
                                </div>
                                <div class="col-md-2 col-4">
                                    <h6 class="fw-bold details-bar-font">{{'OrdrFrmTitle002Titl002'|translate}}</h6>
                                </div>
                                <div class="col-md-2 col-4">
                                    <h6 class="fw-bold details-bar-font">{{'OrdrFrmTitle002Titl003'|translate}}</h6>
                                </div>
                            </div>
                            <div formArrayName="questions">
                                <div *ngFor="
                                        let question of formQuestions.controls;
                                        let i = index
                                    " [formGroupName]="i" class="row mb-1">
                                    <div class="col-md-8 col-4">
                                        <span>
                                            {{ question.get("question").value|translate }}
                                        </span>
                                    </div>
                                    <div class="col-md-2 col-4">
                                        <div class="square-switch">
                                            <p-inputSwitch formControlName="isShown"></p-inputSwitch>
                                        </div>
                                    </div>
                                    <div class="col-md-2 col-4">
                                        <div class="square-switch">
                                            <p-inputSwitch formControlName="isRequired"></p-inputSwitch>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row mb-1">
                                <div class="col-12 d-flex justify-content-center">
                                    <a (click)="onAddQuestionClicked()" class="btn rounded-pill save-btn">{{'OrdrFrmQstionBtn'|translate}}<span></span></a>
                                </div>
                            </div>
                            <div class="row mt-4" id="moreQuestion" *ngIf="userQuestions.controls.length > 0">
                                <div class="col-12 mb-md-3">
                                    <div class="row">
                                        <div class="col-md-6 col-12 text-md-start text-center">
                                            <h3 class="more-info-fs fw-bold">{{'OrdrFrmTitle003'|translate}}</h3>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12 border-bottom border-primary border-opacity-50">
                                    <div class="row d-md-flex d-none ">
                                        <div class="col-md-6 ">
                                            <h5>{{'OrdrFrmTitle003Title001'|translate}}</h5>
                                        </div>
                                        <div class="col-md-2 col-3">
                                            <h5>{{'OrdrFrmTitle003Title002'|translate}}</h5>
                                        </div>
                                        <div class="col-md-2 col-3">
                                            <h5>{{'OrdrFrmTitle003Title003'|translate}}</h5>
                                        </div>
                                        <div class="col-md-2 col-3">
                                            <h5>{{'OrdrFrmTitle003Title004'|translate}}</h5>
                                        </div>
                                    </div>
                                </div>
                                <div formArrayName="userQuestions">
                                    <div *ngFor="
                                            let question of userQuestions.controls;
                                            let i = index
                                        "[formGroupName]="i" class="col-12  ">
                                        <div
                                            class="row text-md-start text-center align-items-center border border-primary rounded-4 border-opacity-50 bg-primary-opacity">
                                            <div class="col-md-6 col-12 ">
                                                <h4 class="d-md-none d-block">{{'OrdrFrmTitle003Title001'|translate}}</h4>
                                                <span>
                                                    {{
                                                    question.get("question").value|translate
                                                    }}
                                                </span>
                                            </div>
                                            <div class="col-md-2 col-12">
                                                <h4 class="d-md-none d-block">{{'OrdrFrmTitle003Title002'|translate}}</h4>
                                                <div class="square-switch ">
                                                    <p-inputSwitch formControlName="isShown"></p-inputSwitch>
                                                </div>
                                            </div>
                                            <div class="col-md-2 col-12">
                                                <h4 class="d-md-none d-block">{{'OrdrFrmTitle003Title003'|translate}}</h4>
                                                <div class="square-switch  ">
                                                    <p-inputSwitch formControlName="isRequired" value></p-inputSwitch>
                                                </div>
                                            </div>
                                            <div class="col-md-2 col-12 d-flex gap-1">
                                                <button class="btn btn-primary rounded-5" (click)="Edit(i)"
                                                        type="button"><i class="fa-light fa-pen-to-square"></i></button>
                                                <button class="btn btn-danger rounded-5" (click)="onDeleteClicked(i)"
                                                        type="button"><i class="fa-regular fa-trash-can"></i></button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row" *ngIf="!loader.show1">
            <div class="col-12 d-flex justify-content-lg-center justify-content-center">
                <div class="banner-btn rounded-pill  d-flex justify-content-center gap-1">
                    <button [disabled]="isSubmitting" type="submit"
                            class="rounded-pill save-btn {{isSubmitting? 'bg-disabled' : ''}}" pTooltip="Save changes"
                            showDelay="1000" tooltipPosition="right">
                        <i *ngIf="isSubmitting" class="fas fa-spinner fa-spin"></i>
                        {{'SavBtn'|translate}}  <span></span>
                    </button>
                    <button type="submit"
                    class="rounded-pill save-btn" type="button" pTooltip="Preview order form"
                    showDelay="1000" tooltipPosition="right" (click)="showPreview()">
                          {{'SrveyPrvewTitl'|translate}} <span></span>
                    </button>
                </div>
            </div>
        </div>
    </form>
</div>

