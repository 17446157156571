<form [formGroup]="srv.myform" (submit)="submit()" autocomplete="off">
    <div class="row">
        <div class="col-12">
            <h3>{{'TcktFrmSlctEvetInstncTitle001'|translate}} </h3>
        </div>

        <div class="p-input-filled col-12 mb-2">
            <span class="p-float-label">

                <p-multiSelect pTooltip="{{'TcktFrmSlctEvetInstncTitle001InptPop'|translate}}" showDelay="1000"
                    tooltipPosition="top" [options]="eventInstance" [styleClass]="'w-100'" [optionLabel]="'name'"
                    [optionValue]="'id'" display="chip" formControlName="events" inputId="eventInstance"
                    [ngClass]="{'ng-invalid ng-dirty':(srv.myform.get('events')?.touched && srv.myform.get('events')?.invalid)||(submitted&& srv.myform.get('events').invalid)}"></p-multiSelect>
                <label class="b-5 ms-2" for="eventInstance">{{'TcktFrmSlctEvetInstncTitle001Inpt'|translate}}</label>
            </span>
            <small class="p-error" *ngIf="(  srv.myform.get('events')?.touched &&
                    srv.myform.get('events')?.invalid)||(submitted&& srv.myform.get('events').invalid)
                        ">Please add Event instances</small>
        </div>

    </div>
    <div class="col-12 d-flex  justify-content-center">
        <button pButton pRipple type="button" class="rounded-pill cancel-btn" (click)="cancel()"
            pTooltip="Cancel and dismiss changes" showDelay="1000"
            tooltipPosition="left">{{'CanclBtn'|translate}}<span></span></button>
        <button pButton pRipple type="submit" pTooltip="Save and go to next step" showDelay="1000"
            tooltipPosition="right" class="rounded-pill next-btn ms-1">{{'NextBtn'|translate}} <i class="pi mt-1"
                [class.pi-angle-right]="document.documentElement.dir === 'ltr'"
                [class.pi-angle-left]="document.documentElement.dir === 'rtl'"></i><span></span></button>
    </div>
</form>