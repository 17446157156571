import { Component, OnDestroy, OnInit } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../../../../../environments/environment';
import { EventStepsService } from '../../../../../services/event-steps.service';
import { MessageService } from 'primeng/api';
import { EventsService } from 'src/app/services/events.service';
import { DatePipe } from '@angular/common';
import { Subscription } from 'rxjs';
import { DropDownItem } from 'src/app/Interfaces/DropDownItem';


@Component({
    selector: 'app-publish-event',
    templateUrl: './publish-event.component.html',
    styleUrls: ['./publish-event.component.scss']
})
export class PublishEventComponent implements OnInit, OnDestroy {
    url = environment.Api;
    readyToPublish = false;
    subs: Subscription[] = []
    isPub: boolean;
    eventInstance: DropDownItem[];
    data: Object;

    singlePublish: boolean = false;
    constructor(private http: HttpClient,
        private srv: EventStepsService,
        private messageService: MessageService,
        private EventSrv: EventsService,
        private datePipe: DatePipe
    ) {
    }
    ngOnDestroy(): void {
        this.subs.forEach(el => {
            el.unsubscribe();
        });
    }

    getDateOntomeZoneformat(d, x) {
        let t = this.srv.offSet * 60;
        let MyDate = new Date(d).setMinutes(new Date(d).getMinutes() + (t));
        var l = "";
        switch (x) {
            case 'full': if (new Date(MyDate).getFullYear() == new Date(Date.now()).getFullYear()) {
                l = this.datePipe.transform(new Date(MyDate), 'EEE MMMM d hh:mm a');

            } else {
                l = this.datePipe.transform(new Date(MyDate), 'EEE MMMM d,y hh:mm a');

            }
                break;
            case 'month': l = this.datePipe.transform(new Date(MyDate), 'MMM');
                break;
            case 'day': l = this.datePipe.transform(new Date(MyDate), 'dd');
                break
        }
        return l




        // return new Date(MyDate).toLocaleString();
    }

    getDateOntomeZone(d: string | number) {
        var eventOffset = this.srv.offSet * 60;
        var MyDate = new Date(d).setMinutes(new Date(d).getMinutes() + (eventOffset));
        if (new Date(MyDate).getFullYear() == new Date(Date.now()).getFullYear()) {
            var l = this.datePipe.transform(new Date(MyDate), 'EEE MMMM d hh:mm a');
            return l;
        } else {
            var l = this.datePipe.transform(new Date(MyDate), 'EEE MMMM d,y hh:mm a');
            return l;
        }
    }

    getEvents(x) {

        switch (x) {
            case 'rec':
                this.EventSrv.getReccuringInstances(this.srv.id).subscribe(x => {

                    var t = x.map(y => {
                        return y.id;
                    })
                    const params = { t };
                    this.http.post(environment.Api + 'd/e/publishs', t).subscribe(x => {
                    //    console.log(x)
                        this.data = [];
                        this.data = x;
                    })
                });
                break;
            case 'col':
                this.EventSrv.GetSubEvents(this.srv.id).subscribe(x => {

                    var t = x.map(y => {
                        return y.id;
                    })
                    const params = { t };
                    this.http.post(environment.Api + 'd/e/publishs', t).subscribe(x => {
                     //   console.log(x)
                        this.data = [];
                        this.data = x;
                    })
                });

        }


    }
    ngOnInit(): void {

        var l = this.srv.state$.subscribe(x => {

            if (this.srv.isRecurring && !this.srv.isColection) {
                this.getEvents('rec');
                this.singlePublish = false;
            }

            else {
                if (this.srv.isColection) {
                    this.getEvents('col');
                    this.singlePublish = false;
                }
                else {

                    this.singlePublish = true;
                    if (this.srv.progressValue === 100) {
                        this.readyToPublish = true;
                    }
                    else {
                        this.readyToPublish = false;
                    }
                    this.srv.publishSTate$.subscribe(x => {
                        this.isPub = x;
                    })
                }


            }



        })
        this.subs.push(l)



    }
    publish(id?) {

        if (!this.srv.isRecurring && !this.srv.isColection) {
            var m = this.srv.state$.subscribe(x => {

                if (this.srv.progressValue === 100) {
                    this.http.post(environment.Api + 'd/e/publish', { id: x.id }

                    ).subscribe(x => {
                        this.srv.isPublished = !this.srv.isPublished
                        this.srv.setpublish();
                        this.srv.publishSTate$.subscribe(x => {
                            this.isPub = x;
                        })
                        this.messageService.add({ severity: 'success', summary: this.isPub ? 'Unpublished Successfully' : 'Published Successfully' });
                        //this.isPub = !this.isPub
                    });
                }
            }

            );
            this.subs.push(m);
        } else {

            var m = this.http.post(environment.Api + 'd/e/publish', { id: id }

            ).subscribe(x => {
                // this.srv.isPublished =  !this.srv.isPublished
                // this.srv.setpublish();
                // this.srv.publishSTate$.subscribe(x=>{
                //     this.isPub = x;
                // })
                if (this.srv.isRecurring && !this.srv.isColection) {
                    this.getEvents('rec');
                }
                else {
                    if (this.srv.isColection) {
                        this.getEvents('col');
                    }
                }

                this.messageService.add({ severity: 'success', summary: this.isPub ? 'Unpublished Successfully' : 'Published Successfully' });
                //this.isPub = !this.isPub
            });
            this.subs.push(m);

        }




    }


}
