import { Component, EventEmitter, forwardRef, Input, OnChanges, Output, SimpleChanges, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { DynamicDialogConfig, DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { FileUpload } from 'primeng/fileupload';
import { Observable } from 'rxjs';
import { CropperComponent } from '../cropper/cropper.component';

import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
    selector: 'app-my-image-uploader',
    templateUrl: './my-image-uploader.component.html',
    styleUrls: ['./my-image-uploader.component.scss'],
    providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => MyImageUploaderComponent),
        multi: true
    }]
})
export class MyImageUploaderComponent implements OnChanges, ControlValueAccessor {
    @Input() img: string;
    @Input() format: string = 'png';
    @Input() quality: string = '50'; // only works with jpeg
    @Input() aspectRatio: number = 2 / 1
    @Output() photo = new EventEmitter<string>();
    @Output() ischanged = new EventEmitter<boolean>()

    @ViewChild('aaass') aaass: FileUpload;
    imageChangedEvent: any = '';
    croppedImage: any = '';
    myFile: any = '';
    uploadedFiles: any[] = [];
    isDisabled: boolean;
    isTouched = false;
    onChange!: any;

    onTouch: any;
    constructor(public config: DynamicDialogConfig, private fb: FormBuilder, private dialogService: DialogService, public ref: DynamicDialogRef) { }
    writeValue(obj: any): void {

        this.croppedImage = obj;
        this.img = obj;
    }
    registerOnChange(fn: any): void {
        this.onChange = fn;
    }
    registerOnTouched(fn: any): void {
        this.onTouch = fn;
    }
    setDisabledState?(isDisabled: boolean): void {
        this.isDisabled = isDisabled;
    }


    ngOnChanges(changes: SimpleChanges): void {

        if (changes.img) {
            this.croppedImage = this.img
        }
    }

    clear() {
        this.aaass.clear();
    }
    onUpload(event): void {
        for (const file of event.files) {
            this.uploadedFiles.push(file);
        }
        this.imageChangedEvent = event.originalEvent;
        this.ischanged.emit(true);
        this.show();
    }
    onSelect(event): void {
        this.myFile = event.currentFiles[0];
        this.show();
    }
    show(): void {
        const ref = this.dialogService.open(CropperComponent, {
            data: {
                myFile: this.myFile,
                format: this.format ?? 'png',
                quality: this.quality ?? '50',
                aspectRatio: this.aspectRatio
            },
            header: 'Crop image',
            width: '100%',
            height: '100vh',
            closable: false
        });

        ref.onClose.subscribe((obj: any) => {
            if (obj.b === 'ok') {
                if (obj.a) {
                    this.croppedImage = obj.a;
                    this.photo.emit(obj.a);
                    this.ischanged.emit(true);

                    this.onChange(this.croppedImage)
                    if (!this.isTouched) {
                        this.isTouched = true;
                        this.onTouch();
                    }

                }
                else {
                    this.aaass.clear();
                }
            }
            else {

                this.aaass.clear();
            }


        });
    }
    deleteImg() {
        this.croppedImage = "";
        this.onChange(this.croppedImage)
        if (!this.isTouched) {
            this.isTouched = true;
            this.onTouch();
        }
    }
}
