<p-table [columns]="cols" [value]="data" rowGroupMode="rowspan" [groupRowsBy]="['id', 'name']">
    <ng-template pTemplate="header" let-columns>
      <tr>
        <th>ID</th>
        <th>Name</th>
        <th>Age</th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-rowData let-rowIndex="rowIndex" let-rowspan="rowspan">
      <tr>
        <td [attr.rowspan]="rowspan.id">{{rowData.id}}</td>
        <td [attr.rowspan]="rowspan.name">{{rowData.name}}</td>
        <td>{{rowData.age}}</td>
      </tr>
    </ng-template>
  </p-table> 
