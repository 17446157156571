<div class=" rounded-25 mt-3">
    <p-toast></p-toast>
    <div class="container rounded-top-right-25 rounded-top-left-25 bg-modified  p-0">
        <div class="d-flex align-items-center justify-content-start rounded-top-right-25 rounded-top-left-25 mb-3">

            <div class="  pt-3 bg-white organizer-space shadow-sm rounded-top-right-25 rounded-top-left-25 px-3 pb-4">
                <div class="row align-items-center position-relative">
                    <div class="col-lg-2 col-md-2 col-12 text-md-end text-start">
                            <img style="height: 80px; width: 80px;"
                            [src]="UserPhoto==''?'assets/img/courses/3SUBiaP.png':url+UserPhoto"

                            alt="Organizer Profile Photo" class=" rounded-circle  position-relative" />
                            <a routerLink="/update-profile-image" class="position-absolute">

                                <i class="fa-light fa-pen text-primary icon"></i>
                            </a>

                    </div>
                    <div class="col-lg-10 col-md-10 col-12">
                        <div class="d-flex align-items-center">
                            <h3>{{'Hi,'|translate}} <a  href="javascript:;"><u>{{ UserDetailsForm.get('firstName')?.value +" "+UserDetailsForm.get('lastName')?.value}}</u></a> </h3>
                            <!-- <i class="fa-solid fa-chevron-down ms-2"></i> -->
                        </div>
                        <div class="d-flex align-items-center">

                            <span class="me-2"><a routerLink="/Profile" style="color: blue;">{{'View'|translate}}</a></span>
                        </div>
                    </div>
                </div>
                <div class="row mt-5">

                    <p-accordion>
                        <p-accordionTab header="{{'ContactInformations'|translate}}">
                            <form  [formGroup]="UserDetailsForm">
                            <div class="row">

                                <div class="p-input-filled col-lg-4 col-md-6 col-12">
                                    <span class="p-float-label">
                                        <input type="text" formControlName="prefix" pTooltip="{{'homecontactinfotool001'|translate}}"
                                        showDelay="1000" tooltipPosition="top" pInputText class="form-control" [ngClass]="{
                                                'ng-invalid ng-dirty':
                                                UserDetailsForm.get('prefix')?.touched &&
                                                UserDetailsForm.get('prefix')?.invalid||(submitted&& UserDetailsForm.get('prefix').invalid)
                                            }" [class.ng-invalid.ng-dirty]="" #Prefix />
                                        <label class="form-lable font-size-14" for="Prefix">{{'Prefix'|translate}}</label>
                                    </span>
                                    <small class="p-error" *ngIf="UserDetailsForm.get('prefix')?.touched &&
                                    UserDetailsForm.get('prefix')?.invalid||(submitted&& UserDetailsForm.get('prefix').invalid)
                                        " >{{'homecontactinfotool001ErrMsg'|translate}}</small>

                                </div>
                                <div class="p-input-filled col-lg-4 col-md-6 col-12">
                                    <span class="p-float-label">
                                        <input type="text" formControlName="firstName" pTooltip="{{'homecontactinfotool002'|translate}}"
                                        showDelay="1000" tooltipPosition="top" pInputText class="form-control" [ngClass]="{
                                                'ng-invalid ng-dirty':
                                                UserDetailsForm.get('firstName')?.touched &&
                                                UserDetailsForm.get('firstName')?.invalid||(submitted&& UserDetailsForm.get('firstName').invalid)
                                            }" [class.ng-invalid.ng-dirty]="" #firstName />
                                        <label class="form-lable font-size-14" for="firstName">{{'First name'|translate}}</label>
                                    </span>
                                    <small class="p-error" *ngIf="UserDetailsForm.get('firstName')?.touched &&
                                    UserDetailsForm.get('firstName')?.invalid||(submitted&& UserDetailsForm.get('firstName').invalid)
                                        " >{{'homecontactinfotool002ErrMsg'|translate}}</small>

                                </div>
                                <div class="p-input-filled col-lg-4 col-md-6 col-12">
                                    <span class="p-float-label">
                                        <input type="text" formControlName="lastName" pTooltip="{{'homecontactinfotool003'|translate}}"
                                        showDelay="1000" tooltipPosition="top" pInputText class="form-control" [ngClass]="{
                                                'ng-invalid ng-dirty':
                                                UserDetailsForm.get('lastName')?.touched &&
                                                UserDetailsForm.get('lastName')?.invalid||(submitted&& UserDetailsForm.get('lastName').invalid)
                                            }" [class.ng-invalid.ng-dirty]="" #firstName />
                                        <label class="form-lable font-size-14" for="firstName">{{'Last name'|translate}}</label>
                                    </span>
                                    <small class="p-error" *ngIf="UserDetailsForm.get('lastName')?.touched &&
                                    UserDetailsForm.get('lastName')?.invalid||(submitted&& UserDetailsForm.get('lastName').invalid)
                                        " >{{'homecontactinfotool003ErrMsg'|translate}}</small>

                                </div>
                            </div>
                            <div class="row">


                                <div class="p-input-filled col-lg-4 col-md-6 col-12">
                                    <span class="p-float-label">
                                        <input type="text" formControlName="suffix" pTooltip="{{'homecontactinfotool004'|translate}}"
                                        showDelay="1000" tooltipPosition="top" pInputText class="form-control" [ngClass]="{
                                                'ng-invalid ng-dirty':
                                                UserDetailsForm.get('suffix')?.touched &&
                                                UserDetailsForm.get('suffix')?.invalid||(submitted&& UserDetailsForm.get('suffix').invalid)
                                            }" [class.ng-invalid.ng-dirty]="" #Suffix />
                                        <label class="form-lable font-size-14" for="Suffix">{{'Suffix'|translate}}</label>
                                    </span>
                                    <small class="p-error" *ngIf="UserDetailsForm.get('suffix')?.touched &&
                                    UserDetailsForm.get('suffix')?.invalid||(submitted&& UserDetailsForm.get('suffix').invalid)
                                        " >{{'homecontactinfotool004ErrMsg'|translate}}</small>

                                </div>
                                <div class="p-input-filled col-lg-4 col-md-6 col-12">


                                    <div class="p-inputgroup">
                                        <span class="p-inputgroup-addon "  (click)="op.toggle($event)" [ngClass]="{' currency-custom':document.documentElement.dir === 'ltr',' currency-custom-rtl':document.documentElement.dir === 'rtl'}">
                                          {{selectedMAsk1}}
                                          <i class="fa-solid fa-chevron-down ms-2" *ngIf="selectedMAsk1==''"></i>
                                        </span>

                                        <span class="p-float-label" *ngIf="UserDetailsForm.get('homePhone').value==''||click1" >
                                            <p-inputMask class="w-100" mask="{{mask1}}" [styleClass]="'form-control w-100'" formControlName="homePhone"></p-inputMask>


                                        <label class="form-label font-size-14" for="withoutgrouping"> {{'Home Phone'|translate}}</label>
                                    </span>

                                    <span class="p-float-label"  *ngIf="UserDetailsForm.get('homePhone').value!=''&&!click1">

                                        <p-inputNumber inputId="locale-user" [useGrouping]="false"
                                        class="w-100 " [inputStyleClass]="'form-control'" formControlName="homePhone"
                                        readonly="true"  (click)="getnew1()"></p-inputNumber>
                                        <label class="form-label font-size-14" for="withoutgrouping"> {{'Home Phone'|translate}}</label>
                                    </span>



                                    </div>


                                        <p-overlayPanel #op  [style]="{width: '450px'}">
                                            <ng-template pTemplate>
                                                <p-table #tb1 [value]="countryforphone" selectionMode="single"
                                                 [(selection)]="selectedProduct" (onRowSelect)="onRowSelect($event,op)"
                                                  [scrollable]="true" scrollHeight="400px" responsiveLayout="scroll"
                                                  [globalFilterFields]="['name','num','code']" >
                                                  <ng-template pTemplate="caption">
                                                    <div class="p-input-filled me-md-3 ">
                                                        <span class="p-input-icon-left">
                                                            <i class="pi pi-search"></i>
                                                            <input pInputText type="text" class="form-control"
                                                                (input)="tb1.filterGlobal($event.target.value, 'contains')"
                                                                placeholder="{{'Search...'|translate}}" />
                                                        </span>
                                                    </div>
                                              </ng-template>
                                                    <ng-template pTemplate="body" let-rowData let-product>
                                                        <tr [pSelectableRow]="rowData">
                                                            <td>{{product.name}}</td>

                                                        </tr>
                                                    </ng-template>
                                                </p-table>
                                            </ng-template>
                                        </p-overlayPanel>
                                </div>

                                <div class="p-input-filled col-lg-4 col-md-6 col-12">


                                    <div class="p-inputgroup">
                                        <span class="p-inputgroup-addon  " (click)="opp.toggle($event)"  [ngClass]="{' currency-custom':document.documentElement.dir === 'ltr',' currency-custom-rtl':document.documentElement.dir === 'rtl'}">
                                          {{selectedMAsk2}}
                                              <i class="fa-solid fa-chevron-down ms-2" *ngIf="selectedMAsk2==''"></i>
                                        </span>

                                        <span class="p-float-label" *ngIf="UserDetailsForm.get('mobilePhone').value == '' ||click2">
                                            <p-inputMask class="w-100"  mask="{{mask2}}" [styleClass]="'form-control w-100'" formControlName="mobilePhone"   ></p-inputMask>

                                        <label class="form-label font-size-14" for="withoutgrouping3"> {{'Mobile Phone'|translate}}</label>
                                    </span>

                                    <span class="p-float-label"  *ngIf="UserDetailsForm.get('mobilePhone').value!=''&&!click2">

                                        <p-inputNumber inputId="locale-user" [useGrouping]="false"
                                        class="w-100 " [inputStyleClass]="'form-control'" formControlName="mobilePhone"   readonly="true"  (click)="getnew2()"
                                     ></p-inputNumber>
                                        <label class="form-label font-size-14" for="withoutgrouping"> {{'Mobile Phone'|translate}}</label>
                                    </span>
                                    </div>


                                        <p-overlayPanel #opp  [style]="{width: '450px'}">
                                            <ng-template pTemplate>
                                                <p-table #td2 [value]="countryforphone" selectionMode="single" [(selection)]="selectedProduct" (onRowSelect)="onRowSelect2($event,opp)"
                                                  [scrollable]="true" scrollHeight="400px" responsiveLayout="scroll"
                                                  [globalFilterFields]="['name','num','code']" >
                                                  <ng-template pTemplate="caption">
                                                    <div class="p-input-filled me-md-3 ">
                                                        <span class="p-input-icon-left">
                                                            <i class="pi pi-search"></i>
                                                            <input pInputText type="text" class="form-control"
                                                                (input)="td2.filterGlobal($event.target.value, 'contains')"
                                                                placeholder="{{'Search...'|translate}}" />
                                                        </span>
                                                    </div>
                                              </ng-template>

                                                    <ng-template pTemplate="body" let-rowData let-product>
                                                        <tr [pSelectableRow]="rowData">
                                                            <td>{{product.name}}</td>

                                                        </tr>
                                                    </ng-template>
                                                </p-table>
                                            </ng-template>
                                        </p-overlayPanel>
                                </div>
                            </div>
                            <div class="row">

                                <div class="p-input-filled col-lg-4 col-md-6 col-12">
                                    <span class="p-float-label">
                                        <input type="text" formControlName="job" pTooltip="{{'homecontactinfotool005'|translate}}"
                                        showDelay="1000" tooltipPosition="top" pInputText class="form-control" [ngClass]="{
                                                'ng-invalid ng-dirty':
                                                UserDetailsForm.get('job')?.touched &&
                                                UserDetailsForm.get('job')?.invalid||(submitted&& UserDetailsForm.get('job').invalid)
                                            }" [class.ng-invalid.ng-dirty]="" #JobTitle />
                                        <label class="form-lable font-size-14" for="JobTitle">{{'Job Title'|translate}}</label>
                                    </span>
                                    <small class="p-error" *ngIf="UserDetailsForm.get('job')?.touched &&
                                    UserDetailsForm.get('job')?.invalid||(submitted&& UserDetailsForm.get('job').invalid)
                                        " >{{'homecontactinfotool005ErrMsg'|translate}}</small>
                                </div>

                                <div class="p-input-filled col-lg-4 col-md-6 col-12">
                                    <span class="p-float-label">
                                        <input type="text" formControlName="company" pTooltip="{{'homecontactinfotool006'|translate}}"
                                        showDelay="1000" tooltipPosition="top" pInputText class="form-control" [ngClass]="{
                                                'ng-invalid ng-dirty':
                                                UserDetailsForm.get('company')?.touched &&
                                                UserDetailsForm.get('company')?.invalid||(submitted&& UserDetailsForm.get('company').invalid)
                                            }" [class.ng-invalid.ng-dirty]="" #Company />
                                        <label class="form-lable font-size-14" for="Company">{{'CompanyOrganization'|translate}}</label>
                                    </span>
                                    <small class="p-error" *ngIf="UserDetailsForm.get('company')?.touched &&
                                    UserDetailsForm.get('company')?.invalid||(submitted&& UserDetailsForm.get('company').invalid)
                                        " >{{'homecontactinfotool006ErrMsg'|translate}}</small>

                                </div>


                                <div class="p-input-filled col-lg-4 col-md-6 col-12">
                                    <span class="p-float-label">
                                        <input type="text" formControlName="website" pTooltip="{{'homecontactinfotool007'|translate}}"
                                        showDelay="1000" tooltipPosition="top" pInputText class="form-control" [ngClass]="{
                                                'ng-invalid ng-dirty':
                                                UserDetailsForm.get('website')?.touched &&
                                                UserDetailsForm.get('website')?.invalid||(submitted&& UserDetailsForm.get('website').invalid)
                                            }" [class.ng-invalid.ng-dirty]="" #Website />
                                        <label class="form-lable font-size-14" for="Website">{{'Website'|translate}}</label>
                                    </span>
                                    <small class="p-error" *ngIf="UserDetailsForm.get('website')?.touched &&
                                    UserDetailsForm.get('website')?.invalid||(submitted&& UserDetailsForm.get('website').invalid)
                                        " >{{'homecontactinfotool007ErrMsg'|translate}}</small>

                                </div>
                            </div>
                           <div class="row">
                            <div class="col-12 d-flex  justify-content-center">
                                <div class="banner-btn rounded-pill">

                                    <button pTooltip="Save and go to next step" showDelay="1000" tooltipPosition="right" pButton pRipple
                                     (click)="save('info')" class="rounded-pill next-btn  ms-1 mt-md-0 mt-1">{{'SaveBtn'|translate}} <span></span></button>
                                </div>
                            </div>
                            </div>
                        </form>
                        </p-accordionTab>
                        <p-accordionTab header="{{'HomeAddress'|translate}}">
                            <div class="row">
                                <app-location-sertch (setAddress)="getAddressfromCom($event,'home')"></app-location-sertch>
                            </div>
                            <form [formGroup]="homeAddressForm">
                            <div class="row">
                                <div class="p-input-filled col-lg-4 col-md-6 col-12">
                                    <span class="p-float-label">
                                        <input type="text" formControlName="address" pTooltip="{{'Address1Input002tooltip'|translate}}"
                                            showDelay="1000" tooltipPosition="top" pInputText class="form-control"
                                            [class.ng-invalid.ng-dirty]="" #Address1Input001 [ngClass]="{
                                                'ng-invalid ng-dirty':
                                                  homeAddressForm.get('address')?.touched &&
                                                    homeAddressForm.get('address')?.invalid||(submitted&& homeAddressForm.get('address').invalid)}" />
                                        <!--  -->
                                        <label class="form-lable font-size-14" for="Address1Input001">{{'Address'|translate}}</label>
                                    </span>
                                    <small class="p-error" *ngIf="homeAddressForm.get('address')?.touched &&
                                                           homeAddressForm.get('address')?.invalid||(submitted&& homeAddressForm.get('address').invalid)
                                                                " >{{'AdreesErrorMesage001'|translate}}</small>
                                </div>
                                <div class="p-input-filled col-lg-4 col-md-6 col-12">
                                    <span class="p-float-label">
                                        <input type="text" formControlName="address2" pTooltip="{{'Address2Input001tooltip'|translate}}"
                                            showDelay="1000" tooltipPosition="top" pInputText class="form-control"
                                            [class.ng-invalid.ng-dirty]="" #Address2Input001 [ngClass]="{
                                                'ng-invalid ng-dirty':
                                                  homeAddressForm.get('address2')?.touched &&
                                                   homeAddressForm.get('address2')?.invalid||(submitted&& homeAddressForm.get('address2').invalid)}" />
                                        <!--  -->
                                        <label class="form-lable font-size-14" for="Address2Input001">{{'Address2'|translate}}</label>
                                    </span>
                                    <small class="p-error" *ngIf=" homeAddressForm.get('address2')?.touched &&
                                                            homeAddressForm.get('address2')?.invalid||(submitted&& homeAddressForm.get('address2').invalid)
                                                                " >{{'AdreesErrorMesage002'|translate}}</small>

                                </div>
                                <div class="p-input-filled col-lg-4 col-md-6 col-12">
                                    <span class="p-float-label">
                                        <input type="text" formControlName="city" pTooltip="{{'cityAddress001tooltip'|translate}}"
                                            showDelay="1000" tooltipPosition="top" pInputText class="form-control"
                                            [class.ng-invalid.ng-dirty]="" #cityAddress001 [ngClass]="{
                                                'ng-invalid ng-dirty':
                                                  homeAddressForm.get('city')?.touched &&
                                                   homeAddressForm.get('city')?.invalid||(submitted&& homeAddressForm.get('city').invalid)}"  />
                                        <!-- -->
                                        <label class="form-lable font-size-14" for="cityAddress001">{{'City'|translate}}</label>
                                    </span>
                                    <small class="p-error" *ngIf="homeAddressForm.get('city')?.touched &&
                                                            homeAddressForm.get('city')?.invalid||(submitted&& homeAddressForm.get('city').invalid)
                                                                " >{{'AdreesErrorMesage003'|translate}}</small>

                                </div>

                            </div>
                            <div class="row">
                                <div class="p-input-filled col-lg-4 col-md-6 col-12">
                                    <span class="p-float-label">
                                        <input type="text" formControlName="country" pTooltip="{{'CountryInput001tooltip'|translate}}"
                                            showDelay="1000" tooltipPosition="top" pInputText class="form-control"
                                            [class.ng-invalid.ng-dirty]="" #CountryInput001  [ngClass]="{
                                                'ng-invalid ng-dirty':
                                                   homeAddressForm.get('country')?.touched &&
                                                     homeAddressForm.get('country')?.invalid||(submitted&&  homeAddressForm.get('country').invalid)}" />
                                        <!-- -->
                                        <label class="form-lable font-size-14" for="CountryInput001">{{'Country'|translate}}</label>
                                    </span>
                                    <small class="p-error" *ngIf=" homeAddressForm.get('country')?.touched &&
                                                             homeAddressForm.get('country')?.invalid||(submitted&&  homeAddressForm.get('country').invalid)
                                                                " >{{'AdreesErrorMesage004'|translate}}</small>

                                </div>

                                <div class="p-input-filled  col-lg-4 col-md-6 col-12">
                                    <span class="p-float-label">
                                        <p-inputNumber mode="decimal" pTooltip="{{'ZipPostalCode001tooltip'|translate}}" showDelay="1000"
                                            tooltipPosition="top" inputId="ZipPostalCode001" [useGrouping]="false" [styleClass]="'w-100'"
                                            class="form-control m-0 p-0" [inputStyleClass]="'form-control'" formControlName="zip"
                                            #ZipPostalCode001
                                            [ngClass]="{
                                                'ng-invalid ng-dirty':
                                                     homeAddressForm.get('zip')?.touched &&
                                                     homeAddressForm.get('zip')?.invalid||(submitted&&  homeAddressForm.get('zip').invalid)
                                            }"></p-inputNumber>
                                        <!--  -->

                                        <label class="form-label font-size-14" for="ZipPostalCode001">{{'ZipPostalCode'|translate}}</label>
                                    </span>
                                    <small class="p-error" *ngIf=" homeAddressForm.get('zip')?.touched &&
                                    homeAddressForm.get('zip')?.invalid||(submitted&&  homeAddressForm.get('zip').invalid)
                                       " >{{'AdreesErrorMesage005'|translate}}</small>


                                </div>
                                <div class="p-input-filled col-lg-4 col-md-6 col-12">
                                    <span class="p-float-label">
                                        <input type="text" formControlName="state" pTooltip="{{'StateInput001tooltip'|translate}}"
                                            showDelay="1000" tooltipPosition="top" pInputText class="form-control"
                                            [class.ng-invalid.ng-dirty]="" #StateInput001 [ngClass]="{
                                                'ng-invalid ng-dirty':
                                                homeAddressForm.get('state')?.touched &&
                                                homeAddressForm.get('state')?.invalid||(submitted&&  homeAddressForm.get('state').invalid)}"  />

                                        <label class="form-lable font-size-14" for="StateInput001">{{'state, governorate, emirate, or region'|translate}}</label>
                                    </span>
                                    <small class="p-error" *ngIf=" homeAddressForm.get('state')?.touched &&
                                    homeAddressForm.get('state')?.invalid||(submitted&&  homeAddressForm.get('state').invalid)
                                       " >{{'AdreesErrorMesage006'|translate}}</small>

                                </div>

                            </div>
                            <div class="row">
                                <div class="col-12 d-flex  justify-content-center">
                                    <div class="banner-btn rounded-pill">

                                        <button pTooltip="Save and go to next step" showDelay="1000" tooltipPosition="right" pButton pRipple
                                        (click)="save('home')" class="rounded-pill next-btn  ms-1 mt-md-0 mt-1">{{'SaveBtn'|translate}} <span></span></button>
                                    </div>
                                </div>
                                </div>
                            </form>
                        </p-accordionTab>
                        <p-accordionTab header="{{'BillingAddress'|translate}}">
                            <div class="row">
                                <app-location-sertch (setAddress)="getAddressfromCom($event,'billing')"></app-location-sertch>
                            </div>
                            <form [formGroup]="billingAddressForm">
                            <div class="row">

                                <div class="p-input-filled col-lg-4 col-md-6 col-12">
                                    <span class="p-float-label">
                                        <input type="text" formControlName="address" pTooltip="{{'BillingAddress2Input002tooltip'|translate}}"
                                            showDelay="1000" tooltipPosition="top" pInputText class="form-control"
                                            [class.ng-invalid.ng-dirty]="" #Address1Input002
                                            [ngClass]="{
                                                'ng-invalid ng-dirty':
                                                billingAddressForm.get('address')?.touched &&
                                                billingAddressForm.get('address')?.invalid||(submitted&& billingAddressForm.get('address').invalid)}" />
                                        <!-- -->
                                        <label class="form-lable font-size-14" for="Address1Input002">{{'Address'|translate}}</label>
                                    </span>
                                    <small class="p-error" *ngIf="billingAddressForm.get('address')?.touched &&
                                                            billingAddressForm.get('address')?.invalid||(submitted&& billingAddressForm.get('address').invalid)
                                                                " >{{'BillingAdreesErrorMesage001'|translate}}</small>

                                </div>
                                <div class="p-input-filled col-lg-4 col-md-6 col-12">
                                    <span class="p-float-label">
                                        <input type="text" formControlName="address2" pTooltip="{{'BillingAddress1Input002tooltip'|translate}}"
                                            showDelay="1000" tooltipPosition="top" pInputText class="form-control"
                                            [class.ng-invalid.ng-dirty]="" #Address2Input002
                                            [ngClass]="{
                                                'ng-invalid ng-dirty':
                                                  billingAddressForm.get('address2')?.touched &&
                                                    billingAddressForm.get('address2')?.invalid||(submitted&& billingAddressForm.get('address2').invalid)}"  />
                                        <!-- -->
                                        <label class="form-lable font-size-14" for="Address2Input002">{{'Address2'|translate}}</label>
                                    </span>
                                    <small class="p-error" *ngIf="billingAddressForm.get('address2')?.touched &&
                                                            billingAddressForm.get('address2')?.invalid||(submitted&& billingAddressForm.get('address2').invalid)
                                                                " >{{'BillingAdreesErrorMesage002'|translate}}</small>

                                </div>
                                <div class="p-input-filled col-lg-4 col-md-6 col-12">
                                    <span class="p-float-label">
                                        <input type="text" formControlName="city" pTooltip="{{'BillingcityAddress001tooltip'|translate}}"
                                            showDelay="1000" tooltipPosition="top" pInputText class="form-control"
                                            [class.ng-invalid.ng-dirty]="" #cityAddress002 [ngClass]="{
                                                'ng-invalid ng-dirty':
                                                  billingAddressForm.get('city')?.touched &&
                                                    billingAddressForm.get('city')?.invalid||(submitted&& billingAddressForm.get('city').invalid)}" />
                                        <!--  -->
                                        <label class="form-lable font-size-14" for="cityAddress002">{{'City'|translate}}</label>
                                    </span>
                                    <small class="p-error" *ngIf="billingAddressForm.get('city')?.touched &&
                                                            billingAddressForm.get('city')?.invalid||(submitted&& billingAddressForm.get('city').invalid)
                                                                " >{{'BillingAdreesErrorMesage003'|translate}}</small>
                                </div>

                            </div>
                            <div class="row">
                                <div class="p-input-filled col-lg-4 col-md-6 col-12">
                                    <span class="p-float-label">
                                        <input type="text" formControlName="country" pTooltip="{{'BillingCountryInput001tooltip'|translate}}"
                                            showDelay="1000" tooltipPosition="top" pInputText class="form-control"
                                            [class.ng-invalid.ng-dirty]="" #CountryInput002 [ngClass]="{
                                                'ng-invalid ng-dirty':
                                                  billingAddressForm.get('country')?.touched &&
                                                    billingAddressForm.get('country')?.invalid||(submitted&& billingAddressForm.get('country').invalid)}" />
                                        <!--  -->
                                        <label class="form-lable font-size-14" for="CountryInput002">{{'Country'|translate}}</label>
                                    </span>
                                    <small class="p-error" *ngIf=" billingAddressForm.get('country')?.touched &&
                                                            billingAddressForm.get('country')?.invalid||(submitted&& billingAddressForm.get('country').invalid)
                                                                " >{{'BillingAdreesErrorMesage004'|translate}}</small>
                                </div>

                                <div class="p-input-filled  col-lg-4 col-md-6 col-12">
                                    <span class="p-float-label">
                                        <p-inputNumber mode="decimal" pTooltip="{{'BillingZipPostalCode001tooltip'|translate}}" showDelay="1000"
                                            tooltipPosition="top" inputId="ZipPostalCode002" [useGrouping]="false" [styleClass]="'w-100'"
                                            class="form-control m-0 p-0" [inputStyleClass]="'form-control'" formControlName="zip"
                                            #ZipPostalCode001
                                            [ngClass]="{
                                                'ng-invalid ng-dirty':
                                                    billingAddressForm.get('zip')?.touched &&
                                                    billingAddressForm.get('zip')?.invalid||(submitted&& billingAddressForm.get('zip').invalid)
                                            }" ></p-inputNumber>
                                        <!-- -->

                                        <label class="form-label font-size-14" for="ZipPostalCode002">{{'ZipPostalCode'|translate}}</label>
                                    </span>
                                    <small class="p-error" *ngIf=" billingAddressForm.get('zip')?.touched &&
                                    billingAddressForm.get('zip')?.invalid||(submitted&& billingAddressForm.get('zip').invalid)
                                        " >{{'BillingAdreesErrorMesage005'|translate}}</small>
                                </div>
                                <div class="p-input-filled col-lg-4 col-md-6 col-12">
                                    <span class="p-float-label">
                                        <input type="text" formControlName="state" pTooltip="{{'BillingStateInput001tooltip'|translate}}"
                                            showDelay="1000" tooltipPosition="top" pInputText class="form-control"
                                            [class.ng-invalid.ng-dirty]="" #StateInput002 [ngClass]="{
                                                'ng-invalid ng-dirty':
                                                  billingAddressForm.get('state')?.touched &&
                                                    billingAddressForm.get('state')?.invalid||(submitted&& billingAddressForm.get('state').invalid)}" />
                                        <!--  -->
                                        <label class="form-lable font-size-14" for="StateInput002">{{'state, governorate, emirate, or region'|translate}}</label>
                                    </span>
                                    <small class="p-error" *ngIf="billingAddressForm.get('state')?.touched &&
                                    billingAddressForm.get('state')?.invalid||(submitted&& billingAddressForm.get('state').invalid)
                                        " >{{'BillingAdreesErrorMesage006'|translate}}</small>

                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12 d-flex  justify-content-center">
                                    <div class="banner-btn rounded-pill">

                                        <button pTooltip="Save and go to next step" showDelay="1000" tooltipPosition="right" pButton pRipple
                                        (click)="save('bill')"  class="rounded-pill next-btn  ms-1 mt-md-0 mt-1">{{'SaveBtn'|translate}} <span></span></button>
                                    </div>
                                </div>
                                </div>
                        </form>
                        </p-accordionTab>
                        <p-accordionTab header="{{'ShippingAddress'|translate}}">
                            <div class="row">
                                <app-location-sertch (setAddress)="getAddressfromCom($event,'shipping')"></app-location-sertch>
                            </div>
                            <form [formGroup]="shippingAddressForm">
                            <div class="row">

                                <div class="p-input-filled col-lg-4 col-md-6 col-12">
                                    <span class="p-float-label">
                                        <input type="text" formControlName="address" pTooltip="{{'ShippingAddress2Input002tooltip'|translate}}"
                                            showDelay="1000" tooltipPosition="top" pInputText class="form-control"
                                            [class.ng-invalid.ng-dirty]="" #Address1Input003
                                            [ngClass]="{
                                                'ng-invalid ng-dirty':
                                                  shippingAddressForm.get('address')?.touched &&
                                                    shippingAddressForm.get('address')?.invalid||(submitted&& shippingAddressForm.get('address').invalid)}" />
                                        <!--  -->
                                        <label class="form-lable font-size-14" for="Address1Input003">{{'Address'|translate}}</label>
                                    </span>
                                    <small class="p-error" *ngIf="shippingAddressForm.get('address')?.touched &&
                                                            shippingAddressForm.get('address')?.invalid||(submitted&& shippingAddressForm.get('address').invalid)
                                                                " >{{'ShippingAdreesErrorMesage001'|translate}}</small>

                                </div>
                                <div class="p-input-filled col-lg-4 col-md-6 col-12">
                                    <span class="p-float-label">
                                        <input type="text" formControlName="address2" pTooltip="{{'ShippingAddress2Input002tooltip'|translate}}"
                                            showDelay="1000" tooltipPosition="top" pInputText class="form-control"
                                            [class.ng-invalid.ng-dirty]="" #Address2Input003
                                            [ngClass]="{
                                                'ng-invalid ng-dirty':
                                                  shippingAddressForm.get('address2')?.touched &&
                                                    shippingAddressForm.get('address2')?.invalid||(submitted&& shippingAddressForm.get('address2').invalid)}" />
                                        <!--  -->
                                        <label class="form-lable font-size-14" for="Address2Input003">{{'Address2'|translate}}</label>
                                    </span>
                                    <small class="p-error" *ngIf="shippingAddressForm.get('address2')?.touched &&
                                                            shippingAddressForm.get('address2')?.invalid||(submitted&& shippingAddressForm.get('address2').invalid)
                                                                " >{{'ShippingAdreesErrorMesage002'|translate}}</small>

                                </div>
                                <div class="p-input-filled col-lg-4 col-md-6 col-12">
                                    <span class="p-float-label">
                                        <input type="text" formControlName="city" pTooltip="{{'ShippingcityAddress001tooltip'|translate}}"
                                            showDelay="1000" tooltipPosition="top" pInputText class="form-control"
                                            [class.ng-invalid.ng-dirty]="" #cityAddress003
                                            [ngClass]="{
                                                'ng-invalid ng-dirty':
                                                  shippingAddressForm.get('city')?.touched &&
                                                    shippingAddressForm.get('city')?.invalid||(submitted&& shippingAddressForm.get('city').invalid)}"/>
                                        <!--  -->
                                        <label class="form-lable font-size-14" for="cityAddress003">{{'City'|translate}}</label>
                                    </span>
                                    <small class="p-error" *ngIf="shippingAddressForm.get('city')?.touched &&
                                                            shippingAddressForm.get('city')?.invalid||(submitted&& shippingAddressForm.get('city').invalid)
                                                                " >{{'ShippingAdreesErrorMesage003'|translate}}</small>

                                </div>
                            </div>
                            <div class="row">
                                <div class="p-input-filled col-lg-4 col-md-6 col-12">
                                    <span class="p-float-label">
                                        <input type="text" formControlName="country" pTooltip="{{'ShippingCountryInput001tooltip'|translate}}"
                                            showDelay="1000" tooltipPosition="top" pInputText class="form-control"
                                            [class.ng-invalid.ng-dirty]="" #CountryInput003
                                            [ngClass]="{
                                                'ng-invalid ng-dirty':
                                                  shippingAddressForm.get('country')?.touched &&
                                                    shippingAddressForm.get('country')?.invalid||(submitted&& shippingAddressForm.get('country').invalid)}" />
                                        <!--  -->
                                        <label class="form-lable font-size-14" for="CountryInput003">{{'Country'|translate}}</label>
                                    </span>
                                    <small class="p-error" *ngIf="shippingAddressForm.get('country')?.touched &&
                                                            shippingAddressForm.get('country')?.invalid||(submitted&& shippingAddressForm.get('country').invalid)
                                                                " >{{'ShippingAdreesErrorMesage004'|translate}}</small>

                                </div>

                                <div class="p-input-filled  col-lg-4 col-md-6 col-12">
                                    <span class="p-float-label">
                                        <p-inputNumber mode="decimal" pTooltip="{{'ShippingZipPostalCode001tooltip'|translate}}" showDelay="1000"
                                            tooltipPosition="top" inputId="ZipPostalCode003" [useGrouping]="false" [styleClass]="'w-100'"
                                            class="form-control m-0 p-0" [inputStyleClass]="'form-control'" formControlName="zip"
                                            #ZipPostalCode001
                                            [ngClass]="{
                                                'ng-invalid ng-dirty':
                                                    shippingAddressForm.get('zip')?.touched &&
                                                    shippingAddressForm.get('zip')?.invalid||(submitted&& shippingAddressForm.get('zip').invalid)
                                            }"></p-inputNumber>
                                        <!--  -->

                                        <label class="form-label font-size-14" for="ZipPostalCode003">{{'ZipPostalCode'|translate}}</label>
                                    </span>
                                    <small class="p-error" *ngIf=" shippingAddressForm.get('zip')?.touched &&
                                    shippingAddressForm.get('zip')?.invalid||(submitted&&  shippingAddressForm.get('zip').invalid)
                                        " >{{'ShippingAdreesErrorMesage005'|translate}}</small>

                                </div>
                                <div class="p-input-filled col-lg-4 col-md-6 col-12">
                                    <span class="p-float-label">
                                        <input type="text" formControlName="state" pTooltip="{{'ShippingStateInput001tooltip'|translate}}"
                                            showDelay="1000" tooltipPosition="top" pInputText class="form-control"
                                            [class.ng-invalid.ng-dirty]="" #StateInput001
                                            [ngClass]="{
                                                'ng-invalid ng-dirty':
                                                  shippingAddressForm.get('state')?.touched &&
                                                    shippingAddressForm.get('state')?.invalid||(submitted&& shippingAddressForm.get('state').invalid)}"  />
                                        <!-- -->
                                        <label class="form-lable font-size-14" for="StateInput003">{{'state, governorate, emirate, or region'|translate}}</label>
                                    </span>
                                    <small class="p-error" *ngIf=" shippingAddressForm.get('state')?.touched &&
                                    shippingAddressForm.get('state')?.invalid||(submitted&&  shippingAddressForm.get('state').invalid)
                                        " >{{'ShippingAdreesErrorMesage006'|translate}}</small>

                                </div>
                                <!-- <div class="col-lg-4 col-md-6 col-12">
                                                            <div class="flex flex-column gap-2">
                                                                <label htmlFor="HomCountry">Country</label>
                                                                <input pInputText id="HomCountry" aria-describedby="Country" [(ngModel)]="value" />
                                                            </div>
                                                        </div> -->
                                <!-- <div class="col-lg-4 col-md-6 col-12">
                                                            <div class="flex-auto flex-column gap-2">
                                                                <label for="HomZip/PostalCode" class="block mb-2">Zip/Postal Code </label>
                                                                <input pInputText id="HomZip/PostalCode" pKeyFilter="int" class="w-full" formControlName="value"/>
                                                            </div>
                                                        </div> -->
                                <!-- <div class="col-lg-4 col-md-6 col-12">

                                                                <span class="flex-auto flex-column gap-2">

                                                                    <label  class="block mb-2" for="HomState">State* For USA only</label>
                                                                    <p-dropdown [options]="States" [(ngModel)]="selectedState"
                                                                        placeholder="Select a State" optionLabel="name"
                                                                        inputId="HomState" class="pe-5"></p-dropdown>
                                                                </span>
                                                        </div> -->
                            </div>
                            <div class="row">
                                <div class="col-12 d-flex  justify-content-center">
                                    <div class="banner-btn rounded-pill">

                                        <button pTooltip="Save and go to next step" showDelay="1000" tooltipPosition="right" pButton pRipple
                                        (click)="save('ship')"  class="rounded-pill next-btn  ms-1 mt-md-0 mt-1">{{'SaveBtn'|translate}} <span></span></button>
                                    </div>
                                </div>
                                </div>
                        </form>
                        </p-accordionTab>
                        <p-accordionTab header="{{'WorkAddress'|translate}}">
                            <div class="row">
                                <app-location-sertch (setAddress)="getAddressfromCom($event,'work')"></app-location-sertch>
                            </div>

                            <form [formGroup]="workAddressForm">
                            <div class="row">

                                <div class="p-input-filled col-lg-4 col-md-6 col-12">
                                    <span class="p-float-label">
                                        <input type="text" formControlName="address" pTooltip="{{'workAddress1Input002tooltip'|translate}}"
                                            showDelay="1000" tooltipPosition="top" pInputText class="form-control"
                                            [class.ng-invalid.ng-dirty]="" #Address1Input004
                                            [ngClass]="{
                                                'ng-invalid ng-dirty':
                                                  workAddressForm.get('address')?.touched &&
                                                    workAddressForm.get('address')?.invalid||(submitted&& workAddressForm.get('address').invalid)}"/>
                                        <!-- -->
                                        <label class="form-lable font-size-14" for="Address1Input004">{{'Address'|translate}}</label>
                                    </span>
                                    <small class="p-error" *ngIf="workAddressForm.get('address')?.touched &&
                                                            workAddressForm.get('address')?.invalid||(submitted&& workAddressForm.get('address').invalid)
                                                                " >{{'workAdreesErrorMesage001'|translate}}</small>

                                </div>
                                <div class="p-input-filled col-lg-4 col-md-6 col-12">
                                    <span class="p-float-label">
                                        <input type="text" formControlName="address2" pTooltip="{{'workAddress2Input002tooltip'|translate}}"
                                            showDelay="1000" tooltipPosition="top" pInputText class="form-control"
                                            [class.ng-invalid.ng-dirty]="" #Address2Input004 [ngClass]="{
                                                'ng-invalid ng-dirty':
                                                  workAddressForm.get('address2')?.touched &&
                                                    workAddressForm.get('address2')?.invalid||(submitted&& workAddressForm.get('address2').invalid)}" />
                                        <!--  -->
                                        <label class="form-lable font-size-14" for="Address2Input004">{{'Address2'|translate}}</label>
                                    </span>
                                    <small class="p-error" *ngIf="workAddressForm.get('address2')?.touched &&
                                                            workAddressForm.get('address2')?.invalid||(submitted&& workAddressForm.get('address2').invalid)
                                                                " >{{'workAdreesErrorMesage002'|translate}}</small>

                                </div>
                                <div class="p-input-filled col-lg-4 col-md-6 col-12">
                                    <span class="p-float-label">
                                        <input type="text" formControlName="city" pTooltip="{{'workcityAddress001tooltip'|translate}}"
                                            showDelay="1000" tooltipPosition="top" pInputText class="form-control"
                                            [class.ng-invalid.ng-dirty]="" #cityAddress004 [ngClass]="{
                                                'ng-invalid ng-dirty':
                                                  workAddressForm.get('city')?.touched &&
                                                    workAddressForm.get('city')?.invalid||(submitted&& workAddressForm.get('city').invalid)}"/>
                                        <!--  -->
                                        <label class="form-lable font-size-14" for="cityAddress004">{{'City'|translate}}</label>
                                    </span>
                                    <small class="p-error" *ngIf="workAddressForm.get('city')?.touched &&
                                                            workAddressForm.get('city')?.invalid||(submitted&& workAddressForm.get('city').invalid)
                                                                " >{{'workAdreesErrorMesage003'|translate}}</small>

                                </div>
                            </div>
                            <div class="row">
                                <div class="p-input-filled col-lg-4 col-md-6 col-12">
                                    <span class="p-float-label">
                                        <input type="text" formControlName="country" pTooltip="{{'workCountryInput001tooltip'|translate}}"
                                            showDelay="1000" tooltipPosition="top" pInputText class="form-control"
                                            [class.ng-invalid.ng-dirty]="" #CountryInput004
                                            [ngClass]="{
                                                'ng-invalid ng-dirty':
                                                  workAddressForm.get('country')?.touched &&
                                                    workAddressForm.get('country')?.invalid||(submitted&& workAddressForm.get('country').invalid)}"/>
                                        <!--  -->
                                        <label class="form-lable font-size-14" for="CountryInput004">{{'Country'|translate}}</label>
                                    </span>
                                    <small class="p-error" *ngIf="workAddressForm.get('country')?.touched &&
                                                            workAddressForm.get('country')?.invalid||(submitted&& workAddressForm.get('country').invalid)
                                                                " >{{'workAdreesErrorMesage004'|translate}}</small>

                                </div>

                                <div class="p-input-filled  col-lg-4 col-md-6 col-12">
                                    <span class="p-float-label">
                                        <p-inputNumber mode="decimal" pTooltip="{{'workZipPostalCode001tooltip'|translate}}" showDelay="1000"
                                            tooltipPosition="top" inputId="ZipPostalCode004" [useGrouping]="false" [styleClass]="'w-100'"
                                            class="form-control m-0 p-0" [inputStyleClass]="'form-control'" formControlName="zip"
                                            #ZipPostalCode001 [ngClass]="{
                                                'ng-invalid ng-dirty':
                                                workAddressForm.get('zip')?.touched &&
                                                workAddressForm.get('zip')?.invalid||(submitted&& workAddressForm.get('zip').invalid)
                                            }"></p-inputNumber>
                                        <!--   -->

                                        <label class="form-label font-size-14" for="ZipPostalCode004">{{'ZipPostalCode'|translate}}</label>
                                    </span>
                                    <small class="p-error" *ngIf="workAddressForm.get('zip')?.touched &&
                                    workAddressForm.get('zip')?.invalid||(submitted&& workAddressForm.get('zip').invalid)
                                        " >{{'workAdreesErrorMesage005'|translate}}</small>


                                </div>
                                <div class="p-input-filled col-lg-4 col-md-6 col-12">
                                    <span class="p-float-label">
                                        <input type="text" formControlName="state" pTooltip="{{'workStateInput001tooltip'|translate}}"
                                            showDelay="1000" tooltipPosition="top" pInputText class="form-control"
                                            [class.ng-invalid.ng-dirty]="" #StateInput004
                                            [ngClass]="{
                                                'ng-invalid ng-dirty':
                                                  workAddressForm.get('state')?.touched &&
                                                    workAddressForm.get('state')?.invalid||(submitted&& workAddressForm.get('state').invalid)}"/>
                                        <!-- -->
                                        <label class="form-lable font-size-14" for="StateInput004">{{'state, governorate, emirate, or region'|translate}}</label>
                                    </span>
                                    <small class="p-error" *ngIf="workAddressForm.get('state')?.touched &&
                                    workAddressForm.get('state')?.invalid||(submitted&& workAddressForm.get('state').invalid)
                                        " >{{'workAdreesErrorMesage006'|translate}}</small>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12 d-flex  justify-content-center">
                                    <div class="banner-btn rounded-pill">
                                        <button pTooltip="Save and go to next step" showDelay="1000" tooltipPosition="right" pButton pRipple
                                        (click)="save('work')"  class="rounded-pill next-btn  ms-1 mt-md-0 mt-1">{{'SaveBtn'|translate}} <span></span></button>
                                    </div>
                                </div>
                                </div>
                            </form>
                        </p-accordionTab>
                    </p-accordion>
                </div>
                <div class="row mt-5">
                    <p-accordion>
                        <p-accordionTab header="{{'ChangePassword'|translate}}">
                            <form  [formGroup]="ResetPasswordForm"  autocomplete="off">

                                <div class="row justify-content-center">
                                    <div class="p-input-filled col-lg-4 col-md-6 col-12">
                                        <span class="p-float-label">
                                            <p-password [feedback]="false" minlength="8" pTooltip="{{'changpass001'|translate}}"
                                            showDelay="1000" tooltipPosition="top" formControlName="currentpassword" class="w-full p-0" id="password"
                                                [ngClass]="{
                                                                                    'ng-invalid ng-dirty':
                                                                                    ResetPasswordForm.get('currentpassword')?.touched &&
                                                                                    ResetPasswordForm.get('currentpassword')?.invalid
                                                                                }" [style]="{'width':'100%'}"
                                                [inputStyle]="{'width':'100%','height':'50px'}" [toggleMask]="true">

                                                <ng-template pTemplate="footer">
                                                    <p-divider></p-divider>
                                                    <p class="mt-2">{{'Password must contain :'|translate}} </p>
                                                    <ul class="pl-2 ml-2 m-3" style="line-height: 1.5">
                                                        <li>{{'At least one lowercase'|translate}}</li>
                                                        <li>{{'At least one uppercase'|translate}}</li>
                                                        <li>{{'At least one speical character'|translate}}</li>
                                                        <li>{{'At least one numeric'|translate}}</li>
                                                        <li>{{'Minimum 8 characters'|translate}}</li>
                                                    </ul>
                                                </ng-template></p-password>
                                            <label htmlFor="password">{{'currentpassword'|translate}}</label>
                                        </span>
                                        <small class="p-error" *ngIf="ResetPasswordForm.get('currentpassword')?.touched &&
                                                                            ResetPasswordForm.get('currentpassword')?.invalid ">{{'Please add valid value.'|translate}}</small>
                                    </div>

                                    <div class="p-input-filled col-lg-4 col-md-6 col-12">
                                        <span class="p-float-label">
                                            <p-password [feedback]="false" pTooltip="{{'changpass002'|translate}}"
                                            showDelay="1000" tooltipPosition="top" minlength="8" formControlName="password" class="w-full p-0" id="password"
                                                [ngClass]="{
                                                                                    'ng-invalid ng-dirty':
                                                                                    ResetPasswordForm.get('password')?.touched &&
                                                                                    ResetPasswordForm.get('password')?.invalid
                                                                                }" [style]="{'width':'100%'}"
                                                [inputStyle]="{'width':'100%','height':'50px'}" [toggleMask]="true">

                                                <ng-template pTemplate="footer">
                                                    <p-divider></p-divider>
                                                    <p class="mt-2">{{'Password must contain :'|translate}} </p>
                                                    <ul class="pl-2 ml-2 m-3" style="line-height: 1.5">
                                                        <li>{{'At least one lowercase'|translate}}</li>
                                                        <li>{{'At least one uppercase'|translate}}</li>
                                                        <li>{{'At least one speical character'|translate}}</li>
                                                        <li>{{'At least one numeric'|translate}}</li>
                                                        <li>{{'Minimum 8 characters'|translate}}</li>
                                                    </ul>
                                                </ng-template></p-password>
                                            <label htmlFor="password">{{'Password'|translate}}</label>
                                        </span>
                                        <small class="p-error" *ngIf="ResetPasswordForm.get('password')?.touched &&
                                                                            ResetPasswordForm.get('password')?.invalid ">{{'Please add valid value.'|translate}}</small>
                                    </div>
                                    <div class="p-input-filled  col-lg-4 col-md-6 col-12">
                                        <span class="p-float-label">
                                            <p-password [feedback]="false"  pTooltip="{{'changpass003'|translate}}"
                                            showDelay="1000" tooltipPosition="top" minlength="8" formControlName="retypePass" class="w-full p-0" id="password"
                                                [ngClass]="{
                                                                                    'ng-invalid ng-dirty':
                                                                                    ResetPasswordForm.get('retypePass')?.touched &&
                                                                                    ResetPasswordForm.get('retypePass')?.invalid
                                                                                }" [style]="{'width':'100%'}"
                                                [inputStyle]="{'width':'100%','height':'50px'}" [toggleMask]="true">

                                                <ng-template pTemplate="footer">
                                                    <p-divider></p-divider>
                                                    <p class="mt-2">{{'Password must contain :'|translate}} </p>
                                                    <ul class="pl-2 ml-2 m-3" style="line-height: 1.5">
                                                        <li>{{'At least one lowercase'|translate}}</li>
                                                        <li>{{'At least one uppercase'|translate}}</li>
                                                        <li>{{'At least one speical character'|translate}}</li>
                                                        <li>{{'At least one numeric'|translate}}</li>
                                                        <li>{{'Minimum 8 characters'|translate}}</li>
                                                    </ul>
                                                </ng-template></p-password>
                                            <label htmlFor="password">{{'Confirmpassword'|translate}}</label>
                                        </span>
                                        <small class="p-error" *ngIf="ResetPasswordForm.get('retypePass')?.touched &&ResetPasswordForm.get('retypePass')?.invalid ">{{'Please add validvalue.'|translate}}</small>
                                        <small class="p-error" *ngIf="ResetPasswordForm.invalid&&submitted ">{{'thetowpassworddoesnotmatch'|translate}}</small>
                                    </div>




                                </div>
                                <div class="d-flex justify-content-center">


                                    <div class="banner-btn rounded-pill mx-2 text-center">
                                        <button [disabled]="ResetPasswordForm.invalid||Auth.isSubmitting" type="submit" pTooltip="Register" showDelay="1000" tooltipPosition="right" class="rounded-pill save-btn {{Auth.isSubmitting? 'bg-disabled' : ''}}" (click)="submit()">
                                            <i  *ngIf="Auth.isSubmitting" class="fas fa-spinner fa-spin"></i>
                                            {{'save'|translate}}<span></span>
                                        </button>
                                    </div>
                                </div>



                            </form>
                        </p-accordionTab>
                    </p-accordion>
                </div>
                <div class="row mt-5">

                    <p-accordion >
                        <p-accordionTab header="{{'CreditDebiCards'|translate}}">
                            <form [formGroup]="CardDetailsForm">
                            <div class="row">
                                <div class="p-input-filled col-lg-4 col-md-6 col-12">
                                    <span class="p-float-label">

                                        <p-inputMask class="w-100" mask="9999999999999999"  pTooltip="{{'Cardnumbertoltip001'|translate}}"
                                        showDelay="1000" tooltipPosition="top" [styleClass]="'form-control w-100'" formControlName="cardNumber" placeholder="9999999999999999"
                                        [ngClass]="{
                                            'ng-invalid ng-dirty':
                                              workAddressForm.get('cardNumber')?.touched &&
                                                workAddressForm.get('cardNumber')?.invalid||(submitted&& workAddressForm.get('cardNumber').invalid)}"></p-inputMask>
                                        <!-- <input type="text" formControlName="address" pTooltip="{{'Address1Input004tooltip'|translate}}"
                                            showDelay="1000" tooltipPosition="top" pInputText class="form-control"
                                            [class.ng-invalid.ng-dirty]="" #Address1Input004
                                           /> -->
                                        <!-- -->
                                        <label class="form-lable font-size-14" for="Address1Input004">{{'CardNumber'|translate}}</label>
                                    </span>
                                    <small class="p-error" *ngIf="CardDetailsForm.get('cardNumber')?.touched &&
                                                            CardDetailsForm.get('cardNumber')?.invalid||(submitted&& CardDetailsForm.get('cardNumber').invalid)
                                                                " >{{'cadrnumberErrorMesage001'|translate}}</small>

                                </div>

                                <div class="p-input-filled col-lg-4 col-md-6 col-12">
                                    <span class="p-float-label">
                                        <input type="text" formControlName="cardholderName" pTooltip="{{'cardholderNametooltip'|translate}}"
                                            showDelay="1000" tooltipPosition="top" pInputText class="form-control"
                                            [class.ng-invalid.ng-dirty]="" #Address1Input004
                                               [ngClass]="{
                                            'ng-invalid ng-dirty':
                                              workAddressForm.get('cardholderName')?.touched &&
                                                workAddressForm.get('cardholderName')?.invalid||(submitted&& workAddressForm.get('cardholderName').invalid)}"
                                           />
                                        <!-- -->
                                        <label class="form-lable font-size-14" for="Address1Input004">{{'CardholderName'|translate}}</label>
                                    </span>
                                    <small class="p-error" *ngIf="CardDetailsForm.get('cardholderName')?.touched &&
                                                            CardDetailsForm.get('cardholderName')?.invalid||(submitted&& CardDetailsForm.get('cardholderName').invalid)
                                                                " >{{'cardholderNameErrorMesage001'|translate}}</small>

                                </div>
                                <div class="p-input-filled col-lg-4 col-md-6 col-12">
                                    <span class="p-float-label">
                                        <p-calendar  #cli
                                        dataType="string"
                                        [touchUI]="screenSize"
                                        pTooltip="{{'expiryDate001Pop'|translate}}"
                                         showDelay="1000"
                                         touchUI="true"
                                         tooltipPosition="bottom"
                                           appendTo="body"
                                            inputId="ExpiryDate"
                                            [styleClass]="'form-control m-0 p-0 '"
                                            [inputStyleClass]="'form-control'"
                                             formControlName="expiryDate"
                                            [ngClass]="{
                                            'ng-invalid ng-dirty':
                                            workAddressForm.get('expiryDate')
                                                    ?.touched &&
                                                    workAddressForm.get('expiryDate')?.invalid || workAddressForm.get('expiryDate')?.invalid && submitted
                                        }">
                                    </p-calendar>
                                        <label class="form-label font-size-14" for="ExpiryDate">
                                           {{'ExpiryDate'|translate}}</label>
                                    </span>
                                    <small class="p-error" *ngIf="workAddressForm.get('expiryDate')
                                    ?.touched &&
                                    workAddressForm.get('expiryDate')?.invalid || workAddressForm.get('expiryDate')?.invalid && submitted
                                         ">{{'Dat&TimInput001ErrMsg'|translate}}</small>
                                </div>
                                <!-- <div class="col-lg-4 col-md-6 col-12">
                                    <div class="flex-auto flex-column gap-2">
                                        <label for="CardNumber" class="block mb-2">Card Number</label>
                                        <input pInputText id="CardNumber" pKeyFilter="int" class="w-full" formControlName="value"/>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-6 col-12">
                                    <div class="flex flex-column gap-2">
                                        <label htmlFor="CardholderName">Cardholder Name</label>
                                        <input pInputText id="CardholderName" aria-describedby="Cardholder Name" [(ngModel)]="value" />
                                    </div>
                                </div>

                                <div class="col-lg-4 col-md-6 col-12">
                                        <span class="flex-auto flex-column gap-2">
                                                <label class="block mb-2" for="icon">Expiry Date</label>
                                                <p-calendar appendTo="body" [(ngModel)]="date3" [showIcon]="true" inputId="icon"></p-calendar>
                                        </span>
                                </div> -->
                            </div>
                            <div class="row">
                                <div class="p-input-filled col-lg-4 col-md-6 col-12">
                                    <span class="p-float-label">

                                        <p-inputMask class="w-100" mask="9999" pTooltip="{{'securityCodetooltip'|translate}}"
                                        showDelay="1000" tooltipPosition="top " [styleClass]="'form-control w-100'" formControlName="securityCode" placeholder="9999999999999999"
                                        [ngClass]="{
                                            'ng-invalid ng-dirty':
                                              workAddressForm.get('securityCode')?.touched &&
                                                workAddressForm.get('securityCode')?.invalid||(submitted&& workAddressForm.get('securityCode').invalid)}"></p-inputMask>
                                        <!-- <input type="text" formControlName="address" pTooltip="{{'Address1Input004tooltip'|translate}}"
                                            showDelay="1000" tooltipPosition="top" pInputText class="form-control"
                                            [class.ng-invalid.ng-dirty]="" #Address1Input004
                                           /> -->
                                        <!-- -->
                                        <label class="form-lable font-size-14" for="Address1Input004">{{'Security Code'|translate}}</label>
                                    </span>
                                    <small class="p-error" *ngIf="CardDetailsForm.get('securityCode')?.touched &&
                                                            CardDetailsForm.get('securityCode')?.invalid||(submitted&& CardDetailsForm.get('securityCode').invalid)
                                                                " >{{'securityCodeErrorMesage001'|translate}}</small>

                                </div>

                                <div class="p-input-filled col-lg-8 col-md-6 col-12">
                                    <span class="p-float-label">
                                        <input type="text" formControlName="billingAddress" pTooltip="{{'cardAddress1Input004tooltip'|translate}}"
                                            showDelay="1000" tooltipPosition="top" pInputText class="form-control"
                                            [class.ng-invalid.ng-dirty]="" #Address1Input004
                                               [ngClass]="{
                                            'ng-invalid ng-dirty':
                                              workAddressForm.get('billingAddress')?.touched &&
                                                workAddressForm.get('billingAddress')?.invalid||(submitted&& workAddressForm.get('billingAddress').invalid)}"
                                           />
                                        <!-- -->
                                        <label class="form-lable font-size-14" for="Address1Input004">{{'Billing Address'|translate}}</label>
                                    </span>
                                    <small class="p-error" *ngIf="CardDetailsForm.get('billingAddress')?.touched &&
                                                            CardDetailsForm.get('billingAddress')?.invalid||(submitted&& CardDetailsForm.get('billingAddress').invalid)
                                                                " >{{'cardAdreesErrorMesage001'|translate}}</small>

                                </div>

                                <!-- <div class="col-lg-4 col-md-6 col-12">
                                    <div class="flex-auto flex-column gap-2">
                                        <label for="CredSecurityCode" class="block mb-2">Security Code</label>
                                        <input pInputText id="CredSecurityCode" pKeyFilter="int" class="w-full" formControlName="value"/>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-6 col-12">
                                    <div class="flex flex-column gap-2">
                                        <label htmlFor="CredBillingAddress">Billing Address</label>
                                        <input pInputText id="CredBillingAddress" aria-describedby="Billing Address" [(ngModel)]="value" />
                                    </div>
                                </div> -->
                            </div>
                            <div class="row">
                                <div class="col-12 d-flex  justify-content-center">
                                    <div class="banner-btn rounded-pill">
                                        <button pTooltip="Save and go to next step" showDelay="1000" tooltipPosition="right" pButton pRipple
                                        (click)="save('card')"  class="rounded-pill next-btn  ms-1 mt-md-0 mt-1">{{'SaveBtn'|translate}} <span></span></button>
                                    </div>
                                </div>
                            </div>

                        </form>
                        </p-accordionTab>
                    </p-accordion>
                </div>

                <div class="row mt-5">
                    <p-accordion>
                        <p-accordionTab header="{{'CloseAccount'|translate}}">
        <h1>{{'Areyousuretodeletetheaccount'|translate}}</h1>
        <h4>{{'Knowing that your data will be preserved in memory'|translate}}</h4>
        <button pButton pRipple
        class="rounded-pill add-new-btn mb-lg-0 mb-sm-2 mr-2" > {{'Conferm'|translate}}<span></span>
        </button>
                        </p-accordionTab>
                    </p-accordion>
                </div>




            </div>
        </div>
