<!-- <p-toast></p-toast>
<div class="row align-items-center p-0">
    <div class="card  border-0 col-xl-4 col-lg-5 col-12 m-0 p-0 login-tab-mob">
        <a class="navbar-brand d-lg-flex d-none" routerLink="/"><img src="../../../../assets/img/Logo/Antares - Logo H (Colored).png" class="logo-size-larg" alt="Antares"></a>

        <div class="text-center card-title">
            <h1 class="text-primary">{{'Profile'|translate}}</h1>
        </div>
        <div class="card-body">

<form  [formGroup]="ProfileForm"  autocomplete="off">

    <div class="row justify-content-center">
        <div class="col-12 p-3">
            <app-my-image-uploader pTooltip="{{'Upload image for your profile'|translate}}"  showDelay="1000"
            tooltipPosition="bottom" formControlName="image"
                                   [aspectRatio]="2 / 1" [quality]="'50'"
                                   [format]="'jpeg'"></app-my-image-uploader>
         </div>
    


        <div class="p-input-filled  col-sm-12 mt-1 ">
            <label class="ms-2"> address </label>
            <AutocompleteComponent (problem)="getProblem($event)"  (setAddress)="getAddress($event,1)" 
            pTooltip="{{'search for  location and when selected can you move merker on map to define your position'|translate}}" showDelay="1000" 
            tooltipPosition="top"  addressType="geocode"
             [addressValue]="addressForm.get('address').value" ></AutocompleteComponent>
        </div>

        <div class="p-input-filled  col-sm-12 mt-2">
            <label class="ms-2" > fixed shipping address </label>
            <AutocompleteComponent (problem)="getProblem($event)"  (setAddress)="getAddress($event,2)" 
            pTooltip="{{'LctionInpt001Pop'|translate}}" showDelay="1000" 
            tooltipPosition="top"  addressType="geocode"
             [addressValue]="addressForm.get('address').value" ></AutocompleteComponent>
        </div>

       
        <div class="col-6 my-3 text-end">
          
        </div>


    </div>
    <div class="d-flex justify-content-center">
       
        <div class="banner-btn rounded-pill mx-2 text-center">
            <button type="submit" pTooltip="save information" (click)="submit()" showDelay="1000" tooltipPosition="right" class="rounded-pill save-btn ">
                
                {{'save'|translate}}<span></span>
            </button>
        </div>
    </div>

   

    

</form>
        </div>
    </div>
    <div class="col-xl-8 col-lg-7 d-lg-flex  m-0 p-0 img-fluid authentication-bg">
        <div #gmap style="width:100%;height:100%" #addresstext></div>
        <div #gmap2 style="width:100%;height:100%" #addresstext></div>
    </div>
</div> -->

<p-toast></p-toast>
<div class="mt-3">
<div class="row align-items-center p-0 login-tab-mob">
    <div class="card  border-0 col-xl-6 col-lg-6 col-12 m-0 p-0 ">
        <!-- <a class="navbar-brand d-lg-flex d-none" routerLink="/"><img src="../../../../assets/img/Logo/Antares - Logo H (Colored).png" class="logo-size-larg" alt="Antares"></a> -->
        <!-- <a class="navbar-brand d-lg-none d-sm-flex d-none" routerLink="/"><img src="../../../../assets/img/Logo/Antares - Logo H (White).png" class="logo-size-larg" alt="Antares"></a> -->

        <div class="text-center card-title">
            <h1 class="text-primary">{{'Profile'|translate}}</h1>
        </div>
        <div class="card-body">

            <form [formGroup]="ProfileForm" autocomplete="off">

                <div class="row justify-content-center">
                    <div class="col-12 p-3">
                        <app-my-image-uploader pTooltip="{{'Upload image for your profile'|translate}}" showDelay="1000"
                            tooltipPosition="bottom" formControlName="image" [aspectRatio]="1 / 1" [quality]="'50'"
                            [format]="'jpeg'"></app-my-image-uploader>
                    </div>



                    <div class="p-input-filled  col-sm-12 mt-1 ">
                        <label class="ms-2"> address </label>
                        <AutocompleteComponent (problem)="getProblem($event)" (setAddress)="getAddress($event,1)"
                            pTooltip="{{'search for  location and when selected can you move merker on map to define your position'|translate}}"
                            showDelay="1000" tooltipPosition="top" addressType="geocode"
                            [addressValue]="addressForm.get('address').value"></AutocompleteComponent>
                    </div>
                    <div class="row d-lg-none d-block"  >
                    <div class="col-xl-3 col-lg-3  m-0 p-0  img-fluid  d-flex justify-content-center align-items-center " [ngClass]="{'authentication-bg':img}" >
                        <div  #gmap style="width:90%;height:80%" #addresstext></div>
                    </div>
                </div>
                    <div class="p-input-filled  col-sm-12 mt-2">
                        <label class="ms-2"> fixed shipping address </label>
                        <AutocompleteComponent (problem)="getProblem($event)" (setAddress)="getAddress($event,2)"
                            pTooltip="{{'LctionInpt001Pop'|translate}}" showDelay="1000" tooltipPosition="top"
                            addressType="geocode" [addressValue]="addressForm2.get('address').value">
                        </AutocompleteComponent>
                    </div>
                    <div class="row d-lg-none d-block">
                        <div class="col-xl-3 col-lg-3  m-0 p-0  img-fluid  d-flex justify-content-center align-items-center " [ngClass]="{'authentication-bg':img2}" >
                            <div #gmap2 style="width:90%;height:80%" #addresstext></div>
                        </div>
                    </div>

                    <!-- <div class="p-input-filled col-12">
            <span class="p-float-label">
                <p-password [feedback]="false" minlength="8" formControlName="" class="w-full p-0"  id="password"     [style]="{'width':'100%'}"
                [inputStyle]="{'width':'100%','height':'50px'}"
                 [toggleMask]="true">

                <ng-template pTemplate="footer">
                    <p-divider></p-divider>
                    <p class="mt-2">{{'Password must contain :'|translate}} </p>
                    <ul class="pl-2 ml-2 m-3" style="line-height: 1.5">
                        <li>{{'At least one lowercase'|translate}}</li>
                        <li>{{'At least one uppercase'|translate}}</li>
                        <li>{{'At least one speical character'|translate}}</li>
                        <li>{{'At least one numeric'|translate}}</li>
                        <li>{{'Minimum 8 characters'|translate}}</li>
                    </ul>
                </ng-template></p-password>
                <label htmlFor="password">{{'Password'|translate}}</label>
            </span>

                    </div> -->

                    <!-- <div class="col-6 my-3">
            <p-inputSwitch formControlName="rememberMe" inputId="rememberMe"></p-inputSwitch>
            <label class="form-label font-size-14 ms-2" for="rememberMe">{{'Remember me'|translate}}</label>
        </div> -->
                    <div class="col-6 my-3 text-end">

                    </div>


                </div>
                <div class="d-flex justify-content-center">

                    <div class="banner-btn rounded-pill mx-2 text-center">
                        <button type="submit" pTooltip="save information" (click)="submit()" showDelay="1000"
                            tooltipPosition="right" class="rounded-pill save-btn ">

                            {{'save'|translate}}<span></span>
                        </button>
                    </div>
                </div>





            </form>
        </div>
    </div>
        <div class="col-xl-3 col-lg-3  mt-0 mt-md-5 p-0  img-fluid authentication-bg d-flex justify-content-center align-items-center d-lg-block d-none" [ngClass]="{'authentication-bg':img}">
            <div #gmap4 style="width:90%;height:80%" #addresstext></div>
        </div>
        <div class="col-xl-3 col-lg-3   mt-0 mt-md-5 p-0 img-fluid  d-flex justify-content-center align-items-center d-lg-block d-none" [ngClass]="{'authentication-bg':img2}">
            <div #gmap3 style="width:90%;height:80%" #addresstext></div>
        </div>
</div>
</div>

