import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { EventsService } from 'src/app/services/events.service';
import { ProfileEditingSrvService, UserTicketsOrOrderDto, userLastEventDto } from './user-profile-home-edit/profile-editing-srv.service';
import { environment } from 'src/environments/environment';
import { DropDownItem } from 'src/app/Interfaces/DropDownItem';
import { Router } from '@angular/router';
import { OverlayPanel } from 'primeng/overlaypanel';
import * as m from 'moment';
import 'moment/locale/pt-br';
import { TranslateService } from '@ngx-translate/core';



@Component({
  selector: 'app-user-home-profile',
  templateUrl: './user-home-profile.component.html',
  styleUrls: ['./user-home-profile.component.scss']
})
export class UserHomeProfileComponent implements OnInit {
  image: string="";
  url=environment.Api;
  name: string='';
  id:string=""
  events:userLastEventDto;
  ticketsandOrder:UserTicketsOrOrderDto[]=[];
 newOrgOrders:UserTicketsOrOrderDto[]=[]
 pastOrgOrders:UserTicketsOrOrderDto[]=[]
  orgnizerData:DropDownItem[]=[];
  eventcount: number=0;
  afternum: number;

  totalfollwers: number;
  constructor(private datePipe: DatePipe, private eventsService: EventsService
    ,private profilesrv:ProfileEditingSrvService,private rout:Router,private translate:TranslateService){
      this.translate.onLangChange.subscribe(() => {
        console.log(this.translate.currentLang)
        m.locale(this.translate.currentLang);
        console.log(m.locale())
      });

  }

  onRowSelect2(e ,op: OverlayPanel){
    if(e.data.id==""){
      this.id="";
      this.profilesrv.GetUserlastevant();
      this.profilesrv.GetUserTicketsOrOrder();
      this.profilesrv.GetTopInfo();

     this.orgnizerData= this.orgnizerData.filter(x=>x.id!="");
 
    }
    else{
      this.id=e.data.id;
      this.profilesrv.GetUserlastevant(e.data.id);
      this.profilesrv.GetUserTicketsOrOrder(e.data.id);
      this.profilesrv. GetTopInfo(e.data.id);

     var t= this.orgnizerData.find(x=>x.id=="");
     if( t==undefined){
      this.orgnizerData.push({id:"",name:"Your account"})
     }

  
  
  
    }
    op.hide();

    console.log(e)
  }
 
  downloadTickets(f){
    this.rout.navigate(['event', f.eventId,'order','confirmation',f.orderId])
  }
  gotoOnlinePage(id){
    this.rout.navigate(['event', id,'online'])
  }

  preview(id) {
    this.rout.navigate(['event', id])

  }

  editProduct(event){
    this.rout.navigate(['/events/manage-event/', event.id]);
  }
  ngOnInit(): void {

    this.id = '';
    this.profilesrv.GetUserTicketsOrOrder();
    this.profilesrv.GetTopInfo();
    this.profilesrv.$getProfileTopInfo.subscribe(x=>{
           this.name=x.name;
           this.eventcount=x.totalEvent;
           this.totalfollwers=x.totalfolwers;

    })
    this.profilesrv.$getUserTicketsOrOrders.subscribe((x) => {
        this.ticketsandOrder = x;
        
        this.newOrgOrders = [];
         this.pastOrgOrders = [];
        x.forEach((y) => {
          if(y.usertickets){
           // console.log(this.getDateOntomeZone(y.eventEndDate) < this.getDateOntomeZone(new Date(Date.now())))
            if (this.getDateOntomeZone(y.eventEndDate) < this.getDateOntomeZone(new Date(Date.now()))) {
                this.pastOrgOrders.push(y);
            } else {
                this.newOrgOrders.push(y);
            }
          }else{

   var last=this.getDateOntomeZone(new Date(new Date(Date.now()).setDate(new Date(Date.now()).getDate()-7)))
            var b=
            console.log(this.getDateOntomeZone(y.eventEndDate) < this.getDateOntomeZone(new Date(Date.now()))||this.getDateOntomeZone(y.orderDate)<last)
            if (this.getDateOntomeZone(y.eventEndDate) < this.getDateOntomeZone(new Date(Date.now()))||this.getDateOntomeZone(y.orderDate)<last) {
                this.pastOrgOrders.push(y);
            } else {
                this.newOrgOrders.push(y);
            }
          }
            
        });
    });
    this.profilesrv.GetUserlastevant();
    this.profilesrv.$getUserprofileevent.subscribe((x) => {
        this.events = x.lastevent;
        console.log('events', x.lastevent);
       
        this.afternum =
            x.lastevent == null
                ? 0
                : Math.floor(
                      ( this.getDateOntomeZone(
                        x.lastevent.startDate
                    ).getTime()-this.getDateOntomeZone(new Date(Date.now())).getTime() 
                          ) /
                          (1000 * 60 * 60 * 24)
                  );
    });
    this.profilesrv.GetProfileImage();
    this.profilesrv.$getUserprofileImage.subscribe((x) => {
        this.image = x.image;
    });

    this.profilesrv.GetUserProfileInfo();
    this.profilesrv.$getUserprofileContactInfo.subscribe((x) => {
        this.name = x.firstName + '' + x.lastName;
    });
    this.eventsService.GetOrganizersByUser().subscribe((x) => {
        console.log(x);
        this.orgnizerData = x;
        
    }); 
   
}

getDateOntomeZone(d: Date) {
    var t = new Date(Date.now()).getTimezoneOffset()
    var MyDate = new Date(d).setMinutes(new Date(d).getMinutes() - (new Date(Date.now()).getTimezoneOffset()));
    return new Date(MyDate);
  }
  getDateOntomeZoneformat(d,x){
    let t = new Date(Date.now()).getTimezoneOffset();
    let MyDate = new Date(d).setMinutes(new Date(d).getMinutes() - (t));
    var l="";
    switch(x){
      case 'full':if(new Date(MyDate).getFullYear()==new Date(Date.now()).getFullYear()){
        l= this.datePipe.transform(new Date(MyDate), 'EEE MMMM d hh:mm a');

    }else{
       l= this.datePipe.transform(new Date(MyDate), 'EEE MMMM d,y hh:mm a');

    }
    break;
    case 'month': l=this.datePipe.transform(new Date(MyDate), 'MMM');
    break;
    case 'day':l=this.datePipe.transform(new Date(MyDate), 'dd');
    break
    }
    return l




   // return new Date(MyDate).toLocaleString();
}
getDateOntomeZoneformat2(d,x){
  
   m.locale();
  let t = new Date(Date.now()).getTimezoneOffset();
  let MyDate = new Date(d).setMinutes(new Date(d).getMinutes() - (t));
  var l="";
  switch(x){
    case 'full':if(new Date(MyDate).getFullYear()==new Date(Date.now()).getFullYear()){
      l= m(new Date(MyDate)).format('ddd MMMM D,  hh:mm a');

  }else{
     l=  m(new Date(MyDate)).format( 'ddd MMMM D /y,  hh:mm a');

  }
  break;
  case 'month': l=m(new Date(MyDate)).format('MMM');
  break;
  case 'day':l=m(new Date(MyDate)).format('D');
  break
  }
  return l




 // return new Date(MyDate).toLocaleString();
}
}
