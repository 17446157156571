<!-- <div class="card-2-modified p-0 mx-2 mb-3 row d-flex  justify-content-between revers-cols">
    <div class=" col-md-6 col-sm-12 mb-2 pt-sm-3 ">
        <div class=" w-100 text-break">
            <h3 class=" text-black w-100 pe-1  text-primary ">
                {{item.title}}
            </h3>
        </div>
        <div class=" w-100 text-break">
            <span class=" text-primary d-block ">
                <i class="fa-solid fa-calendar-days text-primary pe-2"></i>
                <span class=" text-black">
                    {{getDateOntomeZone(item.startDate)}}

                </span>
            </span>
        </div>
        <div class=" w-100 text-break">

            <span class=" text-primary d-block">
                <i class="fa-sharp fa-solid fa-location-dot text-primary pe-2"></i>
                {{item.address}}
            </span>

        </div>
        <div class="w-100 text-break">
            <div class="text-sm-end ">
                <span class=" d-flex text-primary d-block">
                    <i class="fa-solid fa-user text-primary pe-2"></i>
                    <span class="text-black">
                        {{item.orgName}}

                    </span>
                </span>
            </div>
        </div>
        <div class="w-100 text-break">
            <span class=""><i class="fa-solid fa-users text-primary "></i> <span class="text-primary">
                </span> Followers</span>
        </div>
    </div>

    <div class="col-md-6 col-sm-12 text-end py-3">
        <img class=" event-image img" [src]="url + item.photo" alt="Event Pic">
        <span class=" love-btn " (click)="Like(item.id)"><i
                class="{{ua.LikedEvents.includes(item.id)? 'fa-solid text-danger':'fa-solid' }} fa-heart "></i></span>
    </div>
</div> -->

<div class="card-2-modified p-0 mx-2 mb-3 row d-flex  justify-content-between align-items-center">

                            <div class="col-sm-6 col-12 mb-2 pt-sm-3">
                                <h6 class="d-block fw-bold text-lenght"><a   [ngClass]="{'text-primary':filters?.name!=''}" [routerLink]="'/event/'+item.id">{{item.title}}</a></h6>
                                <span class="d-block" [ngClass]="{'text-primary':filters?.start!=''||filters?.end!=''}" ><i
                                        class="fa-solid fa-calendar-days text-primary me-1"></i>{{getDateOntomeZoneformat2(item.startDate)}}</span>
                                <span class="d-block" [ngClass]="{'text-primary':filters.address!=''||filters?.online}" ><i class="fa-sharp fa-solid fa-location-dot text-primary"></i>
                                    {{item.address}}</span>
                                <aside class="d-block" >

                                    <i class="fa-solid fa-sack-dollar text-primary me-1"></i>

                                    {{item.price.minValue!=item.price.maxValue?('Starts at:'|translate)+ " " +item.price.minValue+""+item.curSymbol:item.price.maxValue==0?('Free'|translate):('Starts at:'|translate)+" "+item.price.maxValue+""+item.curSymbol}}</aside>
                                <span class="d-block text-lenght" ><i class="fa-solid fa-user text-primary me-1"></i>
                                    <a  [routerLink]="['/organizers','preview',item.orgId]" [ngClass]="{'text-primary':filters?.org}">{{item.orgName}}</a>
                                   </span>
                                <span class=""><i class="fa-solid fa-users text-primary me-1"></i> <span
                                        class="text-primary"> {{item.orgFollowers }} </span> {{'Followers'|translate}}</span>
                            </div>
                            <div class="col-sm-6 col-12 text-end mt-sm-0 mt-1">
                                <img class=" event-image w-100" [src]="url + item.photo" alt="Event Pic">
                                <span  [ngClass]="{' love-btn':document.documentElement.dir === 'ltr',' love-btn-rtl':document.documentElement.dir === 'rtl'}" (click)="Like(item.id)"><i  class="{{ua.LikedEvents.includes(item.id)? 'fa-solid':'fa-regular' }} fa-heart "></i></span>
                            </div>
                        </div>
