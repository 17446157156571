
<div class="  row align-items-center p-0">
<p-toast></p-toast>
    <div class="card  border-0 col-xl-4 col-lg-5 col-12 m-0 p-0 login-tab-mob">
        <a class="navbar-brand d-lg-flex d-none" routerLink="/">
            <img src="{{LogoUrl}}"
             class="logo-size-larg" alt="Antares"></a>
             <!-- src="../../../../assets/img/Logo/Antares - Logo H (Colored).png" -->
        <div class=" text-center card-title ">
            <h1 class="text-primary">{{'Register'|translate}}</h1>
        </div>
        <div class="card-body">

            <form  [formGroup]="registerForm" (ngSubmit)="register()" autocomplete="off">

                <div class="row justify-content-center">
                    <div class="p-input-filled   col-12">
            <span class="p-float-label">
                <input [readOnly]="disableEmail" type="email"  (change)="check()" formControlName="email" class="form-control {{disableEmail ? 'bg-gary' : '' }}" pInputText id="email" [ngClass]="{
                    'ng-invalid ng-dirty':
                    registerForm.get('email')?.touched &&
                    registerForm.get('email')?.invalid
                }" />
                <label htmlFor="email">{{'Email'|translate}}</label>
            </span>
                        <small class="p-error" *ngIf="registerForm.get('email')?.touched &&
            registerForm.get('email')?.invalid ">{{'Please add valid value.'|translate}}</small>
                    </div>
                    <div class="p-input-filled col-md-6 col-12">
            <span class="p-float-label">
                <input type="text" [readOnly]="disablenames"  formControlName="firstName" class="form-control {{disablenames ? 'bg-gary' : '' }}" pInputText id="firstName"   [ngClass]="{
                    'ng-invalid ng-dirty':
                    registerForm.get('firstName')?.touched &&
                    registerForm.get('firstName')?.invalid
                }"/>
                <label htmlFor="firstName">{{'First name'|translate}}</label>
            </span>
            <small class="p-error" *ngIf="registerForm.get('firstName')?.touched &&
            registerForm.get('firstName')?.errors?.required">{{'Please add valid value.'|translate}}</small>
            <small class="p-error" *ngIf="registerForm.get('firstName')?.errors?.pattern">{{'No white space.'|translate}}</small>
                    </div>

                    <div class="p-input-filled col-md-6 col-12">
            <span class="p-float-label">
                <input type="text"  [readOnly]="disablenames" formControlName="lastName" class="form-control {{disablenames ? 'bg-gary' : '' }}" pInputText id="lastName"   [ngClass]="{
                    'ng-invalid ng-dirty':
                    registerForm.get('lastName')?.touched &&
                    registerForm.get('lastName')?.invalid
                }"/>
                <label htmlFor="lastName">{{'Last name'|translate}}</label>
            </span>
                        <small class="p-error" *ngIf="registerForm.get('lastName')?.touched &&
            registerForm.get('lastName')?.errors?.required">{{'Please add valid value.'|translate}}</small>
            <small class="p-error" *ngIf="registerForm.get('lastName')?.errors?.pattern">{{'No white space.'|translate}}</small>
                    </div>


                    <div class="p-input-filled col-md-6 col-12">
            <span class="p-float-label">
                <p-password [promptLabel]="' '" weakLabel=" " strongLabel=" " mediumLabel=" " formControlName="password" class="w-full p-0"  id="password"   [ngClass]="{
                    'ng-invalid ng-dirty':
                    registerForm.get('password')?.touched &&
                    registerForm.get('password')?.invalid
                }"  [style]="{'width':'100%'}"
                [inputStyle]="{'width':'100%','height':'50px'}"
                 [toggleMask]="true">

                <ng-template pTemplate="footer">
                    <p-divider></p-divider>
                    <p class="mt-2">{{'Password must contain :'|translate}} </p>
                    <ul class="pl-2 ml-2 m-3" style="line-height: 1.5">
                        <li>{{'At least one lowercase'|translate}}</li>
                        <li>{{'At least one uppercase'|translate}}</li>
                        <li>{{'At least one speical character'|translate}}</li>
                        <li>{{'At least one numeric'|translate}}</li>
                        <li>{{'Minimum 8 characters'|translate}}</li>
                    </ul>
                </ng-template></p-password>
                <label htmlFor="password">{{'Password'|translate}}</label>
            </span>
                        <small class="p-error" *ngIf="registerForm.get('password')?.touched &&
            registerForm.get('password')?.invalid ">{{'Please add valid value.'|translate}}</small>
                    </div>

                    <div class="p-input-filled col-md-6 col-12">
                        <span class="p-float-label">
                            <p-password  formControlName="confirmPassword" class="w-full"  id="confirmPassword"   [ngClass]="{
                                'ng-invalid ng-dirty':
                                registerForm.get('confirmPassword')?.touched &&
                                registerForm.get('confirmPassword')?.invalid
                            }"  [style]="{'width':'100%'}"
                            [inputStyle]="{'width':'100%','height':'50px'}"
                            [feedback]="false"
                            [toggleMask]="true"></p-password>
                            <label htmlFor="confirmPassword">{{'Confirm password'|translate}}</label>
                        </span>
                        <small class="p-error" *ngIf="registerForm.get('confirmPassword')?.touched &&
            registerForm.get('confirmPassword').errors?.required ">{{'Confirm password is required'|translate}}</small>
                        <small class="p-error" *ngIf="registerForm.get('confirmPassword')?.errors?.confirmPasswordValidator">
                            {{'Passswordandconfirm'|translate}}</small>
                                </div>


                </div>
                <div class="d-flex justify-content-center mt-3">
                    <div class="banner-btn rounded-pill mx-2 text-center">
                        <button type="submit" class="rounded-pill login-btn" routerLink="/" >{{'CanclBtn'|translate}}<span></span></button>
                    </div>

                    <div class="banner-btn rounded-pill mx-2 text-center">
                        <button [disabled]="registerForm.invalid||AuthService.isSubmitting" type="submit" pTooltip="Register" showDelay="1000" tooltipPosition="right" class="rounded-pill save-btn {{AuthService.isSubmitting? 'bg-disabled' : ''}}">
                            <i  *ngIf="AuthService.isSubmitting" class="fas fa-spinner fa-spin"></i>
                            {{'Register'|translate}} <span></span>
                        </button>
                    </div>
                </div>

                <div class="text-center my-3 col-12">
                    <span>{{'haveaccount'|translate}} <a class="text-primary fw-bold" routerLink="/login">{{'Sign in'|translate}}</a></span>
                </div>

                <div class="text-center col-12 mb-3 sign-border ">
                    <span class="bg-white px-5 ">{{'Or sign in with'|translate}}</span>
                </div>
                <div class="text-center col-12 ">
                    <a class="me-3   social-btn rounded-circle" href="javascript:;"><i class="fa-brands fa-google "> </i>  <span></span></a>
                    <a class="  social-btn rounded-circle" href="javascript:;"><i class="fa-brands fa-facebook"></i> <span></span></a>

                </div>


            </form>
        </div>
    </div>
    <div class="col-xl-8 col-lg-7 d-lg-flex d-none m-0 p-0 img-fluid authentication-bg">

    </div>
</div>
