 //Edit event details 101050301
//Manage event Status 101050302
//Create events 101050303
//Manage tickets 101050304
//Access basic event report 101050305
//Manage organizer members and permissions 101050306
//Edit organizer information 101050307



export class AppUserAuth {
    id : string = "";
    firstName : string = "";
    lastName : string = "";
    email : string = ""
    token:string= "";
    isAuthenticated: boolean = false;
    permissions : number[] = [];
    UserRole:string[];

    init(): void {
        this.firstName = "";
        this.lastName = "";
        this.id = "";
        this.email = "";
        this.token="";
        this.isAuthenticated = false;
        this.permissions = [];
        this.UserRole=[];
    }
    getValueOfProperty(obj: any, key:string):boolean {
        let ret = obj[key];
        return ret;
      }
    checkClaim(id : number){
        let x  = this.permissions.find(x => x == id);
        if(x){
            return true;
        }
        return false;
    }
     checkRole(id:string){

        let x  = this.UserRole.find(x => x == "de0f642f-ad5c-469b-9e68-6f542d6cdb92");
        if(x){
            return true;
        }
        return false;

     } 

}
