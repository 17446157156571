import { Component, ViewChild, EventEmitter, Output, OnInit, AfterViewInit, Input } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
// import { } from '@types/googlemaps';

@Component({
    selector: 'AutocompleteComponent',
    template: `

        <span class="p-float-label">
                                <input type="text"  #addresstext id="event-title" pInputText
                                       class="form-control"
                                       (input)="getPlaceAutocomplete()"
                                  
                                       [(ngModel)]="autocompleteInput"
                                >
                                <label class="font-size-14" for="event-title">{{'LctionInpt001'|translate}}</label>
      </span>

    `,
})
export class AutocompleteComponent implements OnInit, AfterViewInit {
    @Input() addressType: string;
    @Input() addressValue: string;
    @Output() setAddress: EventEmitter<any> = new EventEmitter();
    @ViewChild('addresstext') addresstext: any;

    autocompleteInput:string
    queryWait: boolean;
    @Output('problem') thereIsAProblem: EventEmitter<boolean> = new EventEmitter();

     
    constructor() {
    }

    ngOnInit() {
    }
    
    ngAfterViewInit() {
        this.getPlaceAutocomplete();
    }

     getPlaceAutocomplete() {
        let autocomplete
        try{
             autocomplete = new google.maps.places.Autocomplete(this.addresstext.nativeElement,
                {
                    // componentRestrictions: { country: 'US' },
                    types: [this.addressType]  // 'establishment' / 'address' / 'geocode'
                });
                google.maps.event.addListener(autocomplete, 'place_changed', (x) => {

                    const place = autocomplete.getPlace();
                    this.setAddress.emit(place);
                    // this.formInit(place.formatted_address,
                    //     place.address_components.find(y => y.types.includes('administrative_area_level_1')).long_name,
                    //     place.address_components.find(y => y.types.includes('country')).long_name,
                    //     place.geometry.location.lat(),
                    //     place.geometry.location.lng()
                    //        );

                });
                // google.maps.event.addListener(autocomplete, 'place_changed', (x) => {
                //     const place = autocomplete.getPlace();
                
                //     // Check if the address is in the USA
                //     const addressComponents = place.address_components;
                //     const countryComponent = addressComponents.find(component => component.types.includes('country'));
                    
                //     if (countryComponent && countryComponent.short_name === 'US') {
                //         // Get the state code of the address
                //         const stateComponent = addressComponents.find(component => component.types.includes('administrative_area_level_1'));
                //         const stateCode = stateComponent ? stateComponent.short_name : 'State code not available';
                
                //         console.log('State Code:', stateCode);
                //     } else {
                //         console.log('Address is not in the USA');
                //     }
                
                //     this.setAddress.emit(place);
                // });
                
        }
        catch{
            this.thereIsAProblem.emit(true) ;
        }


    }
    // formInit(address: string, city: string, country: string, lat: any, lng: any){
    //     this.form.controls['address'].setValue(address)
    //     this.form.controls['country'].setValue(country)
    //     this.form.controls['city'].setValue(city)
    //     this.form.controls['lat'].setValue(lat)
    //     this.form.controls['lng'].setValue(lng)

    invokeEvent(place: Object) {
        this.setAddress.emit(place);
    }

}
