import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AddOnGroupStepsService } from 'src/app/services/add-on-group-steps.service';
import { EventStepsService } from 'src/app/services/event-steps.service';

@Component({
  selector: 'app-addon-group-form',
  templateUrl: './addon-group-form.component.html',
  styleUrls: ['./addon-group-form.component.scss']
})
export class AddonGroupFormComponent implements OnInit {

    pageTitle:string="";
    helpStepsModal: boolean;
    position: string;
    showHelpStepsModal() {
        this.helpStepsModal = true;
    }

    items = [
        {label: 'Group info', icon: 'pi pi-fw pi-home', routerLink:['group-info'] },
        {label: 'Varieties', icon: 'pi pi-fw pi-calendar',routerLink:  ['vareities'] }


    ];
    constructor(private router:Router,private activatedRoute:ActivatedRoute
      ,private eventStepsSrv: EventStepsService,
    public addOnsGroupSrv:AddOnGroupStepsService,
    private translate: TranslateService){
      this.translate.onLangChange.subscribe(() => {
        this.updateTranslations();
        
      });

    }
  updateTranslations() {
    this.items = [
      {label:this.translate.instant('AddOnGrpFrmGrpInfoTitl') , icon: 'pi pi-fw pi-home', routerLink:['group-info'] },
      {label: this.translate.instant('AddOnGrpFrmVrtsTitl'), icon: 'pi pi-fw pi-calendar',routerLink:  ['vareities'] }


  ];
  }
    ngOnInit(): void {
      this.addOnsGroupSrv.AddonsGroupForm.controls['eventId'].setValue(this.eventStepsSrv.id)
      this.updateTranslations()
        if (this.addOnsGroupSrv.AddonsGroupForm.controls['id'].value == "0") {
           // this.pageTitle = "Add new add-on group"
           this.pageTitle = "AddOnGrpFrmTitrl001"
        } else {
           // this.pageTitle = "Update add-on group"
           this.pageTitle = "AddOnGrpFrmTitrl002"
        }

        this.router.navigate( ['group-info'] ,{relativeTo: this.activatedRoute});
    }
}
