import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { DropDownItem } from 'src/app/Interfaces/DropDownItem';
import { CheckboxModule } from 'primeng/checkbox';
import { promoteFormdto } from 'src/app/Interfaces/promoteData';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { PromoteService } from 'src/app/services/promote.service'
import { ActivatedRoute, Router } from '@angular/router';
import { PromoteFormStepsService } from 'src/app/services/promote-form-steps.service';
import { EventStepsService } from 'src/app/services/event-steps.service';
import { TicketsService } from 'src/app/services/tickets.service';
import { TranslateService } from '@ngx-translate/core';

interface multiSelectOptions {
  name: string,
  code: string
}



@Component({
  selector: 'app-promoteform',
  templateUrl: './promoteform.component.html',
  styleUrls: ['./promoteform.component.scss']
})
export class PromoteformComponent implements OnInit {

    helpStepsModal: boolean;
    position: string;
    showHelpStepsModal() {
        this.helpStepsModal = true;
    }



  items = [{ label: 'step1', routerLink:  ['step1'] },
  { label: 'step2', routerLink:  ['step2']  },
  { label: 'step3', routerLink: ['step3']  },
  ]
  parentId: string;





  constructor(private eventStepsSrv: EventStepsService,
    private traslate:TranslateService, 
    private tikserv: TicketsService,
     private fb: FormBuilder,
      public prosrvform: PromoteFormStepsService,
       public config: DynamicDialogConfig,
        private activatedRoute: ActivatedRoute,
         private router: Router,
          private promoteserv: PromoteService,
     private ref: DynamicDialogRef) {

      this.traslate.onLangChange.subscribe(() => {
        this.updateTranslateio();
      });
  }
  updateTranslateio() {
   this.items = [{ label: this.traslate.instant('PrmoFrmStp1Tilt'), routerLink:  ['step1'] },
    { label:this.traslate.instant('PrmoFrmStp2Titl'), routerLink:  ['step2']  },
    { label:this.traslate.instant('PrmoFrmStp3Titl') , routerLink: ['step3']  },
    ]
  }
  ngOnInit(): void {
   // console.log(this.eventStepsSrv.id)
    this.updateTranslateio();
    this.prosrvform.promoteCodeForm.controls['eventId'].setValue(this.eventStepsSrv.id)
    if (this.prosrvform.promoteCodeForm.controls['id'].value != '0') {

      this.router.navigate( ['step1'], { relativeTo: this.activatedRoute });
    }
    else {


      this.router.navigate( ['step1'], { relativeTo: this.activatedRoute });
    }




  }



}
