<form [formGroup]="form" (submit)="submit()">

    <div class="row justify-content-center mb-2">
        <div class="p-input-filled col-sm-12 col-12 mb-2">
            <span class="p-float-label">
                <input type="text" formControlName="email" pInputText class="form-control" [ngClass]="{
                        'ng-invalid ng-dirty':(  form.get('email')?.touched &&
                        form.get('email')?.invalid)||(submitted&& form.get('email').invalid)
                    }" [class.ng-invalid.ng-dirty]="" />
                <label class="form-lable font-size-14" for="Ticketemail">{{'InvtNuMmbrMdlInpt001'|translate}}</label>
            </span>
            <small class="p-error" *ngIf="(  form.get('email')?.touched &&
            form.get('email')?.invalid)||(submitted&& form.get('email').invalid)
                " >{{'InvtNuMmbrMdlInpt001ErrMsg'|translate}}</small>

        </div>
        <div class="p-input-filled col-sm-6 col-12 mb-2">
            <span class="p-float-label">
                <input type="text" formControlName="firstName" pInputText class="form-control" [ngClass]="{
                        'ng-invalid ng-dirty':(  form.get('firstName')?.touched &&
                        form.get('firstName')?.invalid)||(submitted&& form.get('firstName').invalid)
                    }" [class.ng-invalid.ng-dirty]="" />
                <label class="form-lable font-size-14" for="firstName">{{'InvtNuMmbrMdlInpt002'|translate}}</label>
            </span>
            <small class="p-error" *ngIf="(  form.get('firstName')?.touched &&
            form.get('firstName')?.invalid)||(submitted&& form.get('firstName').invalid)
                " >{{'InvtNuMmbrMdlInpt002ErrMsg'|translate}}</small>

        </div>
        <div class="p-input-filled col-sm-6 col-12 mb-2">
            <span class="p-float-label">
                <input type="text" formControlName="lastName" pInputText class="form-control" [ngClass]="{
                        'ng-invalid ng-dirty':(  form.get('lastName')?.touched &&
                        form.get('lastName')?.invalid)||(submitted&& form.get('lastName').invalid)
                    }" [class.ng-invalid.ng-dirty]="" />
                <label class="form-lable font-size-14" for="lastName">{{'InvtNuMmbrMdlInpt003'|translate}}</label>
            </span>
            <small class="p-error" *ngIf="(  form.get('lastName')?.touched &&
            form.get('lastName')?.invalid)||(submitted&& form.get('lastName').invalid)
                " >{{'InvtNuMmbrMdlInpt002ErrMsg'|translate}}</small>

        </div>
        <small class="p-error" *ngIf="(form.hasError('atLeastOneRequired'))">{{'InvtNuMmbrMdlInpt002ErrMsg'|translate}}</small>
        <div class="p-input-filled col-sm-12 col-12 mb-2">
            <span class="p-float-label">
                <input type="text" formControlName="title" pInputText class="form-control" [ngClass]="{
                        'ng-invalid ng-dirty':(  form.get('title')?.touched &&
                        form.get('title')?.invalid)||(submitted&& form.get('title').invalid)
                    }" [class.ng-invalid.ng-dirty]="" />
                <label class="form-lable font-size-14" for="title">{{'InvtNuMmbrMdlInpt004'|translate}}</label>
            </span>
            <small class="p-error" *ngIf="(  form.get('title')?.touched &&
            form.get('title')?.invalid)||(submitted&& form.get('title').invalid)
                " >{{'InvtNuMmbrMdlInpt004ErrMsg'|translate}}</small>

                

        </div>
        <div class="p-input-filled col-sm-6 col-12 {{!showDropDown? 'd-none' : ''}}" >
            <span class="p-float-label">
                <p-dropdown  [filter]="true" filterBy="name" [showClear]="true" #op appendTo="body" formControlName="organizerId" (onChange)="getRoles($event.value)" autoWidth="false" [style]="{'width':'100%'}"
                    [options]="data" optionLabel="name" optionValue="id" [ngClass]="{
        'ng-invalid ng-dirty':
            (form.get('orgId')?.touched &&
            form.get('orgId')?.invalid) || ( submitted &&
            form.get('orgId')?.invalid)
    }" placeholder="Organizer">
                    <ng-template let-item pTemplate="selectedItem">
                        <div class="flex align-items-center gap-2">

                            <div>{{ item.name }}</div>
                        </div>
                    </ng-template>
                    <ng-template let-item pTemplate="item">
                        <div class="flex align-items-center gap-2">

                            <div>{{ item.name }}</div>
                        </div>
                    </ng-template>

                </p-dropdown>
                <label class="font-size-14" for="orgId">{{'InvtNuMmbrMdlSlct001'|translate}}</label>
            </span>
            <small class="p-error" *ngIf="(form.get('orgId')?.touched &&
    form.get('orgId')?.invalid) || ( submitted &&
    form.get('orgId')?.invalid)">{{'InvtNuMmbrMdlSlct001ErrMsg'|translate}}</small>

        </div>

        <div class="p-input-filled col-sm-6 col-12" *ngIf="op.value">
            <span class="p-float-label">
                <p-dropdown [filter]="true" filterBy="name" [showClear]="true"  appendTo="body" formControlName="roleId"
                 autoWidth="false" [style]="{'width':'100%'}"
                    [options]="data1" optionLabel="name" optionValue="id" [ngClass]="{
        'ng-invalid ng-dirty':
            (form.get('roleId')?.touched &&
            form.get('roleId')?.invalid) || ( submitted &&
            form.get('roleId')?.invalid)
    }" placeholder="Role">
                    <ng-template let-item pTemplate="selectedItem">
                        <div class="flex align-items-center gap-2">
                            <div>{{ item.name }}</div>
                        </div>
                    </ng-template>
                    <ng-template let-item pTemplate="item">
                        <div class="flex align-items-center gap-2">
                            <div>{{ item.name }}</div>
                        </div>
                    </ng-template>
                    <ng-template *ngIf="showDropDown" pTemplate="footer">
                        <div  class="p-0 flex align-items-center gap-2">
                            <button pRipple type="button" (click)="roleMake(op.value)" class="w-100  next-btn border-0"><i class="fa-solid fa-plus p-1"></i>Role<span></span></button>
                        </div>
                    </ng-template>
                </p-dropdown>
                <label class="font-size-14" for="roleId">{{'InvtNuMmbrMdlSlct002'|translate}}</label>
            </span>
            <small class="p-error" *ngIf="(form.get('roleId')?.touched &&
    form.get('roleId')?.invalid) || ( submitted &&
    form.get('roleId')?.invalid)">{{'InvtNuMmbrMdlSlct002ErrMsg'|translate}}</small>

        </div>
    </div>

    <div class=" d-flex  justify-content-center">
            <button  class="rounded-pill next-btn  ms-1 ">{{'InvtNuMmbrMdlBtn001'|translate}} <i class="fa-solid fa-paper-plane"></i><span></span></button>
    </div>

</form>


