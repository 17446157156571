
    <!-- <div class="container">
        <div class="card custom-table-border">

            <div class="card-header border-0 text-center bg-primary rounded-top-23 ">
                <h1 class="fw-bold text-white mt-2">{{'ConfirmationTitl'|translate}}</h1>
            </div>
            <div class="card-body">
                <div class="card-body p-0">
                    <div class="row revers-cols ">
                        <h1>{{'ConfirmationMsg'|translate}}</h1>
                    </div>
                    <div class="row revers-cols mt-3"  [innerHTML]="data.orderConfig?.pageMessage" > -->
                        <!-- {{data.orderConfig.pageMessage}} -->
                    <!-- </div>
                    <div class="row revers-cols mt-3 ">
                        <h4>{{'ConfirmationMsg'|translate}}</h4> -->
                        <!-- <h4>{{'ConfirmationMsg2'|translate}}</h4> -->
                    <!-- </div>
                    <div class="row revers-cols mt-3 ">
                        <h4>{{'ConfirmationMsg3'|translate}}</h4>
                        <img [src]=" imageUrl" alt="Image" style="max-width: 25%;">
                        <div class="row revers-cols mt-3   justify-content-start">
                            <button (click)="downloadImage()" class=" rounded-pill save-btn w-25">
                                {{'ConfirmationBtn'|translate}}
                            </button>
                        </div>
                    </div>
                    <div class="row revers-cols mt-3   justify-content-center">
                        <button (click)="GoTohomePage()" class=" rounded-pill save-btn w-25">
                            {{'ConfirmationBtn2'|translate}}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div> -->
    <div class="container mt-3">
        <div class="card custom-table-border">
            <!-- <div class="card-header text-center bg-primary rounded-top-23">
                <h1 class="text-white">Order</h1>
            </div> -->
            <div class="card-header border-0 text-center bg-primary rounded-top-23 ">
                <h1 class="fw-bold text-white mt-2">{{'ConfirmationTitl'|translate}}</h1>
            </div>
            <div class="card-body" style="overflow: hidden;">
                <div class="card-body p-0">
                    <div class="row revers-cols ">
                        <h1>{{'ConfirmationMsg'|translate}}</h1>
                    </div>
                    <div class="row revers-cols mt-3"  [innerHTML]="data.orderConfig?.pageMessage" >
                        <!-- {{data.orderConfig.pageMessage}} -->
                    </div>
                    <!-- <div class="row revers-cols mt-3 "> -->
                        <!-- <h4>{{'ConfirmationMsg'|translate}}</h4> -->
                        <!-- <h4>{{'ConfirmationMsg2'|translate}}</h4> -->
                    <!-- </div> -->


                    <div class="row revers-cols mt-3 " *ngFor="let d of DataofQr;let i=index">
                        <h4>{{'ConfirmationMsg3'|translate}}</h4>
                        <div  #ticket class="ticket print d-md-none d-block ">
                            <div class="holes-top"></div>
                            <div class="title">
                                <img src="../../../../../assets/img/Logo/Antares - Logo H (Colored).png" width="150" class="logo-size-larg" alt="Antares">
                                <p class="movie-title">{{eventName}}</p>
                            </div>
                            <!-- <div class="poster">
                                <img src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/25240/only-god-forgives.jpg" alt="Movie: Only God Forgives" />
                            </div> -->
                            <div class="info">
                            <table>
                                <tr>
                                    <th>{{'PRICE002'|translate}}</th>
                                    <th>{{'DATE'|translate}}</th>
                                    <th>{{'TIME'|translate}}</th>
                                </tr>
                                <tr>
                                    <td>{{total==0?('Free'|translate):total+' '+cur}}</td>
                                    <td><span>{{orderdate!=undefined?getDateOntomeZoneformat(orderdate,'date'):''}}</span></td>
                                    <td><Span>{{orderdate!=undefined?getDateOntomeZoneformat(orderdate,'time'):''}}</Span></td>
                                </tr>
                            </table>
                            </div>
                            <div class="holes-lower"></div>
                            <div class="serial">
                                <img  alt="Image" style="max-width: 75%;" #qrCodeImage2>
                            </div>
                        </div>



                        <div  class="ticket-md d-md-block d-none" style="min-height: 350px;">
                            <div class="holes-top-md"></div>
                            <div class="row">
                                <div class="col-6">
                                    <div class="title">
                                        <img src="../../../../../assets/img/Logo/Antares - Logo H (Colored).png" width="150" class="logo-size-larg" alt="Antares">

                                        <p class="movie-title">{{eventName}}</p>
                                    </div>

                                    <div class="info">
                                    <table>
                                        <tr>
                                            <th>{{'PRICE002'|translate}}</th>
                                            <th>{{'DATE'|translate}}</th>
                                            <th>{{'TIME'|translate}}</th>
                                        </tr>
                                        <tr>
                                            <td>{{total==0?('Free'|translate):total+' '+cur}}</td>
                                            <td><span>{{getDateOntomeZoneformat(orderdate,'date')}}</span></td>
                                            <td><Span>{{getDateOntomeZoneformat(orderdate,'time')}}</Span></td>
                                        </tr>
                                    </table>
                                    </div>
                                </div>
                                <div class="col-6">
                                     <div class="holes-lower-md"></div>
                            <div class="serial">

                                <!-- <qrcode [qrdata]="'Your data string'" [width]="256" [errorCorrectionLevel]="'Q'"></qrcode> -->

                                   <qrcode [qrdata]="d" [width]="256"  [scale]="64" colorDark="#ffc484"
                                     [errorCorrectionLevel]="'H'" #qrcode (qrCodeURL)="geturl($event,i)" [margin]="2" cssClass="d-none" ></qrcode>
                                  <img  alt="Image" style="max-width: 75%;"  #qrCodeImage>
                            </div>
                                </div>
                            </div>



                        </div>

                                 <!-- <img [src]="imageUrltest" alt="Image" style="max-width: 75%;">  -->


                        <div class="row revers-cols mt-3   justify-content-between">
                            <div class="col-lg-6 col-md-6 col-12 text-center">
                                <button (click)="exportToPDF(i) " class=" rounded-pill save-btn ">
                                    {{'ConfirmationBtn'|translate}}
                                </button>
                            </div>
                            <div class="col-lg-6 col-md-6 col-12 text-center">
                                <button (click)="GoTohomePage()" class=" rounded-pill save-btn ">
                                    {{'ConfirmationBtn2'|translate}}
                                </button>
                            </div>

                        </div>
                    </div>
                    <!-- <div class="row revers-cols mt-3   justify-content-center">
                        <div class="col-lg-4 col-md-6 col-12">
                            <button (click)="GoTohomePage()" class=" rounded-pill save-btn ">
                                {{'ConfirmationBtn2'|translate}}
                            </button>
                        </div>

                    </div> -->
                </div>
            </div>
        </div>
    </div>


