import { Component, OnDestroy, OnInit ,Inject} from '@angular/core';
import { DropDownItem, Sort } from 'src/app/Interfaces/DropDownItem';
import { EventCard, LandingPageService } from './landing-page.service';
import { environment } from 'src/environments/environment';
import { LoaderService } from 'src/app/services/loader.service';
import { AuthService } from 'src/app/services/auth.service';
import { IntersetService } from 'src/app/services/interset.service';
import { UserActionsService } from '../../../services/user-actions.service';
import { messages } from 'nx/src/utils/ab-testing';
import { options } from '@fullcalendar/core/preact';
import { Subscription } from 'rxjs';
import { UserlocationService } from 'src/app/services/userlocation.service';
import { TranslateService } from '@ngx-translate/core';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.scss']
})

export class LandingPageComponent implements OnInit, OnDestroy {
  isAt: boolean=false;
  userAddress: string = ""
  sub: Subscription[] = [];
  url = environment.Api;
  haveInterests = false;
  data: EventCard[] = [];
  data2: EventCard[] = [];
  Count:number = 0;

  items = [1, 2, 3, 4, 5];
  selectedSort: Sort = { id: '', name: 'Default' };
  sortType:DropDownItem[] = [];

  constructor(public int: IntersetService,
    public auth: AuthService,
    private loader: LoaderService,
    private srv: LandingPageService,
    public ua: UserActionsService,
    private userLocSrv: UserlocationService,
    public translate: TranslateService,
    @Inject(DOCUMENT) public document: Document,
  ) { 

    this.translate.onLangChange.subscribe(() => {
   //   console.log("jjgjggjjgj")
   //   console.log("lang is change")
      this.updateTranslations();});

  }
  updateTranslations() {
  //  console.log(this.sortType)
    this. selectedSort={ id: '',name: this.translate.instant('LndPgTitle004Chois001')};
    this.sortType = [
      { id: '', name: this.translate.instant('LndPgTitle004Chois001')},
      { id: 'p', name: this.translate.instant('LndPgTitle004Chois002')},
      { id: 'latest', name:  this.translate.instant('LndPgTitle004Chois003')  },
      { name: this.translate.instant('LndPgTitle004Chois004') , id: 'price2' },
      { name: this.translate.instant('LndPgTitle004Chois005') , id: 'price1' },
    ];
    ///console.log(this.sortType)

    var eventsub = this.srv.getLandingPageEvents().subscribe(x => { this.data = x; if (this.userAddress) { this.loader.hideLoader(); } }, err => { }, () => { if (this.userAddress) { this.loader.hideLoader(); } });
    this.sub.push(eventsub);
    this.data2=[];
   // console.log(this.selectedSort.id)
    var morEventSub = this.srv.getMoreEvent(6, this.Count, '').subscribe(x => { this.data2.push(...x); this.loader.hideLoader(); }, err => { }, () => { if (this.userAddress) { this.loader.hideLoader(); } });
    this.sub.push(morEventSub);
  
  }


  


  ngOnDestroy(): void {
    this.sub.forEach(element => {
      element.unsubscribe();
    });
  }


 



  ngOnInit(): void {
    this.updateTranslations()
   
    var userLocSub = this.userLocSrv.$getuserlocation.subscribe(x => {
      this.userAddress = x;
    });
    this.sub.push(userLocSub);
    //this.getLocation();
    this.loader.showLoader();
    // if()

    this.auth.$getUserlogin.subscribe(x=>{
     this. isAt=x
      if(x){
       
        this.int.getUserInterests(this.auth.securityObject.id);
        var userIntrest = this.int.$getUserInterests
          .subscribe(x => {
            if (x.length > 0) {
              this.haveInterests = true;
            }
          }
          );
        this.sub.push(userIntrest);
        var likeEvent = this.ua.getLikedEventList().subscribe(x => {
          this.ua.LikedEvents = x as string[];
    
        });
        this.sub.push(likeEvent);
      }else{
               
      }
    })
  
   
    // var eventsub = this.srv.getLandingPageEvents().subscribe(x => { this.data = x; if (this.userAddress) { this.loader.hideLoader(); } }, err => { }, () => { if (this.userAddress) { this.loader.hideLoader(); } });
    // this.sub.push(eventsub);
    // var morEventSub = this.srv.getMoreEvent(6, this.Count, this.selectedSort.id).subscribe(x => { this.data2.push(...x); this.loader.hideLoader(); }, err => { }, () => { if (this.userAddress) { this.loader.hideLoader(); } });
    // this.sub.push(morEventSub);
  }




  GetMoreData() {
    this.Count++;
    var morEventSub = this.srv.getMoreEvent(6, this.Count, this.selectedSort.id).subscribe(x => { this.data2.push(...x); });
    this.sub.push(morEventSub);
  }
  getorderEvent(e) {
    this.Count = 0;
    this.data2 = [];
    var morEventSub = this.srv.getMoreEvent(6, this.Count, this.selectedSort.id).subscribe(x => { this.data2.push(...x); });
    this.sub.push(morEventSub);
  }

  TabChange(x) {
    if(this.isAt){
      switch (x) {
        case 0:
          var morEventSub = this.srv.getLandingPageEvents().subscribe(x => { this.data = x; this.loader.hideLoader(); }, err => { }, () => { this.loader.hideLoader(); });
          this.sub.push(morEventSub);
          break;
        case 1:
          if(this.isAt){
            var morEventSub = this.srv.getForYou().subscribe(x => { this.data = x; this.loader.hideLoader(); }, err => { }, () => { this.loader.hideLoader(); });
            this.sub.push(morEventSub);
          }else{
            this.data = [];
          }
         
          break;
        case 2:
          var morEventSub = this.srv.getOnline().subscribe(x => { this.data = x; this.loader.hideLoader(); }, err => { }, () => { this.loader.hideLoader(); });
          this.sub.push(morEventSub);
          break;
        case 3:
          var morEventSub = this.srv.getToday().subscribe(x => { this.data = x; this.loader.hideLoader(); }, err => { }, () => { this.loader.hideLoader(); });
          break;
        case 4:
          var morEventSub = this.srv.getWeekend().subscribe(x => { this.data = x; this.loader.hideLoader(); }, err => { }, () => { this.loader.hideLoader(); });
          this.sub.push(morEventSub);
          break;
        case 5:
          var morEventSub = this.srv.getFree().subscribe(x => { this.data = x; this.loader.hideLoader(); }, err => { }, () => { this.loader.hideLoader(); });
          this.sub.push(morEventSub);
          break;
        case 6:
          var morEventSub = this.srv.getMyEvent().subscribe(x => { this.data = x; this.loader.hideLoader(); }, err => { }, () => { this.loader.hideLoader(); });
          this.sub.push(morEventSub);
          break;
        case 7:
          if(this.userAddress==""){
            alert("You need to allow access to your current location to get the optimal search results.")
            this.userLocSrv.getLocation();
            var userLocSub = this.userLocSrv.$getuserlocation.subscribe(x => {
           
              this.userAddress = x;
              var morEventSub = this.srv.getlocal(this.userAddress).subscribe(x => { this.data = x; if (this.userAddress) { this.loader.hideLoader(); } }, err => { }, () => { this.loader.hideLoader(); });
              this.sub.push(morEventSub);
            });
            this.sub.push(userLocSub);
          }else{
            var morEventSub = this.srv.getlocal(this.userAddress).subscribe(x => { this.data = x; if (this.userAddress) { this.loader.hideLoader(); } }, err => { }, () => { this.loader.hideLoader(); });
              this.sub.push(morEventSub);
          }
          break;
  
      }
    }else{
      switch (x) {
        case 0:
          var morEventSub = this.srv.getLandingPageEvents().subscribe(x => { this.data = x; this.loader.hideLoader(); }, err => { }, () => { this.loader.hideLoader(); });
          this.sub.push(morEventSub);
          break;
        case 1:
          if(this.isAt){
            var morEventSub = this.srv.getForYou().subscribe(x => { this.data = x; this.loader.hideLoader(); }, err => { }, () => { this.loader.hideLoader(); });
            this.sub.push(morEventSub);
          }else{
            this.data = [];
          }
         
          break;
       
        case 2:
          var morEventSub = this.srv.getToday().subscribe(x => { this.data = x; this.loader.hideLoader(); }, err => { }, () => { this.loader.hideLoader(); });
          break;
        case 3:
          var morEventSub = this.srv.getWeekend().subscribe(x => { this.data = x; this.loader.hideLoader(); }, err => { }, () => { this.loader.hideLoader(); });
          this.sub.push(morEventSub);
          break;
        case 4:
          var morEventSub = this.srv.getFree().subscribe(x => { this.data = x; this.loader.hideLoader(); }, err => { }, () => { this.loader.hideLoader(); });
          this.sub.push(morEventSub);
          break;
      
        case 5:
          if(this.userAddress==""){
            alert("You need to allow access to your current location to get the optimal search results.")
            this.userLocSrv.getLocation();
            var userLocSub = this.userLocSrv.$getuserlocation.subscribe(x => {
           
              this.userAddress = x;
              var morEventSub = this.srv.getlocal(this.userAddress).subscribe(x => { this.data = x; if (this.userAddress) { this.loader.hideLoader(); } }, err => { }, () => { this.loader.hideLoader(); });
              this.sub.push(morEventSub);
            });
            this.sub.push(userLocSub);
          }else{
            var morEventSub = this.srv.getlocal(this.userAddress).subscribe(x => { this.data = x; if (this.userAddress) { this.loader.hideLoader(); } }, err => { }, () => { this.loader.hideLoader(); });
              this.sub.push(morEventSub);
          }
          break;
  
      }
    }
    

  }


  // getAddress(latitude: number, longitude: number): void {
  //   const latlng = new google.maps.LatLng(latitude, longitude);
  //   console.log(latlng)
  //   const request: google.maps.places.PlaceSearchRequest = {
  //     location: latlng,
  //     radius: 100,
  //     type: 'address'
  //     //types: 
  //   };
  //   console.log(request)
  //   const service = new google.maps.places.PlacesService(document.createElement('div'));
  //   service.nearbySearch(request, (results, status) => {
  //     if (status === google.maps.places.PlacesServiceStatus.OK) {
  //       if (results[0]) {
  //         console.log(results[0]);
  //         this.userAddress = results[0].vicinity
  //         // Do something with the address
  //       } else {
  //         console.log('No results found');
  //       }
  //     } else {
  //       console.log(`Places search failed due to: ${status}`);
  //     }
  //   });
  // }

  // getLocation(): void {

  //   navigator.geolocation.getCurrentPosition((position) => {
  //     const longitude = position.coords.longitude;
  //     const latitude = position.coords.latitude;
  //     this.getAddress(latitude, longitude)


  //   },(error)=>{

  //    console.log("error")
  //   });

  // }


  getDateOntomeZone(d: Date) {
    let t = new Date(Date.now()).getTimezoneOffset();
    let MyDate = new Date(d).setMinutes(new Date(d).getMinutes() - (t));
    return new Date(MyDate).toLocaleString();
  }

  Like(id: string) {
    var morEventSub = this.ua.postLike(id).subscribe(x => {
      var ll = this.ua.getLikedEventList().subscribe(y => {
        this.ua.LikedEvents = y as string[];
      });
      this.sub.push(ll);
    });
    this.sub.push(morEventSub);
  }
}
