import { Component , OnDestroy, OnInit,Inject } from '@angular/core';
import { PromoteService } from 'src/app/services/promote.service';
import {TableModule} from 'primeng/table';
import { Observable, Subscription } from 'rxjs';
import { PromoteformComponent } from '../promoteform/promoteform.component';
import { DataCols } from 'src/app/components/common/oredata-table/oredata-table.component';
import { DialogService } from 'primeng/dynamicdialog';
import {  ActivatedRoute, Router } from '@angular/router';
import { PromoteFormStepsService } from 'src/app/services/promote-form-steps.service';
import { transition, style, animate, trigger } from '@angular/animations';
import { LoaderService } from 'src/app/services/loader.service';
import { EventStepsService } from 'src/app/services/event-steps.service';
import { ConfirmationService, LazyLoadEvent, MessageService } from 'primeng/api';
import { DOCUMENT, DatePipe } from '@angular/common';
import { promoteData } from 'src/app/Interfaces/promoteData';

const leaveTrans = transition(':leave', [
    style({
        opacity: 1
    }),
    animate('1s ease-out', style({
        opacity: 0
    }))
])



const fadeOut = trigger('fadeOut', [
    leaveTrans
]);


@Component({
  selector: 'app-promote',
  templateUrl: './promote.component.html',
  styleUrls: ['./promote.component.scss'],
  animations : [fadeOut]

})
export class PromoteComponent implements OnInit, OnDestroy{
  endSub:boolean=false
  parentId : string="0"
  data : promoteData[]=[];
  totalRecord:number=0;
  col : any[]
  Action: any[]
  id=""
    sub: Subscription;
  constructor(@Inject(DOCUMENT) public document: Document,public EventSrv : EventStepsService,  public messageService : MessageService,private datePipe: DatePipe,
       public conSrv: ConfirmationService,public loader : LoaderService,private pomoteService:PromoteService,private promoteformsrv : PromoteFormStepsService,private router : Router ,private activerout:ActivatedRoute ) { }
    ngOnDestroy(): void {
      this.sub.unsubscribe()
    }

    getEvent(e:LazyLoadEvent)
    {
      var l= e.first/5;
      this.pomoteService.getAllPromo(this.parentId,l)

    }
  ngOnInit(): void {

    this.sub= this.EventSrv.state$.subscribe(x => {

      this.parentId=x.id;





      if(!this.EventSrv.TimingBool){
        if(this.EventSrv.isRecurring){
          this.router.navigate(['addDate'],
          { queryParams: { returnUrl: this.router.routerState.snapshot.url.replace('Promo-codes' , 'calendar') } });
        }
        else{

          this.router.navigate(['addDate'],
          { queryParams: { returnUrl: this.router.routerState.snapshot.url.replace('Promo-codes' , 'timing') } });

        }

      }
      this.loader.showLoader1();
      //this.parentId = this.activerout.parent.parent.snapshot.paramMap.get('id');
     
       this.pomoteService.getAllPromo(this.parentId)
       this.pomoteService.$getSubject().subscribe(x =>{
          this.loader.hideLoader1();
           this.data = x.promo;
           this.totalRecord=x.totalCount;
           this.endSub=true;

       });


     })




    //  this.Action = [{name: 'Edit', icon: 'pi pi-pencil', class: 'p-button-rounded p-button-success mr-2',parent:this.id , comonp: () => PromoteformComponent ,bbbb:(e)=>{ this.edit(e)}},
    //  {name: 'delete', icon: 'pi pi-trash', class: 'p-button-rounded p-button-warning' ,parent:this.id, comonp: () => PromoteformComponent}];
    //  this.col = [{header: 'Name', field: 'name', filter: true, sort: true , cssclass: '', render: (x) => `<h6>${x.name}</h6>` },
    //  {header: 'Count', field: 'count', filter: true, sort: true , cssclass: '', render: (x) => `<h6 >${x.count}/${x.limit}</h6>`},
    //  {header: '', field: 'id', filter: false, sort: false , cssclass: '', render: (x) => `${false}`}];
  }
  show(){
    this.promoteformsrv.isEditing = false;
    this.promoteformsrv.promoteCodeForm.controls['id'].setValue("0")
    this.router.navigate(['promo-form'] ,{relativeTo: this.activerout.parent});
  }
  edit(y){
    this.promoteformsrv.isEditing = true;
    this.pomoteService.getOnePromote(y.id).subscribe(x=>{

        this.promoteformsrv.Initializers(x.id ,x.name , x.isPercent , this.parentId ,x.quantity , x.discountAmount ,
             this.getDateOntomeZone(x.startDate) ,this.getDateOntomeZone(x.endDate) , this.promoteformsrv.setLimit(x.limit)
              , x.hidden ,x.tickets,x.addons,x.count );


    })

    this.router.navigate( ['promo-form'],{relativeTo: this.activerout.parent});
  }

  getDateOntomeZone(d: Date | number) {
    var eventOffset = this.EventSrv.offSet * 60 ;
    var MyDate = new Date(d).setMinutes(new Date(d).getMinutes()  + (eventOffset));
    return new Date(MyDate);
  }

  remove(event, code){
    this.conSrv.confirm({

        target: event.target,
        message: 'Are you sure that you want to proceed?',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
            this.pomoteService.deletPromo(code.id).subscribe(x=>{
              this.pomoteService.getAllPromo(this.parentId)
                this.messageService.add({ severity: 'info', summary: 'Confirmed', detail: 'You have accepted' });
            })
            // this.messageService.add({ severity: 'info', summary: 'Confirmed', detail: 'You have accepted' });
        },
        reject: () => {
            this.messageService.add({ severity: 'error', summary: 'Rejected', detail: 'You have rejected' });
        }
    });
  }


}
