import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MessageService } from 'primeng/api';
import { environment as env } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class AddressService {

    constructor(public httpClient: HttpClient,private messageService:MessageService) {
    }
    url: string = env.Api
    PostAddress(x){
          var url2= this.url +'d/e/c/step2';
       return this.httpClient.post (url2,x,
            {
            headers:{   'Content-Type':  'application/json',
            'Accept': 'application/json'
           // Authorization: 'my-auth-token'
            }})

    }
    GetAddress(id){
        var url2= this.url +'d/ad/g/i/'+id;
      return this.httpClient.get<{address:string, city: string, country:string, lat:number, lng: number}> (url2)

  }
}
