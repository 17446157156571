import { transition, style, animate, trigger } from '@angular/animations';
import { DatePipe } from '@angular/common';
import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Data, Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { Calendar } from 'primeng/calendar';
import { Subscription } from 'rxjs';
import { EventDateTimeDto } from 'src/app/Interfaces/EventData';
import { EventStepsService } from 'src/app/services/event-steps.service';
import { EventsService } from 'src/app/services/events.service';
import { LoaderService } from 'src/app/services/loader.service';

const leaveTrans = transition(':leave', [
    style({
        opacity: 1
    }),
    animate('1s ease-out', style({
        opacity: 0
    }))
])



const fadeOut = trigger('fadeOut', [
    leaveTrans
]);


@Component({
    selector: 'app-event-date-time',
    templateUrl: './event-date-time.component.html',
    styleUrls: ['./event-date-time.component.scss'],
    animations : [fadeOut]
})
export class EventDateTimeComponent implements OnInit,OnDestroy {
    THRESHOLD = 750;
    submitted: boolean;
    sub: Subscription[]=[];
    maxEventDate: Date;
    @HostListener('window:resize', ['$event'])
    sizeChange(event) {
      this.screenSize= window.innerWidth < this.THRESHOLD;
    }

    eventDateForm!: FormGroup;
    id: string;
    minDateValue!: Date;
    maxDateValue!: Date;
    isSubmitting =false;

    screenSize= window.innerWidth < this.THRESHOLD;
    displayOption = [{ name: "Only Display Start Date", code: "a" },
    { name: "Only Display End Date", code: "b" },
    { name: " Display Both", code: "c" }, { name: " Display None", code: "z" }]
    constructor(public loader : LoaderService,
        private fb: FormBuilder,
         public activatedRoute: ActivatedRoute,
          private eventSrv: EventsService,
           private router:Router,
           private srv :EventStepsService,
           private messageService: MessageService,private datePipe: DatePipe
           ) {
            window.dispatchEvent(new Event('resize'));
           }
    ngOnDestroy(): void {
       this.sub.forEach(e=>{
        e.unsubscribe();
       })
    }
    ngOnInit(): void {

        this.screenSize= window.innerWidth < this.THRESHOLD;
        this.loader.showLoader1();
        this.minDateValue=new Date(new Date(new Date(Date.now()).setHours(0)).setMinutes(0))
       // this.id = this.activatedRoute.parent.snapshot.paramMap.get('id') // we should get it from the service
       this.inilizeform("0" ,this.minDateValue,null,true, true);
     var statesub=  this.srv.state$.subscribe(x=> {
             
             this.id=x.id
            this.inilizeform(x['id'] ,this.minDateValue,null,true, true);
            if(this.id!="0"){
                var evntDateSub= this.eventSrv.getEventDateTime(x['id']).subscribe(y => {
                  
                    this.loader.hideLoader1();
                    if(y.startDate != null) {
                        this.inilizeform(x['id'],this.getDateOntomeZone(y.startDate) ,this.getDateOntomeZone(y.endDate) , y.displayStartTime, y.displayEndTime);
                    }else{

                        if(x.parentId!=""){
                            this.srv.ParentDate$.subscribe(x=>{
                                this.minDateValue=this.getDateOntomeZone(x.start);
                                this.maxEventDate=this.getDateOntomeZone(x.end);
                            })
                           
                        }else
                        {
                            this.minDateValue=new Date(new Date(new Date(Date.now()).setHours(0)).setMinutes(0))
            
                        }
                       // this.minDateValue=new Date(Date.now())
                        //this.minDateValue =new Date( nowDate.setMinutes(nowDate.getMinutes()+nowDate.getTimezoneOffset()+this.srv.offSet * 60));
                      //  console.log(this.minDateValue)
                    }

                })
                this.sub.push(evntDateSub);
            }
            else{
            this.loader.hideLoader1();
           // this.minDateValue=new Date(Date.now())
            // if(x.parentId!=""){
            //     this.minDateValue=this.getDateOntomeZone(this.srv.ParentStartDate);
            //     this.maxEventDate=this.getDateOntomeZone(this.srv.ParentEndDate);
            // }else
            // {
            //     this.minDateValue=new Date(Date.now())

            // }
            }
        })
        this.sub.push(statesub);


    }
    getDateOntomeZone(d: Date | number) {
     //   console.log("callgetdata")
        var eventOffset = this.srv.offSet * 60 ;
        var MyDate = new Date(d).setMinutes(new Date(d).getMinutes()  + (eventOffset));
        return new Date(MyDate);
      }
    inilizeform(id: string, start: Date, end: Date, DisplayStart: boolean, DisplayEnd: boolean) {
     //   console.log(start)
        this.eventDateForm = this.fb.group({
            id: [id],
            startDate: [start , [Validators.required]],
            endDate: [end , [Validators.required]],
            displayStart: [DisplayStart],
            displayEnd: [DisplayEnd],
        })
    }
    confirmdate(e) {
    //    console.log(e);
       var t=e;
       var lm=new Date(t)
       this.maxDateValue = new Date( lm.setMinutes(lm.getMinutes()+15));//15
       this.eventDateForm.controls['endDate'].setValue( this.datePipe.transform(this.maxDateValue, 'MM/dd/yyyy hh:mm a'));
    //    console.log(this.eventDateForm.value)
     
       
    }
    onvisiblityChange(e) {
        switch (e.value) {
            case "a": this.eventDateForm.controls['displayStart'].setValue(true); this.eventDateForm.controls['displayEnd'].setValue(false); break;
            case "b": this.eventDateForm.controls['displayStart'].setValue(false); this.eventDateForm.controls['displayEnd'].setValue(true); break;
            case "c": this.eventDateForm.controls['displayStart'].setValue(true); this.eventDateForm.controls['displayEnd'].setValue(true); break;
            case "z": this.eventDateForm.controls['displayStart'].setValue(false); this.eventDateForm.controls['displayEnd'].setValue(false); break;
        }
    }
    submit(x) {
        if (this.eventDateForm.valid) {
            if (x == 1) {
                this.isSubmitting = true;
                var data = this.eventDateForm.value as EventDateTimeDto;
                var post = this.eventSrv.postEventdate(data).subscribe(x => {
                    if (x) {
                        this.isSubmitting = false;
                        this.srv.TimingBool = true;
                        this.srv.CalculateProgress()
                    }
                    var op = this.eventSrv.GetEventProgressInfo(data.id).subscribe(x => {
                        this.srv.InitState(x);
                        if (x.isColection&&x.id== this.srv.ParentId) {
                            this.srv.setParentDate(x);
                        }
                    });
                    this.sub.push(op)
                    this.messageService.add({ severity: 'success', summary: 'Saved Successfully' });
                })
                this.sub.push(post)
                // if(this.srv.isOnline)
                // this.router.navigate( [{ outlets: { 'eventSteps': ['online-page'] } }],{relativeTo: this.activatedRoute.parent});
                // else{
                //     this.router.navigate( [{ outlets: { 'eventSteps': ['location'] } }],{relativeTo: this.activatedRoute.parent});
                // }
               
            }
            if (x == 2) {
                this.isSubmitting = true;

                
                var data = this.eventDateForm.value as EventDateTimeDto;
                var post = this.eventSrv.postEventdate(data).subscribe(x => {
                    if (x) {
                        this.isSubmitting = false;
                        this.srv.TimingBool = true;
                        this.srv.CalculateProgress()
                    }

                    var tt = this.eventSrv.GetEventProgressInfo(data.id).subscribe(x => {
                        this.srv.InitState(x);
                        if (x.isColection&&x.id== this.srv.ParentId) {
                            this.srv.setParentDate(x);
                        }
                    });
                    this.sub.push(tt)
                    this.messageService.add({ severity: 'success', summary: 'Saved Successfully' });
                   
                  

                },(eror)=>{},()=>{
                    setTimeout(()=>{
                        if (this.srv.isOnline)
                        this.router.navigate(['profile'], { relativeTo: this.activatedRoute.parent });
                    else {
                        this.router.navigate(['location'], { relativeTo: this.activatedRoute.parent });
                    }
                    },500)
                 
                });
                this.sub.push(post)

            }
        }
        else {
            this.submitted = true;
        }


    }
    aaaaa(e){


    }
    getchow(e,b:Calendar){
        b.alignOverlay();
        b.appendOverlay();

    }

}
