import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class EditAddGuard implements CanActivate {

  constructor(private AuthService: AuthService,
    private router: Router) {

    
  }
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      let add: number = route.data["add"];
      let edit: number = route.data["edit"];
      let id : string = route.params?.id

      const guidRegExp = /^[0-9A-Fa-f]{8}-[0-9A-Fa-f]{4}-[1-5][0-9A-Fa-f]{3}-[89abAB][0-9A-Fa-f]{3}-[0-9A-Fa-f]{12}$/
      
     

      let isAuth = this.AuthService.securityObject.isAuthenticated;
      let canAdd = this.AuthService.securityObject.checkClaim(add)
      let canEdit = this.AuthService.securityObject.checkClaim(edit)
    //  console.log(route);
      
      if(isAuth){
     
          if(canAdd && route.params?.id === "0"){
               return true;
          }
          if(canEdit && guidRegExp.test(id)){
            return true;
          }
          this.router.navigate(['notallowed']);
          return false;

      } 
     

    
  }
  
}
