import { Component } from '@angular/core';

@Component({
  selector: 'app-languges',
  templateUrl: './languges.component.html',
  styleUrls: ['./languges.component.scss']
})
export class LangugesComponent {

}
