<form [formGroup]="srv.myform" (submit)="submit()" autocomplete="off">
    <div class="row mb-2">
        <span class="text-start">{{'TcktFrmStngsTitle001'|translate}}</span>
        <div class="p-input-filled  col-md-6 col-12 mb-2">
            <span [classList]="'p-float-label '">
                <p-inputNumber inputId="inputnumber3" pTooltip="{{'TcktFrmStngsTitle001Inpt001Pop'|translate}}"
                    showDelay="1000" tooltipPosition="bottom" [styleClass]="'w-100'" class="form-control m-0 p-0"
                    [inputStyleClass]="'form-control'" [inputStyle]="" formControlName="minQuantity"
                    [ngClass]="{'ng-invalid ng-dirty':srv.myform.get('minQuantity')?.touched && srv.myform.get('minQuantity')?.invalid ||(submitted&& srv.myform.get('minQuantity').invalid)}"
                    #ticketMinQuantity></p-inputNumber>
                <label for="inputnumber3">{{'TcktFrmStngsTitle001Inpt001'|translate}}</label>
            </span>
            <small class="p-error" *ngIf="(srv.myform.get('minQuantity')?.touched &&
        srv.myform.get('minQuantity')?.invalid)||(submitted&& srv.myform.get('minQuantity').invalid)
            ">{{'TcktFrmStngsTitle001Inpt001ErrMsg'|translate}}</small>

        </div>

        <div class="p-input-filled  col-md-6 col-12 mb-2">
            <span class="p-float-label">
                <p-inputNumber pTooltip="{{'TcktFrmStngsTitle001Inpt002Pop'|translate}}" showDelay="1000"
                    tooltipPosition="bottom" inputId="inputnumber4" [styleClass]="'w-100'" class="form-control m-0 p-0"
                    [inputStyleClass]="'form-control'" formControlName="maxQuantity"
                    [ngClass]="{'ng-invalid ng-dirty':srv.myform.get('maxQuantity')?.touched && srv.myform.get('maxQuantity')?.invalid ||(submitted&& srv.myform.get('maxQuantity').invalid)}"
                    #ticketMaxQuantity></p-inputNumber>
                <label for="inputnumber4">{{'TcktFrmStngsTitle001Inpt002'|translate}}</label>
            </span>
            <small class="p-error" *ngIf="(srv.myform.get('maxQuantity')?.touched &&
        srv.myform.get('maxQuantity')?.hasError('required')  )||(submitted&& srv.myform.get('maxQuantity').invalid)
            ">{{'TcktFrmStngsTitle001Inpt002ErrMsg001'|translate}}</small>
            <small class="p-error" *ngIf="(srv.myform.get('maxQuantity')?.hasError('isValid'))
            ">{{'TcktFrmStngsTitle001Inpt002ErrMsg002'|translate}}</small>
            <small class="p-error" *ngIf="(srv.myform.get('maxQuantity')?.hasError('isMatched'))
            ">{{'TcktFrmStngsTitle001Inpt002ErrMsg003'|translate}}</small>

        </div>
    </div>
    <div class="row mb-2">
        <span class="text-start">{{'TcktFrmStngsTitle002'|translate}}</span>
        <div class="p-input-filled  col-md-6 col-12 mb-2">
            <span class="p-float-label">
                <p-inputNumber pTooltip="{{'TcktFrmStngsTitle002Inpt001Pop'|translate}} " showDelay="1000"
                    tooltipPosition="bottom" inputId="inputnumber1" [styleClass]="'w-100'" class="form-control m-0 p-0"
                    [inputStyleClass]="'form-control'" formControlName="minNumber"
                    [ngClass]="{'ng-invalid ng-dirty':srv.myform.get('minNumber')?.touched && srv.myform.get('minNumber')?.invalid||(submitted&& srv.myform.get('minNumber').invalid)}"
                    #minNumber></p-inputNumber>
                <label for="inputnumber1">{{'TcktFrmStngsTitle002Inpt001'|translate}}</label>
            </span>
            <small class="p-error" *ngIf="(srv.myform.get('minNumber')?.touched &&
            srv.myform.get('minNumber')?.invalid)||(submitted&& srv.myform.get('minNumber').invalid)
                ">{{'TcktFrmStngsTitle002Inpt001ErrMsg'|translate}}</small>

        </div>
        <div class="p-input-filled  col-md-6 col-12 mb-2">
            <span class="p-float-label">
                <p-inputNumber pTooltip="{{'TcktFrmStngsTitle002Inpt002Pop'|translate}}" showDelay="1000"
                    tooltipPosition="bottom" inputId="inputnumber2" [styleClass]="'w-100'" class="form-control m-0 p-0"
                    [inputStyleClass]="'form-control'" formControlName="maxNumber"
                    [ngClass]="{'ng-invalid ng-dirty':srv.myform.get('maxNumber')?.touched && srv.myform.get('maxNumber')?.invalid ||(submitted&& srv.myform.get('maxNumber').invalid)}"
                    #maxNumber></p-inputNumber>
                <label for="inputnumber2">{{'TcktFrmStngsTitle002Inpt002'|translate}}</label>
            </span>
            <small class="p-error" *ngIf="(srv.myform.get('maxNumber')?.touched &&
            srv.myform.get('maxNumber')?.invalid)||(submitted&& srv.myform.get('maxNumber').invalid)
                ">{{'TcktFrmStngsTitle002Inpt002ErrMsg'|translate}}</small>

        </div>
    </div>
    <div class=" row mb-2">
        <div class="p-input-filled  col-md-6 col-12 mb-2">
            <label class="b-5 " for="RecTicketConf">{{'TcktFrmStngsTitle003'|translate}}</label>
            <p-dropdown pTooltip="{{'TcktFrmStngsTitle003Pop'|translate}}" showDelay="1000" tooltipPosition="bottom"
                inputId="DelveryM" [styleClass]="'w-100'" [autoDisplayFirst]="false" [options]="visibilty"
                optionLabel="name" optionValue="code" formControlName="hidden"
                [ngClass]="{'ng-invalid ng-dirty':srv.myform.get('hidden')?.touched && srv.myform.get('hidden')?.invalid||(submitted&& srv.myform.get('hidden').invalid) }"
                #hidden></p-dropdown>
            <small class="p-error" *ngIf="(srv.myform.get('hidden')?.touched &&
                srv.myform.get('hidden')?.invalid)||(submitted&& srv.myform.get('hidden').invalid)
                    ">Please add Visibilty</small>

        </div>
        <div class="p-input-filled  col-md-6 col-12 mb-2">
            <label class=" " for="RecTicketConf">{{'TcktFrmStngsTitle004'|translate}}</label>
            <div class="row justify-content-between mt-md-2">
                <div class="col-6">
                    <p-inputSwitch inputId="Etic" ariaLabelledBy="lab1"
                        pTooltip="{{'TcktFrmStngsTitle004Swtch001Pop'|translate}}" showDelay="1000"
                        tooltipPosition="bottom" formControlName="eticket" class="me-2"></p-inputSwitch>
                    <label class="form-check-label  " id="lab1"
                        for="Etic">{{'TcktFrmStngsTitle004Swtch001'|translate}}</label>
                </div>
                <div class="col-6 ">
                    <p-inputSwitch inputId="wiltic" pTooltip="{{'TcktFrmStngsTitle004Swtch002Pop'|translate}} "
                        showDelay="1000" tooltipPosition="bottom" formControlName="willCall" ariaLabelledBy="lab2"
                        class="me-2"></p-inputSwitch>
                    <label class=" form-check-label " id="lab2"
                        for="wiltic">{{'TcktFrmStngsTitle004Swtch002'|translate}}</label>
                </div>
            </div>
            <small class="p-error" *ngIf="(submitted&& srv.myform.invalid)
                ">{{'TcktFrmStngsTitle004ErrMsg'|translate}}</small>

        </div>
    </div>

    <div class="col-12 d-flex justify-content-center">
        <div class="banner-btn rounded-pill">
            <button pTooltip="Go back to previous step" type="button" showDelay="1000" tooltipPosition="right" pButton
                pRipple class="rounded-pill cancel-btn" (click)="previous()"><i class="pi mt-1"
                    [class.pi-angle-right]="document.documentElement.dir === 'rtl'"
                    [class.pi-angle-left]="document.documentElement.dir === 'ltr'"></i> {{'PrviosBtn'|translate}}
                <span></span></button>
            <button pButton pRipple pTooltip="Cancel and dismiss changes" type="button" showDelay="1000"
                tooltipPosition="bottom" class="rounded-pill save-btn text-center ms-1"
                (click)="cancel()">{{'CanclBtn'|translate}} <span></span></button>
            <button pButton pRipple type="Save and finish " showDelay="1000" tooltipPosition="bottom"
                class="rounded-pill save-btn text-center ms-1 mt-md-0 mt-1">{{'FnshBtn'|translate}}
                <span></span></button>
        </div>
    </div>

</form>