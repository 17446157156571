import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, ReplaySubject, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { CalenderGetDto, CalenderGetGetDto } from '../Interfaces/calenderDto';
import { EventDateTimeDto } from '../Interfaces/EventData';
import { EventsService } from './events.service';
import { EventStepsService } from './event-steps.service';
import { ActivatedRoute, Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class CalenderService {
  private subject: Subject<CalenderGetGetDto> = new ReplaySubject<CalenderGetGetDto>(1);

  get $getSubject(): Observable<any> {
    return this.subject.asObservable();
  }
  url :string = environment.Api;
  constructor( public http:HttpClient,
    private eventSrv: EventsService,
    private srv: EventStepsService,
    private router:Router,
    private activatedRoute:ActivatedRoute
    ) { }
  postCalender(data:any){
    const url = this.url+'d/e/c/calendar';
    return this.http.post(url,data,
        {
        headers:{   'Content-Type':  'application/json',
        'Accept': 'application/json'
       // Authorization: 'my-auth-token'
        }})
        .subscribe(x=>{this.getClenderEvent(data.id);
            this.eventSrv.GetEventProgressInfo(data.id).subscribe(x => {
                this.srv.InitState(x);
              //   if (x.isColection) {
              //     this.srv.setParentDate(x);
              // }
                this.router.navigate( ['/events/manage-event',data.id,'calendar']);
               });
        });
  }
  getEventInstance(id:string) :Observable<EventDateTimeDto> {
  return this.http.get<EventDateTimeDto>(this.url + 'd/e/g/date/' + id);
  }

  getClenderEvent(id:string){
    this.http.get<CalenderGetGetDto>(this.url+'d/e/g/calendar/'+id).subscribe(x=> {this.subject.next(x);});
  }
}
