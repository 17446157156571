<div class="container">
    <div class="card custom-table-border">
        <div class="card-header border-0 text-center bg-primary rounded-top-23 ">
            <h1 class="fw-bold text-white mt-2">{{'ShdlrTblTitl'|translate}} </h1>
            </div>
        <div class="card-body">
            <div class="card-body p-0">
                <div class="row d-flex justify-content-center ">
                    <div class="col-sm-12 col-md-12 mb-3">
                        <router-outlet ></router-outlet>
                    </div>
                    <!-- <div class="p-input-filled col-sm-12 col-md-8  mb-3 border-bottom border-primary pb-4">
                        <span class="p-float-label">
                            <p-dropdown inputId="curId" id="curId"
                                [ngClass]="{'ng-invalid ng-dirty':form1.get('curId')?.touched && form1.get('curId')?.invalid}"
                                [styleClass]="'w-100'" formControlName="curId" [autoDisplayFirst]="false"
                                [options]="curData" optionLabel="name" optionValue="Id"></p-dropdown>
                            <label class="form-label font-size-14 " for="curId">Currency</label>
                        </span>
                        <p-message *ngIf="!typeId.value && form1.get('curId')?.touched" severity="error"
                            text="Please add a currency*"></p-message>
                    </div> -->
                </div>
            </div>
        </div>
    </div>
</div>
<!-- <router-outlet name="eventSteps"></router-outlet> -->
