<p-toast></p-toast>
<div class="row align-items-center p-0">
    <div class="card  border-0 col-xl-4 col-lg-5 col-12 m-0 p-0 login-tab-mob">
        <a class="navbar-brand d-lg-flex d-none" routerLink="/"><img 
            src="{{LogoUrl}}"
             class="logo-size-larg" alt="Antares"></a>
             <!-- src="../../../../assets/img/Logo/Antares - Logo H (Colored).png" -->
        <!-- <a class="navbar-brand d-lg-none d-sm-flex d-none" routerLink="/"><img src="../../../../assets/img/Logo/Antares - Logo H (White).png" class="logo-size-larg" alt="Antares"></a> -->

        <div class="text-center card-title">
            <h1 class="text-primary">{{'Login'|translate}}</h1>
        </div>
        <div class="card-body">

<form  [formGroup]="loginForm" (ngSubmit)="login()" autocomplete="off">

    <div class="row justify-content-center">
        <div class="p-input-filled   col-12">
            <span class="p-float-label">
                <input type="email" formControlName="Username" class="form-control" pInputText id="email" [ngClass]="{
                    'ng-invalid ng-dirty':
                    loginForm.get('email')?.touched &&
                    loginForm.get('email')?.invalid
                }" />
                <label htmlFor="email">{{'Email'|translate}}</label>
            </span>
        </div>

        <div class="p-input-filled col-12">
            <span class="p-float-label">
                <p-password [feedback]="false" minlength="8" formControlName="password" class="w-full p-0"  id="password"   [ngClass]="{
                    'ng-invalid ng-dirty':
                    loginForm.get('password')?.touched &&
                    loginForm.get('password')?.invalid
                }"  [style]="{'width':'100%'}"
                [inputStyle]="{'width':'100%','height':'50px'}"
                 [toggleMask]="true">

                <ng-template pTemplate="footer">
                    <p-divider></p-divider>
                    <p class="mt-2">{{'Password must contain :'|translate}} </p>
                    <ul class="pl-2 ml-2 m-3" style="line-height: 1.5">
                        <li>{{'At least one lowercase'|translate}}</li>
                        <li>{{'At least one uppercase'|translate}}</li>
                        <li>{{'At least one speical character'|translate}}</li>
                        <li>{{'At least one numeric'|translate}}</li>
                        <li>{{'Minimum 8 characters'|translate}}</li>
                    </ul>
                </ng-template></p-password>
                <label htmlFor="password">{{'Password'|translate}}</label>
            </span>
                        <small class="p-error" *ngIf="loginForm.get('password')?.touched &&
            loginForm.get('password')?.invalid ">{{'Please add valid value.'|translate}}</small>
                    </div>

        <div class="col-6 my-3">
            <p-inputSwitch formControlName="rememberMe" inputId="rememberMe"></p-inputSwitch>
            <label class="form-label font-size-14 ms-2" for="rememberMe">{{'Remember me'|translate}}</label>
        </div>
        <div class="col-6 my-3 text-end">
            <a href="javascript:;" routerLink="/resetPassword" class="text-primary">{{'Forgotpassword'|translate}}</a>
        </div>


    </div>
    <div class="d-flex justify-content-center">
        <div class="banner-btn rounded-pill mx-2 text-center">
            <button type="submit" class="rounded-pill login-btn" routerLink="/">{{'CanclBtn'|translate}}<span></span></button>
        </div>

        <div class="banner-btn rounded-pill mx-2 text-center">
            <button [disabled]="loginForm.invalid||Auth.isSubmitting" type="submit" pTooltip="Register" showDelay="1000" tooltipPosition="right" class="rounded-pill save-btn {{Auth.isSubmitting? 'bg-disabled' : ''}}">
                <i  *ngIf="Auth.isSubmitting" class="fas fa-spinner fa-spin"></i>
                {{'Login'|translate}}<span></span>
            </button>
        </div>
    </div>

    <div class="text-center my-3 col-12">
        <span>{{'registerques'|translate}} <a class="text-primary fw-bold" routerLink="/register">{{'Register'|translate}}</a></span>
    </div>

    <div class="text-center col-12 mb-3 sign-border">
        <span class="bg-white px-5 ">{{'Or sign in with'|translate}}</span>
    </div>
    <div class="text-center col-12">
        <a class=" me-3  social-btn rounded-circle" href="javascript:;"><i class="fa-brands fa-google"> </i>  <span></span></a>
        <a class="   social-btn rounded-circle" href="javascript:;"><i class="fa-brands fa-facebook"></i> <span></span></a>

    </div>

</form>
        </div>
    </div>
    <div class="col-xl-8 col-lg-7 d-lg-flex d-none m-0 p-0 img-fluid authentication-bg">

    </div>
</div>


