
    <div class="events-area py-3">
        <div #myDiv class="container">
            <div class="card custom-table-border ">
                <div class="card-header border-0 text-center bg-light rounded-top-23 ">
                    <div class="row">
                        <div class=" col-12 d-flex align-content-center justify-content-around " >
                            <h3  class="text-primary">{{'OrderRepCo001'|translate}} <span>{{item['Order #']}}</span></h3>
                            <h3  class="text-primary"><i class="bx bx-calendar text-primary"></i>
                                <span>{{getDateOntomeZoneformat(item['Order Date'],'full')}}</span></h3>
                        </div>
                    </div>
                </div>

                <div class="row p-3 ">
                    <div class="col-lg-4 col-12 ">
                        <h6 *ngIf="checkpropName('Add-Ons')">{{'AddOns:'|translate}} <span>{{item['Add-Ons']}}</span></h6>
                        <h6 *ngIf="checkpropName('Quantity')">{{'Orderrepco004'|translate}} <span>{{item['Quantity']}}</span></h6>
                        <h6 *ngIf="checkpropName('First name')">{{'FirstName:'|translate}}<span>{{item['First name']}}</span></h6>
                        <h6 *ngIf="checkpropName('Last name')">{{'LastName:'|translate}} <span>{{item['Last name']}}</span></h6>
                        <h6 *ngIf="checkpropName('Email')">{{'Email:'|translate}} <span>{{item['Email']}}</span></h6>
                    </div>
                    <div class="col-lg-4 col-12 ">
                        <h6 *ngIf="checkpropName('Total')"> {{'Orderrepco005'|translate}}  <span>{{item['Total']}}</span></h6>
                        <h6 *ngIf="checkpropName('Fees')">{{'Orderrepco006'|translate}} <span>{{item['Fees']}}</span></h6>
                        <h6 *ngIf="checkpropName('Antares fees')">{{'Orderrepco007'|translate}} <span>{{item['Antares fees']}}</span></h6>
                        <h6 *ngIf="checkpropName('Antares Payment Processing Fees')">{{'Orderrepco008'|translate}} <span>{{item['Antares Payment Processing Fees']}}</span></h6>
                        <h6 *ngIf="checkpropName('Shipping Fees')">{{'Orderrepco009'|translate}}<span>{{item['Shipping Fees']}}</span></h6>
                    </div>
                    <div class="col-lg-4  col-12">
                        <h6 *ngIf="checkpropName('Order Type')">{{'Orderrepco010'|translate}}<span>{{item['Order Type']}}</span></h6>
                        <h6 *ngIf="checkpropName('Country')">{{'Orderrepco011'|translate}} <span>{{item['Country']}}</span></h6>
                        <h6 *ngIf="checkpropName('City')">{{'Orderrepco012'|translate}} <span>{{item['City']}}</span></h6>
                    </div>
                    <div class="col-12">
                        <h6 *ngIf="checkpropName('Shipping Address')">{{'Orderrepco013'|translate}} <span>{{item['Shipping Address']}}</span></h6>
                    </div>
                    
                </div>

            </div>
        </div>
    </div>



