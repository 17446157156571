import { Component, OnDestroy, OnInit,Inject } from '@angular/core';
import { DialogService } from 'primeng/dynamicdialog';
import { TicketsTablesDto } from 'src/app/Interfaces/TicketsDtoS';
import { TicketsService } from 'src/app/services/tickets.service';


import { TicketsFormComponent } from './tickets-form/tickets-form.component';
import {DataCols} from '../../../../common/oredata-table/oredata-table.component';
import { ActivatedRoute, Router, RouterStateSnapshot } from '@angular/router';
import { TicketFormStepsService } from 'src/app/services/ticket-form-steps.service';
import { EventStepsService } from 'src/app/services/event-steps.service';
import { ConfirmationService, LazyLoadEvent, MessageService } from 'primeng/api';
import { LoaderService } from 'src/app/services/loader.service';
import { transition, style, animate, trigger } from '@angular/animations';
import { Subscription } from 'rxjs';
import { DOCUMENT, DatePipe } from '@angular/common';

import * as m from 'moment';
import 'moment/locale/pt-br';
import { TranslateService } from '@ngx-translate/core';

const leaveTrans = transition(':leave', [
    style({
        opacity: 1
    }),
    animate('1s ease-out', style({
        opacity: 0
    }))
])



const fadeOut = trigger('fadeOut', [
    leaveTrans
]);

@Component({
  selector: 'app-tickets',
  templateUrl: './tickets.component.html',
  styleUrls: ['./tickets.component.scss'],
  animations : [fadeOut]

})
export class TicketsComponent  implements OnInit, OnDestroy{
  endSub:boolean=false;
  data: any[] = [];
  col: DataCols[];
  parentId : string;
  Action: any[];
  cuurency:string="$"
    sub: Subscription;
  totalRecord: number;
  constructor(
    public loader : LoaderService,
    public ticServ: TicketsService,
    private modalServ: DialogService,
    private  activerout:ActivatedRoute ,
     private router: Router,
     private srv:TicketFormStepsService,
     public EventSrv:EventStepsService,
    public conSrv: ConfirmationService,
    public messageService : MessageService,private datePipe: DatePipe,
    @Inject(DOCUMENT) public document: Document,private translate:TranslateService
     ){
      this.translate.onLangChange.subscribe(() => {
       
        m.locale(this.translate.currentLang)
      });
     }
     ngOnDestroy(): void {
        this.sub.unsubscribe()
      }
  ngOnInit(): void {
    this.loader.showLoader1();
    this.sub = this.EventSrv.state$.subscribe(x=>{
      if(!this.EventSrv.TimingBool){
        if(this.EventSrv.isRecurring){
 
          this.router.navigate(['addDate'],
          { queryParams: { returnUrl: this.router.routerState.snapshot.url.replace('tickets' , 'calendar') } });
        }
        else{

          this.router.navigate(['addDate'],
          { queryParams: { returnUrl: this.router.routerState.snapshot.url.replace('tickets' , 'timing') } });

        }

      }
      this.parentId = x.id;//this.activerout.parent.parent.snapshot.paramMap.get('id');
      this.ticServ.getAllTickets(this.parentId);
        this.cuurency=x.currenySymbole
      this.ticServ.$getSubject.subscribe(res => {
        this.loader.hideLoader1();
        this.data = res.tickets;
        this.totalRecord=res.totalCount;
        this.endSub = true
        if (res?.length > 0) {
          this.EventSrv.TicketsBool = true;
          this.EventSrv.CalculateProgress()
        }
      }

      );
    })

    // this.Action = [{name: 'Edit', icon: 'pi pi-pencil', class: 'p-button-rounded p-button-success mr-2', parent: this.parentId, comonp: () => TicketsFormComponent,bbbb:(e)=>{ this.edit(e)}},
    // {name: 'delet', icon: 'pi pi-trash', class: 'p-button-rounded p-button-warning', parent: this.parentId, comonp: () => TicketsFormComponent }];
    // this.col = [{header: 'Tickets', field: 'name', filter: true, sort: true , cssclass: '', render: (x) => `<h6 >${x.name}</h6 >`},
    // {header: 'Price', field: 'price', filter: true, sort: true , cssclass: '', render: (x) => `<h6 >${x.price == '0' ? 'free' : x.price}</h6 >`},
    // {header: 'Quantity', field: 'count', filter: true, sort: true , cssclass: '', render: (x) => `<h6 >${x.sold}/${x.count}</h6 >`}
    // , {header: 'Start date', field: 'start', filter: true, sort: true , cssclass: '', render: (x) => `<h6 >${new Date(x.start).toLocaleString()}</h6 >`},
    // {header: 'Type', field: 'type', filter: true, sort: true , cssclass: '', render: (x) => `<span class="badge rounded-pill text-bg-dark">${x.type}</span>`},
    // {header: '', field: 'groupId', filter: false, sort: false , cssclass: '', render: (x) => `${x.isRused}`}];
  }

  getDate(x:Date){
    var t= this.getDateOntomeZone(x);

    return t.toLocaleString();
  }
  show(){
    // this.modalServ.open(TicketsFormComponent, {
    //   data: {
    //     parent: this.parentId,
    //     id: '0', // 3b36e84f-ee34-40e6-a551-434a793d83e6
    //   },
    //   header: 'Add Tickets',
    //   width:'70%',

    // });
    this.srv.myform.controls['id'].setValue("0")
    this.router.navigate( ['ticket-form'] ,{relativeTo: this.activerout.parent});

  }
  edit(y){

    this.ticServ.getSingleTickets(y.groupId).subscribe(x=>{
       this.srv.clear()

       this.srv.inilizeForm(x.id,x.events,x.name,x.description,x.quantity,x.price,x.hidden,x.minQuantity,x.maxQuantity
        ,x.minNumber,x.maxNumber,x.ticketType,this.getDateOntomeZone(x.startDate),this.getDateOntomeZone(x.endDate),x.priodNumber,x.priodType,
        x.startingPoint, x.eticket,x.willCall,this.srv.myform.controls['eventType'].value,x.sold)
    })

    this.router.navigate( ['ticket-form'],{relativeTo: this.activerout.parent});

  }

  getTiketsLazy(e:LazyLoadEvent){
   // console.log(e)
    var l= e.first/5
    this.ticServ.getAllTickets(this.parentId,l);
  }

  remove(event, ticket){
    this.conSrv.confirm({

        target: event.target,
        message: 'Are you sure that you want to proceed?',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
            this.ticServ.removeEntry(ticket.groupId).subscribe(x=>{
                this.ticServ.getAllTickets(this.parentId);
                this.messageService.add({ severity: 'info', summary: 'Confirmed', detail: 'You have accepted' });
            })
            // this.messageService.add({ severity: 'info', summary: 'Confirmed', detail: 'You have accepted' });
        },
        reject: () => {
            this.messageService.add({ severity: 'error', summary: 'Rejected', detail: 'You have rejected' });
        }
    });
  }

  getDateOntomeZone(d: Date | number) {
    var eventOffset = this.EventSrv.offSet * 60 ;
    var MyDate = new Date(d).setMinutes(new Date(d).getMinutes()  + (eventOffset));
    return new Date(MyDate);
  }
  getDateOntomeZoneformat(d,x){
    m.locale();
    var eventOffset = this.EventSrv.offSet * 60 ;
    var MyDate = new Date(d).setMinutes(new Date(d).getMinutes()  + (eventOffset));
    var l="";
    switch(x){
      case 'full':if(new Date(MyDate).getFullYear()==new Date(Date.now()).getFullYear()){
        l= m(new Date(MyDate)).format('ddd MMMM D hh:mm a')
        // this.datePipe.transform(new Date(MyDate), 'EEE MMMM d hh:mm a');
        
    }else{
       l=  m(new Date(MyDate)).format( 'ddd MMMM D,y hh:mm a');
       //this.datePipe.transform(new Date(MyDate), 'EEE MMMM d,y hh:mm a');
     
    }
    break;
    case 'month': l=m(new Date(MyDate)).format('MMM');
    //this.datePipe.transform(new Date(MyDate), 'MMM');
    break;
    case 'day':l=m(new Date(MyDate)).format('D')
    //this.datePipe.transform(new Date(MyDate), 'dd');
    break
    }
    return l
    


    
   // return new Date(MyDate).toLocaleString();
}
}
