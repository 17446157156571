<form [formGroup]="form"  autocomplete="off">
    <div class="container">
        <div class="card custom-table-border">
            <!-- <div class="card-header text-center bg-primary rounded-top-23">
                <h1 class="text-white">Event profile</h1>
            </div> -->
            <div class="card-header border-0 text-center bg-primary rounded-top-23 ">
                <h1 class="fw-bold text-white mt-2">{{'EvntProfTitle'|translate}}</h1>
                </div>
            <div class="card-body">
                <div class="card-body p-0">
                    <div *ngIf="loader.show1" @fadeOut class="d-flex justify-content-center align-items-center" style="border-radius: 25px;top : 0;position: absolute;z-index: 1050;background-color:#fff;width: 100%;height: -webkit-fill-available;right: 0;bottom: 0;left: 0;">


                    </div>
                    <div class="row d-flex justify-content-center">
                        <div class="row p-0">
                            <div class="col-12">
                                <h5>{{'EvntProfTitle001'|translate}}</h5>
                            </div>
                            <div class="col-12">
                                <p>
                                   {{'EvntProfTitle001Dsc'|translate}}
                                </p>
                            </div>
                            <div class="col-12">

                                <div class="card">
                                    <div class="col-12 p-0">
                                        <app-my-image-uploader pTooltip="{{'EvntProfImgInpt001Pop'|translate}}"  showDelay="1000"
                                        tooltipPosition="bottom" formControlName="image"
                                                               [aspectRatio]="2 / 1" [quality]="'50'"
                                                               [format]="'jpeg'"></app-my-image-uploader>

                                    </div>
                                    <small class="p-error" *ngIf="(form.get('image')?.touched &&
                                   form.get('image')?.invalid)||(submitted&&form.get('image').invalid)
                                        ">{{'EvntProfImgInpt001ErrMsg'|translate}}</small>
                                    <div class="row text-center py-1">
                                        <div class="col-12 col-md-4 text-danger" style="font-size:14px">
                                            <i class="fa-regular fa-triangle-exclamation"></i>

                                            {{'EvntProfImgInpt001Dsc001'|translate}}
                                            <!-- Recommended image size: <b> 2160 x
                                                1080px</b> -->
                                        </div>
                                        <div class="col-12 col-md-4 text-danger" style="font-size:14px">
                                            <i class="fa-regular fa-triangle-exclamation"></i>
                                            <!-- Maximum file size: <b>10MB</b> -->
                                            {{'vntProfImgInpt001Dsc002'|translate}}
                                        </div>
                                        <div class="col-12 col-md-4 text-danger" style="font-size:14px">
                                            <i class="fa-regular fa-triangle-exclamation"></i>
                                            {{'EvntProfImgInpt001Dsc003'|translate}}
                                            <!-- Supported image files: <b>JPEG or PNG</b> -->
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 p-input-filled">
                                <span class="p-float-label">
                                    <textarea formControlName="summary" pTooltip="{{'EvntProfInpt001Pop'|translate}}"  showDelay="1000"
                                    tooltipPosition="bottom" [autoResize]="true" [cols]="30" [rows]="5"
                                              class="form-control" id="summary-id" pInputTextarea></textarea>
                                    <label for="summary-id">{{'EvntProfInpt001'|translate}}</label>
                                </span>
                            </div>
                            <small class="p-error" *ngIf="(form.get('summary')?.touched &&
                            form.get('summary')?.invalid)||(submitted&&form.get('summary').invalid)
                                 ">{{'EvntProfInpt001ErrMsg'|translate}}</small>
                            <div class="col-12">
                                <h5>{{'EvntProfTitle002'|translate}}</h5>
                            </div>
                            <div class="col-12">
                                <p>
                                  {{'EvntProfTitle002Dsc'|translate}}
                                </p>
                            </div>
                            <div formArrayName="description" class="row d-flex flex-column gap-3">
                                <div *ngFor="
                                        let c of formDescription.controls;
                                        let i = index
                                    " [formGroupName]="i" class="col-12">
                                    <div class="row" *ngIf="c.get('type').value == 'text'">
                                        <div class="col-11">
                                            <p-editor
                                            pTooltip="{{'EvntProfTitle002Inpt002Pop'|translate}} "  showDelay="1000"
                                tooltipPosition="bottom"
                                                [style]="{'min-height': '300px'}"
                                                formControlName="value1"
                                            >

                                            </p-editor>
                                            <small class="p-error" *ngIf="(formDescription.controls[i]?.touched &&
                                            formDescription.controls[i]?.invalid)||(submitted&&formDescription.controls[i].invalid)
                                                 ">{{'EvntProfTitle002Inpt002ErrMsg'|translate}}</small>
                                        </div>

                                        <div
                                            class="col-1 p-0 d-flex flex-column justify-content-center align-items-center">
                                            <button  pTooltip="{{'EvntProfTitle002pstionBtn001Pop'|translate}}"  showDelay="1000"
                                            tooltipPosition="left" pButton pRipple type="button" *ngIf="i>=1" icon="fas fa-arrow-up"
                                                    (click)="move(-1,i)"
                                                    class="p-button-rounded p-button-text"></button>
                                            <button  pTooltip="{{'EvntProfTitle002pstionBtn002Pop'|translate}}"  showDelay="1000"
                                            tooltipPosition="left" pButton pRipple type="button" icon="fas fa-trash"
                                                    (click)="removeItem(i)"
                                                    class="p-button-rounded p-button-text"></button>
                                            <button pTooltip="{{'EvntProfTitle002pstionBtn003Pop'|translate}}"  showDelay="1000"
                                            tooltipPosition="left" pButton pRipple type="button"
                                                    *ngIf="i!=formDescription.controls.length-1 && formDescription.controls.length != 1"
                                                    icon="fas fa-arrow-down" (click)="move(1,i)"
                                                    class="p-button-rounded p-button-text"></button>
                                        </div>
                                    </div>
                                    <div class="row" *ngIf="c.get('type').value == 'image'">
                                        <div class="col-11">
                                            <app-my-image-uploader pTooltip="{{'EvntProfTitle002Inpt003Pop'|translate}}"  showDelay="1000"
                                            tooltipPosition="bottom" formControlName="image"
                                                                   [aspectRatio]="2 / 1" [quality]="'50'"
                                                                   [format]="'jpeg'"
                                                                   formControlName="value1"></app-my-image-uploader>
                                                                   <small class="p-error" *ngIf="(formDescription.controls[i]?.touched &&
                                                                   formDescription.controls[i]?.invalid)||(submitted&&formDescription.controls[i].invalid)
                                                                        ">{{'EvntProfTitle002Inpt003ErrMsg'|translate}}</small>
                                        </div>

                                        <div
                                            class="col-1 p-0 d-flex flex-column justify-content-center align-items-center">
                                            <!-- <button pButton pRipple type="button" icon="fas fa-edit" class="p-button-rounded p-button-text"></button> -->
                                            <!-- <button class="text-primary mb-1" pRipple type="button" icon="fas fa-edit" class="p-button-rounded p-button-text"></button> -->
                                            <!-- <button pButton pRipple type="button" icon="fas fa-save" class="p-button-rounded p-button-text"></button> -->
                                            <button  pTooltip="{{'EvntProfTitle002pstionBtn001Pop'|translate}}"  showDelay="1000"
                                            tooltipPosition="left" pButton pRipple type="button" *ngIf="i>=1" icon="fas fa-arrow-up"
                                                    (click)="move(-1,i)"
                                                    class="p-button-rounded p-button-text"></button>
                                            <button  pTooltip="{{'EvntProfTitle002pstionBtn002Pop'|translate}}"  showDelay="1000"
                                            tooltipPosition="left" pButton pRipple type="button" icon="fas fa-trash"
                                                    (click)="removeItem(i)"
                                                    class="p-button-rounded p-button-text"></button>
                                            <button  pTooltip="{{'EvntProfTitle002pstionBtn003Pop'|translate}}"  showDelay="1000"
                                            tooltipPosition="left" pButton pRipple type="button"
                                                    *ngIf="i!=formDescription.controls.length-1 && formDescription.controls.length != 1"
                                                    icon="fas fa-arrow-down" (click)="move(1,i)"
                                                    class="p-button-rounded p-button-text"></button>
                                        </div>
                                    </div>
                                    <div class="row align-items-center" *ngIf="c.get('type').value == 'video'">
                                        <div class="col-11">
                                            <div class="row ">
                                                <div class="p-input-filled col-sm-12 col-md-12 mb-2">
                                                            <span class="p-float-label">
                                                                <input type="text" pInputText class="form-control"  pTooltip="{{'EvntProfTitle002Inpt001Pop'|translate}}"  showDelay="1000"
                                                                tooltipPosition="bottom"
                                                                       formControlName="value1"/>
                                                                <label class="form-lable font-size-14" for="TicketName">{{'EvntProfTitle002Inpt001'|translate}}</label>
                                                            </span>
                                                </div>
                                                <div class="col-12 mt-2 mb-2" *ngIf="c.get('value1').value ">

                                                    <youtube-player [videoId]="getUrlid(c.get('value1').value)"
                                                    width="100%" height="360"
                                                    suggestedQuality="medium"
                                                    [origin]="'http://localhost:4200'"
                                                    ></youtube-player>
                                                    <!-- <iframe #ifram width="100%" height="360"
                                                            [src]="makeItSafe(c.get('value1').value)"
                                                            title="YouTube video player" frameborder="0"
                                                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                                            allowfullscreen [loading]="'lazy'"  ></iframe> -->
                                                </div>
                                                <!-- <iframe width="560" height="315"
                                                 src="https://www.youtube.com/embed/D4hAVemuQXY" 
                                                 title="YouTube video player" frameborder="0" 
                                                 allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
                                                 allowfullscreen></iframe> -->
                                            </div>
                                            <small class="p-error" *ngIf="(formDescription.controls[i]?.touched &&
                                            formDescription.controls[i]?.invalid)||(submitted&&formDescription.controls[i].invalid)
                                                 ">{{'EvntProfTitle002Inpt001ErrMsg'|translate}}</small>
                                        </div>
                                        <div
                                            class="col-1 p-0 d-flex flex-column justify-content-center align-items-center">
                                            <button  pTooltip="{{'EvntProfTitle002pstionBtn001Pop'|translate}}"  showDelay="1000"
                                            tooltipPosition="left"pButton pRipple type="button" *ngIf="i>=1" icon="fas fa-arrow-up"
                                                    (click)="move(-1,i)"
                                                    class="p-button-rounded p-button-text"></button>
                                            <button   pTooltip="{{'EvntProfTitle002pstionBtn002Pop'|translate}}"  showDelay="1000"
                                            tooltipPosition="left"pButton pRipple type="button" icon="fas fa-trash"
                                                    (click)="removeItem(i)"
                                                    class="p-button-rounded p-button-text"></button>
                                            <button  pTooltip="{{'EvntProfTitle002pstionBtn003Pop'|translate}}"  showDelay="1000"
                                            tooltipPosition="left" pButton pRipple type="button"
                                                    *ngIf="i!=formDescription.controls.length-1 && formDescription.controls.length != 1"
                                                    icon="fas fa-arrow-down" (click)="move(1,i)"
                                                    class="p-button-rounded p-button-text"></button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 d-sm-flex d-inline-block justify-content-sm-between text-center">
                                <button  pTooltip="{{'EvntProfTitle002MainBtn001Pop'|translate}}"  showDelay="1000"
                                tooltipPosition="left" type="button" class="rounded-pill p-button-outlined cancel-btn mt-sm-0 me-sm-0 me-1"
                                        (click)="addComponent('image')">
                                    <i class="pi pi-plus-circle mt-1"></i>{{'EvntProfTitle002MainBtn001'|translate}}
                                    <span></span>
                                </button>
                                <button pTooltip="{{'EvntProfTitle002MainBtn002Pop'|translate}}"  showDelay="1000"
                                tooltipPosition="left" type="button" class="rounded-pill p-button-outlined cancel-btn mt-sm-0 mt-1 me-sm-0 me-1"
                                        (click)="addComponent('text')">
                                    <i class="pi pi-plus-circle mt-1"></i> {{'EvntProfTitle002MainBtn002'|translate}}
                                    <span></span>
                                </button>
                                <button pTooltip="{{'EvntProfTitle002MainBtn003Pop'|translate}}"  showDelay="1000"
                                tooltipPosition="left" type="button" class="rounded-pill p-button-outlined cancel-btn mt-sm-0 mt-1"
                                        (click)="addComponent('video')">
                                    <i class="pi pi-plus-circle mt-1"></i> {{'EvntProfTitle002MainBtn003'|translate}}
                                    <span></span>
                                </button>
                            </div>
                            <div class="col-12">
                                <h6 *ngIf="isRecurring&&!isSreise">{{'serisereccumes'|translate}} </h6>
                                <h6 *ngIf="isRecurring&&isSreise">{{'EvntProfTitle002RecEvntDsc001'|translate}}</h6>
                                <h6 *ngIf="!isRecurring&&isSreise">{{'EvntProfTitle002RecEvntDsc001001'|translate}}</h6>
                           
                            </div>
                            <div class="p-input-filled col-12"  *ngIf="isRecurring||isSreise">
                                <span class="p-float-label">
                        
                                        <p-multiSelect pTooltip="{{'EvntProfTitle002RecEvntSlct001Pop'|translate}} " showDelay="1000" tooltipPosition="top" [options]="eventInstance" [styleClass]="'w-100'" [optionLabel]="'name'" [optionValue]="'id'"
                                            display="chip" formControlName="id" inputId="eventInstance"
                                            [ngClass]="{'ng-invalid ng-dirty':(form.get('id')?.touched && form.get('id')?.invalid)||(submitted&& form.get('id').invalid)}"></p-multiSelect>
                                            <label class="b-5 ms-2" for="eventInstance">{{'EvntProfTitle002RecEvntSlct001'|translate}}</label>
                                            </span>
                                            <small class="p-error" *ngIf="(  form.get('id')?.touched &&
                                            form.get('id')?.invalid)||(submitted&& form.get('id').invalid)
                                                " >{{'EvntProfTitle002RecEvntSlct001ErrMsg'|translate}}</small>
                                    </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row"  *ngIf="!loader.show1">
            <div class="col-12 d-flex justify-content-lg-center justify-content-center">
<!--                <div class="banner-btn rounded-pill mx-2">-->
<!--                    <button class="rounded-pill cancel-btn" pTooltip="Cancel and dismiss changes" showDelay="1000" tooltipPosition="left">-->
<!--                        <i class="pi pi-angle-left mt-1" ></i> Cancel<span></span>-->
<!--                    </button>-->
<!--                </div>-->
                <div class=" d-flex justify-content-center gap-1">
                    <button [disabled]="isSubmitting" (click)="submit(1)" type="submit" class="rounded-pill save-btn {{isSubmitting? 'bg-disabled' : ''}}" pTooltip="Save changes" showDelay="1000" tooltipPosition="right">
                        <i  *ngIf="isSubmitting" class="fas fa-spinner fa-spin"></i>
                        {{'SavBtn'|translate}}  <span></span>
                    </button>
                    <button  *ngIf="!srv.profileBool" [disabled]="isSubmitting" (click)="submit(2)"  type="submit" class="rounded-pill save-btn {{isSubmitting? 'bg-disabled' : ''}}" pTooltip="Save changes" showDelay="1000" tooltipPosition="right">
                        <i  *ngIf="isSubmitting" class="fas fa-spinner fa-spin"></i>
                        {{'Sav&NxtBtn'|translate}}  <span></span>
                    </button>
                </div>
            </div>
        </div>
    </div>
</form>
