import { Component, OnInit, AfterContentInit, OnDestroy } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { QuestionFormComponent } from './question-form/question-form/question-form.component';
import { Question } from '../../../../../Interfaces/question';
import { DragDropModule } from 'primeng/dragdrop';
import { OrderFormService } from './order-form.service';
import { EventStepsService } from 'src/app/services/event-steps.service';
import { TicketsService } from 'src/app/services/tickets.service';
import { MessageService } from 'primeng/api';
import { transition, style, animate, trigger } from '@angular/animations';
import { LoaderService } from 'src/app/services/loader.service';
import { PreviewEventComponent } from './preview-event/preview-event.component';
import { TranslateService } from '@ngx-translate/core';


const leaveTrans = transition(':leave', [
    style({
        opacity: 1
    }),
    animate('1s ease-out', style({
        opacity: 0
    }))
])



const fadeOut = trigger('fadeOut', [
    leaveTrans
]);


export interface OrderFormGetDto {
    id: string;
    eventId: string;
    formType: boolean;
    name: string;
    content: string;
    ticketsIds: string[];
}

@Component({
    selector: 'app-order-form-builder',
    templateUrl: './order-form-builder.component.html',
    styleUrls: ['./order-form-builder.component.scss'],
    animations: [fadeOut]
})
export class OrderFormBuilderComponent implements OnInit, OnDestroy {
    orderFormType: string;
    form: FormGroup;
    ref: DynamicDialogRef;

    submitted = false;
    isSubmitting = false;
    tickets = [

    ];
    defaultInputs = [
        {
            id: -0.1,
            question: "First name",
            type: "short",
            isShown: true,
            isRequired: true,
            answer: "",
            options: [
                {
                    text: "",
                    value1: ""
                },
                {
                    text: "",
                    value1: ""
                }
            ]
        },
        {
            id: -0.2,
            question: "Last name",
            type: "short",
            isShown: true,
            isRequired: true,
            answer: "",
            options: [
                {
                    text: "",
                    value1: ""
                },
                {
                    text: "",
                    value1: ""
                }
            ]
        },
        {
            id: -0.3,
            question: "Email",
            type: "short",
            isShown: true,
            isRequired: true,
            answer: "",
            options: [
                {
                    text: "",
                    value1: ""
                },
                {
                    text: "",
                    value1: ""
                }
            ]
        },
        {
            id: -1,
            question: "Prefix(Mr.,Mrs.,etc.)",
            type: "select",
            isShown: false,
            isRequired: false,
            answer: "",
            options: [
                { text: "", value1: "" },
                { text: "", value1: "" },
            ],
        },
        {
            id: -2,
            question: "Suffix",
            type: "select",
            isShown: false,
            isRequired: false,
            answer: "",
            options: [
                { text: "", value1: "" },
                { text: "", value1: "" },
            ],
        },
        {
            id: -3,
            question: "Gender",
            type: "select",
            isShown: false,
            isRequired: false,
            answer: "",
            options: [
                { text: "", value1: "" },
                { text: "", value1: "" },
            ],
        },
        {
            id: -4,
            question: "Home phone",
            type: "short",
            isShown: false,
            isRequired: false,
            answer: "",
            options: [
                { text: "", value1: "" },
                { text: "", value1: "" },
            ],
        },
        {
            id: -5,
            question: "Cell phone",
            type: "short",
            isShown: false,
            isRequired: false,
            answer: "",
            options: [
                { text: "", value1: "" },
                { text: "", value1: "" },
            ],
        },
        {
            id: -6,
            question: "Home address",
            type: "paragraph",
            isShown: false,
            isRequired: false,
            answer: "",
            options: [
                { text: "", value1: "" },
                { text: "", value1: "" },
            ],
        },
        {
            id: -7,
            question: "Shipping address",
            type: "paragraph",
            isShown: false,
            isRequired: false,
            answer: "",
            options: [
                { text: "", value1: "" },
                { text: "", value1: "" },
            ],
        },
        {
            id: -8,
            question: "Website",
            type: "short",
            isShown: false,
            isRequired: false,
            answer: "",
            options: [
                { text: "", value1: "" },
                { text: "", value1: "" },
            ],
        },
        {
            id: -9,
            question: "Job title",
            type: "short",
            isShown: false,
            isRequired: false,
            answer: "",
            options: [
                { text: "", value1: "" },
                { text: "", value1: "" },
            ],
        },
        {
            id: -10,
            question: "Company / organization",
            type: "short",
            isShown: false,
            isRequired: false,
            answer: "",
            options: [
                { text: "", value1: "" },
                { text: "", value1: "" },
            ],
        },
        {
            id: -11,
            question: "Work address",
            type: "paragraph",
            isShown: false,
            isRequired: false,
            answer: "",
            options: [
                { text: "", value1: "" },
                { text: "", value1: "" },
            ],
        },
        {
            id: -12,
            question: "Work phone",
            type: "paragraph",
            isShown: false,
            isRequired: false,
            answer: "",
            options: [
                { text: "", value1: "" },
                { text: "", value1: "" },
            ],
        }
    ];
    userQuestions0: Question[] = [];
    constructor(public loader: LoaderService,
        private fb: FormBuilder,
        private dialogService: DialogService,
        private orderFormSrv: OrderFormService,
        private srv: EventStepsService,
        private ticketsSrv: TicketsService,
        private messageService: MessageService,
        public refDialog: DynamicDialogRef,
        private translate:TranslateService
    ) { 
        this.translate.onLangChange.subscribe(() => {
            this.updateTranslations();
          });
    }
    updateTranslations() {
        this.defaultInputs = [
            {
                id: -0.1,
                question: this.translate.instant('OrdrFrmTitle002Titl001Chois001'),
                type: "short",
                isShown: true,
                isRequired: true,
                answer: "",
                options: [
                    {
                        text: "",
                        value1: ""
                    },
                    {
                        text: "",
                        value1: ""
                    }
                ]
            },
            {
                id: -0.2,
                question:this.translate.instant('OrdrFrmTitle002Titl001Chois002'),
                type: "short",
                isShown: true,
                isRequired: true,
                answer: "",
                options: [
                    {
                        text: "",
                        value1: ""
                    },
                    {
                        text: "",
                        value1: ""
                    }
                ]
            },
            {
                id: -0.3,
                question: this.translate.instant('OrdrFrmTitle002Titl001Chois003'),
                type: "short",
                isShown: true,
                isRequired: true,
                answer: "",
                options: [
                    {
                        text: "",
                        value1: ""
                    },
                    {
                        text: "",
                        value1: ""
                    }
                ]
            },
            {
                id: -1,
                question: this.translate.instant('OrdrFrmTitle002Titl001Chois004'),
                type: "select",
                isShown: false,
                isRequired: false,
                answer: "",
                options: [
                    { text: "", value1: "" },
                    { text: "", value1: "" },
                ],
            },
            {
                id: -2,
                question: this.translate.instant('OrdrFrmTitle002Titl001Chois005'),
                type: "select",
                isShown: false,
                isRequired: false,
                answer: "",
                options: [
                    { text: "", value1: "" },
                    { text: "", value1: "" },
                ],
            },
            {
                id: -3,
                question: this.translate.instant('OrdrFrmTitle002Titl001Chois006'),
                type: "select",
                isShown: false,
                isRequired: false,
                answer: "",
                options: [
                    { text: "", value1: "" },
                    { text: "", value1: "" },
                ],
            },
            {
                id: -4,
                question: this.translate.instant('OrdrFrmTitle002Titl001Chois007'),
                type: "short",
                isShown: false,
                isRequired: false,
                answer: "",
                options: [
                    { text: "", value1: "" },
                    { text: "", value1: "" },
                ],
            },
            {
                id: -5,
                question: this.translate.instant('OrdrFrmTitle002Titl001Chois008'),
                type: "short",
                isShown: false,
                isRequired: false,
                answer: "",
                options: [
                    { text: "", value1: "" },
                    { text: "", value1: "" },
                ],
            },
            {
                id: -6,
                question:this.translate.instant('OrdrFrmTitle002Titl001Chois009'),
                type: "paragraph",
                isShown: false,
                isRequired: false,
                answer: "",
                options: [
                    { text: "", value1: "" },
                    { text: "", value1: "" },
                ],
            },
            {
                id: -7,
                question: this.translate.instant('OrdrFrmTitle002Titl001Chois010'),
                type: "paragraph",
                isShown: false,
                isRequired: false,
                answer: "",
                options: [
                    { text: "", value1: "" },
                    { text: "", value1: "" },
                ],
            },
            {
                id: -8,
                question: this.translate.instant('OrdrFrmTitle002Titl001Chois011'),
                type: "short",
                isShown: false,
                isRequired: false,
                answer: "",
                options: [
                    { text: "", value1: "" },
                    { text: "", value1: "" },
                ],
            },
            {
                id: -9,
                question: this.translate.instant('OrdrFrmTitle002Titl001Chois012'),
                type: "short",
                isShown: false,
                isRequired: false,
                answer: "",
                options: [
                    { text: "", value1: "" },
                    { text: "", value1: "" },
                ],
            },
            {
                id: -10,
                question: this.translate.instant('OrdrFrmTitle002Titl001Chois013'),
                type: "short",
                isShown: false,
                isRequired: false,
                answer: "",
                options: [
                    { text: "", value1: "" },
                    { text: "", value1: "" },
                ],
            },
            {
                id: -11,
                question: this.translate.instant('OrdrFrmTitle002Titl001Chois014'),
                type: "paragraph",
                isShown: false,
                isRequired: false,
                answer: "",
                options: [
                    { text: "", value1: "" },
                    { text: "", value1: "" },
                ],
            },
            {
                id: -12,
                question: this.translate.instant('OrdrFrmTitle002Titl001Chois015'),
                type: "paragraph",
                isShown: false,
                isRequired: false,
                answer: "",
                options: [
                    { text: "", value1: "" },
                    { text: "", value1: "" },
                ],
            }
        ];
    }
    ngOnInit(): void {
        this.updateTranslations();
        this.loader.showLoader1();
        this.initForm('0', true, [], [], []);
        this.defaultInputs.forEach(x => {
            const question = this.fb.group(
                {
                    id: [x.id],
                    question: [x.question],
                    type: [x.type],
                    isShown: [false],
                    isRequired: [false],
                    answer: [''],
                    options: [this.fb.array(x.options.map(u => this.fb.group(u)))]
                }
            );
            this.formQuestions.push(question);
        });
        this.form.addControl('orderFormType', this.fb.control(true));
        this.form.get('orderFormType').valueChanges.subscribe(z => {
            this.form.get('ticketsIds').patchValue([]);
        });

        this.srv.state$.subscribe(s => {
            this.ticketsSrv.getAllTicketsForOrderForm(s.id).subscribe(x => this.tickets = x);
            this.orderFormSrv.getTickets(s.id).subscribe(yyy => {
                this.tickets = yyy;
                this.orderFormSrv.getOrderForm(s.id).subscribe(x => {
                    this.loader.hideLoader1();
                //    console.log(x)
                    const y = JSON.parse(x.content);


               //     console.log(y)
                    const defaultQ = [];
                    const UserQ = [];

                    y.forEach(t => {
                        if (t.id < 0) {
                            t.options = this.fb.array(t.options.map(x => this.fb.group(x)))
                            defaultQ.push(this.fb.group(t))
                        }

                        else {
                            t.options = this.fb.array(t.options.map(x => this.fb.group(x)))

                            UserQ.push(this.fb.group(t))
                        }
                    }



                    );



                    this.initForm(x.id, x.formType, defaultQ, UserQ, x.ticketsIds);

                }
                );
            }
            );




        });

    }

    showPreview() {
        this.refDialog = this.dialogService.open(PreviewEventComponent, {
            header: 'Preview',
            width: '70%',
            position: 'top',

            contentStyle: { overflow: 'scroll-y' },
            baseZIndex: 10000,
            data: JSON.stringify([...this.form.value.questions, ...this.form.value.userQuestions])
        });
    }

    get formQuestions() {
        return this.form.get('questions') as FormArray;
    }
    get userQuestions() {
        return this.form.get('userQuestions') as FormArray;
    }

    initForm(id, formType, questions, userQuestions, ticketsIds) {
       // console.log(userQuestions)
        this.form = this.fb.group({
            id: [id],
            orderFormType: [formType],
            questions: this.fb.array([...questions]),
            userQuestions: this.fb.array([...userQuestions]),
            ticketsIds: [ticketsIds],

        });
    }
    onAddQuestionClicked(): void {
        this.ref = this.dialogService.open(QuestionFormComponent, {
            header: 'Add a question',
      

            contentStyle: { overflow: 'auto' },
            baseZIndex: 10000,
        });
        this.ref.onClose.subscribe((question) => {


            if (question) {
                this.userQuestions.push(question);
                question.get('id').setValue(this.userQuestions.length + 1)
            }
        });
    }
    ngOnDestroy() {
        if (this.ref) {
            this.ref.close();
        }
    }
    onDeleteClicked(i: number) {
        this.userQuestions.removeAt(i);
    }

    submit() {
        (this.form.valid)
        if (this.form.valid) {
            this.isSubmitting = true;
          //  console.log(this.form.value);

            if (this.form.get('orderFormType').value) {
                this.form.get('ticketsIds').patchValue([]);
            }
            const data = {
                id: this.form.value.id,
                eventId: this.srv.id,
                formType: this.form.value.orderFormType,
                name: 'aa',
                content: JSON.stringify([...this.form.value.questions, ...this.form.value.userQuestions]),
                ticketsIds: this.form.value.ticketsIds
            };
          //  console.log(data);
            this.orderFormSrv.postOrderForm(data).subscribe(z => {
                this.messageService.add({ severity: 'success', summary: 'Saved Successfully' })
                this.isSubmitting = false;
            });

        } else {
            this.submitted = true;
        }

    }

    Edit(i: number) {
        const ques = this.userQuestions.at(i);
      //  console.log(ques.value);
        this.ref = this.dialogService.open(QuestionFormComponent, {
            header: 'Edit a question',
            width: '70%',
            contentStyle: { overflow: 'auto' },
            baseZIndex: 10000,
            data: ques
        });
        this.ref.onClose.subscribe((question) => {
            if (question)
                this.userQuestions.at(i).patchValue(question.value);

        });
    }
}
