import { animate, style, transition, trigger } from '@angular/animations';
import { DOCUMENT, DatePipe } from '@angular/common';
import { Component ,Inject} from '@angular/core';
import { Router } from '@angular/router';
import { ConfirmationService, LazyLoadEvent, MessageService, SelectItem } from 'primeng/api';
import { Subscription } from 'rxjs';
import { EventTableDto } from 'src/app/Interfaces/EventData';
import { AuthService } from 'src/app/services/auth.service';
import { EventStepsService } from 'src/app/services/event-steps.service';
import { EventsService } from 'src/app/services/events.service';
import { LoaderService } from 'src/app/services/loader.service';
import { environment } from 'src/environments/environment';
const leaveTrans = transition(':leave', [
  style({
    opacity: 1
  }),
  animate('1s ease-out', style({
    opacity: 0
  }))
])
const fadeOut = trigger('fadeOut', [
  leaveTrans
]);
@Component({
  selector: 'app-sub-eventsfor-mainevents',
  templateUrl: './sub-eventsfor-mainevents.component.html',
  styleUrls: ['./sub-eventsfor-mainevents.component.scss'],
  animations: [fadeOut]
})
export class SubEventsforMaineventsComponent {
  url = environment.Api;
  sub: Subscription[]=[];
  ParentId:string="0";
  TargetChanged = true;
  endSub = false;
  events: EventTableDto[] = [];
  cols!: any[];
  sortOptions: SelectItem[];
  recuring = '#eee';
  single = '#ffd384';
  sortOrder: number;
  sortKey: any;
  sortField: string;
  allEventForPrev: EventTableDto[] = [];
  eventPrv: EventTableDto;
  TotalRecord:number=0;
  SubTotalRecourd:number=0;
  Subevents: EventTableDto[]=[];
  constructor(
    public auth: AuthService,
    public loader: LoaderService,
    public eventService: EventsService,
    private eventStpSRv:EventStepsService,
    private route: Router,
    private conSrv: ConfirmationService,
    private messageService: MessageService,private datePipe: DatePipe,
    @Inject(DOCUMENT) public document: Document,
  ) { 

  }
  ngOnDestroy(): void {
    this.sub.forEach(element => {
      element.unsubscribe();
    });
  }
  getDateOntomeZone(d: Date) {
    var t = new Date(Date.now()).getTimezoneOffset()
    var MyDate = new Date(d).setMinutes(new Date(d).getMinutes() - (new Date(Date.now()).getTimezoneOffset()));
    return new Date(MyDate);
  }
  onSortChange(event): void {
    const value = event.value;

    if (value.indexOf('!') === 0) {
      this.sortOrder = -1;
      this.sortField = value.substring(1, value.length);
    }
    else {
      this.sortOrder = 1;
      this.sortField = value;
    }
  }
  editProduct(product) {

    // this.eventStpSRv.state$.subscribe(x=>{
    //   this.eventStpSRv.setParentId(x.id);
    //   this.eventStpSRv.setParentDate(x);
    this.eventStpSRv.setParentId(this.eventStpSRv.id);
  
   this.eventStpSRv.setParentDateforsub(this.eventStpSRv.startTime,this.eventStpSRv.endTime);
   //console.log({start:this.eventStpSRv.startTime,end:this.eventStpSRv.endTime})
      this.route.navigate(['/events/manage-event/', product.id]);
  //})
   
  }
  remove(event, id) {

    this.conSrv.confirm({

      target: event.target,
      message: 'Are you sure that you want to proceed?',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
     var del=    this.eventService.removeEntry(id).subscribe(x => {
          this.eventService.getsubevent('subEvent');
          this.messageService.add({ severity: 'success', summary: 'Deleted successfully' });
        });
        this.sub.push(del)
        // this.messageService.add({ severity: 'info', summary: 'Confirmed', detail: 'You have accepted' });
      },
      reject: () => {
        //this.messageService.add({ severity: '', summary: 'Deleted successfully' });
      }
    });
  }
  preview(id) {
    this.route.navigate(['event', id])

  }
  openNew() {
 //console.log(this.eventStpSRv.id)

//  this.eventStpSRv.state$.subscribe(x=>{
//      this.eventStpSRv.setParentId(x.id);
//      this.eventStpSRv.setParentDate(x);
//         this.route.navigate(['/events/manage-event/', '0']);
//  })

this.eventStpSRv.setParentId(this.eventStpSRv.id);

   //this.eventStpSRv.setParentDate({start:this.eventStpSRv.startTime,end:this.eventStpSRv.endTime});
   this.eventStpSRv.setParentDateforsub(this.eventStpSRv.startTime,this.eventStpSRv.endTime);

        this.route.navigate(['/events/manage-event/', '0']);


  }
  getstatus(x) {
    //return x ? 'Published' : 'Draft';
    return x ?   'EvntTblStatus001' : 'EvntTblStatus002';
  }
  
  ngOnInit(): void {
   // this.loader.showLoader();
    this.sortOptions = [
      { label: 'Price High to Low', value: '!price' },
      { label: 'Price Low to High', value: 'price' }
    ];
    this.eventStpSRv.state$.subscribe(x=>{
      this.ParentId=x.id;
      this.eventService.getsubevent(x.id);

      var l= this.eventService.$getSubEvent.subscribe(x => {
       this.allEventForPrev = []
       this.events = x.events;
       this.TotalRecord=x.totalCount
       this.endSub = true;
  
      //  if (x.length !== 0) {
      //    for (let i = 0; i < x.length; i++) {
         
      //      this.allEventForPrev.push(x[i]);
      //      if (x[i].eventInstance != null) {
      //        for (let j = 0; j < x[i].eventInstance.length; j++) {
      //          let t = x[i].eventInstance[j];
              
      //          this.allEventForPrev.push(t);
      //        }
      //      }
      //      // console.log( this.allEventForPrev)
      //    }
      //  }
    
      // this.loader.hideLoader();
       return this.events;
     });
     this.sub.push(l)
    })
   

    this.cols = [
      { field: 'startDate', header: '' },
      { field: 'photoLink', header: '' },
      { field: 'title', header: 'Title' },
      { field: 'totalQty', header: 'Sold' },
      { field: 'soldTickets', header: 'Sold' },
      { field: 'gross', header: 'Gross' },
      { field: 'status', header: 'Status' },
      { field: 'id', header: 'Actions' }
    ];
  }

  getEvent(e:LazyLoadEvent){
    var l=e.first/5;
    this.eventService.getsubevent(this.ParentId,l);

  }
  getrecDate(x: Date): string {
    let t = new Date(x).toLocaleDateString();
    return t;
  }
  getDateOntomeZoneformat(d,x){
    let t = new Date(Date.now()).getTimezoneOffset();
    let MyDate = new Date(d).setMinutes(new Date(d).getMinutes() - (t));
    var l="";
    switch(x){
      case 'full':if(new Date(MyDate).getFullYear()==new Date(Date.now()).getFullYear()){
        l= this.datePipe.transform(new Date(MyDate), 'EEE MMMM d hh:mm a');
        
    }else{
       l= this.datePipe.transform(new Date(MyDate), 'EEE MMMM d,y hh:mm a');
     
    }
    break;
    case 'month': l=this.datePipe.transform(new Date(MyDate), 'MMM');
    break;
    case 'day':l=this.datePipe.transform(new Date(MyDate), 'dd');
    break
    }
    return l
    


    
   // return new Date(MyDate).toLocaleString();
}

getinstanse(id:string,e:LazyLoadEvent){
  if(e){
    var l=e.first/5;
    this.eventService.GetEventRecuringTableInstan(id,l);
  }else{
    this.eventService.GetEventRecuringTableInstan(id,0);
  }

  this.eventService.$getRegSubject.subscribe(x=>{
    this.Subevents=x.events;
    this.SubTotalRecourd=x.totalCount;
   

  })
}
}
