import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, ReplaySubject, Subject } from 'rxjs';
import { profileImagdto } from 'src/app/components/shared/adduserprofilephoto/adduserprofilephoto.component';
import { UserAddreesDto } from 'src/app/components/shared/location-sertch/location-sertch.component';
import { environment } from 'src/environments/environment';
 export interface profileContactInfoVM{
   firstName         :string;
   lastName          :string;
   company           :string;
   homePhone         :string;
   homePhonecountry  :string;
   mobilePhonecountry:string;
   mobilePhone       :string;
   job               :string;
   prefix            :string;
   suffix            :string;
   website           :string;
 }
 export interface cardInfoDto{
  cardNumber:number,
  cardholderName:string,
  expiryDate:string,
  securityCode:number,
  billingAddress:string,
 }

 export interface userLastEvent{
  count:number,
  lastevent:userLastEventDto
 }
 export interface userLastEventDto{
  photoLink    :string
  id           :string
  title        :string
  totalQty    :number
  soldTickets  :number
  startDate    :Date
  address      :string
 }


 export interface UserTicketsOrOrderDto{
  eventName       :string;
  eventStartTime  :Date;
  eventEndDate    :Date; 
  address         :string; 
  eventPic        :string; 
  tikettype       :string;  
  delivery        :string;
  orderNum        :string;
  orderDate       :Date; 
  id              :string;
  eventId:string;
  usertickets        :boolean;
  orderId:string
 }

 export interface UserTicketsOraddOnsDto{
  eventName       :string;
  eventStartTime  :Date;
  eventEndDate    :Date; 
  address         :string; 
  eventPic        :string; 
  tikettype       :string;  
  delivery        :string;
  orderNum        :string;
  orderDate       :Date; 
  id              :string;
  eventId:string;
  usertickets :boolean;
  orderId:string;
  status:string;
  elementPic:string
 }


 export interface profileTopinfo{
 
       name      :string
       totalEvent   :number
        totalfolwers :number

  
 }
@Injectable({
  providedIn: 'root'
})
export class ProfileEditingSrvService {
  profileContactInfoSubject: Subject<profileContactInfoVM> = new ReplaySubject<profileContactInfoVM>(1);
  profileHomeAdDressSubject: Subject<UserAddreesDto> = new ReplaySubject<UserAddreesDto>(1);
  profileWorkAddressSubject: Subject<UserAddreesDto> = new ReplaySubject<UserAddreesDto>(1);
  profileBillingAddressSubject: Subject<UserAddreesDto> = new ReplaySubject<UserAddreesDto>(1);
  profileShippingAddressSubject: Subject<UserAddreesDto> = new ReplaySubject<UserAddreesDto>(1);
  profileImageSubject: Subject<profileImagdto> = new ReplaySubject<profileImagdto>(1);
  profileCardInfoSubject: Subject<cardInfoDto> = new ReplaySubject<cardInfoDto>(1);
  usereventdetaile: Subject<userLastEvent> = new ReplaySubject<userLastEvent>(1);
  userTicketsOrOrder: Subject<UserTicketsOrOrderDto[]> = new ReplaySubject<UserTicketsOrOrderDto[]>(1); 
  ProfileTopInfo: Subject<profileTopinfo> = new ReplaySubject<profileTopinfo>(1);

  userTicketsandaddonsSubject:Subject<UserTicketsOraddOnsDto[]>=new ReplaySubject<UserTicketsOraddOnsDto[]>(1); 

  constructor(private http:HttpClient) { }

  get $getPuserTicketsOrAddons(): Observable<UserTicketsOraddOnsDto[]> {
    return this.userTicketsandaddonsSubject.asObservable();
  }

 get $getProfileTopInfo(): Observable<profileTopinfo> {
    return this.ProfileTopInfo.asObservable();
  }
  get $getUserTicketsOrOrders(): Observable<UserTicketsOrOrderDto[]> {
    return this.userTicketsOrOrder.asObservable();
  }

  get $getUserprofileevent(): Observable<userLastEvent> {
    return this.usereventdetaile.asObservable();
  }
  get $getUserprofileContactInfo(): Observable<profileContactInfoVM> {
    return this.profileContactInfoSubject.asObservable();
  }
  get $getUserprofileHomeAdDress(): Observable<UserAddreesDto> {
  
    return this.profileHomeAdDressSubject.asObservable();
  }get $getUserprofileWorkAddress(): Observable<UserAddreesDto> {
  
    return this.profileWorkAddressSubject.asObservable();
  }get $getUserprofileBillingAddress(): Observable<UserAddreesDto> {
  
    return this.profileBillingAddressSubject.asObservable();
  }get $getUserprofileShippingAddress(): Observable<UserAddreesDto> {
  
    return this.profileShippingAddressSubject.asObservable();
  }get $getUserprofileCardInfo(): Observable<cardInfoDto> {
  
    return this.profileCardInfoSubject.asObservable();
  }
  get $getUserprofileImage(): Observable<profileImagdto> {
  
    return this.profileImageSubject.asObservable();
  }

  GetUserProfileInfo(){
    this.GetUserProfileinfo();
    this.GetUserhomeAddress();
    this.GetUserWorkAddress();
    this.GetUserBillingAddress();
    this.GetUserShippingAddress();
    this.GetUserCadrInfo();
    this.GetProfileImage();
  }
  GetProfileImage(){
    this.http.get<profileImagdto>(environment.Api+'d/up/g/p/i').subscribe(x=>{
      this.profileImageSubject.next(x);
     });
  }
  GetUserProfileinfo(){
    this.http.get<profileContactInfoVM>(environment.Api+'d/up/g/p/in').subscribe(x=>{
     this.profileContactInfoSubject.next(x);
    });
  }
  GetUserhomeAddress(){
    this.http.get<UserAddreesDto>(environment.Api+'d/up/g/p/ha').subscribe(x=>{
     this.profileHomeAdDressSubject.next(x);
    })
  }
  GetUserWorkAddress(){
    this.http.get<UserAddreesDto>(environment.Api+'d/up/g/p/wa').subscribe(x=>{
     this.profileWorkAddressSubject.next(x);
    })
  }
  GetUserBillingAddress(){
    this.http.get<UserAddreesDto>(environment.Api+'d/up/g/p/ba').subscribe(x=>{
     this.profileBillingAddressSubject.next(x);
    })
  }
  GetUserShippingAddress(){
    this.http.get<UserAddreesDto>(environment.Api+'d/up/g/p/wa').subscribe(x=>{
     this.profileShippingAddressSubject.next(x);
    })
  }
  GetUserCadrInfo(){
    this.http.get<cardInfoDto>(environment.Api+'d/up/g/p/ca').subscribe(x=>{
     this.profileCardInfoSubject.next(x);
    })
  }
  PostUserProfileinfo(data:profileContactInfoVM){
    return this.http.post(environment.Api+'d/up/c/p/i',data)
  }
  PostUserhomeAddress(data:UserAddreesDto){
    return this.http.post(environment.Api+'d/up/c/ha',data)
  }
  PostUserWorkAddress(data:UserAddreesDto){
    return this.http.post(environment.Api+'d/up/c/wa',data)
  }
  PostUserBillingAddress(data:UserAddreesDto){
    return this.http.post(environment.Api+'d/up/c/ba',data)
  }
  PostUserShippingAddress(data:UserAddreesDto){
    return this.http.post(environment.Api+'d/up/c/sa',data)
  }
  PostUserCadrInfo(data:cardInfoDto){
    return this.http.post(environment.Api+'d/up/c/pc',data)
  }
  PostUserProfileImage(data:profileImagdto){
    return this.http.post(environment.Api+'d/up/c/i',data)
  }

  GetUserlastevant(Id=""){
    this.http.get<userLastEvent>(environment.Api+'d/up/g/p/'+Id).subscribe(x=>{
      this.usereventdetaile.next(x)
    })
  }
  GetUserTicketsOrOrder(Id=""){
    this.http.get<UserTicketsOrOrderDto[]>(environment.Api+'d/up/g/p/t/'+Id).subscribe(x=>{
     this.userTicketsOrOrder.next(x)
    })
  }
  GetTopInfo(Id=""){
 this.http.get<profileTopinfo>(environment.Api+'d/up/g/p/in/t/'+Id).subscribe(x=>{
    console.log(x);
    this.ProfileTopInfo.next(x)
   })
  }
  GetUserTicketsOrAddOns(Id=0){
    this.http.get<UserTicketsOraddOnsDto[]>(environment.Api+'d/up/g/u/t/'+Id).subscribe(x=>{
     console.log(x);
     this.userTicketsandaddonsSubject.next(x)
    })
  }
}
