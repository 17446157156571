import { Component, OnInit,Inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { OrgnizersService, Roles } from 'src/app/services/orgnizers.service';
import { RolesServiceService } from '../roles.service';
import { LoaderService } from 'src/app/services/loader.service';
import { transition, style, animate, trigger } from '@angular/animations';
import { ConfirmationService, MessageService } from 'primeng/api';
import { DOCUMENT } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';

const leaveTrans = transition(':leave', [
  style({
      opacity: 1
  }),
  animate('1s ease-out', style({
      opacity: 0
  }))
])



const fadeOut = trigger('fadeOut', [
  leaveTrans
]);


@Component({
  selector: 'app-roles-table',
  templateUrl: './roles-table.component.html',
  styleUrls: ['./roles-table.component.scss'],
  animations : [fadeOut]
})
export class RolesTableComponent implements OnInit{
  data: Roles[] = [];
  ngOnInit(): void {
    this.loader.showLoader1();
    this.orgSrv.getRoles(this.activatedRoute.parent.parent.parent.snapshot.paramMap.get('id'))
    this.orgSrv.$getSubject.subscribe(e => { this.data = e; this.loader.hideLoader1()})

    }
    delete(event,id: string) {
      this.conSrv.confirm({

        target: event.target,
        message:  this.translate.instant('Are you sure that you want to proceed?'),
        icon: 'pi pi-exclamation-triangle',
        accept: () => {

         this.messageService.add({ severity: 'info', summary: 'Confirmed', detail: this.translate.instant('You have accepted') });
        },
        reject: () => {

        }
    });
    }
    edit(id : string) {
        this.loader.showLoader1();
        this.rolesrv.initializer(id , "" );
        this.rolesrv.prepareform();
        // this.orgSrv.getPermissionsWithParent().subscribe(x => {

        //   console.log(x)
        //   this.rolesrv.permissionCat = x;
        //   this.rolesrv.newData = this.rolesrv.permissionCat.map(x => {
        //     return {
        //     key : x.key,
        //    //label :  this.translate.instant(x.label),
        //      label:x.label,
        //     count : x.children.length,
        //     }
        //   })
        //   this.orgSrv.getPermissionsForRole(id).subscribe(x => {
        //     this.rolesrv.selectedItems = x.permissions
        //     this.rolesrv.initializer(id ,x.name)
        //     x.permissions.forEach(e => {
        //           this.rolesrv.newData1.push(e.key.toString().slice(0,-2));
    
        //     })

        //       this.rolesrv.checkNode(this.rolesrv.newData , this.rolesrv.newData1);
        //       this.loader.hideLoader1();
        //   })

        // })
         


      this.router.navigate(['roles-form'],{relativeTo: this.activatedRoute.parent});

    }
    openNew(){
      this.loader.showLoader1();
      this.rolesrv.initializer("0" , "");
      this.orgSrv.getPermissionsWithParent().subscribe(x => {  console.log(x);  this.rolesrv.permissionCat = x;this.loader.hideLoader1()
      })

      this.router.navigate(['roles-form'] ,{relativeTo: this.activatedRoute.parent});
    }

  constructor(
     @Inject(DOCUMENT) public document: Document,public conSrv :ConfirmationService,
     public loader : LoaderService,private router : Router
     ,private orgSrv:OrgnizersService,private rolesrv : RolesServiceService
      , private activatedRoute : ActivatedRoute,private translate:TranslateService,private messageService:MessageService) {


  }

}
