<div class="instructor-banner-area">
    <div class="container">
        <div class="row align-items-center ">
            <div class="col-lg-5 col-md-12">
                <div class="instructor-banner-content">
                    <h1 class="el-messiri-font">{{'LndPgTitle001'|translate}}</h1>
                    <p>{{'LndPgTitle001Dsc001'|translate}}</p>
                    <div class="banner-btn rounded-pill">
                        <a *ngIf="!auth.securityObject.isAuthenticated" routerLink="login"
                        class="rounded-pill" [ngClass]="{'default-btn-rtl': document.documentElement.dir === 'rtl',
                        'default-btn': document.documentElement.dir === 'ltr'}"><i
                                class="flaticon-user"></i>{{'NavStrtBtn'|translate}}<span></span></a>
                        <a *ngIf="auth.securityObject.isAuthenticated" routerLink="events/manage-event/0"
                        class="rounded-pill" [ngClass]="{'default-btn-rtl': document.documentElement.dir === 'rtl',
                        'default-btn': document.documentElement.dir === 'ltr'}"><i
                                class="fa-light fa-calendar-plus"></i>{{'LndPgCreatBtn'|translate}}<span></span></a>
                    </div>
                </div>
            </div>
            <div class="col-lg-7 col-md-12">
                <div class="instructor-banner-image  d-lg-flex justify-content-center">
                    <div class="text-center">
                        <img src="assets/img/instructor-home/banner/congratulatiing-colleague-on-promotion-2022-02-02-04-52-27-utc.jpg"
                            alt="image">

                    </div>
                    <div class="banner-dot-shape">
                        <img src="assets/img/instructor-home/banner/dot-shape.png" alt="image">
                    </div>
                </div>
            </div>
        </div>
    </div>



    <section class="why-chose-us">
        <i class="sl-shape shape_01 fa-thin text-primary  fa-regular fa-masks-theater  fa-5x"></i>
        <i class="sl-shape shape_02 fa-thin text-primary  fa-party-horn  fa-3x"></i>
        <i class="sl-shape shape_03 fa-thin  text-primary  fa-ticket fa-4x"></i>
        <i class="sl-shape shape_04 fa-thin  text-primary fa-regular  fa-music fa-5x"></i>
        <i class="sl-shape shape_06 fa-thin text-primary  fa-solid fa-burger-soda fa-5x"></i>
    </section>
