<div class="card custom-table-border">
    <!-- <div class="card-header text-center bg-primary rounded-top-23">
        <h1 class="text-white">Roles</h1>
    </div> -->
    <div class="card-header text-center bg-primary rounded-top-23">
        <h1 class="fw-bold text-white mt-2">{{'RolsTblTitl'|translate}}</h1>
    </div>
    
    <router-outlet ></router-outlet>
    
</div>