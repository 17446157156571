import {Component, OnInit} from '@angular/core';
import {UserActionsService} from '../../../services/user-actions.service';
import {environment} from '../../../../environments/environment';
import {EventCard} from '../landing-page/landing-page.service';
import { Orgnizer } from 'src/app/services/orgnizers.service';

@Component({
  selector: 'app-favorites',
  templateUrl: './favorites.component.html',
  styleUrls: ['./favorites.component.scss']
})
export class FavoritesComponent implements OnInit{
    url = environment.Api;
    Events: EventCard[] = [];
    Organizers: Orgnizer[] = [];
    constructor(private ua: UserActionsService) {
        this.ua.getLikedEventList().subscribe(y => {
            this.ua.LikedEvents = y as string[];
        });
    }
    ngOnInit(): void {
        this.ua.getLikes().subscribe(x => this.Events =  x as EventCard[]);
        this.ua.getFollowers().subscribe(x=>this.Organizers= x as Orgnizer[])
    }

    getDateOntomeZone(d:Date){
        var t = new Date(Date.now()).getTimezoneOffset();
        var MyDate = new Date(d).setMinutes(new Date(d).getMinutes()-(t));
        return new Date(MyDate).toLocaleString();
    }

    Like(id: string) {
        this.ua.postLike(id).subscribe(x => {
            this.ua.getLikedEventList().subscribe(y => {
                this.ua.LikedEvents = y as string[];
            });
            this.ua.getLikes().subscribe(x => this.Events =  x as EventCard[]);
        });
    }

    follow(Orgid){
       
        this.ua.postFollow(Orgid).subscribe(x=>{
            this.ua.getFollowers().subscribe(x=>this.Organizers= x as Orgnizer[])
        });
      }

}
