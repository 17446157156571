import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { EventStepsService } from 'src/app/services/event-steps.service';

@Component({
  selector: 'app-clender-steps-page',
  templateUrl: './clender-steps-page.component.html',
  styleUrls: ['./clender-steps-page.component.scss']
})
export class ClenderStepsPageComponent implements OnInit{
  constructor(private EventSrv:EventStepsService,private router:Router,private ActivetedRout:ActivatedRoute){}
  ngOnInit(): void {
    this.router.navigate([
      {
        outlets: { 'CalenderOutlet': ['calender_prview'] }
      }
    ],{relativeTo: this.ActivetedRout});
  }

}
