<form [formGroup]="Srv.clenderPost" (ngSubmit)="submit()" autocomplete="off">
    <div class="container">
        <div class="row mb-2">
            <span class="text-start">{{'CalenderStep4lable1'|translate}}</span>
            <!-- <div class=" custom-table-border">
                    <p-table  pTooltip="the date for every instance of ypur reccuring event"
                    tooltipPosition="top" showDelay="1000"  #dt [value]="Srv.clenderPost.controls['dates'].value" [rows]="10" [paginator]="true"     [loadingIcon]="'pi pi-spin pi-spinner'" [globalFilterFields]="['name']"
                        [tableStyle]="{'min-width': '75%'}" dataKey="id"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" [showCurrentPageReport]="true">
                        <ng-template pTemplate="caption">

                            <div>

                                <div class="d-sm-flex d-block align-items-center justify-content-sm-between text-center">

                                    <div class="banner-btn rounded-pill  ms-md-4   mb-2" >

                                        </div>
                                <div class="p-input-filled me-md-3 ">

                                </div>
                            </div>
                            </div>

                        </ng-template>
                        <ng-template pTemplate="header">
                            <tr>
                                <th pSortableColumn="name" >Start date <p-sortIcon field="name"></p-sortIcon></th>
                                <th pSortableColumn="price">End date <p-sortIcon field="price"></p-sortIcon></th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-product >
                            <tr>
                                <td>{{product.startDate}}</td>
                                <td>{{product.endDate}}</td>
                            </tr>
                        </ng-template>

                <ng-template pTemplate="summary">
                    <div class="flex align-items-center justify-content-between">

                    </div>
                </ng-template>
                </p-table>


                </div> -->
            <!-- <div class="card"> -->
            <p-timeline [value]="ev">
                <ng-template pTemplate="content" let-event>
                    <small class="p-text-secondary">{{event.end }}</small>
                </ng-template>
                <ng-template pTemplate="opposite" let-event>
                    {{ event.start }}
                </ng-template>
            </p-timeline>
            <!-- </div> -->
        </div>
    </div>
    <div class="col-12 d-flex  justify-content-center">
        <div class="banner-btn rounded-pill mx-1">
            <button class="rounded-pill cancel-btn" pTooltip="Go back for previous step" tooltipPosition="top"
                showDelay="1000" (click)="previous()" type="button"><i class="pi mt-1"
                    [class.pi-angle-right]="document.documentElement.dir === 'rtl'"
                    [class.pi-angle-left]="document.documentElement.dir === 'ltr'"></i> {{'PrviosBtn'|translate}}
                <span></span></button>
            <button class="rounded-pill save-btn ms-1" showDelay="1000" pTooltip="Cancel and dismiss changes "
                tooltipPosition="top" (click)="cancel()" type="button">{{'CanclBtn'|translate}} <span></span></button>
            <button class="rounded-pill save-btn  ms-1 mt-md-0 mt-1" pTooltip="Finishing and go to clender prev "
                tooltipPosition="top" showDelay="1000">{{'FnshBtn'|translate}} <span></span></button>
        </div>
    </div>
</form>
