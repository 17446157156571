import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, ValidatorFn, AbstractControl } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { DropDownItem } from 'src/app/Interfaces/DropDownItem';
import { OrgnizersService } from 'src/app/services/orgnizers.service';

@Component({
  selector: 'app-invite-team-member',
  templateUrl: './invite-team-member.component.html',
  styleUrls: ['./invite-team-member.component.scss']
})
export class InviteTeamMemberComponent implements OnInit{
  data :any[] = [];
  data1 :any[] = [];
    form: FormGroup;
  submitted : boolean;
  showDropDown : boolean;
  showOrg : string = "";



  constructor( private translate:TranslateService,
    private router : Router,private orgSrv : OrgnizersService,private fb: FormBuilder
    ,public ref: DynamicDialogRef , public config : DynamicDialogConfig) {
      this.translate.onLangChange.subscribe(() => {
        this.getRoles(this.showOrg)
      });

  }
  ngOnInit(): void {
      this.init()

      this.showOrg = this.config.data.showOrg;
      if(this.showOrg == "No Show"){
        this.data = this.config.data.org;
        this.showDropDown = true;
      }else{
    //    console.log(this.showOrg)
        this.data = [this.config.data.org];
        this.form.get("organizerId").patchValue(this.showOrg)
        this.form.get("organizerId").updateValueAndValidity();
        this.getRoles(this.showOrg)
        this.showDropDown = false;
      }
    //  console.log(this.data)
  }
  init() {
    this.form = this.fb.group({
      organizerId: ["", [Validators.required ]],
      email: ["", [Validators.required ,  Validators.email]],
      roleId : ["" , Validators.required],
      firstName : ["" , Validators.pattern(/^\S*$/)],
      lastName : ["" , Validators.pattern(/^\S*$/)],
      title : ["" , Validators.required]
    },  { validators: this.atLeastOneRequiredValidator() })
  }
  
  roleMake(id){
    this.router.navigate(["organizers/team-manage/"+id+"/team-roles/roles-table"]);
    this.ref.close() 
  }
  getRoles(event){
   // console.log(event);
    this.orgSrv.getRoles1(event).subscribe( x => {this.data1 = x.map(y=>{ 
      var  m:DropDownItem={id:y.id,name:this.translate.instant(y.name)}
      return m;
    })});
  }
  submit(){
    if(this.form.valid){
    //  console.log(this.form)
      this.ref.close( this.form.value  ) ;
    }
    else{
      this.submitted = true;
    }
  }
   atLeastOneRequiredValidator(): ValidatorFn {
    return (control: AbstractControl) => {
      const firstName = control.get('firstName').value;
      const lastName = control.get('lastName').value;
      if (firstName && !lastName) {
        return { atLeastOneRequired: true };
      }
      if (!firstName && lastName) {
        return { atLeastOneRequired: true };
      }

      return null;
    };}

}
