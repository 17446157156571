<div class="container py-3">
    <div class="card custom-table-border">
        <!-- <div class="card-header text-center bg-primary rounded-top-23">

            <h1 class="fw-bold text-white mt-2">Orders</h1>

        </div> -->
        <div class="card-header border-0 text-center bg-primary rounded-top-23 ">
            <h1 class="fw-bold text-white mt-2">{{'OrdrsTblTitl'|translate}}</h1>
        </div>
        <div *ngIf="loader.show1" @fadeOut class="d-flex justify-content-center align-items-center"
            style="border-radius: 25px;top : 0;position: absolute;z-index: 1050;background-color:#fff;width: 100%;height: -webkit-fill-available;right: 0;bottom: 0;left: 0;">


        </div>


        <p-toast></p-toast>

        <div class="">
            <p-table #dt responsiveLayout="stack" [breakpoint]="'991px'" [value]="data" [rows]="6" [paginator]="true"
                [globalFilterFields]="['eventName']" [tableStyle]="{'max-width':'100%'}" [rowHover]="true" dataKey="id"
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                [showCurrentPageReport]="true" class="">
                <ng-template pTemplate="caption">

                    <div>

                        <div class="d-sm-flex d-block align-items-center justify-content-sm-between text-center">

                            <div class="  ms-md-3  mb-sm-0  mb-2">

                                <button *ngIf="auth.securityObject.checkClaim(10105030101)" pButton pRipple
                                class="rounded-pill add-new-btn mr-2" (click)="orgMenu.toggle($event)">
                                <i class="fa-solid fa-users"></i> {{selectedFilter1}}<span></span>
                            </button>
                                <p-menu #orgMenu  appendTo="body" [popup]="true" [model]="orgnizerData"></p-menu>
                            </div>
                            <div class="p-input-filled me-md-3 ">
                                <span class="p-input-icon-left">
                                    <i class="pi pi-search"></i>
                                    <input pTooltip="{{'OrdrsTblSrchInptPop'|translate}}" showDelay="1000"
                                        tooltipPosition="left" pInputText type="text" class="form-control"
                                        (input)="dt.filterGlobal($event.target.value, 'contains')"
                                        placeholder="{{'Search...'|translate}}" />
                                </span>
                            </div>
                        </div>
                    </div>

                </ng-template>
                <ng-template pTemplate="header" class="">
                    <tr>
                        <th class="fw-bold text-center">{{'Order#'|translate}}</th>
                        <th style="min-width:7.8rem" class="fw-bold text-center ">
                            {{'OrdrsTblTh002'|translate}} </th>
                            <th style="min-width:7rem" class="fw-bold text-center ">
                                {{'UserName'|translate}}  </th>
                        <th  style="min-width:9rem" class="fw-bold text-center ">
                                {{'OrdrsTblTh003'|translate}} </th>
                        <th  style="min-width:7rem" class="fw-bold text-center">
                            {{'OrdrsTblTh001'|translate}} </th>
                        <th p style="min-width:7rem" class="fw-bold text-center ">
                            {{'OrdrsTblTh005'|translate}}
                          
                        </th>
                        <th class="fw-bold text-center "> {{'OrdrsTblTh006'|translate}}</th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-ord let-rowIndex="rowIndex">
                    <tr>
                        <td class="text-md-center padding-custom text-end"><span
                                class="headers-cutom text-start fw-bold ">{{'Order#'|translate}}</span> {{ord.orderNum}}</td>
                                <td class="text-md-center padding-custom text-lenght-larg text-end"><span
                                    class="headers-cutom text-start fw-bold ">{{'OrdrsTblTh002'|translate}}</span> <span
                                    class="text-lenght">{{ord.eventName}}</span></td>
                                    <td class="text-md-center padding-custom text-end"> <span
                                        class="headers-cutom text-start fw-bold ">{{'UserName'|translate}} </span> <span
                                         >{{ord.userName}}</span></td>
                                    <td class="text-md-center padding-custom text-end"><span
                                        class="headers-cutom text-start fw-bold ">{{'OrdrsTblTh003'|translate}}</span>
                                    {{getDateOntomeZoneformat2(ord.orderDate)}}</td>
                        <td class="text-md-center padding-custom text-end"><span
                                class="headers-cutom text-start fw-bold ">{{'OrdrsTblTh001'|translate}}</span>
                            {{ord.totalAmount != '0' ?ord.totalAmount+""+ord.curSymbol:'OrdrsTblTotlAmnt'|translate}}
                        </td>

                        
                        
                        <!-- <td><p-rating [ngModel]="event.gross" [readonly]="true" [cancel]="false"></p-rating></td> -->
                        <!-- <td><span [class]="'product-badge status-' + (product.inventoryStatus ? product.inventoryStatus.toLowerCase() : '')">{{product.inventoryStatus}}</span></td> -->
                       
                        <td class="text-md-center padding-custom text-end"> <span
                                class="headers-cutom text-start fw-bold ">{{'OrdrsTblTh005'|translate}}</span> <span
                                class="badge rounded-pill text-bg-dark" [ngClass]="{
                        'text-bg-green': ord.status!=1 , 'text-bg-dark' : ord.status==1
                    }">{{ord.status==1?('OrdrsTblStatsDsc001'|translate):'OrdrsTblStatsDsc002'|translate}}</span></td>
                        <td class="text-md-center padding-custom text-end">
                            <div [class.action-custom-rtl]=" document.documentElement.dir === 'rtl'"
                                [class.action-custom]=" document.documentElement.dir === 'ltr'">
                                <!-- <button pButton pRipple icon="fa-duotone fa-envelope-open-dollar"
                                    *ngIf="(ord.status==1)||(ord.status!=1&&ord.isRefundable) "
                                    class="p-button-rounded p-button-primary " (click)="getMoney(ord)"></button> -->
                                <button pButton pRipple icon="fa-sharp fa-solid fa-arrow-right-to-line"
                                    class="p-button-rounded p-button-primary" (click)="show(ord)"></button>
                            </div>

                        </td>
                    </tr>
                </ng-template>

                <ng-template pTemplate="summary">
                    <div class="flex align-items-center justify-content-between">
                        {{'OrdrsTblFooter'|translate}} {{data ? data.length : 0 }} {{'OrdrsTblFooter01'|translate}}
                    </div>
                </ng-template>
            </p-table>

        </div>

    </div>
</div>

<!-- <div class="events-area pt-100 pb-70">
    <div class="container">
        <div class="">
            <p-toast></p-toast>


            <div class=" custom-table-border">


            </div>

        </div>
    </div>
</div> -->