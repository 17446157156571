import { AfterViewChecked, ApplicationRef, ChangeDetectionStrategy, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { GeocodingService } from '../../../../shared/map/geocoding.service';
import { GeocoderResponse } from '../../../../shared/map/geocoder-response.model';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { environment } from '../../../../../../environments/environment';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { EventStepsService } from 'src/app/services/event-steps.service';
import { AddressService } from 'src/app/services/address.service'; import { GMap } from 'primeng/gmap';
import { MapMarker } from '@angular/google-maps';
import { MessageService } from 'primeng/api';
import { LoaderService } from 'src/app/services/loader.service';
import { animate, style, transition, trigger } from '@angular/animations';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { EventsService } from 'src/app/services/events.service';
import { DropDownItem } from 'src/app/Interfaces/DropDownItem';
import { DatePipe } from '@angular/common';
const leaveTrans = transition(':leave', [
    style({
        opacity: 1
    }),
    animate('1s ease-out', style({
        opacity: 0
    }))
])



const fadeOut = trigger('fadeOut', [
    leaveTrans
]);

@Component({
    selector: 'app-event-address',
    templateUrl: './event-address.component.html',
    styleUrls: ['./event-address.component.scss'],
    changeDetection: ChangeDetectionStrategy.Default,
    animations: [fadeOut]
})
export class EventAddressComponent implements OnInit, AfterViewChecked,OnDestroy {
    addressForm: FormGroup;
    visible: boolean = false;
    options: any;
    overlays: any[];
    address = '';
    @ViewChild('gmap') gmapElement1: GMap;
    @ViewChild('gmap') gmapElement: any;
    map: google.maps.Map;
    ssss;
    submitted: boolean;
    mapProblem: boolean = false;
    sub: Subscription[]=[];
    isSreise: any;
    ngAfterViewChecked() {
        //     this.visible = true
    }
    isRecurring:boolean=false
    eventInstance:DropDownItem[]=[];

    constructor(public loader: LoaderService,
        private AddressSrv: AddressService,
        private geo: GeocodingService,
        private fb: FormBuilder,
        private http: HttpClient,
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private ApplicationRef1: ApplicationRef,
        // private EventSrv: EventStepsService,
        private srv: EventStepsService,
        private messageService: MessageService,
        private EventPostSrv:EventsService,
        private datePipe: DatePipe
    ) { }
    ngOnDestroy(): void {
     this.sub.forEach(e=>{
        e.unsubscribe();
     })
    }

    formInit(eventId: string[], address: string, city: string, country: string, lat: any, lng: any) {
        this.addressForm = this.fb.group({
            eventId: [eventId],
            address: [address, Validators.required],
            city: [city],
            country: [country, Validators.required],
            lat: [lat, Validators.required],
            lng: [lng, Validators.required]
        });

    }
    getProblem(e){
        this.mapProblem = e;
    }
    getDateOntomeZone(d: string | number) {
        var eventOffset = this.srv.offSet * 60 ;
        var MyDate = new Date(d).setMinutes(new Date(d).getMinutes()  + (eventOffset));
        if(new Date(MyDate).getFullYear()==new Date(Date.now()).getFullYear()){
            var l= this.datePipe.transform(new Date(MyDate), 'EEE MMMM d hh:mm a');
            return l;
        }else{
            var l= this.datePipe.transform(new Date(MyDate), 'EEE MMMM d,y hh:mm a');
            return l;
        }
      }
    ngOnInit() {
        this.loader.showLoader1();
        this.formInit([], ' ', ' ', " ", "", "");

        
       
    // this.EventSrv.getReccuringInstances(this.eventStepsSrv.id).subscribe(x=>{
    //     console.log(x);
    //     var t=x.map(y=>{
    //         if(y.id==this.eventStepsSrv.id){y.name="this instance"}
    //         else(y.name= "instance of:"+y.name )
    //         return y;
    //     })
    //     this.eventInstance=t;
    // })

       var s= this.srv.state$.subscribe(y => {
      //  console.log(y)
        this.formInit([y.id], ' ', ' ', " ", "", "");
        
           
        this.isRecurring=y.isRecurring;
       this.isSreise=y.isColection;
        if(y.isColection){

            this.EventPostSrv.GetSubEvents(y.id).subscribe(x=>{
           //     console.log(x);
                var t=x.map(l=>{
                    if(l.id==y.id){l.name="This event"}
                    
                    return l;
                })
                this.eventInstance=t;
            })
        }
        if(y.isRecurring&&!y.isColection){

            this.EventPostSrv.getReccuringInstances(y.id).subscribe(x=>{
                //console.log(x);
                var t=x.map(l=>{
                    if(l.id==y.id){l.name="This instance"}
                    else(l.name= "Instance of:"+this.getDateOntomeZone(l.name) )
                    return l;
                })
                this.eventInstance=t;
            })
        }

           
        var t= this.AddressSrv.GetAddress(y.id).subscribe(x => {

           
                if (x) {
                    this.formInit([y.id], x.address, x.city, x.country, x.lat, x.lng);
                    this.visible = true
                    try {
                        this.showMap2(x.lat, x.lng, x.address);
                    }
                    catch {
                        this.mapProblem = true;
                    }
                    this.loader.hideLoader1();
                }
               
            })


            this.sub.push(t);
            // this.options = {
            //     center: { lat: 36.890257, lng: 30.707417 },
            //     zoom: 12
            // };

            // this.overlays = [
            //     new google.maps.Marker({ position: { lat: 36.879466, lng: 30.667648 }, title: 'Konyaalti' }),

            // ];

        })
        this.sub.push(s);
        this.loader.hideLoader1();





    }


    handleMapClick(event) {
        // event: MouseEvent of Google Maps api
       // console.log(event);
        this.options = {
            center: { lat: 36.890257, lng: 30.707417 },
            zoom: 5
        };
    }

    handleOverlayClick(event) {
        // event.originalEvent: MouseEvent of Google Maps api
        // event.overlay: Clicked overlay
        // event.map: Map instance
    }

    showMap(value) {
      //  console.log(value);
        const mapProp = {
            center: new google.maps.LatLng(value.geometry.location.lat(), value.geometry.location.lng()),
            zoom: 15,
            mapTypeId: google.maps.MapTypeId.ROADMAP
        };
        // set center
        this.map = new google.maps.Map(this.gmapElement.nativeElement, mapProp);

        // take marker
        const marker = new google.maps.Marker({
            position: new google.maps.LatLng(value.geometry.location.lat(), value.geometry.location.lng()),
            title: value.formatted_address,
            draggable: true
        });
        const geocoder = new google.maps.Geocoder();
        geocoder.geocode({ placeId: value.place_id }, function (results, status) {
            if (status === 'OK') {
              //  console.log(results);
                // this.map.setCenter(results[0].geometry.location);
                // const marker1 = new google.maps.Marker({
                //     map: this.map,
                //     position: results[0].geometry.location
                // });
            } else {
                alert('Geocode was not successful for the following reason: ' + status);
            }
        });
        // set marker
        marker.setMap(this.map);


        this.ssss = marker;
    }


    handleDragEnd(event) {
       // console.log(event)
    }

    showMap2(lat, lng, address) {

        const mapProp = {
            center: new google.maps.LatLng(lat, lng),
            zoom: 15,
            mapTypeId: google.maps.MapTypeId.ROADMAP
        };
        // set center
        this.map = new google.maps.Map(this.gmapElement.nativeElement, mapProp);

        // take marker
        const marker = new google.maps.Marker({
            position: new google.maps.LatLng(lat, lng),
            title: address,
            draggable: true
        });
        const geocoder = new google.maps.Geocoder();
        // geocoder.geocode({placeId: value.place_id}, function(results, status) {
        //     if (status === 'OK') {
        //         console.log(results);
        //         // this.map.setCenter(results[0].geometry.location);
        //         // const marker1 = new google.maps.Marker({
        //         //     map: this.map,
        //         //     position: results[0].geometry.location
        //         // });
        //     } else {
        //         alert('Geocode was not successful for the following reason: ' + status);
        //     }
        // }

        // );
        // set marker
        marker.setMap(this.map);

        this.ssss = marker;

    }



    getAddress(x) {

        this.visible = true;

        console.log(x)
            this.showMap(x);






        this.addressForm.controls['address'].setValue(x.formatted_address);
        this.addressForm.controls['city'].setValue(x.address_components.find(y => y.types.includes('administrative_area_level_1'))?.long_name);
        this.addressForm.controls['country'].setValue(x.address_components.find(y => y.types.includes('country'))?.long_name);
        this.addressForm.controls['lat'].setValue(x.geometry.location.lat());
        this.addressForm.controls['lng'].setValue(x.geometry.location.lng());
        this.ApplicationRef1.tick()
        // this.formInit(x.formatted_address,
        //         x.address_components.find(y => y.types.includes('administrative_area_level_1')).long_name,
        //         x.address_components.find(y => y.types.includes('country')).long_name,
        //         x.geometry.location.lat(),
        //         x.geometry.location.lng()
        //            );




       // console.log(this.addressForm.value)

    }

    submit(x) {
        if (x == 1) {
            this.submitted == true;
            this.addressForm.get('lat').setValue(this.ssss.getPosition().lat())
          var post=  this.AddressSrv.PostAddress(this.addressForm.value).subscribe(x => {
                this.messageService.add({ severity: 'success', summary: 'Saved Successfully' });

                if (x) {
                    this.srv.addressBool = true;
                    this.srv.CalculateProgress()
                }
            }
            );
            this.sub.push(post)
        }
        if (x == 2) {

            this.addressForm.get('lat').setValue(this.ssss.getPosition().lat())
            this.addressForm.get('lng').setValue(this.ssss.getPosition().lng())
         var post=   this.AddressSrv.PostAddress(this.addressForm.value).subscribe(x => {
                this.messageService.add({ severity: 'success', summary: 'Saved Successfully' });
                if (x) {
                    this.srv.addressBool = true;
                    this.srv.CalculateProgress()
                }
            },(er)=>{},()=>{
                if (this.srv.isOnline) {
                    this.router.navigate(['online-page'], { relativeTo: this.activatedRoute.parent });
                }
                else {
                    this.router.navigate(['profile'], { relativeTo: this.activatedRoute.parent });
                }
            });
            this.sub.push(post)
           
        }



    }

}
