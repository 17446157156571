import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { from, observable, Observable, ReplaySubject, Subject } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { AddOnCreateDto, AddOngetDto, AddOnGroupPostDto, AddOnPostDto, addOnsDataTableDto, AddOnsServerDTO, varietyPostDto } from '../Interfaces/addOnsIntreFacesDto';
import { DropDownItem } from '../Interfaces/DropDownItem';
import { environment } from 'src/environments/environment';
import { CopyAddOns } from '../components/pages/events/event-steps/tickets/add-ons/copy-add-ons-group/copy-add-ons-group.component';
@Injectable()
export class AddOnsService {
  private subject: Subject<AddOnsServerDTO> = new ReplaySubject<AddOnsServerDTO>(1);

  get $getSubject(): Observable<AddOnsServerDTO> {
    return this.subject.asObservable();
  }
  //addons$!: Observable<addOnsDataTableDto[]>
  constructor(private http: HttpClient) { }


    postAddOnsGroup(data: AddOnGroupPostDto) {//for post new addonsGroup
        const url = environment.Api+'d/ao/cg/i';
        return this.http.post(url, data,
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            }
        ).subscribe(x => this.getallData(data.eventId));
    }
    UpdateAddOnsGroup(data: AddOnGroupPostDto) {//for post new addonsGroup
      const url = environment.Api+'d/ao/ug/i';
      return this.http.post(url, data,
          {
              headers: {
                  'Content-Type': 'application/json',
                  'Accept': 'application/json'
              }
          }
      ).subscribe(x => this.getallData(data.eventId));
  }

    postAddOns(data: AddOnCreateDto) {//for post new addonsGroup
        const url = environment.Api+'d/ao/c/i';
        return this.http.post(url, data,
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            }
        )
    }



    postCopyAddons(data:CopyAddOns){
      const url = environment.Api+'d/ao/c/g/i';
      return this.http.post(url, data,
          {
              headers: {
                  'Content-Type': 'application/json',
                  'Accept': 'application/json'
              }
          }
      )
    }

    UpdateAddOns(data: AddOnCreateDto) {
      const url = environment.Api+'d/ao/u/i';
      return this.http.post(url, data,
        {
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
            // Authorization: 'my-auth-token'
          }
        }
      )
    }

getEventAddOnsGroup( id:string) :Observable<DropDownItem[]>{
 return  this.http.get<DropDownItem[]>(environment.Api+'d/ao/g/aog/' + id);
}

getAddonsGroupForCopy(id:string){
  return  this.http.get<DropDownItem[]>(environment.Api+'d/ao/g/all/Add/g/'+id);
}

getAddOnsWithoutGroup(id:string):Observable<DropDownItem[]>{
  return  this.http.get<DropDownItem[]>(environment.Api+'d/ao/g/aoS/' + id);
 }


  getallData(id: string,Skip:number=0) {
    this.http.get<AddOnsServerDTO>(environment.Api+'d/ao/g/all/g/' + id+"/"+Skip).subscribe(x=> this.subject.next(x));
  }

  getSingleVariety(id:string):Observable<AddOnCreateDto>{
    return this.http.get<AddOnCreateDto>(environment.Api+'d/ao/g/i/'+id);
  }
  getImage(imageUrl: string):string{
   var  url=environment.Api+imageUrl;

   return url;
  // return  this.http.get(url,{responseType:"blob",headers:{}})//.pipe(switchMap(this.blobToBase64));
  }
  blobToBase64(blob: Blob): Observable<string> {
    return new Observable<string>(observer => {
        const reader = new FileReader();
        reader.onerror = observer.error;
        reader.onabort = observer.error;
        reader.onload = () => observer.next(reader.result as string);
       // reader.onloadend = observer.complete;
        reader.readAsDataURL(blob);

        return {
          unsubscribe: reader.abort
        }
    })
  }

  getSingleAddOns(id:string):Observable<AddOnPostDto>{
    const url= environment.Api+ 'd/ao/g/i/';
    return this.http.get<AddOnPostDto>(url+id);
  }


  removeEntry(id:string){
    const url = environment.Api;
    return this.http.delete(url+'d/ao/remove/'+id)
  }
  removeGroup(id:string){
    const url = environment.Api;
    return this.http.delete(url+'d/ao/remove/g/'+id)
  }

}
