
<form [formGroup]="UserImageForm">
<div class="container">
    <div class="card custom-table-border">
        <!-- <div class="card-header text-center bg-primary rounded-top-23">
            <h1 class="text-white">Event profile</h1>
        </div> -->
        <div class="card-header border-0 text-center bg-primary rounded-top-23 ">
            <h1 class="fw-bold text-white mt-2">{{'User profile photo'|translate}}</h1>
            </div>
        <div class="card-body">
            <div class="card-body p-0">
                <div class="row d-flex justify-content-center">
                    <div class="row p-0">
                        <div class="col-12">
                            <h5>{{'user profile main photo'|translate}}</h5>
                        </div>
                        <div class="col-12">
                            <p>
                               {{'upload your photo'|translate}}
                            </p>
                        </div>
                        <div class="col-12">

                            <div class="card">
                                <div class="col-12 p-0">
                                    <app-my-image-uploader pTooltip="{{'UploadYourphoto'|translate}}"  showDelay="1000"
                                    tooltipPosition="bottom" formControlName="image"
                                                           [aspectRatio]="1 / 1" [quality]="'50'"
                                                           [format]="'jpeg'"></app-my-image-uploader>

                                </div>
                                <small class="p-error" *ngIf="(UserImageForm.get('image')?.touched &&
                               UserImageForm.get('image')?.invalid)||(submitted&&UserImageForm.get('image').invalid)
                                    ">{{'EvntProfImgInpt001ErrMsg'|translate}}</small>
                                
                            </div>
                        </div>
                      
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="row" >
        <div class="col-12 d-flex justify-content-lg-center justify-content-center">
            <div class=" d-flex justify-content-center gap-1 mx-2">
                <button  (click)="GotoProfile()"   class="rounded-pill save-btn  " pTooltip="Save changes" showDelay="1000" tooltipPosition="right">
                   
                    {{'cancelBtn'|translate}}  <span></span>
                </button>
            
            </div>
            <div class=" d-flex justify-content-center gap-1 mx-2">
                <button  (click)="submit(1)" type="submit" class="rounded-pill save-btn " pTooltip="Save changes" showDelay="1000" tooltipPosition="right">
                   
                    {{'SavBtn'|translate}}  <span></span>
                </button>
            
            </div>
        </div>
    </div>
    </div>
</form>        <!-- formControlName="image" -->