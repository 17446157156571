import { DOCUMENT } from '@angular/common';
import { Component, OnInit,Inject } from '@angular/core';
import { FormGroup, NonNullableFormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { DropDownItem } from 'src/app/Interfaces/DropDownItem';
import { EventStepsService } from 'src/app/services/event-steps.service';
import { EventsService } from 'src/app/services/events.service';
import { TicketFormStepsService } from 'src/app/services/ticket-form-steps.service';

@Component({
  selector: 'app-info',
  templateUrl: './info.component.html',
  styleUrls: ['./info.component.scss']
})
export class InfoComponent implements OnInit{
    submitted : boolean=false;
    tickTy: DropDownItem[];
 ticketInfoForm!: FormGroup;


constructor(private fb: NonNullableFormBuilder,
     private attrSrv: EventsService,
     private router: Router,
     private activatedRoute:ActivatedRoute,
     public srv : TicketFormStepsService,public eventStepSrv:EventStepsService,
     @Inject(DOCUMENT) public document: Document,private translate:TranslateService
     ) {
  this.translate.onLangChange.subscribe(x=>{
    this.attrSrv.GetAttributes(1010401).subscribe(x => this.tickTy = x.map(y=>{
      var m:DropDownItem= {name:this.translate.instant(y.name),id:y.Id} ;
      return m;
    }))
  })
}


    ngOnInit(): void {
 
        // this.initialize();
     //   this.srv.inilizeForm('wdw',' this.parentId', "", "", 0, 0, null, 0, 10, 0, 10, null, null, null, 0, null, null, null, true);
        this.attrSrv.GetAttributes(1010401).subscribe(x =>{ console.log(x) ;   this.tickTy = x.map(y=>{
          var m:DropDownItem= {name:this.translate.instant(y.name),id:y.Id} ;
          return m;
        })})

        this.srv.myform.get('ticketType').valueChanges.subscribe(x=>{
            if(x== 101040102){
                this.srv.myform.get('price').setValue(0);

            }
        } )
    }


    sumbit(){
  if(this.validateticketinfo()){
    this.router.navigate( ['date'] ,{relativeTo: this.activatedRoute.parent});

  }else{
    this.submitted=true;
  }



    }
    cancel(){
        this.srv.clear()
        this.router.navigate( ['tickets'] ,{relativeTo: this.activatedRoute.parent.parent.parent});
        // this.router.navigate([
        //     {
        //         outlets: { 'tickets-steps': ['tickets-all'] }
        //     }
        //   ],{relativeTo: this.activatedRoute.parent.parent});
    }
    removeValid(event){

      if(event.value ===101040102){
        this.srv.myform.controls["price"].clearValidators();
        this.srv.myform.controls["price"].updateValueAndValidity();
      }
      else{
      this.srv.myform.controls["price"].setValidators([Validators.required ,Validators.min(0.01)]);

      this.srv.myform.controls["price"].updateValueAndValidity();
      }
    }

    previous(){
        this.router.navigate( ['initial'] ,{relativeTo: this.activatedRoute.parent});

    }
    validateticketinfo(){
    //  console.log(this.srv.myform.get("price"))
        return   this.srv.myform.controls['name'].valid&&this.srv.myform.controls['ticketType'].valid
           &&this.srv.myform.controls['price'].valid  &&this.srv.myform.controls['quantity'].valid
           &&this.srv.myform.controls['description'].valid ;
       }
}
