<div class=" rounded-25 ">
    <div class="container rounded-top-right-25 rounded-top-left-25 bg-modified  p-0">
        <div class="d-flex align-items-center justify-content-center rounded-top-right-25 rounded-top-left-25">

            <!-- style="
            background-image: url('https://img.evbuc.com/https%3A%2F%2Fcdn.evbuc.com%2Fimages%2F283461639%2F61565826027%2F1%2Foriginal.20220512-113603?w=225&auto=format%2Ccompress&q=75&sharp=10&s=f567c3385c47c2a9f57065429cdf4e7a');
             background-repeat: no-repeat;
             background-size: cover;

            aspect-ratio: 2 / 1;" -->

         <div class=" text-center pt-3 bg-white organizer-space shadow-sm rounded-top-right-25 rounded-top-left-25 " >
             <div class="d-flex justify-content-center">
                 <img src="https://img.evbuc.com/https%3A%2F%2Fcdn.evbuc.com%2Fimages%2F283461639%2F61565826027%2F1%2Foriginal.20220512-113603?w=225&auto=format%2Ccompress&q=75&sharp=10&s=f567c3385c47c2a9f57065429cdf4e7a" alt="Organizer Profile Photo"class=" rounded-circle" />

             </div>
             <div class="">
                 <h3 class="text-primary mt-3 fw-bold">
                     <i class="fas fa-user"></i> The Rock Orchestra
                 </h3>
                 <aside class="mx-3" style="font-size:12px;">Lorem ipsum dolor sit amet consectetur adipisicing elit. Aut molestias velit iusto impedit consectetur facere provident ratione repellendus ipsum exercitationem explicabo saepe, facilis voluptatibus ab adipisci dicta sed sapiente vel?</aside>
                 <div class="text-center my-3">
                     <a href="javascript:;" class="follow-btn rounded-pill "> Follow <span></span></a>
                     <a href="javascript:;" class="follow-btn rounded-pill ms-2"> <i class="fas fa-headset"></i> Contact <span></span></a>

                 </div>
                 <div class="d-flex justify-content-center my-3">
                     <p class="mx-3">4.3k <br /> <span><i class="fas fa-users"></i> Followers</span></p>
                     <p class="mx-3">52 <br /> <span><i class="fas fa-calendar-alt"></i> Total events</span></p>
                 </div>

                 <div class="my-3 ">
                     <a href="javascript:;" class="social-btn rounded-circle   my-1 ">
                         <i class="fab fa-facebook"></i><span></span>

                     </a>
                     <a href="javascript:;" class="social-btn rounded-circle  my-1 ms-1 ">
                         <i class="fab fa-whatsapp"></i> <span></span>

                     </a>
                     <a href="javascript:;" class="social-btn rounded-circle my-1 ms-1 ">
                         <i class="fab fa-telegram-plane"></i> <span></span>

                     </a>
                     <a href="javascript:;" class="social-btn rounded-circle  my-1 ms-1 ">
                         <i class="fab fa-instagram"></i> <span></span>

                     </a>
                     <a href="javascript:;" class="social-btn rounded-circle  my-1 ms-1 ">
                         <i class="fab fa-twitter"></i> <span></span>

                     </a>
                     <a href="javascript:;" class="social-btn rounded-circle  my-1 ms-1 ">
                         <i class="fab fa-twitch"></i> <span></span>

                     </a>
                     </div>
                 </div>

             </div>

         </div>
    </div>

    <div class="waves container p-0">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
            <path fill="#FFF3E5" fill-opacity="1" d="M0,96L120,117.3C240,139,480,181,720,192C960,203,1200,181,1320,170.7L1440,160L1440,320L1320,320C1200,320,960,320,720,320C480,320,240,320,120,320L0,320Z"></path>
          </svg>

    </div>
     <section class="container bg-modified rounded-bottom-right-25 rounded-bottom-left-25">


        <div class="row ">
            <div class="">
                <h3 class="mt-5 mb-3 fw-bold">Events:</h3>
                <div class="text center mb-5">
                    <button class="follow-btn rounded-pill">Upcomming (0) <span></span></button>
                    <button class="follow-btn rounded-pill ms-1">Past (0) <span></span></button>
                </div>
            </div>
        <div class="col-lg-4 col-md-6 ">
            <div class="single-courses-box bg-color border-45">
                <div class="courses-image">
                    <a routerLink="/single-courses-1" class="d-block image ">
                        <img class="" src="assets/img/courses/3SUBiaP.png" alt="image">
                    </a>
                    <a routerLink="/" class="fav"><i class="fa-regular fa-heart"></i></a>
                    <div class="price shadow">2000000 ل.س</div>
                </div>
                <div class="courses-content">

                    <h3><a routerLink="/single-courses-1">Een avond met Eckhart Tolle / An Evening with Eckhart
                            Tolle in Rotterdam</a></h3>
                                <div class="">
                                  <span class="mt-2 me-2 category-in-card ">Some Categories adasdsadsadsd</span>
                                  <span class="mt-2 subCategory-in-card ">Some SubCat asdsadsad sadsad</span>
                                </div>


                                <div class="card-footer-font-size  mb-2 mt-2 ">
                                    <div class="mt-3 d-md-flex justify-content-between border-top border-primary" style="color:var(--mainColor);">
                                        <span class="mt-2 fw-bold"><small><i class="far fa-clock "></i> Start</small> 03/06/2021</span><br />
                                        <span  class="mt-2 fw-bold"><small><i class="far fa-clock"></i> End:</small> 03/06/2021</span>
                                    </div>
                                </div>

                                <div class="card-footer-font-size d-block align-items-center ">
                                    <p class=" my-2"><i class="far fa-map-marker-alt"></i> Centrale Bibliotheek • Wassenaar Haag</p>

                                    <!-- <div class="course__authors-box d-flex align-items-center">
                                        <div class="course__authors-box-text">
                                            <h5><i class="fal fa-user"></i> Ahmad asd sa d asd sasa  d sad</h5>
                                        </div>
                                    </div> -->
                                    <div class="course-author d-flex align-items-center">
                                        <span><i class="fal fa-user"></i> James Anderson adsad asdsad sasd sadds sadas</span>
                                    </div>
                                    <p class="card-footer-font-size "><i class="fa-solid fa-users"></i> Followers <span style="color:var(--mainColor);">14500000000</span> </p>
                                </div>
                </div>
            </div>
        </div>






        <div class="col-lg-4 col-md-6 ">
            <div class="single-courses-box bg-color border-45">
                <div class="courses-image">
                    <a routerLink="/single-courses-1" class="d-block image ">
                        <img class="" src="assets/img/courses/f1da5c5a-75dc-4cf5-a7fa-e510452db132.png" alt="image">
                    </a>
                    <a routerLink="/" class="fav"><i class="fa-regular fa-heart"></i></a>
                    <div class="price shadow">$99</div>
                </div>
                <div class="courses-content">

                    <h3><a routerLink="/single-courses-1">Een avond met Eckhart Tolle / An Evening with</a></h3>
                                <div class="">
                                  <span class="mt-2 me-2 category-in-card ">Some Categories </span>
                                  <span class="mt-2 subCategory-in-card ">Some SubCat </span>
                                </div>


                                <div class="card-footer-font-size  mb-2 mt-2 ">
                                    <div class="mt-3 d-md-flex justify-content-between border-top border-primary" style="color:var(--mainColor);">
                                        <span  class="mt-2 fw-bold"><small><i class="far fa-clock "></i> Start</small> 03/06/2021</span><br />
                                        <span  class="mt-2 fw-bold"><small><i class="far fa-clock"></i> End:</small> 03/06/2021</span>
                                    </div>
                                </div>

                                <div class="card-footer-font-size d-block align-items-center ">
                                    <p class=" my-2"><i class="far fa-map-marker-alt"></i> Centrale Bibliotheek • Wassenaar Haag</p>

                                    <!-- <div class="course__authors-box d-flex align-items-center">
                                        <div class="course__authors-box-text">
                                            <h5><i class="fal fa-user"></i> Ahmad asd sa d asd sasa  d sad</h5>
                                        </div>
                                    </div> -->
                                    <div class="course-author d-flex align-items-center">
                                        <span> <i class="fal fa-user"></i> James Anderson</span>
                                    </div>
                                    <p class="card-footer-font-size"><i class="fa-solid fa-users"></i> Followers <span style="color:var(--mainColor);">145k</span> </p>
                                </div>
                </div>
            </div>
        </div>        <div class="col-lg-4 col-md-6 ">
            <div class="single-courses-box bg-color border-45">
                <div class="courses-image">
                    <a routerLink="/single-courses-1" class="d-block image ">
                        <img class="" src="assets/img/courses/3SUBiaP.png" alt="image">
                    </a>
                    <a routerLink="/" class="fav"><i class="fa-regular fa-heart"></i></a>
                    <div class="price shadow">2000000 ل.س</div>
                </div>
                <div class="courses-content">

                    <h3><a routerLink="/single-courses-1">Een avond met Eckhart Tolle / An Evening with Eckhart
                            Tolle in Rotterdam</a></h3>
                                <div class="">
                                  <span class="mt-2 me-2 category-in-card ">Some Categories adasdsadsadsd</span>
                                  <span class="mt-2 subCategory-in-card ">Some SubCat asdsadsad sadsad</span>
                                </div>


                                <div class="card-footer-font-size  mb-2 mt-2 ">
                                    <div class="mt-3 d-md-flex justify-content-between border-top border-primary" style="color:var(--mainColor);">
                                        <span class="mt-2 fw-bold"><small><i class="far fa-clock "></i> Start</small> 03/06/2021</span><br />
                                        <span  class="mt-2 fw-bold"><small><i class="far fa-clock"></i> End:</small> 03/06/2021</span>
                                    </div>
                                </div>

                                <div class="card-footer-font-size d-block align-items-center ">
                                    <p class=" my-2"><i class="far fa-map-marker-alt"></i> Centrale Bibliotheek • Wassenaar Haag</p>

                                    <!-- <div class="course__authors-box d-flex align-items-center">
                                        <div class="course__authors-box-text">
                                            <h5><i class="fal fa-user"></i> Ahmad asd sa d asd sasa  d sad</h5>
                                        </div>
                                    </div> -->
                                    <div class="course-author d-flex align-items-center">
                                        <span><i class="fal fa-user"></i> James Anderson adsad asdsad sasd sadds sadas</span>
                                    </div>
                                    <p class="card-footer-font-size "><i class="fa-solid fa-users"></i> Followers <span style="color:var(--mainColor);">14500000000</span> </p>
                                </div>
                </div>
            </div>
        </div>






        <div class="col-lg-4 col-md-6 ">
            <div class="single-courses-box bg-color border-45">
                <div class="courses-image">
                    <a routerLink="/single-courses-1" class="d-block image ">
                        <img class="" src="assets/img/courses/f1da5c5a-75dc-4cf5-a7fa-e510452db132.png" alt="image">
                    </a>
                    <a routerLink="/" class="fav"><i class="fa-regular fa-heart"></i></a>
                    <div class="price shadow">$99</div>
                </div>
                <div class="courses-content">

                    <h3><a routerLink="/single-courses-1">Een avond met Eckhart Tolle / An Evening with</a></h3>
                                <div class="">
                                  <span class="mt-2 me-2 category-in-card ">Some Categories </span>
                                  <span class="mt-2 subCategory-in-card ">Some SubCat </span>
                                </div>


                                <div class="card-footer-font-size  mb-2 mt-2 ">
                                    <div class="mt-3 d-md-flex justify-content-between border-top border-primary" style="color:var(--mainColor);">
                                        <span  class="mt-2 fw-bold"><small><i class="far fa-clock "></i> Start</small> 03/06/2021</span><br />
                                        <span  class="mt-2 fw-bold"><small><i class="far fa-clock"></i> End:</small> 03/06/2021</span>
                                    </div>
                                </div>

                                <div class="card-footer-font-size d-block align-items-center ">
                                    <p class=" my-2"><i class="far fa-map-marker-alt"></i> Centrale Bibliotheek • Wassenaar Haag</p>

                                    <!-- <div class="course__authors-box d-flex align-items-center">
                                        <div class="course__authors-box-text">
                                            <h5><i class="fal fa-user"></i> Ahmad asd sa d asd sasa  d sad</h5>
                                        </div>
                                    </div> -->
                                    <div class="course-author d-flex align-items-center">
                                        <span> <i class="fal fa-user"></i> James Anderson</span>
                                    </div>
                                    <p class="card-footer-font-size"><i class="fa-solid fa-users"></i> Followers <span style="color:var(--mainColor);">145k</span> </p>
                                </div>
                </div>
            </div>
        </div>        <div class="col-lg-4 col-md-6 ">
            <div class="single-courses-box bg-color border-45">
                <div class="courses-image">
                    <a routerLink="/single-courses-1" class="d-block image ">
                        <img class="" src="assets/img/courses/3SUBiaP.png" alt="image">
                    </a>
                    <a routerLink="/" class="fav"><i class="fa-regular fa-heart"></i></a>
                    <div class="price shadow">2000000 ل.س</div>
                </div>
                <div class="courses-content">

                    <h3><a routerLink="/single-courses-1">Een avond met Eckhart Tolle / An Evening with Eckhart
                            Tolle in Rotterdam</a></h3>
                                <div class="">
                                  <span class="mt-2 me-2 category-in-card ">Some Categories adasdsadsadsd</span>
                                  <span class="mt-2 subCategory-in-card ">Some SubCat asdsadsad sadsad</span>
                                </div>


                                <div class="card-footer-font-size  mb-2 mt-2 ">
                                    <div class="mt-3 d-md-flex justify-content-between border-top border-primary" style="color:var(--mainColor);">
                                        <span class="mt-2 fw-bold"><small><i class="far fa-clock "></i> Start</small> 03/06/2021</span><br />
                                        <span  class="mt-2 fw-bold"><small><i class="far fa-clock"></i> End:</small> 03/06/2021</span>
                                    </div>
                                </div>

                                <div class="card-footer-font-size d-block align-items-center ">
                                    <p class=" my-2"><i class="far fa-map-marker-alt"></i> Centrale Bibliotheek • Wassenaar Haag</p>

                                    <!-- <div class="course__authors-box d-flex align-items-center">
                                        <div class="course__authors-box-text">
                                            <h5><i class="fal fa-user"></i> Ahmad asd sa d asd sasa  d sad</h5>
                                        </div>
                                    </div> -->
                                    <div class="course-author d-flex align-items-center">
                                        <span><i class="fal fa-user"></i> James Anderson adsad asdsad sasd sadds sadas</span>
                                    </div>
                                    <p class="card-footer-font-size "><i class="fa-solid fa-users"></i> Followers <span style="color:var(--mainColor);">14500000000</span> </p>
                                </div>
                </div>
            </div>
        </div>






        <div class="col-lg-4 col-md-6 ">
            <div class="single-courses-box bg-color border-45">
                <div class="courses-image">
                    <a routerLink="/single-courses-1" class="d-block image ">
                        <img class="" src="assets/img/courses/f1da5c5a-75dc-4cf5-a7fa-e510452db132.png" alt="image">
                    </a>
                    <a routerLink="/" class="fav"><i class="fa-regular fa-heart"></i></a>
                    <div class="price shadow">$99</div>
                </div>
                <div class="courses-content">

                    <h3><a routerLink="/single-courses-1">Een avond met Eckhart Tolle / An Evening with</a></h3>
                                <div class="">
                                  <span class="mt-2 me-2 category-in-card ">Some Categories </span>
                                  <span class="mt-2 subCategory-in-card ">Some SubCat </span>
                                </div>


                                <div class="card-footer-font-size  mb-2 mt-2 ">
                                    <div class="mt-3 d-md-flex justify-content-between border-top border-primary" style="color:var(--mainColor);">
                                        <span  class="mt-2 fw-bold"><small><i class="far fa-clock "></i> Start</small> 03/06/2021</span><br />
                                        <span  class="mt-2 fw-bold"><small><i class="far fa-clock"></i> End:</small> 03/06/2021</span>
                                    </div>
                                </div>

                                <div class="card-footer-font-size d-block align-items-center ">
                                    <p class=" my-2"><i class="far fa-map-marker-alt"></i> Centrale Bibliotheek • Wassenaar Haag</p>

                                    <!-- <div class="course__authors-box d-flex align-items-center">
                                        <div class="course__authors-box-text">
                                            <h5><i class="fal fa-user"></i> Ahmad asd sa d asd sasa  d sad</h5>
                                        </div>
                                    </div> -->
                                    <div class="course-author d-flex align-items-center">
                                        <span> <i class="fal fa-user"></i> James Anderson</span>
                                    </div>
                                    <p class="card-footer-font-size"><i class="fa-solid fa-users"></i> Followers <span style="color:var(--mainColor);">145k</span> </p>
                                </div>
                </div>
            </div>
        </div>


        </div>
    </section>
</div>

