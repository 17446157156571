import { DOCUMENT, DatePipe } from '@angular/common';
import { Component, OnInit, Inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AddOnsStepsService } from 'src/app/services/add-ons-steps.service';
import { EventStepsService } from 'src/app/services/event-steps.service';

@Component({
    selector: 'app-addon-sales-date',
    templateUrl: './addon-sales-date.component.html',
    styleUrls: ['./addon-sales-date.component.scss']
})
export class AddonSalesDateComponent implements OnInit {
    submitted: boolean = false;
    minValue!: Date;
    MaxValue!: Date;
    getDateForCalender(e) {
        if (e) {
            return new Date(e).toLocaleTimeString()
        }
        return
    }
    maxValue2!: Date
    constructor(private router: Router, private datePipe: DatePipe
        , private activatedRoute: ActivatedRoute, public srv: AddOnsStepsService, private EventSrv: EventStepsService, @Inject(DOCUMENT) public document: Document) {

    }
    getDateBoolean(date) {
        var d = new Date(date.year, date.month, date.day)




        return d.getFullYear() === this.maxValue2.getFullYear() &&
            d.getMonth() === this.maxValue2.getMonth() &&
            d.getDate() === this.maxValue2.getDate()

    }

    setEndDateMin(e) {

        var t = e;
        var lm = new Date(t)
        //this.MaxValue=new Date(new Date(e).setMinutes(new Date(e).getMinutes()+15));
        this.MaxValue = new Date(lm.setMinutes(lm.getMinutes() + 15));
        this.srv.AddonsForm.controls['endDate'].setValue(this.datePipe.transform(this.MaxValue, 'MM/dd/yyyy hh:mm a'));

    }
    ngOnInit(): void {
        //this.minValue=new Date(Date.now());
        this.minValue = new Date(new Date(new Date(Date.now()).setHours(0)).setMinutes(0));
        this.EventSrv.state$.subscribe(x => {
            this.maxValue2 = new Date(x.endTime)
        })

    }
    cancel() {
        this.srv.clear();
        this.router.navigate(['Add-ons'], { relativeTo: this.activatedRoute.parent.parent.parent });
    }
    submit() {
        if (this.validateAddOnsDates()) {
            this.router.navigate(['settings'], { relativeTo: this.activatedRoute.parent });
        } else {
            this.submitted = true;
        }

    }
    previous() {
        this.router.navigate(['info'], { relativeTo: this.activatedRoute.parent });
    }
    validateAddOnsDates() {
        return this.srv.AddonsForm.controls['startDate'].valid && this.srv.AddonsForm.controls['endDate'].valid;
    }
}
