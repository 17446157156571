<form [formGroup]="Srv.AddonsGroupForm"  (submit)="submit()" autocomplete="off">

    <div class="container">

        <div class="row mb-2">
            <div class="p-input-filled col-sm-6 col-md-6  mb-2">
                <span class="p-float-label">
                    <input pTooltip="{{'AddOnGrpFrmGrpInfoInpt001Pop'|translate}}" showDelay="1000" 
                    tooltipPosition="bottom" type="text" pInputText id="TicketName"
                     formControlName="name" formControlName="name" class="form-control"
                    [ngClass]="{
                        'ng-invalid ng-dirty':
                        (Srv.AddonsGroupForm.get('name')?.touched &&
                        Srv.AddonsGroupForm.get('name')?.invalid)||(submitted&& Srv.AddonsGroupForm.get('name').invalid)
                    }">
                    <label class="form-label font-size-14" for="TicketName">{{'AddOnGrpFrmGrpInfoInpt001'|translate}}</label>
                </span>
                <small class="p-error" *ngIf="(Srv.AddonsGroupForm.get('name')?.touched &&
                Srv.AddonsGroupForm.get('name')?.invalid)||(submitted&& Srv.AddonsGroupForm.get('name').invalid)
                    ">{{'AddOnGrpFrmGrpInfoInpt001ErrMsg'|translate}}</small>
            </div>
            <div class="p-input-filled col-sm-6 col-md-6   mb-2">
                <span class="p-float-label">
                    <input pTooltip="{{'AddOnGrpFrmGrpInfoInpt002Pop'|translate}} " showDelay="1000" tooltipPosition="bottom" type="text" pInputText id="groupCat" formControlName="categoryId"  class="form-control"
                    [ngClass]="{
                        'ng-invalid ng-dirty':
                        (Srv.AddonsGroupForm.get('categoryId')?.touched &&
                        Srv.AddonsGroupForm.get('categoryId')?.invalid)||(submitted&& Srv.AddonsGroupForm.get('categoryId').invalid)
                    }">
                    <label class="form-label font-size-14" for="groupCat">{{'AddOnGrpFrmGrpInfoInpt002'|translate}}</label>
                </span>
                <!-- <span class="p-float-label">
                    <p-dropdown pTooltip="Select this group category" showDelay="1000"
                    [ngClass]="{
                        'ng-invalid ng-dirty':
                        (Srv.AddonsGroupForm.get('categoryId')?.touched &&
                        Srv.AddonsGroupForm.get('categoryId')?.invalid)||(submitted&& Srv.AddonsGroupForm.get('categoryId').invalid)
                    }"
                     tooltipPosition="bottom" inputId="RecTicketConf" [styleClass]="'w-100'" [autoDisplayFirst]="false" [options]="cat"
                    optionLabel="name" optionValue="Id" formControlName="categoryId"  ></p-dropdown>
                    <label class="form-label font-size-14" for="withoutgrouping">Group category</label>
                </span> -->
                <small class="p-error" *ngIf="(Srv.AddonsGroupForm.get('categoryId')?.touched &&
                Srv.AddonsGroupForm.get('categoryId')?.invalid)||(submitted&& Srv.AddonsGroupForm.get('categoryId').invalid)
                    ">{{'AddOnGrpFrmGrpInfoInpt002ErrMsg'|translate}}</small>
            </div>
        </div>
        <div class="row mb-2"  [ngClass]="{
            'ng-invalid ng-dirty':
            (Srv.AddonsGroupForm.get('image')?.touched &&
            Srv.AddonsGroupForm.get('image')?.invalid)||(submitted&& Srv.AddonsGroupForm.get('image').invalid)
        }">
        <app-my-image-uploader pTooltip="{{'AddOnGrpFrmGrpInfoInpt003Pop'|translate}}" showDelay="1000" tooltipPosition="bottom" formControlName="image"
        [aspectRatio]="1 / 1" [quality]="'50'"
        [format]="'jpeg'"></app-my-image-uploader>
        </div>
        <small class="p-error" *ngIf="(Srv.AddonsGroupForm.get('image')?.touched &&
        Srv.AddonsGroupForm.get('image')?.invalid)||(submitted&& Srv.AddonsGroupForm.get('image').invalid)
            ">{{'AddOnGrpFrmGrpInfoInpt003ErrMsg'|translate}}</small>
    </div>
    <div class="col-12 d-flex  justify-content-center">
        <div class="banner-btn rounded-pill ">
            <button pTooltip="Cancel and dismiss changes"  type= "button" showDelay="1000" tooltipPosition="left"
            class="rounded-pill save-btn" (click)="cancel()">{{'CanclBtn'|translate}} <span></span></button>
            <button  pTooltip="Save and go to next step " showDelay="1000" tooltipPosition="right"
             class="rounded-pill next-btn ms-1">{{'NextBtn'|translate}} <i class="pi pi-angle-right mt-1 "></i><span></span></button>
        </div>
    </div>



</form>
