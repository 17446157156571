<div class="events-area ">
    <div #myDiv class="container">
        <div class="card custom-table-border">
            <div class="card-header border-0 text-center bg-primary rounded-top-23 ">
                <h1 class="fw-bold text-white mt-2">{{'SubEvntTblTitl'|translate}}</h1>
            </div>
            <!--
            <div *ngIf="loader.show1" @fadeOut class="d-flex justify-content-center align-items-center" style="border-radius: 25px;top : 0;position: absolute;z-index: 1050;background-color:#fff;width: 100%;height: -webkit-fill-available;right: 0;bottom: 0;left: 0;">


            </div> -->

            <p-toast></p-toast>

            <div class="mt-4">
                <div>

                    <div class="d-sm-flex d-block align-items-center justify-content-sm-between text-center">

                        <div class="  ms-md-4 mb-sm-0  mb-2 ">
                            <button *ngIf="auth.securityObject.checkClaim(10105030101)" pButton pRipple
                                class="rounded-pill add-new-btn mr-2" (click)="openNew()"><i
                                    class="fa-solid fa-plus"></i> {{'SubEvntTblTh001'|translate}} <span></span></button>
                        </div>
                        <!-- <div class="  ms-md-4 mb-sm-0  mb-2 ">
                            <button pButton pRipple class=" p-button-outlined rounded-pill  mr-2"
                        (click)="chang(false)">
                        <i class="fa-solid fa-list mx-2"></i> List<span></span></button>
                    <button pButton pRipple class="p-button-outlined rounded-pill  mr-2" (click)="chang(true)">
                        <i class="fa-sharp fa-solid fa-calendar-clock mx-2"></i> Calendar <span></span></button>
                        </div> -->



                        <div class="p-input-filled me-md-3 ">
                            <span class="p-input-icon-left">
                                <i class="pi pi-search"></i>
                                <input pInputText type="text" class="form-control"
                                    (input)="dt.filterGlobal($event.target.value, 'contains')"
                                    placeholder="Search..." />
                            </span>
                        </div>
                    </div>
                </div>

                <div>
                    <p-table #dt responsiveLayout="stack" [breakpoint]="'991px'" [value]="events" [rows]="5"
                        [paginator]="TotalRecord>5" [lazy]="true" [totalRecords]="TotalRecord"
                        (onLazyLoad)="getEvent($event)" [globalFilterFields]="['title']" [rowHover]="true" dataKey="id"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                        [showCurrentPageReport]="true" class="">
                        <ng-template pTemplate="header" class="">
                            <!-- <tr>
                                <th ></th>
                                <th class="fw-bold text-center" style="min-width: 9rem;">Image</th>
                                <th pSortableColumn="title"  class="fw-bold text-center">Title
                                    <p-sortIcon field="titel"></p-sortIcon></th>
                                <th pSortableColumn="totalQty"  class="fw-bold text-center">
                                    Quantity <p-sortIcon field="totalQty"></p-sortIcon></th>
                                <th pSortableColumn="gross" class="fw-bold text-center">Gross <p-sortIcon
                                        field="gross"></p-sortIcon></th>
                                <th  class="fw-bold text-center">Status </th>
                                <th class="fw-bold text-center" style="min-width: 9rem;"> Action</th>
                            </tr> -->
                            <tr>
                                <th class="fw-bold text-center" style="min-width: 9rem;">{{'SubEvntTblTh001'|translate}}
                                </th>
                                <!-- <th class="d-lg-none d-block"></th> -->
                                <th pSortableColumn="totalQty" class="fw-bold text-center">
                                    {{'SubEvntTblTh002'|translate}} <p-sortIcon field="totalQty"></p-sortIcon></th>
                                <th pSortableColumn="gross" class="fw-bold text-center">{{'SubEvntTblTh003'|translate}}
                                    <p-sortIcon field="gross"></p-sortIcon></th>
                                <th class="fw-bold text-center">{{'SubEvntTblTh004'|translate}} </th>
                                <th class="fw-bold text-center" style="min-width: 9rem;">
                                    {{'SubEvntTblTh005'|translate}}</th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-event let-expanded="expanded">
                          
                            <tr>
                                <td class="text-md-start  padding-custom text-end Image-cutom">
                                    <div class="d-flex">
                                        <div class="static-width">
                                            <!-- <span class=" text-center row align-items-center d-lg-flex d-none fw-bold me-md-2 ">
                                                <h5 class="text-primary col-12">{{getDateOntomeZoneformat(event.startDate,'month')}}</h5>
                                                <h5 class=" col-12">{{getDateOntomeZoneformat(event.startDate,'day')}}</h5>
                                            </span> -->
                                            <div class="d-lg-flex d-block align-items-center me-lg-2">
                                                <img [src]="event.photoLink==null?'assets/img/courses/3SUBiaP.png':url+event.photoLink"
                                                    [alt]="event.title" width="100"
                                                    class="shadow-4 min-size rounded-2" />
                                            </div>
                                        </div>

                                        <div class="d-lg-block d-none">
                                            <span class="text-lenght d-lg-block"> {{event.isRecurring?event.title+"
                                                "+getrecDate(event.startDate):event.title}}</span>
                                            <span class="text-secondary d-lg-block">{{event.address}}</span>
                                            <span
                                                class="text-secondary">{{getDateOntomeZoneformat(event.startDate,'full')}}</span>
                                            <span>
                                                <button *ngIf="event.isSeries" type="button" pButton pRipple
                                                    class="p-button-text p-button-rounded d-lg-block d-none"
                                                    [icon]="'fa-sharp fa-light fa-sitemap'">
                                                    Event series</button>
                                                <button *ngIf="event.isRecurring" type="button" pButton pRipple
                                                    [pRowToggler]="event"
                                                    class="p-button-text p-button-rounded d-lg-block d-none"
                                                    [icon]="expanded ? 'fa-regular fa-chevron-down' : 'fa-solid fa-layer-group ticket fa-rotate-90'"
                                                    (click)="getinstanse(event.id)">Reccuring event</button>
                                            </span>
                                        </div>
                                    </div>
                                </td>
                                <td class="text-md-center padding-custom text-lenght-larg text-end d-lg-none d-flex ">
                                    <span class="headers-cutom text-start fw-bold">{{'Title'|translate}}</span><span
                                        class="text-lenght"> {{event.isRecurring?event.title+"
                                        "+getrecDate(event.startDate):event.title}}</span>
                                </td>
                                <td class="text-md-center padding-custom text-end d-lg-none d-flex ">
                                    <span class="headers-cutom text-start fw-bold ">{{'Date'|translate}}</span>
                                    <span
                                        class="text-lenght text-secondary">{{getDateOntomeZoneformat(event.startDate,'full')}}</span>
                                </td>
                                <td class="text-md-center padding-custom text-end d-lg-none d-flex ">
                                    <span class="headers-cutom text-start fw-bold ">{{'Location'|translate}}</span>
                                    <span class="text-lenght text-secondary"> {{event.address}}</span>
                                </td>
                                <td class="text-md-center padding-custom text-end"><span
                                        class="headers-cutom text-start fw-bold ">{{'EvntTblTh002'|translate}}</span>{{event.soldTickets}}/{{event.totalQty}}
                                </td>
                                <td class="text-md-center padding-custom text-end"><span
                                        class="headers-cutom text-start fw-bold ">{{'EvntTblTh003'|translate}}</span>{{event.gross+""+event.curSymbol}}
                                </td>
                                <!-- <td><p-rating [ngModel]="event.gross" [readonly]="true" [cancel]="false"></p-rating></td> -->
                                <!-- <td><span [class]="'product-badge status-' + (product.inventoryStatus ? product.inventoryStatus.toLowerCase() : '')">{{product.inventoryStatus}}</span></td> -->
                                <td class="text-md-center padding-custom text-end"><span
                                        class="headers-cutom text-start fw-bold ">{{'EvntTblTh004'|translate}}</span><span [ngClass]="{
                                    'text-bg-green': event.isPublished , 'text-bg-dark' : !event.isPublished
                                }" class="badge rounded-pill"> {{getstatus(event.isPublished)|translate}}</span></td>

                                <td class="text-md-center">
                                    <button *ngIf="event.eventInstance != null" type="button" pButton pRipple
                                        [pRowToggler]="event" class="p-button-text p-button-rounded d-lg-none d-block"
                                        [icon]="expanded ? 'fa-regular fa-chevron-down' : 'fa-regular fa-ticket fa-rotate-90'">{{'Event series'|translate}}</button>
                                    <p-overlayPanel #op>
                                        <button pButton pRipple icon="pi pi-pencil"
                                            class="p-button-rounded p-button-primary m-1"
                                            *ngIf="auth.securityObject.checkClaim(10105030101)"
                                            (click)="editProduct(event)"></button><br>
                                        <button *ngIf="event.isPublished" pButton pRipple icon="pi pi-eye"
                                            class="p-button-rounded p-button-primary m-1"
                                            (click)="preview(event.id)"></button><br *ngIf="event.isPublished">
                                        <p-toast></p-toast>
                                        <p-confirmPopup></p-confirmPopup>
                                        <button pButton pRipple icon="pi pi-trash"
                                            class="p-button-rounded p-button-danger m-1"
                                            (click)="remove($event,event.id)"></button>
                                    </p-overlayPanel>

                                    <button pButton pRipple (click)="op.toggle($event)" icon="far fa-ellipsis-v"
                                        class="p-button-rounded p-button-primary m-1"></button>

                                </td>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="rowexpansion" let-event>
                          
                            <tr>
                                <td colspan="12" class="padding-sum-small">

                                    <p-table responsiveLayout="stack" [breakpoint]="'991px'" [value]="Subevents"
                                        dataKey="id" class="table-responsive" [rows]="5" [paginator]="SubTotalRecourd>5"
                                        [lazy]="true" [totalRecords]="SubTotalRecourd"
                                        (onLazyLoad)="getinstanse(event.id,$event)">

                                        <ng-template pTemplate="body" let-ev>
                            <tr>
                                <td class="text-md-center padding-custom text-end Image-cutom">
                                    <div class="d-flex">
                                        <div class="static-width">
                                            <span
                                                class=" text-center row align-items-center d-lg-flex d-none fw-bold me-md-2 ">
                                                <h5 class="text-primary col-12">
                                                    {{getDateOntomeZoneformat(ev.startDate,'month')}}</h5>
                                                <h5 class=" col-12">{{getDateOntomeZoneformat(ev.startDate,'day')}}</h5>
                                            </span>
                                            <div class="d-lg-flex d-block align-items-center me-md-2">
                                                <img [src]="ev.photoLink==null?'assets/img/courses/3SUBiaP.png':url+ev.photoLink"
                                                    [alt]="ev.title" width="100"
                                                    class="shadow-4 min-size-sub rounded-2" />
                                            </div>
                                        </div>

                                        <div class="d-lg-block d-none text-start">
                                            <span class="text-lenght d-lg-block"> {{ev.isRecurring?ev.title+"
                                                "+getrecDate(ev.startDate):ev.title}}</span>
                                            <span class="text-secondary d-lg-block">{{ev.address}}</span>
                                            <span
                                                class="text-secondary">{{getDateOntomeZoneformat(ev.startDate,'full')}}</span>
                                        </div>
                                    </div>
                                </td>
                                <td class="text-md-center padding-custom text-lenght-larg text-end d-lg-none d-flex ">
                                    <span class="headers-cutom text-start fw-bold">{{'Title'|translate}}</span><span
                                        class="text-lenght"> {{ev.isRecurring?ev.title+"
                                        "+getrecDate(ev.startDate):ev.title}}</span>
                                </td>

                                <td class="text-md-center padding-custom text-end d-lg-none d-flex ">
                                    <span class="headers-cutom text-start fw-bold ">{{'Date'|translate}}</span>
                                    <span
                                        class="text-lenght text-secondary">{{getDateOntomeZoneformat(ev.startDate,'full')}}</span>
                                </td>
                                <td class="text-md-center padding-custom text-end d-lg-none d-flex ">
                                    <span class="headers-cutom text-start fw-bold ">{{'Location'|translate}}</span>
                                    <span class="text-lenght text-secondary"> {{ev.address}}</span>
                                </td>
                                <td class="text-md-center padding-custom text-end"><span
                                        class="headers-cutom text-start fw-bold ">{{'EvntTblTh002'|translate}}</span>{{ev.soldTickets}}/{{ev.totalQty}}
                                </td>
                                <td class="text-md-center padding-custom text-end"><span
                                        class="headers-cutom text-start fw-bold ">{{'EvntTblTh003'|translate}}</span>{{ev.gross+""+ev.curSymbol}}
                                </td>

                                <td class="text-md-center padding-custom text-end"><span
                                        class="headers-cutom text-start fw-bold ">{{'EvntTblTh004'|translate}}</span><span [ngClass]="{
                                                        'text-bg-green': ev.isPublished , 'text-bg-dark' : !ev.isPublished
                                                    }" class="badge rounded-pill"> {{getstatus(ev.isPublished)|translate}}</span>
                                </td>
                                <td class="text-center">
                                    <div [class.action-custom]="document.documentElement.dir === 'ltr'"
                                        [class.action-custom-rtl]="document.documentElement.dir === 'rtl'">
                                        <button pButton pRipple icon="pi pi-pencil"
                                            class="p-button-rounded p-button-primary mr-2"
                                            *ngIf="auth.securityObject.checkClaim(10105030101)"
                                            (click)="editProduct(ev)"></button>
                                        <button pButton pRipple icon="pi pi-trash"
                                            class="p-button-rounded p-button-danger"
                                            (click)="remove($event,ev.id)"></button>
                                    </div>
                                </td>
                            </tr>
                        </ng-template>
                    
                        <ng-template pTemplate="emptymessage">
                            <tr>
                                <td colspan="6">{{'EmpMsgSubEvent'|translate}} </td>
                            </tr>
                        </ng-template>

                    </p-table>

                    </td>
                    </tr>


                    </ng-template>
                    <ng-template pTemplate="summary">
                        <div class="flex align-items-center justify-content-between">
                            {{'SubEvntTblFooter01'|translate}}{{allEventForPrev ? allEventForPrev.length : 0
                            }}{{'SubEvntTblFooter02'|translate}}
                        </div>
                    </ng-template>
                    </p-table>

                </div>
            </div>

        </div>
    </div>
</div>
