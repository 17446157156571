
<div class="container">
    <div class="card custom-table-border">
        <div class="card-header border-0 text-center bg-primary rounded-top-23 ">
            <h1 class="fw-bold text-white mt-2">{{'AddOnTblTitl'|translate}}</h1>
            </div>
        <div class="card-body">
            <div class="card-body p-0">
                <div class="container">
                    <div class="d-flex justify-content-between align-items-center">
                        <h2 class="fw-bold">{{pageTitle|translate}}</h2>

                <div>
                    <h6 class="fw-bold  ">{{'Help'|translate}} <span class="text-primary help-btn" (click)="showHelpStepsModal()" ><i class="fa-regular fa-circle-question"></i></span></h6>
                    <p-dialog header="How to start" [(visible)]="helpStepsModal" [modal]="true" class="bg-dark"
                        [draggable]="false" [resizable]="false">
                        <p class="m-0"><b class="text-primary">{{'Step1'|translate}}  </b> {{'addonsformstep1tooltip'|translate}} </p>
                        <p class="m-0"><b class="text-primary">{{'Step2'|translate}} </b> {{'addonsformstep2tooltip'|translate}} </p>
                        <p class="m-0"><b class="text-primary">{{'Step3'|translate}}  </b>  {{'addonsformstep3tooltip'|translate}}</p>
                        <p class="m-0"><b class="text-primary">{{'Step4'|translate}}  </b>  {{'addonsformstep4tooltip'|translate}}</p>
                       
                            <ng-template pTemplate="footer">
                            <p-button icon="pi pi-check" (click)="helpStepsModal=false" label="Ok" styleClass="p-button-text"></p-button>
                            </ng-template>
                    </p-dialog>
                </div>

                    </div>
                    <div class="row">
                        <div class="col-12 p-0">
                            <p-steps [model]="items" [readonly]="true"></p-steps>

                        </div>
                        <div class="col-12 p-0">
                            <router-outlet ></router-outlet>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>

</div>

