import { transition, style, animate, trigger } from '@angular/animations';
import { DOCUMENT } from '@angular/common';
import { Component, OnDestroy, OnInit ,Inject} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationService, LazyLoadEvent, MessageService } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { Subscription } from 'rxjs';
import { addOnsDataTableDto } from 'src/app/Interfaces/addOnsIntreFacesDto';
import { AddOnGroupStepsService } from 'src/app/services/add-on-group-steps.service';
import { AddOnsStepsService } from 'src/app/services/add-ons-steps.service';

import { AddOnsService } from 'src/app/services/add-ons.service';
import { EventStepsService } from 'src/app/services/event-steps.service';
import { LoaderService } from 'src/app/services/loader.service';
import { environment } from 'src/environments/environment';

const leaveTrans = transition(':leave', [
    style({
        opacity: 1
    }),
    animate('1s ease-out', style({
        opacity: 0
    }))
])



const fadeOut = trigger('fadeOut', [
    leaveTrans
]);


@Component({
  selector: 'app-add-ons',
  templateUrl: './add-ons.component.html',
  styleUrls: ['./add-ons.component.scss'],
  animations : [fadeOut]
})
export class AddOnsComponent implements OnInit , OnDestroy{
    sub: Subscription;
  url = environment.Api
  datacount:number=0;
  endSub:boolean=false;
  data: addOnsDataTableDto[] = [];
  parentId: string;
  canaddGroup:boolean=false;
  cuurency:string="$";
  totalRecourd:number=0;
  constructor(public loader : LoaderService,
    public addServ: AddOnsService,
     private modalServ: DialogService, 
     private activerout: ActivatedRoute, 
     private router: Router,
    private addOnsSrv: AddOnsStepsService,
     private addOnsGroupSrv: AddOnGroupStepsService 
     ,private EventSrv:EventStepsService  ,public conSrv: ConfirmationService,
     public messageService : MessageService,
     @Inject(DOCUMENT) public document: Document) {

  }

  ngOnDestroy(): void {
    this.sub.unsubscribe()
  }
  ngOnInit(): void {
    this.loader.showLoader1();
    this.sub= this.EventSrv.state$.subscribe(x=>{
      if(!this.EventSrv.TimingBool){
        if(this.EventSrv.isRecurring){
       //   console.log(this.router.routerState.snapshot.url)
          this.router.navigate(['addDate'],
          { queryParams: { returnUrl: this.router.routerState.snapshot.url.replace('Add-ons' , 'calendar') } });
        }
        else{
       //   console.log(this.router.routerState.snapshot.url)
          this.router.navigate(['addDate'],
          { queryParams: { returnUrl: this.router.routerState.snapshot.url.replace('Add-ons' , 'timing') } });

        }

      }
        this.parentId = x.id;
        this.cuurency=x.currenySymbole;
        this.addServ.getallData(this.parentId);
    this.addServ.$getSubject.subscribe(x => {
        this.loader.hideLoader1()
     
      this.data = x.addOns;
      this.totalRecourd=x.totalCount;
        let ff=this.data.length!=0?this.data.map(y=>y.varieties ? y.varieties.length : 1):[0];
      ff=[...ff]
      this.datacount=ff.reduce((x,y)=>x+y);
      this.checknumberofvarites(this.data);
      this.endSub=true;})
    })
    //this.parentId = this.activerout.parent.parent.snapshot.paramMap.get("id")

  }
 checknumberofvarites(x:addOnsDataTableDto[]){
  if(x!=null){
    if(x.length>1){
      this.canaddGroup=true;
     }else{
       if(x[0]?.varieties!=null){
         this.canaddGroup=true;
       }else{
         this.canaddGroup=false;
       }
     }
   }else{

   }


 }

 getAddOnsLazy(e:LazyLoadEvent){
  var l=e.first/5;
  this.addServ.getallData(this.parentId,l);

 }
  addAddOn() {
    this.addOnsSrv.AddonsForm.controls['id'].setValue("0");
    this.router.navigate(['addon-from'], { relativeTo: this.activerout.parent });
  }
  addGroup() {
    this.addOnsGroupSrv.AddonsGroupForm.controls['id'].setValue("0");
    this.router.navigate(['addon-group-form'], { relativeTo: this.activerout.parent });
  }
  updateAddOnsGroup(data) {

    this.addOnsGroupSrv.Intial(data.id, this.parentId,this.url+ data.image, data.name, data.varieties.map(x => x.id), false,data.category)
    this.router.navigate(['addon-group-form'], { relativeTo: this.activerout.parent });
  }
  updateAddOns(data) {
    this.addServ.getSingleVariety(data.id).subscribe(x => {
      this.addOnsSrv.init(x.id, x.name,this.url+ x.image, this.parentId,
         x.imageChanged, x.quantity, x.price, x.description, x.minPerOrder,
          x.maxPerOrder, this.getDateOntomeZone(x.startDate),
           this.getDateOntomeZone(x.endDate), x.hidden, x.eTicket, x.willCall, x.hasGroup, x.groupId,x.categoryId,data.soldItems)
    })
    this.router.navigate(['addon-from'], { relativeTo: this.activerout.parent });
  }

  removegroup(event, ticket){
    this.conSrv.confirm({

        target: event.target,
        message: 'Are you sure that you want to proceed?',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
            this.addServ.removeGroup(ticket.id).subscribe(x=>{
                this.addServ.getallData(this.parentId);
                this.messageService.add({ severity: 'info', summary: 'Confirmed', detail: 'You have accepted' });
            })
            // this.messageService.add({ severity: 'info', summary: 'Confirmed', detail: 'You have accepted' });
        },
        reject: () => {
            this.messageService.add({ severity: 'error', summary: 'Rejected', detail: 'You have rejected' });
        }
    });
  }
  remove(event, ticket){
    this.conSrv.confirm({

        target: event.target,
        message: 'Are you sure that you want to proceed?',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
            this.addServ.removeEntry(ticket.id).subscribe(x=>{
                this.addServ.getallData(this.parentId);
                this.messageService.add({ severity: 'info', summary: 'Confirmed', detail: 'You have accepted' });
            })
            // this.messageService.add({ severity: 'info', summary: 'Confirmed', detail: 'You have accepted' });
        },
        reject: () => {
            this.messageService.add({ severity: 'error', summary: 'Rejected', detail: 'You have rejected' });
        }
    });
  }
  // updateVarity(data){
  //   this.modalServ.open(AddOnsComponent, {
  //     data: {
  //       id:data.id,

  //       isSingleupdate:true, //this for check if update varity (true)only or update addons (false)
  //     },
  //     header: 'update Variety ',
  //     width:'70%'

  //   });
  // }
  // updateAddOns(data){
  //   this.modalServ.open(AddOnsComponent, {
  //     data: {
  //       id:data.id,
  //       parent:this.parentId,
  //       isSingleupdate:false, //this for check if update varity (true)only or update addons (false)
  //     },
  //     header: 'update AddOns ',
  //     width:'70%'

  //   });
  // }

  copyAddOns(){
    this.router.navigate(['copyAddOns'], { relativeTo: this.activerout.parent });
  }
  getMaxPrice(product: addOnsDataTableDto) {
    var item: number = Math.max(...product.varieties.map(z => z.price))
    return item
  }
  getMinPrice(product: addOnsDataTableDto) {
    var item = Math.min(...product.varieties.map(z => z.price));
    return item
  }

  getDateOntomeZone(d: Date | number) {
    var eventOffset = this.EventSrv.offSet * 60 ;
    var MyDate = new Date(d).setMinutes(new Date(d).getMinutes()  + (eventOffset));
    return new Date(MyDate);
  }

}
