import { Injectable } from '@angular/core';
import { Observable, ReplaySubject, Subject } from 'rxjs';
import { Categories } from '../Interfaces/intersets';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

export interface interests{
  userId :string;
  interests : number[];
}
export interface interestsDto{
  id : number,
  value : boolean,
  name : string
}

@Injectable({
  providedIn: 'root'
})


export class IntersetService {
    isSubmitting :boolean= false
  url : string = environment.Api;

  private subject: Subject<interestsDto[]> = new ReplaySubject<interestsDto[]>(1);

  get $getUserInterests(): Observable<any> {

    return this.subject.asObservable();
  }

  constructor(public http : HttpClient) { }

  getAllIntersets(): Observable<Categories[]> {
    return this.http.get<Categories[]>(this.url + 'd/a/g/c/all')
  }
  getUserInterests(id) {
   this.http.get<interestsDto[]>(this.url + 'd/e/g/in/'+id).subscribe(x=>{
     this.subject.next(x)
    })
  }

 
  postUserInterests(data) {
    this.isSubmitting = true;
   //  console.log(this.url + 'd/e/c/in')
     this.http.post(this.url + 'd/e/c/in', data).subscribe(
      response => {
        this.getUserInterests(data.userId);
        this.isSubmitting = false;
       // console.log('Response:', response);
      },
      error => {
        this.isSubmitting = false;
      //  console.log('Error:', error);
      }
    );
  }


}
