<p-editor [style]="{ 'min-height': '350px' }" [(ngModel)]="val" (onTextChange)="issa($event)">
    <ng-template pTemplate="header">
        <span class="ql-formats">
            <button
                type="button"
                class="ql-bold"
                aria-label="Bold"
            ></button>
            <button
                type="button"
                class="ql-italic"
                aria-label="Italic"
            ></button>
            <button
                type="button"
                class="ql-underline"
                aria-label="Underline"
            ></button>
            <button
                type="button"
                class="ql-link"
                aria-label="Link"
            ></button>
        </span>
    </ng-template>
</p-editor>
