import { Injectable } from '@angular/core';
import { ReplaySubject, Subject } from 'rxjs';
import { EventParentDate, EventStateDto } from '../Interfaces/EventBasicInfoDto';
import { progressDto } from '../Interfaces/EventData';

@Injectable({
  providedIn: 'root'
})
export class EventStepsService {

    addressBool=false;
    profileBool=false;
    onlineProfileBool=false;
    TimingBool=false;
    TicketsBool=false;


    isPublished : boolean = false;

     currancysymbole:string="$"
    id: string;
    title: string;
    organizerId: string;
    typeId: bigint;
    subCatId: bigint;
    tagsId: string[];
    isPrivate: boolean;
    isRecurring: boolean;
    isOnline: boolean;
    tzId: bigint;
    categoryId:bigint;
    curId: bigint;
    offSet:number;
  isColection:boolean;

  ParentId:string="";
  ParentStartDate:Date;
  ParentEndDate:Date;

    ///////////////
   startTime:Date;
   endTime:Date;

    permissions : any[] = [];
    progressValue : number = 20;
   address:boolean=false;
   onlinePageId:boolean=false;
   summary:string="";
   numOfTickets:number=0;
    numOfSteps : number ;
    completedSteps: number ;

    eventSteps = [
        {label: 'Basic info',icon: 'pi pi-pw fa-regular fa-circle-info', routerLink: [{ outlets: { 'eventSteps': ['basic-info'] } }], visible : true},
        {id:"timing", label: 'Timing',icon: 'pi pi-pw fa-regular fa-stopwatch', routerLink: [{ outlets: { 'eventSteps': ['timing'] } }], visible : true},
        {label: 'Sub events',icon: 'pi pi-pw fa-regular fa-calendar-days', routerLink: [{ outlets: { 'eventSteps': ['calendar'] } }], visible : true},
        {id:"Scheduler", label: 'Scheduler',icon: 'pi pi-pw fa-regular fa-calendar-days', routerLink: [{ outlets: { 'eventSteps': ['calendar'] } }], visible : true},
        {id:"location" ,label: 'Location',icon: 'pi pi-pw fa-regular fa-map-location-dot', routerLink: [{ outlets: { 'eventSteps': ['location'] } }], visible : true},
        {label: 'Profile',icon: 'pi pi-pw fa-regular fa-star-shooting', routerLink: [{ outlets: { 'eventSteps': ['profile'] } }], visible : true},
        {id:"online", label: 'Online page',icon: 'pi pi-pw fa-regular fa-map-location-dot', routerLink: [{ outlets: { 'eventSteps': ['location'] } }] , visible : true},
        {label: 'Tickets',icon: 'pi pi-pw fa-regular fa-ticket', routerLink: [{ outlets: { 'eventSteps': ['tickets'] } }], visible : true},
        {label: 'Order form',icon: 'pi pi-pw fa-solid fa-list-check', routerLink: [{ outlets: { 'eventSteps': ['order-form'] } }], visible : true},
        {label: 'Publish',icon: 'pi pi-pw fa-regular fa-calendar-days', routerLink: [{ outlets: { 'eventSteps': ['calendar'] } }], visible : true},
       
    ];
  constructor() {
  }

  setpublish(){
    this.publishSTate$.next(this.isPublished)
  }

  CalculateProgress(){
        let x=20;
        if(this.addressBool )
        x+=20;
        if(this.TimingBool )
        x+=20;
        if(this.TicketsBool)
        x+=20;
        if(this.profileBool)
        x+=20;


        this.progressValue =x;
        var e:progressDto={addressBool:this.addressBool,
            profileBool:this.profileBool,
            TicketsBool:this.TicketsBool,
            TimingBool:this.TimingBool
        }
        this. progresSTate$.next(e)
        return x;
  }

 checkEventDate(){
  if(this.startTime!=undefined&&this.endTime!=undefined  ){
    //console.log("dateCheck")


    return true;
  }
  return false

 }
 checkAddress(){
  if(this.address||this.onlinePageId ){
    //console.log("AddressCheck")

    return true;
  }
  return false;
}
checkProfile(){
  if(this.summary ){
   // console.log("summarydateCheck")

    return true;
  }
  return false;
}
checkTickets(){
  if(this.numOfTickets>0){
   // console.log("ticketsCheck")

    return true;
  }
  return false
}

  InitState(x:EventStateDto){
   // console.log(x);
    this.isPublished = x.isPublished;
    this.id = x.id;
    this.title = x.title;
    this.organizerId = x.organizerId;
    this.typeId = x.typeId;
    this.subCatId =x.subCatId;
    this.tagsId=x.tagsId;
    this.isPrivate=x.isPrivate;
    this.isRecurring=x.isRecurring;
    this.isOnline=x.isOnline;
    this.tzId=x.tzId;
    this.categoryId=x.categoryId;
    this.curId=x.curId;
    this.offSet=x.offSet;
    this.address=x.address;
    this.endTime=x.endTime;
    this.numOfTickets=x.tickets;
    this.startTime=x.startTime;
    this.summary=x.summary;
    this.onlinePageId=x.online;
    this.isColection=x.isColection;
    this.ParentId=x.parentId;
   // this.ParentId=x.isColection?x.parentId:"";
    this.addressBool = this.checkAddress();
    this.profileBool=  this.checkProfile();
    this.TicketsBool =this.checkTickets();
    this.TimingBool =this.checkEventDate();
    var e:progressDto={addressBool:this.addressBool,
        profileBool:this.profileBool,
        TicketsBool:this.TicketsBool,
        TimingBool:this.TimingBool
    }
   
    this.CalculateProgress();
  
    this.currancysymbole=x.currenySymbole;
    this.state$.next(x)
    this. progresSTate$.next(e)
    this.publishSTate$.next(x.isPublished)
  }

  setParentId(x){
   // console.log("is initial the parentID  ",x)
    //x.endTime,x.id,x.startTime
    this.ParentId=x;
    this.ParentId$.next(this.ParentId);
  }

  setParentDate(x){
   // console.log("this is state for parent Date",x)
    this.ParentEndDate=x.endTime;
    this.ParentEndDate=x.startTime;
    this.ParentDate$.next({start:x.startTime,end:x.endTime});
  }
  setParentDateforsub(start,end){
   // console.log("this is state for parent Date",x)
    this.ParentEndDate=end;
    this.ParentEndDate=start;
    this.ParentDate$.next({start:start,end:end});
  }

  public state$ = new ReplaySubject<EventStateDto>(1)
 public progresSTate$ = new ReplaySubject<progressDto>(1)
 public publishSTate$ = new ReplaySubject<boolean>(1)
 public ParentId$ = new ReplaySubject<string>(1)
 public ParentDate$=new ReplaySubject<EventParentDate>(1)



}
