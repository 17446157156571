<form formGroup="form1" autocomplete="off" class="mt-3">
    <div class="container">
        <div class="card custom-table-border">
            <!-- <div class="card-header text-center bg-primary rounded-top-23">
                <h1 class="text-white">Order</h1>
            </div> -->

            <div class="card-header border-0 text-center bg-primary rounded-top-23 ">
                <h1 class="fw-bold text-white mt-2">{{'WatListConfirmationTitl'|translate}}</h1>
            </div>
            <div class="card-body">
                <div class="card-body p-0">
                    <div class="row revers-cols ">
                        <h1>{{'WatListConfirmationMsg'|translate}}</h1>
                    </div>
                    <div class="row revers-cols mt-3" [innerHTML]="message">

                    </div>
                      <div class="row revers-cols mt-3 ">
                        <h4>{{'ConfirmationMsg2'|translate}}</h4>
                      
                    </div>
                    <div class="row revers-cols mt-3   justify-content-center">
                        <button class=" rounded-pill save-btn w-25" (click)="GoTohomePage()">
                            {{'ConfirmationBtn2'|translate}}
                        </button>
                     </div>
                </div>
            </div>
        </div>

    </div>
</form>
