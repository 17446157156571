import {
    Component,
    Input,
    ChangeDetectionStrategy,
    OnChanges,
    SimpleChanges,
    OnInit,
    Output,
    EventEmitter,
} from '@angular/core';
import { FormGroup,FormBuilder,Validator, Validators } from '@angular/forms';


export interface OrderFormInput {
  id: number
  question: string
  type: string
  isShown: boolean
  isRequired: boolean
  answer: string
  options: Option[]
}

export interface Option {
  text: string
  value1: string
}

@Component({
    selector: 'app-json-form',
    templateUrl: './json-form.component.html',
    styleUrls: ['./json-form.component.scss'],
})
export class JsonFormComponent implements OnChanges, OnInit {
    @Input() jsonFormData: OrderFormInput[];
    @Output() result : EventEmitter<any[]> = new EventEmitter();
    @Output() IsNotValid : EventEmitter<boolean> = new EventEmitter();
    public myForm: FormGroup = this.fb.group({});
    public form2 : FormGroup = this.fb.group({val: [null]});
    constructor(private fb: FormBuilder) {}
    ngOnChanges(changes: SimpleChanges): void {
        if (!changes.jsonFormData.firstChange) {
          this.createForm(this.jsonFormData);
       //   console.log(this.jsonFormData)
        }
    }
    onSubmit(){

    }

    ngOnInit(){
        this.createForm(this.jsonFormData);
        this.myForm.valueChanges.subscribe(x=>{


            if(this.myForm.valid){
                this.result.emit(x)
            }
            else{
                this.IsNotValid.emit(true)
            }

        })
    }
    createForm(controls: OrderFormInput[])
    {
      for (const control of controls)
      {
        const validatorsToAdd = [];
      //  console.log(control.isRequired)
        if (control.isRequired){

            validatorsToAdd.push(Validators.required);
        }

        this.myForm.addControl(
          control.question,
           this.fb.control(null,validatorsToAdd));

      }
   ////   console.log(this.myForm)
   //   console.log(controls)
    }
}
