import { animate, style, transition, trigger, } from '@angular/animations';
import { DOCUMENT, DatePipe } from '@angular/common';
import { Component,Inject, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LoaderService } from 'src/app/services/loader.service';
import { ProfileEditingSrvService, UserTicketsOrOrderDto } from '../user-home-profile/user-profile-home-edit/profile-editing-srv.service';
import { environment } from 'src/environments/environment';
import * as m from 'moment';
import 'moment/locale/pt-br';
import { TranslateService } from '@ngx-translate/core';
const leaveTrans = transition(':leave', [
  style({
      opacity: 1
  }),
  animate('1s ease-out', style({
      opacity: 0
  }))
])



const fadeOut = trigger('fadeOut', [
  leaveTrans
]);
@Component({
  selector: 'app-user-by-tickets',
  templateUrl: './user-by-tickets.component.html',
  styleUrls: ['./user-by-tickets.component.scss']
})
export class UserByTicketsComponent implements OnInit {
  url=environment.Api
  ticketsandOrder: UserTicketsOrOrderDto[]=[];
 constructor( @Inject(DOCUMENT) public document: Document,private datePipe: DatePipe,public loader : LoaderService,private router:Router 
 ,private ActiveRout:ActivatedRoute ,private profilesrv:ProfileEditingSrvService,private translate:TranslateService){
  this.translate.onLangChange.subscribe(() => {
    m.locale(this.translate.currentLang);
  });
 }
  ngOnInit(): void {
  this.profilesrv.GetUserTicketsOrAddOns();
  this.profilesrv.$getPuserTicketsOrAddons.subscribe((x) => {
    this.ticketsandOrder = x;
    console.log(x)


//     this.newOrgOrders = [];
//      this.pastOrgOrders = [];
//     x.forEach((y) => {
//       if(y.usertickets){
//        // console.log(this.getDateOntomeZone(y.eventEndDate) < this.getDateOntomeZone(new Date(Date.now())))
//         if (this.getDateOntomeZone(y.eventEndDate) < this.getDateOntomeZone(new Date(Date.now()))) {
//             this.pastOrgOrders.push(y);
//         } else {
//             this.newOrgOrders.push(y);
//         }
//       }else{

// var last=this.getDateOntomeZone(new Date(new Date(Date.now()).setDate(new Date(Date.now()).getDate()-7)))
//         var b=
//         console.log(this.getDateOntomeZone(y.eventEndDate) < this.getDateOntomeZone(new Date(Date.now()))||this.getDateOntomeZone(y.orderDate)<last)
//         if (this.getDateOntomeZone(y.eventEndDate) < this.getDateOntomeZone(new Date(Date.now()))||this.getDateOntomeZone(y.orderDate)<last) {
//             this.pastOrgOrders.push(y);
//         } else {
//             this.newOrgOrders.push(y);
//         }
//       }
        
//     });
});
   }

   downloadTickets(f){
    this.router.navigate(['event', f.eventId,'order','confirmation',f.orderId])
  }
  gotoOnlinePage(id){
    this.router.navigate(['event', id,'online'])
  }

  preview(id) {
    this.router.navigate(['event', id])

  }
  getDateOntomeZone(d: Date) {
    var t = new Date(Date.now()).getTimezoneOffset()
    var MyDate = new Date(d).setMinutes(new Date(d).getMinutes() - (new Date(Date.now()).getTimezoneOffset()));
    return new Date(MyDate);
  }
  getDateOntomeZoneformat(d,x){
    //console.log(d);
    let t = new Date(Date.now()).getTimezoneOffset();
    let MyDate = new Date(d).setMinutes(new Date(d).getMinutes() - (t));
  //  console.log(new Date(MyDate))
    //console.log(new Date(MyDate).getFullYear())
   // console.log('new year',new Date(MyDate).getFullYear()==new Date(Date.now()).getFullYear())
    var l="";
    switch(x){
      case 'full':if(new Date(MyDate).getFullYear()==new Date(Date.now()).getFullYear()){
        l= m(MyDate).format('ddd MMMM D hh:mm a');
        // this.datePipe.transform(new Date(MyDate), 'EEE MMMM d hh:mm a');

    }else{
       l= m(MyDate).format('ddd MMMM D,YYYY hh:mm a')
       //this.datePipe.transform(new Date(MyDate), 'EEE MMMM d,y hh:mm a');

    }
    break;
    case 'month': l=m(new Date(MyDate)).format(' MMM ')
   // this.datePipe.transform(new Date(MyDate), 'MMM');
    break;
    case 'day':l=m(new Date(MyDate)).format(' D ')
    //this.datePipe.transform(new Date(MyDate), 'dd');
    break
    }
    return l




   // return new Date(MyDate).toLocaleString();
}

}
