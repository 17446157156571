import { Injectable } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

import { TreeNode } from 'primeng/api';
import { LoaderService } from 'src/app/services/loader.service';
import { OrgnizersService, Permission, Roles } from 'src/app/services/orgnizers.service';

@Injectable({
  providedIn: 'root'
})
export class RolesServiceService {
  id : number ;
  form: FormGroup;
  permissionCat : TreeNode[] = [];
  
  selectedItems : any[] = [];
  newSelection : any[] =[];
  newData : any[] = [];
  newData1 : any[] = [];


  dropform : FormGroup;
  constructor(private translat:TranslateService ,private loader : LoaderService,private fb : FormBuilder,private activatedRoute : ActivatedRoute,private orgSrv : OrgnizersService) {


   }


  get formPermissions() {
    return this.form.get('Permissions') as FormArray;
  }
   
   checkNode(param1 : any[] , param2 : any[]){
    param1.forEach(obj => {
      const count = param2.filter(num => num === obj.key.toString()).length;
      if (count !== obj.count) {
      //  console.log(`Mismatch for id ${obj.key}`);
      }
      else{
        this.selectedItems.push(obj);
      }
    });
           

   }


  getperpar(){
     this.orgSrv.getPermissionsWithParent().subscribe(x => {this.permissionCat = x;
      this.newData = this.permissionCat.map(x => {
        return {
        id : x.key,
        count : x.children.length,
        }
      })
    })
     
  }







  initializer(id : string,name: string) {
    this.form = this.fb.group({
      id: [id,],
      name: [name, Validators.required],
    })

  }
  


  submit(id : string) {
   // console.log(this.permissionCat)
    //  console.log(this.selectedItems)
      let data: Roles = {
        id: this.form.get("id").value,
        name: this.form.get("name").value,
        orgId : id,
        permissions: this.newSelection
      }
      this.orgSrv.postRoles(data)
      this.form.reset()

    }


    prepareform(){
        this.orgSrv.getPermissionsWithParent().subscribe(x => {

          console.log(x)
          this.permissionCat = x.map(y=>{
            var m:TreeNode={key:y.key,
            label:this.translat.instant(y.label),
            children:y.children.map(z=>{
              var t:TreeNode={key:z.key,label:this.translat.instant(z.label),parent:z.parent}
              return t;
            })}
            return m;
          });

          console.log(this.permissionCat)
          this.newData = this.permissionCat.map(x => {
            return {
            key : x.key,
           //label :  this.translate.instant(x.label),
             label:x.label,
            count : x.children.length,
            }
          })
          console.log(this.newData)
          var id=this.form.controls['id'].value
          this.orgSrv.getPermissionsForRole(id).subscribe(x => {
            console.log(x)
            this.selectedItems = x.permissions.map(y=>{
              var m:TreeNode={key:y.key,
              label:this.translat.instant(y.label)
            }
            return m;
            })
            console.log(this.selectedItems)
            this.initializer(id ,x.name)
            x.permissions.forEach(e => {
                  this.newData1.push(e.key.toString().slice(0,-2));
    
            })

              this.checkNode(this.newData , this.newData1);
              this.loader.hideLoader1();
          })

        })
    }
  }

