import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment as env} from '../../environments/environment';
import {Observable, ReplaySubject, Subject} from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class UserActionsService {
  constructor(private httpClient: HttpClient) { }
    url = env.Api;

    private subject: Subject<any> = new ReplaySubject<any>(1);
    private Likersubject: Subject<string[]> = new ReplaySubject<string[]>(1);
    private followerSubject: Subject<string[]> = new ReplaySubject<string[]>(1);
    get $getSubject(): Observable<any> {

        return this.subject.asObservable();
    }
    get $getLikers(): Observable<any> {

        return this.Likersubject.asObservable();
    }
    get $getfollowers(): Observable<any> {

        return this.followerSubject.asObservable();
    }

    public LikedEvents: string[] = [];
    
    public FollowedOrganizers: string[];
    postLike(id){
        return this.httpClient.post(this.url + 'd/ua/l/' + id, {} );
    }
    postFollow(id){
        return this.httpClient.post(this.url + 'd/ua/f/' + id , {});
    }
    getLikes(){
        return this.httpClient.get(this.url + 'd/ua/l/');
    }
    getLikedEvent() {
         this.httpClient.get<string[]>(this.url + 'd/ua/l/list').subscribe(x=>{
            this.Likersubject.next(x);
         });
    }
    getLikedEventList() {
        return this.httpClient.get<string[]>(this.url + 'd/ua/l/list');
    }
    getFollowOrgnizer() {
        this.httpClient.get<string[]>(this.url + 'd/ua/f/list').subscribe(x=>{
            this.followerSubject.next(x);
        });
    }
    getFollowerList() {
        return this.httpClient.get<string[]>(this.url + 'd/ua/f/list');
    }
    getFollowers(){
        return this.httpClient.get(this.url + 'd/ua/f/');
    }

}
