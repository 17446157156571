import {Component, OnDestroy, OnInit} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { OrganizerFromStepService } from 'src/app/services/organizer-from-step.service';
import {Orgnizer, OrgnizersService, member, member1} from 'src/app/services/orgnizers.service';
import {DataCols} from '../../common/oredata-table/oredata-table.component';
import { environment as env } from 'src/environments/environment';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';

import { InviteTeamMemberComponent } from './invite-team-member/invite-team-member.component';
import { AuthService } from 'src/app/services/auth.service';
import { LoaderService } from 'src/app/services/loader.service';
import { animate, style, transition, trigger } from '@angular/animations';
import { ConfirmationService, MessageService } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';

const leaveTrans = transition(':leave', [
    style({
        opacity: 1
    }),
    animate('1s ease-out', style({
        opacity: 0
    }))
])



const fadeOut = trigger('fadeOut', [
    leaveTrans
]);


@Component({
    selector: 'app-organizers',
    templateUrl: './organizers.component.html',
    styleUrls: ['./organizers.component.scss'],
    animations : [fadeOut]

})
export class OrganizersComponent implements OnInit ,OnDestroy{
    data: Orgnizer[];
    columns: DataCols[];

    public url = env.Api;

    constructor(private conSrv : ConfirmationService,
        public loader : LoaderService,public auth : AuthService
        ,public dialogService : DialogService,
        public ref: DynamicDialogRef,public OrgServ: OrgnizersService 
        , private organizerformservice : OrganizerFromStepService 
        , private router : Router , private activerout : ActivatedRoute, private messageService: MessageService,private translate:TranslateService) {
    }
    ngOnDestroy(): void {
            
    }

    ngOnInit(): void {
        this.loader.showLoader1()
        this.OrgServ.getAllOrgs();
        this.OrgServ.$getSubject.subscribe(e => {this.data = e ; this.loader.hideLoader1()} , err => {} , () => {this.loader.hideLoader1()});
      //  console.log(this.data)
    }
    manage(id : any){
        this.organizerformservice.form.get("id").patchValue(id);
        this.router.navigate([`/organizers/team-manage/${id}/team-members`])
    }
    preview(id : any){
        this.router.navigate(["/organizers/preview" , id])
    }
    openNew() {
        this.organizerformservice.form.get("id").setValue("0");
        this.router.navigate(["organizer-form" , 0],{relativeTo: this.activerout.parent})
    }

    delete(event,id) {
        this.conSrv.confirm({

            target: event.target,
            message: 'Are you sure that you want to proceed?',
            icon: 'pi pi-exclamation-triangle',
            accept: () => {

                var del=    this.OrgServ.deletOrg(id).subscribe(x => {
                    this.OrgServ.getAllOrgs();
                    this.messageService.add({ severity: 'success', summary: 'Deleted successfully' });
                  });
                //  this.sub.push(del)
                
                //  this.messageService.add({ severity: 'info', summary: 'Confirmed', detail: 'You have accepted' });
            },
            reject: () => {

            }
        });
    }
    createEvent(id){
           this.organizerformservice.orgId = id;
          this.router.navigate(["events/manage-event/0/basic-info"])
    }
    
    openNewTeam(){
        this.ref = this.dialogService.open(InviteTeamMemberComponent, {
            header: this.translate.instant('InvtNuMmbrMdlTitl'),
            // width: '50%',
            contentStyle: {overflow: 'auto'},
            baseZIndex: 10000,
            data : {
                org: this.data ,
                showOrg : "No Show"
            }
        });
        this.ref.onClose.subscribe((data : member1) => {
          if(data != undefined){
             //   console.log(data)
                this.OrgServ.isSubmitting = true
                this.OrgServ.postInvite(data)
           }
        });
    }

    edit(id: any) {

        // this.OrgServ.getOneOrg(id).subscribe(x => {


        //     this.organizerformservice.Initializers(x.id , x.name ,x.bio ,this.url+x.imageUrl ,x.email , x.website , x.links);
        //     this.organizerformservice.getLinksForEdit(x.links)
        //     console.log(x.links)
            
        //     console.log(this.organizerformservice.form.value.links);
        // })
        this.router.navigate(["organizer-form" , id],{relativeTo: this.activerout.parent})
    }
}
