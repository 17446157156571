<form [formGroup]="srvform.form" (submit)="submit()" autocomplete="off">
    <div class="col-12">
        <div class="p-input-filled mb-3">
            <span class="p-float-label">
                <input type="text" formControlName="name" #asdqwe id="name" pInputText class="form-control"
                    maxlength="2000" [ngClass]="{
                        'ng-invalid ng-dirty':
                            (srvform.form.get('name')?.touched &&
                            srvform.form.get('name')?.invalid) || (srvform.form.get('name')?.invalid && submitted)
                    }" [class.ng-invalid.ng-dirty] />
                <label class="font-size-14" for="name">{{'OrgnzrFrmBscInfoInpt001'|translate}}</label>
            </span>
            <small class="d-block m-1">{{asdqwe.value.length}}/{{2000}}</small>
            <small class="p-error" *ngIf="(srvform.form.get('name')?.touched &&
            srvform.form.get('name')?.invalid)  || (srvform.form.get('name')?.invalid && submitted)
                ">{{'OrgnzrFrmBscInfoInpt001ErrMsg'|translate}}</small>
        </div>
    </div>
    <div class="col-12 p-0">

        <div class="card">
            <div class="col-12">
                <app-my-image-uploader formControlName="image" [aspectRatio]="1 / 1" [quality]="'50'"
                    [format]="'jpeg'"></app-my-image-uploader>
            </div>
            <small class="p-error" *ngIf="(srvform.form.get('image')?.touched &&
            srvform.form.get('image')?.invalid)  || (srvform.form.get('image')?.invalid && submitted)
                ">{{'OrgnzrFrmBscInfoInpt002ErrMsg'|translate}}</small>
            <div class="row text-center py-1">
                <div class="col-12 col-md-4 text-danger" style="font-size:14px">
                    <i class="fa-regular fa-triangle-exclamation"></i>
                    <!-- Recommended image size: <b> 2160 x
                            1080px</b> -->
                    {{'OrgnzrFrmBscInfoInpt002Dsc001'|translate}}
                </div>
                <div class="col-12 col-md-4 text-danger" style="font-size:14px">
                    <i class="fa-regular fa-triangle-exclamation"></i>
                    <!-- Maximum file size: <b>10MB</b> -->
                    {{'OrgnzrFrmBscInfoInpt002Dsc002'|translate}}
                </div>
                <div class="col-12 col-md-4 text-danger" style="font-size:14px">
                    <i class="fa-regular fa-triangle-exclamation"></i>
                    <!-- Supported image files: <b>JPEG or PNG</b> -->
                    {{'OrgnzrFrmBscInfoInpt002Dsc003'|translate}}
                </div>
            </div>
        </div>
    </div>
    <div class="col-12 p-input-filled">
        <span class="p-float-label">
            <textarea #oop formControlName="bio" [autoResize]="true" [cols]="30" [rows]="5" [ngClass]="{
                'ng-invalid ng-dirty':
                    (srvform.form.get('bio')?.touched &&
                    srvform.form.get('bio')?.invalid)  || (srvform.form.get('bio')?.invalid && submitted)
            }" maxlength="2000" class="form-control" id="bio-id" pInputTextarea></textarea>
            <label for="bio-id">{{'OrgnzrFrmBscInfoInpt003'|translate}}</label>
        </span>
        <small class="d-block m-1">{{oop.value.length}}/{{2000}}</small>
        <small class="p-error  m-1" *ngIf="(srvform.form.get('bio')?.touched &&
        srvform.form.get('bio')?.invalid)  || (srvform.form.get('bio')?.invalid && submitted)
            ">{{'OrgnzrFrmBscInfoInpt003Pop'|translate}}</small>
    </div>
    <div class=" d-flex  justify-content-center">
        <div class="banner-btn rounded-pill mx-1">

            <button class="rounded-pill save-btn ms-1" (click)="cancel()">{{'CanclBtn'|translate}}
                <span></span></button>
            <button class="rounded-pill next-btn  ms-1 mt-md-0 mt-1">{{'NextBtn'|translate}} <i class="pi mt-1"
                    [class.pi-angle-right]="document.documentElement.dir === 'ltr'"
                    [class.pi-angle-left]="document.documentElement.dir === 'rtl'"></i><span></span></button>
        </div>
    </div>
</form>