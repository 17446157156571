import { DOCUMENT } from '@angular/common';
import { Component, OnInit, Inject } from '@angular/core';
import { Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CalenderStepsService } from 'src/app/services/calender-steps.service';
import { EventStepsService } from 'src/app/services/event-steps.service';

@Component({
    selector: 'app-calender-form-step3',
    templateUrl: './calender-form-step3.component.html',
    styleUrls: ['./calender-form-step3.component.scss']
})
export class CalenderFormStep3Component implements OnInit {
    submitted: boolean = false;
    constructor(public Srv: CalenderStepsService, private router: Router, private activatedRoute: ActivatedRoute, private evSrv: EventStepsService, @Inject(DOCUMENT) public document: Document) {
    }
    ngOnInit(): void {
        this.evSrv.ParentId$.subscribe(x => {
            if (x != "") {
                this.evSrv.ParentDate$.subscribe(x => {

                    var MAinEvntPriod = (Date.parse(x.end.toLocaleString()) - Date.parse(x.start.toLocaleString())) / 86400000;
                 //   console.log(MAinEvntPriod)
                    this.Srv.clenderSteps.controls['OcurinngEnd'].setValidators([Validators.required, Validators.min(2), Validators.max(Math.floor(MAinEvntPriod))]);


                    // const endTimeParse = x.end. / 1000;

                    // const days = Math.floor(timeLeft / 86400);
                })
            }
        })
    }
    submit() {
        if (this.validateCalenderstep3()) {
            //this.Srv.finish();
            this.router.navigate(['step1'], { relativeTo: this.activatedRoute.parent });
        }
        else { this.submitted = true; }

    }
    cancel() {
        this.Srv.clearForm();
        this.router.navigate(['calendar'], { relativeTo: this.activatedRoute.parent.parent.parent });
        // this.router.navigate([
        //   {
        //     outlets: { 'CalenderOutlet': ['calender_prview'] }
        //   }
        // ],{relativeTo: this.activatedRoute.parent.parent});
    }
    // previous(){
    //   this.router.navigate([
    //     {
    //         outlets: { 'calender-Form-steps': ['step2'] }
    //     }
    // ], { relativeTo: this.activatedRoute.parent});
    // }
    validateCalenderstep3() {
        return this.Srv.clenderSteps.controls['OcurinngEnd'].valid;
    }
}
