import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Observable, ReplaySubject, Subject } from 'rxjs';
import { AuthService, User, register } from 'src/app/services/auth.service';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-register',
    templateUrl: './register.component.html',
    styleUrls: ['./register.component.scss']
})






export class RegisterComponent implements OnInit{
    


    LogoUrl:string="";
    private subject: Subject<string> = new ReplaySubject<string>(1);

  get $getSubject(): Observable<string> {
    return this.subject.asObservable();
  }
     id : string =this.activatedroute.snapshot.paramMap.get('id');
     disableEmail: boolean;
     disablenames: boolean;
   
    registerForm = this.fb.group({
        email: ['', [Validators.required , Validators.email]],
        firstName: ['',[Validators.required , Validators.pattern(/^\S*$/)]],
        lastName: ['',[Validators.required , Validators.pattern(/^\S*$/)]],
        password: ['',[Validators.required , Validators.pattern(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+~`-])(?=.*\d).{8,32}$/)]],
        confirmPassword: ['',[Validators.required ]],

    },{
        validators : this.ConfirmPasswordValidator("password" , "confirmPassword")
    })
    constructor(private fb:FormBuilder, public AuthService : AuthService, private activatedroute : ActivatedRoute,private translate:TranslateService){
        this.translate.onLangChange.subscribe(x=>{
            var s = localStorage.getItem(this.translate.currentLang+1) as string;
            this.subject.next(s);
        })
    }
    ngOnInit(): void {
        var s = localStorage.getItem(this.translate.currentLang+1) as string;
        this.subject.next(s);
        this.$getSubject.subscribe(x=>{
            this.LogoUrl=environment.Api+x;
        })
         if(this.id){
            this.AuthService.getDataForRegister(this.id).subscribe(x => {

                this.registerForm.get("email").patchValue(x.email);
                this.registerForm.get("email").updateValueAndValidity();
                this.disableEmail = true;
                if(x.firstName && x.lastName){
                    this.disablenames = true;
                    this.registerForm.get("firstName").patchValue(x.firstName)
                    this.registerForm.get("firstName").updateValueAndValidity()
                    this.registerForm.get("lastName").patchValue(x.lastName)
                    this.registerForm.get("lastName").updateValueAndValidity()
                }

            });

         }
    }




   check(){
  //  console.log(this.registerForm)
   }

    ConfirmPasswordValidator(controlName: string, matchingControlName: string) {

        return (formGroup: FormGroup) => {
            let control = formGroup.controls[controlName];
            let matchingControl = formGroup.controls[matchingControlName]
            if (
                matchingControl.errors &&
                !matchingControl.errors.confirmPasswordValidator
            ) {
                return;
            }
            if (control.value !== matchingControl.value) {
                matchingControl.setErrors({ confirmPasswordValidator: true });
            } else {
                matchingControl.setErrors(null);
            }
        };
    }

    register(){
        let x = this.registerForm.value as register;
        this.AuthService.register(x , this.activatedroute.snapshot.paramMap.get('id'))

    }
}
