<div class="container py-3">
    <div class="card custom-table-border">
        <!-- <div class="card-header text-center bg-primary rounded-top-23">

            <h1 class="fw-bold text-white mt-2">Orders</h1>

        </div> -->
        <div class="card-header border-0 text-center bg-primary rounded-top-23 ">
            <h1 class="fw-bold text-white mt-2">{{'YourReservedTickets'|translate}}</h1>
        </div>
        <div *ngIf="loader.show1" @fadeOut class="d-flex justify-content-center align-items-center"
            style="border-radius: 25px;top : 0;position: absolute;z-index: 1050;background-color:#fff;width: 100%;height: -webkit-fill-available;right: 0;bottom: 0;left: 0;">


        </div>


        <p-toast></p-toast>

        <div class="">
            <p-table #dt responsiveLayout="stack" [breakpoint]="'991px'" [value]="ticketsandOrder" [rows]="6" 

            sortField="status" sortMode="single" [scrollable]="true" scrollHeight="400px" rowGroupMode="subheader" groupRowsBy="status" 
                [globalFilterFields]="['eventName','tikettype']" [tableStyle]="{'max-width':'100%'}" [rowHover]="true" dataKey="id"
                currentPageReportTemplate="{{'Showing'|translate}} {first} {{'to'|translate}} {last} {{'of'|translate}} {totalRecords} {{'tickets.'|translate}}"
                [showCurrentPageReport]="true" class="">
                <ng-template pTemplate="caption">

                    <div>

                        <div class="d-sm-flex d-block align-items-center justify-content-sm-between text-center">

                            <div class="  ms-md-3  mb-sm-0  mb-2">
                            </div>
                            <div class="p-input-filled me-md-3 ">
                                <span class="p-input-icon-left">
                                    <i class="pi pi-search"></i>
                                    <input pTooltip="{{'OrdrsTblSrchInptPop'|translate}}" showDelay="1000"
                                        tooltipPosition="left" pInputText type="text" class="form-control"
                                        (input)="dt.filterGlobal($event.target.value, 'contains')"
                                        placeholder="{{'Search...'|translate}}" />
                                </span>
                            </div>
                        </div>
                    </div>

                </ng-template>
                <!-- <ng-template pTemplate="header" class="">
                    <tr>
                     
                        
                       
                    </tr>
                </ng-template> -->

                <ng-template pTemplate="groupheader" let-customer>
                    <tr pRowGroupHeader>
                        <td colspan="5">
                           
                            <span class="font-bold ml-2">{{(customer.status+" "+'tickets')|translate}}</span>
                        </td>
                    </tr>
                </ng-template>
                <!-- <ng-template pTemplate="groupfooter" let-customer>
                    <tr>
                        <td colspan="5" class="text-right font-bold pr-6">Total Customers: {{calculateCustomerTotal(customer.representative.name)}}</td>
                    </tr>
                </ng-template> -->
                <ng-template pTemplate="body" let-ord let-rowIndex="rowIndex">
                    <tr>
                 
                     
                           
                                <div class="d-lg-flex d-block align-items-center mt-3  me-2 justify-content-between ">
                                    <div class="text-md-start  padding-custom text-end Image-cutom ">
                                        <div class="d-lg-flex d-block align-items-center">
                                            <div class="static-width text-center ">
                                                <span class=" text-center row align-items-center  fw-bold me-md-2 ">
                                                    <h5 class="text-primary col-12">{{getDateOntomeZoneformat(ord?.eventStartTime,'month')}}</h5>
                                                    <h5 class=" col-12">{{getDateOntomeZoneformat(ord?.eventStartTime,'day')}}</h5>
                                                </span>
                                                <div class="d-lg-flex d-block align-items-center me-lg-2">
                                                    <img [src]="ord?.eventPic==null?'assets/img/courses/3SUBiaP.png':url+ord?.eventPic"
                                                        alt="img" width="100" class="shadow-4 min-size rounded-2" />
                                                </div>
                                            </div>
                                            <div class="d-block my-lg-0 my-2 text-center">
                                                <span class="text-lenght d-lg-block fw-bold">{{ord.eventName}}</span>
                                            <span class="text-secondary d-lg-block d-none">{{ord?.address}} </span>
                                                <span class="text-secondary d-lg-block d-none">{{'Starts:'|translate}}{{" "+getDateOntomeZoneformat(ord?.eventStartTime,'full')+"."}} </span>
                                                    <span class="text-secondary d-lg-block d-none">{{'OrderRepCo001'|translate}}{{" "+ord.orderNum+" "}}{{'placed on'|translate}}{{" "+getDateOntomeZoneformat(ord?.orderDate,'full')+"."}}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="text-center padding-custom text-lenght-larg ">
                                        <span class=" text-start fw-bold">{{ord.tikettype}}</span>
                                       
                                    </div>
                                    <div class="text-center padding-custom text-lenght-larg">
                                        <span class=" text-start fw-bold">{{ord.delivery|translate}}</span>
                                       
                                    </div>
                                    <div class="text-center">
                                        <button *ngIf="" type="button" pButton pRipple [pRowToggler]="event"
                                            class="p-button-text p-button-rounded d-lg-none d-block"
                                            [icon]="expanded ? 'fa-regular fa-chevron-down' : 'fa-regular fa-ticket fa-rotate-90'">{{'EvntTblBtn002Chois004'|translate}}</button>
                                        <p-overlayPanel #opi>
                                            <button pButton pRipple icon="fa-sharp fa-solid fa-eye"
                                            class="p-button-rounded p-button-primary m-1" 
                                            (click)="preview(ord.eventId)"></button><br>
                                            <button  *ngIf="ord?.address=='Online'" pButton pRipple icon="fa-solid fa-link"
                                                class="p-button-rounded p-button-primary m-1"
                                                (click)="gotoOnlinePage(ord.eventId)"></button><br  *ngIf="ord?.address=='Online'">
                                            <button  pButton pRipple icon="fa-sharp fa-solid fa-ticket"
                                                class="p-button-rounded p-button-primary m-1"
                                                (click)="downloadTickets(ord)"></button><br >
                                            <p-toast></p-toast>
                                            <p-confirmPopup></p-confirmPopup>
                                            <button pButton pRipple icon="pi pi-trash"
                                                class="p-button-rounded p-button-danger m-1"></button>
                                        </p-overlayPanel>
        
                                        <button pButton pRipple (click)="opi.toggle($event)" icon="far fa-ellipsis-v"
                                            class="p-button-rounded p-button-primary m-1"></button>
        
                                    </div>
                                </div>
               
              
                    </tr>
                </ng-template>

                <ng-template pTemplate="summary">
                    <div class="flex align-items-center justify-content-between">
                        {{'OrdrsTblFooter'|translate}} {{ticketsandOrder ? ticketsandOrder.length : 0 }} {{'tickets.'|translate}}
                    </div>
                </ng-template>
            </p-table>

        </div>

    </div>
</div>

<!-- <div class="events-area pt-100 pb-70">
    <div class="container">
        <div class="">
            <p-toast></p-toast>


            <div class=" custom-table-border">


            </div>

        </div>
    </div>
</div> -->
