import {
  Component,
  Input,
  ChangeDetectionStrategy,
  OnChanges,
  SimpleChanges,
  OnInit,
  Output,
  EventEmitter,
} from '@angular/core';
import { FormGroup,FormBuilder,Validator, Validators } from '@angular/forms';
import { OrderFormInput } from '../json-form/json-form.component';

@Component({
  selector: 'app-form-for-edit-attendes',
  templateUrl: './form-for-edit-attendes.component.html',
  styleUrls: ['./form-for-edit-attendes.component.scss']
})
export class FormForEditAttendesComponent  implements OnChanges, OnInit{
  @Input() jsonFormData: OrderFormInput[];
  @Input() FormValue: any[];
  @Output() result : EventEmitter<any[]> = new EventEmitter();
  @Output() IsNotValid : EventEmitter<boolean> = new EventEmitter();
  public myForm: FormGroup = this.fb.group({});
  public form2 : FormGroup = this.fb.group({val: [null]});
  constructor(private fb: FormBuilder) {}
  ngOnChanges(changes: SimpleChanges): void {
      if (!changes.jsonFormData.firstChange) {
        this.createForm(this.jsonFormData);
     //   console.log(this.jsonFormData)
      }
  }
  onSubmit(){

  }

  ngOnInit(){
      this.createForm(this.jsonFormData);

      
      this.myForm.valueChanges.subscribe(x=>{


          if(this.myForm.valid){
              this.result.emit(x)
          }
          else{
              this.IsNotValid.emit(true)
          }

      })
     
  }
  createForm(controls: OrderFormInput[])
  {
    for (const control of controls)
    {
      const validatorsToAdd = [];
    //  console.log(control.isRequired)
      if (control.isRequired){

          validatorsToAdd.push(Validators.required);
      }

      this.myForm.addControl(
        control.question,
         this.fb.control(null,validatorsToAdd));

    }
 ////   console.log(this.myForm)
 //   console.log(controls)
 setTimeout(()=>{
  this.myForm.setValue(this.FormValue)
 },200)
  }

  setValue(controls: OrderFormInput[])
  {
   
 ////   console.log(this.myForm)
 //   console.log(controls)
  }
}






