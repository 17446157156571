
<div class="  row align-items-center p-0">
    <p-toast></p-toast>
        <div class="card  border-0 col-xl-4 col-lg-5 col-12 m-0 p-0 login-tab-mob">
            <a class="navbar-brand d-lg-flex d-none" routerLink="/"><img 
                src="{{LogoUrl}}"
                 class="logo-size-larg" alt="Antares"></a>
                 <!-- src="../../../../../assets/img/Logo/Antares - Logo H (Colored).png" -->
            <div class=" text-center card-title ">
                <h1 class="text-primary">{{'Change password'|translate}}</h1>
            </div>
            <div class="card-body">
    
                <form  [formGroup]="form" (submit)="submit()" autocomplete="off">
                   <div class="p-input-filled  col-12">
                    <span class="p-float-label">
                        <p-password  [promptLabel]="' '" weakLabel=" " strongLabel=" " mediumLabel=" " formControlName="password" class="w-full p-0"  id="password"   [ngClass]="{
                            'ng-invalid ng-dirty':
                            form.get('password')?.touched &&
                            form.get('password')?.invalid
                        }"  [style]="{'width':'100%'}"
                        [inputStyle]="{'width':'100%'}"
                         [toggleMask]="true">
                        <ng-template pTemplate="footer">
                            <p-divider></p-divider>
                            <p class="mt-2">{{'Password must contain :'|translate}} </p>
                            <ul class="pl-2 ml-2 m-3" style="line-height: 1.5">
                                <li>{{'At least one lowercase'|translate}}</li>
                                <li>{{'At least one uppercase'|translate}}</li>
                                <li>{{'At least one speical character'|translate}}</li>
                                <li>{{'At least one numeric'|translate}}</li>
                                <li>{{'Minimum 8 characters'|translate}}</li>
                            </ul>
                        </ng-template></p-password>
                        <label htmlFor="password">{{'Password'|translate}}</label>
                    </span>
                                <small class="p-error" *ngIf="form.get('password')?.touched &&
                    form.get('password')?.invalid ">{{'Please add valid value.'|translate}}</small>
                            </div>
                            <div class="p-input-filled col-12">
                                <span class="p-float-label">
                                    <p-password  formControlName="confirmPassword" class="w-full"  id="confirmPassword"   [ngClass]="{
                                        'ng-invalid ng-dirty':
                                        form.get('confirmPassword')?.touched &&
                                        form.get('confirmPassword')?.invalid
                                    }"  [style]="{'width':'100%'}"
                                    [inputStyle]="{'width':'100%'}"
                                    [feedback]="false"
                                    [toggleMask]="true"></p-password>
                                    <label htmlFor="confirmPassword">{{'Confirm password'|translate}}</label>
                                </span>
                                <small class="p-error" *ngIf="form.get('confirmPassword')?.touched &&
                    form.get('confirmPassword').errors?.required ">{{'Confirm password is required'|translate}}</small>
                                <small class="p-error" *ngIf="form.get('confirmPassword')?.errors?.confirmPasswordValidator">{{'Passsword and
                                    confirm password don't match.'|translate}}</small>
                                        </div>
        
        
                   <div class="banner-btn rounded-pill mx-2 text-center">
                    <button [disabled]="form.invalid||AuthService.isSubmitting" type="submit" pTooltip="Register" showDelay="1000" tooltipPosition="right" class="rounded-pill save-btn {{AuthService.isSubmitting? 'bg-disabled' : ''}}">
                        <i  *ngIf="AuthService.isSubmitting" class="fas fa-spinner fa-spin"></i>
                        {{'Change'|translate}} <span></span>
                    </button>
                </div>
                </form>
            </div>
        </div>
        <div class="col-xl-8 col-lg-7 d-lg-flex d-none m-0 p-0 img-fluid authentication-bg">
    
        </div>
    </div>
    