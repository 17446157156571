import { DOCUMENT, DatePipe } from '@angular/common';
import { Component, OnInit, Inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { CalenderStepsService } from 'src/app/services/calender-steps.service';
import { EventStepsService } from 'src/app/services/event-steps.service';

@Component({
    selector: 'app-calender-form-step1',
    templateUrl: './calender-form-step1.component.html',
    styleUrls: ['./calender-form-step1.component.scss']
})
export class CalenderFormStep1Component implements OnInit {
    submitted: boolean = false;
    minDateValue!: Date;//for start date min value
    maxDateValue!: Date;//for set min date for end date
    maxEventDate!: Date;
    maxEventDate2!: Date;
    constructor(public Srv: CalenderStepsService, private router: Router,
        private activatedRoute: ActivatedRoute, private evSrv: EventStepsService
        , private datePipe: DatePipe, @Inject(DOCUMENT) public document: Document) {

    }

    getDateOntomeZone(d: Date | number) {
        var eventOffset = this.evSrv.offSet * 60;
        var MyDate = new Date(d).setMinutes(new Date(d).getMinutes() + (eventOffset));
        return new Date(MyDate);
    }
    ngOnInit(): void {
        // this.minDateValue=new Date(Date.now());
        this.evSrv.ParentId$.subscribe(x => {
            if (x != "") {
                this.evSrv.ParentDate$.subscribe(x => {
                    this.minDateValue = this.getDateOntomeZone(x.start);
                    this.maxEventDate = this.getDateOntomeZone(x.end);
                    this.maxEventDate2 = this.getDateOntomeZone(x.end);
                    this.Srv.clenderSteps.controls['startDate'].setValue(this.minDateValue)
                })


            } else {
                this.minDateValue = new Date(new Date(new Date(Date.now()).setHours(0)).setMinutes(0))
                this.Srv.clenderSteps.controls['startDate'].setValue(this.minDateValue);

            }
        })

    }
    setDateAndConfig(e)//for get start date and set min date for end Dateand bind select for monthly reccuring
    {

        this.evSrv.ParentId$.subscribe(x => {
            if (x != "") {
                this.evSrv.ParentDate$.subscribe(x => {
                    var MAinEvntPriod = (Date.parse(x.end.toLocaleString()) - Date.parse(x.start.toLocaleString())) / 86400000;
                    //console.log(this.Srv.clenderSteps.controls['OcurinngEnd'].value == MAinEvntPriod)
                    if (this.Srv.clenderSteps.controls['OcurinngEnd'].value == MAinEvntPriod) {
                      //  console.log('in if')
                        this.maxEventDate2 = new Date(new Date(e).setDate(new Date(e).getDate() + 1));
                       // console.log(this.maxEventDate2)
                        var t = e;
                        var lm = new Date(t)
                        this.maxDateValue = new Date(lm.setMinutes(lm.getMinutes() + 15));//15
                        this.Srv.clenderSteps.controls['endDate'].setValue(this.datePipe.transform(this.maxDateValue, 'MM/dd/yyyy hh:mm a'));
                    } else {
                      //  console.log('in else')
                        this.maxEventDate2 = new Date(new Date(new Date(e).setDate(new Date(e).getDate() + ((MAinEvntPriod / this.Srv.clenderSteps.controls['OcurinngEnd'].value)) - 1)));
                       // console.log(this.maxEventDate2)
                        var t = e;
                        var lm = new Date(t)
                        this.maxDateValue = new Date(lm.setMinutes(lm.getMinutes() + 15));//15
                        this.Srv.clenderSteps.controls['endDate'].setValue(this.datePipe.transform(this.maxDateValue, 'MM/dd/yyyy hh:mm a'));
                    }
                })

            } else {
                var t = e;
                var lm = new Date(t)
                this.maxDateValue = new Date(lm.setMinutes(lm.getMinutes() + 15));//15
                this.Srv.clenderSteps.controls['endDate'].setValue(this.datePipe.transform(this.maxDateValue, 'MM/dd/yyyy hh:mm a'));

            }
        });


        //this.maxDateValue =  new Date(new Date(e).setMinutes(new Date(e).getMinutes()+15));
    }
    submit() {
        if (this.validateCalenderInfo()) {
            this.router.navigate(['step2'], { relativeTo: this.activatedRoute.parent });
        } else {
            this.submitted = true;
        }

    }
    previous() {
        this.router.navigate(['step3'], { relativeTo: this.activatedRoute.parent })
    }
    cancel() {
        this.Srv.clearForm();
        this.router.navigate(['calendar'], { relativeTo: this.activatedRoute.parent.parent.parent });
        // this.router.navigate([
        //   {
        //     outlets: { 'CalenderOutlet': ['calender_prview'] }
        //   }
        // ], { relativeTo: this.activatedRoute.parent.parent });
    }
    validateCalenderInfo() {
        return this.Srv.clenderSteps.controls['startDate'].valid && this.Srv.clenderSteps.controls['endDate'].valid;
    }
}
