
import { AfterContentInit, AfterViewChecked, AfterViewInit,Inject ,Component, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MenuItem } from 'primeng/api';
import { EventStepsService } from 'src/app/services/event-steps.service';
import { EventsService } from 'src/app/services/events.service';
import { ViewEncapsulation } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { Subscription } from 'rxjs';
import { DOCUMENT, DatePipe } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import * as m from 'moment';
import 'moment/locale/pt-br';
@Component({
    selector: 'app-event-steps',
    templateUrl: './event-steps.component.html',
    styleUrls: ['./event-steps.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class EventStepsComponent implements OnInit, OnDestroy {

    eventId: string;
    sub: Subscription[] = [];
    constructor(private auth: AuthService,
        private router: Router,
        private activerout: ActivatedRoute,
        public srv: EventStepsService,
        private eventSrv: EventsService,private datePipe: DatePipe,private translate:TranslateService,
        @Inject(DOCUMENT) public document: Document) {

            this.translate.onLangChange.subscribe(() => {
                this.getstringLoderr();
                this.updateItem();
                this.updatteIte2();
                m.locale(this.translate.currentLang)
                var eventsub = this.eventSrv.GetEventProgressInfo(this.eventId).subscribe(x => {
                    this.srv.InitState(x);
                    this.srv.setParentId(x.parentId);
                    // if(x.isColection&&x.){
                    //     this.srv.setParentDate(x);
                    // }
                 
                    this.hasPerant=x.parentId!=""?true:false;
                    this.Parent=x.parentId;
                    this.eventTitle = this.srv.title;})
              });
         }
    updateItem() {

        if (this.auth.securityObject.checkClaim(10105030103)) {
            this.items = [
                { label: this.translate.instant('EvntMnuChois001'), icon: 'pi pi-pw fa-regular fa-circle-info', routerLink: ['basic-info'], visible: true, clicked: false, completed: true },
                { id: "timing", label:this.translate.instant('EvntMnuChois002') , icon: 'pi pi-pw fa-regular fa-stopwatch', routerLink: ['timing'], visible: true, clicked: false, completed: this.srv.TimingBool },
                { id: "Scheduler", label: this.translate.instant('EvntMnuChois003'), icon: 'pi pi-pw fa-regular fa-calendar-days', routerLink: ['calendar'], visible: true, clicked: false, completed: this.srv.TimingBool },
                { id: "Subevent", label: this.translate.instant('EvntMnuChois010'), icon: 'pi pi-pw fa-solid fa-list-tree', routerLink: ['SubEvents'], visible: true, clicked: false },
    
                { id: "location", label: this.translate.instant('EvntMnuChois004'), icon: 'pi pi-pw fa-regular fa-map-location-dot', routerLink: ['location'], visible: true, clicked: false, completed: this.srv.addressBool },
                { label: this.translate.instant('EvntMnuChois005'), icon: 'pi pi-pw fa-regular fa-star-shooting', routerLink: ['profile'], visible: true, clicked: false, completed: this.srv.profileBool },
                { id: "online", label:this.translate.instant('EvntMnuChois006') , icon: 'pi pi-pw fa-regular fa-map-location-dot', routerLink: ['online-page'], visible: true, clicked: false, completed: this.srv.addressBool },
                {
                    label: this.translate.instant('EvntMnuChois007'), icon: 'pi pi-pw fa-regular fa-ticket', routerLink: ['tickets'], visible: true, routerLinkActive: "active", completed: this.srv.TicketsBool, clicked: false, items: [
                        { label: this.translate.instant('EvntMnuChois008'), icon: 'pi pi-pw fa-regular fa-puzzle-piece', routerLink: ['Add-ons'], visible: true, clicked: false },
                        { label:this.translate.instant('EvntMnuChois009'), icon: 'pi pi-pw fa-regular fa-regular fa-tag', routerLink: ['Promo-codes'], visible: true, clicked: false },
                    ]
                },
                { label:this.translate.instant('EvntMnuChois011') , icon: 'pi pi-pw fa-solid fa-list-check', routerLink: ['order-form'], visible: true, clicked: false, items: [
                    { label: this.translate.instant('Order confirmation'), icon: 'pi pi-pw fa-regular fa-puzzle-piece', routerLink: ['Order-confirmation'], visible: true, clicked: false },
                    { label: this.translate.instant('WaitListTitl1'), icon: 'pi pi-pw fa-regular fa-regular fa-tag', routerLink: ['wait-list'], visible: true, clicked: false },
                ] },
                { label: this.translate.instant('EvntMnuChois012'), icon: 'pi pi-pw fa-solid fa-calendar-arrow-up', routerLink: ['publish'], visible: this.canPublish, clicked: false },
            ];
        }
        
        else {
            this.items = [
                { label: this.translate.instant('EvntMnuChois001'), icon: 'pi pi-pw fa-regular fa-circle-info', routerLink: ['basic-info'], visible: true, clicked: false, completed: true },
                { id: "timing", label:this.translate.instant('EvntMnuChois002') , icon: 'pi pi-pw fa-regular fa-stopwatch', routerLink: ['timing'], visible: true, clicked: false, completed: this.srv.TimingBool },
                { id: "Scheduler", label: this.translate.instant('EvntMnuChois003'), icon: 'pi pi-pw fa-regular fa-calendar-days', routerLink: ['calendar'], visible: true, clicked: false, completed: this.srv.TimingBool },
                { id: "Subevent", label: this.translate.instant('EvntMnuChois010'), icon: 'pi pi-pw fa-solid fa-list-tree', routerLink: ['SubEvents'], visible: true, clicked: false },
    
                { id: "location", label: this.translate.instant('EvntMnuChois004'), icon: 'pi pi-pw fa-regular fa-map-location-dot', routerLink: ['location'], visible: true, clicked: false, completed: this.srv.addressBool },
                { label: this.translate.instant('EvntMnuChois005'), icon: 'pi pi-pw fa-regular fa-star-shooting', routerLink: ['profile'], visible: true, clicked: false, completed: this.srv.profileBool },
                { id: "online", label:this.translate.instant('EvntMnuChois006') , icon: 'pi pi-pw fa-regular fa-map-location-dot', routerLink: ['online-page'], visible: true, clicked: false, completed: this.srv.addressBool },
                 { label:  this.translate.instant('EvntMnuChois008'), icon: 'pi pi-pw fa-regular fa-puzzle-piece', routerLink: ['Add-ons'], visible: true, clicked: false },
               
                 { label:this.translate.instant('EvntMnuChois011') , icon: 'pi pi-pw fa-solid fa-list-check', routerLink: ['order-form'], visible: true, clicked: false },
                 { label: this.translate.instant('EvntMnuChois012'), icon: 'pi pi-pw fa-solid fa-calendar-arrow-up', routerLink: ['publish'], visible: this.canPublish, clicked: false },
            ];
        }


    


       

    }

    updatteIte2()
    {
        this.items2 = [{ label: this.translate.instant('EvntMnuChois013'), icon: 'pi pi-pw fa-regular fa-calendar-days', routerLink: ['Dashboard'], visible: true },
        { label: this.translate.instant('EvntMnuChois014'), icon: 'pi pi-pw fa-regular fa-calendar-days', routerLink: ['Marketing'], visible: true },
        { label: this.translate.instant('EvntMnuChois015'), icon: 'pi pi-pw fa-regular fa-calendar-days',  visible: true,
        items: [
            { label: this.translate.instant('EvntMnuChois016'), icon: 'pi pi-pw fa-regular fa-puzzle-piece', routerLink: ['Manage-Attendees'], visible: true, clicked: false },
            { label:this.translate.instant('EvntMnuChois017'), icon: 'pi pi-pw fa-regular fa-regular fa-tag', routerLink: ['Manage-Attendees','new-Attendee'], visible: true, clicked: false },
            { label:this.translate.instant('EvntMnuChois018'), icon: 'pi pi-pw fa-regular fa-regular fa-tag', routerLink: ['Manage-Attendees','attendes'], visible: true, clicked: false },
        ] },
        ];
    }
    ngOnDestroy(): void {
        this.sub.forEach(e => {
            e.unsubscribe();
        })
    }

    getstringLoderr():string{
        if(this.translate.currentLang=='en'){
            return 5-(this.srv.progressValue/20)+this.translate.instant('EvntMnuStps')
        }
        else{
            return (5-(this.srv.progressValue/20)).toString()+this.translate.instant('EvntMnuStps')
        }
        
       
    }
    hasPerant:boolean;
    Parent:string;
    eventTitle: string;
    eventDate: string;
    canSeeTickets = this.auth.securityObject.checkClaim(10105030103);
    canPublish = this.auth.securityObject.checkClaim(10105030102);
    visibleSidebar1;
    items = [];
    items2 = [];
    ngOnInit() {
       // this.eventId = this.activerout.snapshot.paramMap.get('id');
        var param= this.activerout.paramMap.subscribe((param)=>{
            this.eventId=param.get('id');
            if (this.eventId != '0') {
                var eventsub = this.eventSrv.GetEventProgressInfo(this.eventId).subscribe(x => {
                    this.srv.InitState(x);
                    this.srv.setParentId(x.parentId);
                    // if(x.isColection&&x.){
                    //     this.srv.setParentDate(x);
                    // }
                 
                    this.hasPerant=x.parentId!=""?true:false;
                    this.Parent=x.parentId;
                    this.eventTitle = this.srv.title;
                    this.Navigate1(this.activerout.snapshot?.firstChild?.routeConfig?.path);
                    // if (this.auth.securityObject.checkClaim(10105030103)) {
                    //     this.items = [
                    //         { label: 'Basic info', icon: 'pi pi-pw fa-regular fa-circle-info', routerLink: ['basic-info'], visible: true, clicked: false, completed: true },
                    //         { id: "timing", label: 'Timing', icon: 'pi pi-pw fa-regular fa-stopwatch', routerLink: ['timing'], visible: true, clicked: false, completed: this.srv.TimingBool },
                    //         { id: "Scheduler", label: 'Scheduler', icon: 'pi pi-pw fa-regular fa-calendar-days', routerLink: ['calendar'], visible: true, clicked: false, completed: this.srv.TimingBool },
                           
                    //         { id: "Subevent", label: 'Sub events', icon: 'pi pi-pw fa-solid fa-list-tree', routerLink: ['SubEvents'], visible: true, clicked: false },
                           
                    //         { id: "location", label: 'Location', icon: 'pi pi-pw fa-regular fa-map-location-dot', routerLink: ['location'], visible: true, clicked: false, completed: this.srv.addressBool },
                    //         { label: 'Profile', icon: 'pi pi-pw fa-regular fa-star-shooting', routerLink: ['profile'], visible: true, clicked: false, completed: this.srv.profileBool },
                    //         { id: "online", label: 'Online page', icon: 'pi pi-pw fa-regular fa-map-location-dot', routerLink: ['online-page'], visible: true, clicked: false, completed: this.srv.addressBool },
                    //         {
                    //             label: 'Tickets', icon: 'pi pi-pw fa-regular fa-ticket', routerLink: ['tickets'], visible: true, routerLinkActive: "active", completed: this.srv.TicketsBool, clicked: false, items: [
                    //                 { label: 'Add-ons', icon: 'pi pi-pw fa-regular fa-puzzle-piece', routerLink: ['Add-ons'], visible: true, clicked: false },
                    //                 { label: 'Promo codes', icon: 'pi pi-pw fa-regular fa-regular fa-tag', routerLink: ['Promo-codes'], visible: true, clicked: false },
                    //             ]
                    //         },

                    //         { label: 'Order form', icon: 'pi pi-pw fa-solid fa-list-check', routerLink: ['order-form'], visible: true, clicked: false },
                    //         { label: 'Publish', icon: 'pi pi-pw fa-solid fa-calendar-arrow-up', routerLink: ['publish'], visible: this.canPublish, clicked: false },
                    //     ];
                    // }
                    
                    // else {
                    //     this.items = [
                    //         { label: 'Basic info', icon: 'pi pi-pw fa-regular fa-circle-info', routerLink: ['basic-info'], visible: true, clicked: false, completed: true },
                    //         { id: "timing", label: 'Timing', icon: 'pi pi-pw fa-regular fa-stopwatch', routerLink: ['timing'], visible: true, clicked: false, completed: this.srv.TimingBool },
                    //         { id: "Scheduler", label: 'Scheduler', icon: 'pi pi-pw fa-regular fa-calendar-days', routerLink: ['calendar'], visible: true, clicked: false, completed: this.srv.TimingBool },
                    //         { id: "Subevent", label: 'Sub events', icon: 'pi pi-pw fa-solid fa-list-tree', routerLink: ['SubEvents'], visible: true, clicked: false },
    
                    //         { id: "location", label: 'Location', icon: 'pi pi-pw fa-regular fa-map-location-dot', routerLink: ['location'], visible: true, clicked: false, completed: this.srv.addressBool },
                    //         { label: 'Profile', icon: 'pi pi-pw fa-regular fa-star-shooting', routerLink: ['profile'], visible: true, clicked: false, completed: this.srv.profileBool },
                    //         { id: "online", label: 'Online page', icon: 'pi pi-pw fa-regular fa-map-location-dot', routerLink: ['online-page'], visible: true, clicked: false, completed: this.srv.addressBool },
                    //         { label: 'Add-ons', icon: 'pi pi-pw fa-regular fa-puzzle-piece', routerLink: ['Add-ons'], visible: true, clicked: false },
                           
                    //         { label: 'Order form', icon: 'pi pi-pw fa-solid fa-list-check', routerLink: ['order-form'], visible: true, clicked: false },
                    //         { label: 'Publish', icon: 'pi pi-pw fa-solid fa-calendar-arrow-up', routerLink: ['publish'], visible: this.canPublish, clicked: false },
                    //     ];
                    // }

                    this.updateItem();
                    this.updatteIte2();
                    this.checktype(x)
                    // this.items2 = [{ label: 'Dashboard', icon: 'pi pi-pw fa-regular fa-calendar-days', routerLink: ['Dashboard'], visible: true },
                    // { label: 'Marketing', icon: 'pi pi-pw fa-regular fa-calendar-days', routerLink: ['Marketing'], visible: true },
                    // { label: 'Manage attendees', icon: 'pi pi-pw fa-regular fa-calendar-days', routerLink: ['Manage-Attendees'], visible: true },
                    // ];
                    // if (x.isOnline) {
                    //     this.items.find(x => x.id == 'online').visible = true;
                    //     this.items.find(x => x.id == 'location').visible = false;
                    // }
                    // else {
                    //     this.items.find(x => x.id == 'online').visible = false;
                    //     this.items.find(x => x.id == 'location').visible = true;
                    // }
                    // if (x.isRecurring) {
                    //     this.items.find(x => x.id == 'Scheduler').visible = true;
                    //     this.items.find(x => x.id == 'timing').visible = false;
                    // }
                    // else {
                    //     this.items.find(x => x.id == 'Scheduler').visible = false;
                    //     this.items.find(x => x.id == 'timing').visible = true;
                    // }
                    // if (x.isColection) {
                    //     this.items.find(x => x.id == 'Subevent').visible = true;
    
                    // }
                    // else {
                    //     this.items.find(x => x.id == 'Subevent').visible = false;
                    // }
                });
                this.sub.push(eventsub)
                var statsub = this.srv.state$.subscribe(x => {
                    this.eventDate = this.getDateOntomeZone(x.startTime);
                    // if(x.isColection){
                    //     this.srv.setParentId(x.id);
                    //     //this.srv.setParentDate(x)
                    // }
                    var progressub = this.srv.progresSTate$.subscribe(y => {
                        // if (this.auth.securityObject.checkClaim(10105030103)) {
    
                        //     this.items = [
                        //         { label: 'Basic info', icon: 'pi pi-pw fa-regular fa-circle-info', routerLink: ['basic-info'], visible: true, clicked: false, completed: true },
                        //         { id: "timing", label: 'Timing', icon: 'pi pi-pw fa-regular fa-stopwatch', routerLink: ['timing'], visible: true, clicked: false, completed: this.srv.TimingBool },
    
                        //         { id: "Scheduler", label: 'Scheduler', icon: 'pi pi-pw fa-regular fa-calendar-days', routerLink: ['calendar'], visible: true, clicked: false, completed: this.srv.TimingBool },
                        //         { id: "Subevent", label: 'Sub events', icon: 'pi pi-pw fa-solid fa-list-tree', routerLink: ['SubEvents'], visible: true, clicked: false },
                                
                        //         { id: "location", label: 'Location', icon: 'pi pi-pw fa-regular fa-map-location-dot', routerLink: ['location'], visible: true, clicked: false, completed: this.srv.addressBool },
                        //         { label: 'Profile', icon: 'pi pi-pw fa-regular fa-star-shooting', routerLink: ['profile'], visible: true, clicked: false, completed: this.srv.profileBool },
                        //         { id: "online", label: 'Online page', icon: 'pi pi-pw fa-regular fa-map-location-dot', routerLink: ['online-page'], visible: true, clicked: false, completed: this.srv.addressBool },
                        //         {
                        //             label: 'Tickets', icon: 'pi pi-pw fa-regular fa-ticket', routerLink: ['tickets'], visible: true, routerLinkActive: "active", completed: this.srv.TicketsBool, clicked: false, items: [
                        //                 { label: 'Add-ons', icon: 'pi pi-pw fa-regular fa-puzzle-piece', routerLink: ['Add-ons'], visible: true, clicked: false },
                        //                 { label: 'Promo codes', icon: 'pi pi-pw fa-regular fa-regular fa-tag', routerLink: ['Promo-codes'], visible: true, clicked: false },
                        //             ]
                        //         },

                        //         { label: 'Order form', icon: 'pi pi-pw fa-solid fa-list-check', routerLink: ['order-form'], visible: true, clicked: false },
                        //         { label: 'Publish', icon: 'pi pi-pw fa-solid fa-calendar-arrow-up', routerLink: ['publish'], visible: this.canPublish, clicked: false },
                        //     ];
                        // }
                   
                        // else {
                        //     this.items = [
                        //         { label: 'Basic info', icon: 'pi pi-pw fa-regular fa-circle-info', routerLink: ['basic-info'], visible: true, clicked: false, completed: true },
                        //         { id: "timing", label: 'Timing', icon: 'pi pi-pw fa-regular fa-stopwatch', routerLink: ['timing'], visible: true, clicked: false, completed: this.srv.TimingBool },
                        //         { id: "Scheduler", label: 'Scheduler', icon: 'pi pi-pw fa-regular fa-calendar-days', routerLink: ['calendar'], visible: true, clicked: false, completed: this.srv.TimingBool },
                        //         { id: "Subevent", label: 'Sub events', icon: 'pi pi-pw fa-solid fa-list-tree', routerLink: ['SubEvents'], visible: true, clicked: false },
    
                        //         { id: "location", label: 'Location', icon: 'pi pi-pw fa-regular fa-map-location-dot', routerLink: ['location'], visible: true, clicked: false, completed: this.srv.addressBool },
                        //         { label: 'Profile', icon: 'pi pi-pw fa-regular fa-star-shooting', routerLink: ['profile'], visible: true, clicked: false, completed: this.srv.profileBool },
                        //         { id: "online", label: 'Online page', icon: 'pi pi-pw fa-regular fa-map-location-dot', routerLink: ['online-page'], visible: true, clicked: false, completed: this.srv.addressBool },
                        //         { label: 'Add-ons', icon: 'pi pi-pw fa-regular fa-puzzle-piece', routerLink: ['Add-ons'], visible: true, clicked: false },
                               
                        //         { label: 'Order form', icon: 'pi pi-pw fa-solid fa-list-check', routerLink: ['order-form'], visible: true, clicked: false },
                        //         { label: 'Publish', icon: 'pi pi-pw fa-solid fa-calendar-arrow-up', routerLink: ['publish'], visible: this.canPublish, clicked: false },
                        //     ];
                        // }

                        this.updateItem();
                        this.updatteIte2();
    
                        // this.items2 = [{ label: 'Dashboard', icon: 'pi pi-pw fa-regular fa-calendar-days', routerLink: ['Dashboard'], visible: true },
                        // { label: 'Marketing', icon: 'pi pi-pw fa-regular fa-calendar-days', routerLink: ['Marketing'], visible: true },
                        // { label: 'Manage attendees', icon: 'pi pi-pw fa-regular fa-calendar-days', routerLink: ['Manage-Attendees'], visible: true },
                        // ];
                        this.checktype(x)
                        // if (x['isOnline']) {
                        //     this.items.find(x => x.id == 'online').visible = true;
                        //     this.items.find(x => x.id == 'location').visible = false;
    
                        // }
                        // else {
                        //     this.items.find(x => x.id == 'online').visible = false;
                        //     this.items.find(x => x.id == 'location').visible = true;
                        // }
                        // if (x['isRecurring']) {
                        //     this.items.find(x => x.id == 'Scheduler').visible = true;
                        //     this.items.find(x => x.id == 'timing').visible = false;
                        // }
                        // else {
                        //     this.items.find(x => x.id == 'Scheduler').visible = false;
                        //     this.items.find(x => x.id == 'timing').visible = true;
                        // }
                        // if (x['isColection']) {
                        //     this.items.find(x => x.id == 'Subevent').visible = true;
    
                        // }
                        // else {
                        //     this.items.find(x => x.id == 'Subevent').visible = false;
                        // }
                    })
                    this.sub.push(progressub)
                    // this.items = [
                    //     { label: 'Basic info', icon: 'pi pi-pw fa-regular fa-circle-info', routerLink: ['basic-info'] , visible: true , clicked : false ,completed : true},
                    //     { id: "timing", label: 'Timing', icon: 'pi pi-pw fa-regular fa-stopwatch', routerLink: ['timing'] , visible: true , clicked : false , completed: this.srv.TimingBool},
                    //     { id: "Scheduler", label: 'Scheduler', icon: 'pi pi-pw fa-regular fa-calendar-days', routerLink: ['calendar'] , visible: true , clicked : false,  completed: this.srv.TimingBool },
                    //     { id: "location", label: 'Location', icon: 'pi pi-pw fa-regular fa-map-location-dot', routerLink: ['location'] , visible: true , clicked : false,  completed: this.srv.addressBool },
                    //     { label: 'Profile', icon: 'pi pi-pw fa-regular fa-star-shooting', routerLink: ['profile'] , visible: true , clicked : false ,  completed: this.srv.profileBool },
                    //     { id: "online", label: 'Online page', icon: 'pi pi-pw fa-regular fa-map-location-dot', routerLink: ['online-page'] , visible: true , clicked : false ,completed: this.srv.addressBool  },
                    //     { label: 'Tickets', icon: 'pi pi-pw fa-regular fa-ticket', routerLink: ['tickets'] , visible: true ,   routerLinkActive:"active" ,completed: this.srv.TicketsBool ,  clicked : false,items:[
                    //         { label: 'Add-ons', icon: 'pi pi-pw fa-regular fa-circle-info', routerLink: ['Add-ons'], visible: true , clicked : false },
                    //         { label: 'Promo codes', icon: 'pi pi-pw fa-regular fa-circle-info', routerLink: ['Promo-codes'], visible: true , clicked : false },
                    //     ] },
                    //     { label: 'Order form', icon: 'pi pi-pw fa-solid fa-list-check', routerLink: ['order-form'], visible: true , clicked : false },
                    //     { label: 'Publish', icon: 'pi pi-pw fa-regular fa-calendar-days', routerLink: [ 'publish'], visible: true , clicked : false },
                    // ];
    
    
                    //     this.items2 = [{ label: 'Dashboard', icon: 'pi pi-pw fa-regular fa-calendar-days', routerLink: ['Dashboard'], visible: true },
                    //         { label: 'Marketing', icon: 'pi pi-pw fa-regular fa-calendar-days', routerLink: ['Marketing'], visible: true },
                    //         { label: 'Manage attendees', icon: 'pi pi-pw fa-regular fa-calendar-days', routerLink: ['Manage-Attendees'], visible: true },
                    //         ];
                    //     if (x['isOnline']) {
                    //         this.items.find(x => x.id == 'online').visible = true;
                    //         this.items.find(x => x.id == 'location').visible = false;
    
                    //     }
                    //     else {
                    //         this.items.find(x => x.id == 'online').visible = false;
                    //         this.items.find(x => x.id == 'location').visible = true;
                    //     }
                    //     if (x['isRecurring']) {
                    //         this.items.find(x => x.id == 'Scheduler').visible = true;
                    //         this.items.find(x => x.id == 'timing').visible = false;
                    //     }
                    //     else {
                    //         this.items.find(x => x.id == 'Scheduler').visible = false;
                    //         this.items.find(x => x.id == 'timing').visible = true;
                    //     }
                }
                );
                this.sub.push(statsub)
            }
            else {

                this.Navigate1(null);
            }
        });
        // this.router.navigate()
      
    }

    checktype(x){
        if (x['isOnline']) {
            this.items.find(x => x.id == 'online').visible = true;
            this.items.find(x => x.id == 'location').visible = false;

        }
        else {
            this.items.find(x => x.id == 'online').visible = false;
            this.items.find(x => x.id == 'location').visible = true;
        }
        if (x['isRecurring']) {
            this.items.find(x => x.id == 'Scheduler').visible = true;
            this.items.find(x => x.id == 'timing').visible = false;
        }
        else {
            this.items.find(x => x.id == 'Scheduler').visible = false;
            this.items.find(x => x.id == 'timing').visible = true;
        }
        if (x['isColection']) {
            this.items.find(x => x.id == 'Subevent').visible = true;

        }
        else {
            this.items.find(x => x.id == 'Subevent').visible = false;
        }
    }
    itemClicked(item) {
        if (!item.clicked) {

            this.router.navigate(item.routerLink, { relativeTo: this.activerout })
            this.items.forEach(x => {
                x.clicked = false;
                if (x.items) {
                    x.items.forEach(y => {
                        y.clicked = false;
                    })
                }

            })
            item.clicked = true;

        }
    }
    Navigate1(x: string) {
        if (x != null) {
            this.router.navigate([x], { relativeTo: this.activerout });
        }
        else {
            this.router.navigate([

                'basic-info'

            ], { relativeTo: this.activerout });
        }
    }
    getDateOntomeZone(d: Date | number) {
        var eventOffset = this.srv.offSet * 60;
        var MyDate = new Date(d).setMinutes(new Date(d).getMinutes() + (eventOffset));
        var m = new Date(MyDate);
        return m.toLocaleString()
    }

    getDateOntomeZoneformat(d,x){
        m.locale();
        let t =0
        // this.srv.offSet * 60;
        let MyDate = new Date(d).setMinutes(new Date(d).getMinutes() + (t));
        var l="";
        switch(x){
          case 'full':if(new Date(MyDate).getFullYear()==new Date(Date.now()).getFullYear()){
            l=  m(new Date(MyDate)).format('ddd MMMM D hh:mm a')
           // this.datePipe.transform(new Date(MyDate), 'EEE MMMM d hh:mm a');
            
        }else{
           l=  m(new Date(MyDate)).format( 'ddd MMMM D,y hh:mm a')
           //this.datePipe.transform(new Date(MyDate), 'EEE MMMM d,y hh:mm a');
         
        }
        break;
        case 'month': l=this.datePipe.transform(new Date(MyDate), 'MMM');
        break;
        case 'day':l=this.datePipe.transform(new Date(MyDate), 'dd');
        break
        }
        return l
        
    
    
        
       // return new Date(MyDate).toLocaleString();
    }
}
