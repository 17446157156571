import { Component, OnInit } from '@angular/core';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';

import { ActivatedRoute, Router } from '@angular/router';
import { OrgnizersService, member, member1, teammembers } from 'src/app/services/orgnizers.service';
import { AssignRoleComponent } from './assign-role/assign-role.component';
import { transition, style, animate, trigger } from '@angular/animations';
import { LoaderService } from 'src/app/services/loader.service';
import { ConfirmationService, MessageService } from 'primeng/api';
import { InviteTeamMemberComponent } from '../../invite-team-member/invite-team-member.component';
import { TranslateService } from '@ngx-translate/core';


const leaveTrans = transition(':leave', [
  style({
      opacity: 1
  }),
  animate('1s ease-out', style({
      opacity: 0
  }))
])



const fadeOut = trigger('fadeOut', [
  leaveTrans
]);


@Component({
  selector: 'app-team-members',
  templateUrl: './team-members.component.html',
  styleUrls: ['./team-members.component.scss'],
  animations : [fadeOut]
})
export class TeamMembersComponent implements OnInit{
  data :teammembers[] =[]
  data1 : any[] =[];
  id : string;

  ref: DynamicDialogRef;


  delete(event,id : any){
    this.conSrv.confirm({

      target: event.target,
      message: this.translate.instant('Are you sure that you want to proceed?'),
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        var del=    this.orgSrv.deletOrgteam(this.id,id).subscribe(x => {


            this.orgSrv.getTeamMembers(this.id);
          this.messageService.add({ severity: 'success', summary: this.translate.instant('Deleted successfully') });
        });
          // this.messageService.add({ severity: 'info', summary: 'Confirmed', detail: 'You have accepted' });
      },
      reject: () => {

      }
  });
  }
  edit(userId : any){
    this.ref = this.dialogService.open(AssignRoleComponent, {
      header: this.translate.instant('Select role for user'),
      width: '50%',

      contentStyle: {overflow: 'auto'},
      baseZIndex: 10000,
      data:{
        id : this.id
      }
  });
  this.ref.onClose.subscribe((roleId: string ) => {
    if(roleId != undefined){
     this.orgSrv.PostRoleForUser(userId, roleId ,this.id);
     this.orgSrv.getTeamMembers(this.id);
     this.orgSrv.$getSubject.subscribe(e => this.data = e);
    }
  });
  }
  ngOnInit(): void {
    this.loader.showLoader1();
    this.id = this.activatedroute.parent.snapshot.paramMap.get('id');
    this.orgSrv.getOneOrg(this.id).subscribe(x => {this.data1 = x})
    this.orgSrv.getTeamMembers(this.id);
    this.orgSrv.$getSubject.subscribe(e => {this.data = e , this.loader.hideLoader1()});

  }
  openNew(): void {
  //  console.log(this.id)
    this.ref = this.dialogService.open(InviteTeamMemberComponent, {
      header:this.translate.instant('Invite new team member') ,
      // width: '50%',
      contentStyle: {overflow: 'auto'},
      baseZIndex: 10000,
      data : {
          org: this.data1 ,
          showOrg : this.id 
      }
  });
  this.ref.onClose.subscribe((data : member1) => {
    if(data != undefined){
        //  console.log(data)
          this.orgSrv.isSubmitting = true;
          this.orgSrv.postInvite(data)
     }
  });
}
  constructor(public conSrv : ConfirmationService, private translate:TranslateService,
    public loader : LoaderService,private orgSrv : OrgnizersService
    ,private dialogService: DialogService,
    private router:Router , private activatedroute : ActivatedRoute , private messageService: MessageService,) {


  }
}
