import { AfterContentInit, AfterViewInit, Component, ContentChild, ElementRef, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { ActivatedRoute, Data, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { DropDownItem } from 'src/app/Interfaces/DropDownItem';
import { ticketsFormDto } from 'src/app/Interfaces/TicketsDtoS';
import { EventStepsService } from 'src/app/services/event-steps.service';
import { EventsService } from 'src/app/services/events.service';
import { TicketFormStepsService } from 'src/app/services/ticket-form-steps.service';
import { TicketsService } from 'src/app/services/tickets.service';

@Component({
    selector: 'app-tickets-form',
    templateUrl: './tickets-form.component.html',
    styleUrls: ['./tickets-form.component.scss']
})
export class TicketsFormComponent implements OnInit {
    helpStepsModal: boolean;
    position: string;
    PageTitle:string=""
    info: string;
    Date: string;
    setting: string;
    inital: string;
    showHelpStepsModal() {
        this.helpStepsModal = true;
    }



    items = [];
    /**
     *
     */
    constructor(private router: Router, private activatedRoute: ActivatedRoute,
        private translate: TranslateService,
         private eventStepsSrv: EventStepsService,private srv: TicketFormStepsService) {
            this.translate.onLangChange.subscribe(() => {
                this.updateTranslations();
              });


    }



    ngOnInit(): void {


        this.srv.myform.controls['eventType'].setValue(this.eventStepsSrv.isRecurring)

        this.updateTranslations();

        // this.translate.onLangChange.subscribe(() => {
        //     this.translate.get('TcktFrmInfoInptTitl').subscribe((title: string) => {
        //       this.info=title;
        //     });
        //     this.translate.get('TcktFrmDateTitl').subscribe((title: string) => {
        //         this.Date=title;
        //       });
        //       this.translate.get('TcktFrmStngsTitl').subscribe((title: string) => {
        //         this.setting=title;
        //       });
        //       this.translate.get('TcktFrmSlctEvetInstncTitl').subscribe((title: string) => {
        //         this.inital=title;
        //       });

        //       if (this.eventStepsSrv.isRecurring ||this.eventStepsSrv.isColection ) {

        //         if( this.srv.myform.controls['id'].value!='0') {
        //             this.items = [
        //                 { label: this.info, routerLink:  ['info'] },
        //                 { label: this.Date, routerLink: ['date']  },
        //                 { label:this.setting, routerLink:  ['settings'] }
        //             ];
        //           // this.PageTitle="Update tickets"
        //           this.PageTitle="TcktFrmTite002"
                 
        //             this.router.navigate( ['info'] , { relativeTo: this.activatedRoute });
        //         }
        //         else{
        //            // this.PageTitle="Add new ticket"
        //            this.PageTitle="TcktFrmTite001" 
        //             this.items = [{ label: this.inital, routerLink:  ['initial']  },
        //             { label: this.info, routerLink:  ['info'] },
        //                 { label: this.Date, routerLink: ['date']  },
        //                 { label:this.setting, routerLink:  ['settings'] }]
        //             this.router.navigate(['initial'], { relativeTo: this.activatedRoute });
        //         }
    
    
        //     }
        //     else {
        //         if( this.srv.myform.controls['id'].value!='0'){
        //             //this.PageTitle="Update tickets"
        //             this.PageTitle="TcktFrmTite002"
        //         }else{
        //             //this.PageTitle="Add new ticket"
        //             this.PageTitle="TcktFrmTite001" 
        //         }
        //         this.srv.myform.controls['events'].setValue([this.eventStepsSrv.id])
        //         this.items = [
        //             { label: this.info, routerLink:  ['info'] },
        //             { label: this.Date, routerLink: ['date']  },
        //             { label:this.setting, routerLink:  ['settings'] }
        //         ];
    
        //         this.router.navigate( ['info'] , { relativeTo: this.activatedRoute });
        //     }



        //   });


      

    }


    updateTranslations(){

        if (this.eventStepsSrv.isRecurring ||this.eventStepsSrv.isColection ) {

            if( this.srv.myform.controls['id'].value!='0') {
                this.items = [
                    { label:this.translate.instant('TcktFrmInfoInptTitl'), routerLink:  ['info'] },
                    { label:this.translate.instant('TcktFrmDateTitl'), routerLink: ['date']  },
                    { label: this.translate.instant('TcktFrmStngsTitl'), routerLink:  ['settings'] }
                ];
              // this.PageTitle="Update tickets"
              this.PageTitle="TcktFrmTite002"
             
                this.router.navigate( ['info'] , { relativeTo: this.activatedRoute });
            }
            else{
               // this.PageTitle="Add new ticket"
               this.PageTitle="TcktFrmTite001" 
                this.items = [{ label: this.translate.instant('TcktFrmSlctEvetInstncTitl'), routerLink:  ['initial']  },
                { label:this.translate.instant('TcktFrmInfoInptTitl'), routerLink:  ['info'] },
                { label:this.translate.instant('TcktFrmDateTitl'), routerLink: ['date']  },
                { label: this.translate.instant('TcktFrmStngsTitl'), routerLink:  ['settings'] }
            ];
                this.router.navigate(['initial'], { relativeTo: this.activatedRoute });
            }


        }
        else {
            if( this.srv.myform.controls['id'].value!='0'){
                //this.PageTitle="Update tickets"
                this.PageTitle="TcktFrmTite002"
            }else{
                //this.PageTitle="Add new ticket"
                this.PageTitle="TcktFrmTite001" 
            }
            this.srv.myform.controls['events'].setValue([this.eventStepsSrv.id])
            this.items = [
                { label:this.translate.instant('TcktFrmInfoInptTitl'), routerLink:  ['info'] },
                { label:this.translate.instant('TcktFrmDateTitl'), routerLink: ['date']  },
                { label: this.translate.instant('TcktFrmStngsTitl'), routerLink:  ['settings'] }
            ];

            this.router.navigate( ['info'] , { relativeTo: this.activatedRoute });
        }


    }
    

}


