import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment as env } from 'src/environments/environment';
import {MessageService} from 'primeng/api';
export interface VmOnlineEvent {
    id: string;
    eventId: string;
    description: string;
    links: VmOnlineLink[];
}
export interface VmOnlineLink {
    id: string;
    title: string;
    link: string;
    editor: string;
    image: string;
    // tickets: string[];
}


@Injectable({
    providedIn: 'root'
})
export class OnlineEventProfileService {
    getOnlineEventProfile(x: string) {
        return this.httpClient.get(this.url + 'd/e/g/online/' + x);
    }
    url = env.Api;
    constructor(public httpClient: HttpClient, public messageService: MessageService) { }
    postOnlineEventProfile(x: VmOnlineEvent) {


        return this.httpClient.post<VmOnlineEvent>(this.url + 'd/e/c/online', x);



    }

    getAllTickets(id: string) {


        return this.httpClient.get<any[]>(this.url+'d/t/g/t/all/'+id);

      }

}
