import { MessageService } from 'primeng/api';
import  jwtDecode  from 'jwt-decode';


import { Permission } from './orgnizers.service';
import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {tap} from 'rxjs/operators';
import {environment as env} from 'src/environments/environment';

import { AppUserAuth } from '../security/app-user-auth';
import { Router } from '@angular/router';

import { options } from '@fullcalendar/core/preact';
import { Observable, ReplaySubject, Subject } from 'rxjs';
import { refTok } from './common.interceptor';

interface loginForm {
    username: string;
    password: string;
    rememberMe: boolean;
}

export interface register {
    firstName: string;
    lastName: string;
    email: string;
    paassword: string;
}


export interface User {
    id : string;
    firstName : string;
    lastName :string;
    email : string;
    token:string;
    refrToken:string;
    permissions : any[]
}
export interface resetPassword{
    email : string;
    token : string;
    password : string;
}
export interface changpassDto{
    currentpassword:string;
    password:string;
}
@Injectable({
    providedIn: 'root'
})

export class AuthService {
    isSubmitting : boolean = false;
    securityObject: AppUserAuth = new AppUserAuth();
    private subject: Subject<boolean> = new ReplaySubject<boolean>(1);

    get $getUserlogin(): Observable<any> {
  
      return this.subject.asObservable();
    }

    constructor(private router : Router,private http: HttpClient,private messageService : MessageService) {
        this.check();
    }

    loadUser() {
        const request = this.http.get<any>('/api/user');
        // request.subscribe(user => this.user = user);
    }

    //assign securit object after refreshing for service
    check(){
        let x = localStorage.getItem("user");

        if (x) {

            let zzz : any= jwtDecode(x);
            this.securityObject.token = zzz.token;

            Object.assign(this.securityObject , zzz);
            this.securityObject.isAuthenticated = true;
            this.subject.next(true)


            this.securityObject.permissions = JSON.parse(zzz.permissions)
            this.securityObject.UserRole = JSON.parse(zzz.userRole);


        }

    }

   checkUserProfile(){
    return this.http.get<boolean>(env.Api+'d/up/c/g');
   }


    checKAdmin(){
      var l=  this.securityObject.UserRole.find(x=>x=='de0f642f-ad5c-469b-9e68-6f542d6cdb92')
      if(l){
        return true;
      }else{
        return false;
      }
    }
    resetPassword(email : string){
        this.isSubmitting = true;
        var data = {
            email : email
        }
        
        this.http.post(env.Api + "api/auth/c/p/r" ,data).subscribe(x => {
            this.isSubmitting = false
            this.messageService.add({severity:"success" , summary : "Go check your email" , life:5000})
        }, err => {this.messageService.add({severity:"error" , summary:"Somthing went wrong please try again later" , life : 5000});this.isSubmitting = false})
    }
    resetPasswordConfirm(data : resetPassword){
        this.isSubmitting = true
        this.http.post(env.Api + "api/auth/c/p" ,data).subscribe(x => {
            this.isSubmitting = false
            this.messageService.add({severity : "success" , summary : "Pasword changed successfuly" , life:3000})
        } , 
        err => {
            this.isSubmitting = false;
            
            this.messageService.add({severity : "error" , summary : "Somthing went wrong please try again later" , life:3000})

        });
    }
   
    changepassword(data:changpassDto){
        this.isSubmitting = true
        this.http.post(env.Api + "api/auth/c/p/p" ,data).subscribe(x => {
            console.log(x)
            this.isSubmitting = false
            this.messageService.add({severity : "success" , summary : "Pasword changed successfuly" , life:3000});
            

        } , 
        err => {
            console.log(err)
            this.isSubmitting = false;
            
            this.messageService.add({severity : "error" , summary : err.error , life:3000})

        });

    }

    login(loginForm: any,invitation: string="null") {
        this.isSubmitting = true;
        return this.http.post<User>(
            env.Api + 'api/auth/login/'+invitation,
            loginForm, {withCredentials: true}).pipe(tap(x => {
            localStorage.setItem('user' , x.token);
            localStorage.setItem('refreah' , x.refrToken);
            let zzz : any= jwtDecode(x.token);
            this.securityObject.token = x.token;

            Object.assign(this.securityObject , zzz);
            this.securityObject.permissions = JSON.parse(zzz.permissions)
            this.securityObject.UserRole = JSON.parse(zzz.userRole)
          //  console.log(this.securityObject)
            this.securityObject.isAuthenticated = true;
            this.subject.next(true);
            


        }));
    }

    register(data: register, invitation: string) {
        this.isSubmitting = true;
        this.http.post(env.Api + 'api/auth/register/' + invitation ?? '', data).subscribe(x =>{this.messageService.add({severity:'success', summary:'Registered successfully'});this.router.navigate(['emailCheck'],);this.isSubmitting = false}
            , err => {this.messageService.add({severity:'error', summary:err.error[0].description , life : 3000});this.isSubmitting = false}
            );
    }
    getDataForRegister(id) :Observable<{firstName : string , lastName : string , email : string}>{
       return this.http.get<{firstName : string , lastName : string , email : string}>(env.Api + "api/auth/check/" + id)
    }

    logout() {

        this.securityObject.init();
        localStorage.removeItem('user');
        localStorage.removeItem('refreah');
        this.subject.next(false)
        this.router.navigate(["login"])

    }


    rexfreachToken(token,reftoken){
         var data={
            token:token,
            RefToken:reftoken
         }
       return this.http.post<refTok>(env.Api + 'api/auth/Refreach', data)
           
    }

}

