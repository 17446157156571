import { DOCUMENT } from '@angular/common';
import { Component, OnInit ,Inject} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DropDownItem } from 'src/app/Interfaces/DropDownItem';
import { AddOnsStepsService } from 'src/app/services/add-ons-steps.service';
import { AddOnsService } from 'src/app/services/add-ons.service';
import { EventStepsService } from 'src/app/services/event-steps.service';
import { EventsService } from 'src/app/services/events.service';

@Component({
  selector: 'app-addon-info',
  templateUrl: './addon-info.component.html',
  styleUrls: ['./addon-info.component.scss']
})
export class AddonInfoComponent  implements OnInit {
  submitted:boolean=false;
    croppedImage:string="";
    cat:DropDownItem[]=[];
    constructor( @Inject(DOCUMENT) public document: Document,private router:Router,private activatedRoute:ActivatedRoute,public srv:AddOnsStepsService,private AddOnsSrv:AddOnsService,private AttrSrv:EventsService,public EventStepSrv:EventStepsService){
    }
    ngOnInit(): void {
      if(this.srv.AddonsForm.controls['id'].value!="0"){
        this.croppedImage = this.AddOnsSrv.getImage(this.srv.AddonsForm.controls['image'].value);
      }
      this.AttrSrv.GetAttributes(10112).subscribe(x=>{
        this.cat=x;
      })
    }
    cancel(){
      this.srv.clear();
      this.router.navigate( ['Add-ons'] ,{relativeTo: this.activatedRoute.parent.parent.parent});
    }
    submit(){
       if(this.validateAddOnsInfo()){
        this.router.navigate( ['sales-date'] ,{relativeTo: this.activatedRoute.parent});
       }else{
        this.submitted=true;
       }

    }
    previous(){
        this.router.navigate(['initial'] ,{relativeTo: this.activatedRoute.parent});
    }
    setPhoto(e){
        this.srv.AddonsForm.controls['image'].setValue(e)

    }
    setIsChanged(e){
        this.srv.AddonsForm.controls['imageChanged'].setValue(e)
    }

    validateAddOnsInfo(){
      return   this.srv.AddonsForm.controls['name'].valid&&this.srv.AddonsForm.controls['price'].valid
         &&this.srv.AddonsForm.controls['quantity'].valid  &&this.srv.AddonsForm.controls['image'].valid
         &&this.srv.AddonsForm.controls['description'].valid;
     }


}
