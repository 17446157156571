<div class="container  rounded-25 bg-modified  p-0">
    <div class="d-flex align-items-center justify-content-center rounded-25   position-relative">
        <div class="love-btn ">
            <button type="button" (click)="Like(item?.id)"><i class="fa-solid fa-user-plus text-danger"></i></button>
        </div>
        <div class="Users-btn ">
            <button type="submit"><i class=" fas fa-users"></i> {{item?.numOfFollowers}} </button>
        </div>
        <div class=" text-center pt-5 bg-white organizer-space shadow-sm  rounded-25">
            <div class="d-flex justify-content-center boxSh">
                <img style="height: 200px; width: 200px;" [src]="url + item?.imageUrl" alt="Organizer Profile Photo"
                    class=" rounded-circle" />
            </div>
            <div class=" mt-3">
                <h3> <a [routerLink]="'/organizers/preview/'+item.id" class=" text-lenght fw-bold">
                        {{item?.name}}
                    </a></h3>
                <div class="my-5 ">
                    <span *ngFor="let l of linksForPreview;let i=index" class="m-1">
                        <a href="{{'https://' + l?.url}}" target="_blank" class="social-btn rounded-circle ">
                            <i class="{{l?.icon}}"
                                [ngStyle]="{width: (l.icon =='fa-brands fa-youtube'  ) ? '15px' : 'inherit', 'margin-left': (l.icon =='fa-brands fa-youtube'  ) ? '-2px' : 'inherit'}"></i><span></span>
                        </a>
                    </span>
                </div>
            </div>
        </div>
    </div>
</div>