<div class=" rounded-25 mt-3">
    <div class="container rounded-top-right-25 rounded-top-left-25 bg-modified  p-0">
        <div class="d-flex align-items-center justify-content-center rounded-top-right-25 rounded-top-left-25">



            <div class=" text-center pt-3 bg-white organizer-space shadow-sm rounded-top-right-25 rounded-top-left-25 ">
                <div class="d-flex justify-content-center">
                    <img style="height: 250px;" [src]="url + imageUrl" alt="Organizer Profile Photo"
                        class=" rounded-circle" />

                </div>
                <div class="">
                    <h3 class="text-primary mt-3 text-lenght fw-bold">
                        <i class="fas fa-user"></i> {{name}}
                    </h3>

                    <div class="text-center my-3">

                        <a href="javascript:;" class="follow-btn rounded-pill ms-2" (click)="showContactModal()">
                            <i class="fas fa-headset"></i> {{'CntactBtn'|translate}} <span></span></a>

                    </div>

                    <p-dialog [closable]="true" [(visible)]="helpStepsModal" [modal]="true" class="bg-dark"
                        [draggable]="false" [resizable]="false" [showHeader]="false"
                        [breakpoints]="{'960px': '540px', '600px': '320px'}"
                        [style]="{'width': '540px','border-radius':'25px'}">
                        <div class="position-relative " style="text-align: start;">
                            <img class="call-cont-img" src="../../../../assets/img/Logo/Contact-Us-on.jpg" alt="">

                            <button class="btn border-0 contact-close-btn" type="button"
                                (click)="helpStepsModal=false"><i class="fa-solid fa-xmark"></i></button>
                            <h1 class="cont-frase">{{'CntactMdl'|translate}}</h1>
                            <div class="contact-info">
                                <p class="cont-num text-white"><i class="fa-solid fa-phone  m-2"></i> <a class=""
                                        href="tel:{{mobileNum}}">{{mobileNum}}</a> </p>
                                <p class="cont-email text-white"><i class="fa-solid fa-phone m-2"></i><a class=""
                                        href="tel:{{phoneNum}}">{{phoneNum}}</a></p>
                            </div>
                        </div>
                    </p-dialog>
                    <div class="d-flex justify-content-center my-3">
                        <p class="mx-3">{{totalfollwers}} <br /> <span><i class="fas fa-users"></i>
                                {{'OrgnizerProfDsc001'|translate}}</span></p>
                        <p class="mx-3">{{eventcount}} <br /> <span><i class="fas fa-calendar-alt"></i>
                                {{'OrgnizerProfDsc002'|translate}}</span></p>
                    </div>

                    <!-- <div class="my-3 ">
                        <span *ngFor="let l of linksForPreview" class="m-1">
                            <a href="{{'https://' + l?.url}}" target="_blank" class="social-btn rounded-circle ">
                                <i class="{{l?.icon}}" style="width: 15px;"></i><span></span>
                            </a>
                        </span>
                    </div> -->
                </div>

            </div>

        </div>
    </div>

    <div class="waves container p-0">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
            <path fill="#FFF3E5" fill-opacity="1"
                d="M0,96L120,117.3C240,139,480,181,720,192C960,203,1200,181,1320,170.7L1440,160L1440,320L1320,320C1200,320,960,320,720,320C480,320,240,320,120,320L0,320Z">
            </path>
        </svg>

    </div>
    <section class="container bg-modified rounded-bottom-right-25 rounded-bottom-left-25">
        <div class="row">
            <div *ngFor="let item of EventCards; index as i;" class="col-lg-4 col-md-6">
                <app-event-card class="flex-card-small" [item]="item" (likedClicked)="Like($event)"></app-event-card>
            </div>
        </div>
    </section>
</div>
