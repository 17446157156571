<form [formGroup]="srv.AddonsForm" (submit)="submit()" autocomplete="off">

    <div class="">

        <div class="row mb-2">
            <div class=" p-input-filled col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6 mb-2">
                <span class="p-float-label">
                    <p-calendar dataType="string" appendTo="body" #op1
                        pTooltip="{{'AddOnFrmSlsDateInpt001Pop'|translate}}" showDelay="1000" tooltipPosition="bottom"
                        [showTime]="true" [hourFormat]="12" [showSeconds]="false" inputId="time"
                        [styleClass]="'form-control m-0 p-0'" [inputStyleClass]="'form-control'"
                        formControlName="startDate" [minDate]="minValue" [maxDate]="maxValue2" [stepMinute]="1"
                        [ngClass]="{
                        'ng-invalid ng-dirty':
                            (srv.AddonsForm.get('startDate')?.touched &&
                            srv.AddonsForm.get('startDate')?.invalid)||(submitted&& srv.AddonsForm.get('startDate').invalid)
                    }" (onSelect)="setEndDateMin($event)">
                        <ng-template pTemplate="date" let-date>
                            <span [ngStyle]="{color:getDateBoolean(date) ? 'red' : 'inherit'}">{{date.day}}</span>
                        </ng-template>
                        <ng-template pTemplate="footer">
                            <p class="clock-positon"> <i class="fa-solid fa-clock"></i> </p>
                            <!-- <p class="" style="padding-left: 1.1rem;position:relative;translate: 0 -80px;"> <i style="font-size: 1.1rem" class="fa-solid fa-clock"></i> </p> -->
                        </ng-template>


                    </p-calendar>
                    <label class="form-lable font-size-14" for="salesStart">{{'AddOnFrmSlsDateInpt001'|translate}}
                    </label>
                </span>
                <small class="p-error" *ngIf="(srv.AddonsForm.get('startDate')?.touched &&
                srv.AddonsForm.get('startDate')?.invalid)||(submitted&& srv.AddonsForm.get('startDate').invalid)
                    ">{{'AddOnFrmSlsDateInpt001ErrMsg'|translate}}</small>

            </div>
            <div class=" p-input-filled col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6 mb-2  mb-2">
                <span class="p-float-label">

                    <p-calendar dataType="string" appendTo="body" #op
                        pTooltip="{{'AddOnFrmSlsDateInpt002Pop'|translate}}" showDelay="1000" tooltipPosition="bottom"
                        [showTime]="true" [hourFormat]="12" [minDate]="MaxValue" [maxDate]="maxValue2" [stepMinute]="1"
                        formControlName="endDate" [showSeconds]="false" inputId="time"
                        [styleClass]="'form-control m-0 p-0'" [inputStyleClass]="'form-control '" [ngClass]="{
                        'ng-invalid ng-dirty':
                            (srv.AddonsForm.get('endDate')?.touched &&
                            srv.AddonsForm.get('endDate')?.invalid)||(submitted&& srv.AddonsForm.get('endDate').invalid)
                    }">
                        <ng-template pTemplate="date" let-date>
                            <span [ngStyle]="{color:getDateBoolean(date) ? 'red' : 'inherit'}">{{date.day}}</span>
                        </ng-template>
                        <ng-template pTemplate="footer">
                            <p class="clock-positon"> <i class="fa-solid fa-clock"></i> </p>
                            <!-- <p class="" style="padding-left: 1.1rem;position:relative;translate: 0 -80px;"> <i style="font-size: 1.1rem" class="fa-solid fa-clock"></i> </p> -->
                        </ng-template>
                    </p-calendar>




                    <label class="form-lable font-size-14" for="salesEnd">{{'AddOnFrmSlsDateInpt002'|translate}}
                    </label>
                </span>
                <small class="p-error" *ngIf="(srv.AddonsForm.get('endDate')?.touched &&
                    srv.AddonsForm.get('endDate')?.invalid)||(submitted&& srv.AddonsForm.get('endDate').invalid)
                        ">{{'AddOnFrmSlsDateInpt002ErrMsg'|translate}}</small>
            </div>
        </div>
    </div>
    <div class="col-12 d-flex  justify-content-center">
        <div class="banner-btn rounded-pill mx-1">

            <button pTooltip="go back tdo previous step" type="button" showDelay="1000" tooltipPosition="left"
                class="rounded-pill cancel-btn" (click)="previous()"><i class="pi mt-1"
                    [class.pi-angle-right]="document.documentElement.dir === 'rtl'"
                    [class.pi-angle-left]="document.documentElement.dir === 'ltr'"></i> {{'PrviosBtn'|translate}}
                <span></span></button>
            <button pTooltip="Cancel and dismiss changes" type="button" showDelay="1000" tooltipPosition="bottom"
                class="rounded-pill save-btn ms-1" (click)="cancel()">{{'CanclBtn'|translate}} <span></span></button>
            <button pTooltip="Save and go to next step" showDelay="1000" tooltipPosition="bottom"
                class="rounded-pill next-btn  ms-1 mt-md-0 mt-1">{{'NextBtn'|translate}} <i class="pi mt-1"
                    [class.pi-angle-right]="document.documentElement.dir === 'ltr'"
                    [class.pi-angle-left]="document.documentElement.dir === 'rtl'"></i><span></span></button>
        </div>
    </div>


</form>