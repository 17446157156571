import { HttpClient } from '@angular/common/http';
import { Component, OnDestroy, OnInit,Inject } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { DropdownItem } from 'primeng/dropdown';

import { DropDownItem } from 'src/app/Interfaces/DropDownItem';
import { environment } from 'src/environments/environment';
import * as moment from 'moment';
import { OrdernotisignalRService } from 'src/app/services/ordernotisignal-r.service';
import { DOCUMENT, DatePipe } from '@angular/common';
import { MessageService } from 'primeng/api';
import { OrderConfigDto, SubmitWaitlistMemberDTo } from 'src/app/Interfaces/OrderIfo';
import { Subscription } from 'rxjs';
import * as m from 'moment';
import 'moment/locale/pt-br';
import { TranslateService } from '@ngx-translate/core';

 export interface items{
    id?:string;
    name?:string;
    delivryMethod?:DropDownItem[];
 }

 export interface itemshipingaddress{

    isUsLocal:boolean;
    address:string;
    id:string;
    country:string;
    city:string;
    lat: number;
    lng:number;
 }


export interface OrderCheckout {
  id: string;
  price: number;
  qty: number;
  old: number;
  name: string;
  curSymbol: string;
  delviryMethod?: string;
  maxPerTicket: number
  minPerTicket: number

}

export interface OrderDataDto {

  displayEnd?: boolean;
  displayStart?: boolean
  eventEnd?: Date;
  eventId?: string;
  eventImage?: string;
  eventName?: string;
  eventStart?: Date;
  addOns?: AddOn[];
  tickets?: Ticket[];
  isForBuyer ?: boolean;
}

export interface AddOn {
  id: string
  name: string
  photo: string
  varieties: Variety[]
}

export interface Variety {
  id: string
  name: string
  eTicket: boolean
  willCall: boolean
  maxPerOrder: number
  minPerOrder: number
  price: number
  newPrice: number
  addStart: string
  addEnd: string
  isSoldOut: boolean
  varietyImage: string;
  curSymbol: string;

}

export interface Ticket {
  id: string
  name: string
  description: string
  eTicket: boolean
  willCall: boolean
  maxPerOrder: number
  minPerOrder: number
  maxPerTicket: number
  minPerTicket: number
  price: number
  type: string
  newPrice: number
  ticketStart: string
  ticketEnd: string
  isSoldOut: boolean
  curSymbol: string
}

export interface postAddOnsDto{
eventId?:string
  promo? :string;
 order?:VmOrderItem[],
 formAnswer?: string,
 shippingCost?:number,
 email?:string,
 type?:string
}
export interface VmOrderItem{
  id? :string;
 qty ?:number;
  deleveryMethods?:string;
   price?:number ;
   orderForm?:string;
   shipping?:VMaddresShipping
}
export interface VMaddresShipping{
    address: string
    city:string
    country: string
    isUsLocal: boolean
    lat:number
    lng: number
}
@Component({
    selector: 'app-order-event',
    templateUrl: './order-event.component.html',
    styleUrls: ['./order-event.component.scss'],
})
export class OrderEventComponent implements OnInit, OnDestroy {
    url = environment.Api;

    id: string;
    subs: Subscription[] = [];
    configdata: OrderConfigDto = {};
    data: OrderDataDto = {};
    Addressdata: any[] = [];
    show: boolean[] = [];
    CheckOut: OrderCheckout[] = [];
    promoCode: string;
    firstStep: boolean = false;
    allitem: items[] = [];
    form1!: FormGroup;
    WaitlistForm!: FormGroup;
    ordertick: OrderCheckout[] = [];
    formData: any[] = [];
    Dataforpost: postAddOnsDto = {};
    noti: any[] = [];
    shippingCost: number=0;
    constructor(
        private http: HttpClient,
        private messageService: MessageService,
        @Inject(DOCUMENT) public document: Document,
        private activatedRoute: ActivatedRoute,
        private fb: FormBuilder,
        private orderSrv: OrdernotisignalRService,
        private datePipe: DatePipe,
        private router: Router,private translate:TranslateService
    ) {
        this.translate.onLangChange.subscribe(() => {
            m.locale(this.translate.currentLang);
          });
    }

    // updatetranslate(){
    //   checkStartDateForItem(getDateOntomeZone1(ticket.ticketStart))?("Sales start at: "+getDateOntomeZone( ticket.ticketEnd)):(checkEndDateForItem(getDateOntomeZone1(ticket.ticketEnd))?"SalesEnd":"Sales end at: " +getDateOntomeZone(ticket.ticketEnd))
    // }
    ngOnDestroy(): void {
        this.subs.forEach((x) => {
            x.unsubscribe();
        });
        this.orderSrv.stopconnection();
    }

    visible: boolean;
    showDialog() {
        this.visible = true;
    }
    checkStartDateForItem(d: Date): boolean {
        var i = new Date(Date.now());
        var IsBefor = moment(i).isBefore(d);

        // console.log(IsBefor)
        return IsBefor;
    }
    checkEndDateForItem(d: Date): boolean {
        var i = new Date(Date.now());

        var IsAfter = moment(i).isAfter(d);
        // console.log(IsAfter)
        return IsAfter;
    }
    get orderForm() {
        return this.form1.get('order') as FormArray;
    }
    inilizw(id) {
        this.form1 = this.fb.group({
            id: [id],
            order: this.fb.array([]),
        });
        this.WaitlistForm = this.fb.group({
            email: [, Validators.required],
            name: [, Validators.required],
            phone: [],
            waitListId: [],
            eventId: [],
            endDate: [],
        });
    }
    itOpen(): boolean {
        return this.show.some((x) => x == true);
    }
    shown(i) {
        if (this.show[i]) {
            this.show[i] = false;
        } else {
            this.show[i] = true;
        }
    }

    getendDate(): string {
        var end = new Date(Date.now());
        if (this.configdata.waitlist.maxSeconde) {
            end = new Date(
                end.setDate(end.getDay() + this.configdata.waitlist.maxSeconde)
            );
        }
        if (this.configdata.waitlist.maxHours) {
            end = new Date(
                end.setHours(end.getHours() + this.configdata.waitlist.maxHours)
            );
        }
        if (this.configdata.waitlist.maxMinute) {
            end = new Date(
                end.setMinutes(
                    end.getMinutes() + this.configdata.waitlist.maxMinute
                )
            );
        }

        return this.datePipe.transform(end, 'EEE MMMM d hh:mm a');
    }
    submitmyWaitlist(e) {
        this.WaitlistForm.controls['eventId'].setValue(this.id);

        this.WaitlistForm.controls['waitListId'].setValue(
            this.configdata.waitlist.id
        );
        this.WaitlistForm.controls['endDate'].setValue(this.getendDate());

        // console.log(this.WaitlistForm.value);
        var data = this.WaitlistForm.value as SubmitWaitlistMemberDTo;
        var l = this.http
            .post(environment.Api + 'd/or/c/u/Waitlist', data, {
                headers: {
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                    // Authorization: 'my-auth-token'
                },
            })
            .subscribe((x) => {
                this.router.navigate(['wait-List'], {
                    relativeTo: this.activatedRoute.parent,
                });
            });
        this.subs.push(l);
    }
    allitemShippingaddress: itemshipingaddress[] = [];
    countOfShippingAddress: number = 0;
    getAddress(e, i) {
        var index = this.allitemShippingaddress.findIndex((x) => x.id == e.id);
        if (index == -1) {
            this.allitemShippingaddress.push(e);
        } else {
            this.allitemShippingaddress[index] = e;
        }

        this.countOfShippingAddress = this.getdistinectShippengAddress();
       // console.log(e, i);
        //console.log(this.allitemShippingaddress);
    }

    getdistinectShippengAddress() {
        var uniqueObjList = [];

        // Iterate through each object in the original list
        for (var obj of this.allitemShippingaddress) {
            // Check if there is any existing object with the same properties
            const isDuplicate = uniqueObjList.some((uniqueObj) => {
                return (
                    uniqueObj.address === obj.address &&
                    uniqueObj.city === obj.city &&
                    uniqueObj.country === obj.country &&
                    uniqueObj.isUsLocal === obj.isUsLocal
                );
            });

            // If not found, add the object to the new array
            if (!isDuplicate) {
                uniqueObjList.push(obj);
            }
        }

        // Get the count of distinct objects
        var distinctCount = uniqueObjList.length;
        return distinctCount;
      //  console.log(distinctCount);
    }
    //add item as order
    addItem(
        id: string,
        qty: number,
        name: string,
        price: number,
        newPrice: number,
        curSymbol: string,
        dilveryMethod: bigint,
        min: number = 0,
        max: number = 0
    ) {
        const Order = this.fb.group({
            id: [id, []],
            qty: [qty],
            name: [name],
            price: [newPrice],
            oldPrice: [price],
            curSymbol: [curSymbol],
            maxPerTicket: [max],
            minPerTicket: [min],
            dilveryMethod: [dilveryMethod],
        });
        this.orderForm.push(Order);
    }

    GetConfigInfo(Id: string) {
        return this.http.get<OrderConfigDto>(
            environment.Api + 'd/or/g/e/o/co/' + Id
        );
    }
    getShippingCost(){
      return  this.http.get<number>( environment.Api + 'd/e/p/g').subscribe(x=>{
        this.shippingCost=x;
      });
    }
    ngOnInit(): void {
        this.getShippingCost();
        this.id = this.activatedRoute.parent.snapshot.paramMap.get('id');
        var m = this.GetConfigInfo(this.id).subscribe((x) => {
            //*watting list and confirm data */
            this.configdata = x;
        });
        this.subs.push(m);
        this.inilizw(this.id);
        var t = this.postPromo(this.id).subscribe((x) => {
            this.orderSrv.setcon(this.id);
             // console.log("my event  data",x)
            this.data = x;
            for (var i = 0; i < x.tickets.length; i++) {
                this.addItem(
                    x.tickets[i].id,
                    0,
                    x.tickets[i].name,
                    x.tickets[i].price,
                    x.tickets[i].newPrice != null
                        ? x.tickets[i].newPrice
                        : x.tickets[i].price,
                    x.tickets[i].curSymbol,
                    null,
                    x.tickets[i].minPerTicket,
                    x.tickets[i].maxPerTicket
                );
            }

            for (var i = 0; i < x.addOns.length; i++) {
                this.show.push(false);
                x.addOns[i].varieties.forEach((element) => {
                    this.addItem(
                        element.id,
                        0,
                        element.name,
                        element.price,
                        element.newPrice != null
                            ? element.newPrice
                            : element.price,
                        element.curSymbol,
                        null
                    );
                });
            }

            this.checkAllTiketQty();
        });

        this.subs.push(t);
    }

    getOptions(min, max) {
        let arr = [];
        for (let index = min; index <= max; index++) {
            arr.push(index);
        }
        return arr;
    }
    getMinMax(x): string {
        var t = x.map((x) => {
            return x.price;
        });
        var m = '[' + Math.min(...t) + '-' + Math.max(...t) + ']';
        return m;
    }
    setchekout(e, product) {
        if (e.value != null) {
            this.orderForm.controls.forEach((etm) => {
                if (etm?.get('id').value == product.id) {
                    etm.get('qty').setValue(e.value);
                }
            });
        } else {
            this.orderForm.controls.forEach((etm) => {
                if (etm?.get('id').value == product.id) {
                    etm.get('qty').setValue(0);
                }
            });
        }
    }
    setchekoutTickets(e, product) {
        if (e.value != null) {
            this.orderForm.controls.forEach((etm) => {
                if (etm?.get('id').value == product.id) {
                    etm.get('qty').setValue(e.value);
                }
            });
        } else {
            this.orderForm.controls.forEach((etm) => {
                if (etm?.get('id').value == product.id) {
                    etm.get('qty').setValue(0);
                }
            });
        }
        //this.getorderTickets();
        this.changeform();
    }
    SetDonation(e, product) {
        if (e.value != 0 || e.value != null) {
            this.orderForm.controls.forEach((etm) => {
                if (etm?.get('id').value == product.id) {
                    etm.get('qty').setValue(1);
                    etm.get('price').setValue(product.price + e.value);
                }
            });
        } else {
            this.orderForm.controls.forEach((etm) => {
                if (etm?.get('id').value == product.id) {
                    etm.get('qty').setValue(0);
                    etm.get('price').setValue(product.price);
                }
            });
        }
    }
    haveQty(x) {
        var l = this.orderForm.controls.find((y) => y?.get('id').value == x.id);
        if (l?.get('qty').value != 0) {
            return true;
        } else {
            return false;
        }
    }
    gettotal() {
        var l = this.orderForm.value.map((x) => {
            var i = x.price * x.qty;
            return i;
        });
        var tt = l.reduce((x, y) => x + y, 0);
        return tt;
        // var t = this.CheckOut.map(x => {
        //   var i = x.price * x.qty;
        //   return i;
        // })
        // var tt = t.reduce((x, y) => x + y, 0)
        // return tt
    }
    getitemPrice(Price1: number, qty: number) {
        return qty * Price1;
    }
    getDelveryMethodsOption(product) {
        var m: DropDownItem[] = [];
        if (product?.eTicket) {
            m.push({ name: this.translate.instant('E-Ticket'), id: '101040201' });
        }
        if (product.willCall) {
            m.push({ name: this.translate.instant('Will call'), id: '101040202' });
        }
        return m;
    }
    postPromo(Id, promo?) {
        let id = this.activatedRoute.parent.snapshot.paramMap.get('id');
        if (promo) {
            this.promoCode = promo;
            return this.http.get<OrderDataDto>(
                environment.Api + 'd/or/g/i/' + id + '/' + promo
            );
        } else {
            return this.http.get<OrderDataDto>(
                environment.Api + 'd/or/g/i/' + id
            );
        }
    }
    getformcontrol(x) {
        return this.orderForm.controls
            .find((y) => y.get('id').value == x.id)
            ?.get('qty');
    }
    submitpromo(e) {
        var o = this.postPromo(this.id, e).subscribe(
            (x) => {
                this.data = x;

                for (var i = 0; i < x.tickets.length; i++) {
                    var m = this.orderForm.controls.find(
                        (y) => y.get('id').value == x.tickets[i].id
                    );
                    if (m != null) {
                        m.get('price').setValue(
                            x.tickets[i].newPrice != null
                                ? x.tickets[i].newPrice
                                : x.tickets[i].price
                        );
                    } else {
                        this.addItem(
                            x.tickets[i].id,
                            0,
                            x.tickets[i].name,
                            x.tickets[i].price,
                            x.tickets[i].newPrice != null
                                ? x.tickets[i].newPrice
                                : x.tickets[i].price,
                            x.tickets[i].curSymbol,
                            null,
                            x.tickets[i].minPerTicket,
                            x.tickets[i].maxPerTicket
                        );
                    }
                }
                // for(var  i=0;i<x.tickets.length;i++){

                //   this.addItem(x.tickets[i].id,0,x.tickets[i].name,x.tickets[i].newPrice!=null?x.tickets[i].newPrice:x.tickets[i].price,x.tickets[i].curSymbol,null)
                // }

                for (var i = 0; i < x.addOns.length; i++) {
                    this.show.push(false);

                    x.addOns[i].varieties.forEach((element) => {
                        var m = this.orderForm.controls.find(
                            (y) => y.get('id').value == element.id
                        );

                        if (m != null) {
                            m.get('price').setValue(
                                element.newPrice != null
                                    ? element.newPrice
                                    : element.price
                            );
                        } else {
                            this.addItem(
                                element.id,
                                0,
                                element.name,
                                element.price,
                                element.newPrice != null
                                    ? element.newPrice
                                    : element.price,
                                element.curSymbol,
                                null
                            );
                        }
                    });
                }
            },
            (error) => {
                this.messageService.add({
                    severity: 'danger',
                    summary: 'Promo-code invaild or unrognized',
                });
            }
        );
        this.subs.push(o);
    }
    minNumofOrderform = 0;
    maxNumofOrderform = 1;
    AttendesNumberOptions = 0;
    checkAvailableWaitlist(): boolean {
        if (
            this.configdata.waitlist.totalUsed <
            this.configdata.waitlist.maxnumber
        ) {
            return true;
        } else {
            return false;
        }
    }

    GoToHomePage() {
        this.router.navigate(['']);
    }
    checkAllTiketQty(): boolean {
        var l = this.data.tickets?.map((x) => {
            return x.isSoldOut;
        });
        var m = l?.every((x) => x);

        return m;
    }
    changeform() {
        var p = this.http
            .get(environment.Api + 'd/e/ordform/' + this.id)

            .subscribe((x) => {
                //   console.log("sssssssss")
                //  console.log(x)
                this.formData = JSON.parse(x['form']);
            });
        this.subs.push(p);

        this.getorderTickets();
    }
    getorderTickets() {
        var ItemIds: string[] = this.orderForm.value.map((x) => {
            return x.id;
        });
        // console.log(ItemIds)
        var alltickets: string[] = this.data.tickets.map((x) => {
            return x.id;
        });
        // console.log(alltickets)
        var ordertickets: string[] = ItemIds.filter((x) =>
            alltickets.includes(x)
        );
        this.ordertick = this.orderForm.value.filter(
            (x) => ordertickets.includes(x.id) && x.qty != 0
        );
        if (this.ordertick.length > 0) {
            this.firstStep = true;
        } else {
            this.firstStep = false;
        }
    }


    setDelverymethods(event, id) {
        //  console.log(delvery changed)
        var l = this.orderForm.controls.find((y) => y?.get('id').value == id);
        l?.get('dilveryMethod').setValue(event.value);
        this.getwillcallItem();
    }
    postOrder() {
        // console.log(this.CheckOut)
        this.Dataforpost.eventId = this.id;
        this.Dataforpost.promo = this.promoCode;
        if(this.countOfShippingAddress!=0){
            this.Dataforpost.shippingCost=this.countOfShippingAddress*this.shippingCost
        }else{
            this.Dataforpost.shippingCost=0
        }
       
        if (this.data.isForBuyer) {
            var t: VmOrderItem[] = [];
            this.orderForm.value.forEach((x) => {
                if (x.qty != 0) {
                    var l: VmOrderItem = {
                        id: x.id,
                        deleveryMethods: x.dilveryMethod,
                        price: x.price,
                        qty: x.qty,
                    };
                    l.orderForm = JSON.stringify(this.orderformanstikets[0]);
                    t.push(l);
                }
            });
            this.Dataforpost.order = t;
            this.Dataforpost.formAnswer = JSON.stringify(this.orderformanstikets[0]);
            this.Dataforpost.type="payment";
            this.Dataforpost.email="";
        }
         else {
            var t: VmOrderItem[] = [];
            this.orderForm.value.forEach((x) => {
                if (x.qty != 0) {
                    var l: VmOrderItem = {
                        id: x.id,
                        deleveryMethods: x.dilveryMethod,
                        price: x.price,
                        qty: x.qty,
                    };
                    var answer = this.orderformanstikets.find(
                        (x) => x.ticets == l.id
                    );
                    if (answer == null) {
                        l.orderForm = '';
                    } else {
                        l.orderForm = JSON.stringify(answer);
                    }

                    if(l.deleveryMethods=='101040202'){
                        var loc=this.allitemShippingaddress.find(x=>x.id=l.id);
                        var m:VMaddresShipping={
                            address:loc.address,
                            city:loc.city,
                            country:loc.country,
                            isUsLocal:loc.isUsLocal,
                            lat:loc.lat==null?0:loc.lat,
                            lng:loc.lng==null?0:loc.lng
                        }
                        l.shipping=m;
                    }
                    
                    t.push(l);
                }
            });
            this.Dataforpost.order = t;

            this.Dataforpost.formAnswer = JSON.stringify(
                this.orderformanstikets
            );
            this.Dataforpost.type="payment";
            this.Dataforpost.email="";

        }
    // console.log(this.Dataforpost)
        var f = this.http
            .post(this.url + 'd/or/c', this.Dataforpost)
            .subscribe((x) => {
                this.messageService.add({
                    severity: 'success',
                    summary: 'Saved Successfully',
                });
                this.router.navigate(['confirmation', x], {
                    relativeTo: this.activatedRoute.parent,
                });
            });
        this.subs.push(f);
    }
    numOfattendees: number[] = [];
    getHowManyAtt(x, i) {
        this.numOfattendees[i] = x.value;
      //  console.log(this.numOfattendees[i]);
      //  console.log(this.numOfattendees);
        this.OrderFormResult = [];
    }
    orderformanstikets: any[] = [];
    OrderFormResult: any[] = [];
    OrderFormIsComplete = false;
    GetResult(x, index, i, item) {
        //    this.OrderFormResult.push(x)
        this.OrderFormResult[index] = {
            attendeesNum: this.generateRandomNumber(),
            status: 'Attending',
            Answer: x,
        };
        // // console.log(x)
        // console.log('the result is :',this.OrderFormResult)
        let dd = 0;
        this.OrderFormResult.forEach((x) => dd++);
        // console.log(dd)
       //console.log('order answer' + index);
       //console.log('ticket order answer' + i);
       //console.log(x);
       //console.log('order answer count ' + dd);
       //console.log('attendes count' + this.numOfattendees[i]);
       //console.log(this.numOfattendees[i] == dd);
        if (this.numOfattendees[i] == dd) {
            this.OrderFormIsComplete = true;
            this.orderformanstikets[i] = {
                ticets: item.id,
                order: this.OrderFormResult,
            };
        } else {
            this.OrderFormIsComplete = false;
        }
    }
    GetResult1(x, index) {
        //    this.OrderFormResult.push(x)
        this.OrderFormResult[index] ={
            attendeesNum: this.generateRandomNumber(),
            status: 'Attending',
            Answer: x,
        };
        this.orderformanstikets[0] = {
            ticets: 0,
            order: this.OrderFormResult,
        };
        this.OrderFormIsComplete = true;
    }

    generateRandomNumber(): string {
        const length = 7;
        let result = '';
        const characters = '0123456789';
        const charactersLength = characters.length;

        for (let i = 0; i < length; i++) {
            result += characters.charAt(
                Math.floor(Math.random() * charactersLength)
            );
        }

        return result;
    }

    getValud($event, i) {
        this.OrderFormIsComplete = false;
    }
    getValud1($event, i) {
        this.OrderFormIsComplete = false;
    }

    getDateOntomeZone1(d: Date) {
        var t = new Date(Date.now()).getTimezoneOffset();
        var MyDate = new Date(d).setMinutes(
            new Date(d).getMinutes() - new Date(Date.now()).getTimezoneOffset()
        );
        return new Date(MyDate).toLocaleString();
    }

    getDateOntomeZone(d) {
        let t = new Date(Date.now()).getTimezoneOffset();
        let MyDate = new Date(d).setMinutes(new Date(d).getMinutes() - t);
        var l=""
        if(new Date(MyDate).getFullYear()==new Date(Date.now()).getFullYear()){
            l= m(MyDate).format('ddd MMMM D hh:mm a');
            // this.datePipe.transform(new Date(MyDate), 'EEE MMMM d hh:mm a');
      
        }else{
           l= m(MyDate).format('ddd MMMM D,YYYY hh:mm a')
           //this.datePipe.transform(new Date(MyDate), 'EEE MMMM d,y hh:mm a');
      
        }
        return l

        // return new Date(MyDate).toLocaleString();
    }

    getwillcallItem() {
       // console.log(this.orderForm.value);
        var item = this.orderForm.value
            .filter((x) => x.dilveryMethod == '101040202')
            .map((x) => {
                return { id: x.id, name: x.name };
            });
       // console.log(item);
        if (item == undefined || item.length == 0) {
            this.Addressdata = [];
        } else {
            item.forEach((x) => {
                var m = this.Addressdata.find((y) => y.id == x.id);
                if (m == undefined) {
                    this.Addressdata.push(x);
                }
            });

           // console.log(this.Addressdata);
            var t = this.Addressdata.filter(
                (obj) => !item.some((t) => t.id === obj.id)
            );
           // console.log(t);
            if (t.length != 0) {
                this.Addressdata = this.Addressdata.filter((x) =>
                    t.some((m) => m.id === x.id)
                );
            }
        }

       // console.log(this.Addressdata);
    }

    getnumberofshipping() {
        const getDistinctObjectCount = (list: any[]): number => {
            const distinctObjects = new Set(list);
            return distinctObjects.size;
        };
    }
}
