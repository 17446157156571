import { Component } from '@angular/core';

@Component({
  selector: 'app-roles-steps',
  templateUrl: './roles-steps.component.html',
  styleUrls: ['./roles-steps.component.scss']
})
export class RolesStepsComponent {

}
