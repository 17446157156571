<div class="container  mt-3">
    <div class="love-btn ">
        <button [disabled]="intersetService.isSubmitting" type="submit" (click)="saveForm()"
            class="rounded-pill save-btn {{intersetService.isSubmitting? 'bg-disabled' : ''}}">
            <i *ngIf="intersetService.isSubmitting" class="fas fa-spinner fa-spin"></i>
            {{'SavBtn'|translate}}<span></span>
        </button>
    </div>
    <div class="hate-btn ">
        <button type="button" (click)="goBack()"><i class="fa-solid fa-left"></i></button>
    </div>
    <div class="p-3 d-flex flex-column justify-content-center align-items-center">
        <h1 class="fw-bold">{{'IntrstPgTitl001'|translate}}</h1>
        <p class="mt-4 fs-5">{{'IntrstPgTitl001Dsc001'|translate}}</p>
    </div>


    <form (ngSubmit)="saveForm()" [formGroup]="interestForm">
        <div class="border-25">
            <div *ngFor="let item of data" class="interests_section- ">
                <div class="interests_section-right-box p-3 my-5 " *ngIf="item.name != 'Other'">
                    <div class="row ">
                        <h2>{{item.name}}</h2>
                        <div class="row mt-4">
                            <div class="d-flex flex-wrap" formArrayName="interests">
                                <div *ngFor="let sub of item.subCategory let i = index" class="m-1"
                                    [formGroupName]="i">
                                    <p-toggleButton onLabel="{{sub.name}}" offLabel="{{sub.name}}"
                                        formControlName="value1"></p-toggleButton>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>



        </div>
        <div class="text-end my-3">
            <!-- <button pButton pRipple type="submit"  class="save-btn rounded-pill">Save <span></span></button> -->

        </div>

    </form>
</div>
