import { Injectable } from '@angular/core';
import { Observable, ReplaySubject, Subject } from 'rxjs';

export interface userlocationDto{
  city:string;
  lat:number;
  lng:number
}
@Injectable({
  providedIn: 'root'
})
export class UserlocationService {
  private subject: Subject<string> = new ReplaySubject<string>(1);
  private subject1: Subject<userlocationDto> = new ReplaySubject<userlocationDto>(1);
  get $getuserlocation(): Observable<any> {

    return this.subject.asObservable();
}
get $getuserlocationLatLang(): Observable<any> {

  return this.subject1.asObservable();
}
  constructor( ) { }

  getAddress(latitude: number, longitude: number): void {

    const geocoder = new google.maps.Geocoder();
    const latlng = new google.maps.LatLng(latitude, longitude);
    geocoder.geocode({ 'location': latlng }, (results, status) => {
      if (status === 'OK' && results[0]) {
        const address = results[0].formatted_address;
      //  console.log(results[0]);
        // console.log(results[0].address_components);
        if(results[0].address_components.find(y => y.types.includes('administrative_area_level_1'))?.long_name!=undefined){
          this.subject.next(results[0].address_components.find(y => y.types.includes('administrative_area_level_1'))?.long_name)
          this.subject1.next({city:results[0].address_components.find(y => y.types.includes('administrative_area_level_1'))?.long_name,lat:latitude,lng:longitude})
          
        }else{
          if(results[0].address_components.find(y => y.types.includes('administrative_area_level_2'))?.long_name!=undefined){
            this.subject.next(results[0].address_components.find(y => y.types.includes('administrative_area_level_2'))?.long_name)
            this.subject1.next({city:results[0].address_components.find(y => y.types.includes('administrative_area_level_2'))?.long_name,lat:latitude,lng:longitude})
            
          }
          else{
            if(results[0].address_components.find(y => y.types.includes('locality'))?.long_name!=undefined){
              this.subject.next(results[0].address_components.find(y => y.types.includes('locality'))?.long_name)
              this.subject1.next({city:results[0].address_components.find(y => y.types.includes('locality'))?.long_name,lat:latitude,lng:longitude})
              
            }

          }

        }
       // Do something with the address
      } else {
        console.log('Geocoder failed due to: ' + status);
      }
    });




   // const latlng = new google.maps.LatLng(latitude, longitude);
    // const request: google.maps.places.PlaceSearchRequest = {
    //   location: latlng,
    //   radius: 100,
    //   type: 'address'
    //   //types: 
    // };
    
    // const service = new google.maps.places.PlacesService(document.createElement('div'));
    // service.nearbySearch(request, (results, status) => {
    //   if (status === google.maps.places.PlacesServiceStatus.OK) {
    //     if (results[0]) {
    //       console.log(results[0]);
    //       this.subject.next(results[0].address_components.find(y => y.types.includes('administrative_area_level_1'))?.long_name)
    //       this.subject1.next({city:results[0].vicinity,lat:latitude,lng:longitude})
    //       // this.userAddress = results[0].vicinity
    //       // Do something with the address
    //     } else {
    //       console.log('No results found');
    //     }
    //   } else {
    //     console.log(`Places search failed due to: ${status}`);
    //   }
    // });
  }

  getLocation(): void {

    navigator.geolocation.getCurrentPosition((position) => {
      const longitude = position.coords.longitude;
      const latitude = position.coords.latitude;
      this.getAddress(latitude, longitude)


    },(error)=>{

    //console.log("error")
     switch(error.code) {
      case error.PERMISSION_DENIED:
       console.log("User denied the request for Geolocation.");
        break;
      case error.POSITION_UNAVAILABLE:
        console.log("Location information is unavailable.");
        break;
      case error.TIMEOUT:
       console.log("The request to get user location timed out.");
        break;
      default:
        console.log("An unknown error occurred.");
        break;
    }
    });

  }
}
