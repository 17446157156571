import { DOCUMENT } from '@angular/common';
import { Component, OnInit, Inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { OrganizerFromStepService } from 'src/app/services/organizer-from-step.service';
import { OrgnizersService } from 'src/app/services/orgnizers.service';
import { environment as env } from 'src/environments/environment';

@Component({
    selector: 'app-form-step1',
    templateUrl: './form-step1.component.html',
    styleUrls: ['./form-step1.component.scss']
})
export class FormStep1Component implements OnInit {
    url: string = env.Api;
    id: string;
    submitted: boolean

    constructor(public OrgServ: OrgnizersService, public srvform: OrganizerFromStepService, private router: Router, private activatedRoute: ActivatedRoute, @Inject(DOCUMENT) public document: Document) {


    }
    ngOnInit(): void {

    }

    valid() {
        return this.srvform.form.get("name").valid && this.srvform.form.get("image").valid && this.srvform.form.get("bio").valid
    }

    submit() {
        if (this.valid()) {

            this.router.navigate([
                "step2"
            ], { relativeTo: this.activatedRoute.parent });
        }
        else {
            this.submitted = true
        }

    }
    cancel() {
        this.srvform.clear()
        this.router.navigate([
            '/organizers'
        ]);
    }
}
