<form [formGroup]="onlineForm"  autocomplete="off">
    <div class="">
        <div class="card custom-table-border">
            <!-- <div class="card-header text-center bg-primary rounded-top-23">
                <h1 class="text-white">Online event page</h1>
            </div> -->
            <div class="card-header border-0 text-center bg-primary rounded-top-23 ">
                <h1 class="fw-bold text-white mt-2">{{'OnlinEvntPgTblTitl'|translate}}</h1>
                </div>
            <div class="card-body">
                <div class="">
                    <div *ngIf="loader.show1" @fadeOut class="d-flex justify-content-center align-items-center" style="border-radius: 25px;top : 0;position: absolute;z-index: 1050;background-color:#fff;width: 100%;height: -webkit-fill-available;right: 0;bottom: 0;left: 0;">
                    </div>
                    <div class="row">
                        <div class="col-12">
                         {{'OnlinEvntPgTblDsc'|translate}}
                        </div>
                    </div>
                    <div class="row my-5">
                        <div class="col-12 col-md-4">
                            <div class="card ">
                                <div class="card-body p-0">
                                    <h5 class="card-title">{{'OnlinEvntPgTblTitle001'|translate}}</h5>
                                    <p class="card-text">{{'OnlinEvntPgTblTitle001Dsc001'|translate}}</p>
                                    <div class="banner-btn rounded-pill ">
                                        <button  [ngStyle]="{'background'   : 'rgb(4 205 4 / 60%)'}"  pTooltip="{{'OnlinEvntPgTblTitle001Btn001Pop'|translate}}"  showDelay="1000"
                                        tooltipPosition="left" class="rounded-pill save-btn" type="button"
                                            (click)="addComponent('provider')">
                                            {{'OnlinEvntPgTblTitle001Btn001'|translate}}<span></span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-md-8  ">
                            <div class="card w-100 ">
                                <div class="card-body p-0">
                                    <h5 class="card-title">{{'OnlinEvntPgTblTitle002'|translate}}</h5>
                                    <p class="card-text">{{'OnlinEvntPgTblTitle002Dsc001'|translate}}</p>
                                    <div class=" buttons-small-screen row justify-content-sm-between justify-content-center">
                                        <div class="col-xl-3 col-sm-4 col-6  ">
                                            <button   pTooltip="{{'OnlinEvntPgTblTitle002Btn001Pop'|translate}}"  showDelay="1000"
                                            tooltipPosition="left"class="rounded-pill addSmth-btn" type="button"
                                                (click)="addComponent('text')">
                                                <i class="fa-solid fa-text mt-1"></i> {{'OnlinEvntPgTblTitle002Btn001'|translate}}<span></span>
                                            </button>
                                        </div>
                                        <div class=" col-xl-3 col-sm-4 col-6  ">
                                            <button pTooltip="{{'OnlinEvntPgTblTitle002Btn002Pop'|translate}}"  showDelay="1000"
                                            tooltipPosition="left" class="rounded-pill addSmth-btn" type="button"
                                                (click)="addComponent('image')">
                                                <i class="fa-solid fa-image mt-1"></i> {{'OnlinEvntPgTblTitle002Btn002'|translate}}<span></span>
                                            </button>
                                        </div>
                                        <div pTooltip="{{'OnlinEvntPgTblTitle002Btn003Pop'|translate}}"  showDelay="1000"
                                        tooltipPosition="left" class=" col-xl-3 col-sm-4 col-6  ">
                                            <button class="rounded-pill addSmth-btn" type="button"
                                                (click)="addComponent('video')">
                                                <i class="fa-solid fa-film"></i> {{'OnlinEvntPgTblTitle002Btn003'|translate}}<span></span>
                                            </button>
                                        </div>
                                         <div class="col-xl-3 col-sm-4 col-6  ">
                                            <button pTooltip="{{'OnlinEvntPgTblTitle002Btn004Pop'|translate}}"  showDelay="1000"
                                            tooltipPosition="left" class="rounded-pill addSmth-btn" type="button"
                                                (click)="addComponent('link')">
                                                <i class="fa-solid fa-link"></i>{{'OnlinEvntPgTblTitle002Btn004'|translate}}<span></span>
                                            </button>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <hr />
                        </div>
                    </div>
                    <div class="row mb-2" id="desContainer">
                    </div>
                    <div formArrayName="description" class="row d-flex flex-column gap-3">
                        <div *ngFor="
                let c of formDescription.controls;
                let i = index
                "[formGroupName]="i" class="col-12">
                            <div class="row" *ngIf="c.get('type').value == 'text'">
                                <div class="col-11">
                                    <p-editor pTooltip="{{'OnlinEvntPgTblTitle002Btn001Inpt001Pop'|translate}}"  showDelay="1000"
                                    tooltipPosition="left"
                                      [style]="{'min-height': '300px'}" formControlName="value1">

                                    </p-editor>
                                </div>
                                <div class="col-1 p-0 d-flex flex-column justify-content-center align-items-center">
                                    <button pButton pRipple type="button" *ngIf="i>=1" icon="fas fa-arrow-up" (click)="move(-1,i)" class="p-button-rounded p-button-text"></button>
                                    <button pButton pRipple type="button"  icon="fas fa-trash" (click)="removeItem(i)" class="p-button-rounded p-button-text"></button>
                                    <button pButton pRipple type="button" *ngIf="i!=formDescription.controls.length-1 && formDescription.controls.length != 1" icon="fas fa-arrow-down" (click)="move(1,i)" class="p-button-rounded p-button-text"></button>
                                </div>
                            </div>
                            <div class="row" *ngIf="c.get('type').value == 'image'">
                                <div class="col-11">
                                    <app-my-image-uploader pTooltip="{{'OnlinEvntPgTblTitle002Btn002Inpt001Pop'|translate}}"  showDelay="1000"
                                    tooltipPosition="left" formControlName="image" [aspectRatio]="2 / 1"
                                        [quality]="'50'" [format]="'jpeg'"
                                        formControlName="value1"></app-my-image-uploader>
                                </div>
                                <div class="col-1 p-0 d-flex flex-column justify-content-center align-items-center">
                                    <button pButton pRipple type="button" *ngIf="i>=1" icon="fas fa-arrow-up" (click)="move(-1,i)" class="p-button-rounded p-button-text"></button>
                                    <button pButton pRipple type="button"  icon="fas fa-trash" (click)="removeItem(i)" class="p-button-rounded p-button-text"></button>
                                    <button pButton pRipple type="button" *ngIf="i!=formDescription.controls.length-1 && formDescription.controls.length != 1" icon="fas fa-arrow-down" (click)="move(1,i)" class="p-button-rounded p-button-text"></button>
                                </div>
                            </div>
                            <div class="row" *ngIf="c.get('type').value == 'link'">
                                <div class="col-11 p-0">
                                    <div class="p-input-filled col-12 col-md-12 mb-3">
                                                <span class="p-float-label">
                                                    <input pTooltip="{{'OnlinEvntPgTblTitle002Btn004Inpt001Pop'|translate}}"  showDelay="1000"
                                                    tooltipPosition="left" type="text" pInputText formControlName="title"
                                                           class="form-control" formControlName="value1">
                                                    <label class="font-size-14">{{'OnlinEvntPgTblTitle002Btn004Inpt001'|translate}}</label>
                                                </span>
                                    </div>
                                    <div class="p-input-filled col-12 col-md-12 mb-3">
                                                <span class="p-float-label">
                                                    <input type="text" pInputText formControlName="link"
                                                           class="form-control" formControlName="value2">
                                                    <label class="font-size-14" >{{'OnlinEvntPgTblTitle002Btn004Inpt002'|translate}}</label>
                                                </span>
                                    </div>
                                </div>
                                <div class="col-1 p-0 d-flex flex-column justify-content-center align-items-center">
                                    <button pButton pRipple type="button" *ngIf="i>=1" icon="fas fa-arrow-up" (click)="move(-1,i)" class="p-button-rounded p-button-text"></button>
                                    <button pButton pRipple type="button"  icon="fas fa-trash" (click)="removeItem(i)" class="p-button-rounded p-button-text"></button>
                                    <button pButton pRipple type="button" *ngIf="i!=formDescription.controls.length-1 && formDescription.controls.length != 1" icon="fas fa-arrow-down" (click)="move(1,i)" class="p-button-rounded p-button-text"></button>
                                </div>
                            </div>
                            <div class="row align-items-center" *ngIf="c.get('type').value == 'video'">
                                <div class="col-11">
                                    <div class="row ">
                                        <div class="p-input-filled col-sm-12 col-md-12 mb-2">
                                            <span class="p-float-label">
                                                <input  pTooltip="{{'OnlinEvntPgTblTitle002Btn003Inpt001Pop'|translate}}"  showDelay="1000"
                                                tooltipPosition="left"type="text" pInputText class="form-control"
                                                    formControlName="value1" />
                                                <label class="form-label font-size-14" >{{'OnlinEvntPgTblTitle002Btn003Inpt001'|translate}}</label>
                                            </span>
                                        </div>
                                        <div class="col-12 mt-2 mb-2" *ngIf="c.get('value1').value ">
                                            <iframe id="" width="100%" height="360"
                                                [src]="makeItSafe(c.get('value1').value)" title="YouTube video player"
                                                frameborder="0"
                                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                                allowfullscreen></iframe>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-1 p-0 d-flex flex-column justify-content-center align-items-center">
                                    <button pButton pRipple type="button" *ngIf="i>=1" icon="fas fa-arrow-up" (click)="move(-1,i)" class="p-button-rounded p-button-text"></button>
                                    <button pButton pRipple type="button"  icon="fas fa-trash" (click)="removeItem(i)" class="p-button-rounded p-button-text"></button>
                                    <button pButton pRipple type="button" *ngIf="i!=formDescription.controls.length-1 && formDescription.controls.length != 1" icon="fas fa-arrow-down" (click)="move(1,i)" class="p-button-rounded p-button-text"></button>
                                </div>
                            </div>

                            <div class="row" *ngIf="c.get('type').value == 'provider'">
                                <div class="col-11">
                                    <div class="card custom-table-border  p-md-4">
                                        <div class="card-body">
                                            <h5 class="card-title mb-4">{{'OnlinEvntPgTblTitle001Btn001SctionTitl'|translate}}</h5>
                                            <div class="p-input-filled col-12 p-0 col-md-12 mb-3">
                                                <span class="p-float-label">
                                                    <input  pTooltip="{{'OnlinEvntPgTblTitle001Btn001SctionInpt001Pop'|translate}}"  showDelay="1000"
                                                    tooltipPosition="left"type="text" pInputText formControlName="title"
                                                        class="form-control">
                                                    <label class="font-size-14">{{'OnlinEvntPgTblTitle001Btn001SctionInpt001'|translate}}</label>
                                                </span>
                                            </div>
                                            <div class="p-input-filled col-12 p-0 col-md-12 mb-3">
                                                <span class="p-float-label">
                                                    <input  pTooltip="{{'OnlinEvntPgTblTitle001Btn001SctionInpt002Pop'|translate}}"  showDelay="1000"
                                                    tooltipPosition="left"  type="text" pInputText formControlName="link"
                                                        class="form-control">
                                                    <label class="font-size-14">{{'OnlinEvntPgTblTitle001Btn001SctionInpt002'|translate}}</label>
                                                </span>
                                            </div>
                                            <div class="mt-2 mb-2" style="min-height:300px;">
                                                <app-my-image-uploader  pTooltip="{{'OnlinEvntPgTblTitle001Btn001SctionInpt003Pop'|translate}}"  showDelay="1000"
                                                tooltipPosition="left" formControlName="image" [aspectRatio]="2 / 1"
                                                    [quality]="'50'" [format]="'jpeg'"
                                                    formControlName="image"></app-my-image-uploader>
                                            </div>

                                            <div class="mt-2 mb-2" style="min-height:300px;">
                                                <p-editor  pTooltip="{{'OnlinEvntPgTblTitle001Btn001SctionInpt004Pop'|translate}}"  showDelay="1000"
                                                tooltipPosition="left" [style]="{ 'min-height': '300px' }" formControlName="editor">

                                                </p-editor>
                                            </div>

                                            <!-- <div class="mt-2 mb-2">
                                                <div class="p-input-filled col-md-12 col-12 mb-2 ">
                                                    <span class="p-float-label">
                                                        <p-multiSelect [styleClass]="'w-100'" [options]="tickets"
                                                            optionLabel="name" optionValue="code"
                                                            defaultLabel="Select a Ticket" formControlName="tickets"
                                                            appendTo="body"></p-multiSelect>
                                                        <label for="">Select a Ticket</label>
                                                    </span>
                                                </div>
                                            </div> -->
                                        </div>
                                    </div>
                                </div>
                                <div class="col-1 p-0 d-flex flex-column justify-content-center align-items-center">
                                    <!-- <button pButton pRipple type="button" icon="fas fa-edit" class="p-button-rounded p-button-text"></button> -->
                                    <!-- <button class="text-primary mb-1" pRipple type="button" icon="fas fa-edit" class="p-button-rounded p-button-text"></button> -->
                                    <!-- <button pButton pRipple type="button" icon="fas fa-save" class="p-button-rounded p-button-text"></button> -->
                                    <button pButton pRipple type="button" *ngIf="i>=1" icon="fas fa-arrow-up" (click)="move(-1,i)" class="p-button-rounded p-button-text"></button>
                                    <button pButton pRipple type="button"  icon="fas fa-trash" (click)="removeItem(i)" class="p-button-rounded p-button-text"></button>
                                    <button pButton pRipple type="button" *ngIf="i!=formDescription.controls.length-1 && formDescription.controls.length != 1" icon="fas fa-arrow-down" (click)="move(1,i)" class="p-button-rounded p-button-text"></button>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row" *ngIf="!loader.show1">
            <div class="col-12 d-flex justify-content-lg-center justify-content-center">
<!--                <div class="banner-btn rounded-pill mx-2">-->
<!--                    <button   pTooltip="Cancel"  showDelay="1000"-->
<!--                    tooltipPosition="left"class="rounded-pill cancel-btn">-->
<!--                        <i class="pi pi-angle-left mt-1"></i> Cancel<span></span>-->
<!--                    </button>-->
<!--                </div>-->
                <div class=" d-flex justify-content-center gap-1">
                    <button [disabled]="isSubmitting" (click)="submit(1)" class="rounded-pill save-btn {{isSubmitting? 'bg-disabled' : ''}}" pTooltip="Save changes" showDelay="1000" tooltipPosition="right">
                        <i  *ngIf="isSubmitting" class="fas fa-spinner fa-spin"></i>
                        {{'SavBtn'|translate}}  <span></span>
                    </button>
                    <button *ngIf="!srv.addressBool" [disabled]="isSubmitting" (click)="submit(2)"  type="submit" class="rounded-pill save-btn {{isSubmitting? 'bg-disabled' : ''}}" pTooltip="Save changes" showDelay="1000" tooltipPosition="right">
                        <i  *ngIf="isSubmitting" class="fas fa-spinner fa-spin"></i>
                        {{'Sav&NxtBtn'|translate}}  <span></span>
                    </button>
                </div>
            </div>
        </div>
    </div>
</form>

