<div [class.single-courses-box-rtl]="document.documentElement.dir === 'rtl'"
[class.single-courses-box]="document.documentElement.dir === 'ltr'"

class="single-courses-box bg-color border-45">
    <div class="courses-image">
        <a [routerLink]="'/event/'+item.id" class="d-block image ">
            <img class="" [src]="url+ item.photo" style="min-height:100px; min-width:100px"  alt="image">
        </a>
        <a (click)="Like(item.id)" class="fav"><i class="{{   this.ua.LikedEvents.includes(item.id)? 'fa-solid':'fa-regular' }} fa-heart text-primary"></i></a>
        <div class="price shadow">{{item.price.minValue!=item.price.maxValue?("Start at:"|translate) +item.price.minValue+item.curSymbol:item.price.maxValue==0?('Free'|translate):item.price.maxValue+item.curSymbol}}</div>

        <!-- <div class="price shadow">{{item.price.minValue!=item.price.maxValue?item.price.minValue+item.curSymbol+"-"+item.price.maxValue+item.curSymbol:item.price.maxValue==0?'free':item.price.maxValue+item.curSymbol}}</div> -->
    </div>
    <div class="courses-content">

        <h3 class="text-lenght"><a [routerLink]="'/event/'+item.id">{{item.title}}</a></h3>
        <div class="">
            <span class="mt-2 me-2 category-in-card ">{{item.category}}</span>
            <span class="mt-2 subCategory-in-card ">{{item.subCategory}}</span>
        </div>

        <div class="card-footer-font-size  mb-2 mt-2 ">
            <div class="mt-3  border-top border-primary"
                 style="color:var(--mainColor);">
                                            <span class="mt-2 fw-bold"><small><i class="far fa-clock "></i>
                                                    {{'Start:'|translate}}</small> {{getDateOntomeZoneformat2(item.startDate)}}</span><br />
                <span class="mt-2 fw-bold"><small><i class="far fa-clock"></i> {{'End:'|translate}}</small>
                    {{getDateOntomeZoneformat2(item.endDate)}}</span>
            </div>
        </div>
        <div class="card-footer-font-size d-block align-items-center ">
            <p class="text-lenght my-2"><i class="far fa-map-marker-alt"></i> {{item.address}}</p>
            <!-- <div class="course__authors-box d-flex align-items-center">
                                <div class="course__authors-box-text">
                                    <h5><i class="fal fa-user"></i> Ahmad asd sa d asd sasa  d sad</h5>
                                </div>
                            </div> -->
            <div class="course-author d-flex align-items-center">
                <span class="text-lenght-org"><i class="fal fa-user"></i> <a [routerLink]="['/organizers','preview',item.orgId]"> {{" "+item.orgName}}</a></span>
            </div>
            <p class="card-footer-font-size "><i class="fa-solid fa-users pe-1"></i> {{'EvntPgTimDsc003'|translate}}
                <span style="color:var(--mainColor);"> {{item.orgFollowers}}</span> </p>
        </div>
    </div>
</div>
