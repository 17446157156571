import { Component } from '@angular/core';

@Component({
  selector: 'app-image-uploader',
  templateUrl: './image-uploader.component.html',
  styleUrls: ['./image-uploader.component.scss']
})
export class ImageUploaderComponent {

    onSelect($event: any) {

    }

    onUpload($event: any) {
    }

    onClearClicked() {

    }
}
