<form [formGroup]="eventDateForm" (ngSubmit)="submit()" autocomplete="off">
    <div class="">
        <div class="row mb-2"> {{"CalenderUpdateTitl"|translate}}</div>
                    <div class="row d-flex justify-content-center">
                        <div class="p-input-filled col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6 mb-2">
                            <span class="p-float-label">
                                <p-calendar [showTime]="true"
                                dataType="string"
                                pTooltip="Start date for this instance"
                                tooltipPosition="bottom" showDelay="1000"
                                [showSeconds]="false" [hourFormat]="12" appendTo="body"
                                [minDate]="minDateValue" inputId="salesStart" [styleClass]="'form-control m-0 p-0'"
                                [inputStyleClass]="'form-control'" [stepMinute]="1"  formControlName="startDate"
                                (onSelect)="confirmdate($event)" [ngClass]="{
                                'ng-invalid ng-dirty':
                                    eventDateForm.get('startDate')
                                        ?.touched &&
                                    eventDateForm.get('startDate')?.invalid
                            }">
                                              <ng-template pTemplate="footer" >
                                                <p class="clock-positon"> <i  class="fa-solid fa-clock"></i> </p>
                    <!-- <p class="" style="padding-left: 1.1rem;position:relative;translate: 0 -80px;"> <i style="font-size: 1.1rem" class="fa-solid fa-clock"></i> </p> -->
                 </ng-template>
                                </p-calendar>
                                <label class="form-label font-size-14" for="salesStart">
                                    {{"CalenderUpdateLable1"|translate}}</label>
                            </span>
                        </div>
                        <div class="p-input-filled col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6 mb-2 mb-2">
                            <span class="p-float-label">
                                <p-calendar
                                pTooltip="End date for this instance"  showDelay="1000"
                                dataType="string"
                                tooltipPosition="bottom"[showTime]="true" [stepMinute]="1"  [showSeconds]="false" [hourFormat]="12" appendTo="body"
                                    [minDate]="maxDateValue"  inputId="salesEnd"
                                    [styleClass]="'form-control m-0 p-0'" [inputStyleClass]="'form-control'"
                                    formControlName="endDate" [ngClass]="{
                                    'ng-invalid ng-dirty':
                                        eventDateForm.get('endDate')?.touched &&
                                        eventDateForm.get('endDate')?.invalid
                                }">
                                              <ng-template pTemplate="footer" >
                                                <p class="clock-positon"> <i  class="fa-solid fa-clock"></i> </p>
                    <!-- <p class="" style="padding-left: 1.1rem;position:relative;translate: 0 -80px;"> <i style="font-size: 1.1rem" class="fa-solid fa-clock"></i> </p> -->
                 </ng-template>
                                </p-calendar>
                                <label class="form-label font-size-14" for="salesEnd">
                                    {{"CalenderUpdateLable2"|translate}}</label>
                            </span>
                        </div>
                    </div>


                    <div class="row mb-2">
                        <div class="col-6">
                            <p-inputSwitch    pTooltip="Select if start date will show in event profile page"
                            tooltipPosition="bottom" showDelay="1000"
                            ariaLabelledBy="ttt" formControlName="displayStart"
                                inputId="bbbb"></p-inputSwitch>
                            <label class=" form-check-label b-5 ms-2" id="ttt" for="bbbb">{{"CalenderUpdateLable3"|translate}}</label>
                        </div>
                        <div class="col-6">
                            <p-inputSwitch
                            pTooltip="select if end date will show in event profile page"
                            tooltipPosition="left" showDelay="1000"  ariaLabelledBy="tttt" inputId="bbb"
                                formControlName="displayEnd"></p-inputSwitch>
                            <label class=" form-check-label b-5 ms-2" id="tttt" for="bbb">{{"CalenderUpdateLable4"|translate}}</label>
                        </div>
                    </div>
        <div class="row">
            <div class="col-12 d-flex justify-content-lg-center justify-content-center">
                <div class="banner-btn rounded-pill mx-2">
                    <button
                    pTooltip="Cancel and dismiss changes"
                                tooltipPosition="left" showDelay="1000" type="button"  class="rounded-pill save-btn" (click)="cancel()">
                                <i class="pi mt-1"
                                [class.pi-angle-right]="document.documentElement.dir === 'rtl'"
                                [class.pi-angle-left]="document.documentElement.dir === 'ltr'"></i>{{'CanclBtn'|translate}}<span></span>
                    </button>
                </div>
                <div class="banner-btn rounded-pill mx-2 ">
                    <button
                    pTooltip="Save updates"
                    tooltipPosition="right" showDelay="1000"  type="submit" class="rounded-pill save-btn">
                    {{'SavBtn'|translate}}   <i class="pi mt-1"
                    [class.pi-angle-right]="document.documentElement.dir === 'ltr'"
                    [class.pi-angle-left]="document.documentElement.dir === 'rtl'"></i><span></span>
                    </button>
                </div>
            </div>
        </div>
    </div>


</form>

