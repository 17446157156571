import {Component, ViewChild} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';
import {EventDetailsPageService} from '../event-page/event-details-page.service';
import {ActivatedRoute} from '@angular/router';
import {DatePipe, Location} from '@angular/common';
import {environment} from '../../../../environments/environment';
import * as moment from 'moment/moment';
import {EventPageDto} from '../event-page/event-page.component';
import {OnlinePageService} from './online-page.service';
import * as m from 'moment';
import 'moment/locale/pt-br';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-online',
  templateUrl: './online.component.html',
  styleUrls: ['./online.component.scss']
})
export class OnlineComponent {

    constructor(public _sanitizer: DomSanitizer,
                private onlinePageService: OnlinePageService,
                public activatedRoute: ActivatedRoute,
                private loc: Location,private datePipe: DatePipe,private translate:TranslateService
    ) {
        this.translate.onLangChange.subscribe(() => {
            console.log(this.translate.currentLang)
            m.locale(this.translate.currentLang);
            console.log(m.locale())
          });
    }
    enteval!: any;
    mapProblem = false;
    days: any;
    hours: any;
    minutes: any;
    seconds: any;
    myInterval: any;

    oo: any;
    data: EventPageDto = {};
    isStarted = false;
    isEnded = false;


    url = environment.Api;

    visible: boolean;
    ngOnDestroy(): void {
        clearInterval( this.myInterval);
        clearInterval( this.enteval);
    }
    ngOnInit(): void {
    //    console.log(this.activatedRoute);
        const id = this.activatedRoute.snapshot.paramMap.get('id');

        this.onlinePageService.getOnlineEventDetails(id).subscribe(x => {
         //   console.log(x)
            this.data = x;
            // this.enteval = setInterval(() => {
            //     this.checkedDate(this.getDateOntomeZone(this.data?.startDate), this.getDateOntomeZone(this.data.endDate));
            // }, 0);
            // this.myInterval = setInterval(() => {
            //     this.commingSoonTime(this.getDateOntomeZone(this.data?.startDate));
            // }, 0);
            this.data.description = JSON.parse(x.description);

            this.enteval = setInterval(() => {
                this.checkedDate(this.getDateOntomeZone2(this.data?.startDate), this.getDateOntomeZone2(this.data.endDate))
              }, 0)
              this.myInterval = setInterval(() => {
               // console.log(this.getDateOntomeZone(this.data?.startDate))
                this.commingSoonTime(this.getDateOntomeZone2(this.data?.startDate));
              }, 0);
        });
    }

    goBack(){
        this.loc.back();
    }

    makeItSafe(x) {
        let str = x;
        const index = str.indexOf('v');
        str = str.slice(index + 2, str.length);
        const url = 'https://www.youtube.com/embed/' + str;
        return this._sanitizer.bypassSecurityTrustResourceUrl(url);
    }
    makeItSafeHtml(x) {

        return this._sanitizer.bypassSecurityTrustHtml(x);
    }

    // getDateOntomeZone(d: Date) {
    //     const t = new Date(Date.now()).getTimezoneOffset();
    //     const MyDate = new Date(d).setMinutes(new Date(d).getMinutes() - (new Date(Date.now()).getTimezoneOffset()));
    //     return new Date(MyDate).toLocaleString();
    // }
    getDateOntomeZone(d){

        m.locale();
        let t = new Date(Date.now()).getTimezoneOffset();
        let MyDate = new Date(d).setMinutes(new Date(d).getMinutes() - (t));
        if(new Date(MyDate).getFullYear()==new Date(Date.now()).getFullYear()){
            var l= m(new Date(MyDate)).format('ddd MMMM D hh:mm a');
            //this.datePipe.transform(new Date(MyDate), 'EEE MMMM d hh:mm a');
            return l;
        }else{
            var l= m(new Date(MyDate)).format( 'ddd MMMM D,y hh:mm a');
            //this.datePipe.transform(new Date(MyDate), 'EEE MMMM d,y hh:mm a');
            return l;
        }
       // return new Date(MyDate).toLocaleString();
    }
    checkedDate(x, y) {
        // console.log("start:"+x )
        // console.log("end:"+y )
        // console.log("end:"+new Date(y) )
        const Datenow = new Date(Date.now());
        // console.log("itstarted",moment(Datenow).isSameOrAfter(new Date(x)) && moment(Datenow).isBefore(new Date(y)) )
        // console.log("itfinshd",moment(Datenow).isSameOrAfter(new Date(y))) 
        if (moment(Datenow).isSameOrAfter(new Date(x)) && moment(Datenow).isBefore(new Date(y))) {
          this.isStarted = true;
          this.isEnded = false;
          clearInterval(this.myInterval);
        } else {
          if ( moment(Datenow).isSameOrAfter(new Date(y))) {
            this.isStarted = false;
            this.isEnded = true;
            clearInterval(this.myInterval);
          }
          else{
            if (moment(Datenow).isBefore(new Date(x))) {
              this.isStarted = false;
              this.isEnded = false;
             
            }
          }
        }
      }
    commingSoonTime = (x) => {
  
        const endTimeParse = (Date.parse(x)) / 1000;
        const now = new Date(Date.now());
        const nowParse = (Date.parse(now.toString()) / 1000);
        const timeLeft = (endTimeParse - nowParse) < 0 ? 0 : (endTimeParse - nowParse);
        const days = Math.floor(timeLeft / 86400);
        let hours = Math.floor((timeLeft - (days * 86400)) / 3600);
        let minutes = Math.floor((timeLeft - (days * 86400) - (hours * 3600)) / 60);
        let seconds = Math.floor((timeLeft - (days * 86400) - (hours * 3600) - (minutes * 60)));
        if (hours < 10) { hours = 0 + hours; }
        if (minutes < 10) { minutes = 0 + minutes; }
        if (seconds < 10) { seconds = 0 + seconds; }
        this.days = days;
        this.hours = hours;
        this.minutes = minutes;
        this.seconds = seconds;
      }

    showDialog() {
        this.visible = true;
    }
    getDateOntomeZone2(d){
        let t = new Date(Date.now()).getTimezoneOffset();
        let MyDate = new Date(d).setMinutes(new Date(d).getMinutes() - (t));
        // if(new Date(MyDate).getFullYear()==new Date(Date.now()).getFullYear()){
        //     var l= this.datePipe.transform(new Date(MyDate), 'EEE MMMM d hh:mm a');
        //     return l;
        // }else{
        //     var l= this.datePipe.transform(new Date(MyDate), 'EEE MMMM d,y hh:mm a');
        //     return l;
        // }
        return new Date(MyDate).toLocaleString();
      }
}
