import { Injectable } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import {  ticketsFormDto } from '../Interfaces/TicketsDtoS';
import { TicketsService } from './tickets.service';

@Injectable({
  providedIn: 'root'
})
export class TicketFormStepsService {


    myform!: FormGroup;
  constructor(private fb: FormBuilder, private ticketSrv: TicketsService) {
    this.inilizeForm(null,null,'','',0,0,false,1,10,1,10,null,new Date(new Date(new Date(Date.now()).setHours(0)).setMinutes(0)),null,0,0,0,false,false,false,1);
   }


  inilizeForm(id?: string, events?: string[], name?: string, des?: string, qty?: number, pric?: number, hidden?, minqty?: number, maxqty?: number, minper?: number, maxper?, type?, statr?: Date, end?: Date, priod?: number, ptype?: number, startp?: number, etic?: boolean, wiltic?: boolean, etype?: boolean, sold?: number) {
    this.myform = this.fb.group({
      id: [id],
      events: [events],
      name: [name, [Validators.required]],
      description: [des, Validators.required],
      quantity: [qty, [Validators.required, Validators.min(sold) , Validators.min(sold)]],
      price: [pric , [Validators.required ]],
      hidden: [hidden, Validators.required],
      minQuantity: [minqty, Validators.required],
      maxQuantity: [maxqty, [Validators.required, this.bigThan('minQuantity') , this.lessthan('quantity')]],
      minNumber: [minper, Validators.required],
      maxNumber: [maxper, [Validators.required, this.bigThan('minNumber')]],
      ticketType: [type, Validators.required],
      startDate: [statr, Validators.required],
      endDate: [end],
      priodNumber: [priod],
      priodType: [ptype],
      startingPoint: [startp],
      eticket: [etic],
      willCall: [wiltic],
      eventType: [etype]
    },{ validators: this.ValidticketsDel()});

    this.myform.controls.minQuantity.valueChanges.subscribe(() => {
      this.myform.controls.maxQuantity.updateValueAndValidity();
    });
    this.myform.controls.minNumber.valueChanges.subscribe(() => {
      this.myform.controls.maxNumber.updateValueAndValidity();
    });
    this.myform.controls.willCall.valueChanges.subscribe(() => {
      this.myform.updateValueAndValidity();
    });
    this.myform.controls.eticket.valueChanges.subscribe(() => {
      this.myform.updateValueAndValidity();
    });
   // console.log(this.myform.controls.eventType.value);


  }

  bigThan(param:string):ValidatorFn{
    return (control:AbstractControl)=>{
             return control?.value>=control?.parent?.controls[param].value?null:{isMatched:true}
          }
  }
  lessthan(param:string):ValidatorFn{

    return (control:AbstractControl)=>{
             return control?.value<=control?.parent?.controls[param].value?null:{isValid:true}
          }
  }

  ValidticketsDel():ValidatorFn{
    return (group: FormGroup)=>{
             return (group?.controls['willCall'].value||group?.controls['eticket'].value)?null:{ValidTicDev:true}
          }
  }
  validDate(param:string):ValidatorFn{
    return (control:AbstractControl)=>{
     var s=new Date(control?.value);
     var e=new Date(control?.parent.controls[param].value);
      return s>e ?null:{isTrue:true}
    }
  }

  setsomeValidation(t: boolean) {
    if (t) {
      this.myform.controls['priodNumber'].setValidators([Validators.required]);
      this.myform.controls['priodType'].setValidators([Validators.required]);
      this.myform.controls['startingPoint'].setValidators([Validators.required]);

    } else {

      this.myform.controls['endDate'].setValidators([Validators.required,this.validDate('startDate')])
      this.myform.controls.startDate.valueChanges.subscribe(()=>{
        this.myform.controls.endDate.updateValueAndValidity();
      })
    }
  }


   clear(){
   // this.myform.reset();
   this.inilizeForm(null,null,'','',0,0,false,1,10,1,10,null,new Date(new Date(new Date(Date.now()).setHours(0)).setMinutes(0)),null,0,0,0,false,false,false,1);
   }
//    step1Submit(x : myformDto){
// console.log(this.myform.value)
//     // this.ticket.id = x?.id;
//     // this.ticket.events = x?.events;
//     // this.ticket = { ...this.ticket, ...x}

//     // this.ticket.name= x?.name;
//     // this.ticket.description= x?.description;
//     // this.ticket.ticketType = x?.ticketType;
//     // this.ticket.price = x?.price;
//     // this.ticket.quantity = x?.quantity;

//    }
//    step2Submit(x : myformDto){
//     console.log(this.myform.value)
//     // this.ticket.startDate= x?.startDate;
//     // this.ticket.endDate= x?.endDate;
//     // this.ticket.priodNumber = x?.priodNumber;
//     // this.ticket.priodType = x?.priodType;
//     // this.ticket.startingPoint = x?.startingPoint;
//     // this.ticket.eventType = x?.eventType;
//     // this.ticket = { ...this.ticket,...x}
//    }
   Finish(){
    // this.ticket.minNumber= x?.minNumber;
    // this.ticket.maxNumber= x?.maxNumber;
    // this.ticket.maxQuantity = x?.maxQuantity;
    // this.ticket.minQuantity = x?.minQuantity;
    // this.ticket.eticket = x?.eticket;
    // this.ticket.willcall = x?.willcall;
    // this.ticket.hidden = x?.hidden;
    // this.ticket = { ...this.ticket,...x}
    // console.log(this.ticket)

    var data = this.myform.value as ticketsFormDto;
    this.ticketSrv.postBasicInfo(data);
   }
}
