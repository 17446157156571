import { Component } from '@angular/core';

@Component({
  selector: 'app-organizer-steps',
  templateUrl: './organizer-steps.component.html',
  styleUrls: ['./organizer-steps.component.scss']
})
export class OrganizerStepsComponent {

}
