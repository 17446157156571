<div class="container">
    <div class=" text-center my-5">
    <h2 class="fw-bold ">Everything you need to customize, manage, and grow your network</h2>
    <aside class="text-secondary mb-4">Choose a subscription that works for you to grow</aside>
    <button class="modified-pricing-btn rounded-pill ">Create event <span></span></button>
</div>

    <div class="row m-0 card-row justify-content-center">
        <div class="card row pricing-card-modified free-card mb-md-0 mb-3  col-md-4 col-12 justify-content-center align-items-center">
           <div class="mb-3">
            <h3>Free</h3>
            <span>Free to use for free events</span>
           </div>

            <hr>
            <div>
                <h3>Includes:</h3>
                <ul class="p-0 mb-5">
                    <li class="d-flex justify-content-between">
                        <span>Unlimited events</span><i class="fa-solid fa-xmark text-danger"></i>
                    </li>
                    <li class="d-flex justify-content-between">
                        <span>Ticketing & registration</span><i class="fa-solid fa-xmark text-danger"></i>
                    </li>
                    <li class="d-flex justify-content-between">
                        <span>Sales analytics</span><i class="fa-solid fa-xmark text-danger"></i>
                    </li>
                    <li class="d-flex justify-content-between">
                        <span>Team access & permissions</span><i class="fa-solid fa-xmark text-danger"></i>
                    </li>
                </ul>
            </div>
            <div class="text-center ">
                <button class="premium-pricing-btn rounded-pill ">Create event <span></span></button>
            </div>
        </div>

        <div class="card row pricing-card-modified mb-md-0 mb-3 mx-4 col-md-4 col-12 justify-content-center align-items-center premium-card">
            <div class="mb-3">
             <span class="d-flex align-items-center justify-content-between "><span class=" fs-3 d-flex">Premium </span> %20 event</span>

             <span>Total 1 nejmy stars</span>
            </div>

             <hr>
             <div>
                 <h3>Includes:</h3>
                 <ul class="p-0 mb-5">
                     <li class="d-flex justify-content-between">
                         <span>Unlimited events</span><i class="fa-solid fa-badge-check text-success"></i>
                     </li>
                     <li class="d-flex justify-content-between">
                         <span>Ticketing & registration</span><i class="fa-solid fa-badge-check text-success"></i>
                     </li>
                     <li class="d-flex justify-content-between">
                         <span>Sales analytics</span><i class="fa-solid fa-badge-check text-success"></i>
                     </li>
                     <li class="d-flex justify-content-between">
                         <span>Team access & permissions</span><i class="fa-solid fa-badge-check text-success"></i>
                     </li>
                 </ul>
             </div>
             <div class="text-center ">
                 <button class="premium-pricing-btn rounded-pill ">Buy <span></span></button>
             </div>
         </div>


         <div class="card row pricing-card-modified enterprise-card mb-md-0 mb-3  col-md-4 col-12 justify-content-center align-items-center">
            <div class="mb-3">
             <h3>Enterprise</h3>
             <span>Tailored white-label solution</span>
            </div>

             <hr>
             <div>
                 <h3>Includes:</h3>
                 <ul class="p-0 mb-5">
                     <li class="d-flex justify-content-between">
                         <span>customized</span><i class="fa-solid fa-seal-question text-primary"></i>
                     </li>
                     <li class="d-flex justify-content-between">
                         <span>customized</span><i class="fa-solid fa-seal-question text-primary"></i>
                     </li>
                     <li class="d-flex justify-content-between">
                         <span>customized</span><i class="fa-solid fa-seal-question text-primary"></i>
                     </li>
                     <li class="d-flex justify-content-between">
                         <span>customized</span><i class="fa-solid fa-seal-question text-primary"></i>
                     </li>
                 </ul>
             </div>
             <div class="text-center">
                 <button class="premium-pricing-btn rounded-pill ">let us connect <span></span></button>
             </div>
         </div>

    </div>

    <!-- <div class="faq-area py-5">
        <div class="container">
            <div class="faq-accordion">
                <p-accordion>
                    <accordion-group class="text-center d-flex justify-content-center border border-primary" heading="Complete features list">

                        <div class="container-fauild">
                            <div class="row">

                                <div class="col-md-3">
                                    <div class="box_collapse">
                                        <div row>
                                            <p class="text-center">
                                                <i class="fa-solid fa-boxes-stacked"></i>
                                            </p>
                                            <p class="text-center">Various ticket types</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-3">
                                    <div class="box_collapse">
                                        <div>
                                            <p class="text-center">
                                                <i class="fa-solid fa-table-columns"></i>
                                            </p>
                                            <p class="text-center">Sales/RSVP summary dashboard</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-3">
                                    <div class="box_collapse">
                                        <div>
                                            <p class="text-center">
                                                <i class="fa-solid fa-print-magnifying-glass"></i>
                                            </p>
                                            <p class="text-center">Digital or printed tickets</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-3">
                                    <div class="box_collapse">
                                        <div>
                                            <p class="text-center">
                                                <i class="fa-solid fa-users-medical"></i>
                                            </p>
                                            <p class="text-center">Group registration</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-3">
                                    <div class="box_collapse">
                                        <div>
                                            <p class="text-center">
                                                <i class="fa-regular fa-mobile"></i>
                                            </p>
                                            <p class="text-center">100% mobile-optimized</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-3">
                                    <div class="box_collapse">
                                        <div>
                                            <p class="text-center">
                                                <i class="fa-solid fa-share-nodes"></i>
                                            </p>
                                            <p class="text-center">Attendee social sharing tools</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-3">
                                    <div class="box_collapse">
                                        <div>
                                            <p class="text-center">
                                                <i class="fa-solid fa-envelope"></i>
                                            </p>
                                            <p class="text-center">Email invitations</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-3">
                                    <div class="box_collapse">
                                        <div>
                                            <p class="text-center">
                                                <i class="fa-solid fa-database"></i>
                                            </p>
                                            <p class="text-center">Detailed exports of all data</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-3">
                                    <div class="box_collapse">
                                        <div>
                                            <p class="text-center">
                                                <i class="fa-solid fa-square-poll-vertical"></i>
                                            </p>
                                            <p class="text-center">Survey integrations</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-3">
                                    <div class="box_collapse">
                                        <div>
                                            <p class="text-center">
                                                <i class="fa-duotone fa-hands-asl-interpreting"></i>
                                            </p>
                                            <p class="text-center">Badge printing integrations</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-3">
                                    <div class="box_collapse">
                                        <div>
                                            <p class="text-center">
                                                <i class="fa-solid fa-credit-card"></i>
                                            </p>
                                            <p class="text-center">Accepts all major credit cards</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-3">
                                    <div class="box_collapse">
                                        <div>
                                            <p class="text-center">
                                                <i class="fa-solid fa-money-check-dollar-pen"></i>
                                            </p>
                                            <p class="text-center">Scheduled Payouts</p>
                                        </div>
                                    </div>
                                </div>


                            </div>
                        </div>


                    </accordion-group>
                </accordion>
            </div>
        </div>
    </div>


    <div class="faq-area py-4 bg-faq">
        <div class="container">
            <div class="faq-accordion">
                <accordion>
                    <accordion-group heading="How much does it cost to use Antares?">
                        <p>You can sign up for free, and all free events are free. We charge 20% of the ticket price for the paid events.</p>
                    </accordion-group>
                    <accordion-group heading="Can I use Antares for free events?">
                        <p>There's no cost for organizers to use Antares if you're not charging for tickets.</p>
                    </accordion-group>
                    <accordion-group heading="How do I sell tickets on Antares?">
                        <p>You can accept credit card payments for your event.</p>
                    </accordion-group>
                    <accordion-group heading="How do I get money from Antares?">
                        <p>Enter your payout details, and your payout will start processing 4-5 days after the event ends.</p>
                    </accordion-group>
                    <accordion-group heading="Does Antares allow me to scan tickets?">
                        <p>Yes, you can scan tickets. You can check-in attendees using your smartphone or tablet's camera to scan the attendees' QR codes on their tickets.</p>
                    </accordion-group>
                    <accordion-group heading="How do I build an event website?">
                        <p>Log into your Antares account and click the Create button. Enter the pertinent details about your event, and you'll be ready to share your event page.</p>
                    </accordion-group>
                    <accordion-group heading="Can I allow attendees to change or cancel their registration?">
                        <p>Yes, attendees can change or cancel their registration for Antares events, provided that you choose that option.</p>
                    </accordion-group>
                    <accordion-group heading="Can I use Antares for virtual events?">
                        <p>Promote your virtual event and sell tickets on Antares, and we organize the event with the list of online meetings providers.</p>
                    </accordion-group>
                </accordion>
            </div>
        </div>
    </div> -->

</div>
<div class="partner-area  ptb-70  ">
    <div class="mx-md-5">
        <app-partner></app-partner>
    </div>
</div>
