
<div class="card custom-table-border">
    <!-- <div class="card-header text-center bg-primary rounded-top-23">
        <h1 class="text-white">Team Members</h1>
    </div> -->
    <div class="card-header text-center bg-primary rounded-top-23">
        <h1 class="fw-bold text-white mt-2">{{'TemMmbrsTblTitl'|translate}}</h1>
    </div>

        <div class="">
            <p-toast></p-toast>

            <div *ngIf="loader.show1" @fadeOut class="d-flex justify-content-center align-items-center" style="border-radius: 25px;top : 0;position: absolute;z-index: 1050;background-color:#fff;width: 100%;height: -webkit-fill-available;right: 0;bottom: 0;left: 0;">


            </div>

                <p-table #dt responsiveLayout="stack" [breakpoint]="'991px'" [value]="data" [rows]="10" [paginator]="data.length > 5" [globalFilterFields]="['firstName' ]"
                         [tableStyle]="{'min-width': '100%','max-width':'100%'}" [rowHover]="true" dataKey="id"
                         currentPageReportTemplate="{{'Showing'|translate}} {first} {{'to'|translate}} {last} {{'of'|translate}} {totalRecords} {{'entries'|translate}}"
                         [showCurrentPageReport]="true" class="">
                    <ng-template pTemplate="caption">

                        <div>

                            <div class="d-sm-flex d-block align-items-center justify-content-sm-between text-center">

                                <div class="  ms-md-4 mb-sm-0  mb-2" >

                                   <button [disabled]="orgSrv.isSubmitting" pButton   class="rounded-pill save-btn {{orgSrv.isSubmitting? 'bg-disabled' : ''}}"
                                 (click)="openNew()"><i  *ngIf="orgSrv.isSubmitting" class="fas fa-spinner fa-spin"></i><i *ngIf="!orgSrv.isSubmitting" class="fa-solid fa-plus"></i> {{'TemMmbrsTblBtn001'|translate}} <span></span></button>
                                            
                                </div>
                                <div class="p-input-filled me-md-3 ">
                            <span class="p-input-icon-left">
                                <i class="pi pi-search"></i>
                                <input pInputText type="text" class="form-control" (input)="dt.filterGlobal($event.target.value, 'contains')"
                                       placeholder="{{'Search...'|translate}}" />
                            </span>
                                </div>
                            </div>
                        </div>

                    </ng-template>
                    <ng-template pTemplate="header" class="">
                        <tr>
                            <th  class="fw-bold text-center">{{'TemMmbrsTblTh001'|translate}}</th>
                            <th  class="fw-bold text-center">{{'TemMmbrsTblTh002'|translate}}</th>
                            <th  class="fw-bold text-center">{{'TemMmbrsTblTh003'|translate}} </th>
                            <th  class="fw-bold text-center">{{'TemMmbrsTblTh004'|translate}}
                                </th>
                            <th  class="fw-bold text-center">{{'TemMmbrsTblTh005'|translate}}
                               </th>

                            <th class="fw-bold text-center"> {{'TemMmbrsTblTh006'|translate}}</th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-member>
                        <tr >

                            <td class="text-center padding-custom text-end"><span class="headers-cutom text-start fw-bold ">{{'TemMmbrsTblTh001'|translate}}</span>{{member.firstName}}</td>
                            <td class="text-center padding-custom text-end"><span class="headers-cutom text-start fw-bold ">{{'TemMmbrsTblTh002'|translate}}</span>{{member.lastName}}</td>

                            <td class="text-center padding-custom text-end"><span class="headers-cutom text-start fw-bold ">{{'TemMmbrsTblTh003'|translate}}</span>{{member.email}}</td>

                            <td class="text-center padding-custom text-end"><span class="headers-cutom text-start fw-bold ">{{'TemMmbrsTblTh004'|translate}}</span>{{member.role|translate}}</td>
                            <td class="text-center padding-custom text-end"><span class="headers-cutom text-start fw-bold ">{{'TemMmbrsTblTh005'|translate}}</span>{{member.title}}</td>
                            <td class="text-center">

                                    <button pButton pRipple icon="pi pi-pencil"
                                    class="p-button-rounded p-button-primary m-1"(click)="edit(member.id)" pTooltip="Edit" tooltipPosition="top"></button>
                                    <p-toast></p-toast>
                                    <p-confirmPopup></p-confirmPopup>
                                    <button pButton pRipple icon="pi pi-trash" class="p-button-rounded p-button-danger m-1" pTooltip="Delete" tooltipPosition="top"
                                    (click)="delete($event,member.id)"></button>


                            </td>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="summary">
                        <div class="flex align-items-center justify-content-between">

                        </div>
                    </ng-template>
                </p-table>
            </div>

        </div>

