import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'compositKey'
})
export class CompositKeyPipe implements PipeTransform {

  transform(object: any, properties: any[]): any {
    if (object && properties?.length) {
      object.compositeDataKey = properties.map(property => object[property]).join('-');
    }
    return object;
  }

}
