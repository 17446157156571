import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { EventsService } from 'src/app/services/events.service';
import { ProfileEditingSrvService } from '../user-profile-home-edit/profile-editing-srv.service';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { OrgnizersService } from 'src/app/services/orgnizers.service';
import { EventCard } from '../../landing-page/landing-page.service';

@Component({
  selector: 'app-user-profile-prview',
  templateUrl: './user-profile-prview.component.html',
  styleUrls: ['./user-profile-prview.component.scss']
})
export class USerProfilePrviewComponent implements OnInit {
  url=environment.Api;
  imageUrl:string="";
  name:string="";
  helpStepsModal : boolean = false;
  eventcount: number;
  totalfollwers: number;
  mobileNum:string="";
  phoneNum:string="";
  EventCards: EventCard[];
  constructor(private datePipe: DatePipe, private eventsService: EventsService,private profilesrv:ProfileEditingSrvService,private rout:Router,private orgsrv : OrgnizersService){


  }
  ngOnInit(): void {
    this.profilesrv.GetTopInfo();
    this.profilesrv.$getProfileTopInfo.subscribe(x=>{

           this.eventcount=x.totalEvent;
           this.totalfollwers=x.totalfolwers;

    })
    this.profilesrv.GetProfileImage();
    this.profilesrv.$getUserprofileImage.subscribe((x) => {
        this.imageUrl = x.image;
    });
    this.profilesrv.GetUserProfileInfo();
    this.profilesrv.$getUserprofileContactInfo.subscribe((x) => {
        this.name = x.firstName +' '+ x.lastName;
        console.log(x)

        this.mobileNum=x.mobilePhonecountry+x.mobilePhone;
        this.phoneNum=x.homePhonecountry+x.homePhone;
    });

    this.orgsrv.getuserEvents( 6 , 0).subscribe(x => this.EventCards = x);
  }
  showContactModal(){
    this.helpStepsModal = true
  }
}
