
<form  [formGroup]="Srv.AddonsGroupForm" (submit)="submit()" autocomplete="off">

    <div class="container">

        <div class=" row mb-2">

            <div class="p-input-filled col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6 mb-2" >
                <span class="p-float-label">

                    <p-multiSelect pTooltip="{{'AddOnGrpFrmVrtsSlct001Pop'|translate}}" showDelay="1000" tooltipPosition="bottom" #dtObject [options]="addOns" [styleClass]="'w-100'" optionLabel="name"
                    optionValue="id"
                    display="chip"  formControlName="addOns"     [ngClass]="{
                        'ng-invalid ng-dirty':
                        (Srv.AddonsGroupForm.get('addOns')?.touched &&
                        Srv.AddonsGroupForm.get('addOns')?.invalid)||(submitted&& Srv.AddonsGroupForm.get('addOns').invalid)
                    }" (onChange)="set($event)"></p-multiSelect>
                    <label class="b-5 ms-2" for="RecTicketConf">{{'AddOnGrpFrmVrtsSlct001'|translate}}</label>
                </span>
                <small class="p-error" *ngIf="(Srv.AddonsGroupForm.get('addOns')?.touched &&
                Srv.AddonsGroupForm.get('addOns')?.invalid)||(submitted&& Srv.AddonsGroupForm.get('addOns').invalid)
                ">{{'AddOnGrpFrmVrtsSlct001ErrMsg'|translate}}</small>
            </div>
        </div>

    </div>


    <div class="col-12 d-flex  justify-content-center">
        <div class="banner-btn rounded-pill mx-1">

            <button  pTooltip="go back to previous step"  type= "button"  showDelay="1000" tooltipPosition="left" class="rounded-pill cancel-btn"
                (click)="previous()"><i class="pi pi-angle-left  mt-1"></i> {{'PrviosBtn'|translate}}  <span></span></button>
            <button pTooltip="Cancel and dismiss changes "  type= "button" showDelay="1000" tooltipPosition="bottom" class="rounded-pill save-btn ms-1"
                (click)="cancel()" >{{'CanclBtn'|translate}} <span></span></button>
            <button  pTooltip="Save changes " showDelay="1000" tooltipPosition="right" 
            class="rounded-pill save-btn  ms-1 mt-md-0 mt-1">{{'FnshBtn'|translate}} <span></span></button>
        </div>
    </div>



</form>
