<div *ngIf="!mapProblem" class="card-body">
    <div  class="row  align-items-center" [ngClass]="{'justify-content-between':isRecurring,'justify-content-center':!isRecurring}" >
        <div class="p-input-filled  col-sm-12 col-md-6 mb-2">
            <AutocompleteComponent (problem)="getProblem($event)"  (setAddress)="getAddress2($event)"
            pTooltip="{{'LctionInpt001Pop'|translate}}" showDelay="1000"
            tooltipPosition="top"  addressType="geocode"
             [addressValue]="addressinput" ></AutocompleteComponent>
        </div>
    </div>
    <div class="row mb-3">
        <div class=" col-md-12 col-12 col-xs-12">
            <div #gmap style="width:100%;height:400px" #addresstext [ngClass]="{'d-none': !visible}" ></div>
        </div>
    </div>

</div>

<div *ngIf="mapProblem">
    <div class="row">
        <div class="col-12 d-flex justify-content-center">
            <h5>
                {{'ThereissomethingwrongwithgoogleAPI,tryagainlater'|translate}}
            </h5>
        </div>
    </div>
</div>
