import { DOCUMENT } from '@angular/common';
import { Component, OnInit,Inject } from '@angular/core';
import { Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { DropDownItem } from 'src/app/Interfaces/DropDownItem';
import { promoteFormdto } from 'src/app/Interfaces/promoteData';
import { EventStepsService } from 'src/app/services/event-steps.service';
import { PromoteFormStepsService } from 'src/app/services/promote-form-steps.service';
import { PromoteService } from 'src/app/services/promote.service';

@Component({
  selector: 'app-step3',
  templateUrl: './step3.component.html',
  styleUrls: ['./step3.component.scss']
})
export class Step3Component implements OnInit {
    Submitted:boolean=false;
    MaxDate:Date;

    ticketLimit: DropDownItem[] = [
      {
          id: "1",
          name: "Limited"
      }
      ,
      {
          id: "0",
          name: "Unlimited"
      }
  ]
  constructor(@Inject(DOCUMENT) public document: Document,private router: Router,private translate:TranslateService,
    private promoteserv:PromoteService, private activatedRoute: ActivatedRoute , public  prosrvform:PromoteFormStepsService,public eventStepSrv:EventStepsService) {

      
        this.translate.onLangChange.subscribe(() => {
            this.updateTranslations();
          });
  }
  updateTranslations() {
    this.ticketLimit = [
      {
          id: "1",
          name: this.translate.instant('PrmoFrmStp3Slct001Chois001')
      }
      ,
      {
          id: "0",
          name:  this.translate.instant('PrmoFrmStp3Slct001Chois002')
      }
  ]
  }



  getDateForCalender(e){
    if(e){
      return new Date(e).toLocaleTimeString()
    }
    return
   }
  ngOnInit(): void {
this.eventStepSrv.state$.subscribe(x=>{
  this.updateTranslations()
    this.MaxDate=new Date(x.endTime)
    if(this.prosrvform.promoteCodeForm.controls['isHidden'].value){
        this.prosrvform.promoteCodeForm.controls["discountAmount"].clearValidators();
        this.prosrvform.promoteCodeForm.controls["discountAmount"].updateValueAndValidity()
    }
 })


  }
  getDateBoolean(date){
    var d =new Date(date.year,date.month,date.day)




    return d .getFullYear() === this.MaxDate.getFullYear() &&
    d.getMonth() === this.MaxDate.getMonth() &&
    d.getDate() === this.MaxDate.getDate()

   }

  validatestep3() : boolean{
    if(this.prosrvform.promoteCodeForm.valid)

      return true
      else
      return false
  }
  checkValid(e){

    if(e.checked){
      this.prosrvform.promoteCodeForm.controls["discountAmount"].setValidators(Validators.max(100));
      this.prosrvform.promoteCodeForm.controls["discountAmount"].setValue(0);
      this.prosrvform.promoteCodeForm.controls["discountAmount"].updateValueAndValidity()
     // console.log(e.checked)
    }
    else{
      this.prosrvform.promoteCodeForm.controls["discountAmount"].clearValidators();
      this.prosrvform.promoteCodeForm.controls["discountAmount"].setValidators(Validators.required);
      this.prosrvform.promoteCodeForm.controls["discountAmount"].setValue(0);
      this.prosrvform.promoteCodeForm.controls["discountAmount"].updateValueAndValidity()
     // console.log(e.checked)
    }
  }

    //remove validation when multiple and set required when single
    removeValid(){
      if(this.prosrvform.promoteCodeForm.get("limit").value === "1"){
        this.prosrvform.promoteCodeForm.controls["quantity"].setValidators(Validators.required);
        this.prosrvform.promoteCodeForm.controls["quantity"].updateValueAndValidity()


      }
      else{
      this.prosrvform.promoteCodeForm.controls["quantity"].setValidators([]);
      this.prosrvform.promoteCodeForm.controls["quantity"].updateValueAndValidity()

      }
    }
  submit() {
//console.log(this. validatestep3())
    if(this. validatestep3()){
        this.prosrvform.finish();
        this.prosrvform.clear();

        this.router.navigate( ['Promo-codes'] ,{relativeTo: this.activatedRoute.parent.parent.parent});
    }
    else{
        this.Submitted=true;
    }


    // this.router.navigate([
    //   {
    //     outlets: { 'Promot-steps': ['promo-codes-all'] }
    //   }
    // ],{relativeTo: this.activatedRoute.parent.parent});

  }
  cancel() {
    this.prosrvform.clear()
    this.router.navigate( ['Promo-codes'] ,{relativeTo: this.activatedRoute.parent.parent.parent});
    // this.router.navigate([
    //   {
    //     outlets: { 'Promot-steps': ['promo-codes-all'] }
    //   }
    // ],{relativeTo: this.activatedRoute.parent.parent});
  }


  previous() {
    this.router.navigate( ['step2'], { relativeTo: this.activatedRoute.parent });

  }

}
