import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, ReplaySubject, Subject } from 'rxjs';
import { ticketsFormDto, TicketsGetDto, TicketsTablesDto, tiketsDTo } from '../Interfaces/TicketsDtoS';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class TicketsService {
  private subject: Subject<tiketsDTo[]> = new ReplaySubject<tiketsDTo[]>(1);

  get $getSubject(): Observable<any> {
    return this.subject.asObservable();
  }

url :string = environment.Api;
  constructor(public http:HttpClient) { }
  getAllTickets(id:string,Skip:number=0){
  this.http.get<tiketsDTo[]>(this.url+'d/t/g/t/all/'+id+"/"+Skip).subscribe(x=> this.subject.next(x));
  //  return this.http.get<TicketsTablesDto[]>(this.url+'d/t/g/t/all/'+id);
  }

  getAllTicketsForOrderForm(id: string): Observable<TicketsTablesDto[]> {


    return this.http.get<TicketsTablesDto[]>(this.url+'d/t/g/t/dropdown/'+id);

  }

  getSingleTickets(id:string):Observable<TicketsGetDto>{
    return this.http.get<TicketsGetDto>(this.url+'d/t/g/i/'+id);
  }

  postBasicInfo(data :ticketsFormDto){
    const url = this.url+'d/t/c/i';
    return this.http.post(url,data,
        {
        headers:{   'Content-Type':  'application/json',
        'Accept': 'application/json'
       // Authorization: 'my-auth-token'
        }}
).subscribe(x=>this.getAllTickets(data.events[0]));
}


removeEntry(id:string){
    return this.http.delete(this.url+'d/t/remove/'+id)
     }


}


