import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ProfileEditingSrvService } from '../../pages/user-home-profile/user-profile-home-edit/profile-editing-srv.service';
import { environment } from 'src/environments/environment';
 export interface profileImagdto{
  id:string;
  image:string
 }
@Component({
  selector: 'app-adduserprofilephoto',
  templateUrl: './adduserprofilephoto.component.html',
  styleUrls: ['./adduserprofilephoto.component.scss']
})
export class AdduserprofilephotoComponent implements OnInit {
  UserImageForm!:FormGroup;
  env=environment.Api;
  @Input() Image: string;
  submitted:boolean=false;
   constructor(private fb:FormBuilder,private root:Router,private ActRoot:ActivatedRoute,private ProfileSrv:ProfileEditingSrvService){

   }
  initalForm(id,image){
    this.UserImageForm = this.fb.group({
      id: [id],
      image: [image,[Validators.required]]
  })
  }
  //,this.validUserProfileImage()
  // validUserProfileImage():ValidatorFn{
  //   return (control:AbstractControl)=>{
  //            return control?.value!=""?null:{imageisnull:true}
  //         }
  // }
  ngOnInit(): void {
   this.initalForm('','')

   this.ProfileSrv.GetProfileImage();
   this.ProfileSrv.$getUserprofileImage.subscribe(x=>{
    console.log(x)
    this.initalForm(x.id,this.env+x.image)
   })



   
  }
  submit(x){

    console.log(this.UserImageForm.value )
    var data=this.UserImageForm.value as profileImagdto;
    console.log(data)
    this.ProfileSrv.PostUserProfileImage(data).subscribe(x=>{
      this.root.navigate(['User-profile'],{relativeTo: this.ActRoot.parent});
    })
  }
  GotoProfile(){
    this.root.navigate(['User-profile'],{relativeTo: this.ActRoot.parent});
  }


}
