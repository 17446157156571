
<form [formGroup]="myform">
    <div class="row">
        <div class="col-12">
            <h5>{{'Use your shipping address'|translate}}</h5>
            <p-inputSwitch formControlName="isUsLocal"  pTooltip="{{'selected if you want to pick up this element in your shipping address '|translate}}"
                showDelay="1000" tooltipPosition="top" inputId="is-online" 
                (onChange)="outputAddress()"></p-inputSwitch>
        </div>
        
            <div class="p-input-filled  col-12  mb-2" *ngIf="!myform.get('isUsLocal').value">
                <AutocompleteComponent (problem)="getProblem($event)"  (setAddress)="getAddress($event)" 
                pTooltip="{{'LctionInpt001Pop'|translate}}" showDelay="1000" 
                tooltipPosition="top"  addressType="geocode"
                 [addressValue]="myform.get('address').value" ></AutocompleteComponent>
            </div>

            <div class="  col-12 ">
                <div #gmap style="width:100%;height:400px" #addresstext [ngClass]="{'d-none': !visible}" ></div>
            </div>
     
    </div>
    <!-- <div class="row mb-3" *ngIf="visible">

        <div class="p-input-filled  col-sm-4 col-md-4 mb-3">
            <span class="p-float-label">
            <input  inputId="ffff"
             pTooltip="{{'LctionInpt002Pop'|translate}}" showDelay="1000" tooltipPosition="bottom" formControlName="address"  class="form-control"  pInputText />
            <label class=" form-label font-size-18"  for="ffff">{{'LctionInpt002'|translate}}</label>
            </span>
        </div>
        <div class="p-input-filled  col-sm-4 col-md-4 mb-3">
            <span class="p-float-label">
            <input formControlName="country" type="text" 
            pTooltip="{{'LctionInpt003Pop'|translate}}" showDelay="1000" tooltipPosition="bottom" class="form-control" required pInputText />
            <label class="font-size-18" >{{'LctionInpt003'|translate}}</label>
            </span>
        </div>
        <div class="p-input-filled  col-sm-4 col-md-4 mb-3">
            <span class="p-float-label">
            <input  formControlName="{{'LctionInpt004Pop'|translate}}" pTooltip="City" showDelay="1000" tooltipPosition="bottom" type="text" class="form-control" required pInputText />
            <label class="font-size-18" >{{'LctionInpt004'|translate}}</label>
            </span>
        </div>
     

    </div> -->
</form>

