import { Injectable } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import * as moment from 'moment';
import { RRule } from 'rrule';
import { CalenderService } from './calender.service';

@Injectable({
  providedIn: 'root'
})
export class CalenderStepsService {
  clenderSteps!:FormGroup;
  cutomOption!:FormGroup;
  clenderPost!:FormGroup;
  inialForm2(id:string,date:{startDate:Date,endDate:Date}[]){
    this.clenderPost=this.fb.group({
      id:[id],
      dates:[date]
    });
  }
  inital(){
    this.clenderSteps=this.fb.group({
      eventId:[""],
      startDate:[,[Validators.required]],//get start Date for first instance
      endDate:[,[Validators.required,this.DateValid('startDate')]],//get EndDate for first instance
      occuringtype:[,[Validators.required]],
      weeklyOccuringOption:[],
      monthlyOccuringOption:[],
      OcurinngEnd:[,[Validators.required, Validators.min(2)]]
    });
   this.cutomOption=this.fb.group({
    number:[],
    option:[],
   });

    this.clenderSteps.controls['startDate'].valueChanges.subscribe(()=>
     this.clenderSteps.controls['endDate'].updateValueAndValidity()  )
    //  this.clenderSteps.controls['endDate'].valueChanges.subscribe(()=>
    //  this.clenderSteps.controls['endDate'].updateValueAndValidity()  )

  }
  clearForm(){
    this.inital();
  }
  setSomeValidtion(){
    if(this.clenderSteps.controls['occuringtype'].value=='WEEKLY'){
      this.clenderSteps.controls['weeklyOccuringOption'].setValidators([Validators.required]);
      this.clenderSteps.controls['monthlyOccuringOption'].setValidators([]);
      this.cutomOption.controls['number'].setValidators([])
      this.cutomOption.controls['option'].setValidators([])
    }else{
      if(this.clenderSteps.controls['occuringtype'].value=='MONTHLY'){
        this.clenderSteps.controls['weeklyOccuringOption'].setValidators([]);
        this.clenderSteps.controls['monthlyOccuringOption'].setValidators([Validators.required]);
        this.cutomOption.controls['number'].setValidators([])
        this.cutomOption.controls['option'].setValidators([])
      }
      else{
        
        if(this.clenderSteps.controls['occuringtype'].value=='Custom'){
          this.clenderSteps.controls['weeklyOccuringOption'].setValidators([]);
          this.clenderSteps.controls['monthlyOccuringOption'].setValidators([]);
          this.cutomOption.controls['number'].setValidators([Validators.required,Validators.min(2)])
          this.cutomOption.controls['option'].setValidators([Validators.required])
         }else{
          this.clenderSteps.controls['weeklyOccuringOption'].setValidators([]);
          this.clenderSteps.controls['monthlyOccuringOption'].setValidators([]);
          this.cutomOption.controls['number'].setValidators([])
          this.cutomOption.controls['option'].setValidators([])
         }
       
      }
    }
  }
  DateValid(param:string):ValidatorFn{
    return (control:AbstractControl)=>{
     var s=new Date(control?.value);
     var e=new Date(control?.parent?.controls[param].value);
      return moment(s).isAfter(e)||moment(s).isSame(e)?null:{IsBigger:true}
    }
  }

  constructor( private fb:FormBuilder,private CalServ:CalenderService) {
    this.inital();
  }
  getWeekDayAsSTr(array) {
    var text = "";
    for (var i = 0; i < array.length; i++) {
      if (i == 0) {
        text = text + array[i];
      }
      else {
        text = text + "," + array[i];
      }

    }
    //console.log(text);
    return text;

  }
  DayOfWeek(date)//to get num of day in week with the weeek num of month
  {
   var days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday',
     'Thursday', 'Friday', 'Saturday'],
     prefixes = ['First', 'Second', 'Third', 'Fourth', 'Last'];
   if (prefixes[Math.ceil(moment(date).dates() / 7 - 1)] == "Fourth") {
     return 'Last  ' + days[moment(date).day()];
   }
   else {
     return prefixes[Math.ceil(moment(date).dates() / 7 - 1)] + ' ' + days[moment(date).day()];
   }

 }
  finish(){
    //var t=

      var option;
//console.log(t);
//console.log(this.clenderSteps.value)
     // console.log( new Date(this.clenderSteps.controls['startDate'].value))
      if (this.clenderSteps.controls['occuringtype'].value== "DAILY") {
        var startPoint=this.setDateOntomeZonetoUtc(this.clenderSteps.controls['startDate'].value)
       // console.log(this.clenderSteps.controls['startDate'].value);
       // console.log(startPoint);
       //  console.log(new Date(new Date(startPoint).setDate(new Date(startPoint).getDate()+1)));
        option = {
                  freq: RRule.DAILY,
                  count: this.clenderSteps.controls['OcurinngEnd'].value-1,
                  dtstart: new Date(new Date(startPoint).setDate(new Date(startPoint).getDate()+1))// Date(this.clenderSteps.controls['startDate'].value),
              };
            // if (this.ocorinngEndType=="Num") {
            //     option = {
            //         freq: RRule.DAILY,
            //         count: this.OcurinngEnd,
            //         dtstart: new Date(this.startDate),
            //     };
            //     console.log(option);
            // }
            // if (this.ocorinngEndType== "Date") {
            //     option = {
            //         freq: RRule.DAILY,
            //         dtstart: new Date(this.startDate),
            //         until: new Date(this.OcurinngEnd),
            //     };
            //     console.log(option);
            // }
        }
        if (this.clenderSteps.controls['occuringtype'].value=="WEEKLY") {

          // if (this.ocorinngEndType=="Num") {


               var text = `Every week on ${this.getWeekDayAsSTr(this.clenderSteps.controls['weeklyOccuringOption'].value)} for ${this.clenderSteps.controls['OcurinngEnd'].value-1} times`;
                option = RRule.parseText(text);
                var startPoint=this.setDateOntomeZonetoUtc(this.clenderSteps.controls['startDate'].value)
               //option.dtstart = new Date(new Date(new Date(Date.parse(startPoint + 'Z'))).setDate(new Date(startPoint).getDate()+1))
               option.dtstart = new Date(new Date(startPoint).setDate(new Date(startPoint).getDate()+1))
               //new Date(Date.parse(this.clenderSteps.controls['startDate'].value + 'Z'));/*new Date(startDate1,,);*/


                //option = {
                //    freq: rrule.RRule.WEEKLY,

                //    byweekday: weekday,
                //    count: NumberOfTime.val(),
                //    dtstart: new Date(startDate1),
                //};
           // }
            // if (this.ocorinngEndType== "Date") {
            //     text = `Every week on ${this.getWeekDayAsSTr(this.DaysOfWeek)} until ${new Date(this.OcurinngEnd)}`;
            //     option = RRule.parseText(text);
            //     option.dtstart = new Date(Date.parse(this.startDate + 'Z'));
            //     console.log(option);
            //     //option = {
            //     //    freq: rrule.RRule.WEEKLY,
            //     //    byweekday: weekday,
            //     //    dtstart: new Date(startDate1),
            //     //    until: new Date(EndOccurDate.val()),
            //     //};
            // }
        }

        if (this.clenderSteps.controls['occuringtype'].value== "MONTHLY") {
            //if (this.ocorinngEndType=="Num") {

                if (this.clenderSteps.controls['monthlyOccuringOption'].value== "lastDay")
                {
                    text = `Every month on the last for ${this.clenderSteps.controls['OcurinngEnd'].value-1} times`;
                    option = RRule.parseText(text);
                }
                if (this.clenderSteps.controls['monthlyOccuringOption'].value=="Day")
                {
                    text = `Every month on the ${moment(this.clenderSteps.controls['startDate'].value).dates()}Th  for ${this.clenderSteps.controls['OcurinngEnd'].value-1} times`;
                    option = RRule.parseText(text);
                }
                if (this.clenderSteps.controls['monthlyOccuringOption'].value== "WeekDay")
                {
                    text = `Every month on the ${this.DayOfWeek(this.clenderSteps.controls['startDate'].value)}  for ${this.clenderSteps.controls['OcurinngEnd'].value-1} times`;
                    option = RRule.parseText(text);
                }
                var startPoint=this.setDateOntomeZonetoUtc(this.clenderSteps.controls['startDate'].value)
                option.dtstart =   new Date(new Date(startPoint).setMonth(new Date(startPoint).getMonth()+1))
               // option.dtstart = new Date(new Date(new Date(Date.parse(startPoint + 'Z'))).setMonth(new Date(startPoint).getMonth()+1))
               // console.log(option.dtstart)
                // }
            // if (this.ocorinngEndType== "Date") {
            //     if (this.MonthlyOccType == "lastDay") {
            //       var  text = `Every month on the last until ${new Date(this.OcurinngEnd)}`;
            //         option = RRule.parseText(text);
            //         console.log(option);

            //     }
            //     if (this.MonthlyOccType == "Day") {
            //     var    text = `Every month on the ${moment(this.startDate).dates()}Th  until ${new Date(this.OcurinngEnd)}`;
            //         option = RRule.parseText(text);
            //         console.log(option);
            //     }
            //     if (this.MonthlyOccType == "WeekDay") {
            //      var   text = `Every month on the ${this.weekAndDay(this.startDate)}  until ${new Date(this.OcurinngEnd)}`;
            //         option = RRule.parseText(text);
            //         console.log(option);
            //     }
            // }
        }
        if (this.clenderSteps.controls['occuringtype'].value=="YEARLY") {
           // if (this.ocorinngEndType=="Num") {
           // console.log('IamHere')
                  var  text = `Every year for ${this.clenderSteps.controls['OcurinngEnd'].value-1} times`;
                   var startPoint=this.setDateOntomeZonetoUtc(this.clenderSteps.controls['startDate'].value)
                   option = RRule.parseText(text);
                  option.dtstart=new Date(new Date(startPoint).setFullYear(new Date(startPoint).getFullYear()+1))
               //   console.log(option)
          //  }
            // if (this.ocorinngEndType== "Date") {
            //         text = `Every year until ${new Date(this.OcurinngEnd)}`;
            //         option = RRule.parseText(text);
            //         console.log(option);
            // }
        }
        if(this.clenderSteps.controls['occuringtype'].value=="Custom"){
          var text="";
       // console.log(text)
        var t=0;
        switch(this.cutomOption.controls['option'].value){
         case 'day':t=this.cutomOption.controls['number'].value;
       text = `Every  ${this.cutomOption.controls['number'].value}  ${this.cutomOption.controls['option'].value} for ${this.clenderSteps.controls['OcurinngEnd'].value-1} times`;

         break;
         case  'week':t=this.cutomOption.controls['number'].value*7;
           text = `Every  ${this.cutomOption.controls['number'].value}  ${this.cutomOption.controls['option'].value} for ${this.clenderSteps.controls['OcurinngEnd'].value-1} times`;

         break;
         case  'month':t=this.cutomOption.controls['number'].value*30;
         text = `Every  ${this.cutomOption.controls['number'].value}  ${this.cutomOption.controls['option'].value} for ${this.clenderSteps.controls['OcurinngEnd'].value-1} times`;

         break;
         case  'year':t=this.cutomOption.controls['number'].value*365;
         text = `Every  ${this.cutomOption.controls['number'].value}  ${this.cutomOption.controls['option'].value} for ${this.clenderSteps.controls['OcurinngEnd'].value-1} times`;

         break;

        }
       
          var startPoint=this.setDateOntomeZonetoUtc(this.clenderSteps.controls['startDate'].value);
          option = RRule.parseText(text);
          option.dtstart=new Date(new Date(startPoint).setDate(new Date(startPoint).getDate()+t))
      //    console.log(option)
        }
        var dates = [];

          dates.push({startDate:this.clenderSteps.controls['startDate'].value,endDate:this.clenderSteps.controls['endDate'].value})

       var stamp= moment(this.clenderSteps.controls['endDate'].value).diff(this.clenderSteps.controls['startDate'].value);
      // console.log(stamp)
        var rruler = new RRule(option);
      //  console.log(rruler.toText())
       // console.log(rruler.all())
        for (var i = 0; i < rruler.all().length; i++) {
            
            dates.push({ startDate: rruler.all()[i].toLocaleString(),endDate: new Date(new Date(rruler.all()[i]).getTime() + stamp).toLocaleString()})//
        }

        this.inialForm2(this.clenderSteps.controls['eventId'].value,dates);
      //  console.log(this.clenderPost.value)
          // var Vm={
          //     id: this.clenderSteps.controls['eventId'].value,
          //     dates: dates,
          // }
          // console.log(Vm)
// this.postCalender()
 // console.log(this.clenderPost.value)
  }



  postCalender(){

     this.CalServ.postCalender(this.clenderPost.value);
       this.clearForm()
  }

  setDateOntomeZonetoUtc(d:Date){
    var t= new Date(Date.now()).getTimezoneOffset()
    //console.log(new Date(d));
    //var MyDate=new Date(d).setMinutes(new Date(d).getMinutes()+(new Date(Date.now()).getTimezoneOffset()));
    
    var MyDate=new Date(d)
   // console.log(MyDate);
    //console.log(new Date(MyDate));
    return new Date(MyDate);
  }

  testValidation(parentEndDate:Date):boolean{
    //var t=

      var option;
//console.log(t);
//console.log(this.clenderSteps.value)
     // console.log( new Date(this.clenderSteps.controls['startDate'].value))
      if (this.clenderSteps.controls['occuringtype'].value== "DAILY") {
        var startPoint=this.setDateOntomeZonetoUtc(this.clenderSteps.controls['startDate'].value)
       // console.log(this.clenderSteps.controls['startDate'].value);
       // console.log(startPoint);
       //  console.log(new Date(new Date(startPoint).setDate(new Date(startPoint).getDate()+1)));
        option = {
                  freq: RRule.DAILY,
                  count: this.clenderSteps.controls['OcurinngEnd'].value-1,
                  dtstart: new Date(new Date(startPoint).setDate(new Date(startPoint).getDate()+1))// Date(this.clenderSteps.controls['startDate'].value),
              };
            // if (this.ocorinngEndType=="Num") {
            //     option = {
            //         freq: RRule.DAILY,
            //         count: this.OcurinngEnd,
            //         dtstart: new Date(this.startDate),
            //     };
            //     console.log(option);
            // }
            // if (this.ocorinngEndType== "Date") {
            //     option = {
            //         freq: RRule.DAILY,
            //         dtstart: new Date(this.startDate),
            //         until: new Date(this.OcurinngEnd),
            //     };
            //     console.log(option);
            // }
        }
        if (this.clenderSteps.controls['occuringtype'].value=="WEEKLY") {

          // if (this.ocorinngEndType=="Num") {


               var text = `Every week on ${this.getWeekDayAsSTr(this.clenderSteps.controls['weeklyOccuringOption'].value)} for ${this.clenderSteps.controls['OcurinngEnd'].value-1} times`;
                option = RRule.parseText(text);
                var startPoint=this.setDateOntomeZonetoUtc(this.clenderSteps.controls['startDate'].value)
               //option.dtstart = new Date(new Date(new Date(Date.parse(startPoint + 'Z'))).setDate(new Date(startPoint).getDate()+1))
               option.dtstart = new Date(new Date(startPoint).setDate(new Date(startPoint).getDate()+1))
               //new Date(Date.parse(this.clenderSteps.controls['startDate'].value + 'Z'));/*new Date(startDate1,,);*/


                //option = {
                //    freq: rrule.RRule.WEEKLY,

                //    byweekday: weekday,
                //    count: NumberOfTime.val(),
                //    dtstart: new Date(startDate1),
                //};
           // }
            // if (this.ocorinngEndType== "Date") {
            //     text = `Every week on ${this.getWeekDayAsSTr(this.DaysOfWeek)} until ${new Date(this.OcurinngEnd)}`;
            //     option = RRule.parseText(text);
            //     option.dtstart = new Date(Date.parse(this.startDate + 'Z'));
            //     console.log(option);
            //     //option = {
            //     //    freq: rrule.RRule.WEEKLY,
            //     //    byweekday: weekday,
            //     //    dtstart: new Date(startDate1),
            //     //    until: new Date(EndOccurDate.val()),
            //     //};
            // }
        }

        if (this.clenderSteps.controls['occuringtype'].value== "MONTHLY") {
            //if (this.ocorinngEndType=="Num") {

                if (this.clenderSteps.controls['monthlyOccuringOption'].value== "lastDay")
                {
                    text = `Every month on the last for ${this.clenderSteps.controls['OcurinngEnd'].value-1} times`;
                    option = RRule.parseText(text);
                }
                if (this.clenderSteps.controls['monthlyOccuringOption'].value=="Day")
                {
                    text = `Every month on the ${moment(this.clenderSteps.controls['startDate'].value).dates()}Th  for ${this.clenderSteps.controls['OcurinngEnd'].value-1} times`;
                    option = RRule.parseText(text);
                }
                if (this.clenderSteps.controls['monthlyOccuringOption'].value== "WeekDay")
                {
                    text = `Every month on the ${this.DayOfWeek(this.clenderSteps.controls['startDate'].value)}  for ${this.clenderSteps.controls['OcurinngEnd'].value-1} times`;
                    option = RRule.parseText(text);
                }
                var startPoint=this.setDateOntomeZonetoUtc(this.clenderSteps.controls['startDate'].value)
                option.dtstart =   new Date(new Date(startPoint).setMonth(new Date(startPoint).getMonth()+1))
               // option.dtstart = new Date(new Date(new Date(Date.parse(startPoint + 'Z'))).setMonth(new Date(startPoint).getMonth()+1))
               // console.log(option.dtstart)
                // }
            // if (this.ocorinngEndType== "Date") {
            //     if (this.MonthlyOccType == "lastDay") {
            //       var  text = `Every month on the last until ${new Date(this.OcurinngEnd)}`;
            //         option = RRule.parseText(text);
            //         console.log(option);

            //     }
            //     if (this.MonthlyOccType == "Day") {
            //     var    text = `Every month on the ${moment(this.startDate).dates()}Th  until ${new Date(this.OcurinngEnd)}`;
            //         option = RRule.parseText(text);
            //         console.log(option);
            //     }
            //     if (this.MonthlyOccType == "WeekDay") {
            //      var   text = `Every month on the ${this.weekAndDay(this.startDate)}  until ${new Date(this.OcurinngEnd)}`;
            //         option = RRule.parseText(text);
            //         console.log(option);
            //     }
            // }
        }
        if (this.clenderSteps.controls['occuringtype'].value=="YEARLY") {
           // if (this.ocorinngEndType=="Num") {
            console.log('IamHere')
                  var  text = `Every year for ${this.clenderSteps.controls['OcurinngEnd'].value-1} times`;
                   var startPoint=this.setDateOntomeZonetoUtc(this.clenderSteps.controls['startDate'].value)
                   option = RRule.parseText(text);
                  option.dtstart=new Date(new Date(startPoint).setFullYear(new Date(startPoint).getFullYear()+1))
               ////   console.log(option)
          //  }
            // if (this.ocorinngEndType== "Date") {
            //         text = `Every year until ${new Date(this.OcurinngEnd)}`;
            //         option = RRule.parseText(text);
            //         console.log(option);
            // }
        }
        if(this.clenderSteps.controls['occuringtype'].value=="Custom"){
          var text="";
      //  console.log(text)
        var t=0;
        switch(this.cutomOption.controls['option'].value){
         case 'day':t=this.cutomOption.controls['number'].value;
       text = `Every  ${this.cutomOption.controls['number'].value}  ${this.cutomOption.controls['option'].value} for ${this.clenderSteps.controls['OcurinngEnd'].value-1} times`;

         break;
         case  'week':t=this.cutomOption.controls['number'].value*7;
           text = `Every  ${this.cutomOption.controls['number'].value}  ${this.cutomOption.controls['option'].value} for ${this.clenderSteps.controls['OcurinngEnd'].value-1} times`;

         break;
         case  'month':t=this.cutomOption.controls['number'].value*30;
         text = `Every  ${this.cutomOption.controls['number'].value}  ${this.cutomOption.controls['option'].value} for ${this.clenderSteps.controls['OcurinngEnd'].value-1} times`;

         break;
         case  'year':t=this.cutomOption.controls['number'].value*365;
         text = `Every  ${this.cutomOption.controls['number'].value}  ${this.cutomOption.controls['option'].value} for ${this.clenderSteps.controls['OcurinngEnd'].value-1} times`;

         break;

        }
       
          var startPoint=this.setDateOntomeZonetoUtc(this.clenderSteps.controls['startDate'].value);
          option = RRule.parseText(text);
          option.dtstart=new Date(new Date(startPoint).setDate(new Date(startPoint).getDate()+t))
        //  console.log(option)
        }
        var dateArray :Date[]=[];

        dateArray.push(new Date(this.clenderSteps.controls['endDate'].value))

       var stamp= moment(this.clenderSteps.controls['endDate'].value).diff(this.clenderSteps.controls['startDate'].value);
      // console.log(stamp)
        var rruler = new RRule(option);
      //  console.log(rruler.toText())
       // console.log(rruler.all())
        for (var i = 0; i < rruler.all().length; i++) {
            
          dateArray.push( new Date(new Date(rruler.all()[i]).getTime() + stamp))//
        }
        //console.log(rruler.all())
        const maxDate = new Date(Math.max.apply(null, dateArray));
//console.log(maxDate);
      //  console.log(parentEndDate)
     //   console.log(new Date(parentEndDate))
      //  console.log(moment(this.setDateOntomeZonetoUtc(maxDate)).isAfter( new Date(parentEndDate)))
        

        if(moment(this.setDateOntomeZonetoUtc(maxDate)).isAfter( new Date(parentEndDate))){
          return false
        }
        else{
          return true
        }
       
       
        //this.inialForm2(this.clenderSteps.controls['eventId'].value,dates);
      //  console.log(this.clenderPost.value)
          // var Vm={
          //     id: this.clenderSteps.controls['eventId'].value,
          //     dates: dates,
          // }
          // console.log(Vm)
// this.postCalender()
 // console.log(this.clenderPost.value)
  }
}
