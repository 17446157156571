import {HttpClient} from '@angular/common/http';
import {Component, OnInit} from '@angular/core';
import {environment} from '../../../../environments/environment';
import {Question} from '../../../Interfaces/question';

@Component({
    selector: 'app-order-form',
    templateUrl: './order-form.component.html',
    styleUrls: ['./order-form.component.scss']
})
export class OrderFormComponent implements OnInit {
    data =  [


            {
              id: -0.1,
              question: "First name",
              type: "short",
              isShown: true,
              isRequired: true,
              answer: "",
              options: [
                {
                  text: "",
                  value1: ""
                },
                {
                  text: "",
                  value1: ""
                }
              ]
            },
            {
              id: -0.2,
              question: "Last name",
              type: "short",
              isShown: true,
              isRequired: true,
              answer: "",
              options: [
                {
                  text: "",
                  value1: ""
                },
                {
                  text: "",
                  value1: ""
                }
              ]
            },
            {
              id: -0.3,
              question: "Email",
              type: "short",
              isShown: true,
              isRequired: true,
              answer: "",
              options: [
                {
                  text: "",
                  value1: ""
                },
                {
                  text: "",
                  value1: ""
                }
              ]
            }
          ,

        {
          id: -1,
          question: "Prefix(Mr.,Mrs.,etc.)",
          type: "select",
          isShown: true,
          isRequired: true,
          answer: "",
          options: [
            { text: "", value1: "" },
            { text: "", value1: "" },
          ],
        },
        {
          id: -2,
          question: "Suffix",
          type: "select",
          isShown: true,
          isRequired: true,
          answer: "",
          options: [
            { text: "", value1: "" },
            { text: "", value1: "" },
          ],
        },
        {
          id: -3,
          question: "Gender",
          type: "select",
          isShown: true,
          isRequired: true,
          answer: "",
          options: [
            { text: "", value1: "" },
            { text: "", value1: "" },
          ],
        },
        {
          id: -4,
          question: "Home phone",
          type: "short",
          isShown: true,
          isRequired: true,
          answer: "",
          options: [
            { text: "", value1: "" },
            { text: "", value1: "" },
          ],
        },
        {
          id: -5,
          question: "Cell phone",
          type: "short",
          isShown: true,
          isRequired: true,
          answer: "",
          options: [
            { text: "", value1: "" },
            { text: "", value1: "" },
          ],
        },
        {
          id: -6,
          question: "Home address",
          type: "paragraph",
          isShown: true,
          isRequired: true,
          answer: "",
          options: [
            { text: "", value1: "" },
            { text: "", value1: "" },
          ],
        },
        {
          id: -7,
          question: "Shipping address",
          type: "paragraph",
          isShown: true,
          isRequired: true,
          answer: "",
          options: [
            { text: "", value1: "" },
            { text: "", value1: "" },
          ],
        },
        {
          id: -8,
          question: "Website",
          type: "short",
          isShown: true,
          isRequired: true,
          answer: "",
          options: [
            { text: "", value1: "" },
            { text: "", value1: "" },
          ],
        },
        {
          id: -9,
          question: "Job title",
          type: "short",
          isShown: true,
          isRequired: true,
          answer: "",
          options: [
            { text: "", value1: "" },
            { text: "", value1: "" },
          ],
        },
        {
          id: -10,
          question: "Company / organization",
          type: "short",
          isShown: true,
          isRequired: true,
          answer: "",
          options: [
            { text: "", value1: "" },
            { text: "", value1: "" },
          ],
        },
        {
          id: -11,
          question: "Work address",
          type: "paragraph",
          isShown: true,
          isRequired: true,
          answer: "",
          options: [
            { text: "", value1: "" },
            { text: "", value1: "" },
          ],
        },
        {
          id: -12,
          question: "Work phone",
          type: "paragraph",
          isShown: true,
          isRequired: true,
          answer: "",
          options: [
            { text: "", value1: "" },
            { text: "", value1: "" },
          ],
        },
        {
          id: 2,
          question: "hhhh",
          type: "short",
          isShown: true,
          isRequired: false,
          answer: "",
          options: [],
        },
      ];
    public formData: Question[];
    /**
     *
     */
    constructor(private http: HttpClient) {
    }
    ngOnInit(): void {
        this.http
            .get(environment.Api + 'd/e/ordform/19b4e515-1041-4ac1-a976-afd79c4cca26')
            .subscribe((x) => {
      //      console.log(x);
            this.formData = JSON.parse(x['form']);
         //   console.log(this.formData);
            });
    }
}
