import { MessageService } from 'primeng/api';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { TranslateService } from '@ngx-translate/core';
import { Observable, ReplaySubject, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit{
value: any;
returnUrl: string;
    loginForm = this.fb.group({
        Username: ['', Validators.required],
        password: ['', [Validators.required , Validators.pattern(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+~`-])(?=.*\d).{8,32}$/)]],
        rememberMe: [false]

    });
    

    LogoUrl:string="";
    private subject: Subject<string> = new ReplaySubject<string>(1);

  get $getSubject(): Observable<string> {
    return this.subject.asObservable();
  }
    constructor(private messageService : MessageService,
        private route: ActivatedRoute,private fb: FormBuilder, public Auth: AuthService, private router: Router,private translate:TranslateService){
            this.translate.onLangChange.subscribe(x=>{
                var s = localStorage.getItem(this.translate.currentLang+1) as string;
                this.subject.next(s);
            })
        }
    ngOnInit(): void {

        var s = localStorage.getItem(this.translate.currentLang+1) as string;
        this.subject.next(s);
        this.$getSubject.subscribe(x=>{
            this.LogoUrl=environment.Api+x;
        })
        this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
    }

    login(){
        this.Auth.login(this.loginForm.value).subscribe( x => {

            this.Auth.checkUserProfile().subscribe(x=>{console.log(x)
            if(x){
                this.router.navigate([this.returnUrl]);
                this.Auth.isSubmitting = false
            }else{
                this.router.navigate(['User-profile']);
                this.Auth.isSubmitting = false
            }
            
            })
           

            
        }, err => {this.messageService.add({severity : "error" , summary : "Somthing went wrong , check your email and password" , life : 3000}  );this.Auth.isSubmitting = false} , ()=>{this.Auth.isSubmitting = false});
    }


}
