


import { HttpClient } from '@angular/common/http';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { Component ,OnDestroy, OnInit,Inject} from '@angular/core';

import { DropDownItem } from 'src/app/Interfaces/DropDownItem';
import { environment } from 'src/environments/environment';
import * as moment from 'moment';
import { OrdernotisignalRService } from 'src/app/services/ordernotisignal-r.service';
import { DOCUMENT, DatePipe } from '@angular/common';
import { MessageService } from 'primeng/api';
import { OrderCheckout, OrderDataDto, items, postAddOnsDto } from '../order-event.component';
import { OrderConfigDto } from 'src/app/Interfaces/OrderIfo';





 


@Component({
    selector: 'app-tickets-msg',
    templateUrl: './tickets-msg.component.html',
    styleUrls: ['./tickets-msg.component.scss']
  })
export  class TicketsMsgComponent implements OnInit,OnDestroy {
  url = environment.Api;
  id: string;
  data: OrderDataDto = {};
  
  message:string="";
  constructor(private http: HttpClient,   private messageService: MessageService, 
    @Inject(DOCUMENT) public document: Document,
    private activatedRoute: ActivatedRoute,
    private fb:FormBuilder,private orderSrv:OrdernotisignalRService, private datePipe: DatePipe,private router:Router) {

  }

  
  ngOnDestroy(): void {
  
  }
  GoTohomePage(){
    this.router.navigate( [''] );
   }
 getUserwaitList(){
  return  this.http.get(environment.Api+'d/or/g/u/Waitlist'+this.id)
 }
 GetConfigInfo(Id:string){
  return this.http.get<OrderConfigDto>(environment.Api + 'd/or/g/e/o/co/' + Id);
}

 


 



  //add item as order

  ngOnInit(): void {
    this.id = this.activatedRoute.parent.snapshot.paramMap.get('id');

    this.GetConfigInfo(this.id).subscribe(x=>{
  this.message=x.waitlist.responseMessage;
    })
    //getUserwaitList()
    
   
  }

 
}
