<!-- 
<div class="container ">
    <form [formGroup]="rolesrv.form" (submit)="submit()">
        <div class="d-flex justify-content-center">
            <div class="p-input-filled  col-md-6 col-12">
                <div class="p-input-filled col-12 ">
                    <form [formGroup]="rolesrv.dropform" >
                    <span class="p-float-label">
                    <p-dropdown   (onChange)="onCategoryChange($event)" formControlName="dropCat" autoWidth="false" [options]="rolesrv.permissionCat" optionLabel="name" appendTo="body" optionValue="id" styleClass="form-control" filterBy="name" [showClear]="true" placeholder="Role">

                        <ng-template let-item pTemplate="selectedItem">
                            <div class="flex align-items-center gap-2">

                                <div>{{ item.name }}</div>
                            </div>
                        </ng-template>
                        <ng-template let-item pTemplate="item">
                            <div class="flex align-items-center gap-2">

                                <div>{{ item.name }}</div>
                            </div>
                        </ng-template>
                    </p-dropdown>
                    <label class="font-size-14" for="linktype">Role</label>
                </span>
            </form>
                </div>
                <div *ngIf="loader.show1" @fadeOut class="d-flex justify-content-center align-items-center" style="border-radius: 25px;top : 0;position: absolute;z-index: 1050;background-color:#fff;width: 100%;height: -webkit-fill-available;right: 0;bottom: 0;left: 0;">

                </div>
                <span class="p-float-label">
                    <input type="name" formControlName="name" id="name" pInputText class="form-control" maxlength="25"
                        [ngClass]="{
                                'ng-invalid ng-dirty':
                                (rolesrv.form.get('name')?.touched &&
                                rolesrv.form.get('name')?.invalid) || (rolesrv.form.get('name')?.invalid && submitted)
                            }" [class.ng-invalid.ng-dirty] />
                    <label class="font-size-14" for="name">Name</label>
                </span>
                <small class="p-error" *ngIf="(rolesrv.form.get('name')?.touched &&
                rolesrv.form.get('name')?.invalid) || (rolesrv.form.get('name')?.invalid && submitted)
                    " >Please add Valid Value</small>
            </div>
        </div>

        <div class="">
        <div class="row justify-content-md-center justify-content-between my-2">
            <div class="col-md-8 col-8 ">
                <h6 class="fw-bold details-bar-font ">Permissions</h6>
            </div>
            <div class="col-md-2 col-4 text-md-center text-end">
                <h6 class="fw-bold details-bar-font">Value</h6>
            </div>

        </div>
        <div class="" style="height: max-content;" formArrayName="Permissions" >
            <ng-container *ngFor="
                    let question of rolesrv.formPermissions.controls;
                    let i = index
                " [formGroupName]="i" >
                <div  *ngIf="question.get('parentId').value == id" class="row justify-content-md-center justify-content-between  mb-1">
                    <div class="col-md-8 col-8" >
                        <label for='{{i}}'>
                            {{ question.get("name").value }}
                        </label>
                    </div>
                    <div class="col-md-2 col-4 text-md-center text-end" >
                        <div class="square-switch">
                            <p-inputSwitch [inputId]="i" formControlName="value1"></p-inputSwitch>
                        </div>
                    </div>
                </div>

            </ng-container>
        </div>
    </div>
        <div class="d-flex justify-content-center my-2">
        <button class="rounded-pill save-btn ms-1" (click)="cancel()">Cancel <span></span></button>
        <button class="rounded-pill save-btn  ms-1 ">Save <span></span></button>
            </div>
    </form>
</div> -->




<div class="container ">
    <div *ngIf="loader.show1" @fadeOut class="d-flex justify-content-center align-items-center" style="border-radius: 25px;top : 0;position: absolute;z-index: 1050;background-color:#fff;width: 100%;height: -webkit-fill-available;right: 0;bottom: 0;left: 0;">

    </div>
    <form [formGroup]="rolesrv.form" (submit)="submit()">
        <div class="row justify-content-center">
                    <div class="p-input-filled col-6 ">
            <span class="p-float-label">
                <input type="name" formControlName="name" id="name" pInputText class="form-control" maxlength="25"
                    [ngClass]="{
                            'ng-invalid ng-dirty':
                            (rolesrv.form.get('name')?.touched &&
                            rolesrv.form.get('name')?.invalid) || (rolesrv.form.get('name')?.invalid && submitted)
                        }" [class.ng-invalid.ng-dirty] />
                <label class="font-size-14" for="name">{{'RolsFrmInpt001'|translate}}</label>
            </span>
            <small class="p-error" *ngIf="(rolesrv.form.get('name')?.touched &&
            rolesrv.form.get('name')?.invalid) || (rolesrv.form.get('name')?.invalid && submitted)
                " >{{'RolsFrmInpt001ErrMsg'|translate}}</small>
        </div>
        </div>
        <div class="d-flex justify-content-center">
            <p-tree [value]="rolesrv.permissionCat"  selectionMode="checkbox" class="w-full md:w-30rem" [(selection)]="rolesrv.selectedItems"></p-tree>



    </div>
        <div class="d-flex justify-content-center my-2">
        <button class="rounded-pill save-btn ms-1" (click)="cancel()">{{'CanclBtn'|translate}} <span></span></button>
        <button class="rounded-pill save-btn  ms-1 ">{{'SavBtn'|translate}}  <span></span></button>
            </div>
    </form>
</div>






<!--
    <div class="">
        <p-toast></p-toast>

        <div *ngIf="loader.show1" @fadeOut class="d-flex justify-content-center align-items-center" style="border-radius: 25px;top : 0;position: absolute;z-index: 1050;background-color:#fff;width: 100%;height: -webkit-fill-available;right: 0;bottom: 0;left: 0;">


        </div>

            <p-table #dt responsiveLayout="stack" [breakpoint]="'374px'" [value]="rolesrv.formPermissions.controls" [rows]="10" [paginator]="true" [globalFilterFields]="['name']"
                [tableStyle]="{'min-width': '100%','max-width':'100%'}" [rowHover]="true" dataKey="id"
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                [showCurrentPageReport]="true" class="">
                <ng-template pTemplate="caption">

                    <div>

                        <div
                            class="d-sm-flex d-block align-items-center justify-content-sm-between text-center">

                            <div class=" ms-md-4  mb-sm-0 mb-2">
                                <div class="p-input-filled col-12 ">
                                    <form [formGroup]="dropform" >
                                    <span class="p-float-label">
                                    <p-dropdown  (onChange)="onCategoryChange($event)" formControlName="dropCat" autoWidth="false" [options]="rolesrv.permissionCat" optionLabel="name" appendTo="body" optionValue="id" styleClass="form-control" filterBy="name" [showClear]="true" placeholder="Role">

                                        <ng-template let-item pTemplate="selectedItem">
                                            <div class="flex align-items-center gap-2">

                                                <div>{{ item.name }}</div>
                                            </div>
                                        </ng-template>
                                        <ng-template let-item pTemplate="item">
                                            <div class="flex align-items-center gap-2">

                                                <div>{{ item.name }}</div>
                                            </div>
                                        </ng-template>
                                    </p-dropdown>
                                    <label class="font-size-14" for="linktype">Role</label>
                                </span>
                            </form>
                                </div>
                            </div>
                            <div class="p-input-filled me-md-3 ">
                                <span class="p-input-icon-left">
                                    <i class="pi pi-search"></i>
                                    <input pInputText type="text" class="form-control"
                                        (input)="dt.filterGlobal($event.target.value, 'contains')"
                                        placeholder="Search..." />
                                </span>
                            </div>
                        </div>
                    </div>

                </ng-template>
                <ng-template pTemplate="header" class="">
                    <tr>
                        <th class="fw-bold text-center">Role name</th>

                        <th class="fw-bold text-center"> Action</th>
                    </tr>
                </ng-template>
                <form [formGroup]="rolesrv.form">
                <ng-template pTemplate="body" let-role>
                    <tr>
                        <td class="text-center padding-custom text-end">{{ question.get("name").value }}</td>

                        <td class="text-center">
                            <div class="action-custom">

                                <div class="col-md-2 col-4 text-md-center text-end">
                                    <div class="square-switch">
                                        <p-inputSwitch formControlName="value1"></p-inputSwitch>
                                    </div>
                                </div>
                            </div>

                        </td>
                    </tr>
                </ng-template>
            </form>
                <ng-template pTemplate="summary">
                    <div class="flex align-items-center justify-content-between">

                    </div>
                </ng-template>
            </p-table>
        </div>

-->
