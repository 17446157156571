import { ApplicationRef, Component, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { GMap } from 'primeng/gmap';
import { DropDownItem } from 'src/app/Interfaces/DropDownItem';
import { AddressService } from 'src/app/services/address.service';
import { LoaderService } from 'src/app/services/loader.service';
import { GeocodingService } from '../../shared/map/geocoding.service';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { EventStepsService } from 'src/app/services/event-steps.service';
import { MessageService } from 'primeng/api';
import { EventsService } from 'src/app/services/events.service';
import { DatePipe } from '@angular/common';
import { Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';
 export interface  profileData{
  id:string;
  image:string;
  userAddress:userAddress;
  userShippingAddress:userAddress;

 }
 export interface  userAddress{

  address:string,
  city: string,
  country:string,
  lat: string,
  lng: string,
 }
@Component({
  selector: 'app-user-profile-form',
  templateUrl: './user-profile-form.component.html',
  styleUrls: ['./user-profile-form.component.scss']
})
export class UserProfileFormComponent {
  addressForm: FormGroup;
  addressForm2:FormGroup;
  ProfileForm:FormGroup;
  visible: boolean = false;
  options: any;
  overlays: any[];
  image:string;
  img:boolean= false;
  img2:boolean= false;
  address = '';
  @ViewChild('gmap2') gmapElement1: any;
  @ViewChild('gmap') gmapElement: any;
  @ViewChild('gmap3') gmapElement2: any;
  @ViewChild('gmap4') gmapElement3: any;
  map: google.maps.Map;
  map2: google.maps.Map;
  ssss;
  submitted: boolean;
  mapProblem: boolean = false;
  sub: Subscription[]=[];
  isSreise: any;
  s2marker: google.maps.Marker;
  id:string="0"
  ngAfterViewChecked() {
      //     this.visible = true
  }
 

  constructor(public loader: LoaderService,
      private AddressSrv: AddressService,
      private geo: GeocodingService,
      private fb: FormBuilder,
      private http: HttpClient,
      private router: Router,
      private activatedRoute: ActivatedRoute,
      private ApplicationRef1: ApplicationRef,
      // private EventSrv: EventStepsService,
      private srv: EventStepsService,
      private messageService: MessageService,
      private EventPostSrv:EventsService,
      private datePipe: DatePipe
  ) { }


  formInit(id:string, address?: string, city?: string, country?: string, lat?: any, lng?: any) {
      this.addressForm = this.fb.group({
       
          address: [address, Validators.required],
          city: [city],
          country: [country, Validators.required],
          lat: [lat, Validators.required],
          lng: [lng, Validators.required],
          
      });
      this.addressForm2 = this.fb.group({
  
        address: [address, Validators.required],
        city: [city],
        country: [country, Validators.required],
        lat: [lat, Validators.required],
        lng: [lng, Validators.required],
        
    });

    this.ProfileForm=this.fb.group({
      image:["",Validators.required],
      id:[id]
    })


  }
  getProblem(e){
      this.mapProblem = e;
  }
 
  ngOnInit() {

      this.loader.showLoader1();
      this.formInit(this.id, ' ', ' ', " ", "", "");
     
  }


  handleMapClick(event) {
      // event: MouseEvent of Google Maps api
     // console.log(event);
      this.options = {
          center: { lat: 36.890257, lng: 30.707417 },
          zoom: 5
      };
  }

  handleOverlayClick(event) {
      // event.originalEvent: MouseEvent of Google Maps api
      // event.overlay: Clicked overlay
      // event.map: Map instance
  }

  // showMap(value,i) {

  //   if(i==1){
      
  //     const mapProp = {
  //       center: new google.maps.LatLng(value.geometry.location.lat(), value.geometry.location.lng()),
  //       zoom: 15,
  //       mapTypeId: google.maps.MapTypeId.ROADMAP
  //   };
  //   // set center
  //   this.map = new google.maps.Map(this.gmapElement.nativeElement, mapProp);

  //   // take marker
  //   const marker = new google.maps.Marker({
  //       position: new google.maps.LatLng(value.geometry.location.lat(), value.geometry.location.lng()),
  //       title: value.formatted_address,
  //       draggable: true
  //   });
  //     const geocoder = new google.maps.Geocoder();
  //     geocoder.geocode({ placeId: value.place_id }, function (results, status) {
  //         if (status === 'OK') {
  //           //  console.log(results);
  //             // this.map.setCenter(results[0].geometry.location);
  //             // const marker1 = new google.maps.Marker({
  //             //     map: this.map,
  //             //     position: results[0].geometry.location
  //             // });
  //         } else {
  //             alert('Geocode was not successful for the following reason: ' + status);
  //         }
  //     });
  //     marker.setMap(this.map);
  //     this.ssss = marker;
  //   }else{
  //     const mapProp = {
  //       center: new google.maps.LatLng(value.geometry.location.lat(), value.geometry.location.lng()),
  //       zoom: 15,
  //       mapTypeId: google.maps.MapTypeId.ROADMAP
  //   };
  //      // set center
  //      this.map = new google.maps.Map(this.gmapElement1.nativeElement, mapProp);
  //      const marker2 = new google.maps.Marker({
  //       position: new google.maps.LatLng(value.geometry.location.lat(), value.geometry.location.lng()),
  //       title: value.formatted_address,
  //       draggable: true
  //   });
  //     const geocoder = new google.maps.Geocoder();
  //     geocoder.geocode({ placeId: value.place_id }, function (results, status) {
  //         if (status === 'OK') {
           
  //         } else {
  //             alert('Geocode was not successful for the following reason: ' + status);
  //         }
  //     });
  //     marker2.setMap(this.map);


  //     this.s2marker = marker2;
  //   }

  
    
  // }


  showMap(value,i) {

    if(i==1){

      const mapProp = {
        center: new google.maps.LatLng(value.geometry.location.lat(), value.geometry.location.lng()),
        zoom: 15,
        mapTypeId: google.maps.MapTypeId.ROADMAP
    };
    // set center
    this.map = new google.maps.Map(this.gmapElement.nativeElement, mapProp);

    // take marker
    const marker = new google.maps.Marker({
        position: new google.maps.LatLng(value.geometry.location.lat(), value.geometry.location.lng()),
        title: value.formatted_address,
        draggable: true
    });
    this.map2 = new google.maps.Map(this.gmapElement3.nativeElement, mapProp);

    // take marker

      const geocoder = new google.maps.Geocoder();
      geocoder.geocode({ placeId: value.place_id }, function (results, status) {
          if (status === 'OK') {
            //  console.log(results);
              // this.map.setCenter(results[0].geometry.location);
              // const marker1 = new google.maps.Marker({
              //     map: this.map,
              //     position: results[0].geometry.location
              // });
          } else {
              alert('Geocode was not successful for the following reason: ' + status);
          }
      });
      marker.setMap(this.map);
      marker.setMap(this.map2);
      this.ssss = marker;
    }else{
      const mapProp = {
        center: new google.maps.LatLng(value.geometry.location.lat(), value.geometry.location.lng()),
        zoom: 15,
        mapTypeId: google.maps.MapTypeId.ROADMAP
    };
       // set center
       this.map = new google.maps.Map(this.gmapElement1.nativeElement, mapProp);
       const marker2 = new google.maps.Marker({
        position: new google.maps.LatLng(value.geometry.location.lat(), value.geometry.location.lng()),
        title: value.formatted_address,
        draggable: true
    });
    this.map2 = new google.maps.Map(this.gmapElement2.nativeElement, mapProp);

      const geocoder = new google.maps.Geocoder();
      geocoder.geocode({ placeId: value.place_id }, function (results, status) {
          if (status === 'OK') {

          } else {
              alert('Geocode was not successful for the following reason: ' + status);
          }
      });
      marker2.setMap(this.map);
      marker2.setMap(this.map2);

      this.s2marker = marker2;
    }



  }

  handleDragEnd(event) {
     // console.log(event)
  }





  getAddress(x, d) {
    this.visible = true;
    this.showMap(x, d);
    if (d == 1) {
      this.img=true
      this.addressForm.controls['address'].setValue(x.formatted_address);
      this.addressForm.controls['city'].setValue(x.address_components.find(y => y.types.includes('administrative_area_level_1'))?.long_name);
      this.addressForm.controls['country'].setValue(x.address_components.find(y => y.types.includes('country'))?.long_name);
      this.addressForm.controls['lat'].setValue(x.geometry.location.lat());
      this.addressForm.controls['lng'].setValue(x.geometry.location.lng());
      this.ApplicationRef1.tick()
    }
    else {
      this.img2=true
      this.addressForm2.controls['address'].setValue(x.formatted_address);
      this.addressForm2.controls['city'].setValue(x.address_components.find(y => y.types.includes('administrative_area_level_1'))?.long_name);
      this.addressForm2.controls['country'].setValue(x.address_components.find(y => y.types.includes('country'))?.long_name);
      this.addressForm2.controls['lat'].setValue(x.geometry.location.lat());
      this.addressForm2.controls['lng'].setValue(x.geometry.location.lng());
      this.ApplicationRef1.tick()
    }
  }

  submit() 
  {


          this.addressForm.get('lat').setValue(this.ssss.getPosition().lat())
          this.addressForm.get('lng').setValue(this.ssss.getPosition().lng())
          this.addressForm2.get('lat').setValue(this.s2marker.getPosition().lat())
          this.addressForm2.get('lng').setValue(this.s2marker.getPosition().lng())
          console.log(this.addressForm.value)
          console.log(this.addressForm2.value)
          console.log(this.ProfileForm)
          var m= this.addressForm.value as userAddress;
          var m2=this.addressForm2.value as userAddress;
          var f:profileData={
            id:this.ProfileForm.controls['id'].value,
            image:this.ProfileForm.controls['image'].value,
            userAddress:m,
            userShippingAddress:m2
          }
         this.http.post(environment.Api+'d/up/c',f).subscribe(x=>x)
  }
}
