<form [formGroup]="prosrvform.promoteCodeForm" (submit)="submit()" autocomplete="off">
    <div class="p-input-filled  mb-2" *ngIf="!prosrvform.isEditing">
        <p-inputSwitch pTooltip="{{'PrmoFrmStp1Swtch001Pop'|translate}}" showDelay="1000" tooltipPosition="bottom"
            name="isMultiple" binary="true" formControlName="isMultiple" (onChange)="removeValid()"
            inputId="isMultiple"></p-inputSwitch>
        <label class=" form-check-label b-5 ms-2" for="isMultiple">{{'PrmoFrmStp1Swtch001'|translate}}</label>
    </div>
    <div class="p-input-filled col-sm-6 col-md-6  mb-2" *ngIf="!prosrvform.promoteCodeForm.get('isMultiple').value">
        <span class="p-float-label">
            <input type="text" pTooltip="{{'PrmoFrmStp1Inpt001Pop'|translate}}" showDelay="1000"
                tooltipPosition="bottom" formControlName="name" pInputText class="form-control"
                [ngClass]="{'ng-invalid ng-dirty':prosrvform.promoteCodeForm.get('name')?.touched && prosrvform.promoteCodeForm.get('name')?.invalid ||prosrvform.promoteCodeForm.get('name')?.invalid && submitted }">
            <label class="form-label font-size-14" for="TicketName">{{'PrmoFrmStp1Inpt001'|translate}}</label>
        </span>
        <small class="p-error" *ngIf="prosrvform.promoteCodeForm.get('name')?.touched &&
        prosrvform.promoteCodeForm.get('name')?.errors?.required">Please add valid value.</small>
        <small class="p-error" *ngIf="prosrvform.promoteCodeForm.get('name')?.errors?.pattern">No white space.</small>
    </div>
    <div class="p-input-filled col-12 col-lg-6  mb-2" *ngIf="prosrvform.promoteCodeForm.get('isMultiple').value">
        <span>
            <label class="file-upload">
                <input pTooltip="Uploade file that contain names for codes" showDelay="1000" tooltipPosition="bottom"
                    accept=".csv" type="file" name="files" class="" #uploads (change)="onChange($event)" />
                <i class="fa-solid fa-plus"></i> {{'PrmoFrmStp1Btn001'|translate}}
            </label>
            <span class="mx-3">{{filename}}</span>
        </span>
        <br>
        <small *ngIf="prosrvform.promoteCodeForm.get('names')?.touched &&
         prosrvform.promoteCodeForm.get('names')?.invalid ||prosrvform.promoteCodeForm.get('names')?.invalid
         && submitted " class="p-error" text="">{{'PrmoFrmStp1ErrMsg'|translate}}</small>
    </div>
    <div class="col-12 d-flex  justify-content-center">
        <div class="banner-btn rounded-pill ">
            <button pTooltip="Cancel and dismiss changes " type="button" showDelay="1000" tooltipPosition="left"
                class="rounded-pill save-btn" (click)="cancel()">{{'CanclBtn'|translate}} <span></span></button>
            <button pTooltip="Save and go to next step" showDelay="1000" tooltipPosition="right"
                class="rounded-pill next-btn ms-1">{{'NextBtn'|translate}} <i class="pi mt-1"
                    [class.pi-angle-right]="document.documentElement.dir === 'ltr'"
                    [class.pi-angle-left]="document.documentElement.dir === 'rtl'"></i><span></span></button>
        </div>
    </div>

</form>