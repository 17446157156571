import { Injectable, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Links, LinkTypes, OrganizerFormDto, OrgnizersService } from './orgnizers.service';

@Injectable({
  providedIn: 'root'
})
export class OrganizerFromStepService implements OnInit {
    form !: FormGroup;
    reg : string = '^(https?://)?[\\w.-]+(\\.[\\w.-]+)+[\\w-._~:/?#[\\]@!$&\'()*+,;=]*$';
    links: any[] = [];
    linkDto: any[] = [];
    linkData: LinkTypes[];
    linksForm: FormGroup;
    orgId : string;

  constructor(private fb:FormBuilder , private orgSrv : OrgnizersService) {
    this.Initializers("0" , "" ,'' , '' ,"" , '' , [] , null)
    this.orgSrv.getLinksType().subscribe(x => this.linkData = x);
   }
  ngOnInit(): void {

  }
  bbbb(){
    this.orgSrv.getLinksType().subscribe(x => this.linkData = x);
  }

  getLinksName(y) {
    let x;
    x = this.linkData.find(x => x.attrId + "" == y);
    return x;
  }
  Initializersform() {
    this.linksForm = this.fb.group({
      linktype: ["", Validators.required],
      linkurl: ["", [Validators.required , Validators.pattern(this.reg)]],
    })
  }


  getLinksForEdit(links){
    links.forEach(e => {
      let additionalData = this.getLinksName(e.attrId)
      let x = {
        name: additionalData.name,
        linktype: e.attrId,
        linkurl: e.linkUrl,
      }
      let y : Links= {
        attrId : e.attrId ,
        linkUrl : e.linkUrl
      }
   //   console.log(x);
      this.links.push(x);
      this.linkDto.push(y);
    });
  }
  Initializers(id : string , name : string ,bio : string , image : string , email : string , website : string , links : Links[] , phone : number) {
    this.form = this.fb.group({
      id : [id , ],
      name : [name ,[ Validators.required , Validators.maxLength(2000)]] ,
      bio : [bio , Validators.maxLength(2000) ] ,
      phoneNumber : [phone , [Validators.required , Validators.pattern(/^\+?\d+$/) , Validators.minLength(5) , Validators.maxLength(20)] ] ,
      image : [image , Validators.required],
      email : [email ,[ Validators.required , Validators.email]],
      website : [website ,[Validators.pattern(this.reg)]],
      links : [links , ]
    })
  }
  clear(){
    this.Initializers("" , "" , "" , "" , "" , "" , [] , null);
    this.links =[];
    this.linkDto = [];

   }
   finish(){
    var data = this.form.value as OrganizerFormDto;
    //console.log(data);
    this.orgSrv.postOrganizer(data);
   }
}

