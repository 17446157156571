

        <div class="">
            <p-toast></p-toast>

            <div *ngIf="loader.show1" @fadeOut class="d-flex justify-content-center align-items-center" style="border-radius: 25px;top : 0;position: absolute;z-index: 1050;background-color:#fff;width: 100%;height: -webkit-fill-available;right: 0;bottom: 0;left: 0;">


            </div>

                <p-table #dt responsiveLayout="stack" [breakpoint]="'374px'" [value]="data" [rows]="10" [paginator]="data.length > 5" [globalFilterFields]="['name']"
                    [tableStyle]="{'min-width': '100%','max-width':'100%'}" [rowHover]="true" dataKey="id"
                    currentPageReportTemplate="{{'Showing'|translate}} {first} {{'to'|translate}} {last} {{'of'|translate}} {totalRecords} {{'entries'|translate}}"
                    [showCurrentPageReport]="true" class="">
                    <ng-template pTemplate="caption">

                        <div>

                            <div
                                class="d-sm-flex d-block align-items-center justify-content-sm-between text-center">

                                <div class=" ms-md-4  mb-sm-0 mb-2">
                                    <button pButton pRipple class="rounded-pill add-new-btn mr-2"
                                        (click)="openNew()"><i class="fa-solid fa-plus"></i>
                                        {{'RolsTblBtn001'|translate}}<span></span></button>
                                </div>
                                <div class="p-input-filled me-md-3 ">
                                    <span class="p-input-icon-left">
                                        <i class="pi pi-search"></i>
                                        <input pInputText type="text" class="form-control"
                                            (input)="dt.filterGlobal($event.target.value, 'contains')"
                                            placeholder="{{'roleSerach'|translate}}" />
                                    </span>
                                </div>
                            </div>
                        </div>

                    </ng-template>
                    <ng-template pTemplate="header" class="">
                        <tr>
                            <th class="fw-bold text-center">{{'RolsTblRollsHed01'|translate}}</th>

                            <th class="fw-bold text-center">{{'TemMmbrsTblTh006'|translate}}</th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-role>
                        <tr>
                            <td class="text-center padding-custom text-end"><span class="headers-cutom text-start fw-bold ">{{'RolsTblRollsHed01'|translate}}</span>{{role.name|translate}}</td>
                            <!-- <td><p-rating [ngModel]="event.gross" [readonly]="true" [cancel]="false"></p-rating></td> -->
                            <!-- <td><span [class]="'product-badge status-' + (product.inventoryStatus ? product.inventoryStatus.toLowerCase() : '')">{{product.inventoryStatus}}</span></td> -->

                            <td class="text-center">
                                <div [class.action-custom]="document.documentElement.dir === 'ltr'"
                                [class.action-custom-rtl]="document.documentElement.dir === 'rtl'">


                                <button pButton pRipple icon="pi pi-pencil" pTooltip="Edit" tooltipPosition="top"
                                    class="p-button-rounded p-button-primary m-1" (click)="edit(role.id)"></button>
                                    <p-toast></p-toast>
                                    <p-confirmPopup></p-confirmPopup>
                                <button pButton pRipple icon="pi pi-trash" pTooltip="Delete" tooltipPosition="top"
                                    class="p-button-rounded p-button-danger m-1" (click)="delete($event,role.id)"></button>
                                </div>

                            </td>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="summary">
                        <div class="flex align-items-center justify-content-between">
                            <!--                            In total there are {{event ? data.length : 0 }} events.-->
                        </div>
                    </ng-template>
                </p-table>
            </div>


