import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { animate, style, transition, trigger } from '@angular/animations';
import { LoaderService } from 'src/app/services/loader.service';
import { TranslateService } from '@ngx-translate/core';
import * as m from 'moment';
import 'moment/locale/pt-br';
import { UserOrderInstance, UserOrderService } from '../user-order/user-order.service';
import { ActivatedRoute } from '@angular/router';
const leaveTrans = transition(':leave', [
  style({
      opacity: 1
  }),
  animate('1s ease-out', style({
      opacity: 0
  }))
])



const fadeOut = trigger('fadeOut', [
  leaveTrans
]);
@Component({
  selector: 'app-userpriview-orderinstance',
  templateUrl: './userpriview-orderinstance.component.html',
  styleUrls: ['./userpriview-orderinstance.component.scss'],
  animations:[fadeOut]
})
export class USerpriviewOrderinstanceComponent implements OnInit {
  url = environment.Api;
  Id:string="";
  data:UserOrderInstance={};
  constructor (private activerout:ActivatedRoute,private srv:UserOrderService,public loader : LoaderService,private translate:TranslateService){
    this.translate.onLangChange.subscribe(() => {
     
      m.locale(this.translate.currentLang);
    })
  }
  ngOnInit(): void {
    this.Id = this.activerout.snapshot.paramMap.get('id');
    this.srv.getUserOrderPrivew( this.Id).subscribe(x=>{
      //console.log(x);
      this.data=x; })

  }
  getDateOntomeZone(d: Date) {
    var t = new Date(Date.now()).getTimezoneOffset()
    var MyDate = new Date(d).setMinutes(new Date(d).getMinutes() - (new Date(Date.now()).getTimezoneOffset()));
    return new Date(MyDate).toLocaleString();
  }
    getTotal(x:any[]){
      return x.map(x=> x?.finalPrice).reduce((x,y)=>{return x+y})
    }

    
  getDateOntomeZoneformat2(d){
    m.locale();
      let t = new Date(Date.now()).getTimezoneOffset();
      let MyDate = new Date(d).setMinutes(new Date(d).getMinutes() - (t));
      var l="";
    if(new Date(MyDate).getFullYear()==new Date(Date.now()).getFullYear()){
          l= m(new Date(MyDate)).format('ddd MMMM D hh:mm a');
      }else{
         l=  m(new Date(MyDate)).format( 'ddd MMMM D,y hh:mm a');
      }
      return l
     // return new Date(MyDate).toLocaleString();
    }
}
